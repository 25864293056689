import React from 'react'

import { Event } from 'models/event'
import Services from 'services'

import {
  getQueryForDisplay,
  useAllTaskLabelLookup,
} from 'utils/task-definitions'
import { cn } from 'utils/utils'

import { useAnalytics } from 'components/common/analytics/analytics-context'
import { Badge } from 'components/ui/badge'
import Banner from 'components/ui/banner'
import { Button } from 'components/ui/button'
import { Dialog, DialogContent } from 'components/ui/dialog'
import { ScrollArea } from 'components/ui/scroll-area'
import { Spinner } from 'components/ui/spinner'

import { DocumentsCell } from './history-cells'
import { useHistoryMetadataStore } from './history-metadata-store'

interface HistoryDeleteDialogProps {
  modalOpen: boolean
  setModalOpen: (open: boolean) => void
  event: Event
  isWorkspaceDelete: boolean
  isVaultDelete?: boolean
  description?: string
  taskLabelLookup?: Record<string, string>
}

const HistoryDeleteDialog: React.FC<HistoryDeleteDialogProps> = ({
  modalOpen,
  setModalOpen,
  event,
  isWorkspaceDelete,
  isVaultDelete = false,
  description,
  taskLabelLookup,
}) => {
  const deleteEvent = useHistoryMetadataStore((s) => s.deleteEvent)
  const { trackEvent } = useAnalytics()
  const [isDeleting, setIsDeleting] = React.useState(false)
  const allTaskLabelLookup = useAllTaskLabelLookup()
  const taskLabelLookupOrDefault = taskLabelLookup ?? allTaskLabelLookup

  const onDeleteSubmit = React.useCallback(async () => {
    setIsDeleting(true)
    Services.HoneyComb.Record({
      metric: 'ui.history_delete_event_dialog_submitted',
      event_id: event.id,
      event_user_id: event.userId,
    })
    trackEvent('History Delete Event Dialog Submitted', {
      event_id: event.id,
    })

    await deleteEvent(event.id, isWorkspaceDelete, isVaultDelete)
    setModalOpen(false)
    setIsDeleting(false)
  }, [
    event.id,
    event.userId,
    trackEvent,
    deleteEvent,
    isVaultDelete,
    setModalOpen,
    isWorkspaceDelete,
  ])

  const query = getQueryForDisplay(event).trim()
  const isQueryLong = query.length > 200
  const queryText =
    query.length == 0 ? null : isQueryLong ? (
      <ScrollArea maxHeight="h-16" overflowHintSide="end">
        <p className="pb-6">{query}</p>
      </ScrollArea>
    ) : (
      <p>{query}</p>
    )

  const response = event.response.trim()
  const isResponseLong = response.length > 300
  const responseText = isResponseLong ? (
    <ScrollArea maxHeight="h-24" overflowHintSide="end">
      <p className="pb-6">{response}</p>
    </ScrollArea>
  ) : (
    <p>{response}</p>
  )

  return (
    <Dialog
      open={modalOpen}
      onOpenChange={(open) => {
        setModalOpen(open)
        Services.HoneyComb.Record({
          metric: `ui.history_delete_event_dialog_${
            open ? 'opened' : 'dismissed'
          }`,
          event_id: event.id,
          event_user_id: event.userId,
        })
        trackEvent(
          `History Delete Event Dialog ${open ? 'Opened' : 'Dismissed'}`,
          {
            event_id: event.id,
          }
        )
      }}
    >
      <DialogContent showCloseIcon={false}>
        <div>
          <p className="text-xl">Delete query</p>
          <Banner
            title="Are you sure?"
            variant="destructive"
            description={
              <p>
                {description ||
                  'This permanently deletes the query and any associated documents. Once deleted, they cannot be recovered.'}
              </p>
            }
            className="mt-4"
          />
          <div className="mt-6 space-y-4">
            <div className="space-y-2">
              <p className="font-semibold">Type</p>
              <Badge variant="secondary" className="w-fit">
                <p>{taskLabelLookupOrDefault[event.kind]}</p>
              </Badge>
            </div>
            <div className={cn('space-y-2', { '-mb-1': isQueryLong })}>
              <p className="font-semibold">Query</p>
              {queryText}
              {DocumentsCell({ documents: event.documents || [] })}
            </div>
            {response.length > 0 && (
              <div className={cn('space-y-2', { '-mb-1': isResponseLong })}>
                <p className="font-semibold">Response</p>
                {responseText}
              </div>
            )}
          </div>
          <div className="mt-6 flex flex-row justify-end space-x-2">
            <Button
              variant="ghost"
              onClick={() => {
                setModalOpen(false)
                Services.HoneyComb.Record({
                  metric: 'ui.history_delete_event_dialog_cancelled',
                  event_id: event.id,
                  event_user_id: event.userId,
                })
                trackEvent('History Delete Event Dialog Cancelled', {
                  event_id: event.id,
                })
              }}
            >
              Cancel
            </Button>
            <Button
              variant="destructive"
              disabled={isDeleting}
              onClick={onDeleteSubmit}
              className="*:text-sm"
            >
              {isDeleting ? (
                <div className="flex items-center">
                  <Spinner className="top-3 mr-2 h-3 w-3" />
                  Deleting…
                </div>
              ) : (
                <span>Permanently delete</span>
              )}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default HistoryDeleteDialog
