import React from 'react'

import { cn } from 'utils/utils'

interface AlertIconProps {
  className?: string
}

const AlertIcon: React.FC<AlertIconProps> = ({ className = '' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 12 12"
      fill="none"
      className={cn('h-auto w-auto', className)}
    >
      <g strokeLinecap="round" strokeLinejoin="round" clipPath="url(#a)">
        <path
          fill="#F87171"
          stroke="#F87171"
          d="M6 11A5 5 0 1 0 6 1a5 5 0 0 0 0 10Z"
        />
        <path stroke="#fff" d="M6 4v2M6 8h.005" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h12v12H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default AlertIcon
