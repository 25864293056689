/* tslint:disable */
/* eslint-disable */
/**
 * Harvey Web App - Workspace
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ChannelPartner = {
    PWC: 'PWC',
    MICROSOFT: 'MICROSOFT'
} as const;
export type ChannelPartner = typeof ChannelPartner[keyof typeof ChannelPartner];


export function ChannelPartnerFromJSON(json: any): ChannelPartner {
    return ChannelPartnerFromJSONTyped(json, false);
}

export function ChannelPartnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChannelPartner {
    return json as ChannelPartner;
}

export function ChannelPartnerToJSON(value?: ChannelPartner | null): any {
    return value as any;
}

