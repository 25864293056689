// Polyfill for requestIdleCallback
// https://developer.mozilla.org/en-US/docs/Web/API/Window/requestIdleCallback
const requestIdleCallback = (
    callback: (deadline: { didTimeout: boolean; timeRemaining: () => number }) => void,
    options?: { timeout?: number }
  ): number => {
    if (typeof window.requestIdleCallback === 'function') {
      return window.requestIdleCallback(callback, options)
    }

    const start = Date.now()
    return window.setTimeout(() => {
      const deadline = {
        didTimeout: false,
        timeRemaining: () => Math.max(0, 50 - (Date.now() - start)),
      }
      callback(deadline) // Call your async function here
    }, options?.timeout || 1)
  }

  const cancelIdleCallback = (id: number): void => {
    if (typeof window.cancelIdleCallback === 'function') {
      window.cancelIdleCallback(id)
    } else {
      clearTimeout(id)
    }
  }

  export { requestIdleCallback, cancelIdleCallback }
