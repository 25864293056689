/* tslint:disable */
/* eslint-disable */
/**
 * PwC Deals API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DiligenceTaskIdentifier } from './DiligenceTaskIdentifier';
import {
    DiligenceTaskIdentifierFromJSON,
    DiligenceTaskIdentifierFromJSONTyped,
    DiligenceTaskIdentifierToJSON,
    DiligenceTaskIdentifierToJSONTyped,
} from './DiligenceTaskIdentifier';
import type { SocketMessageResponse } from './SocketMessageResponse';
import {
    SocketMessageResponseFromJSON,
    SocketMessageResponseFromJSONTyped,
    SocketMessageResponseToJSON,
    SocketMessageResponseToJSONTyped,
} from './SocketMessageResponse';

/**
 * 
 * @export
 * @interface DiligenceTask
 */
export interface DiligenceTask {
    /**
     * 
     * @type {DiligenceTaskIdentifier}
     * @memberof DiligenceTask
     */
    identifier: DiligenceTaskIdentifier;
    /**
     * 
     * @type {string}
     * @memberof DiligenceTask
     */
    title: string;
    /**
     * 
     * @type {SocketMessageResponse}
     * @memberof DiligenceTask
     */
    answer?: SocketMessageResponse;
    /**
     * 
     * @type {Array<string>}
     * @memberof DiligenceTask
     */
    dependentTasks?: Array<string>;
}



/**
 * Check if a given object implements the DiligenceTask interface.
 */
export function instanceOfDiligenceTask(value: object): value is DiligenceTask {
    if (!('identifier' in value) || value['identifier'] === undefined) return false;
    if (!('title' in value) || value['title'] === undefined) return false;
    return true;
}

export function DiligenceTaskFromJSON(json: any): DiligenceTask {
    return DiligenceTaskFromJSONTyped(json, false);
}

export function DiligenceTaskFromJSONTyped(json: any, ignoreDiscriminator: boolean): DiligenceTask {
    if (json == null) {
        return json;
    }
    return {
        
        'identifier': DiligenceTaskIdentifierFromJSON(json['identifier']),
        'title': json['title'],
        'answer': json['answer'] == null ? undefined : SocketMessageResponseFromJSON(json['answer']),
        'dependentTasks': json['dependent_tasks'] == null ? undefined : json['dependent_tasks'],
    };
}

  export function DiligenceTaskToJSON(json: any): DiligenceTask {
      return DiligenceTaskToJSONTyped(json, false);
  }

  export function DiligenceTaskToJSONTyped(value?: DiligenceTask | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'identifier': DiligenceTaskIdentifierToJSON(value['identifier']),
        'title': value['title'],
        'answer': SocketMessageResponseToJSON(value['answer']),
        'dependent_tasks': value['dependentTasks'],
    };
}

