import React from 'react'
import { useEffectOnce } from 'react-use'

import _ from 'lodash'

import { HistoryTypeEnum } from 'models/helpers/history-helper'
import { AuditLogType } from 'openapi/models/AuditLogType'

import { AUDIT_LOG_DEBOUNCE_TIME } from 'utils/audit-log'
import { usePostAuditLog } from 'utils/audit-log'

import { useClientMattersStore } from 'components/client-matters/client-matters-store'
import { AppHeader } from 'components/common/app-header'
import { AppMain } from 'components/common/app-main'
import { useAuthUser } from 'components/common/auth-context'
import { useVaultProjects } from 'components/vault/hooks/use-vault-projects'

import HistoryFilterRow from './history-filter-row'
import { useHistoryMetadataStore } from './history-metadata-store'
import HistoryStatus from './history-status'
import HistoryTable from './history-table'

const History = (): JSX.Element => {
  const userInfo = useAuthUser()

  React.useEffect(() => {
    const fetchData = async () => {
      await useHistoryMetadataStore.getState().fetchData()
    }
    void fetchData()
  }, [])

  // Load all vault projects for the user, without loading metadata
  useVaultProjects(undefined, {
    isEnabled: userInfo.IsVaultUser,
    loadAllMetadata: false,
  })

  const clientMatters = useClientMattersStore((s) => s.clientMatters)
  React.useEffect(() => {
    useHistoryMetadataStore
      .getState()
      .setCurrentWorkspaceClientMatters(clientMatters)
  }, [clientMatters])

  const { postAuditLog } = usePostAuditLog()

  useEffectOnce(() => {
    if (_.isNil(userInfo) || !userInfo.IsHistoryUser) {
      return
    }
    const timer = setTimeout(() => {
      void (async () => {
        try {
          await postAuditLog(
            AuditLogType.USERCLIENT_VIEW_HISTORY,
            userInfo.workspace.id,
            {}
          )
        } catch (error) {
          console.error('Error posting audit log:', error)
        }
      })()
    }, AUDIT_LOG_DEBOUNCE_TIME) // Delay the log posting by 1 second

    // Cleanup function to clear the timeout if the component unmounts before the timeout is reached
    return () => clearTimeout(timer)
  })

  if (_.isNil(userInfo) || !userInfo.IsHistoryUser) {
    return <></>
  }

  return (
    <AppMain
      data-testid="history-container"
      hasContainer
      containerClassName="flex h-full flex-col space-y-4"
    >
      <AppHeader
        title="History"
        subtitle="Browse your previous queries"
        actions={
          <div className="flex grow justify-end lg:hidden">
            <HistoryStatus
              type={HistoryTypeEnum.USER}
              workspace={userInfo.workspace}
            />
          </div>
        }
      />

      <div className="flex shrink-0 justify-end lg:justify-between">
        <div className="hidden lg:block">
          <HistoryStatus
            type={HistoryTypeEnum.USER}
            workspace={userInfo.workspace}
          />
        </div>
        <HistoryFilterRow
          type={HistoryTypeEnum.USER}
          workspace={userInfo.workspace}
        />
      </div>
      <HistoryTable
        type={HistoryTypeEnum.USER}
        workspace={userInfo.workspace}
      />
    </AppMain>
  )
}

export default History
