import pluralize from 'pluralize'

import { uploadFile } from 'api'
import { DiligenceDocument } from 'openapi/models/DiligenceDocument'
import { UploadedFile } from 'openapi/models/UploadedFile'

import { displayFileUploadError } from 'utils/dropzone'
import { createFileName } from 'utils/file-utils'

import {
  DiligenceActions,
  DiligenceState,
} from 'components/workflows/workflow/discovery/diligence-store'

export const newFileHandler = async (params: {
  files: File[]
  get: () => DiligenceState & DiligenceActions
}) => {
  const { files, get } = params

  const newFiles = prepareFiles(files, get)
  eagerlyAddDocuments(newFiles, get)

  const uploadedFiles = await uploadFiles(newFiles)
  handleUploadResults(uploadedFiles, get)
}

function prepareFiles(
  files: File[],
  get: () => DiligenceState & DiligenceActions
) {
  const existingNamesSet = new Set(
    // eslint-disable-next-line
    get().documents?.map((doc) => doc.file.name) ?? []
  )
  return files.map((file) => ({
    name: createFileName(file.name, existingNamesSet),
    file,
  }))
}

function eagerlyAddDocuments(
  newFiles: { name: string; file: File }[],
  get: () => DiligenceState & DiligenceActions
) {
  const documentsToAdd = newFiles.map(({ name, file }) =>
    getEagerDiligenceDocument(name, file.size)
  )
  get().setDocuments([...get().documents, ...documentsToAdd])
}

async function uploadFiles(newFiles: { name: string; file: File }[]) {
  return Promise.all(
    newFiles.map(async ({ name, file }) => ({
      name,
      file,
      uploadedFile: await uploadFile(file, true).catch(() => null),
    }))
  )
}

function handleUploadResults(
  /*
   * Handle the results of the file upload process once it returns from backend
   */
  uploadedFiles: {
    name: string
    file: File
    uploadedFile: UploadedFile | null
  }[],
  get: () => DiligenceState & DiligenceActions
) {
  // Find successful and rejected files
  const rejectedFiles = uploadedFiles.filter(
    ({ uploadedFile }) => uploadedFile === null
  )
  const successfulFiles = uploadedFiles.filter(
    ({ uploadedFile }) => uploadedFile !== null
  )

  // Handle rejected files with errors
  if (rejectedFiles.length > 0) {
    displayFileUploadError(
      rejectedFiles.map(({ file }) => file.name),
      formatUploadErrorMessage(rejectedFiles.length),
      uploadedFiles.length > 0
    )
    rejectedFiles.forEach(({ name }) => get().removeDocument(name))
  }

  // Get new documents with updated file properties
  const newDocuments = get().documents.map((oldDoc) => {
    const oneSuccessfulFile = successfulFiles.find(
      ({ name }) => oldDoc.file.name === name
    )

    // If no successful file uploaded, return the old document
    if (!oneSuccessfulFile) return oldDoc

    // Shallow copy the old DiligenceDoc and the doc.file, and spread out everything from the uploadedFile
    return {
      ...oldDoc,
      isLoading: false,
      file: {
        ...oldDoc.file,
        ...oneSuccessfulFile.uploadedFile!,
      },
    }
  })

  // Update documents
  get().setDocuments(newDocuments)
  get().setIsUploadingDocuments(false)
}

function formatUploadErrorMessage(rejectedFilesCount: number): string {
  return `${rejectedFilesCount} ${pluralize(
    'file',
    rejectedFilesCount
  )} failed to upload: `
}

function getEagerDiligenceDocument(
  fileName: string,
  fileSize: number
): DiligenceDocument {
  return {
    isLoading: true,
    file: {
      id: '',
      name: fileName,
      path: '',
      url: '',
      size: fileSize,
      pdfkitId: '',
    },
  }
}
