import React, { useCallback } from 'react'
import { useUnmount } from 'react-use'

import { TrashIcon } from 'lucide-react'
import { useShallow } from 'zustand/react/shallow'

import { Button } from 'components/ui/button'
import Icon from 'components/ui/icon/icon'
import { useCompetitiveAnalysisDataGridStore } from 'components/workflows/workflow/competitive-analysis/stores/competitive-analysis-data-grid-store'

const LeftHeaderActions = () => {
  return <div />
}

const RightHeaderActions = () => {
  const gridApi = useCompetitiveAnalysisDataGridStore(
    useShallow((s) => s.gridApi)
  )
  const selectedRows = useCompetitiveAnalysisDataGridStore(
    useShallow((s) => s.selectedRows)
  )
  const bulkRemoveSelectedRows = useCompetitiveAnalysisDataGridStore(
    useShallow((s) => s.bulkRemoveSelectedRows)
  )

  const hasSelectedRows = selectedRows.length > 0

  const handleDeleteSelectedRows = useCallback(() => {
    if (!gridApi) return
    const selectedData = gridApi.getSelectedRows()
    gridApi.applyTransaction({
      remove: selectedData,
    })
    bulkRemoveSelectedRows(selectedRows)
    gridApi.refreshCells()
  }, [bulkRemoveSelectedRows, gridApi, selectedRows])

  if (!hasSelectedRows) {
    return null
  }

  return (
    <div className="flex items-center gap-2">
      <Button
        size="sm"
        variant="destructive"
        className="flex items-center gap-1"
        onClick={handleDeleteSelectedRows}
      >
        <Icon icon={TrashIcon} size="small" />
        <p className="text-xs">Delete</p>
      </Button>
    </div>
  )
}

const DataGridHeader = () => {
  const clearSelectedRows = useCompetitiveAnalysisDataGridStore(
    useShallow((s) => s.clearSelectedRows)
  )

  useUnmount(() => {
    clearSelectedRows()
  })

  return (
    <div className="flex max-h-[49px] min-h-[49px] w-full shrink-0 items-center justify-between gap-2 border-b px-4 py-2">
      <LeftHeaderActions />
      <RightHeaderActions />
    </div>
  )
}

export default DataGridHeader
