import React, { MouseEvent } from 'react'

import _ from 'lodash'

import { ResearchArea } from 'openapi/models/ResearchArea'
import Services from 'services'

import { cn } from 'utils/utils'

import { useAnalytics } from 'components/common/analytics/analytics-context'
import Markdown from 'components/common/markdown/markdown'
import { Badge } from 'components/ui/badge'
import Citation from 'components/ui/citation'

import { SourceCardArtifactCitation } from './source-card-artifact-citation'

interface Props {
  area?: ResearchArea
  title: string
  snippets: SnippetInfo[]
  badges?: Badge[]
  metadata?: string[]
  blueTitle?: boolean
  number?: string
  useLongSnippets?: boolean
  useNewCitationStyle?: boolean
}

interface SnippetInfo {
  text: string
  citationNumber: number
  href?: string
  onClick?: () => void
}

interface Badge {
  text: string
  variant: 'default' | 'secondary' | 'destructive' | 'outline' | 'ghost'
}

export const SourceCardWithSnippets: React.FC<Props> = ({
  area,
  title,
  snippets,
  badges,
  metadata,
  blueTitle = false,
}) => {
  const [selectedSnippetIndex, setSelectedSnippetIndex] = React.useState(0)
  const { trackEvent } = useAnalytics()

  const href = snippets[selectedSnippetIndex].href
  const onClick = (e: MouseEvent<HTMLAnchorElement>) => {
    const highlightedText = window.getSelection()?.toString() ?? ''
    if (highlightedText.length) {
      e.preventDefault()
      return
    }
    Services.HoneyComb.Record({
      metric: 'ui.source_clicked',
      area,
      num_snippets: snippets.length,
    })
    trackEvent('Source Clicked', {
      research_area: area,
      num_snippets: snippets.length,
    })
    snippets[selectedSnippetIndex].onClick?.()
  }

  const isUsaCaselaw =
    area === ResearchArea.USACASELAW || area === ResearchArea.USCASELAW

  return (
    <div>
      <a
        href={href}
        target="_blank"
        rel="noreferrer"
        className="duration-50 group -ml-2 flex cursor-pointer select-text flex-col border-l-2 border-transparent pl-2 transition-all hover:border-primary"
        onClick={onClick}
        draggable={false}
        data-testid="source-item"
      >
        <div
          className="flex items-end justify-between"
          data-testid="assistant--source-card"
        >
          <div className="flex items-center">
            <p
              className={cn('mr-2 truncate font-semibold', {
                'text-primary': blueTitle,
              })}
            >
              {title}
            </p>
            {isUsaCaselaw && metadata && (
              <SourceCardArtifactCitation title={title} metadata={metadata} />
            )}
          </div>

          {!_.isNil(badges) && badges.length > 0 && (
            <div className="shrink-0 space-x-2">
              {badges.map((badge, index) => (
                <Badge
                  key={index}
                  variant={badge.variant}
                  className="text-nowrap"
                >
                  {badge.text}
                </Badge>
              ))}
            </div>
          )}
        </div>
        {!_.isNil(metadata) && metadata.length > 0 && (
          <div className="flex space-x-2 divide-x">
            {metadata.map((meta, index) => (
              <p key={index} className="pl-2 first:pl-0">
                {meta}
              </p>
            ))}
          </div>
        )}
        <Markdown
          className="prose-xs line-clamp-3 *:text-xs"
          content={snippets[selectedSnippetIndex].text}
        />
      </a>
      <div className="mt-1 flex items-center space-x-2">
        <p className="ml-0.5 shrink-0 text-xs text-muted">References</p>
        <div className="flex flex-wrap gap-1">
          {snippets.map((snippet, index) => (
            <Citation
              key={snippet.citationNumber}
              isSelected={selectedSnippetIndex === index}
              onClick={() => setSelectedSnippetIndex(index)}
            >
              {snippet.citationNumber}
            </Citation>
          ))}
        </div>
      </div>
    </div>
  )
}
