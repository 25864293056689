import { NavigateFunction } from 'react-router-dom'

import { driver, DriveStep } from 'driver.js'
import { create } from 'zustand'

import { UserInfo } from 'models/user-info'

import { waitForElement } from 'utils/utils'

import { TrackEventFunction } from 'components/common/analytics/analytics-context'
import useVaultQueryDetailStore from 'components/vault/query-detail/vault-query-detail-store'
import { useVaultStore } from 'components/vault/utils/vault-store'

type ProductTourState = {
  isTourActive: boolean
  startTour: () => void
  endTour: () => void
}

export const useVaultV2TourStore = create<ProductTourState>((set) => ({
  isTourActive: false,
  startTour: () => set({ isTourActive: true }),
  endTour: () => set({ isTourActive: false }),
}))

export const runVaultV2Tour = async (
  navigate: NavigateFunction,
  userInfo?: UserInfo,
  trackEvent?: TrackEventFunction
) => {
  useVaultV2TourStore.getState().startTour()

  const startRoute = window.location.pathname

  const vaultState = useVaultStore.getState()
  // Navigate to the first example project
  const firstProjectId = vaultState.exampleProjectIds.values().next().value

  navigate(`/vault/projects/${firstProjectId}`)

  await waitForElement('#vault-workflow-list')

  const steps: DriveStep[] = [
    {
      element: '#vault-workflow-list',
      popover: {
        title: 'Get started with a workflow',
        description:
          'Use one of Harvey‘s pre-built workflows to easily extract key information',
        onNextClick: async () => {
          driverObj.moveNext()
          trackEvent?.('Vault V2 Tour Next Clicked', {
            to_step: 'new-workflow-card',
            from_step: 'vault-workflow-list',
          })
        },
      },
    },
    {
      element: '#vault-new-workflow-card',
      popover: {
        title: 'Or start your own query',
        description: 'Ask a question or review files using your own questions',
        onNextClick: async () => {
          // Preselect all files in the project
          const projectFileIds =
            useVaultStore.getState().folderIdToVaultFileIds[firstProjectId] ||
            []
          useVaultQueryDetailStore
            .getState()
            .setPendingQueryFileIds(projectFileIds)

          // Navigate to new query page
          navigate(`/vault/projects/${firstProjectId}/queries/new`)
          await waitForElement('#add-column-button')
          driverObj.moveNext()
          trackEvent?.('Vault V2 Tour Next Clicked', {
            to_step: 'add-column-button',
            from_step: 'new-workflow-card',
          })
        },
      },
    },
    {
      element: '#add-column-button',
      popover: {
        title: 'Add a new column',
        description: 'To ask a question over all your documents, add a column.',
        onNextClick: async () => {
          const addColumnButton = document.querySelector('#add-column-button')
          if (addColumnButton) {
            ;(addColumnButton as HTMLElement).click()
          }
          await waitForElement('#column-editor')
          driverObj.moveNext()
          trackEvent?.('Vault V2 Tour Next Clicked', {
            to_step: 'column-editor',
            from_step: 'add-column-button',
          })
        },
        onPrevClick: async () => {
          navigate(`/vault/projects/${firstProjectId}`)
          await waitForElement('#vault-new-workflow-card')
          driverObj.movePrevious()
        },
      },
    },
    {
      element: '#column-editor',
      popover: {
        title: 'Ask your question',
        description:
          'Enter a question and click Continue. Harvey will automatically suggest a column type and label.',
        onNextClick: async () => {
          const cancelButton = document.querySelector(
            '#column-editor-cancel-button'
          )
          if (cancelButton) {
            ;(cancelButton as HTMLElement).click()
          }
          driverObj.moveNext()
          trackEvent?.('Vault V2 Tour Next Clicked', {
            to_step: 'column-builder',
            from_step: 'column-editor',
          })
        },
        onPrevClick: async () => {
          const cancelButton = document.querySelector(
            '#column-editor-cancel-button'
          )
          if (cancelButton) {
            ;(cancelButton as HTMLElement).click()
          }
          await waitForElement('#add-column-button')
          driverObj.movePrevious()
        },
      },
    },
    {
      element: '#column-builder',
      popover: {
        title: 'Use the Column builder',
        description:
          'Quickly create multiple columns at once based off a single prompt.',
        onNextClick: async () => {
          const columnBuilderButton = document.querySelector('#column-builder')
          if (columnBuilderButton) {
            ;(columnBuilderButton as HTMLElement).click()
          }
          await waitForElement('#column-builder-dialog')
          driverObj.moveNext()
          trackEvent?.('Vault V2 Tour Next Clicked', {
            to_step: 'column-builder-dialog',
            from_step: 'column-builder',
          })
        },
        onPrevClick: async () => {
          const addColumnButton = document.querySelector('#add-column-button')
          if (addColumnButton) {
            ;(addColumnButton as HTMLElement).click()
          }
          await waitForElement('#column-editor')
          driverObj.movePrevious()
        },
      },
    },
    {
      element: '#column-builder-dialog',
      popover: {
        title: 'Input your prompt',
        description:
          'After entering your prompt, Harvey will suggest columns for you.',
        onNextClick: async () => {
          useVaultStore.getState().setIsColumnBuilderDialogOpen(false)
          handleComplete()
        },
        onPrevClick: async () => {
          useVaultStore.getState().setIsColumnBuilderDialogOpen(false)
          driverObj.movePrevious()
        },
      },
    },
  ]

  const handleComplete = () => {
    closeVaultV2Tour()
    trackEvent?.('Vault V2 Tour Completed')
  }

  const handleClose = () => {
    closeVaultV2Tour()
    trackEvent?.('Vault V2 Tour Closed')
  }

  const closeVaultV2Tour = () => {
    useVaultV2TourStore.getState().endTour()
    driverObj.destroy()
    navigate(startRoute)
  }

  const driverObj = driver({
    showProgress: true,
    steps,
    disableActiveInteraction: true,
    onCloseClick: handleClose,
    onDestroyStarted: async () => {
      if (!driverObj.hasNextStep()) {
        closeVaultV2Tour()
      }
    },
    popoverClass: 'vault-v2-tour',
  })

  driverObj.drive()
}
