import { ResearchFilter } from 'openapi/models/ResearchFilter'

export enum TopLevelJurisdiction {
  Federal = 'federal-courts',
  State = 'state-courts',
}

export const JURISDICTION_TITLE: Record<TopLevelJurisdiction, string> = {
  [TopLevelJurisdiction.Federal]: 'Federal courts',
  [TopLevelJurisdiction.State]: 'State courts',
}

export const isFederal = (filter: ResearchFilter): boolean => {
  return filter.id.includes(TopLevelJurisdiction.Federal)
}

export const isState = (filter: ResearchFilter): boolean => {
  return filter.id.includes(TopLevelJurisdiction.State)
}
