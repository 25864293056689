import React from 'react'

import { ContractsCustomTerm } from 'openapi/models/ContractsCustomTerm'
import { ContractsDocument } from 'openapi/models/ContractsDocument'

import { displayWarningMessage } from 'utils/toast'
import { IS_LOADING_HELP_TEXT } from 'utils/tooltip-texts'

import AskHarveyButton from 'components/common/ask-harvey-button'
import {
  Sheet,
  SheetContent,
  SheetFooter,
  SheetHeader,
  SheetTitle,
} from 'components/ui/sheet'
import {
  GroupedTerms,
  groupTermsByType,
} from 'components/workflows/workflow/contracts/utils/utils'

import TermGroup from './term-group'

type Props = {
  documents: ContractsDocument[]
  isLoading: boolean
  selectedTerms: GroupedTerms
  setSelectedTerms: (terms: GroupedTerms) => void
  runExtraction: () => void
  showSheet: boolean
  setShowSheet: (show: boolean) => void
  customTerms: ContractsCustomTerm[]
  setCustomTerms: (terms: ContractsCustomTerm[]) => void
  handleTermAdd: (type: string, term: string) => void
}

export const ExtractTermsSheet: React.FC<Props> = ({
  documents,
  selectedTerms,
  setSelectedTerms,
  runExtraction,
  showSheet,
  setShowSheet,
  customTerms,
  handleTermAdd,
}) => {
  const specializedTerms = groupTermsByType(documents, customTerms)
  const isLoading = customTerms.some((term) => term.isLoading)

  const handleTermCheckedChange = (type: string, term: string) => {
    const updatedTerms = selectedTerms[type] ? [...selectedTerms[type]] : []
    if (updatedTerms.includes(term)) {
      // Remove term if it's already selected
      setSelectedTerms({
        ...selectedTerms,
        [type]: updatedTerms.filter((t) => t !== term),
      })
    } else {
      // Add term if it's not selected
      setSelectedTerms({
        ...selectedTerms,
        [type]: updatedTerms.concat(term),
      })
    }
  }

  const localHandleTermAdd = async (type: string, term: string) => {
    if (specializedTerms[type].includes(term)) {
      displayWarningMessage(
        `Term “${term}” already exists for ${type} contract type.`
      )
      return false
    }

    try {
      handleTermAdd(type, term)
      return true
    } catch (error) {
      displayWarningMessage(`Failed to add term “${term}”.`)
      return false
    }
  }

  const noTermsSelected = Object.values(selectedTerms).every(
    (terms) => terms.length === 0
  )
  const askHarveyDisabled = isLoading || noTermsSelected

  const getButtonTooltip = () => {
    if (isLoading) {
      return IS_LOADING_HELP_TEXT
    } else if (noTermsSelected) {
      return 'Select at least one term to extract'
    } else {
      return ''
    }
  }

  return (
    <Sheet
      onOpenChange={(value: boolean) => setShowSheet(value)}
      open={showSheet}
      key="extract-terms"
    >
      <SheetContent className="w-full sm:max-w-2xl">
        <SheetHeader>
          <SheetTitle>Select terms to extract</SheetTitle>
        </SheetHeader>
        <div className="mb-12 space-y-4 p-6">
          {Object.entries(specializedTerms).map(([type, terms]) => (
            <TermGroup
              key={type}
              type={type}
              terms={terms}
              selectedTerms={selectedTerms}
              setSelectedTerms={setSelectedTerms}
              handleTermAdd={localHandleTermAdd}
              handleTermCheckedChange={handleTermCheckedChange}
            />
          ))}
        </div>
        <SheetFooter>
          <AskHarveyButton
            handleSubmit={() => {
              setShowSheet(false)
              runExtraction()
            }}
            disabled={askHarveyDisabled}
            tooltip={getButtonTooltip()}
            isLoading={isLoading}
          />
        </SheetFooter>
      </SheetContent>
    </Sheet>
  )
}
