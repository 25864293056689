import _ from 'lodash'

// TODO(Adam): Add unit tests for these utils

type ArrayElement = {
  id?: string
  modifiedAt?: Date
  createdAt?: Date
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any // Allows for any other property
}

type GetIdFunction<T> = (element: T) => string | undefined

interface MergeFuncParams<T extends ArrayElement> {
  oldArr: T[]
  newArr: T[]
  getId: GetIdFunction<T>
  createdKey: string
  modifiedKey: string
}

interface DedupeFuncParams<T extends ArrayElement> {
  elements: T[]
  getId?: GetIdFunction<T>
  createdKey?: string
  modifiedKey?: string
}

const defaultGetId = (element: ArrayElement) => element.id

/**
 * @returns the subarray (of an array of arrays) that is rightmost and non-empty, along with its index
 */
const getRightmostNonEmptySubarrayAndIndex = <T>(
  arrayOfArrays: T[][]
): [T[] | undefined, number] => {
  const index = _.findLastIndex(
    arrayOfArrays,
    (subArray) => !_.isEmpty(subArray)
  )

  if (index !== -1) {
    return [arrayOfArrays[index], index]
  } else {
    return [undefined, -1]
  }
}

/**
 * @returns the subarray (of an array of arrays) that is leftmost and non-empty, along with its index
 */
const getLeftmostNonEmptySubarrayAndIndex = <T>(
  arrayOfArrays: T[][]
): [T[] | undefined, number] => {
  const index = _.findIndex(arrayOfArrays, (subArray) => !_.isEmpty(subArray))

  if (index !== -1) {
    return [arrayOfArrays[index], index]
  } else {
    return [undefined, -1]
  }
}

/**
 * Function that takes two arrays and merges by preferring the new value over the old value
 */
const combineArrays = <T extends ArrayElement>({
  oldArr,
  newArr,
  getId = defaultGetId,
  createdKey = 'createdAt',
  modifiedKey = 'modifiedAt',
}: MergeFuncParams<T>): T[] => {
  const sortByCreated = (a: T, b: T) => a[createdKey]! - b[createdKey]!
  const sortByModified = (a: T, b: T) => a[modifiedKey]! - b[modifiedKey]!

  const merged: T[] = [...oldArr]

  // Create a map for quick ID-to-index lookup in oldArr
  const indexMap = new Map<string, number>()
  oldArr.forEach((element, index) => {
    const id = getId(element)
    if (id) {
      indexMap.set(id, index)
    }
  })

  newArr.sort(sortByModified)

  // put new elements into merged array
  newArr.forEach((newElement) => {
    const id = getId(newElement)
    if (!_.isNil(id) && indexMap.has(id)) {
      const value = indexMap.get(id) as number
      merged[value] = newElement
    } else {
      // If the ID does not exist, add the new element and update the index map
      // TODO: What if the item is older than other items in the cache? Since this is not a paginated fetch I think that needs to be handled by caller
      if (id) {
        indexMap.set(id, merged.length)
      }
      merged.push(newElement)
    }
  })

  merged.sort(sortByCreated)

  return merged
}

const deduplicateByLastModified = <T extends ArrayElement>({
  elements,
  getId = defaultGetId,
  modifiedKey = 'modifiedAt',
  createdKey = 'createdAt',
}: DedupeFuncParams<T>): T[] => {
  if (_.isEmpty(elements)) {
    return elements
  }

  const elementsMap = new Map<string, T>()

  elements.forEach((element) => {
    if (_.isNil(element) || _.isNil(getId(element))) {
      return // Skip if no id is provided or if the element is null
    } else {
      const id = getId(element) as string
      const existingElement = elementsMap.get(id)
      const existingFreshness = _.get(existingElement, modifiedKey, null)
      const elementFreshness = _.get(element, modifiedKey, null)
      if (
        _.isNil(existingElement) ||
        (!_.isNil(existingFreshness) &&
          !_.isNil(elementFreshness) &&
          existingFreshness < elementFreshness)
      ) {
        elementsMap.set(id, element)
      }
    }
  })

  // Convert map values to an array and sort by createdAt, assuming createdAt exists and is a Date.
  const uniqueEvents = Array.from(elementsMap.values())
  const sortByCreated = (a: T, b: T) => {
    const dateA = new Date(a[createdKey]!).getTime()
    const dateB = new Date(b[createdKey]!).getTime()
    return dateB - dateA
  }
  const sortedUniqueEvents = uniqueEvents.sort(sortByCreated)
  return sortedUniqueEvents
}

/**
 * Filters out data that is past its expiration date according to the retention policy.
 *
 * @param {Array} data - The array of data elements to filter.
 * @param {Number} retentionPolicy - The retention policy duration in milliseconds.
 * @param {Date} currentTime - The current time for comparison.
 * @param {String} createdKey - The key in data elements that holds the creation date.
 * @returns {Array} - Filtered data array with elements still within the retention period.
 */
const evictOldData = ({
  data,
  retentionPolicy,
  currentTime,
  createdKey,
  deletedKey,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any[]
  retentionPolicy: number
  currentTime: Date
  createdKey: string
  deletedKey?: string
}) => {
  return data.filter((element) => {
    const createdTime = new Date(element[createdKey]).getTime()
    const age = currentTime.getTime() - createdTime
    return age <= retentionPolicy && (!deletedKey || !element[deletedKey])
  })
}

// exports
export type { MergeFuncParams, DedupeFuncParams, ArrayElement, GetIdFunction }
export {
  combineArrays,
  deduplicateByLastModified,
  getRightmostNonEmptySubarrayAndIndex,
  getLeftmostNonEmptySubarrayAndIndex,
  evictOldData,
}
