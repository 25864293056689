import React, { useEffect } from 'react'

import { isNil } from 'lodash'

import { useSharingStore } from 'stores/sharing-store'
import {
  PermissionLevel,
  PermissionsByUser,
  PermissionsByWorkspace,
  ShareType,
} from 'types/sharing'

import { useEventSharing } from 'hooks/use-event-sharing'

import { useAssistantStore } from 'components/assistant/stores/assistant-store'
import { isVaultKnowledgeSource } from 'components/assistant/utils/assistant-knowledge-sources'
import SharePopover from 'components/common/sharing/share-popover'

const AVAILABLE_PERMISSIONS = [PermissionLevel.VIEW]

const AssistantShareMenu = () => {
  const eventOwnerUserEmail = useAssistantStore(
    (state) => state.eventOwnerUserEmail
  )
  const knowledgeSource = useAssistantStore((state) => state.knowledgeSource)
  const isEventOwner = useAssistantStore((state) => state.isEventOwner)
  const eventId = useAssistantStore((state) => state.eventId)
  const setAvailablePermissions = useSharingStore(
    (s) => s.setAvailablePermissions
  )

  // Set available permissions to view only for now
  useEffect(() => {
    setAvailablePermissions(AVAILABLE_PERMISSIONS)

    return () => {
      // Reset available permissions
      setAvailablePermissions(Object.values(PermissionLevel))
    }
  })

  const getDisabledStatus = () => {
    if (isNil(eventId)) {
      return {
        disabled: true,
        tooltip: 'Sharing is not available for this event',
      }
    } else if (isVaultKnowledgeSource(knowledgeSource)) {
      return {
        disabled: true,
        tooltip:
          'Sharing is not available for events with Vault knowledge source',
      }
    }
    // TODO: Other disabled states?
    return { disabled: false, tooltip: undefined }
  }

  const { disabled, tooltip: disabledTooltip } = getDisabledStatus()

  const {
    grantUserPermissions,
    changeSharedWithWorkspace,
    updateSharePermissions,
    sharingStatus,
  } = useEventSharing(eventId)

  return (
    <SharePopover
      shareType={ShareType.EVENT}
      isReadOnly={!isEventOwner}
      ownerUserEmail={eventOwnerUserEmail}
      permissionsByUser={
        (sharingStatus?.permissionsByUser as PermissionsByUser) || []
      }
      permissionsByWorkspace={
        (sharingStatus?.permissionsByWorkspace as PermissionsByWorkspace) || []
      }
      onChangeSharedWithWorkspace={changeSharedWithWorkspace}
      onGrantUserPermissions={grantUserPermissions}
      onUpdateSharePermissions={updateSharePermissions}
      disabled={disabled}
      disabledTooltip={disabledTooltip}
    />
  )
}

export default AssistantShareMenu
