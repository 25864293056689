import React, { useMemo, useRef, useState } from 'react'
import { useMount } from 'react-use'

import _ from 'lodash'
import { debounce } from 'lodash'
import { ListFilter, Search, Star } from 'lucide-react'
import { useShallow } from 'zustand/react/shallow'

import { EventKind } from 'openapi/models/EventKind'
import { LibraryVisbilityScope } from 'openapi/models/LibraryVisbilityScope'
import Services from 'services'

import {
  groupTaskTypeEntries,
  TaskDefinitions,
  taskLabelLookup,
} from 'utils/task-definitions'
import { displayErrorMessage } from 'utils/toast'
import { cn } from 'utils/utils'

import {
  useAnalytics,
  FILTER_CHANGED_EVENT_NAME,
} from 'components/common/analytics/analytics-context'
import { useAuthUser } from 'components/common/auth-context'
import { Badge } from 'components/ui/badge'
import { Button } from 'components/ui/button'
import { Input } from 'components/ui/input'
import { Label } from 'components/ui/label'
import { MultiSelect } from 'components/ui/multi-select'
import {
  Popover,
  PopoverContent,
  PopoverMenuItem,
  PopoverTrigger,
} from 'components/ui/popover'
import { ScrollArea } from 'components/ui/scroll-area'
import Skeleton from 'components/ui/skeleton'
import { Switch } from 'components/ui/switch'
import { Tabs, TabsList, TabsTrigger } from 'components/ui/tabs'

import {
  useGetLibraryItemDeleted,
  useLibraryMetadataStore,
} from './library-metadata-store'
import { LibraryItemKind, Prompt } from './library-types'
import {
  getCategories,
  sortByStarred,
  getPrivateItems,
  getTeamItems,
  getHarveyItems,
  VISIBILITY_SCOPE_TITLES,
  getPracticeAreas,
} from './library.helpers'
import { useLibraryData } from './use-library-data'
import { useVisibilityScope } from './use-visibility-scope'

const LOAD_PROMPT_MENU_ENTRY_POINT = 'library load prompt menu'

interface LoadPromptButtonProps {
  onPopoverOpen?: (isOpen: boolean) => void
  setQuery: (query: string) => void
  setQueryPreview?: (query: string) => void
  triggerComponent: (
    disabled: boolean | undefined,
    tooltipText: string
  ) => React.ReactNode
  eventKind?: EventKind
  disabled?: boolean
}

const promptGroups = {
  private: {
    id: LibraryVisbilityScope.PRIVATE,
    name: 'Private',
  },
  team: {
    id: LibraryVisbilityScope.WORKSPACE,
    name: 'Team',
  },
  harvey: {
    id: LibraryVisbilityScope.HARVEY,
    name: 'Harvey',
  },
}

const LoadPromptButton: React.FC<LoadPromptButtonProps> = ({
  onPopoverOpen,
  setQuery,
  setQueryPreview,
  triggerComponent,
  eventKind,
  disabled,
}) => {
  const userInfo = useAuthUser()
  const { trackEvent } = useAnalytics()
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)
  const [isStarredOnly, setIsStarredOnly] = useState(false)
  const [filterCount, setFilterCount] = useState(0)
  const [selectedTaskTypes, setSelectedTaskTypes] = useState<string[]>([])
  const [selectedCategories, setSelectedCategories] = useState<string[]>([])
  const [selectedPracticeAreas, setSelectedPracticeAreas] = useState<string[]>(
    []
  )
  const [search, setSearch] = useState('')
  const [getFavoriteStatus, getHiddenStatus, updateLibraryItemFavorite] =
    useLibraryMetadataStore(
      useShallow((s) => [
        s.getFavoriteStatus,
        s.getHiddenStatus,
        s.updateLibraryItemFavorite,
      ])
    )
  const getLibraryItemDeleted = useGetLibraryItemDeleted()

  const { items, isLoading } = useLibraryData(LibraryItemKind.PROMPT, {
    isEnabled: isPopoverOpen,
  })

  const taskLookup = taskLabelLookup(TaskDefinitions(userInfo))

  const initialTab = userInfo.IsLibraryPrivatePromptUser
    ? LibraryVisbilityScope.PRIVATE
    : LibraryVisbilityScope.WORKSPACE
  const [selectedTab, setSelectedTab] = useVisibilityScope({
    initialScope: initialTab,
    items,
  })

  useMemo(() => {
    setFilterCount(
      _.compact([
        isStarredOnly,
        selectedCategories.length > 0,
        selectedPracticeAreas.length > 0,
        selectedTaskTypes.length > 0,
      ]).length
    )
  }, [
    isStarredOnly,
    selectedCategories.length,
    selectedPracticeAreas.length,
    selectedTaskTypes.length,
  ])

  const starredFirst = (a: Prompt, b: Prompt): number => {
    return sortByStarred(a, b, getFavoriteStatus)
  }
  const privatePrompts = getPrivateItems(items)
  const teamPrompts = getTeamItems(items)
  const harveyPrompts = getHarveyItems(items)
  const numPrompts =
    privatePrompts.length + teamPrompts.length + harveyPrompts.length

  let buttonTooltipText = ''
  if (disabled) {
    buttonTooltipText = 'Loading prompts is disabled'
  } else {
    buttonTooltipText = 'Load a prompt from Library'
  }

  const eventKinds = [EventKind.ASSISTANT_CHAT, EventKind.ASSISTANT_DRAFT].map(
    (eventKind) => ({
      text: taskLookup[eventKind],
      value: taskLookup[eventKind],
    })
  )

  const { sortedTaskTypes, sortedGroups } = groupTaskTypeEntries(
    eventKinds,
    userInfo
  )

  const categories = getCategories(items)
  const practiceAreas = getPracticeAreas(items)
  useMount(() => {
    setSelectedTaskTypes([
      taskLookup[EventKind.ASSISTANT_CHAT],
      taskLookup[EventKind.ASSISTANT_DRAFT],
    ])
  })

  const handlePopoverOpenChange = (isOpen: boolean) => {
    if (isOpen) {
      setSearch('')
      trackEvent('Load Prompt Menu Opened', { entry_point: eventKind })
    } else {
      // Always clear the preview query when the menu is closed
      handlePromptPreview('')
    }
    setIsPopoverOpen(isOpen)
    if (onPopoverOpen) {
      onPopoverOpen(isOpen)
    }
  }

  const handleFiltersOpenChange = (isOpen: boolean) => {
    if (isOpen) trackEvent('Load Prompt Additional Filters Opened')
  }

  const focusOnClose = useRef(true)
  const handleCloseAutoFocus = (e: Event) => {
    if (!focusOnClose.current) e.preventDefault()
  }
  const handleEscapeKeyDown = () => {
    focusOnClose.current = true
  }

  const toggleSelectedTab = (value: string) => {
    Services.HoneyComb.Record({
      metric: 'ui.load_prompt_dialog_tab_toggle',
      selected_tab: value,
    })
    trackEvent('Library Load Prompt Tab Selected', {
      selected_tab: value,
    })
    setSelectedTab(value as LibraryVisbilityScope)
    setSelectedCategories([])
    setSelectedPracticeAreas([])
  }

  const handleSelectPrompt = (prompt: Prompt) => {
    Services.HoneyComb.Record({
      metric: 'ui.load_prompt_dialog_prompt_load',
      task_type: taskLookup[prompt.eventKind],
      prompt_id: prompt.id,
      starred: prompt.starred,
      workspace_id: prompt.workspaceId,
      user_id: prompt.userId,
      visibility_scope: prompt.visibilityScope,
      categories: prompt.categories,
      practice_areas: prompt.practiceAreas,
      document_types: prompt.documentTypes,
    })
    trackEvent('Library Item Loaded', {
      kind: LibraryItemKind.PROMPT,
      starred: prompt.starred,
      visibility_scope: prompt.visibilityScope,
      entry_point: LOAD_PROMPT_MENU_ENTRY_POINT,
      in_new_tab: false,
    })
    setIsPopoverOpen(false)
    if (!prompt.eventId) {
      return displayErrorMessage('No library item ID found')
    }
    focusOnClose.current = false
    setQuery(prompt.query)
  }

  const handlePromptPreview = (preview: string) => {
    if (setQueryPreview) setQueryPreview(preview)
  }

  const handlePromptMouseFocus = (
    e: React.MouseEvent<HTMLButtonElement>,
    focus: boolean
  ) => {
    if (focus) e.currentTarget.focus()
    else e.currentTarget.blur()
  }

  const handleToggleFavorite = async (e: React.MouseEvent, prompt: Prompt) => {
    e.stopPropagation()
    e.preventDefault()
    const newValue = !getFavoriteStatus(prompt)
    Services.HoneyComb.Record({
      metric: 'ui.load_prompt_dialog_favorite_toggle',
      task_type: taskLookup[prompt.eventKind],
      prompt_id: prompt.id,
      starred: newValue,
      workspace_id: prompt.workspaceId,
      user_id: prompt.userId,
      visibility_scope: prompt.visibilityScope,
      categories: prompt.categories,
      practice_areas: prompt.practiceAreas,
      document_types: prompt.documentTypes,
    })
    trackEvent('Library Item Starred', {
      item_id: prompt.id,
      event_id: prompt.eventId,
      kind: prompt.kind,
      task_type: prompt.eventKind,
      categories: prompt.categories,
      practice_areas: prompt.practiceAreas,
      document_types: prompt.documentTypes,
      visibility_scope: prompt.visibilityScope,
      starred: newValue,
      entry_point: LOAD_PROMPT_MENU_ENTRY_POINT,
    })
    await updateLibraryItemFavorite(prompt.id, newValue)
  }

  const debouncedRecordSearchChange = debounce((value: string) => {
    Services.HoneyComb.Record({
      metric: 'ui.load_prompt_dialog_search_change',
      value,
    })
    trackEvent('Library Search Query Changed', {
      value: value,
      kind: LibraryItemKind.PROMPT,
      entry_point: LOAD_PROMPT_MENU_ENTRY_POINT,
    })
  }, 300) // Adjust the debounce delay as needed

  const handleSearchChange = async (value: string) => {
    debouncedRecordSearchChange(value)
    setSearch(value)
  }

  const handleTaskTypesChange = async (taskTypes: string[]) => {
    Services.HoneyComb.Record({
      metric: 'ui.load_prompt_dialog_task_types_change',
      task_types: taskTypes,
    })
    trackEvent(FILTER_CHANGED_EVENT_NAME, {
      entity_name: LOAD_PROMPT_MENU_ENTRY_POINT,
      field_changed: 'task type',
      kind: LibraryItemKind.PROMPT,
      task_types: taskTypes,
    })
    setSelectedTaskTypes(taskTypes)
  }

  const handleCategoryChange = async (categories: string[]) => {
    Services.HoneyComb.Record({
      metric: 'ui.load_prompt_dialog_categories_change',
      categories,
    })
    trackEvent(FILTER_CHANGED_EVENT_NAME, {
      entity_name: LOAD_PROMPT_MENU_ENTRY_POINT,
      field_changed: 'category',
      kind: LibraryItemKind.PROMPT,
      categories: categories,
      entry_point: LOAD_PROMPT_MENU_ENTRY_POINT,
    })
    setSelectedCategories(categories)
  }

  const handlePracticeAreaChange = async (practiceAreas: string[]) => {
    Services.HoneyComb.Record({
      metric: 'ui.load_prompt_dialog_practice_areas_change',
      practice_areas: practiceAreas,
    })
    trackEvent(FILTER_CHANGED_EVENT_NAME, {
      entity_name: LOAD_PROMPT_MENU_ENTRY_POINT,
      field_changed: 'practice area',
      kind: LibraryItemKind.PROMPT,
      practice_areas: practiceAreas,
      entry_point: LOAD_PROMPT_MENU_ENTRY_POINT,
    })
    setSelectedPracticeAreas(practiceAreas)
  }

  const handleStarredOnlyChange = (checked: boolean) => {
    setIsStarredOnly(checked)
    trackEvent(FILTER_CHANGED_EVENT_NAME, {
      entity_name: 'library table',
      field_changed: 'starred',
      starred: checked,
    })
  }

  const prompts = useMemo(
    () => {
      if (!isPopoverOpen) {
        return []
      }
      const visiblePrompts =
        selectedTab === promptGroups.team.id
          ? teamPrompts
          : selectedTab === promptGroups.harvey.id
          ? harveyPrompts
          : privatePrompts

      const isNotDeleted = (prompt: Prompt) => !getLibraryItemDeleted(prompt)
      // Hidden items are already filtered for non-admins from the response.
      // This filter is only to show the same visible-only items to admins.
      const isNotHidden = (prompt: Prompt) => !getHiddenStatus(prompt)

      const matchesFilters = (prompt: Prompt) => {
        const promptEventKind =
          prompt.eventKind === EventKind.ASSISTANT_DRAFT
            ? EventKind.ASSISTANT_DRAFT
            : EventKind.ASSISTANT_CHAT

        return (
          (isStarredOnly ? getFavoriteStatus(prompt) : true) &&
          (selectedTaskTypes.length === 0 ||
            selectedTaskTypes.includes(taskLookup[promptEventKind])) &&
          (selectedCategories.length === 0 ||
            selectedCategories.some((category) =>
              prompt.categories.includes(category)
            )) &&
          (selectedPracticeAreas.length === 0 ||
            selectedPracticeAreas.some((practiceArea) =>
              prompt.practiceAreas.includes(practiceArea)
            ))
        )
      }

      const matchesSearch = (prompt: Prompt) =>
        prompt.name.toLowerCase().includes(search.toLowerCase()) ||
        prompt.query.toLowerCase().includes(search.toLowerCase())

      return (visiblePrompts as Prompt[]) // TODO: Cast this type in useLibraryData
        .filter(
          (prompt) =>
            isNotDeleted(prompt) &&
            isNotHidden(prompt) &&
            matchesSearch(prompt) &&
            matchesFilters(prompt)
        )
        .sort(starredFirst)
    },
    // We want to keep the order of prompts until the user navigates away.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      isPopoverOpen,
      isStarredOnly,
      numPrompts,
      search,
      selectedCategories,
      selectedPracticeAreas,
      selectedTab,
      selectedTaskTypes,
    ]
  )

  const tabSentenceCase =
    selectedTab === LibraryVisbilityScope.HARVEY
      ? VISIBILITY_SCOPE_TITLES[selectedTab]
      : VISIBILITY_SCOPE_TITLES[selectedTab].toLowerCase()
  const isFiltered =
    isStarredOnly ||
    search.length > 0 ||
    selectedCategories.length > 0 ||
    selectedPracticeAreas.length > 0

  return (
    <Popover open={isPopoverOpen} onOpenChange={handlePopoverOpenChange}>
      <PopoverTrigger disabled={disabled} asChild>
        {triggerComponent(disabled, buttonTooltipText)}
      </PopoverTrigger>
      <PopoverContent
        className="flex h-80 w-[484px] flex-col p-0"
        align="start"
        onClick={(e) => e.stopPropagation()}
        onCloseAutoFocus={handleCloseAutoFocus}
        onEscapeKeyDown={handleEscapeKeyDown}
      >
        <div className="flex shrink-0 items-center space-x-1 border-b px-3">
          <Search className="size-4 shrink-0 text-muted" />
          <Input
            className="grow border-0 p-1 focus-visible:ring-0 focus-visible:ring-offset-0"
            placeholder="Search for a prompt"
            onChange={(e) => handleSearchChange(e.target.value)}
          />
        </div>
        <div className="flex shrink-0 items-center justify-between border-b bg-primary px-2 py-1">
          <Tabs
            value={selectedTab}
            onValueChange={toggleSelectedTab}
            className="h-7"
          >
            <TabsList className="h-fit" data-testid="prompt-tab-filters">
              {userInfo.IsLibraryPrivatePromptUser && (
                <TabsTrigger
                  className="px-1.5 py-0.5 text-xs"
                  value={promptGroups.private.id}
                  tooltip="Prompts created by you for your own personal use"
                >
                  {promptGroups.private.name}
                </TabsTrigger>
              )}
              <TabsTrigger
                className="px-1.5 py-0.5 text-xs"
                value={promptGroups.team.id}
                tooltip="Prompts created by your admin for everyone"
              >
                {promptGroups.team.name}
              </TabsTrigger>
              <TabsTrigger
                className="px-1.5 py-0.5 text-xs"
                value={promptGroups.harvey.id}
                tooltip="Prompts created by Harvey"
              >
                {promptGroups.harvey.name}
              </TabsTrigger>
            </TabsList>
          </Tabs>
          <Popover onOpenChange={handleFiltersOpenChange}>
            <PopoverTrigger asChild>
              <Button
                size="sm"
                variant="ghost"
                className={cn(
                  'group flex shrink-0 items-center justify-between',
                  {
                    'text-muted': filterCount === 0,
                  }
                )}
              >
                <ListFilter size={12} className="mr-1 opacity-50" />
                <span className="text-xs">Filter</span>
                {filterCount > 0 && (
                  <Badge
                    variant="secondary"
                    className="ml-1 size-4 justify-center bg-button-secondary p-0 text-2xs group-hover:bg-button-secondary-hover"
                  >
                    {filterCount}
                  </Badge>
                )}
              </Button>
            </PopoverTrigger>
            <PopoverContent align="end" className="flex flex-col space-y-2 p-4">
              <Label className="my-0.5">Additional filters</Label>
              <div className="flex h-8 flex-row-reverse items-center justify-between pt-2">
                <Switch
                  checked={isStarredOnly}
                  onCheckedChange={handleStarredOnlyChange}
                  id="prompt-starred-only"
                />
                <Label
                  className="font-normal text-muted"
                  htmlFor="prompt-starred-only"
                >
                  Starred
                </Label>
              </div>
              <ItemSelect
                label="Type"
                selectedValues={selectedTaskTypes}
                setSelectedValues={handleTaskTypesChange}
                sortedEntries={sortedTaskTypes}
                sortedGroups={sortedGroups}
              />
              {(selectedTab === LibraryVisbilityScope.WORKSPACE ||
                selectedTab === LibraryVisbilityScope.HARVEY) && (
                <>
                  <ItemSelect
                    label="Category"
                    selectedValues={selectedCategories}
                    setSelectedValues={handleCategoryChange}
                    sortedEntries={categories.map((category) => ({
                      text: category,
                      value: category,
                    }))}
                  />
                  <ItemSelect
                    label="Practice area"
                    selectedValues={selectedPracticeAreas}
                    setSelectedValues={handlePracticeAreaChange}
                    sortedEntries={practiceAreas.map((practiceArea) => ({
                      text: practiceArea,
                      value: practiceArea,
                    }))}
                  />
                </>
              )}
            </PopoverContent>
          </Popover>
        </div>
        <ScrollArea className="p-1">
          {prompts.length > 0 ? (
            prompts.map((prompt) => (
              <PopoverMenuItem
                key={prompt.id}
                className="group"
                onFocus={() => handlePromptPreview(prompt.query)}
                onBlur={() => handlePromptPreview('')}
                onMouseEnter={(e) => handlePromptMouseFocus(e, true)}
                onMouseLeave={(e) => handlePromptMouseFocus(e, false)}
                onClick={() => handleSelectPrompt(prompt)}
              >
                <span className="line-clamp-1 max-w-[92%] text-left text-sm">
                  {prompt.name}
                </span>
                <Button
                  className="m-0 flex size-5 p-0 hover:bg-button-secondary-hover"
                  variant="ghost"
                >
                  <Star
                    className={cn(
                      'h-4 w-4 shrink-0 rounded transition-opacity',
                      {
                        'fill-gold stroke-gold': getFavoriteStatus(prompt),
                        'stroke-primary opacity-0 focus:opacity-100 group-hover:opacity-100':
                          !getFavoriteStatus(prompt),
                      }
                    )}
                    onClick={(e) => handleToggleFavorite(e, prompt)}
                  />
                </Button>
              </PopoverMenuItem>
            ))
          ) : isLoading ? (
            <div className="p-2">
              <Skeleton rowHeight="h-4" />
            </div>
          ) : (
            <div className="p-4 text-center text-sm">
              No {tabSentenceCase} prompts found
              {isFiltered && ' matching your filters'}
            </div>
          )}
        </ScrollArea>
      </PopoverContent>
    </Popover>
  )
}

export default LoadPromptButton

const ItemSelect = ({
  label,
  selectedValues,
  setSelectedValues,
  sortedEntries,
  sortedGroups,
  ungroupedTitle,
}: {
  label: string
  selectedValues: string[]
  setSelectedValues: (selectedValues: string[]) => void
  sortedEntries: { text: string; value: string }[]
  sortedGroups?: { label: string; entries: { text: string; value: string }[] }[]
  ungroupedTitle?: string
}) => {
  return (
    <div className="flex h-8 items-center justify-between space-x-4">
      <p className="text-muted">{label}</p>
      <MultiSelect
        placeholder="Select"
        className={cn('w-36 shrink-0', {
          'text-muted': !selectedValues.length,
        })}
        selectedValues={selectedValues}
        setSelectedValues={setSelectedValues}
        sortedEntries={sortedEntries}
        sortedGroups={sortedGroups}
        ungroupedTitle={ungroupedTitle}
      />
    </div>
  )
}
