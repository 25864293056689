import pluralize from 'pluralize'

import { uploadFile } from 'api'
import { ContractsDocument } from 'openapi/models/ContractsDocument'
import {
  ContractsExtractionRequest,
  ContractsExtractionRequestToJSON,
} from 'openapi/models/ContractsExtractionRequest'
import { ContractsTask } from 'openapi/models/ContractsTask'

import { displayFileUploadError } from 'utils/dropzone'
import { createFileName } from 'utils/file-utils'
import { InitSocketAndSendQuery } from 'utils/use-harvey-socket'

import {
  ContractsAction,
  ContractsState,
} from 'components/workflows/workflow/contracts/contracts-store'
import { getExtractedTermsForAllTypes } from 'components/workflows/workflow/contracts/utils/utils'

// Lives outside store to keep store clean
export const newFileHandler = async (params: {
  files: File[]
  initSocketAndSendQuery: InitSocketAndSendQuery
  get: () => ContractsState & ContractsAction
}) => {
  const { files, initSocketAndSendQuery, get } = params
  const startingDocuments = get().documents ?? []

  const existingNamesSet = new Set(
    startingDocuments.map((doc) => doc.file.name)
  )
  const newFiles = files.map((file) => ({
    name: createFileName(file.name, existingNamesSet),
    file,
  }))

  // Eagerly add the documents
  get().setDocuments(
    startingDocuments.concat(
      newFiles.map((file) => createEagerDocument(file.name, file.file))
    )
  )

  const uploadedFiles = await Promise.all(
    newFiles.map(async ({ file, name }) => ({
      name,
      file,
      uploadedFile: await uploadFile(file, true).catch(() => {
        return null
      }),
    }))
  )

  const rejectedFiles = uploadedFiles.filter(
    ({ uploadedFile }) => uploadedFile === null
  )

  if (rejectedFiles.length) {
    displayFileUploadError(
      rejectedFiles.map(({ file }) => file.name),
      `${rejectedFiles.length} ${pluralize(
        'file',
        rejectedFiles.length
      )} failed to upload: `,
      uploadedFiles.length > 0
    )
    rejectedFiles.forEach((file) => get().removeDocument(file.name))
  }

  const docs: ContractsDocument[] = uploadedFiles
    .filter(({ uploadedFile }) => uploadedFile !== null)
    .map(({ file, name, uploadedFile }) => ({
      file: {
        id: uploadedFile!.id,
        name,
        path: uploadedFile!.path,
        url: uploadedFile!.url,
        size: file.size,
      },
      extractedTerms: {},
      specializedTerms: [],
    }))

  const request: ContractsExtractionRequest = {
    documents: docs,
    requestedTerms: getExtractedTermsForAllTypes(startingDocuments),
    customTerms: get().customTerms,
  }

  initSocketAndSendQuery({
    query: ContractsTask.EXTRACT,
    additionalAuthParams: {},
    additionalRequestParams: ContractsExtractionRequestToJSON(request),
  })
}

function createEagerDocument(fileName: string, file: File) {
  return {
    file: {
      id: '',
      name: fileName,
      path: '',
      url: '',
      size: file.size,
    },
    extractedTerms: {},
    specializedTerms: [],
    isLoading: true,
  }
}
