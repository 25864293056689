import { SentryEventType, sentryEvent } from 'utils/sentry'

export default class TelemtryEvent {
  fields: Record<string, any>
  timestamp: Date
  onFinish: (e: TelemtryEvent) => void

  constructor(onFinish: (e: TelemtryEvent) => void) {
    this.fields = {}
    this.timestamp = new Date()
    this.onFinish = onFinish
  }

  Add(fields: Record<string, any>): void {
    this.fields = { ...this.fields, ...fields }
  }

  FirstMark(key: string): void {
    if (this.fields[key] === undefined) {
      this.fields[key] = new Date().getTime() - this.timestamp.getTime()
    }
  }

  Finish(fields: Record<string, any>): void {
    this.Add({ total_time: new Date().getTime() - this.timestamp.getTime() })
    this.Record(fields)

    const { metric = '', retry_count = '', total_time = '' } = this.fields
    sentryEvent(SentryEventType.EVENT, `telemetry_event`, {
      metric,
      retry_count,
      total_time,
    })
  }

  Record(fields: Record<string, any>): void {
    this.Add(fields)
    this.onFinish(this)
  }

  ToRaw(): Record<string, any> {
    return {
      time: this.timestamp.toISOString(),
      data: this.fields,
    }
  }
}
