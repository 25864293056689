import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'

import { MessageFeedback } from 'openapi/models/MessageFeedback'
import { Maybe } from 'types'

import { SafeRecord } from 'utils/safe-types'

interface VaultFeedbackState {
  // Keyed by queryId, then fileId, then columnId
  feedbacks: SafeRecord<
    string, // queryId
    SafeRecord<
      string, // fileId
      SafeRecord<
        string, // columnId
        MessageFeedback & { id: string }
      >
    >
  >
}

interface VaultFeedbackAction {
  getFeedback: (
    queryId: Maybe<string>,
    fileId: Maybe<string>,
    columnId: Maybe<string>
  ) => (MessageFeedback & { id: string }) | undefined
  setFeedback: ({
    queryId,
    fileId,
    columnId,
    feedbackId,
    feedback,
  }: {
    queryId: string
    fileId: string
    columnId: string
    feedbackId: string
    feedback: MessageFeedback
  }) => void
}

const useVaultFeedbackStore = create(
  devtools(
    immer<VaultFeedbackState & VaultFeedbackAction>((set, get) => ({
      // VaultFeedbackState
      feedbacks: {},
      // VaultFeedbackAction
      getFeedback: (queryId, fileId, columnId) => {
        const { feedbacks } = get()
        return feedbacks[queryId ?? '']?.[fileId ?? '']?.[columnId ?? '']
      },
      setFeedback: ({ queryId, fileId, columnId, feedbackId, feedback }) =>
        set((state) => ({
          feedbacks: {
            ...state.feedbacks,
            [queryId]: {
              ...state.feedbacks[queryId],
              [fileId]: {
                ...state.feedbacks[queryId]?.[fileId],
                [columnId]: {
                  id: feedbackId,
                  ...feedback,
                },
              },
            },
          },
        })),
    }))
  )
)

export default useVaultFeedbackStore
