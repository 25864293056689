import React from 'react'

import { cn } from 'utils/utils'

import { useVaultStore } from 'components/vault/utils/vault-store'

export const Overlay = () => {
  const isTextAreaFocused = useVaultStore((state) => state.isTextAreaFocused)
  if (!isTextAreaFocused) return null

  return (
    <div>
      <div
        className={cn(
          'fixed inset-0 z-50 h-screen w-screen bg-overlay opacity-0',
          {
            'opacity-100': isTextAreaFocused,
          }
        )}
      />
    </div>
  )
}

export default Overlay
