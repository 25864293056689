import React from 'react'

import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from 'components/ui/accordion'
import { Badge } from 'components/ui/badge'
import BasicTransition from 'components/ui/basic-transition'
import { Checkbox } from 'components/ui/checkbox'
import useWorkflowTaxonomyGroupSelection, {
  Props,
  WorkflowTaxonomyGroup,
} from 'components/workflows/workflow/utils/use-workflow-taxonomy-selector-group'

export const WorkflowTaxonomySelectorGroup = <T extends WorkflowTaxonomyGroup>({
  group,
  onToggleTaxonomy,
  selectedTaxonomies,
  setSelectedTaxonomies,
}: Props<T>) => {
  const groupValue = group.title
  const {
    areAllRowsSelected,
    onTaxonomyRowCheckedChange,
    toggleSelectAllTrigger,
    numberSelected,
  } = useWorkflowTaxonomyGroupSelection({
    group,
    selectedTaxonomies,
    setSelectedTaxonomies,
    onToggleTaxonomy,
  })

  return (
    <AccordionItem
      value={groupValue}
      data-testid="accordion-item"
      className="last:border-b-0"
    >
      <AccordionTrigger
        data-testid="accordion-title"
        className="group h-14 px-4 hover:no-underline"
      >
        <div className="flex flex-col space-y-3">
          <div className="flex items-baseline space-x-3">
            <Checkbox
              data-testid="select-all-checkbox"
              className="self-center"
              onClick={toggleSelectAllTrigger}
              checked={numberSelected > 0}
              isIndeterminate={!areAllRowsSelected && numberSelected > 0}
            />
            <div className="text-left text-sm group-hover:underline">
              {group.title}
            </div>
            <BasicTransition
              show={numberSelected > 0}
              className="flex h-5 items-baseline"
            >
              <Badge variant="secondary">{numberSelected} Selected</Badge>
            </BasicTransition>
          </div>
          {group.description && (
            <p className="mt-1 text-left text-xs">{group.description}</p>
          )}
        </div>
      </AccordionTrigger>
      <AccordionContent
        data-testid="accordion-content"
        className="my-2 grid grid-cols-2 gap-1 px-4"
      >
        {group.rows.map((taxonomyRow) => (
          <Checkbox
            key={taxonomyRow}
            className="space-x-3"
            label={taxonomyRow}
            value={taxonomyRow}
            checked={selectedTaxonomies.some(
              (groups) =>
                groups.title === group.title &&
                groups.rows.includes(taxonomyRow)
            )}
            onClick={() => onTaxonomyRowCheckedChange(group.title, taxonomyRow)}
            id={taxonomyRow}
            data-testid="taxonomy-row-checkbox"
          />
        ))}
      </AccordionContent>
    </AccordionItem>
  )
}
