import React from 'react'
import {
  // eslint-disable-next-line no-restricted-imports
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from 'react-router-dom'

import { cn } from 'utils/utils'

export interface LinkProps extends RouterLinkProps {
  className?: string
}

// We should always use this component for all links. This is to ensure a11y and consistency
const Link = React.forwardRef<HTMLAnchorElement, LinkProps>(
  ({ className, ...props }, ref) => {
    return (
      <RouterLink
        className={cn(
          'rounded focus:outline-none focus-visible:ring focus-visible:ring-ring',
          className
        )}
        ref={ref}
        {...props}
      />
    )
  }
)

Link.displayName = 'Link'

export default Link
