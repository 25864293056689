import React from 'react'

import { AppHeader } from 'components/common/app-header'
import ErrorPage, { ErrorPageTitle } from 'components/common/error/error'

import SettingsLayout from './settings-layout'

interface SettingsErrorProps extends React.ComponentProps<typeof ErrorPage> {
  isInternalAdmin?: boolean
}

const SettingsError = ({
  title = ErrorPageTitle.SOMETHING_WENT_WRONG,
  isInternalAdmin,
  ...props
}: SettingsErrorProps) => {
  return (
    <>
      <AppHeader
        title="Settings"
        subtitle="Error"
        breadcrumbs={<div />}
        isInternalAdmin={isInternalAdmin}
      />
      <SettingsLayout>
        <div className="flex max-h-[480px] items-center justify-center">
          <ErrorPage title={title} {...props} />
        </div>
      </SettingsLayout>
    </>
  )
}
export default SettingsError
