import React from 'react'

import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationNext,
  PaginationPrevious,
} from 'components/ui/pagination/pagination'

type Props = {
  pageIndex: number
  onPageChange: (pageIndex: number) => void
  canNextPage: boolean
}

const PaginationButtons: React.FC<Props> = ({
  pageIndex,
  onPageChange,
  canNextPage,
}) => {
  const handlePreviousPage = () => {
    if (pageIndex > 0) onPageChange(pageIndex - 1)
  }

  const handleNextPage = () => {
    if (canNextPage) onPageChange(pageIndex + 1)
  }

  return (
    <Pagination className="flex items-center justify-end space-x-2 py-3">
      <PaginationContent>
        <PaginationItem>
          <PaginationPrevious onClick={handlePreviousPage} />
        </PaginationItem>

        <PaginationItem>
          <PaginationNext onClick={handleNextPage} />
        </PaginationItem>
      </PaginationContent>
    </Pagination>
  )
}

export default PaginationButtons
