import React, { useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'

import { useShallow } from 'zustand/react/shallow'

import { useGeneralStore } from 'stores/general-store'

import { useNavigateWithQueryParams } from 'hooks/use-navigate-with-query-params'
import { Env } from 'utils/env'
import {
  Site,
  getOtherSite,
  getSiteColor,
  getSiteName,
  getSiteUrl,
} from 'utils/site'
import { cn } from 'utils/utils'

import { SettingsPath } from 'components/base-app-path'
import { Button } from 'components/ui/button'

const InternalAdminLayout: React.FC<{ site: Site; env: Env }> = ({
  site,
  env,
}) => {
  const navigate = useNavigateWithQueryParams()
  const location = useLocation()
  const [isSidebarOpen, isStatusBarPreview] = useGeneralStore(
    useShallow((s) => [s.isSidebarOpen, s.isStatusBarPreview])
  )

  useEffect(() => {
    if (
      location.pathname === SettingsPath.InternalAdmin ||
      location.pathname === `${SettingsPath.InternalAdmin}/`
    ) {
      navigate(SettingsPath.InternalAdminWorkspaces, { replace: true })
    }
  }, [location.pathname, navigate])

  const renderDescription = (site: Site) => {
    return (
      <p className="truncate px-6 text-base text-primary-inverse">
        <b>Internal Admin:</b> You are currently on the {getSiteName(site)}{' '}
        version of the site. You can only read and write {getSiteName(site)}{' '}
        data.
      </p>
    )
  }

  return (
    <>
      {!isStatusBarPreview && (
        <div
          className={cn(
            'fixed top-0 z-50 flex min-h-10 w-full items-center justify-center space-x-4',
            {
              'pr-48': isSidebarOpen,
              'pr-16': !isSidebarOpen,
            }
          )}
          style={{ backgroundColor: getSiteColor(site) }}
          data-testid="internal-admin-site-banner"
        >
          {renderDescription(site)}
          {(env === Env.PRODUCTION || env === Env.STAGING) && (
            <Button
              variant="outline"
              size="sm"
              onClick={() => {
                window.location.href =
                  getSiteUrl(getOtherSite(site), env) +
                  SettingsPath.InternalAdmin
              }}
            >
              Switch to {getSiteName(getOtherSite(site))}
            </Button>
          )}
        </div>
      )}
      <Outlet />
    </>
  )
}

export default InternalAdminLayout
