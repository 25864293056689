import React, { useEffect, useState } from 'react'

import _ from 'lodash'

import { DeleteAllWorkspaceHistory, Workspace } from 'models/workspace'
import { Maybe } from 'types'

import { displayErrorMessage, displaySuccessMessage } from 'utils/toast'
import { parseIsoString } from 'utils/utils'

import { Button } from 'components/ui/button'
import { Calendar } from 'components/ui/calendar'
import { Checkbox } from 'components/ui/checkbox'

import DeleteAllWorkspaceHistoryModal from './delete-all-workspace-history-modal'

interface WorkspaceDetailsHistoryManagementProps {
  workspace: Workspace
}

const WorkspaceDetailsHistoryManagement = ({
  workspace,
}: WorkspaceDetailsHistoryManagementProps) => {
  const today = new Date()
  const [selectedDate, setSelectedDate] = useState<Maybe<Date>>(today)
  const [historyDeletedAt, setHistoryDeletedAt] = useState<Maybe<string>>(
    workspace.settings.historyDeletedAt
  )
  const [requestResetUsage, setRequestResetUsage] = useState(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)

  useEffect(() => {
    setHistoryDeletedAt(workspace.settings.historyDeletedAt)
  }, [workspace.settings])

  const handleDeleteAllHistory = async () => {
    if (selectedDate) {
      try {
        const res = await DeleteAllWorkspaceHistory(
          workspace.id,
          selectedDate.toISOString(),
          requestResetUsage
        )

        displaySuccessMessage(
          'History deleted successfully. Total deleted: ' + res.deletedCount,
          2
        )
      } catch (error) {
        displayErrorMessage('Failed to delete history. Please try again.')
      }
    }
  }

  const handleDateSelect = (date: Maybe<Date>) => {
    if (date && date > today) {
      displayErrorMessage('You cannot select a future date.', 1)
      setSelectedDate(null)
    } else if (
      date &&
      historyDeletedAt &&
      date <= parseIsoString(historyDeletedAt)
    ) {
      displayErrorMessage('Please select a date after the last deletion date.')
      setSelectedDate(null)
    } else {
      setSelectedDate(date)
    }
  }

  return (
    <>
      {selectedDate && (
        <DeleteAllWorkspaceHistoryModal
          open={deleteModalOpen}
          selectedDate={selectedDate}
          requestResetUsage={requestResetUsage}
          onOpenChange={(open) => {
            setDeleteModalOpen(open)
          }}
          onDelete={handleDeleteAllHistory}
        />
      )}
      <div>
        <div className="space-y-4">
          {historyDeletedAt && (
            <div>
              <p>
                Last history deletion date:{' '}
                <b>{parseIsoString(historyDeletedAt).toLocaleDateString()}</b>
              </p>
            </div>
          )}
          <div>
            <p>
              Delete <b>ALL</b> history of this workspace before date:
            </p>
          </div>
          <div>
            <Calendar
              mode="single"
              selected={selectedDate || undefined}
              onSelect={handleDateSelect}
              disabled={{
                after: today,
                before: !_.isNil(historyDeletedAt)
                  ? parseIsoString(historyDeletedAt)
                  : undefined,
              }}
            />
          </div>
          <div className="flex space-x-2">
            <Checkbox
              checked={requestResetUsage}
              onCheckedChange={(value) => setRequestResetUsage(!!value)}
            />
            <p>Reset usage</p>
          </div>
        </div>
        <div className="mt-6 flex">
          <Button
            onClick={() => setDeleteModalOpen(true)}
            disabled={!selectedDate}
          >
            Delete all history
          </Button>
        </div>
      </div>
    </>
  )
}

export default WorkspaceDetailsHistoryManagement
