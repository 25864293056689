import React from 'react'

import { isNil } from 'lodash'

import { cn } from 'utils/utils'

import { useAssistantStore } from 'components/assistant/stores/assistant-store'
import {
  getStreamingState,
  StreamingState,
} from 'components/assistant/utils/assistant-helpers'
import { Button } from 'components/ui/button'
import { Progress } from 'components/ui/progress'

interface Props {
  className?: string
  message?: string
  onCancel?: () => void
}

const AssistantLoadingState = ({ className, message, onCancel }: Props) => {
  const getStreamingMessageLoadingState = useAssistantStore(
    (s) => s.getStreamingMessageLoadingState
  )

  const loadingState = getStreamingMessageLoadingState()
  if (isNil(message)) message = loadingState?.message ?? 'Loading…'
  // TODO: Backend should format these
  if (!/…|\.\.\./.test(message) && !message.startsWith('Done')) message += '…'

  const progressPercent = loadingState?.progressPercent ?? 0
  const isProcessing =
    progressPercent > 0 &&
    getStreamingState({ headerText: message, progress: progressPercent }) ===
      StreamingState.PROCESSING

  const cancelButton = (
    <Button
      className="hover:bg-button-secondary-hover"
      onClick={onCancel}
      size="sm"
      variant="ghost"
    >
      Cancel
    </Button>
  )

  return (
    <div className="relative">
      {/* Render two loading containers so the progress bar keeps the same width
          as it fades out and the streaming message expands */}
      <LoadingContainer
        className={cn(
          'pointer-events-none opacity-0',
          {
            'pointer-events-auto opacity-100': isProcessing,
          },
          className
        )}
        action={cancelButton}
      >
        <p className="basis-1/4 truncate text-xs">{message}</p>
        <Progress
          value={progressPercent}
          className={cn('h-2 w-1/2 bg-button-secondary-hover')}
        />
      </LoadingContainer>
      <LoadingContainer
        className={cn(
          'absolute inset-x-0 inset-y-0',
          {
            'pointer-events-none opacity-0': isProcessing,
          },
          className
        )}
        action={cancelButton}
      >
        <p className="basis-3/4 truncate text-xs">{message}</p>
      </LoadingContainer>
    </div>
  )
}

export default AssistantLoadingState

const LoadingContainer = ({
  action,
  children,
  className,
}: {
  action: React.ReactNode
  children: React.ReactNode
  className: string
}) => {
  return (
    <div
      className={cn(
        'flex items-center justify-between space-x-2 rounded-md bg-secondary px-4 py-2',
        'delay-250 transition-opacity duration-500',
        className
      )}
    >
      {children}
      <div className="shrink-0 basis-1/4 text-right">{action}</div>
    </div>
  )
}
