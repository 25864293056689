import React, { useEffect, useState, useCallback } from 'react'

import { Transition } from '@headlessui/react'
import * as Sentry from '@sentry/browser'
import { X, Lock } from 'lucide-react'

import Services from 'services'

import { TaskType } from 'utils/task'
import { cn } from 'utils/utils'

import { useAuthUser } from 'components/common/auth-context'
import { Button } from 'components/ui/button'
import * as Card from 'components/ui/card'
import { Textarea } from 'components/ui/text-area'

import FeedbackSubmittedBanner from './feedback-submitted-banner'

export interface FeedbackResult {
  closed: boolean
  sentiment: number | undefined
  comments: string
}

interface FeedbackProps {
  queryId: string
  saveFeedback: (result: FeedbackResult) => void
  className?: string
  taskType?: TaskType
}

export const FEEDBACK_NOT_SHARED_NOTICE =
  'We never share your feedback or use it to improve third-party or other firms’ models'

// When submitted this component handles the feedback submission,
// saveFeedback is for the parent component to handle the state change
const Feedback: React.FC<FeedbackProps> = ({
  queryId,
  saveFeedback,
  className,
  taskType,
}) => {
  const [sentiment, setSentiment] = useState<number | undefined>(undefined)
  const [comments, setComments] = useState<string>('')
  const [submitted, setSubmitted] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)
  const userInfo = useAuthUser()

  const handleComments = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
    setComments(e.target.value)
  }

  const submit = useCallback(
    async (closed: boolean = false) => {
      const data = {
        closed,
        sentiment,
        comments,
        // do not add feedback_meta here it is tax specific
      }

      saveFeedback(data)
      try {
        const result = await Services.Backend.Post<unknown>(
          `feedback/${queryId}`,
          data,
          undefined
        )

        if (result !== true) {
          throw new Error('Failed to submit feedback, response')
        }
      } catch (e) {
        setError(true)
        Sentry.captureException(e)
        Services.HoneyComb.RecordError(e)
      }
    },
    [comments, queryId, saveFeedback, sentiment]
  )

  useEffect(() => {
    if (sentiment !== undefined) {
      void submit()
    }
  }, [sentiment, submit])

  const handleSubmit = async (): Promise<void> => {
    await submit()
    setSubmitted(true)
    setTimeout(close, 3000)
  }

  const handleClose = (): void => {
    if (!submitted) {
      void submit()
    }
    close()
  }

  const close = (): void => {
    void submit(true)
  }

  useEffect(() => {
    setSentiment(undefined)
    setComments('')
    setSubmitted(false)
  }, [queryId])

  // Overwrite Feedback permission for OGC Review
  const show =
    (userInfo.IsPwcUser && taskType === TaskType.OGC_REVIEW) ||
    (userInfo.IsFeedbackUser ?? false)

  const placeholderText =
    sentiment === -1
      ? 'Your feedback can be about factual, reasoning, or stylistic issues you would like Harvey to improve on.'
      : 'Your feedback can be about factual, reasoning, or stylistic aspects of the answer that made Harvey’s response helpful.'

  return (
    <Transition
      enter="transition-opacity duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      show={show}
    >
      <Card.Card
        className={cn(
          'relative z-50 mx-4 px-4 transition',
          {
            'h-[40px]': sentiment === undefined,
            'h-fit': sentiment !== undefined,
          },
          className
        )}
        data-testid="feedback"
      >
        {!submitted && (
          <>
            <div className="flex h-[40px] w-full items-center justify-between">
              <div className="text-sm text-primary">
                Was this answer helpful?
              </div>
              <div className="flex items-center">
                <Button
                  size="sm"
                  className="rounded-r-none"
                  variant={sentiment === 1 ? 'default' : 'outline'}
                  onClick={() => {
                    setSentiment(1)
                  }}
                  data-testid="feedback-yes"
                >
                  Yes
                </Button>

                <Button
                  size="sm"
                  className="rounded-l-none border-l-0"
                  variant={sentiment === -1 ? 'default' : 'outline'}
                  onClick={() => {
                    setSentiment(-1)
                  }}
                  data-testid="feedback-no"
                >
                  No
                </Button>
                <button
                  onClick={handleClose}
                  className="ml-2 flex h-6 w-6 items-center justify-center rounded-lg p-1 transition hover:bg-secondary-hover"
                  data-testid="feedback-close"
                >
                  <X />
                </button>
              </div>
            </div>

            {sentiment !== undefined && (
              <div className="mt-2">
                <div>
                  <Textarea
                    value={comments}
                    onChange={handleComments}
                    placeholder={placeholderText}
                    data-testid="feedback-comments"
                  />
                  <p className="ml-1 mt-1 text-xs text-muted">
                    {sentiment === -1
                      ? `Describe how Harvey’s answer could be improved.`
                      : `Describe how Harvey’s answer was helpful so we can generate
                    more answers like this.`}
                  </p>
                </div>
                <div className="mb-3 mt-4 flex w-full flex-wrap items-center justify-between gap-2">
                  <p className="ml-1 inline-flex items-center text-primary">
                    <Lock className="mb-0.5 mr-1 inline h-3 w-3 shrink-0" />
                    <span className="text-xs">
                      {FEEDBACK_NOT_SHARED_NOTICE}
                    </span>
                  </p>
                  <div>
                    <Button
                      size="sm"
                      onClick={() => {
                        void handleSubmit()
                      }}
                      data-testid="feedback-submit"
                    >
                      Submit
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </>
        )}

        {submitted && <FeedbackSubmittedBanner error={error} />}
      </Card.Card>
    </Transition>
  )
}

export default Feedback
