import React, { useEffect, useRef } from 'react'
import { FileRejection, useDropzone } from 'react-dropzone'
import { useParams } from 'react-router-dom'
import { useMount, useUnmount } from 'react-use'

import _ from 'lodash'
import { Instance } from 'pspdfkit'

import { uploadFile } from 'api'
import { useHistoryItemQuery } from 'models/queries/use-history-item-query'
import { EventKind } from 'openapi/models/EventKind'
import { UploadedFile, UploadedFileToJSON } from 'openapi/models/UploadedFile'
import { FileType } from 'types/file'
import { Source } from 'types/task'
import { WorkflowType, WorkflowTypeToTaskType } from 'types/workflows'

import { useNavigateWithQueryParams } from 'hooks/use-navigate-with-query-params'
import { onDrop } from 'utils/dropzone'
import { feedbackClosedState } from 'utils/feedback'
import { createAcceptObject, mbToBytes, mbToReadable } from 'utils/file-utils'
import { exportWordWithQuery } from 'utils/markdown'
import { displayErrorMessage } from 'utils/toast'
import {
  GET_UPLOAD_X_DOCUMENT_HELP_TEXT,
  IS_LOADING_HELP_TEXT,
} from 'utils/tooltip-texts'
import { useDropzoneTrack } from 'utils/use-dropzone-track'
import useHarveySocket from 'utils/use-harvey-socket'
import {
  HarveySocketCompletionStatus,
  hasCompletedStreaming,
} from 'utils/use-harvey-socket-utils'
import { isUserInputEmpty } from 'utils/utils'

import { useClientMattersStore } from 'components/client-matters/client-matters-store'
import useQueryAnalytics from 'components/common/analytics/use-query-analytics'
import { AppHeaderActions } from 'components/common/app-header-actions'
import AskHarveyButton from 'components/common/ask-harvey-button'
import { useAuthUser } from 'components/common/auth-context'
import { Dropzone } from 'components/common/dropzone/dropzone'
import DropzoneDescription from 'components/common/dropzone/dropzone-description'
import ExportDialog from 'components/common/export/export-dialog'
import { ExportOptionValues } from 'components/common/export/types'
import PdfViewer from 'components/common/pdf-viewer/pdf-viewer'
import Response from 'components/common/response/response'
import { WorkflowTypeToDetails } from 'components/workflows/workflow-definitions'
import { MAX_FILE_SIZE_MB } from 'components/workflows/workflow/components/workflow-file-input'
import { onAnnotationClick } from 'components/workflows/workflow/components/workflow-response-sources'
import WorkflowLayout from 'components/workflows/workflow/workflow-layout'

import { useDocCompareStore } from './store'

const ROUTE = 'document_compare'
const TASK_TYPE = 'DOCUMENT_COMPARISON'
const MAX_FILES = 1
const FILE_TYPES = [FileType.PDF, FileType.WORD]

const DocumentComparisonWorkflow: React.FC = () => {
  const workflow = WorkflowTypeToDetails[WorkflowType.DOCUMENT_COMPARISON]
  const navigate = useNavigateWithQueryParams()
  const userInfo = useAuthUser()
  const pspdfInstanceRefA = useRef<Instance | null>(null)
  const pspdfInstanceRefB = useRef<Instance | null>(null)
  const [isFileALoading, setIsFileALoading] = React.useState(false)
  const [isFileBLoading, setIsFileBLoading] = React.useState(false)
  const { id: historyId } = useParams<{ id: string }>()
  const { historyItem } = useHistoryItemQuery({ id: historyId ?? null })
  const {
    response,
    annotations,
    setTask,
    queryId,
    isLoading,
    headerText,
    documentA,
    documentB,
    setDocumentA,
    setDocumentB,
    feedback,
    setFeedback,
    reset,
    completionStatus,
  } = useDocCompareStore()
  const { selectedClientMatter, setClientMatterSelectDisabled } =
    useClientMattersStore()
  const { initSocketAndSendQuery, sendCancelRequest } = useHarveySocket({
    path: ROUTE,
    setter: setTask,
    endCallback: () => {
      setClientMatterSelectDisabled(false)
    },
  })

  const {
    setQueryViewed,
    recordExport,
    recordQueryCancel,
    recordQuerySubmitted,
    recordQueryCompletion,
    recordReset,
  } = useQueryAnalytics(EventKind.DOCUMENT_COMPARISON)

  const handleCancel = () => {
    recordQueryCancel()
    sendCancelRequest()
  }

  const handleReset = () => {
    navigate(workflow.path, { replace: true })
    reset()
    recordReset()
  }

  useEffect(() => {
    if (
      userInfo.IsHistoryUser &&
      queryId &&
      hasCompletedStreaming(completionStatus)
    ) {
      navigate(`${workflow.path}/${queryId}`, {
        replace: true,
        state: { fetchHistoryItem: false },
      })
    }
  }, [
    queryId,
    completionStatus,
    userInfo.IsHistoryUser,
    navigate,
    workflow.path,
  ])

  const recordFileDrop = useDropzoneTrack(EventKind.DOCUMENT_COMPARISON)

  const onFileAccept = async (
    acceptedFiles: File[],
    setDocument: (document: UploadedFile | null) => void,
    setIsFileLoading: (isFileLoading: boolean) => void
  ) => {
    if (!acceptedFiles.length) return
    setIsFileLoading(true)
    recordFileDrop(acceptedFiles)
    try {
      const file = acceptedFiles[0]
      if (_.isNil(file)) {
        setIsFileLoading(false)
        displayErrorMessage('Error uploading file.')
        return
      }
      const response = await uploadFile(file)
      setDocument(response)
    } finally {
      setIsFileLoading(false)
    }
  }

  const { getRootProps: getRootPropsA, getInputProps: getInputPropsA } =
    useDropzone({
      accept: createAcceptObject(FILE_TYPES),
      onDrop: (acceptedFiles: File[], fileRejections: FileRejection[]) =>
        onDrop({
          acceptedFiles,
          fileRejections,
          currentFileCount: 0,
          maxFiles: MAX_FILES,
          acceptedFileTypes: FILE_TYPES,
          maxFileSize: mbToBytes(MAX_FILE_SIZE_MB),
          maxZipFileSize: 0,
          handleAcceptedFiles: async (acceptedFiles: File[]) => {
            onFileAccept(acceptedFiles, setDocumentA, setIsFileALoading)
          },
        }),
      maxSize: mbToBytes(MAX_FILE_SIZE_MB),
      maxFiles: MAX_FILES,
    })

  const { getRootProps: getRootPropsB, getInputProps: getInputPropsB } =
    useDropzone({
      accept: createAcceptObject(FILE_TYPES),
      onDrop: (acceptedFiles: File[], fileRejections: FileRejection[]) =>
        onDrop({
          acceptedFiles,
          fileRejections,
          currentFileCount: 0,
          maxFiles: MAX_FILES,
          acceptedFileTypes: FILE_TYPES,
          maxFileSize: mbToBytes(MAX_FILE_SIZE_MB),
          maxZipFileSize: 0,
          handleAcceptedFiles: async (acceptedFiles: File[]) => {
            onFileAccept(acceptedFiles, setDocumentB, setIsFileBLoading)
          },
        }),
      maxSize: mbToBytes(MAX_FILE_SIZE_MB),
      maxFiles: MAX_FILES,
    })

  const onAskHarvey = () => {
    if (!documentA || !documentB) {
      return null
    }
    recordQuerySubmitted({
      event_kind: EventKind.DOCUMENT_COMPARISON,
    })
    const client_matter_id = userInfo.isClientMattersReadUser
      ? selectedClientMatter?.id
      : null

    setClientMatterSelectDisabled(true)
    initSocketAndSendQuery({
      query: '',
      additionalAuthParams: { task_type: TASK_TYPE, client_matter_id },
      additionalRequestParams: {
        source_event_id: queryId,
        task_type: TASK_TYPE,
        documents: [documentA, documentB].map(UploadedFileToJSON),
      },
    })
  }

  useMount(() => {
    if (!_.isNil(historyItem)) {
      setTask({
        queryId: historyItem.id,
        query: historyItem.query,
        response: historyItem.response,
        annotations: historyItem.annotations,
        completionStatus: HarveySocketCompletionStatus.Completed,
      })
      setFeedback(feedbackClosedState)
      const documents = historyItem.documents
      if (documents && documents.length === 2) {
        setDocumentA(documents[0])
        setDocumentB(documents[1])
      }
      setQueryViewed()
    }
  })

  useUnmount(() => {
    handleCancel()
    reset()
  })

  useEffect(() => {
    if (
      queryId &&
      hasCompletedStreaming(completionStatus) &&
      userInfo.IsHistoryUser
    ) {
      navigate(`${workflow.path}/${queryId}`, {
        replace: true,
        state: { fetchHistoryItem: false },
      })
      recordQueryCompletion()
    }
  }, [
    queryId,
    completionStatus,
    navigate,
    recordQueryCompletion,
    userInfo,
    workflow.path,
  ])

  const documentAreaRef = useRef<HTMLDivElement>(null)
  const getHrvyInfoMetadata = (footnoteNumber: string) => {
    if (!(footnoteNumber in annotations)) {
      return
    }
    const source = annotations[footnoteNumber] as unknown as Source
    let pspdfInstanceRef: any = null
    if (source.documentName === documentA?.name) {
      pspdfInstanceRef = pspdfInstanceRefA
    } else if (source.documentName === documentB?.name) {
      pspdfInstanceRef = pspdfInstanceRefB
    }
    if (!pspdfInstanceRef?.current) {
      return
    }

    return {
      onClick: () => {
        void onAnnotationClick(
          annotations,
          footnoteNumber,
          pspdfInstanceRef as any
        )
        documentAreaRef.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        })
      },
    }
  }

  const showFeedback =
    hasCompletedStreaming(completionStatus) && !feedback?.closed

  const isAskHarveyDisabled = !documentA || !documentB || isLoading

  const getButtonTooltip = () => {
    if (!documentA) {
      return GET_UPLOAD_X_DOCUMENT_HELP_TEXT('the first')
    } else if (!documentB) {
      return GET_UPLOAD_X_DOCUMENT_HELP_TEXT('the second')
    } else if (isLoading) {
      return IS_LOADING_HELP_TEXT
    } else {
      return ''
    }
  }

  const handleExportInner = async (withInlineCitations: boolean) => {
    await exportWordWithQuery({
      files:
        documentA && documentB ? [documentA.name, documentB.name] : undefined,
      query: '',
      response,
      taskType: WorkflowTypeToTaskType[workflow.type],
      includeAnnotation: withInlineCitations,
      queryId,
    })
    recordExport(String(queryId), 'word', withInlineCitations)
    return
  }

  const handleExport = async (exportValues: ExportOptionValues) => {
    await handleExportInner(!!exportValues.includeAnnotation)
  }

  const emptyUserInput = isUserInputEmpty([documentA, documentB])
  const resetDisabled = emptyUserInput || isLoading

  return (
    <WorkflowLayout
      workflowType={workflow.type}
      title="Document comparison"
      appHeaderActions={
        <>
          <AppHeaderActions
            handleReset={handleReset}
            resetDisabled={resetDisabled}
            saveExample={{
              show: userInfo.canSeeSaveExample,
              disabled: !hasCompletedStreaming(completionStatus),
              params: {
                eventId: String(queryId),
              },
            }}
          />
          <ExportDialog
            hasSources
            onExport={handleExport}
            disabled={!hasCompletedStreaming(completionStatus)}
          />
        </>
      }
    >
      <div className="space-y-2">
        <div className="h-fit space-y-2">
          <div className="flex w-full flex-row space-x-2" ref={documentAreaRef}>
            <div className="h-[640px] w-1/2" data-testid="document-a">
              {documentA ? (
                <div className="flex h-full flex-col">
                  <div className="flex-grow">
                    <PdfViewer
                      document={documentA}
                      onClear={() => {
                        setDocumentA(null)
                      }}
                      canClear={!isLoading}
                      isMaximized={false}
                      pspdfInstanceRef={pspdfInstanceRefA}
                    />
                  </div>
                </div>
              ) : (
                <div className="h-full" data-testid="dropzone-a">
                  <Dropzone
                    dropzone={{
                      getRootProps: getRootPropsA,
                      getInputProps: getInputPropsA,
                    }}
                    isLoading={isFileALoading}
                    description={
                      <DropzoneDescription
                        fileTypes={FILE_TYPES}
                        maxSize={mbToReadable(MAX_FILE_SIZE_MB)}
                      />
                    }
                  />
                </div>
              )}
            </div>
            <div className="w-1/2" data-testid="document-b">
              {documentB ? (
                <div className="flex h-full flex-col">
                  <div className="flex-grow">
                    <PdfViewer
                      document={documentB}
                      onClear={() => {
                        setDocumentB(null)
                      }}
                      canClear={!isLoading}
                      isMaximized={false}
                      pspdfInstanceRef={pspdfInstanceRefB}
                    />
                  </div>
                </div>
              ) : (
                <div className="h-full" data-testid="dropzone-b">
                  <Dropzone
                    dropzone={{
                      getRootProps: getRootPropsB,
                      getInputProps: getInputPropsB,
                    }}
                    isLoading={isFileBLoading}
                    description={
                      <DropzoneDescription
                        fileTypes={FILE_TYPES}
                        maxSize={mbToReadable(MAX_FILE_SIZE_MB)}
                      />
                    }
                  />
                </div>
              )}
            </div>
          </div>

          <AskHarveyButton
            handleSubmit={onAskHarvey}
            disabled={isAskHarveyDisabled}
            tooltip={getButtonTooltip()}
            isLoading={isLoading}
          />
        </div>
        <Response
          className="border-none"
          markdown={response}
          isLoading={isLoading}
          headerText={headerText}
          handleCancel={handleCancel}
          emptyStateText={
            userInfo.GetHelpPanel(WorkflowTypeToTaskType[workflow.type])
              ?.content ?? ''
          }
          getHrvyInfoMetadata={getHrvyInfoMetadata}
          queryId={queryId}
          handleSaveFeedback={showFeedback ? setFeedback : undefined}
        />
      </div>
    </WorkflowLayout>
  )
}

export default DocumentComparisonWorkflow
