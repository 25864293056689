import * as React from 'react'

import { Slot } from '@radix-ui/react-slot'
import { cva, type VariantProps } from 'class-variance-authority'

import { cn } from 'utils/utils'

import {
  TooltipPortal,
  TooltipTrigger,
  Tooltip,
  TooltipContent,
} from 'components/ui/tooltip'

import Link from './link/link'
import { Spinner } from './spinner'

const buttonVariants = cva(
  'inline-flex items-center justify-center rounded-md text-sm ring-offset-primary transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50',
  {
    variants: {
      variant: {
        default:
          'bg-interactive text-primary-inverse hover:bg-interactive-hover disabled:hover:bg-interactive font-medium',
        destructive:
          'bg-destructive text-destructive hover:bg-destructive/70 disabled:hover:bg-destructive',
        outline:
          'border border-input bg-primary hover:bg-button-secondary hover:text-primary disabled:hover:bg-primary',
        dashed:
          'border border-input border-dashed bg-primary hover:bg-button-secondary hover:text-primary disabled:hover:bg-primary',
        secondary:
          'bg-button-secondary text-primary hover:bg-button-secondary-hover disabled:hover:bg-button-secondary',
        ghost:
          'hover:bg-button-secondary hover:text-primary disabled:hover:bg-primary',
        link: 'text-primary underline-offset-4 hover:underline',
        text: 'hover:text-primary hover:underline',
        unstyled: 'bg-transparent text-inherit inline-block',
      },
      size: {
        sm: 'h-6 px-2 py-1 text-xs',
        default: 'h-8 rounded-md px-2.5',
        lg: 'h-10 px-4 py-2',
        xl: 'h-11 rounded-md px-8',
        icon: 'h-10 w-10',
        smIcon: 'h-8 w-8',
        xsIcon: 'h-6 w-6',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  }
)

export type ButtonVariantProps = VariantProps<typeof buttonVariants>

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    ButtonVariantProps {
  asChild?: boolean
  tooltip?: string
  tooltipDelay?: number
  tooltipClassName?: string
  tooltipPopoverClassName?: string
  tooltipSide?: 'top' | 'right' | 'bottom' | 'left'
  tooltipAlign?: 'start' | 'end' | 'center'
  to?: string
  isLoading?: boolean
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant,
      tooltipClassName,
      tooltipPopoverClassName,
      size,
      tooltipDelay,
      tooltipSide,
      tooltipAlign,
      asChild = false,
      isLoading = false,
      ...props
    },
    ref
  ) => {
    const Comp: React.ElementType = asChild ? Slot : props.to ? Link : 'button'
    const hasTooltip =
      props.tooltip !== undefined && props.tooltip.trim() !== ''

    if (hasTooltip) {
      const isButton = Comp === 'button'
      return (
        <Tooltip delayDuration={tooltipDelay}>
          <TooltipTrigger className={tooltipClassName} asChild={isButton}>
            <Comp
              className={cn(
                'relative',
                buttonVariants({ variant, size, className }),
                {
                  'text-transparent': isLoading,
                }
              )}
              ref={ref}
              {...props}
            >
              {isLoading && (
                <div className="absolute inset-0 flex items-center justify-center">
                  <Spinner className="mr-0 h-4 w-4" />
                </div>
              )}

              {props.children}
            </Comp>
          </TooltipTrigger>
          <TooltipPortal>
            <TooltipContent
              side={tooltipSide}
              className={tooltipPopoverClassName}
              align={tooltipAlign}
            >
              {props.tooltip}
            </TooltipContent>
          </TooltipPortal>
        </Tooltip>
      )
    }

    return (
      <Comp
        className={cn(
          'relative',
          buttonVariants({ variant, size, className }),
          {
            'text-transparent': isLoading,
          }
        )}
        ref={ref}
        {...props}
      >
        {isLoading && (
          <div className="absolute inset-0 flex w-full items-center justify-center">
            <Spinner className="mr-0 h-4 w-4" />
          </div>
        )}
        {props.children}
      </Comp>
    )
  }
)
Button.displayName = 'Button'

export { Button, buttonVariants }
