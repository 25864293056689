// offlineDB.ts
// This file stores the offline storage.  We are using IndexedDB under the hood in the browser, with 3rd party wrapper of idb-keyval
import { LogoutOptions, RedirectLoginOptions } from '@auth0/auth0-react'
import { AsyncStorage } from '@tanstack/query-persist-client-core'
import { get, set, del, clear } from 'idb-keyval'

import { retryOperation } from 'utils/retry-operation'

// Function to clear the whole IDB store
const clearOfflineStore = async () => {
  await clear()
}

// Custom storage object with retry logic
const HarvStorage: AsyncStorage & { clearAll: () => Promise<void> } = {
  getItem: (key) => retryOperation(() => get(key)),
  setItem: (key, value) => retryOperation(() => set(key, value)),
  removeItem: (key) => retryOperation(() => del(key)),
  clearAll: () => retryOperation(clearOfflineStore),
}

// Clear the cache before logging out
const logoutCacheWrapper = async (
  logoutFN: (options?: LogoutOptions) => void
) => {
  // Clear out offline db with retry
  await HarvStorage.clearAll()
  await logoutFN({ returnTo: window.location.origin })
}

// Clear the cache before redirecting to the login screen
const loginCacheWrapper = async (
  loginWithRedirectFN: (options?: RedirectLoginOptions) => Promise<void>
) => {
  // Clear out offline db with retry
  await HarvStorage.clearAll()
  await loginWithRedirectFN({
    appState: {
      returnTo: window.location.pathname + window.location.search,
    },
  })
}

// Export
export { HarvStorage, loginCacheWrapper, logoutCacheWrapper }
