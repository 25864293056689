import React, { useCallback } from 'react'
import { FileRejection, useDropzone } from 'react-dropzone'
import { useWindowSize } from 'react-use'

import _ from 'lodash'
import { UploadIcon } from 'lucide-react'
import { useShallow } from 'zustand/react/shallow'

import { FileType } from 'types/file'

import { onDrop } from 'utils/dropzone'
import { mbToBytes, mbToReadable } from 'utils/file-utils'
import { useDropzoneTrack } from 'utils/use-dropzone-track'
import { cn } from 'utils/utils'

import ClientMatterSelect from 'components/client-matters/client-matter-select'
import { useClientMattersStore } from 'components/client-matters/client-matters-store'
import { useAuthUser } from 'components/common/auth-context'
import { Dropzone } from 'components/common/dropzone/dropzone'
import DropzoneDescription from 'components/common/dropzone/dropzone-description'
import { Button } from 'components/ui/button'
import Icon from 'components/ui/icon/icon'
import { Input } from 'components/ui/input'
import { Label } from 'components/ui/label'
import { Spinner } from 'components/ui/spinner'
import VaultCreateDisclaimer from 'components/vault/components/vault-create-disclaimer'
import {
  FileToUpload,
  maxFileSizeInMb,
  maxTotalFileSizeInMb,
} from 'components/vault/utils/vault'
import {
  MAX_EXCEL_FILE_SIZE_IN_MB,
  ACCEPTED_FILE_TYPES,
} from 'components/vault/utils/vault'
import { useVaultCreateProjectStore } from 'components/vault/utils/vault-create-project-store'
import {
  handleDroppedFilesOrDeletedFiles,
  sumFileSizesInBytes,
} from 'components/vault/utils/vault-helpers'

import VaultUploadedFileList from './vault-uploaded-file-list'

const ANALYTICS_NAME = 'VAULT_PROJECT'

// 99px: height of header
// 72px: height of footer
// 40px: padding-top height
// 72px: height of project name
// 40px: margin between project name and disclaimer
// 20px + 64px: height of disclaimer
// 16px: margin between disclaimer and file list
// 32px: add more button + margin
// 16px: padding bottom
const LIST_UNAVAILABLE_HEIGHT = 99 + 72 + 40 + 72 + 40 + 20 + 64 + 16 + 32 + 16

const CreateProject = () => {
  const userInfo = useAuthUser()
  const { height } = useWindowSize()
  const [
    isDropzoneLoading,
    isSubmitting,
    newFolderName,
    filesToUpload,
    totalFileSizeInBytes,
    clientMatterName,
  ] = useVaultCreateProjectStore(
    useShallow((s) => [
      s.isDropzoneLoading,
      s.isSubmitting,
      s.newFolderName,
      s.filesToUpload,
      s.totalFileSizeInBytes,
      s.clientMatterName,
    ])
  )
  const [shouldCmLockQueries] = useClientMattersStore(
    useShallow((state) => [state.shouldCmLockQueries])
  )

  const setNewFolderName = useVaultCreateProjectStore((s) => s.setNewFolderName)
  const setDroppedFiles = useVaultCreateProjectStore((s) => s.setDroppedFiles)
  const setTotalFileSizeInBytes = useVaultCreateProjectStore(
    (s) => s.setTotalFileSizeInBytes
  )
  const setFilesToUpload = useVaultCreateProjectStore((s) => s.setFilesToUpload)
  const setIsDropzoneLoading = useVaultCreateProjectStore(
    (s) => s.setIsDropzoneLoading
  )
  const setClientMatterName = useVaultCreateProjectStore(
    (s) => s.setClientMatterName
  )

  const onFolderNameChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const folderName = e.target.value
    setNewFolderName(folderName)
  }

  const recordFileDrop = useDropzoneTrack(ANALYTICS_NAME)

  const onFileDrop = async (files: File[]) => {
    setIsDropzoneLoading(false)
    setDroppedFiles(files)
    await handleDroppedFilesOrDeletedFiles({
      latestDroppedFiles: files,
      hasFolderName: true,
      currentTotalSizeInBytes: totalFileSizeInBytes,
      setTotalFileSizeInBytes: setTotalFileSizeInBytes,
      setFilesToUpload: setFilesToUpload,
      checkForDuplicates: true,
      existingFilesToUpload: filesToUpload,
    })
    recordFileDrop(files)
  }

  const onFileDelete = useCallback(
    async (file: FileToUpload) => {
      const filesToUploadAfterDeletion = filesToUpload.filter(
        (f) => f.name !== file.name
      )
      const currentTotalSizeInBytes = await sumFileSizesInBytes(
        filesToUploadAfterDeletion.map((file) => file.file)
      )
      await handleDroppedFilesOrDeletedFiles({
        latestDroppedFiles: [],
        hasFolderName: true,
        currentTotalSizeInBytes: currentTotalSizeInBytes,
        setTotalFileSizeInBytes: setTotalFileSizeInBytes,
        setFilesToUpload: setFilesToUpload,
        checkForDuplicates: false,
        existingFilesToUpload: filesToUpload.filter(
          (f) => f.name !== file.name
        ),
      })
    },
    [filesToUpload, setFilesToUpload, setTotalFileSizeInBytes]
  )

  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    maxFiles: userInfo.workspace.getVaultFilesCountLimit(userInfo.vaultFeature),
    onDrop: async (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      setIsDropzoneLoading(true)
      return onDrop({
        acceptedFiles,
        fileRejections,
        currentFileCount: filesToUpload.length,
        maxFiles: userInfo.workspace.getVaultFilesCountLimit(
          userInfo.vaultFeature
        ),
        acceptedFileTypes: ACCEPTED_FILE_TYPES,
        maxFileSize: mbToBytes(maxFileSizeInMb(userInfo)),
        maxExcelFileSize: mbToBytes(MAX_EXCEL_FILE_SIZE_IN_MB),
        maxZipFileSize: mbToBytes(maxTotalFileSizeInMb(userInfo)),
        maxTotalFileSizeProps: {
          maxTotalFileSize: mbToBytes(maxTotalFileSizeInMb(userInfo)),
          currentTotalFileSize: await sumFileSizesInBytes(
            filesToUpload.map((file) => file.file)
          ),
        },
        shouldSkipPasswordProtectionCheck: true,
        handleAcceptedFiles: onFileDrop,
        handleRejectedFiles: () => {
          setIsDropzoneLoading(false)
        },
      })
    },
  })

  return (
    <div className="container max-w-[622px] pb-4 pt-10">
      <div className="w-full space-y-10 px-2">
        <div className="space-y-2">
          <Label htmlFor="project-name">Project name</Label>
          <Input
            required
            placeholder="Choose a name for your project"
            value={newFolderName}
            disabled={isSubmitting}
            onChange={onFolderNameChange}
            maxLength={64}
          />
        </div>
        {userInfo.IsVaultProjectClientMatterUser && (
          <ClientMatterSelect
            className={cn({
              'text-muted': _.isEmpty(clientMatterName),
            })}
            selectedValue={clientMatterName ?? undefined}
            setSelectedValue={setClientMatterName}
            allowAddNewItem={false}
            showClearOption={!shouldCmLockQueries}
          />
        )}
        <div>
          <div className="flex items-center gap-1">
            <Label htmlFor="files">Files</Label>
            <p className="text-muted">(Optional)</p>
          </div>
          <VaultCreateDisclaimer />
          <div
            className="mt-4"
            {...getRootProps({
              onClick: (event) => {
                event.stopPropagation()
                event.preventDefault()
              },
            })}
          >
            {filesToUpload.length === 0 || isDragActive ? (
              <Dropzone
                isLoading={isDropzoneLoading}
                dropzone={{ getRootProps, getInputProps }}
                processingDescription="Processing files"
                description={
                  <DropzoneDescription
                    customFileTypes={
                      mbToReadable(maxFileSizeInMb(userInfo)) !==
                      mbToReadable(MAX_EXCEL_FILE_SIZE_IN_MB)
                        ? [
                            {
                              fileType: FileType.EXCEL,
                              maxSize: mbToReadable(MAX_EXCEL_FILE_SIZE_IN_MB),
                            },
                          ]
                        : undefined
                    }
                    fileTypes={ACCEPTED_FILE_TYPES}
                    maxSize={mbToReadable(maxFileSizeInMb(userInfo))}
                    totalSize={mbToReadable(maxTotalFileSizeInMb(userInfo))}
                  />
                }
                className="h-64"
              />
            ) : (
              <>
                <VaultUploadedFileList
                  height={height - LIST_UNAVAILABLE_HEIGHT}
                  filesToUpload={filesToUpload}
                  isSubmitting={isSubmitting}
                  onFileDelete={onFileDelete}
                />
                <div className="mt-2 flex justify-center">
                  {isDropzoneLoading ? (
                    <div className="flex h-6 items-center">
                      <Spinner className="h3 w-3" />
                      <p className="text-xs">Adding...</p>
                    </div>
                  ) : (
                    <Button
                      variant="link"
                      onClick={open}
                      disabled={
                        filesToUpload.length >=
                        userInfo.workspace.getVaultFilesCountLimit(
                          userInfo.vaultFeature
                        )
                      }
                      size="sm"
                    >
                      <Icon icon={UploadIcon} size="small" className="mr-1" />
                      Add more
                    </Button>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default CreateProject
