import React from 'react'

import { withAuthenticationRequired } from '@auth0/auth0-react'

import type { SentryAssignees } from 'utils/sentry'

import ErrorPage, { ErrorPageTitle } from 'components/common/error/error'
import LoadingScreen from 'components/common/loading-screen'
import SentryAssigneeWrapper from 'components/sentry/sentry-wrapper'

interface AuthenticationGuardProps {
  component: React.ComponentType | React.FC<any>
  hasPerms: boolean
  componentProps?: Record<string, any>
  sentryAssignee?: SentryAssignees
}

const AuthenticationGuard = ({
  component,
  hasPerms,
  componentProps,
  sentryAssignee,
}: AuthenticationGuardProps) => {
  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => <LoadingScreen />,
  })

  if (hasPerms) {
    return (
      <SentryAssigneeWrapper sentryAssignee={sentryAssignee}>
        <Component {...componentProps} />
      </SentryAssigneeWrapper>
    )
  }

  return <ErrorPage title={ErrorPageTitle.NOT_AUTHORIZED} />
}

export default AuthenticationGuard
