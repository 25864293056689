import React, { useState } from 'react'

import {
  ColumnDef,
  PaginationState,
  SortingState,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import _ from 'lodash'
import pluralize from 'pluralize'

import { ClientMatterWithStats } from 'models/client-matters'
import { Maybe } from 'types'

import { getTableDateString } from 'utils/date-utils'
import { EM_DASH, cn } from 'utils/utils'

import {
  CLIENT_MATTER_SETTINGS_READD_METRIC,
  ClientMatterAdd,
} from 'components/client-matters/client-matter-utils'
import { useSettingsState } from 'components/settings/settings-store'
import { Badge } from 'components/ui/badge'
import { DataTable } from 'components/ui/data-table/data-table'
import DataTableFooter from 'components/ui/data-table/data-table-footer'
import DataTableSortHeader from 'components/ui/data-table/data-table-sort-header'
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/ui/tooltip'

import ClientMattersTableActions from './client-matters-table-actions'

interface ClientMattersTableProps {
  data: ClientMatterWithStats[]
  filter: string
  isLoading: boolean
  setFilter: (value: string) => void
  onAddClientMatter: (
    clientMatters: ClientMatterAdd[],
    onSuccess: () => void,
    metric: string
  ) => void
  onEditClientMatter: (
    cmName: string,
    cmDesc: Maybe<string>,
    cmAllowed: boolean
  ) => void
  onDeleteClientMatter: (cmName: string) => void
  showCmManagement: boolean
  id?: string
}

const ClientMattersTable = ({
  data,
  filter,
  isLoading,
  setFilter,
  onAddClientMatter,
  onEditClientMatter,
  onDeleteClientMatter,
  showCmManagement,
  id,
}: ClientMattersTableProps) => {
  const settingsUser = useSettingsState((state) => state.settingsUser)

  const onAddCm = (cmName: string, cmDesc: string, cmAllowed: boolean) => {
    onAddClientMatter(
      [
        {
          cmName,
          cmDesc,
          cmAllowed,
        },
      ],
      () => {},
      CLIENT_MATTER_SETTINGS_READD_METRIC
    )
  }

  const onEditCm = (
    cmName: string,
    cmDesc: Maybe<string>,
    cmAllowed: boolean
  ) => {
    onEditClientMatter(cmName, cmDesc, cmAllowed)
  }

  const onDeleteCm = (cmName: string) => {
    onDeleteClientMatter(cmName)
  }

  const columns: ColumnDef<ClientMatterWithStats>[] = [
    {
      accessorKey: 'name',
      header: ({ column }) => {
        return <DataTableSortHeader column={column} header="Client matter #" />
      },
      cell: ({ getValue }) => (
        <div className="ml-3 text-sm">{String(getValue())}</div>
      ),
      enableGlobalFilter: true,
    },
    {
      accessorKey: 'description',
      header: ({ column }) => {
        return (
          <DataTableSortHeader
            column={column}
            header="Description"
            tooltipText="Description of this client matter #"
          />
        )
      },
      cell: ({ getValue }) => (
        <Tooltip>
          <TooltipTrigger>
            <div className="ml-3 max-w-44 overflow-hidden truncate text-sm">
              {String(getValue() ?? '')}
            </div>
          </TooltipTrigger>
          <TooltipContent>{String(getValue() ?? '')}</TooltipContent>
        </Tooltip>
      ),
      enableGlobalFilter: true,
    },
    {
      accessorKey: 'allowed',
      header: ({ column }) => {
        return (
          <DataTableSortHeader
            column={column}
            header="Allowed"
            tooltipText="Whether this client matter # is allowed to be used"
          />
        )
      },
      cell: ({ getValue }) => {
        const value = getValue()
        let badge = <Badge variant="secondary">Yes</Badge>
        if (!_.isNil(value) && !value) {
          badge = <Badge variant="destructive">No</Badge>
        }
        return <div className="ml-3 text-sm">{badge}</div>
      },
      enableGlobalFilter: false,
    },
    {
      accessorKey: 'totalEvents',
      header: ({ column }) => {
        return (
          <DataTableSortHeader
            column={column}
            header="Queries"
            tooltipText={
              settingsUser?.IsTerritoryAdmin
                ? 'Cumulative number of queries run with this client matter #'
                : 'Number of queries you ran with this client matter #'
            }
          />
        )
      },
      cell: ({ getValue }) => (
        <div className="ml-4 text-sm">{String(getValue()) ?? EM_DASH}</div>
      ),
      enableGlobalFilter: false,
    },
    {
      accessorKey: 'userCount',
      header: ({ column }) => {
        return (
          <DataTableSortHeader
            column={column}
            header="Users"
            tooltipText="Number of users who used this client matter #"
          />
        )
      },
      cell: ({ getValue }) => (
        <div className="ml-4 text-sm">{String(getValue()) ?? EM_DASH}</div>
      ),
      enableGlobalFilter: false,
    },
    {
      accessorKey: 'userEmail',
      header: ({ column }) => {
        return (
          <DataTableSortHeader
            column={column}
            header="Added by"
            tooltipText="User who added this client matter #"
          />
        )
      },
      cell: ({ getValue }) => (
        <div className="ml-3 text-sm">{String(getValue())}</div>
      ),
      enableGlobalFilter: true,
    },
    {
      accessorKey: 'createdAt',
      header: ({ column }) => {
        return (
          <DataTableSortHeader
            column={column}
            header="Date added"
            tooltipText="Date when this client matter # was added"
          />
        )
      },
      cell: ({ getValue }) => {
        const value = getValue()
        return (
          <div className="ml-3 text-sm">
            {value ? getTableDateString(value) : ''}
          </div>
        )
      },
      enableGlobalFilter: false,
    },
    {
      accessorKey: 'deletedAt',
      header: ({ column }) => {
        return (
          <DataTableSortHeader
            column={column}
            header="Date deleted"
            tooltipText="Date when this client matter # was deleted"
          />
        )
      },
      cell: ({ getValue }) => {
        const value = getValue()
        return (
          <div className="ml-3 text-sm">
            {value ? getTableDateString(value) : ''}
          </div>
        )
      },
      enableGlobalFilter: false,
    },
    {
      accessorKey: 'actions',
      header: '',
      cell: ({ row }) => {
        return (
          <ClientMattersTableActions
            row={row}
            onAddClientMatter={onAddCm}
            onEditClientMatter={onEditCm}
            onDeleteClientMatter={onDeleteCm}
          />
        )
      },
      enableGlobalFilter: false,
    },
  ]
  const hideColumns: string[] = []
  if (_.isNil(settingsUser) || !settingsUser.IsTerritoryAdmin) {
    hideColumns.push('userCount', 'deletedAt')
  }
  if (!showCmManagement) {
    hideColumns.push('actions')
  }
  if (_.isNil(settingsUser) || !settingsUser.isDisallowClientMattersUser) {
    hideColumns.push('allowed')
  }

  const [tablePaginationState, setTablePaginationState] =
    useState<PaginationState>({
      pageIndex: 0,
      pageSize: 10,
    })
  const [sorting, setSorting] = useState<SortingState>([])

  const table = useReactTable({
    data,
    columns,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    manualPagination: false,
    state: {
      sorting,
      pagination: tablePaginationState,
      globalFilter: filter,
      columnVisibility: _.mapValues(_.keyBy(hideColumns), () => false),
    },
    onPaginationChange: setTablePaginationState,
    onGlobalFilterChange: setFilter,
    enableSorting: true,
    enableSortingRemoval: true,
  })

  const rowCountCopy = `${table.getFilteredRowModel().rows.length} ${pluralize(
    'client matter',
    table.getFilteredRowModel().rows.length
  )}`

  if (_.isNil(settingsUser) || !settingsUser.isClientMattersReadUser)
    return null

  return (
    <>
      <DataTable
        table={table}
        className={cn({ 'min-h-[620px]': !isLoading })}
        useVirtual={data.length > 100}
        isLoading={isLoading}
        virtualEstimateSize={60}
        id={id}
      />
      <DataTableFooter table={table} isLoading={isLoading}>
        <p>{rowCountCopy}</p>
      </DataTableFooter>
    </>
  )
}

export default ClientMattersTable
