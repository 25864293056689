import React, { useCallback, useMemo, useRef, useState } from 'react'

import { isEmpty } from 'lodash'
import { ChevronUp, ChevronDown } from 'lucide-react'

import { DiligenceSection } from 'openapi/models/DiligenceSection'
import { UploadedFile } from 'openapi/models/UploadedFile'

import { cn } from 'utils/utils'

import FullscreenLoading from 'components/common/fullscreen-loading'
import PdfPushSheet from 'components/common/pdf-viewer/pdf-push-sheet'
import { Button } from 'components/ui/button'
import Icon from 'components/ui/icon/icon'
import {
  ImperativeResizablePanelGroupHandle,
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from 'components/ui/resizable'
import { EXPIRATION_URL_KEY } from 'components/vault/utils/vault'
import { FetchVaultFile } from 'components/vault/utils/vault-fetcher'
import { isUrlExpired } from 'components/vault/utils/vault-helpers'
import { useVaultStore } from 'components/vault/utils/vault-store'
import { useMeasureNodeHeight } from 'components/workflows/workflow/discovery/common/use-measure-node-height'
import { useSelectedCitation } from 'components/workflows/workflow/discovery/common/use-selected-citation'
import { useDiligenceStore } from 'components/workflows/workflow/discovery/diligence-store'
import { getSizeInPercentage } from 'components/workflows/workflow/discovery/util'

import { FollowUpSectionWrapper } from './follow-up-input-section-wrapper'
import { ManySections } from './many-sections'

interface Props {
  sectionsWithAnswers: DiligenceSection[]
  progress: number
}

export const ManySectionsWithPDF: React.FC<Props> = ({
  sectionsWithAnswers,
  progress,
}) => {
  const isLoading = (sectionsWithAnswers: DiligenceSection[]) => {
    return sectionsWithAnswers.some((section) => section.isLoading)
  }

  const [documents, followUpQAPairs, knowledgeSource] = useDiligenceStore(
    (state) => [state.documents, state.followUpQAPairs, state.knowledgeSource]
  )

  const { selectedSourceFileId } = useSelectedCitation()

  const getDocument = useCallback(
    async (
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      eventId: string | null,
      fileId: string
    ): Promise<UploadedFile | undefined> => {
      if (!knowledgeSource) {
        return documents.find((d) => d.file.id === fileId)?.file
      }

      const vaultFile = useVaultStore.getState().fileIdToVaultFile[fileId]
      if (vaultFile) {
        const vaultFileUrl = vaultFile.url || vaultFile.docAsPdfUrl || ''
        if (
          !isEmpty(vaultFileUrl) &&
          !isUrlExpired(vaultFileUrl, EXPIRATION_URL_KEY)
        ) {
          return vaultFile
        }
      }
      const file = await FetchVaultFile(fileId)
      useVaultStore.getState().upsertVaultFiles([file])
      return file
    },
    [documents, knowledgeSource]
  )

  const pushSheet = useMemo(() => {
    if (!selectedSourceFileId) {
      return null
    }

    const sources = sectionsWithAnswers
      .flatMap((section) => section.tasks)
      .flatMap((task) => {
        return task.answer?.sources || []
      })
      .filter((source) => source.documentId === selectedSourceFileId)

    sources.push(
      ...followUpQAPairs
        .flatMap((pair) => pair.answer?.sources || [])
        .filter((source) => source.documentId === selectedSourceFileId)
    )

    return (
      <PdfPushSheet
        fileId={selectedSourceFileId}
        getDocument={getDocument}
        sources={sources}
      />
    )
  }, [followUpQAPairs, getDocument, sectionsWithAnswers, selectedSourceFileId])

  const resizablePanelGroupRef =
    useRef<ImperativeResizablePanelGroupHandle | null>(null)

  const innerResizeablePanelGroupRef =
    useRef<ImperativeResizablePanelGroupHandle | null>(null)

  const resetLayout = () => {
    const panelGroup = resizablePanelGroupRef.current
    if (panelGroup) {
      panelGroup.setLayout([100, 0])
    }
  }

  const { containerHeight, containerRef } = useMeasureNodeHeight()
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  // We need to set the min, max, and default sizes for the drawer when it is open or closed
  // Below, we choose the size in pixels, and then compute the percentage size to use for the ResizablePanel
  const drawerOpenHeight = 280 // pixels
  const drawerClosedHeight = 40 // pixels
  const defaultDrawerSize = isDrawerOpen
    ? getSizeInPercentage(drawerOpenHeight, containerHeight)
    : getSizeInPercentage(drawerClosedHeight, containerHeight)
  const minDrawerSize = defaultDrawerSize
  const maxDrawerSize = isDrawerOpen ? undefined : defaultDrawerSize
  const defaultReportPanelSize = 100 - defaultDrawerSize

  // This handler changes the layout from closed drawer to open drawer and vice versa
  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen)
    if (innerResizeablePanelGroupRef.current && containerHeight) {
      innerResizeablePanelGroupRef.current.setLayout([
        defaultReportPanelSize,
        minDrawerSize,
      ])
    }
  }

  return (
    <div className="flex size-full" ref={containerRef}>
      {isLoading(sectionsWithAnswers) && (
        <FullscreenLoading
          isLoading={isLoading(sectionsWithAnswers)}
          progress={progress}
          zIndex="z-1"
        />
      )}
      <ResizablePanelGroup direction="horizontal" ref={resizablePanelGroupRef}>
        {/* The report and drawer inner resizable panel */}
        <ResizablePanel defaultSize={100} minSize={20}>
          <ResizablePanelGroup
            direction="vertical"
            ref={innerResizeablePanelGroupRef}
          >
            <ResizablePanel defaultSize={defaultReportPanelSize} minSize={20}>
              <ManySections sections={sectionsWithAnswers} />
            </ResizablePanel>

            {/* The drawer is below */}
            {isDrawerOpen && <ResizableHandle withHandle />}

            <ResizablePanel
              defaultSize={defaultDrawerSize}
              minSize={minDrawerSize}
              maxSize={maxDrawerSize}
              className="z-20 transition-all ease-in-out"
            >
              <div
                className={cn('relative h-full border bg-button-secondary', {
                  'bg-primary': isDrawerOpen,
                })}
              >
                <Button
                  className="left-2 top-2 h-6 min-w-[100px] rounded-t-md p-2 hover:bg-button-secondary-hover focus:outline-none focus-visible:ring focus-visible:ring-ring"
                  variant="ghost"
                  size="sm"
                  onClick={toggleDrawer}
                >
                  <Icon
                    className="mr-2"
                    icon={isDrawerOpen ? ChevronDown : ChevronUp}
                  />
                  <p>Follow-up questions</p>
                </Button>
                {/* Drawer Content */}
                <div className="flex flex-col items-center overflow-auto p-4">
                  {isDrawerOpen && <FollowUpSectionWrapper />}
                </div>
                {/* End Drawer Content */}
              </div>
            </ResizablePanel>
            {/* End drawer */}
          </ResizablePanelGroup>
        </ResizablePanel>

        {/* The side panel for viewing documents */}
        {selectedSourceFileId && (
          <>
            <ResizableHandle withHandle onDoubleClick={resetLayout} />
            <ResizablePanel defaultSize={0} minSize={40}>
              {pushSheet}
            </ResizablePanel>
          </>
        )}
      </ResizablePanelGroup>
    </div>
  )
}
