import { EM_DASH } from 'utils/utils'

import { ReviewCellResponse } from 'components/vault/utils/vault'

import { StrictCellRenderer } from './cell-renderer-types'

export class CurrencyCellRenderer extends StrictCellRenderer {
  renderCell(response: ReviewCellResponse) {
    if (!response.value) {
      return EM_DASH
    }

    const number = parseFloat(response.value)
    if (isNaN(number)) {
      return response.value
    }

    const hasZeroDecimals = number % 1 === 0
    const formatter = new Intl.NumberFormat(navigator.language, {
      style: 'currency',
      currency: response.currencyCode ?? undefined,
      minimumFractionDigits: hasZeroDecimals ? 0 : 2,
      maximumFractionDigits: 2,
      useGrouping: true,
    })

    return formatter.format(number)
  }
}
