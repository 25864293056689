import React from 'react'

import { X } from 'lucide-react'

import { EvaluationQuestion } from 'openapi/models/EvaluationQuestion'

import { Button } from 'components/ui/button'
import { Card, CardContent, CardHeader, CardTitle } from 'components/ui/card'
import { ScrollArea } from 'components/ui/scroll-area'
import { Tabs, TabsContent, TabsList, TabsTrigger } from 'components/ui/tabs'

import EvaluationQuestionNew from './evaluation-question-new'
import EvaluationQuestionSelect from './evaluation-question-select'

// TODO questions about what to pass in
const EvaluationQuestionAdd = ({
  availableEvaluationQuestionsRef, // TODO don't want to re-render on update of selections
  handleNewSelectedQuestion,
  handleDeleteQuestion,
  onCancel,
}: {
  availableEvaluationQuestionsRef: React.MutableRefObject<EvaluationQuestion[]>
  handleNewSelectedQuestion: (
    question: EvaluationQuestion,
    shouldClose: boolean
  ) => void
  handleDeleteQuestion: (questionId: string) => Promise<void>
  onCancel: () => void
}) => {
  // TODO why is this rendered 4x on selection of question, 2x withotu strict mode
  // https://stackoverflow.com/questions/71265042/what-is-usecallback-in-react-and-when-to-use-it
  return (
    <Card className="mb-5 mt-5 p-2">
      <CardHeader>
        <div className="flex justify-between">
          <CardTitle>Add Evaluation Question</CardTitle>
          <Button variant="outline" size="icon" onClick={onCancel}>
            <X size={14} />
          </Button>
        </div>
      </CardHeader>
      <CardContent>
        <Tabs defaultValue="select">
          <TabsList className="justify-start">
            <TabsTrigger value="select">Select existing</TabsTrigger>
            <TabsTrigger value="create">Create new</TabsTrigger>
          </TabsList>
          <TabsContent value="select">
            <ScrollArea className="h-[500px] pl-4 pr-4">
              <EvaluationQuestionSelect
                availableEvaluationQuestionsRef={
                  availableEvaluationQuestionsRef
                }
                handleNewSelectedQuestion={handleNewSelectedQuestion}
                handleDeleteQuestion={handleDeleteQuestion}
              />
            </ScrollArea>
          </TabsContent>
          <TabsContent value="create">
            <EvaluationQuestionNew
              handleNewSelectedQuestion={handleNewSelectedQuestion}
            />
          </TabsContent>
        </Tabs>
      </CardContent>
    </Card>
  )
}

export { EvaluationQuestionAdd }
