/* tslint:disable */
/* eslint-disable */
/**
 * Transcripts API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { UploadedFile } from './UploadedFile';
import {
    UploadedFileFromJSON,
    UploadedFileFromJSONTyped,
    UploadedFileToJSON,
} from './UploadedFile';

/**
 * 
 * @export
 * @interface TranscriptsAnalyzeRequest
 */
export interface TranscriptsAnalyzeRequest {
    /**
     * 
     * @type {Array<UploadedFile>}
     * @memberof TranscriptsAnalyzeRequest
     */
    documents: Array<UploadedFile>;
}

/**
 * Check if a given object implements the TranscriptsAnalyzeRequest interface.
 */
export function instanceOfTranscriptsAnalyzeRequest(value: object): value is TranscriptsAnalyzeRequest {
    if (!('documents' in value) || value['documents'] === undefined) return false;
    return true;
}

export function TranscriptsAnalyzeRequestFromJSON(json: any): TranscriptsAnalyzeRequest {
    return TranscriptsAnalyzeRequestFromJSONTyped(json, false);
}

export function TranscriptsAnalyzeRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): TranscriptsAnalyzeRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'documents': ((json['documents'] as Array<any>).map(UploadedFileFromJSON)),
    };
}

export function TranscriptsAnalyzeRequestToJSON(value?: TranscriptsAnalyzeRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'documents': ((value['documents'] as Array<any>).map(UploadedFileToJSON)),
    };
}

