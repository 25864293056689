import saveAs from 'file-saver'

import { logExport } from './markdown'
import { TaskType } from './task'
import { backendFormat } from './utils'

export const createExportFilename = (base: string) =>
  `${base}_${backendFormat(new Date())}.docx`

export const exportTaskDocumentFromBackend = async (
  url: string,
  taskType: TaskType,
  queryId: string
) => {
  const response = await fetch(url)
  const blob = await response.blob()
  saveAs(blob, createExportFilename(taskType))
  logExport('docx', taskType, queryId)
}
