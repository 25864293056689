import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'

import {
  ContractsCreateSmartFilterRequest,
  ContractsCreateSmartFilterRequestToJSON,
} from 'openapi/models/ContractsCreateSmartFilterRequest'
import { ContractsCreateTermRequestToJSON } from 'openapi/models/ContractsCreateTermRequest'
import {
  ContractsCustomTerm,
  ContractsCustomTermFromJSON,
  instanceOfContractsCustomTerm,
} from 'openapi/models/ContractsCustomTerm'
import { ContractsDocumentFromJSON } from 'openapi/models/ContractsDocument'
import {
  ContractsDocument,
  instanceOfContractsDocument,
} from 'openapi/models/ContractsDocument'
import { ContractsExtractionRequestToJSON } from 'openapi/models/ContractsExtractionRequest'
import {
  ContractsSmartFilter,
  ContractsSmartFilterFromJSON,
  instanceOfContractsSmartFilter,
} from 'openapi/models/ContractsSmartFilter'
import { ContractsTask } from 'openapi/models/ContractsTask'
import { Maybe } from 'types'

import {
  HarveySocketTask,
  InitSocketAndSendQuery,
} from 'utils/use-harvey-socket'

import { newFileHandler } from './utils/new-file-handler-util'
import { GroupedTerms } from './utils/utils'

export interface ContractsState {
  documents: ContractsDocument[]
  filters: ContractsSmartFilter[]
  toolbar: Maybe<ContractsToolbarType>
  customTerms: ContractsCustomTerm[]
  selectedTerms: GroupedTerms
  isFilterLoading: boolean
}

export enum ContractsToolbarType {
  SMART_FILTER,
}

export interface ContractsAction {
  setDocuments: (document: ContractsDocument[]) => void
  setFilters: (filters: ContractsSmartFilter[]) => void
  setToolbar: (toolbar: ContractsToolbarType | null) => void
  setCustomTerms: (terms: ContractsCustomTerm[]) => void
  setDocumentsLoadingState: (
    isLoading: boolean,
    filter?: (doc: ContractsDocument) => boolean
  ) => void
  removeDocument: (fileName: string) => void
  addCustomTerm: (newTerm: ContractsCustomTerm) => void
  removeFailedCustomTerms: () => void
  setSelectedTerms: (terms: GroupedTerms) => void
  reset: () => void
  setIsFilterLoading: (isLoading: boolean) => void

  // Socket task handlers
  handleNewFiles: (
    files: File[],
    initSocketAndSendQuery: InitSocketAndSendQuery
  ) => Promise<void>
  handleNewFilesCompleted: () => void
  handleCreateCustomTerm: (
    term: string,
    contractType: string,
    initSocketAndSendQuery: InitSocketAndSendQuery
  ) => void
  handleCreateCustomTermCompleted: () => void
  handleExtractTerms: (initSocketAndSendQuery: InitSocketAndSendQuery) => void
  handleExtractTermsCompleted: () => void
  handleCreateSmartFilter: (params: {
    initSocketAndSendQuery: InitSocketAndSendQuery
    contractType: string
    termName: string
    query: string
  }) => void
  handleCreateSmartFilterCompleted: () => void

  // Socket response handler
  setter: (data: Partial<HarveySocketTask>) => void
}

const initialState: ContractsState = {
  documents: [],
  filters: [],
  toolbar: null,
  customTerms: [],
  selectedTerms: {},
  isFilterLoading: false,
}

export const useContractsStore = create(
  devtools(
    immer<ContractsState & ContractsAction>((set, get) => ({
      ...initialState,

      // Reset state to initial
      reset: () => set(() => ({ ...initialState })),

      // Setter methods for state properties
      setDocuments: (documents: ContractsDocument[]) => set({ documents }),
      setFilters: (filters: ContractsSmartFilter[]) => set({ filters }),
      setToolbar: (toolbar: ContractsToolbarType | null) => set({ toolbar }),
      setCustomTerms: (terms: ContractsCustomTerm[]) =>
        set({ customTerms: terms }),
      setSelectedTerms: (terms: GroupedTerms) => set({ selectedTerms: terms }),
      setIsFilterLoading: (isLoading: boolean) =>
        set({ isFilterLoading: isLoading }),
      // Document loading state handler
      setDocumentsLoadingState: (
        isLoading: boolean,
        filter?: (doc: ContractsDocument) => boolean
      ) =>
        set((state) => {
          state.documents.forEach((doc) => {
            if (!filter || filter(doc)) {
              doc.isLoading = isLoading
            }
          })
        }),

      // Add or remove documents
      addDocument: (document: ContractsDocument) =>
        set((state) => ({ documents: [...state.documents, document] })),
      removeDocument: (fileName: string) =>
        set((state) => ({
          documents: state.documents.filter(
            (doc) => doc.file.name !== fileName
          ),
        })),

      // Custom term manipulation
      addCustomTerm: (newTerm: ContractsCustomTerm) =>
        set((state) => ({ customTerms: [...state.customTerms, newTerm] })),
      removeFailedCustomTerms: () =>
        set((state) => ({
          customTerms: state.customTerms.filter((term) => !term.isLoading),
        })),

      handleNewFiles: async (
        files: File[],
        initSocketAndSendQuery: InitSocketAndSendQuery
      ) => {
        return newFileHandler({ files, initSocketAndSendQuery, get })
      },
      handleNewFilesCompleted: () => {
        get().setDocumentsLoadingState(false)
      },
      handleCreateCustomTerm: (
        term: string,
        contractType: string,
        initSocketAndSendQuery: InitSocketAndSendQuery
      ) => {
        // Eager add the term to the list
        get().addCustomTerm({
          termDisplayName: term,
          termJsonKey: term,
          definition: term,
          contractType,
          userInput: term,
          isLoading: true,
        })

        initSocketAndSendQuery({
          query: ContractsTask.CREATE_TERM,
          additionalRequestParams: ContractsCreateTermRequestToJSON({
            term,
            contractType,
          }),
        })
      },
      handleCreateCustomTermCompleted: () => {
        get().removeFailedCustomTerms()
      },
      handleExtractTerms: (initSocketAndSendQuery: InitSocketAndSendQuery) => {
        get().setDocumentsLoadingState(
          true,
          (doc) => get().selectedTerms[doc.type as string]?.length > 0
        )

        initSocketAndSendQuery({
          query: ContractsTask.EXTRACT,
          additionalRequestParams: ContractsExtractionRequestToJSON({
            documents: get().documents,
            requestedTerms: get().selectedTerms,
            customTerms: get().customTerms,
          }),
        })
      },
      handleExtractTermsCompleted: () => {
        get().setDocumentsLoadingState(false)
      },
      handleCreateSmartFilter: (params: {
        initSocketAndSendQuery: InitSocketAndSendQuery
        contractType: string
        termName: string
        query: string
      }) => {
        const { initSocketAndSendQuery, contractType, termName, query } = params
        get().setIsFilterLoading(true)
        const payload: ContractsCreateSmartFilterRequest = {
          userInput: {
            userQuery: query,
            contractType,
            term: termName,
          },
          documents: get().documents.filter((doc) => doc.type === contractType),
          customTerm: get().customTerms.find(
            (term) =>
              term.contractType === contractType && term.userInput === termName
          ),
        }

        initSocketAndSendQuery({
          query: ContractsTask.SMART_FILTER,
          additionalAuthParams: {},
          additionalRequestParams:
            ContractsCreateSmartFilterRequestToJSON(payload),
        })
      },
      handleCreateSmartFilterCompleted: () => {
        get().setIsFilterLoading(false)
      },

      // Dynamic response handler for socket tasks
      setter: (data: Partial<HarveySocketTask>) => {
        const updateOrAddDocument = (docToUpdate: ContractsDocument) =>
          set((state) => {
            const index = state.documents.findIndex(
              (doc) => doc.file.name === docToUpdate.file.name
            )
            if (index !== -1) state.documents[index] = docToUpdate
            else state.documents.push(docToUpdate)
          })

        const updateOrAddCustomTerm = (newTerm: ContractsCustomTerm) =>
          set((state) => {
            const index = state.customTerms.findIndex(
              (t) =>
                t.userInput === newTerm.userInput &&
                t.contractType === newTerm.contractType
            )
            if (index !== -1) state.customTerms[index] = newTerm
            else state.customTerms.push(newTerm)
          })

        const addSmartFilter = (filter: ContractsSmartFilter) =>
          set((state) => ({ filters: [...state.filters, filter] }))

        const responseHandlers = [
          // TODO there is a weird bug where if the smartfilter handler is last in the list
          // the smart filter response will be misclassified as a custom term response
          {
            transformer: ContractsSmartFilterFromJSON,
            instanceOf: instanceOfContractsSmartFilter,
            handler: addSmartFilter,
          },
          {
            transformer: ContractsDocumentFromJSON,
            instanceOf: instanceOfContractsDocument,
            handler: updateOrAddDocument,
          },
          {
            transformer: ContractsCustomTermFromJSON,
            instanceOf: instanceOfContractsCustomTerm,
            handler: updateOrAddCustomTerm,
          },
        ]

        const handleResponse = (response: string) => {
          const data = JSON.parse(response)
          responseHandlers.some(({ transformer, instanceOf, handler }) => {
            try {
              const transformed = transformer(data)
              if (instanceOf(transformed)) {
                handler(transformed as any)
                return true // Breaks the loop
              }
              // eslint-disable-next-line no-empty
            } catch (e) {}
            return false
          })
        }

        try {
          if (!data.response) return
          const responses = Array.isArray(data.response)
            ? data.response
            : [data.response]
          responses.forEach(handleResponse)
        } catch (e) {
          console.error('Error handling response', e)
        }
      },
    }))
  )
)
