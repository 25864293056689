/* tslint:disable */
/* eslint-disable */
/**
 * Transcripts API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration of transcript types
 * @export
 */
export const TranscriptType = {
    TRIAL: 'trial',
    DEPOSITION: 'deposition',
    OTHER: 'other'
} as const;
export type TranscriptType = typeof TranscriptType[keyof typeof TranscriptType];


export function instanceOfTranscriptType(value: any): boolean {
    for (const key in TranscriptType) {
        if (Object.prototype.hasOwnProperty.call(TranscriptType, key)) {
            if (TranscriptType[key as keyof typeof TranscriptType] === value) {
                return true;
            }
        }
    }
    return false;
}

export function TranscriptTypeFromJSON(json: any): TranscriptType {
    return TranscriptTypeFromJSONTyped(json, false);
}

export function TranscriptTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): TranscriptType {
    return json as TranscriptType;
}

export function TranscriptTypeToJSON(value?: TranscriptType | null): any {
    return value as any;
}

