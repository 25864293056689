import Services from 'services'

const TAB_FOCUS_EVENT_NAME = 'ui.tab_focus_time'
const TAB_FOCUS_EVENT_DEBOUNCE_TIME_MILLIS = 1000

const useTabFocusTracking = () => {
  const startTabFocusEvent = () => {
    if (window.TabFocusEvent == null) {
      window.TabFocusEvent = Services.HoneyComb.Start({
        metric: TAB_FOCUS_EVENT_NAME,
      })
    }
    return window.TabFocusEvent
  }

  const finishTabFocusEvent = () => {
    if (window.TabFocusEvent) {
      // Only record tab focus if it was active for more than 1 second to reduce noise
      if (
        new Date().getTime() - window.TabFocusEvent.timestamp.getTime() >
        TAB_FOCUS_EVENT_DEBOUNCE_TIME_MILLIS
      ) {
        window.TabFocusEvent.Finish({})
      }
      window.TabFocusEvent = null
    }
  }

  // Start tab focus once on mount
  startTabFocusEvent()

  // Record tab focus and unfocus events on window focus and blur
  const handleFocus = () => {
    startTabFocusEvent()
  }
  const handleBlur = () => {
    finishTabFocusEvent()
  }
  window.addEventListener('focus', handleFocus)
  window.addEventListener('blur', handleBlur)

  // Record tab close event on window beforeunload
  const handleBeforeUnload = () => {
    finishTabFocusEvent()
  }
  window.addEventListener('beforeunload', handleBeforeUnload)

  return () => {
    window.removeEventListener('focus', handleFocus)
    window.removeEventListener('blur', handleBlur)
    window.removeEventListener('beforeunload', handleBeforeUnload)
  }
}

export default useTabFocusTracking
