import React from 'react'

import { ShieldAlert } from 'lucide-react'

import { Icon } from 'components/ui/icon/icon'

const VaultCreateDisclaimer = () => {
  return (
    <div
      className="flex w-full items-center gap-2 rounded-lg bg-secondary px-4 py-3"
      data-testid="vault-create-folder-dialog--warning"
    >
      <Icon icon={ShieldAlert} />
      <p>
        These documents will be saved to your Harvey instance and only
        accessible to you and your workspace admins. You can delete them at any
        time.
      </p>
    </div>
  )
}

export default VaultCreateDisclaimer
