import React from 'react'

import { cn } from 'utils/utils'

interface Props {
  children: React.ReactNode
  className?: string
  actions?: React.ReactNode
}

const AssistantInputSourceHeader = ({
  children,
  className,
  actions,
}: Props) => {
  return (
    <div className="flex shrink-0 items-center justify-between border-b px-6 py-3">
      <div className={cn('flex items-center text-sm font-medium', className)}>
        {children}
      </div>
      {actions && <div className="flex items-center space-x-2">{actions}</div>}
    </div>
  )
}

export default AssistantInputSourceHeader
