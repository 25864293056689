import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { useState } from 'react'
import { FileRejection, useDropzone } from 'react-dropzone'
import { useMount } from 'react-use'

import _ from 'lodash'
import { File, Trash } from 'lucide-react'

import { EvaluationQuestion } from 'openapi/models/EvaluationQuestion'
import { ExperimentMetadata } from 'openapi/models/ExperimentMetadata'

import { useNavigateWithQueryParams } from 'hooks/use-navigate-with-query-params'
import { onDrop } from 'utils/dropzone'
import { mbToBytes } from 'utils/file-utils'
import { displayErrorMessage, displaySuccessMessage } from 'utils/toast'

import { SettingsPath } from 'components/base-app-path'
import { useAuthUser } from 'components/common/auth-context'
import { Dropzone } from 'components/common/dropzone/dropzone'
import SettingsAppHeader from 'components/settings/settings-app-header'
import SettingsLayout from 'components/settings/settings-layout'
import { Button } from 'components/ui/button'
import { Input } from 'components/ui/input'
import { Label } from 'components/ui/label'
import SectionHeader from 'components/ui/section-header'
import { Separator } from 'components/ui/separator'

import { EvaluationQuestionAdd } from './evaluation-question/evaluation-question-add'
import EvaluationQuestionCard from './evaluation-question/evaluation-question-card'
import {
  CreateExperiment,
  DeleteEvaluationQuestion,
  FetchEvaluationQuestions,
} from './utils/experiment-fetcher'
import {
  ALLOWED_FILE_TYPES,
  EXPERIMENT_CSV_COLUMNS,
  ExperimentAllowedFileTypes,
  MAX_FILES,
  MAX_FILE_SIZE_MB,
} from './utils/experiment-utils'

const NewExperiment = () => {
  const userInfo = useAuthUser()
  const navigate = useNavigateWithQueryParams()

  const [experimentName, setExperimentName] = useState<string>()
  const [experimentSlug, setExperimentSlug] = useState<string>() // TODO validate

  const [isDropzoneLoading, setIsDropzoneLoading] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isAddingNewEvalQuestion, setIsAddingNewEvalQuestion] = useState(false)

  const [validationErrorMsg, setValidationErrorMsg] = useState<string>()

  const [file, setFile] = useState<File>() // TODO add some valiation?

  const [selectedExperimentEvalQuestions, setSelectedExperimentEvalQuestions] =
    useState<EvaluationQuestion[]>([])

  const [evaluationQuestionBank, setEvaluationQuestionBank] = useState<
    EvaluationQuestion[]
  >([])

  const [availableEvaluationQuestions, setAvailableEvaluationQuestions] =
    useState<EvaluationQuestion[]>([])

  const availableEvaluationQuestionsRef = useRef<EvaluationQuestion[]>([])

  useEffect(() => {
    const avail = _.differenceBy(
      evaluationQuestionBank,
      selectedExperimentEvalQuestions,
      'id'
    )
    if (_.isEqual(availableEvaluationQuestions, avail)) {
      return
    }
    setAvailableEvaluationQuestions(avail)
    availableEvaluationQuestionsRef.current = avail
  }, [
    availableEvaluationQuestions,
    evaluationQuestionBank,
    selectedExperimentEvalQuestions,
  ])

  const fetchEvaluationQuestionBank = async () => {
    const evaluationQuestions = await FetchEvaluationQuestions(false)
    setEvaluationQuestionBank(evaluationQuestions)
  }

  useMount(() => {
    void fetchEvaluationQuestionBank() // TODO fetching in use effect, handling of errors
  })

  // TODO no need for use callback since child doesn't use use memo
  const handleNewSelectedQuestion = useCallback(
    (question: EvaluationQuestion, shouldClose: boolean) => {
      if (selectedExperimentEvalQuestions.some((q) => q.id === question.id)) {
        displayErrorMessage('Question already selected.')
        return
      }
      setSelectedExperimentEvalQuestions([
        ...selectedExperimentEvalQuestions,
        question,
      ])
      if (shouldClose) {
        setIsAddingNewEvalQuestion(false)
        // after a new question is created we should update our evaluation question bank
        void fetchEvaluationQuestionBank()
      }
    },
    [selectedExperimentEvalQuestions]
  )

  const handleDeleteEvaluationQuestion = async (questionId: string) => {
    await DeleteEvaluationQuestion(questionId)
    void fetchEvaluationQuestionBank()
  }

  const onFileDrop = async (acceptedFiles: File[]) => {
    if (acceptedFiles.length >= 1) {
      setFile(acceptedFiles[0])
    } else {
      displayErrorMessage('No file detected.')
    }
  }

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      setIsDropzoneLoading(true)
      onDrop({
        acceptedFiles,
        fileRejections,
        currentFileCount: 0,
        maxFiles: MAX_FILES,
        acceptedFileTypes: ALLOWED_FILE_TYPES,
        maxFileSize: mbToBytes(MAX_FILE_SIZE_MB),
        maxZipFileSize: 0,
        handleAcceptedFiles: onFileDrop,
      })
      setIsDropzoneLoading(false)
    },
    maxFiles: MAX_FILES,
    accept: ExperimentAllowedFileTypes,
  })

  const canSubmit = useMemo(() => {
    if (_.isNil(experimentName)) {
      setValidationErrorMsg('Experiment name is required.')
      return false
    }
    if (_.isNil(experimentSlug)) {
      setValidationErrorMsg('Experiment slug is required.')
      return false
    }
    if (_.isNil(file)) {
      setValidationErrorMsg('CSV file is required.')
      return false
    }
    if (selectedExperimentEvalQuestions.length === 0) {
      setValidationErrorMsg('At least one evaluation question is required.')
      return false
    }

    if (isSubmitting) {
      setValidationErrorMsg('Submitting…')
      return false
    }

    if (isAddingNewEvalQuestion) {
      setValidationErrorMsg(
        'Cannot submit until you exit evaluation question selection mode.'
      )
      return false
    }
    if (isDropzoneLoading) {
      setValidationErrorMsg('Uploading CSV file…')
      return false
    }

    setValidationErrorMsg(undefined)
    return true
  }, [
    experimentName,
    experimentSlug,
    file,
    isAddingNewEvalQuestion,
    isDropzoneLoading,
    isSubmitting,
    selectedExperimentEvalQuestions,
  ])

  const onSubmit = async () => {
    setIsSubmitting(true)

    try {
      if (!canSubmit) {
        displayErrorMessage(validationErrorMsg!)
        return
      }

      const experimentMetadata: ExperimentMetadata = {
        config: {
          evaluationQuestions: selectedExperimentEvalQuestions.map(
            (question, idx) => ({
              questionId: question.id,
              orderRank: idx + 1,
              isRequired: true, // TODO need to allow for toggle of required questions
            })
          ),
        },
      }

      const newExperiment = await CreateExperiment({
        experimentName: experimentName!,
        experimentSlug: experimentSlug!,
        experimentMetadata,
        csvFile: file!,
      })

      displaySuccessMessage(
        `Experiment ${newExperiment.slug} created successfully`,
        5
      )
      navigate(SettingsPath.InternalAdminExperimentManagement) // TODO redirect to specific experiment overview page
    } catch (error) {
      displayErrorMessage(`Failed to create experiment: ${error}`, 5)
    } finally {
      setIsSubmitting(false)
    }
  }

  // this is to remove from the selected questions list, not delete from the DB
  const removeEvaluationQuestion = (id: string) => {
    setSelectedExperimentEvalQuestions(
      selectedExperimentEvalQuestions.filter((question) => question.id !== id)
    )
  }

  if (
    _.isNil(userInfo) ||
    _.isEmpty(userInfo) ||
    !userInfo.IsResponseComparisonAdmin
  ) {
    return <div>You are not authorized to create experiments.</div>
  }

  return (
    <>
      <SettingsAppHeader isInternalAdmin />
      <SettingsLayout>
        <SectionHeader title="Create an experiment" />
        <div className="my-5">
          <Label className="font-normal">Experiment Name</Label>
          <Input
            type="text"
            placeholder="e.g. GPT 4o vs 3.5"
            description={{
              label:
                'This is the friendly name, can include spaces and any text',
              variant: 'default',
            }}
            value={experimentName}
            onChange={(e) => setExperimentName(e.target.value)}
            disabled={isSubmitting}
          />
        </div>
        <div className="my-5">
          <Label className="font-normal">Experiment Slug</Label>
          <Input
            type="text"
            placeholder="e.g. gpt4o_05_17"
            description={{
              label:
                'This is the identifier that will be used to navigate to the experiment. Must be unique. No spaces allowed. Only alphanumeric with dashes/underscores.',
              variant: 'default',
            }}
            value={experimentSlug}
            onChange={(e) => setExperimentSlug(e.target.value)}
            disabled={isSubmitting}
          />
        </div>
        <div className="my-5">
          <Label className="font-normal">Experiment CSV</Label>
          {_.isNil(file) ? (
            <Dropzone
              isLoading={isDropzoneLoading}
              dropzone={{ getRootProps, getInputProps }}
              description={`Max CSV file count allowed: ${MAX_FILES}. Expected columns: ${EXPERIMENT_CSV_COLUMNS.join(
                ', '
              )}`}
            />
          ) : (
            <div className="flex w-full items-center justify-between rounded px-2 py-1 transition hover:bg-secondary-hover">
              <div className="flex w-full items-center text-left">
                <File size={14} className="mr-2 shrink-0" />
                <p className="line-clamp-1 break-all text-start">{file.name}</p>
              </div>
              <button
                className="rounded p-1 transition hover:bg-button-secondary-hover"
                onClick={() => setFile(undefined)}
                disabled={isSubmitting}
              >
                <Trash size={14} />
              </button>
            </div>
          )}
        </div>
        <Separator orientation="horizontal" className="w-full" />
        <h2 className="my-5 text-lg font-semibold">
          Add evaluation questions to experiment
        </h2>
        {selectedExperimentEvalQuestions.map((question, idx) => (
          <EvaluationQuestionCard
            key={question.id}
            evaluationQuestion={question}
            orderRank={idx + 1}
            onRemove={removeEvaluationQuestion}
          />
        ))}
        {!isAddingNewEvalQuestion && (
          <div className="my-5">
            <Button
              variant="outline"
              disabled={isSubmitting}
              onClick={() => setIsAddingNewEvalQuestion(true)}
            >
              Add a question
            </Button>
          </div>
        )}
        {isAddingNewEvalQuestion && (
          <EvaluationQuestionAdd
            availableEvaluationQuestionsRef={availableEvaluationQuestionsRef}
            handleNewSelectedQuestion={handleNewSelectedQuestion}
            onCancel={() => setIsAddingNewEvalQuestion(false)}
            handleDeleteQuestion={handleDeleteEvaluationQuestion}
          />
        )}
        {/* TODO disable button based on validatoin / completeness */}
        <Button
          type="submit"
          variant="default"
          onClick={onSubmit}
          disabled={!canSubmit}
          tooltip={validationErrorMsg}
        >
          Submit
        </Button>
      </SettingsLayout>
    </>
  )
}

export default NewExperiment
