import { FileType } from 'types/file'

export const MIN_QUERY_LENGTH = 9

export const DOC_QA_QUERY_LIMIT = 4000
export const OPEN_ENDED_QUERY_LIMIT = 100000
export const MAX_FILE_SIZE = 20
export const MAX_TOTAL_FILE_SIZE = 100
export const MAX_ZIP_FILE_SIZE = 50
export const NUM_MAX_FILES = 50

export const ACCEPTED_FILE_TYPES = [
  FileType.PDF,
  FileType.WORD,
  FileType.WORD_LEGACY,
  FileType.EXCEL,
  FileType.EXCEL_LEGACY,
  FileType.ZIP,
  FileType.EMAIL,
  FileType.TEXT,
  FileType.CSV,
  FileType.POWERPOINT,
  FileType.POWERPOINT_LEGACY,
  FileType.OUTLOOK,
  FileType.RTF,
]
