import React, { useEffect, useState } from 'react'

import _ from 'lodash'

import {
  getStatusMessageForWorkspace,
  setStatusMessageForWorkspace,
  Workspace,
} from 'models/workspace'

import { displayErrorMessage, displaySuccessMessage } from 'utils/toast'

import { StatusVariant } from 'components/common/status-bar'
import {
  CTA_TEXT_PLACEHOLDER,
  CTA_URL_PLACEHOLDER,
  OFFLINE_PLACEHOLDER,
  OPEN_AI_DOWN_PLACEHOLDER,
} from 'components/settings/incident-management/utils'
import { Button } from 'components/ui/button'
import { Card, CardContent, CardHeader } from 'components/ui/card'
import { Input } from 'components/ui/input'
import { Label } from 'components/ui/label'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/ui/select'

interface WorkspaceDetailsStatusBarProps {
  workspace: Workspace
}

const WorkspaceDetailsStatusBar = ({
  workspace,
}: WorkspaceDetailsStatusBarProps) => {
  const [header, setHeader] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [ctaText, setCtaText] = useState<string>('')
  const [ctaUrl, setCtaUrl] = useState<string>('')
  const [variant, setVariant] = useState<StatusVariant>(
    StatusVariant.destructive
  )

  useEffect(() => {
    const fetchStatusBar = async () => {
      const resp = await getStatusMessageForWorkspace(workspace.id)
      const statusBarMeta = resp?.statusBarMeta
      if (!_.isNil(statusBarMeta)) {
        setHeader(statusBarMeta.header ?? '')
        setDescription(statusBarMeta.description)
        setCtaText(statusBarMeta.cta?.text ?? '')
        setCtaUrl(statusBarMeta.cta?.link ?? '')
        setVariant(statusBarMeta.variant)
      }
    }
    fetchStatusBar()
  }, [workspace.id])

  const saveStatusBar = async () => {
    try {
      await setStatusMessageForWorkspace(workspace.id, {
        header,
        description,
        cta: {
          text: ctaText,
          link: ctaUrl,
        },
        variant,
      })
      displaySuccessMessage('Status message saved successfully')
      // display success and failure ws from resp
    } catch (error) {
      console.error('Failed to save status message:', error)
      displayErrorMessage('Failed to save status message')
    }
  }

  const getSaveDisabledTooltip = () => {
    if (!description) {
      return 'Description is required'
    } else if (ctaText && !ctaUrl) {
      return 'CTA URL is required if CTA Text is provided'
    } else if (!ctaText && ctaUrl) {
      return 'CTA Text is required if CTA URL is provided'
    }

    return undefined
  }

  const clearAllInputs = () => {
    setHeader('')
    setDescription('')
    setCtaText('')
    setCtaUrl('')
    setVariant(StatusVariant.destructive)
  }

  return (
    <Card>
      <CardHeader>
        <p>
          View currently set status bar for this workspace. To set a custom
          status message for this workspace, override it below.
        </p>
        {workspace.settings.onlyCustomStatusMessage && (
          <p className="font-semibold text-warning">
            This workspace is currently set to only show custom status messages.
          </p>
        )}
      </CardHeader>
      <CardContent className="space-y-4">
        <div>
          <Label>Header</Label>
          <Input
            type="text"
            className="mt-1 w-full"
            placeholder={OFFLINE_PLACEHOLDER}
            value={header}
            onChange={(e) => setHeader(e.target.value)}
          />
        </div>
        <div>
          <Label>
            Description<span className="text-destructive">*</span>
          </Label>
          <Input
            type="text"
            className="mt-1 w-full"
            placeholder={OPEN_AI_DOWN_PLACEHOLDER}
            required
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
        <div className="flex justify-between space-x-8">
          <div className="w-1/2">
            <Label>CTA Text</Label>
            <Input
              type="text"
              className="mt-1 w-full"
              placeholder={CTA_TEXT_PLACEHOLDER}
              value={ctaText}
              onChange={(e) => setCtaText(e.target.value)}
            />
          </div>
          <div className="w-1/2">
            <Label>CTA URL</Label>
            <Input
              type="text"
              className="mt-1 w-full"
              placeholder={CTA_URL_PLACEHOLDER}
              value={ctaUrl}
              onChange={(e) => setCtaUrl(e.target.value)}
            />
          </div>
        </div>
        <div>
          <Label>
            Intent<span className="text-destructive">*</span>
          </Label>
          <Select
            value={variant}
            onValueChange={(value) => {
              setVariant(value as StatusVariant)
            }}
          >
            <SelectTrigger className="mt-1 h-8 min-w-[200px]">
              <SelectValue placeholder="Select an intent" />
            </SelectTrigger>
            <SelectContent>
              {Object.values(StatusVariant).map((statusType) => (
                <SelectItem key={statusType} value={statusType}>
                  {_.startCase(statusType)}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        <div className="flex justify-end">
          <Button variant="secondary" onClick={clearAllInputs} className="mr-2">
            Clear all
          </Button>
          <Button
            onClick={saveStatusBar}
            tooltip={getSaveDisabledTooltip()}
            disabled={!_.isNil(getSaveDisabledTooltip())}
            tooltipSide="left"
          >
            {_.isEmpty(description) ? 'Save' : 'Override'}
          </Button>
        </div>
      </CardContent>
    </Card>
  )
}

export default WorkspaceDetailsStatusBar
