import { useParams } from 'react-router-dom'

import { Dict } from 'mixpanel-browser'

import { EventKind } from 'openapi/models/EventKind'

import { AssistantMode } from 'components/assistant/components/assistant-mode-select'
import { useAssistantStore } from 'components/assistant/stores/assistant-store'
import { useAnalytics } from 'components/common/analytics/analytics-context'

export const useAssistantAnalytics = () => {
  const { trackEvent } = useAnalytics()
  const { mode } = useParams()

  const eventId = useAssistantStore((s) => s.eventId)
  const taskType =
    mode === AssistantMode.DRAFT
      ? EventKind.ASSISTANT_DRAFT
      : EventKind.ASSISTANT_CHAT
  const sharedProperties: Dict = {
    event_kind: taskType,
  }
  if (eventId) sharedProperties['event_id'] = String(eventId)

  const trackAssistantEvent = (eventName: string, properties?: Dict) => {
    trackEvent(eventName, {
      ...sharedProperties,
      ...properties,
    })
  }

  return trackAssistantEvent
}
