/* tslint:disable */
/* eslint-disable */
/**
 * Contracts Processing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ContractsSmartFilterResult
 */
export interface ContractsSmartFilterResult {
    /**
     * 
     * @type {string}
     * @memberof ContractsSmartFilterResult
     */
    fileName: string;
    /**
     * 
     * @type {boolean}
     * @memberof ContractsSmartFilterResult
     */
    include: boolean;
}

/**
 * Check if a given object implements the ContractsSmartFilterResult interface.
 */
export function instanceOfContractsSmartFilterResult(value: object): value is ContractsSmartFilterResult {
    if (!('fileName' in value) || value['fileName'] === undefined) return false;
    if (!('include' in value) || value['include'] === undefined) return false;
    return true;
}

export function ContractsSmartFilterResultFromJSON(json: any): ContractsSmartFilterResult {
    return ContractsSmartFilterResultFromJSONTyped(json, false);
}

export function ContractsSmartFilterResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContractsSmartFilterResult {
    if (json == null) {
        return json;
    }
    return {
        
        'fileName': json['file_name'],
        'include': json['include'],
    };
}

export function ContractsSmartFilterResultToJSON(value?: ContractsSmartFilterResult | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'file_name': value['fileName'],
        'include': value['include'],
    };
}

