import { HarvQueryKeyPrefix } from 'models/queries/all-query-keys'
import { useWrappedQuery } from 'models/queries/lib/use-wrapped-query'

import { fetchRecentAssistantQueries } from 'components/assistant/utils/assistant-api'

export const useAssistantRecentQueriesHistory = (isEnabled: boolean) => {
  return useWrappedQuery({
    queryKey: [HarvQueryKeyPrefix.AssistantRecentQueries],
    queryFn: () => fetchRecentAssistantQueries(),
    enabled: isEnabled,
  })
}
