import React, { useEffect } from 'react'

import { getWorkspaceRoles, WorkspaceRole } from 'models/roles'
import { Workspace } from 'models/workspace'

import WorkspaceRolesTable from './workspace-roles-table'

interface WorkspaceDetailsRolesProps {
  workspace: Workspace
}

const WorkspaceDetailsRoles = ({ workspace }: WorkspaceDetailsRolesProps) => {
  const [workspaceRoles, setWorkspaceRoles] = React.useState<WorkspaceRole[]>(
    []
  )

  useEffect(() => {
    const fetchWorkspaceRoles = async () => {
      const roles = await getWorkspaceRoles(workspace.id)
      setWorkspaceRoles(roles)
    }
    fetchWorkspaceRoles()
  }, [workspace])

  return (
    <div className="p-3">
      <WorkspaceRolesTable
        workspaceRoles={workspaceRoles}
        workspaceName={workspace.clientName ?? ''}
      />
    </div>
  )
}

export default WorkspaceDetailsRoles
