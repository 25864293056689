// Functions to interact with backend for examples model
import { UsaCaseLawArtifact } from 'models/usa-case-law-artifact'
import Services from 'services'

export async function fetchUsaCaseLaw(
  id: string,
  region?: string,
  sources?: { first: string; last: string; length: number; footnote: number }[]
): Promise<UsaCaseLawArtifact> {
  const params = new URLSearchParams()
  if (sources) {
    params.set('sources', JSON.stringify(sources))
  }
  if (region) {
    params.set('region', region)
  }
  const url =
    `research/usa_caselaw/${id}` +
    (params.toString() ? `?${params.toString()}` : '')
  return await Services.Backend.Get<UsaCaseLawArtifact>(url)
}

interface CourtCitation {
  courtCitation: string
}

export async function fetchCourtCitation(
  courtName: string
): Promise<CourtCitation> {
  const params = new URLSearchParams({ court_name: courtName })
  const url = `research/court_name_to_citation_string?${params.toString()}`
  return await Services.Backend.Get<CourtCitation>(url)
}
