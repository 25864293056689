import React from 'react'
import { Routes, Route } from 'react-router-dom'

import ResearchIndexPage from './research-index-page'
import ResearchPage from './research-page'

const ResearchIndex = () => {
  return (
    <Routes>
      <Route path="/" element={<ResearchIndexPage />} />
      <Route path="/:area/:id?" element={<ResearchPage />} />
    </Routes>
  )
}

export default ResearchIndex
