import { FileRejection, useDropzone } from 'react-dropzone'

import { DiligenceDocument } from 'openapi/models/DiligenceDocument'

import { onDrop } from 'utils/dropzone'
import { createAcceptObject, mbToBytes } from 'utils/file-utils'

import * as config from 'components/workflows/workflow/discovery/config'

export const useDiligenceDropzone = (
  documents: DiligenceDocument[],
  handler: (files: File[]) => Promise<void>,
  setIsUploadingDocuments: (isUploading: boolean) => void
) => {
  const { getRootProps, getInputProps, open } = useDropzone({
    accept: createAcceptObject(config.FILE_TYPES),
    onDrop: (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      setIsUploadingDocuments(true)
      return onDrop({
        acceptedFiles,
        fileRejections,
        currentFileCount: documents?.length ?? 0,
        maxFiles: config.MAX_FILES,
        acceptedFileTypes: config.FILE_TYPES,
        maxFileSize: mbToBytes(config.MAX_FILE_SIZE_MB),
        handleAcceptedFiles: handler,
        handleRejectedFiles: () => setIsUploadingDocuments(false),
        maxTotalFileSizeProps: {
          maxTotalFileSize: mbToBytes(config.MAX_TOTAL_FILE_SIZE_MB),
          currentTotalFileSize:
            documents?.reduce((acc, doc) => acc + (doc.file.size ?? 0), 0) ?? 0,
        },
      })
    },
    maxFiles: config.MAX_FILES,
    maxSize: mbToBytes(config.MAX_FILE_SIZE_MB),
  })

  return { getRootProps, getInputProps, open }
}
