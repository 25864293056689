import React from 'react'
import { DateRange, Matcher } from 'react-day-picker'

import { CalendarRange } from 'lucide-react'

import { readableDateRange } from 'utils/date-utils'
import { cn } from 'utils/utils'

import { Button } from './button'
import { Calendar } from './calendar'
import { Popover, PopoverTrigger, PopoverContent } from './popover'

interface DateRangePickerProps {
  selectedDateRange: DateRange | undefined
  onSelectDateRange: (dateRange: DateRange | undefined) => void
  disableFutureDates?: boolean
  disablePastDatesBeforeDate?: Date
}

const DateRangePicker: React.FC<DateRangePickerProps> = ({
  selectedDateRange,
  onSelectDateRange,
  disableFutureDates = false,
  disablePastDatesBeforeDate,
}) => {
  const disabled = [
    disableFutureDates && { after: new Date() },
    disablePastDatesBeforeDate && { before: disablePastDatesBeforeDate },
  ].filter(Boolean) as Matcher[]
  const [popoverOpen, setPopoverOpen] = React.useState(false)
  const footer = selectedDateRange?.from && (
    <div className="mt-2 flex justify-end">
      <Button
        variant="outline"
        onClick={() => {
          onSelectDateRange(undefined)
          setPopoverOpen(false)
        }}
      >
        Clear
      </Button>
    </div>
  )

  return (
    <Popover open={popoverOpen} onOpenChange={setPopoverOpen}>
      <PopoverTrigger asChild>
        <Button
          id="date"
          variant="outline"
          className={cn(
            'justify-start text-left font-normal',
            !selectedDateRange?.from && 'text-muted'
          )}
        >
          <CalendarRange className="mr-2 h-4 w-4" />
          {formatDateRange(selectedDateRange)}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0" align="end">
        <Calendar
          initialFocus
          mode="range"
          defaultMonth={selectedDateRange?.to}
          selected={selectedDateRange}
          numberOfMonths={1}
          disabled={disabled}
          footer={footer}
          onDayClick={(date) => {
            // Custom logic to handle date range selection
            if (selectedDateRange?.from && selectedDateRange.to) {
              // Clear the date range if both dates are already selected
              onSelectDateRange({ from: date, to: undefined })
            } else if (selectedDateRange?.from) {
              if (selectedDateRange.from.toISOString() === date.toISOString()) {
                // Clear the start date if it's already selected
                onSelectDateRange({ from: undefined, to: undefined })
              } else {
                // Set the end date if the start date is already selected
                onSelectDateRange({
                  from:
                    date < selectedDateRange.from
                      ? date
                      : selectedDateRange.from,
                  to:
                    date > selectedDateRange.from
                      ? date
                      : selectedDateRange.from,
                })
              }
            } else {
              // Set the start date if no date is selected
              onSelectDateRange({ from: date, to: undefined })
            }
          }}
        />
      </PopoverContent>
    </Popover>
  )
}

const formatDateRange = (dateRange: DateRange | undefined) => {
  if (!dateRange?.from) {
    return <p>Pick a date</p>
  } else {
    return <p>{readableDateRange(dateRange)}</p>
  }
}

export default DateRangePicker
