import { ResearchArea } from 'openapi/models/ResearchArea'
import { ResearchFilter } from 'openapi/models/ResearchFilter'
import Services from 'services'

export const FetchResearchTaxonomies = async (
  areaRaw: ResearchArea | null
): Promise<ResearchFilter[]> => {
  const area =
    areaRaw && Object.values(ResearchArea).includes(areaRaw) ? areaRaw : null
  const path = area ? `research/taxonomy?area=${area}` : 'research/taxonomy'
  return Services.Backend.Get<ResearchFilter[]>(path)
}
