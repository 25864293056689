import React, { useState } from 'react'

import { Row } from '@tanstack/react-table'
import _ from 'lodash'
import { Upload } from 'lucide-react'
import { useShallow } from 'zustand/react/shallow'

import { DiligenceDocument } from 'openapi/models/DiligenceDocument'
import { DiligenceDocumentLabel } from 'openapi/models/DiligenceDocumentLabel'

import { mbToReadable } from 'utils/file-utils'

import {
  FileSource,
  KnowledgeSourceConfig,
} from 'components/assistant/utils/assistant-knowledge-sources'
import { VaultKnowledgeSource } from 'components/assistant/utils/assistant-knowledge-sources'
import { KnowledgeSourceItem } from 'components/assistant/utils/assistant-knowledge-sources'
import { useAuthUser } from 'components/common/auth-context'
import { Dropzone } from 'components/common/dropzone/dropzone'
import DropzoneDescription from 'components/common/dropzone/dropzone-description'
import VaultKnowledgeSourcePicker from 'components/common/vault-knowledge-source-picker'
import { Button } from 'components/ui/button'
import { DiligenceDocumentsTable } from 'components/workflows/workflow/discovery/common/documents-table'
import { useDiligenceDropzone } from 'components/workflows/workflow/discovery/common/use-diligence-dropzone'
import * as config from 'components/workflows/workflow/discovery/config'
import { useDiligenceStore } from 'components/workflows/workflow/discovery/diligence-store'

export const DiligenceDocumentsPageInner: React.FC<{
  handleDocumentsChanged: () => void
}> = ({ handleDocumentsChanged }) => {
  const documents = useDiligenceStore((state) => state.documents)
  const [knowledgeSource, setKnowledgeSource] = useDiligenceStore(
    useShallow((s) => [s.knowledgeSource, s.setKnowledgeSource])
  )
  const [isUploadingDocuments, setIsUploadingDocuments] = useDiligenceStore(
    useShallow((state) => [
      state.isUploadingDocuments,
      state.setIsUploadingDocuments,
    ])
  )
  const handleNewFiles = useDiligenceStore((state) => state.handleNewFiles)
  const removeDocument = useDiligenceStore((state) => state.removeDocument)
  const setDocumentTypes = useDiligenceStore((state) => state.setDocumentTypes)
  const [isAddingVault, setIsAddingVault] = useState<boolean>(!!knowledgeSource)

  const userInfo = useAuthUser()
  const isVaultEnabled = userInfo.isDealsDiscoveryFromVaultUser

  const handleNewFilesWrapper = async (files: File[]) => {
    await handleNewFiles(files)
    handleDocumentsChanged()
  }

  const { getRootProps, getInputProps, open } = useDiligenceDropzone(
    documents,
    handleNewFilesWrapper,
    setIsUploadingDocuments
  )

  const onMultiSelectChange = (
    row: Row<DiligenceDocument>,
    selectedValues: DiligenceDocumentLabel[]
  ) => {
    setDocumentTypes(row.original.file.name, selectedValues)
  }

  const onDeleteClick = (row: Row<DiligenceDocument>) => {
    removeDocument(row.original.file.name)
    handleDocumentsChanged()
  }
  const handleSourceCancel = () => {
    setIsAddingVault(false)
    setKnowledgeSource(null)
  }

  const isAnyDocuments =
    !(_.isNil(documents) || _.isEmpty(documents)) || !_.isNil(knowledgeSource)

  const vaultSource = () => {
    const config = KnowledgeSourceConfig[FileSource.VAULT]
    if (!config) return null

    return (
      <Button
        key={FileSource.VAULT}
        className="text-primary"
        onClick={(e) => {
          e.stopPropagation()
          setIsAddingVault(true)
        }}
        size="sm"
        variant="outline"
      >
        {React.cloneElement(config.icon, {
          className: 'h-4 w-4 shrink-0 mr-2',
        })}
        {config.label}
      </Button>
    )
  }

  return (
    <div className="h-full w-full">
      {isAnyDocuments ? (
        !(_.isNil(documents) || _.isEmpty(documents)) ? (
          <div className="relative flex h-full flex-col">
            <div className="min-h-0 grow">
              <DiligenceDocumentsTable
                documents={documents}
                onMultiSelectChange={onMultiSelectChange}
                onDeleteClick={onDeleteClick}
              />
            </div>
            <Button
              className="absolute right-2 top-2 z-20"
              variant="outline"
              onClick={open}
            >
              <Upload size={16} className="mr-1" />
              Upload
            </Button>
          </div>
        ) : null
      ) : (
        <div className="flex h-full w-full justify-center">
          <Dropzone
            className="h-full w-full"
            isLoading={isUploadingDocuments}
            dropzone={{ getRootProps, getInputProps }}
            description={
              <DropzoneDescription
                fileTypes={config.FILE_TYPES}
                maxSize={mbToReadable(config.MAX_FILE_SIZE_MB)}
              >
                {isVaultEnabled && (
                  <span className="flex flex-col items-center">
                    or
                    <span className="mt-2 flex flex-wrap justify-center gap-1">
                      {vaultSource()}
                    </span>
                  </span>
                )}
              </DropzoneDescription>
            }
          />
        </div>
      )}
      {isAddingVault && (
        <div className="h-full rounded-lg border">
          <VaultKnowledgeSourcePicker
            knowledgeSource={knowledgeSource}
            setKnowledgeSource={(knowledgeSource: KnowledgeSourceItem | null) =>
              setKnowledgeSource(knowledgeSource as VaultKnowledgeSource)
            }
            onClose={handleSourceCancel}
          />
        </div>
      )}
    </div>
  )
}
