import React, { useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { Row } from '@tanstack/react-table'

import { TranscriptType } from 'openapi/models/TranscriptType'
import { TranscriptsDocument } from 'openapi/models/TranscriptsDocument'
import { TranscriptsQuestionAnswer } from 'openapi/models/TranscriptsQuestionAnswer'

import ErrorPage from 'components/common/error/error'
import { PdfViewerSheet } from 'components/common/pdf-viewer/pdf-viewer-sheet'
import { TranscriptsFilesTable } from 'components/workflows/workflow/transcripts/common/files-table'
import { TranscriptsSubheader } from 'components/workflows/workflow/transcripts/common/subheader'
import { TranscriptsTopicList } from 'components/workflows/workflow/transcripts/common/topic-list'
import { TranscriptsTopicView } from 'components/workflows/workflow/transcripts/common/topic-view'
import { TranscriptsLayout } from 'components/workflows/workflow/transcripts/layout'
import { getTranscriptsFileViewFromTypePage } from 'components/workflows/workflow/transcripts/pages'
import { useTranscriptsStore } from 'components/workflows/workflow/transcripts/transcripts-store'
import {
  TranscriptsDocOrQA,
  findMatchingDocumentAndSource,
} from 'components/workflows/workflow/transcripts/util/util'
import { getAllWitnessesWithTopics } from 'components/workflows/workflow/transcripts/util/witness-util'

import { WitnessCards } from './witness-cards'

export const TranscriptsByType: React.FC = () => {
  const { documents, questions } = useTranscriptsStore()
  const navigate = useNavigate()
  const [viewingQuestion, setViewingQuestion] =
    React.useState<TranscriptsQuestionAnswer | null>(null)
  const { type } = useParams<{ type: TranscriptType }>()

  const [documentSheet, setDocumentSheet] = React.useState<{
    document: TranscriptsDocument | null
    page: number | null
    show: boolean
  }>({
    document: null,
    page: null,
    show: false,
  })

  const documentsMatchingType = documents.filter((doc) => {
    return doc.metadata?.transcriptType === type
  })

  const questionsContent = questions
    .filter((q) => q.documentType === type)
    .map((question) => ({
      title: question.question,
      markdownContent: question.answer.text ?? '',
      docOrQA: question,
    }))

  const witnesses = useMemo(
    () =>
      getAllWitnessesWithTopics(documentsMatchingType).sort((a, b) =>
        a.name.localeCompare(b.name)
      ),
    [documentsMatchingType]
  )

  const handleFileClick = (row: Row<TranscriptsDocument>) => {
    navigate(
      getTranscriptsFileViewFromTypePage(
        row.original.file.name,
        type as TranscriptType
      )
    )
  }

  const handleSourceClick = (id: string, source: TranscriptsDocOrQA) => {
    const match = findMatchingDocumentAndSource(
      id,
      source,
      documentsMatchingType
    )

    if (!match) return

    setDocumentSheet({
      document: match.document,
      page: match.source.page,
      show: true,
    })
  }

  if (!type) {
    return <ErrorPage />
  }

  return (
    <TranscriptsLayout type={type}>
      <TranscriptsSubheader documents={documents} />

      {documentSheet.show && (
        <PdfViewerSheet
          document={documentSheet.document!.file}
          page={documentSheet.page!}
          showSheet={documentSheet.show}
          setShowSheet={(show) =>
            setDocumentSheet((prev) => ({ ...prev, show }))
          }
        />
      )}
      <div className="flex h-full flex-col space-y-4">
        <div className="space-y-1">
          <TranscriptsFilesTable
            documents={documentsMatchingType}
            onRowClick={handleFileClick}
          />
        </div>
        {witnesses.length > 0 && (
          <div className="space-y-1">
            <WitnessCards type={type} witnesses={witnesses} />
          </div>
        )}
        {questionsContent.length > 0 && (
          <div className="space-y-1 pb-8">
            <p className="mb-2 text-xl">User questions</p>
            {!viewingQuestion ? (
              <TranscriptsTopicList
                titles={questions.map((q) => q.question)}
                onClick={(i) => setViewingQuestion(questions[i])}
              />
            ) : (
              <TranscriptsTopicView
                close={() => setViewingQuestion(null)}
                onSourceClick={(i: string) =>
                  handleSourceClick(i, viewingQuestion)
                }
                title={viewingQuestion.question}
                markdownContent={viewingQuestion.answer.text ?? ''}
              />
            )}
          </div>
        )}
      </div>
    </TranscriptsLayout>
  )
}

export default TranscriptsByType
