import React from 'react'

import OneDriveIcon from 'components/ui/icons/onedrive-icon'
import SharePointIcon from 'components/ui/icons/sharepoint-icon'

const SharepointOneDriveLogo: React.FC = () => {
  return (
    <div className="flex items-center space-x-2 pl-px">
      <div className="flex items-center space-x-1">
        <SharePointIcon className="size-3" />
        <span className="text-sm">SharePoint</span>
      </div>
      <span> / </span>
      <div className="flex items-center space-x-1">
        <OneDriveIcon className="size-4" />
        <span className="text-sm">OneDrive</span>
      </div>
    </div>
  )
}

export default SharepointOneDriveLogo
