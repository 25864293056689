import React from 'react'

import { useShallow } from 'zustand/react/shallow'

import { useUserWorkspacesQuery } from 'models/queries/use-workspace-query'
import { Workspace } from 'models/workspace'

import { displayErrorMessage } from 'utils/toast'
import { cn } from 'utils/utils'

import { useAuthUser } from 'components/common/auth-context'
import WorkspaceDropdown from 'components/dashboard/workspace-dropdown'
import { ScrollArea } from 'components/ui/scroll-area'
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/ui/tooltip'

import SettingsSidebar from './settings-sidebar'
import { useSettingsState } from './settings-store'

interface Props {
  children: React.ReactNode
  id?: string
}

const SettingsLayout = ({ children, id }: Props) => {
  const userInfo = useAuthUser()
  const [settingsUser, setSettingsUser, isSettingsSidebarCollapsed] =
    useSettingsState(
      useShallow((s) => [
        s.settingsUser,
        s.setSettingsUser,
        s.isSettingsSidebarCollapsed,
      ])
    )

  const { data: workspaceData } = useUserWorkspacesQuery()
  const workspaces = workspaceData || []

  const selectedWorkspace = settingsUser?.workspace ?? workspaces[0]

  const workspaceDropdownWillRender = workspaces.length > 1

  const setSettingsWorkspace = (workspace: Workspace) => {
    try {
      if (!settingsUser) {
        throw new Error('No settingsUser')
      }
      settingsUser.SetWorkspace(workspace, userInfo)
      setSettingsUser(settingsUser)
    } catch (e) {
      displayErrorMessage(`Unable to select ${workspace.clientName}`)
    }
  }

  return (
    <div
      className="flex min-h-0 grow items-stretch"
      data-testid="settings-container"
    >
      <ScrollArea
        className={cn('z-10 h-full shrink-0 p-4', {
          'hidden sm:flex': isSettingsSidebarCollapsed,
        })}
      >
        {workspaceDropdownWillRender && ( // workspace dropdown doesn't render unless more than 1 workspace is available
          <div className="mb-6">
            <Tooltip>
              <TooltipTrigger>
                <WorkspaceDropdown
                  workspaces={workspaces.filter((w) => !w.deletedAt)}
                  selected={selectedWorkspace}
                  setSelected={setSettingsWorkspace}
                  className="w-48"
                />
              </TooltipTrigger>
              <TooltipContent side="right">
                <p>
                  View and manage settings for {selectedWorkspace.clientName}
                </p>
              </TooltipContent>
            </Tooltip>
          </div>
        )}
        <div className="px-2.5">
          <SettingsSidebar />
        </div>
      </ScrollArea>
      <ScrollArea className="h-full grow">
        <div className="p-4" id={id}>
          {children}
        </div>
      </ScrollArea>
    </div>
  )
}

export default SettingsLayout
