import React, { useCallback } from 'react'
import { useSearchParams } from 'react-router-dom'

import { usePDFViewerStore } from 'stores/pdf-viewer-store'

import { unloadPDFDiv } from 'utils/pspdfkit'
import { Source } from 'utils/task'
import { displayErrorMessage } from 'utils/toast'

import {
  fileIdSearchParamKey,
  sourceIdSearchParamKey,
} from 'components/vault/utils/vault'
import { useVaultStore } from 'components/vault/utils/vault-store'

import VaultQueryResponse from './vault-query-response'
import VaultQuerySources from './vault-query-sources'

const VaultAskQueryResponse = ({
  sendCancelRequestN1,
}: {
  sendCancelRequestN1: () => void
}) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const fileId = searchParams.get(fileIdSearchParamKey)
  const sourceId = searchParams.get(sourceIdSearchParamKey)
  const fileIdToVaultFile = useVaultStore((s) => s.fileIdToVaultFile)
  const setInstance = usePDFViewerStore((s) => s.setInstance)
  const setActiveDocument = useVaultStore((s) => s.setActiveDocument)

  const onDocumentSourceClick = useCallback(
    (source: Source) => {
      const sourceFileId = source.documentId
      if (!sourceFileId) {
        displayErrorMessage('This file does not exist.')
        return
      }
      const file = fileIdToVaultFile[sourceFileId]
      if (!file) {
        displayErrorMessage('This file does not exist.')
        return
      }
      const isExistingFile = fileId === source.documentId
      if (isExistingFile && sourceId === source.id) return
      if (isExistingFile) {
        setSearchParams((prev) => {
          const newParams = new URLSearchParams(prev)
          newParams.set(sourceIdSearchParamKey, source.id)
          return newParams
        })
        return
      }
      setInstance(null)
      unloadPDFDiv()
      setActiveDocument(file)
      setSearchParams((prev) => {
        const newParams = new URLSearchParams(prev)
        // @ts-expect-error ts(2345)
        newParams.set(fileIdSearchParamKey, source.documentId)
        newParams.set(sourceIdSearchParamKey, source.id)
        return newParams
      })
    },
    [
      fileId,
      fileIdToVaultFile,
      setActiveDocument,
      setInstance,
      setSearchParams,
      sourceId,
    ]
  )

  return (
    <>
      <VaultQueryResponse
        sendCancelRequest={sendCancelRequestN1}
        onDocumentSourceClick={onDocumentSourceClick}
      />
      <VaultQuerySources onDocumentSourceClick={onDocumentSourceClick} />
    </>
  )
}

export default VaultAskQueryResponse
