import { MessageFeedback } from 'openapi/models/MessageFeedback'
import Services from 'services'
import { Maybe } from 'types'

const CreateMessageFeedback = async (
  eventId: Maybe<string>,
  messageId: string,
  feedback: MessageFeedback
) => {
  if (!eventId) throw new Error('Event ID is not set')

  const requestPath = `assistant/feedback/${eventId}/${messageId}`
  const response = await Services.Backend.Post(requestPath, feedback, {
    throwOnError: true,
  })
  return response
}

export { CreateMessageFeedback }
