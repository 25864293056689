import Services from 'services'

export const AUDIT_LOG_DEBOUNCE_TIME = 1000

export type AuditLog = {
  timestamp: string
  logType: string
  workspaceId: number
  data: Record<string, unknown>
  userId?: string
}

export const postAuditLogToBackend = async (
  auditLog: AuditLog
): Promise<void> => {
  await Services.Backend.Post('audit_log', auditLog)
}

export const usePostAuditLog = () => {
  const postAuditLog = async (
    logType: string,
    workspaceId: number,
    data?: Record<string, unknown>
  ) => {
    const logData = {
      userAgent: window.navigator.userAgent,
      ...data,
    }
    const auditLog: AuditLog = {
      timestamp: new Date().toISOString(),
      logType,
      workspaceId,
      data: logData,
    }
    await postAuditLogToBackend(auditLog)
  }
  return { postAuditLog }
}
