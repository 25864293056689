import React from 'react'
import { useNavigate } from 'react-router-dom'

import { mbToReadable } from 'utils/file-utils'

import { Dropzone } from 'components/common/dropzone/dropzone'
import DropzoneDescription from 'components/common/dropzone/dropzone-description'
import { TranscriptsFilesTable } from 'components/workflows/workflow/transcripts/common/files-table'
import { TranscriptsSubheader } from 'components/workflows/workflow/transcripts/common/subheader'
import * as config from 'components/workflows/workflow/transcripts/config'
import { useTranscriptsDropzone } from 'components/workflows/workflow/transcripts/hooks/use-dropzone'
import { TranscriptsLayout } from 'components/workflows/workflow/transcripts/layout'
import { getTranscriptsFileViewFromRootPage } from 'components/workflows/workflow/transcripts/pages'
import { useTranscriptsStore } from 'components/workflows/workflow/transcripts/transcripts-store'

import { CaseTypeCards } from './case-type-cards'

export const TranscriptsCaseView: React.FC = () => {
  const navigate = useNavigate()
  const { documents, handleNewFiles, handleNewFilesCompleted, setter } =
    useTranscriptsStore()

  const { getRootProps, getInputProps } = useTranscriptsDropzone({
    handleNewFiles,
    handleNewFilesCompleted,
    documents,
    setter,
  })

  const handleRowClick = (row: any) => {
    if (row.original.isLoading) return
    navigate(getTranscriptsFileViewFromRootPage(row.original.file.name))
  }

  if (!documents.length) {
    return (
      <TranscriptsLayout>
        <div className="flex h-full w-full justify-center">
          <Dropzone
            className="h-full w-full"
            isLoading={false}
            dropzone={{ getRootProps, getInputProps }}
            description={
              <DropzoneDescription
                fileTypes={config.FILE_TYPES}
                maxSize={mbToReadable(config.MAX_FILE_SIZE_MB)}
              />
            }
          />
        </div>
      </TranscriptsLayout>
    )
  }

  return (
    <TranscriptsLayout>
      <TranscriptsSubheader documents={documents} />

      <div className="h-full w-full">
        <div className="space-y-4">
          <CaseTypeCards documents={documents} />
          <TranscriptsFilesTable
            documents={documents}
            onRowClick={handleRowClick}
            pageSize={10}
          />
        </div>
      </div>
    </TranscriptsLayout>
  )
}

export default TranscriptsCaseView
