import { PdfKitSource } from 'openapi/models/PdfKitSource'
import {
  TranscriptsDocument,
  instanceOfTranscriptsDocument,
} from 'openapi/models/TranscriptsDocument'
import { TranscriptsQuestionAnswer } from 'openapi/models/TranscriptsQuestionAnswer'

import { displayErrorMessage, displayWarningMessage } from 'utils/toast'

import { exportTranscripts } from 'components/workflows/workflow/transcripts/export'

export type TranscriptsDocOrQA = TranscriptsDocument | TranscriptsQuestionAnswer

// Find the matching source by sourceId in the document or question answer
// If the source is found, return the document and the source
export const findMatchingDocumentAndSource = (
  sourceId: string,
  docOrQA: TranscriptsDocOrQA,
  allDocs?: TranscriptsDocument[]
): { document: TranscriptsDocument; source: PdfKitSource } | null => {
  let document: TranscriptsDocument | null = null
  let source: PdfKitSource | null = null

  if (instanceOfTranscriptsDocument(docOrQA)) {
    // Assuming instanceOfTranscriptsDocument can be replaced with a property check
    document = docOrQA as TranscriptsDocument
    source =
      document.topics
        .flatMap((topic) => topic.sources)
        .find((s) => s?.id === sourceId) ?? null
  } else {
    source =
      (docOrQA as TranscriptsQuestionAnswer).answer.sources?.find(
        (s) => s.id === sourceId
      ) ?? null
    if (source) {
      document =
        allDocs?.find((doc) => doc.file.name === source?.documentName) ?? null
    }
  }

  if (!document || !source) {
    console.error('Couldn’t find matching document or source.')
    displayErrorMessage(
      'Unexpected: Couldn’t find matching document or source.'
    )
    return null
  }

  return { document, source }
}

export const handleExport = (
  documents: TranscriptsDocument[],
  qa: TranscriptsQuestionAnswer[]
) => {
  if (documents.some((doc) => doc.isLoading)) {
    displayWarningMessage('Please wait for all files to finish loading')
    return
  }
  exportTranscripts(documents, qa)
}
