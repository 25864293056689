import * as React from 'react'

import { cn } from 'utils/utils'

interface Description {
  label: string
  variant: 'default' | 'error' | 'warning' | 'success'
  leadingIcon?: React.ReactElement
}

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  description?: Description
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, description, ...props }, ref) => {
    return (
      <>
        <input
          type={type}
          className={cn(
            'flex h-10 w-full rounded-md border border-input bg-primary px-3 py-2 text-sm ring-offset-primary file:border-0 file:bg-transparent file:text-sm file:font-semibold placeholder:pb-0.5 placeholder:text-sm placeholder:text-muted focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
            className
          )}
          ref={ref}
          {...props}
        />
        {description && (
          <p
            className={cn(
              'ml-2 mt-1.5 inline-flex items-center text-xs text-muted',
              {
                'text-destructive': description.variant === 'error',
                'text-warning': description.variant === 'warning',
                'text-success': description.variant === 'success',
              }
            )}
          >
            {description.leadingIcon &&
              React.cloneElement(description.leadingIcon, {
                size: 12,
                className: 'mr-1',
              })}
            {description.label}
          </p>
        )}
      </>
    )
  }
)
Input.displayName = 'Input'

export { Input }
