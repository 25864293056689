import React, { useEffect, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import _ from 'lodash'
import { Download } from 'lucide-react'

import { UploadedFile } from 'openapi/models/UploadedFile'

import { downloadUploadedFiles } from 'utils/download'
import {
  displayErrorMessage,
  displayInfoMessage,
  displayWarningMessage,
} from 'utils/toast'
import { cn, findScrollingContainer } from 'utils/utils'

import { useAssistantFileStore } from 'components/assistant/stores/assistant-file-store'
import { useAssistantStore } from 'components/assistant/stores/assistant-store'
import { FILE_ID_PARAM } from 'components/assistant/utils/assistant-helpers'
import { Button } from 'components/ui/button'
import Icon from 'components/ui/icon/icon'

interface LayoutProps {
  children: React.ReactNode
}

export const AssistantThreadLayout = ({ children }: LayoutProps) => {
  return <div className="align-stretch flex h-full">{children}</div>
}

interface ContentProps {
  children: React.ReactNode
  className?: string
  contentId?: string
  hasSidebar?: boolean
  sidebar?: React.ReactNode
}

export const AssistantThreadContent = ({
  children,
  className,
  contentId,
  hasSidebar,
  sidebar,
}: ContentProps) => {
  return (
    <div
      className={cn('mx-auto flex w-full max-w-[1000px] px-6', className, {
        // Horizontally center main content with placeholder sidebars on either side.
        // 1640 = (1000px = max width of regular container) + 320px * 2 (= width of sidebar)
        'max-w-[1640px]': hasSidebar,
      })}
    >
      {hasSidebar && <AssistantThreadSidebarPlaceholderLeft />}
      {/* 936 = (1000px = max width of container) - 32px * 2 (= container padding) */}
      <div className="w-full max-w-[936px] grow" id={contentId}>
        {children}
      </div>
      {hasSidebar && (sidebar || <AssistantThreadSidebarPlaceholderRight />)}
    </div>
  )
}

interface SidebarProps {
  children?: React.ReactNode
  className?: string
}

export const AssistantThreadSidebar = ({
  children,
  className,
}: SidebarProps) => {
  const sidebarRef = useRef<HTMLDivElement | null>(null)
  const [isSticky, setIsSticky] = useState(true)

  useEffect(() => {
    if (!sidebarRef.current) return

    const containerEl = findScrollingContainer(sidebarRef.current)
    if (!containerEl) return

    setIsSticky(sidebarRef.current.offsetHeight <= containerEl.offsetHeight)
  }, [children])

  // Hide the sidebar sections when push sheet is open
  const [searchParams] = useSearchParams()
  const fileId = searchParams.get(FILE_ID_PARAM)
  if (fileId) return null

  return (
    <div
      className={cn(
        'box-border w-40 min-w-[auto] grow basis-0 sm:w-80',
        className
      )}
    >
      <div
        className={cn('space-y-4', {
          'sticky top-8': isSticky,
        })}
        ref={sidebarRef}
      >
        {children}
      </div>
    </div>
  )
}

interface SidebarSectionProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string
  children?: React.ReactNode
  documents?: UploadedFile[]
  innerClassName?: string
  title?: string
}

export const AssistantThreadSidebarSection = ({
  children,
  className,
  documents,
  innerClassName,
  title,
  ...props
}: SidebarSectionProps) => {
  const hasFiles = !!documents && !_.isEmpty(documents)

  const eventId = useAssistantStore((s) => s.eventId)
  const userCaption = useAssistantStore((s) => s.userCaption)
  const messages = useAssistantStore((s) => s.messages)
  const exportTitle = userCaption || messages[0]?.caption || eventId
  const getDocument = useAssistantFileStore((s) => s.getDocument)

  const header = (!!title || hasFiles) && (
    <div
      className={cn(
        'flex items-center p-2',
        {
          'justify-between': !!title && hasFiles,
          'justify-end': !title && hasFiles,
        },
        innerClassName
      )}
    >
      {!!title && <h5 className="text-sm font-semibold">{title}</h5>}
      {hasFiles && (
        <Button
          variant="ghost"
          size="xsIcon"
          onClick={() =>
            fetchAndDownloadDocuments({
              documents,
              exportTitle,
              eventId,
              getDocument,
              title,
            })
          }
          aria-label={`Download ${title || 'files'}`}
        >
          <Icon icon={Download} aria-hidden="true" />
        </Button>
      )}
    </div>
  )

  return (
    <div
      className={cn(
        'ml-4 w-36 space-y-2 rounded-md border px-2 py-4 sm:ml-8 sm:w-72',
        className
      )}
      {...props}
    >
      {header}
      {children}
    </div>
  )
}

const fetchAndDownloadDocuments = async ({
  documents,
  eventId,
  exportTitle,
  getDocument,
  title,
}: {
  documents: UploadedFile[]
  eventId: string | null
  exportTitle: string | null
  getDocument: (
    eventId: string | null,
    documentId: string
  ) => Promise<UploadedFile | undefined>
  title?: string
}) => {
  displayInfoMessage('Your download will start shortly.', 5)

  // Sequentially fetch documents is fine since assistant file store will cache all docs for a given event
  const documentsDict = await documents.reduce(
    async (dict, doc) => {
      const acc = await dict
      const document = await getDocument(eventId ?? null, doc.id)
      if (document) {
        acc[doc.id] = document
      }
      return acc
    },
    Promise.resolve({} as Record<string, UploadedFile | undefined>)
  )

  const documentsReadyToDownload: UploadedFile[] = []
  const documentIdsNotReadyToDownload: string[] = []
  Object.entries(documentsDict).forEach(([id, document]) => {
    if (!!document && !!document.url) {
      documentsReadyToDownload.push(document)
    } else {
      documentIdsNotReadyToDownload.push(id)
    }
  })

  // This should never happen, but if it does, we'll display a warning message
  if (documentIdsNotReadyToDownload.length > 0) {
    if (documentsReadyToDownload.length > 0) {
      const message = `There are currently ${documentIdsNotReadyToDownload.length} files that are not available. Skipping these files.`
      displayWarningMessage(message)
      console.warn(message, {
        filesNotReadyToDownload: documentIdsNotReadyToDownload,
      })
    } else {
      // This really should never happen, but if it does, we'll display an error message
      const message =
        'There are currently no files that are available to download.'
      displayErrorMessage(message)
      console.error(message)
    }
  }

  await downloadUploadedFiles({
    uploadedFiles: documentsReadyToDownload.map((doc) => ({
      id: doc.id,
      name: doc.name,
      url: doc.url!,
    })),
    zippedFileName: `${exportTitle ?? ''}${title ? ` ${title}` : ''}`,
  })
}

export const AssistantThreadSidebarSubSection = ({
  children,
  className,
}: {
  children: React.ReactNode
  className?: string
}) => {
  return (
    <div
      className={cn(
        "relative mt-4 px-2 pb-2 pt-4 before:absolute before:-left-2 before:-right-2 before:top-0 before:h-px before:border-t before:border-primary before:content-[''] last:pb-0",
        className
      )}
    >
      {children}
    </div>
  )
}

// Placeholder that doesn't shrink, so it matches sidebar section width
export const AssistantThreadSidebarPlaceholderRight = () => {
  return (
    <AssistantThreadSidebar>
      <AssistantThreadSidebarSection className="invisible" />
    </AssistantThreadSidebar>
  )
}

// Placeholder that shrinks on smaller screens so the main content can take up more space
export const AssistantThreadSidebarPlaceholderLeft = () => (
  <AssistantThreadSidebar />
)
