import React, { useEffect } from 'react'
import { useParams, useLocation } from 'react-router-dom'

import { useHistoryItemQuery } from 'models/queries/use-history-item-query'
import { Maybe } from 'types'
import { WorkflowType } from 'types/workflows'

import { useNavigateWithQueryParams } from 'hooks/use-navigate-with-query-params'
import { SafeRecord } from 'utils/safe-types'
import { TaskType } from 'utils/task'

import { AppHeader } from 'components/common/app-header'
import { AppMain } from 'components/common/app-main'
import { useAuthUser } from 'components/common/auth-context'
import ErrorPage, { ErrorPageTitle } from 'components/common/error/error'
import FullscreenLoading from 'components/common/fullscreen-loading'
import { getWorkflowSubtitle } from 'components/workflows/constants'
import { KindToWorkflowType } from 'components/workflows/workflow-config'
import { WorkflowTypeToDetails } from 'components/workflows/workflow-definitions'
import { userHasPermissionForWorkflow } from 'components/workflows/workflows-utils'

// This is needed to keep backward compatibility with old URLs
// e.g. we migrate from /redlines to /redline-analysis but we still want
// to support the old URL that user might have bookmarked
const FallbackWorkflowIdToType: SafeRecord<string, WorkflowType> = {
  redlines: WorkflowType.REDLINE_ANALYSIS,
}

const WorkflowContainer: React.FC = () => {
  const navigate = useNavigateWithQueryParams()
  const userInfo = useAuthUser()
  const { state } = useLocation()
  const { workflow_id: workflowId, id: historyId } = useParams()
  const isEnabled =
    !state || state?.fetchHistoryItem === undefined || state?.fetchHistoryItem
  const { historyItem, isFetched, isPending } = useHistoryItemQuery({
    id: historyId ?? null,
    isEnabled: isEnabled,
  })
  const isDataFetched = isEnabled ? isFetched : true

  useEffect(() => {
    // if we have state, let's clear it so that the tab can refresh
    // without fetchHistoryItem
    // clearing state like this prevents an extra re-render from useLocation
    if (state) window.history.replaceState({}, '')
  }, [state])

  let workflowType: Maybe<WorkflowType>
  if (historyItem && historyItem.kind in KindToWorkflowType) {
    // If history item is provided, use that to determine the workflow type
    workflowType = KindToWorkflowType[historyItem.kind as TaskType]
  } else if (
    workflowId &&
    Object.values(WorkflowType).includes(workflowId as WorkflowType)
  ) {
    // Otherwise, use the workflow_id (from the URL) to determine the workflow type
    workflowType = workflowId as WorkflowType
  } else if (workflowId && workflowId in FallbackWorkflowIdToType) {
    workflowType = FallbackWorkflowIdToType[workflowId]
  } else {
    workflowType = undefined
  }
  const workflow = workflowType
    ? WorkflowTypeToDetails[workflowType]
    : undefined

  if (
    workflowType &&
    (!userInfo || !userHasPermissionForWorkflow(userInfo, workflowType))
  ) {
    return <ErrorPage title={ErrorPageTitle.NOT_AUTHORIZED} />
  }

  const WorkflowComponent = workflow?.component?.()

  if (!WorkflowComponent) {
    console.error(`No component found for workflow type: ${workflowType}`)
    return (
      <ErrorPage
        primaryCTA={{
          text: 'Back to Dashboard',
          onClick: () => {
            navigate('/')
          },
        }}
        title={ErrorPageTitle.PAGE_NOT_FOUND}
        description={`The requested workflow /${workflowId} you are trying to access does not exist.`}
      />
    )
  }

  if (historyId && !isDataFetched && !isPending) {
    return (
      <AppMain>
        <AppHeader
          title={workflow?.name}
          subtitle={workflow?.type ? getWorkflowSubtitle(workflow.type) : ''}
        />
        <FullscreenLoading isLoading />
      </AppMain>
    )
  }

  if (historyId && !historyItem && isEnabled) {
    return (
      <ErrorPage
        primaryCTA={{
          text: `Back to ${workflow?.name}`,
          onClick: () => {
            navigate(workflow?.path ?? '')
          },
        }}
        title={ErrorPageTitle.PAGE_NOT_FOUND}
        description={`The requested history item /${historyId} you are trying to access does not exist.\nContact support@harvey.ai if this issue persists.`}
      />
    )
  }

  return <WorkflowComponent />
}

export default WorkflowContainer
