import React, { useState } from 'react'
import { useMemo } from 'react'

import _ from 'lodash'
import pluralize from 'pluralize'
import { Area, AreaChart, XAxis, YAxis } from 'recharts'

import { UsageData } from 'models/usage-data'
import { TimeInterval } from 'openapi/models/TimeInterval'

import { readableNumber, cn } from 'utils/utils'

import { Card, CardContent, CardHeader } from 'components/ui/card'
import {
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from 'components/ui/chart-components/chart'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/ui/select'
import { Separator } from 'components/ui/separator'
import Skeleton from 'components/ui/skeleton'

import { ChartConfigType, CHART_CONFIGS } from './dashboard-page'

export interface AreaChartCardProps {
  usageData: UsageData | undefined
  isLoading: boolean
  unit: ChartConfigType
}

const TIME_INTERVAL_OPTIONS: Record<TimeInterval, string> = {
  [TimeInterval.DAY]: 'Daily',
  [TimeInterval.MONTH]: 'Monthly',
}

const AreaChartCard = ({ usageData, unit, isLoading }: AreaChartCardProps) => {
  const [selectedTimeInterval, setSelectedTimeInterval] =
    useState<TimeInterval>(TimeInterval.DAY)

  const totalGraphCount = useMemo(() => {
    if (_.isNil(usageData)) {
      return 0
    }
    switch (unit) {
      case CHART_CONFIGS.users:
        return usageData.aggregate.userCountTotal
      case CHART_CONFIGS.queries:
        return usageData.aggregate.queryCountTotal
      default:
        console.error(`Unknown unit: ${unit}`)
        return 0
    }
  }, [usageData, unit])

  // NOTE maybe we just let area chart handle this with data keys
  const chartData = useMemo(() => {
    if (_.isNil(usageData)) {
      return []
    }
    switch (unit) {
      case CHART_CONFIGS.users:
        return usageData.timeseriesMap[selectedTimeInterval].map((data) => ({
          timestamp: data.timestamp,
          parsedDateShortened: data.parsedDateShortened,
          parsedDateString: data.parsedDateString,
          count: data.userCountTotal,
        }))
      case CHART_CONFIGS.queries:
        return usageData.timeseriesMap[selectedTimeInterval].map((data) => ({
          timestamp: data.timestamp,
          parsedDateShortened: data.parsedDateShortened,
          parsedDateString: data.parsedDateString,
          count: data.queryCountTotal,
        }))
      default:
        console.error(`Unknown unit: ${unit}`)
        return []
    }
  }, [usageData, unit, selectedTimeInterval])

  const chartConfig = {
    count: {
      label: unit.tooltipLabel,
    },
  }

  return (
    <Card className="rounded-lg border">
      <CardHeader className="pl-2 pr-2 pt-2">
        <div className="flex items-center justify-between">
          <div className="text-neutral-950 truncate text-sm font-semibold">
            {unit.areaChart.title}
          </div>
          {/* TODO correct color ? */}
          <div className="flex items-center gap-4">
            <Select
              onValueChange={(value) =>
                setSelectedTimeInterval(value as TimeInterval)
              }
            >
              <SelectTrigger className="h-8 md:min-w-32">
                <SelectValue>
                  {TIME_INTERVAL_OPTIONS[selectedTimeInterval]}
                </SelectValue>
              </SelectTrigger>
              <SelectContent className="overflow-y-auto">
                {Object.entries(TIME_INTERVAL_OPTIONS).map(
                  ([timeInterval, displayValue]) => (
                    <SelectItem key={timeInterval} value={timeInterval}>
                      {displayValue}
                    </SelectItem>
                  )
                )}
              </SelectContent>
            </Select>
            <div className="truncate text-sm text-muted">
              Total: {readableNumber(totalGraphCount)}{' '}
              {pluralize(unit.tooltipLabel.toLowerCase(), totalGraphCount)}
            </div>
          </div>
        </div>
      </CardHeader>
      <Separator className="w-full" />
      <CardContent
        className={cn('h-80', { 'p-0': !isLoading, 'pt-6': isLoading })}
      >
        {isLoading && <Skeleton className="h-full" />}
        {!isLoading && totalGraphCount > 0 && (
          <ChartContainer config={chartConfig} className="mt-4">
            <AreaChart
              data={chartData}
              // top margin to prevent highest data point from being cut off
              margin={{ top: 4, right: 0, left: 0, bottom: 0 }}
              //   TODO if you choose 4 day interval, only 2 labels will show up
            >
              <XAxis
                dataKey="parsedDateShortened"
                interval="equidistantPreserveStart"
                tickLine={false}
                tick={({ x, y, payload }) => {
                  return (
                    <g transform={`translate(${x},${y})`}>
                      <text
                        x={0}
                        y={0}
                        dy={12}
                        textAnchor="middle"
                        className="text-xs"
                      >
                        {readableNumber(payload.value)}
                      </text>
                    </g>
                  )
                }}
              />
              <YAxis
                allowDecimals={false}
                tickLine={false}
                width={40}
                tick={({ x, y, payload }) => {
                  return (
                    <g transform={`translate(${x},${y})`}>
                      <text
                        x={0}
                        y={0}
                        dx={-12}
                        textAnchor="middle"
                        className="text-xs"
                      >
                        {readableNumber(payload.value)}
                      </text>
                    </g>
                  )
                }}
              />
              <ChartTooltip
                cursor={false}
                content={<ChartTooltipContent payloadKey="parsedDateString" />}
              />
              <>
                <defs>
                  <linearGradient
                    id="gradient-jade"
                    x1="0"
                    x2="0"
                    y1="0"
                    y2="1"
                  >
                    <stop offset="0%" stopColor="#A9C7C9" stopOpacity={1} />
                    <stop offset="100%" stopColor="#A9C7C9" stopOpacity={0} />
                  </linearGradient>
                </defs>
                <Area
                  dataKey="count"
                  stroke="#6F9A9E"
                  strokeWidth={2}
                  fill="url(#gradient-jade)"
                  type="monotone"
                  animationDuration={800}
                />
              </>
            </AreaChart>
          </ChartContainer>
        )}
        {!isLoading && totalGraphCount === 0 && (
          <div className="flex h-full items-center justify-center">
            <p className="text-sm text-muted">
              {unit.areaChart.emptyStateText}
            </p>
          </div>
        )}
      </CardContent>
    </Card>
  )
}

export default AreaChartCard
