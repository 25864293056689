import React, { useState, useRef } from 'react'
import { useClickAway } from 'react-use'

import { Pencil, TrashIcon } from 'lucide-react'
import { useShallow } from 'zustand/react/shallow'

import { displayWarningMessage } from 'utils/toast'
import { cn } from 'utils/utils'

import { useAnalytics } from 'components/common/analytics/analytics-context'
import { Button } from 'components/ui/button'
import { Checkbox } from 'components/ui/checkbox'
import { Input } from 'components/ui/input'
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/ui/tooltip'
import { QueryQuestion } from 'components/vault/utils/vault'
import { useVaultStore } from 'components/vault/utils/vault-store'

interface Props {
  question: QueryQuestion
  questionsLimit: number
  questions: QueryQuestion[]
  setQuestions: (questions: QueryQuestion[]) => void
  idx: number
  maxQuestionCharacterLength: number
  minQuestionCharacterLength: number
  setIsEditingQuestion: (isEditing: boolean) => void
  disabled: boolean
}

export const QuestionItem: React.FC<Props> = ({
  question,
  questionsLimit,
  questions,
  setQuestions,
  idx,
  maxQuestionCharacterLength,
  minQuestionCharacterLength,
  setIsEditingQuestion,
  disabled,
}) => {
  const { trackEvent } = useAnalytics()
  const [isEditing, setIsEditing] = useState(false)
  const [editingText, setEditingText] = useState(question.text)
  const ref = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)

  const selectedQuestions = useVaultStore(
    useShallow((state) => state.selectedQuestions)
  )
  const totalQuestionsLength = useVaultStore(
    (state) => state.totalQuestionLength
  )
  const setSelectedQuestions = useVaultStore(
    (state) => state.setSelectedQuestions
  )

  const handleCheckboxChange = () => {
    if (selectedQuestions.includes(question)) {
      setSelectedQuestions(
        selectedQuestions.filter((q) => q !== question),
        questions
      )
    } else {
      setSelectedQuestions([...selectedQuestions, question], questions)
    }
  }

  const cannotSelectQuestion =
    totalQuestionsLength >= questionsLimit &&
    !selectedQuestions.includes(question)

  const handleEditClick = () => {
    trackEvent('Vault Query Question Edited')
    setIsEditing(true)
    setIsEditingQuestion(true)
    setTimeout(() => {
      inputRef.current?.focus()
    }, 0)
  }

  const handleDeleteClick = () => {
    trackEvent('Vault Query Question Deleted')
    const newQuestions = questions.filter((q) => q.id !== question.id)
    setQuestions(newQuestions)
    setSelectedQuestions(
      selectedQuestions.filter((q) => q.id !== question.id),
      newQuestions
    )
  }

  const handleCancelClick = () => {
    setIsEditing(false)
    setIsEditingQuestion(false)
    setEditingText(question.text)
  }

  const handleSaveClick = () => {
    // Validation
    const isDuplicate = questions.some(
      (q) => q.text === editingText && q.id !== question.id
    )

    if (isDuplicate) {
      return displayWarningMessage('Question already exists')
    }

    if (editingText.trim().length < minQuestionCharacterLength) {
      return displayWarningMessage(
        `Question must be at least ${minQuestionCharacterLength} characters long`
      )
    }
    if (editingText.trim().length > maxQuestionCharacterLength) {
      return displayWarningMessage(
        `Question must be at most ${maxQuestionCharacterLength} characters long`
      )
    }

    // Deep copy questions so that we can modify the questions
    const newQuestions: QueryQuestion[] = JSON.parse(JSON.stringify(questions))
    newQuestions[idx].text = editingText
    setQuestions(newQuestions)
    setSelectedQuestions(
      newQuestions.filter((q) =>
        selectedQuestions.map((s) => s.id).includes(q.id)
      ),
      newQuestions
    )
    setIsEditing(false)
    setIsEditingQuestion(false)
  }

  useClickAway(ref, () => {
    if (isEditing) {
      handleCancelClick()
    }
  })

  return (
    <div className="px-2" ref={ref}>
      <Tooltip>
        <TooltipTrigger asChild>
          <div
            className={cn(
              'relative flex items-center justify-between rounded border border-transparent px-2 py-0.5 transition hover:bg-secondary',
              {
                'border-primary shadow-sm hover:bg-primary': isEditing,
              }
            )}
          >
            <div className="flex w-full items-center space-x-4">
              {!disabled && (
                <Checkbox
                  id={question.id}
                  checked={selectedQuestions.includes(question)}
                  onCheckedChange={handleCheckboxChange}
                  disabled={cannotSelectQuestion}
                  checkboxClassName="disabled:cursor-default"
                />
              )}
              <label
                htmlFor={question.id}
                className={cn(
                  'flex w-full cursor-pointer items-center space-x-4',
                  cannotSelectQuestion && 'cursor-default opacity-50'
                )}
              >
                <p className="w-20 shrink-0 text-nowrap text-muted">
                  Column {idx + 1}
                </p>

                {isEditing ? (
                  <Input
                    value={editingText}
                    onChange={(e) => setEditingText(e.target.value)}
                    className="h-6 w-full border-none p-0 focus-within:ring-transparent focus:ring-transparent focus-visible:ring-transparent"
                    placeholder={`Type a question (${minQuestionCharacterLength} - ${maxQuestionCharacterLength} characters)`}
                    ref={inputRef}
                  />
                ) : (
                  <div className="flex h-6 items-center">
                    <p className="line-clamp-1">{question.text}</p>
                  </div>
                )}
              </label>
            </div>
            {!disabled && (
              // eslint-disable-next-line react/jsx-no-useless-fragment
              <>
                {!isEditing ? (
                  <div className="flex shrink-0 gap-x-2">
                    <Button
                      size="xsIcon"
                      variant="ghost"
                      className="hover:bg-button-secondary-hover"
                      onClick={handleEditClick}
                      tooltip="Edit"
                    >
                      <Pencil size={12} />
                    </Button>
                    <Button
                      size="xsIcon"
                      variant="ghost"
                      className="hover:bg-button-secondary-hover"
                      onClick={handleDeleteClick}
                      tooltip="Remove"
                    >
                      <TrashIcon size={12} />
                    </Button>
                  </div>
                ) : (
                  <div className="flex shrink-0">
                    <Button
                      size="sm"
                      variant="ghost"
                      onClick={handleCancelClick}
                    >
                      Cancel
                    </Button>
                    <Button
                      size="sm"
                      variant="ghost"
                      onClick={handleSaveClick}
                      className="font-semibold"
                    >
                      Save
                    </Button>
                  </div>
                )}
              </>
            )}
          </div>
        </TooltipTrigger>
        {cannotSelectQuestion && (
          <TooltipContent>
            You have selected {totalQuestionsLength} questions. Please remove
            some questions to add more.
          </TooltipContent>
        )}
      </Tooltip>
    </div>
  )
}

export default QuestionItem
