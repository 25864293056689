import React from 'react'

import { Column } from '@tanstack/react-table'
import { ChevronsUpDown } from 'lucide-react'

import { Button } from 'components/ui/button'

import { DocumentTable } from './file-explorer-columns'

interface HeaderProps {
  column: Column<DocumentTable>
}

export const DocumentHeader: React.FC<HeaderProps> = ({ column }) => {
  return (
    <div>
      <Button
        variant="ghost"
        onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
      >
        Document
        <ChevronsUpDown className="ml-2 size-3" />
      </Button>
    </div>
  )
}

export const TypeHeader: React.FC = () => {
  return <div>Type</div>
}

export const PartiesHeader: React.FC<HeaderProps> = ({ column }) => {
  return (
    <div>
      <Button
        variant="ghost"
        onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
      >
        Parties
        <ChevronsUpDown className="ml-2 size-3" />
      </Button>
    </div>
  )
}

export const DateHeader: React.FC = () => {
  return <div>Date</div>
}

export const FieldsHeader: React.FC = () => {
  return <div>Fields</div>
}
