import Backend from 'services/backend/backend'
import HoneyComb from 'services/honey-comb/honey-comb'

import { backendRestUrl, backendWebsocketUrl } from 'utils/server-data'

import TelemtryEvent from './honey-comb/telemetry-event'

declare global {
  interface WebSocketEventHandlers {
    onopen: (event: Event) => void
    onmessage: (event: MessageEvent) => void
    onclose: (event: CloseEvent) => void
  }

  interface Window {
    HoneyComb: HoneyComb
    Backend: Backend
    TabFocusEvent: TelemtryEvent | null
    WebSocketFactory: (
      url: string,
      handlers: WebSocketEventHandlers
    ) => WebSocket
  }
}

// eslint-disable-next-line
if (!window.HoneyComb) {
  window.HoneyComb = new HoneyComb(
    process.env.REACT_APP_HONEYCOMB_CLIENT_ID ?? '',
    process.env.REACT_APP_HONEYCOMB_DATASET ?? ''
  )
}

// eslint-disable-next-line
if (!window.Backend) {
  window.Backend = new Backend(backendRestUrl, backendWebsocketUrl)
}
// eslint-disable-next-line
if (!window.WebSocketFactory) {
  window.WebSocketFactory = (
    url: string,
    handlers: WebSocketEventHandlers
  ): WebSocket => {
    const webSocket = new WebSocket(url)
    webSocket.onopen = handlers.onopen
    webSocket.onmessage = handlers.onmessage
    webSocket.onclose = handlers.onclose
    return webSocket
  }
}

const Services = {
  HoneyComb: window.HoneyComb,
  Backend: window.Backend,
}

export default Services
