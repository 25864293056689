import React from 'react'

import pluralize from 'pluralize'

import { Workspace } from 'models/workspace'

import { Alert, AlertDescription, AlertTitle } from 'components/ui/alert'
import { Badge } from 'components/ui/badge'
import { Button } from 'components/ui/button'
import { Checkbox } from 'components/ui/checkbox'
import { ScrollArea } from 'components/ui/scroll-area'

type MismatchedDomainAlertProps = {
  mismatchDomainUsers: string[]
  workspace: Workspace
  domainMismatchAcknowledge: boolean
  setDomainMismatchAcknowledge: (value: boolean) => void
  blockSubmittingMismatch: boolean
  customerFacing: boolean
  orgDomains: string[]
}

const SOLUTIONS_ARCHITECTS_SLACK_URL =
  'https://app.slack.com/client/T046TNN7M55/C06MC7SH486'

export const MismatchedUserWorkspaceDomainAlert: React.FC<
  MismatchedDomainAlertProps
> = ({
  mismatchDomainUsers,
  workspace,
  domainMismatchAcknowledge,
  setDomainMismatchAcknowledge,
  blockSubmittingMismatch,
  customerFacing,
  orgDomains,
}) => {
  const user = `${mismatchDomainUsers.length} ${pluralize(
    'user',
    mismatchDomainUsers.length
  )}`

  const verb = mismatchDomainUsers.length === 1 ? 'has' : 'have'
  const domain = pluralize('domain', workspace.domains.length)
  const alertDescription = blockSubmittingMismatch ? (
    <>
      {user} {verb} an email that does not match the {domain} allowed in your
      Harvey workspace.
    </>
  ) : (
    `You’re attempting to add ${user} with a mismatched domain. Please ensure that all emails are spelled correctly and that there is only 1 email per line before proceeding.`
  )
  return (
    <>
      <Alert variant={!blockSubmittingMismatch ? 'default' : 'destructive'}>
        <AlertTitle className="mt-1 flex justify-between">
          {user} cannot be added
          {!blockSubmittingMismatch && (
            <Checkbox
              checked={domainMismatchAcknowledge}
              onCheckedChange={() =>
                setDomainMismatchAcknowledge(!domainMismatchAcknowledge)
              }
            />
          )}
        </AlertTitle>
        <AlertDescription>{alertDescription}</AlertDescription>
      </Alert>
      <div className="m-2 *:text-sm">
        <div className="mb-4">
          Please ensure that all emails are spelled correctly and that there is
          only 1 email per line. If you have questions, reach out
          {customerFacing ? ' to ' : ' in '}
          <Button
            className="m-0 p-0"
            variant="link"
            onClick={() =>
              customerFacing
                ? window.open('mailto:support@harvey.ai')
                : window.open(SOLUTIONS_ARCHITECTS_SLACK_URL, '_blank')
            }
          >
            {customerFacing ? 'support@harvey.ai' : 'Slack.'}
          </Button>
        </div>
        <div className="mb-4">
          User emails must belong to the following {domain}:
          <b className="text-sm font-semibold"> {orgDomains.join(', ')}</b>
        </div>
        <div>User emails that do not match:</div>
        <ScrollArea className="max-h-80">
          <div className="mt-1 max-h-80">
            {mismatchDomainUsers.map((email, idx) => (
              <Badge key={idx} variant="outline" className="mr-1 mt-1">
                {email}
              </Badge>
            ))}
          </div>
        </ScrollArea>
      </div>
    </>
  )
}
