import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'

import _ from 'lodash'
import { ArrowLeft } from 'lucide-react'
import { useShallow } from 'zustand/react/shallow'

import { DiligenceSectionFromJSON } from 'openapi/models/DiligenceSection'
import { WorkflowType } from 'types/workflows'

import { useNavigateWithQueryParams } from 'hooks/use-navigate-with-query-params'
import { TaskStatus } from 'utils/task'
import { displayErrorMessage } from 'utils/toast'
import { DOCUMENTS_UPLOADING_HELP_TEXT } from 'utils/tooltip-texts'
import useHarveySocket from 'utils/use-harvey-socket'

import { FileSource } from 'components/assistant/utils/assistant-knowledge-sources'
import { AppHeaderActions } from 'components/common/app-header-actions'
import AskHarveyButton from 'components/common/ask-harvey-button'
import { useAuthUser } from 'components/common/auth-context'
import ExportDialog from 'components/common/export/export-dialog'
import {
  CHECKBOX_ALL_VALUE,
  ExportOptionGroup,
  ExportOptionValues,
} from 'components/common/export/types'
import LoadingScreen from 'components/common/loading-screen'
import { Button } from 'components/ui/button'
import { Spinner } from 'components/ui/spinner'
import { WorkflowTypeToDetails } from 'components/workflows/workflow-definitions'
import { useDiligenceHistoryItemQuery } from 'components/workflows/workflow/discovery/common/use-diligence-history-item-query'
import { useSelectedCitation } from 'components/workflows/workflow/discovery/common/use-selected-citation'
import * as config from 'components/workflows/workflow/discovery/config'
import { useDiligenceStore } from 'components/workflows/workflow/discovery/diligence-store'
import { handleExport } from 'components/workflows/workflow/discovery/export'
import { DiligenceDocumentsPageInner } from 'components/workflows/workflow/discovery/flow/add-documents/documents-page-inner'
import { sectionsToTaxonomy } from 'components/workflows/workflow/discovery/util'
import {
  taxonomiesWithTasks,
  taxonomyToSections,
} from 'components/workflows/workflow/discovery/util'
import WorkflowLayout from 'components/workflows/workflow/workflow-layout'

import { ReportsBox } from './report'
import { SelectDetailsForm } from './select-details'

export const DiligenceFlow = () => {
  const userInfo = useAuthUser()
  const [
    availableSections,
    documents,
    knowledgeSource,
    followUpQAPairs,
    isRunningProcessSections,
    queryId,
    reset,
    sections,
    selectedTaxonomies,
    setDocuments,
    setIsRunningProcessSections,
    setQueryId,
    setSections,
    setSelectedTaxonomies,
    setKnowledgeSource,
  ] = useDiligenceStore(
    useShallow((s) => [
      s.availableSections,
      s.documents,
      s.knowledgeSource,
      s.followUpQAPairs,
      s.isRunningProcessSections,
      s.queryId,
      s.reset,
      s.sections,
      s.selectedTaxonomies,
      s.setDocuments,
      s.setIsRunningProcessSections,
      s.setQueryId,
      s.setSections,
      s.setSelectedTaxonomies,
      s.setKnowledgeSource,
    ])
  )
  // Actions for running the report
  const [
    setter,
    runProcessSections,
    runSectionsCompletedCallback,
    runAsyncProcessSections,
  ] = useDiligenceStore(
    useShallow((s) => [
      s.setter,
      s.runProcessSections,
      s.runSectionsCompletedCallback,
      s.runAsyncProcessSections,
    ])
  )

  const [reportErrorOccurred, setReportErrorOccurred] = useState(false)

  const { state } = useLocation()
  const [shouldFetchHistoryItem, setShouldFetchHistoryItem] = useState(
    state?.fetchHistoryItem ?? true
  )
  const navigate = useNavigateWithQueryParams()
  const { id: historyId } = useParams<{ id: string }>()
  const pollingInterval = 2_000
  // TODO: Revisit use of this approach for polling history items. refetchInterval is called repeatedly regardless of what the previous return value was.
  // The logic for managing error state and report completion, though idempotent, ideally is executed once.
  const { historyItem } = useDiligenceHistoryItemQuery({
    id: historyId,
    isEnabled: shouldFetchHistoryItem,
    maxRetryCount: (30 * 60 * 1000) / pollingInterval, // retry for up to 30 minutes
    refetchInterval: (query) => {
      const status = query.state.data?.status
      if (!status) {
        return false
      }

      // Poll for history item updates if it is still in progress
      if (
        query.state.status === 'success' &&
        [TaskStatus.IN_PROGRESS, TaskStatus.PENDING].includes(status)
      ) {
        if (!isRunningProcessSections) {
          setIsRunningProcessSections(true)
        }
        return pollingInterval
      }

      if ([TaskStatus.CANCELLED, TaskStatus.ERRORED].includes(status)) {
        setReportErrorOccurred(true)

        if (isRunningProcessSections) {
          runSectionsCompletedCallback()
        }
      } else if (status === TaskStatus.COMPLETED) {
        setShouldFetchHistoryItem(false)

        if (isRunningProcessSections) {
          runSectionsCompletedCallback()
        }
      }

      return false
    },
  })

  const { setSelectedCitation } = useSelectedCitation()

  useEffect(() => {
    if (!reportErrorOccurred) {
      return
    }

    displayErrorMessage(
      'An error occurred while running the report. Please try again.',
      Infinity
    )
  }, [reportErrorOccurred])

  const isAnySectionLoading = sections.some((section) => section.isLoading)

  const isAnythingToExport =
    sections.filter((section) => section.tasks.some((task) => task.answer))
      .length > 0

  const workflowType = WorkflowType.DILIGENCE
  const workflow = WorkflowTypeToDetails[workflowType]

  const { initSocketAndSendQuery } = useHarveySocket({
    path: config.ROUTE,
    setter,
    endCallback: runSectionsCompletedCallback,
    closeOnUnmount: false,
  })

  const selectedTaxonomiesWithTasks = taxonomiesWithTasks(selectedTaxonomies)

  const runReport = async () => {
    if (!selectedTaxonomiesWithTasks.length) return

    // Reset sections and answers when re-running report
    setSelectedCitation(null)
    const selectedSections = taxonomyToSections(
      selectedTaxonomiesWithTasks,
      availableSections
    )

    if (userInfo.isDealsDiscoveryAsyncUser) {
      try {
        await runAsyncProcessSections(selectedSections)
      } catch (error) {
        displayErrorMessage((error as Error).message, 8)
        return
      }
    } else {
      runProcessSections(selectedSections, initSocketAndSendQuery)
    }

    setShowReport(true)
  }

  const [showReport, setShowReport] = useState(!!historyId)
  const [isReportPresent, setIsReportPresent] = useState(!!historyId)

  const handleSubmit = async () => {
    if (isReportPresent) {
      setShowReport(true)
    } else {
      await runReport()
      setIsReportPresent(true)
    }
  }

  // Navigate to the discovery/:id route for newly generated reports only.
  // Skip doing so for history items since we're already on that route when loading history.
  useEffect(() => {
    if (userInfo.IsHistoryUser && queryId && isReportPresent && !historyId) {
      if (!userInfo.isDealsDiscoveryAsyncUser && isAnySectionLoading) {
        return
      }

      navigate(`${workflow.path}/${queryId}`, {
        replace: true,
        state: { fetchHistoryItem: userInfo.isDealsDiscoveryAsyncUser },
      })
    }
  }, [
    historyId,
    isAnySectionLoading,
    isReportPresent,
    navigate,
    queryId,
    userInfo,
    workflow.path,
  ])

  useEffect(
    () => {
      if (_.isNil(historyItem) || _.isNil(historyItem.metadata)) {
        return
      }

      setQueryId(historyItem!.id)

      const metadata = historyItem.metadata as {
        processedSections: { [key: string]: string }
      }

      const processedSections = Object.values(metadata.processedSections)
        .map((str) => {
          const json = JSON.parse(str)
          return DiligenceSectionFromJSON(json)
        })
        .sort(
          (a, b) =>
            availableSections.findIndex((s) => s.title === a.title) -
            availableSections.findIndex((s) => s.title === b.title)
        )

      const diligenceDocuments = historyItem.documents?.map((doc) => {
        return {
          file: doc,
          isLoading: false,
        }
      })

      const knowledgeSource = historyItem.knowledgeSources?.find((ks) =>
        [FileSource.VAULT].includes(ks.type)
      )

      setSections(
        processedSections.map((section) => ({
          ...section,
          isLoading: reportErrorOccurred ? false : section.isLoading,
        }))
      )
      setSelectedTaxonomies(sectionsToTaxonomy(processedSections))
      setDocuments(diligenceDocuments ?? [])
      setKnowledgeSource(knowledgeSource ?? null)
      setSelectedCitation(null)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      availableSections,
      historyItem,
      reportErrorOccurred,
      setDocuments,
      setQueryId,
      setSections,
      setSelectedTaxonomies,
      setKnowledgeSource,
    ]
  )

  const isButtonLoading =
    userInfo.isDealsDiscoveryAsyncUser && !queryId && isAnySectionLoading

  const tooltip = isButtonLoading
    ? 'Setting up report; streaming will begin shortly'
    : documents.some((doc) => doc.isLoading)
    ? DOCUMENTS_UPLOADING_HELP_TEXT
    : documents.length === 0 && _.isNil(knowledgeSource)
    ? 'No documents uploaded'
    : selectedTaxonomiesWithTasks.length === 0
    ? 'No details selected'
    : undefined

  const getIsExportDisabled = (exportValues: ExportOptionValues) => {
    const sectionsValue = exportValues.sections
    const selectedSections = Array.isArray(sectionsValue) ? sectionsValue : []
    return selectedSections.length === 0
  }

  const onExport = async (exportValues: ExportOptionValues) => {
    const sectionsValue = exportValues.sections
    const selectedSections = Array.isArray(sectionsValue) ? sectionsValue : []
    const includeFollowUps = Array.isArray(exportValues.includeFollowUps)
      ? exportValues.includeFollowUps.includes('includeFollowUps')
      : false

    if (selectedSections.length) {
      const sectionsToExport = sections.filter((section) =>
        selectedSections.includes(section.title)
      )
      void handleExport({
        sections: sectionsToExport,
        documents,
        followUpQAPairs: includeFollowUps ? followUpQAPairs : [],
      })
    }
  }

  const exportOptions: ExportOptionGroup[] = [
    {
      id: 'sections',
      name: 'Choose sections to include in export',
      options: [
        {
          label: 'All sections',
          value: CHECKBOX_ALL_VALUE,
        },
        ...selectedTaxonomiesWithTasks.map((taxonomy) => ({
          label: taxonomy.title,
          value: taxonomy.title,
        })),
      ],
      type: 'checkbox',
      defaultValue: selectedTaxonomiesWithTasks.map(
        (taxonomy) => taxonomy.title
      ),
    },
    // TODO(Adam): Special casing follow ups for now, maybe do something different later
    {
      id: 'includeFollowUps',
      name: 'Include follow-up Q&As',
      options: [
        {
          label: 'Include follow-up questions and answers',
          value: 'includeFollowUps',
        },
      ],
      type: 'checkbox',
      defaultValue: ['includeFollowUps'],
    },
  ]

  const numberOfSelectedSubsections = selectedTaxonomiesWithTasks.reduce(
    (acc, curr) => acc + curr.rows.length,
    0
  )

  const handleReset = () => {
    setShowReport(false)
    setIsReportPresent(false)
    reset()
    navigate(workflow.path, { replace: true })
  }

  const handleBack = () => {
    setShowReport(false)
  }

  const handleSelectionChanged = () => {
    setSelectedCitation(null)
    setIsReportPresent(false)
  }

  const isResetDisabled =
    selectedTaxonomiesWithTasks.length === 0 && documents.length === 0

  const buttonTitle = isReportPresent
    ? 'Return to Report'
    : `Run Selected (${numberOfSelectedSubsections})`

  const showLoadingSpinner = showReport && isAnySectionLoading

  if (historyId && sections.length === 0) {
    return <LoadingScreen />
  }

  return (
    <WorkflowLayout
      workflowType={workflowType}
      title="Discovery"
      childClassName="space-y-4 h-full"
      appHeaderActions={
        <>
          {showLoadingSpinner && (
            <div className="flex items-center pr-4">
              <Spinner className="h-3 w-3" />
              <p className="grow text-xs">Loading…</p>
            </div>
          )}
          {showReport && (
            <Button variant="outline" onClick={handleBack}>
              <ArrowLeft className="mr-1 h-4 w-4" />
              Back
            </Button>
          )}
          <AppHeaderActions
            handleReset={handleReset}
            resetDisabled={isResetDisabled}
          />
          {showReport && (
            <ExportDialog
              onExport={onExport}
              optionGroups={exportOptions}
              disabled={!isAnythingToExport || isAnySectionLoading}
              getIsSubmitDisabled={getIsExportDisabled}
            />
          )}
        </>
      }
      renderChildrenDirectly={showReport}
    >
      {showReport ? (
        <ReportsBox />
      ) : (
        <>
          <div className="flex h-full flex-col overflow-auto lg:flex-row lg:space-x-2">
            <div className="h-1/2 lg:h-full lg:w-1/3">
              <DiligenceDocumentsPageInner
                handleDocumentsChanged={handleSelectionChanged}
              />
            </div>
            <div className="mt-4 overflow-y-scroll rounded-lg border p-4 lg:mt-0 lg:w-2/3">
              <SelectDetailsForm
                handleSelectionChanged={handleSelectionChanged}
              />
            </div>
          </div>
          <div className="mt-4 flex flex-col gap-3 rounded-lg bg-accent px-4 py-3 lg:flex-row lg:items-center lg:justify-between">
            <p className="font-semibold">
              Harvey will run the Discovery report based on the selected fields
            </p>
            <div className="flex items-center">
              <AskHarveyButton
                buttonTitle={buttonTitle}
                disabled={!!tooltip || isButtonLoading}
                handleSubmit={handleSubmit}
                isLoading={isButtonLoading}
                tooltip={tooltip}
              />
            </div>
          </div>
        </>
      )}
    </WorkflowLayout>
  )
}
