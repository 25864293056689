import React from 'react'

import { X } from 'lucide-react'

import { useGeneralStore } from 'stores/general-store'

import { cn } from 'utils/utils'

import { Button } from 'components/ui/button'

interface AnnouncementCardProps {
  title: string
  description: string
  announcementKey: string
  actionButtonLabel?: string
  onClick?: () => void
  className?: string
  dismiss?: (e: React.MouseEvent) => void
}

const AnnouncementCard: React.FC<AnnouncementCardProps> = ({
  title,
  description,
  announcementKey,
  actionButtonLabel,
  onClick,
  className,
  dismiss,
}) => {
  const isSidebarOpen = useGeneralStore((state) => state.isSidebarOpen)

  if (!isSidebarOpen) {
    return null
  }

  return (
    <div
      className={cn(
        'relative max-w-96 rounded-lg border border-primary bg-primary px-3 py-2 text-xs',
        className
      )}
      key={announcementKey}
    >
      <div className="flex items-start space-x-1">
        <p className="mt-1 grow text-left text-xs font-semibold">{title}</p>
        {dismiss && (
          <Button
            variant="ghost"
            size="sm"
            onClick={dismiss}
            className="z-10 p-1"
          >
            <X size={16} strokeWidth={1} color="black" />
          </Button>
        )}
      </div>
      <p className="mt-1 text-left text-xs text-muted">{description}</p>
      <div className="mt-2 flex justify-end">
        <Button
          className="apply-click-on-parent static"
          variant="link"
          size="sm"
          onClick={onClick}
        >
          {actionButtonLabel || 'Show me'} &rarr;
        </Button>
      </div>
    </div>
  )
}

export default AnnouncementCard
