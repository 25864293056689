import React from 'react'

import { HelpCircle } from 'lucide-react'

import { ResearchArea } from 'openapi/models/ResearchArea'
import Services from 'services'

import { useAnalytics } from 'components/common/analytics/analytics-context'
import { useAuthUser } from 'components/common/auth-context'
import Markdown from 'components/common/markdown/markdown'
import { Button } from 'components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from 'components/ui/dialog'

import {
  SEC_HELP,
  MEMOS_HELP,
  EURLEX_HELP,
  USA_CASELAW_HELP,
  FRANCE_CASE_LAW_HELP_SHORT,
  JAPAN_TAX_HELP,
  TAX_AI_HELP_TEXT,
  AUS_BREACH_REPORTING_HELP,
  CUATRECASAS_HELP,
  FROM_COUNSEL_HELP,
} from './constants'
import { getTitle } from './research-helpers'

interface HelpProps {
  area: ResearchArea
}

const Help: React.FC<HelpProps> = ({ area }) => {
  const userInfo = useAuthUser()

  const title = getTitle(area)
  const { trackEvent } = useAnalytics()

  const onDialogOpenChange = (open: boolean) => {
    if (open) {
      Services.HoneyComb.Record({
        metric: 'ui.research_help_clicked',
        area,
      })
      trackEvent('Research Help Clicked', {
        research_area: area,
      })
    }
  }

  function getHelpTexts() {
    const helpTexts = []
    if (area === ResearchArea.TAX) {
      if (userInfo.IsHMRCQAUser) {
        helpTexts.push(TAX_AI_HELP_TEXT)
      }
      if (userInfo.IsJapanTaxQAUser) {
        helpTexts.push(JAPAN_TAX_HELP)
      }
    } else if (area === ResearchArea.EDGAR) {
      helpTexts.push(SEC_HELP)
    } else if (area === ResearchArea.MEMOS) {
      helpTexts.push(MEMOS_HELP)
    } else if (area === ResearchArea.EURLEX) {
      helpTexts.push(EURLEX_HELP)
    } else if (area === ResearchArea.USACASELAW) {
      helpTexts.push(USA_CASELAW_HELP)
    } else if (area === ResearchArea.USCASELAW) {
      helpTexts.push(USA_CASELAW_HELP)
    } else if (area === ResearchArea.FRANCECASELAW) {
      helpTexts.push(FRANCE_CASE_LAW_HELP_SHORT)
    } else if (area === ResearchArea.AUSBREACHREPORTING) {
      helpTexts.push(AUS_BREACH_REPORTING_HELP)
    } else if (area === ResearchArea.CUATRECASAS) {
      helpTexts.push(CUATRECASAS_HELP)
    } else if (area === ResearchArea.FROMCOUNSEL) {
      helpTexts.push(FROM_COUNSEL_HELP)
    }
    return helpTexts
  }

  const helpTexts = getHelpTexts()
  return (
    <Dialog onOpenChange={onDialogOpenChange}>
      <DialogTrigger asChild>
        <Button
          size="smIcon"
          variant="outline"
          className="shadow"
          data-testid="research-help-button"
        >
          <HelpCircle size={16} />
        </Button>
      </DialogTrigger>
      <DialogContent className="max-w-3xl">
        <DialogHeader>
          <DialogTitle>{title} Help</DialogTitle>
        </DialogHeader>
        {helpTexts.map((helpText, idx) => (
          <Markdown
            key={`help-${area}-${idx}`}
            className="mt-8"
            content={helpText}
          />
        ))}
      </DialogContent>
    </Dialog>
  )
}

export default Help
