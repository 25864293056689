import React from 'react'

import _ from 'lodash'

import { ContractsDocumentExtractedTermsValue } from 'openapi/models/ContractsDocumentExtractedTermsValue'

import { Button } from 'components/ui/button'

interface TermItemProps {
  term: string
  termExtraction: ContractsDocumentExtractedTermsValue
  onSourceClick: (term: string) => Promise<void>
}

const TermItem: React.FC<TermItemProps> = ({
  term,
  termExtraction,
  onSourceClick,
}) => {
  return (
    <button
      className="block w-full space-y-2 rounded-lg px-4 py-3 text-left transition hover:bg-secondary"
      onClick={() => onSourceClick(term)}
    >
      <div className="flex items-baseline justify-between">
        <p className="text-lg">{term}</p>
        {!_.isNil(termExtraction.source?.zeroIdxPageNum) && (
          <Button className="text-xs" variant="link">
            Page {termExtraction.source!.zeroIdxPageNum + 1}
          </Button>
        )}
      </div>
      <p className="prose prose-sm">{termExtraction.text}</p>
    </button>
  )
}

export default TermItem
