import React, { useMemo, useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'

import { useShallow } from 'zustand/react/shallow'

import { displayErrorMessage } from 'utils/toast'

import { useAuthUser } from 'components/common/auth-context'
import { Button } from 'components/ui/button'
import {
  SetVaultExampleProject,
  UnsetVaultExampleProject,
} from 'components/vault/utils/vault-fetcher'
import { useVaultStore } from 'components/vault/utils/vault-store'

type SetExampleProps = {
  isSetExampleOpen: boolean
  setIsSetExampleOpen: (show: boolean) => void
}

const SetExampleProject: React.FC<SetExampleProps> = ({
  isSetExampleOpen,
  setIsSetExampleOpen,
}) => {
  const userInfo = useAuthUser()

  const currentProject = useVaultStore((state) => state.currentProject)
  const exampleProjectIds = useVaultStore(
    useShallow((state) => state.exampleProjectIds)
  )

  const [submitting, setSubmitting] = useState<boolean>(false)

  useHotkeys(
    'Control+h',
    () => {
      setIsSetExampleOpen(!isSetExampleOpen)
    },
    [isSetExampleOpen]
  )

  const onSetExample = async () => {
    setSubmitting(true)
    try {
      await SetVaultExampleProject(currentProject!.id)
      window.location.reload()
    } catch (error) {
      displayErrorMessage((error as Error).message)
    } finally {
      setSubmitting(false)
    }
  }

  const onUnsetExample = async () => {
    setSubmitting(true)
    try {
      await UnsetVaultExampleProject(currentProject!.id)
      window.location.reload()
    } catch (error) {
      displayErrorMessage((error as Error).message)
    } finally {
      setSubmitting(false)
    }
  }

  const isExampleProject = useMemo(() => {
    return currentProject && exampleProjectIds.has(currentProject.id)
  }, [currentProject, exampleProjectIds])

  // Only allow setting example if current project not already an example project
  // and if user has permissions to create example projects
  const shouldShowSetExampleProject =
    userInfo.IsVaultCreateSandboxProjectUser &&
    isSetExampleOpen &&
    currentProject &&
    !isExampleProject

  // Only show unsetting example if the current project is the example project
  const shouldShowUnsetExampleProject =
    userInfo.IsVaultCreateSandboxProjectUser &&
    isSetExampleOpen &&
    currentProject &&
    isExampleProject

  if (!shouldShowSetExampleProject && !shouldShowUnsetExampleProject) {
    return null
  }

  return (
    <>
      {shouldShowSetExampleProject && (
        <Button
          onClick={onSetExample}
          size="sm"
          disabled={submitting}
          isLoading={submitting}
        >
          Set Example Project
        </Button>
      )}
      {shouldShowUnsetExampleProject && (
        <Button
          onClick={onUnsetExample}
          size="sm"
          disabled={submitting}
          isLoading={submitting}
        >
          Unset Example Project
        </Button>
      )}
    </>
  )
}

export default SetExampleProject
