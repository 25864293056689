// if you await this, it will delay by X seconds
const awaitDelay = (seconds: number) =>
  new Promise((res) => setTimeout(res, seconds * 1000))

// await new Promise(resolve => setTimeout(resolve, delay));
const exponentialBackoffSeconds = (attempt: number) => {
  if (attempt < 1) {
    return 0
  } else {
    return Math.pow(2, attempt) // e.g., 1s, 2s, 4s, ...
  }
}

export { awaitDelay, exponentialBackoffSeconds }
