import React from 'react'

import SearchInput from 'components/ui/search-input'
import { cn } from 'utils/utils'

interface DataTableSearchProps {
  searchQuery: string
  setSearchQuery: (value: string) => void
}

interface DataTableHeaderProps {
  children?: React.ReactNode
  searchable?: DataTableSearchProps
  className?: string
}

const DataTableHeader: React.FC<DataTableHeaderProps> = ({
  children,
  searchable,
  className,
}) => {
  return (
    <div className={cn('flex items-center', className)}>
      <div className="flex grow">{children}</div>
      {searchable && (
        <SearchInput
          value={searchable.searchQuery}
          setValue={searchable.setSearchQuery}
          withIcon
        />
      )}
    </div>
  )
}

export default DataTableHeader
