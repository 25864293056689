import { UserInfo, Permission } from 'models/user-info'

import { createRouteComponent } from 'utils/routing'

import CompetitiveAnalysis from './index'

export enum CompetitiveAnalysisPages {
  COMPETITIVE_ANALYSIS_HOME = '/workflows/competitive-analysis',
}

export const getCompetitiveAnalysisPageRoutes = (userInfo: UserInfo) => {
  return [
    createRouteComponent({
      path: CompetitiveAnalysisPages.COMPETITIVE_ANALYSIS_HOME,
      hasPerms: userInfo.permissions.includes(
        Permission.USE_COMPETITIVE_ANALYSIS
      ),
      component: CompetitiveAnalysis,
    }),
  ]
}
