import React from 'react'

import { capitalize } from 'lodash'
import { FileText, LucideIcon, SquareChevronRight } from 'lucide-react'

import { useAnalytics } from 'components/common/analytics/analytics-context'
import { useAuthUser } from 'components/common/auth-context'
import Icon from 'components/ui/icon/icon'
import { Tabs, TabsList, TabsTrigger } from 'components/ui/tabs'

export enum AssistantMode {
  ASSIST = 'assist',
  DRAFT = 'draft',
}

type ModeConfig = {
  icon: LucideIcon
  description: string
}

const MODE_CONFIG: Record<AssistantMode, ModeConfig> = {
  [AssistantMode.ASSIST]: {
    icon: SquareChevronRight,
    description:
      'Answers based on a wide array of knowledge sources, with follow-up questions',
  },
  [AssistantMode.DRAFT]: {
    icon: FileText,
    description: 'Generate detailed and complex work, e.g. contracts and memos',
  },
}

interface Props {
  mode: AssistantMode
  setMode: (value: AssistantMode) => void
}

const AssistantModeSelect = ({ mode, setMode }: Props) => {
  const userInfo = useAuthUser()
  const { trackEvent } = useAnalytics()

  const handleValueChange = (value: string) => {
    const newMode = value as AssistantMode
    setMode(newMode)
    trackEvent('Assistant Mode Selected', { mode: capitalize(newMode) })
  }

  return (
    <Tabs value={mode} onValueChange={handleValueChange}>
      <TabsList variant="minimal">
        <TabsTrigger
          id="assistant-mode-assist"
          variant="minimal"
          value={AssistantMode.ASSIST}
          className="px-2 pb-3 data-[state=active]:shadow-[inset_0_-2px_0_0_hsl(var(--text-primary)),0_2px_0_0_hsl(var(--text-primary))]"
        >
          <Icon
            icon={MODE_CONFIG[AssistantMode.ASSIST].icon}
            className="mr-1.5"
          />
          <span className="text-sm font-medium">
            {capitalize(AssistantMode.ASSIST)}
          </span>
        </TabsTrigger>
        {userInfo.IsAssistantDraftUser && (
          <TabsTrigger
            id="assistant-mode-draft"
            variant="minimal"
            value={AssistantMode.DRAFT}
            className="px-2 pb-3 data-[state=active]:shadow-[inset_0_-2px_0_0_hsl(var(--text-primary)),0_2px_0_0_hsl(var(--text-primary))]"
          >
            <Icon
              icon={MODE_CONFIG[AssistantMode.DRAFT].icon}
              className="mr-1.5"
            />
            <span className="text-sm font-medium">
              {capitalize(AssistantMode.DRAFT)}
            </span>
          </TabsTrigger>
        )}
      </TabsList>
    </Tabs>
  )
}

export default AssistantModeSelect
