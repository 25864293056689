import React from 'react'

import { cn } from 'utils/utils'

import { ScrollArea } from 'components/ui/scroll-area'

import { AppHeader } from './app-header'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode
  containerClassName?: string
  hasContainer?: boolean
}

export const AppMain = ({
  children,
  className,
  containerClassName,
  hasContainer,
  ...props
}: Props) => {
  const mainChildren: React.ReactNode[] = []
  let headerChild: React.ReactNode = null

  React.Children.forEach(children, (child) => {
    if (React.isValidElement(child) && child.type === AppHeader) {
      headerChild = child
    } else {
      mainChildren.push(child)
    }
  })

  return (
    <>
      {headerChild}
      <div className={cn('relative min-h-0 grow', className)} {...props}>
        {hasContainer ? (
          <ScrollArea className="h-full">
            <div className={cn('container mx-auto py-4', containerClassName)}>
              {mainChildren}
            </div>
          </ScrollArea>
        ) : (
          mainChildren
        )}
      </div>
    </>
  )
}
