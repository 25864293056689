import React from 'react'

import { Maybe } from 'types'

import { cn } from 'utils/utils'

import { Button } from 'components/ui/button'

const RatingButtons = ({
  min,
  max,
  step,
  rating,
  onRatingChange,
  disabled,
}: {
  min: number
  max: number
  step: number
  rating: Maybe<number>
  onRatingChange: (rating: number) => void
  disabled: boolean
}) => {
  return (
    <div className="flex flex-row gap-2">
      {Array.from({ length: (max - min) / step + 1 }, (_, i) => (
        <Button
          key={i}
          variant="outline"
          // TODO do i need cn here?
          className={cn('font-semibold', {
            'bg-highlight hover:bg-highlight': rating === min + i * step,
          })}
          onClick={() => {
            if (rating !== min + i * step) {
              onRatingChange(min + i * step)
            }
          }}
          disabled={disabled}
        >
          {min + i * step}
        </Button>
      ))}
    </div>
  )
}

export default RatingButtons
