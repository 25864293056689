// Definition: Types for announcements
import { NavigateFunction } from 'react-router-dom'

import { UserInfo } from 'models/user-info'

import { TrackEventFunction } from 'components/common/analytics/analytics-context'

enum AudienceType {
  EVERYONE = 'Everyone',
  ONLY_USERS_CREATED_BEFORE_ANNOUNCEMENT = 'Only-created-before-announcement',
}

enum PersistanceBehavior {
  HIDE_AFTER_VIEWED = 'hide-after-viewed',
  PERSIST_UNTIL_DISMISSED = 'persist-until-dismissed',
}

enum AnnouncementType {
  MODAL = 'modal',
  NO_MODAL = 'no-modal',
}

interface AnnouncementCriteria {
  audienceType: AudienceType
  startDate?: Date
  endDate?: Date
  persistanceBehavior?: PersistanceBehavior
  customValidator?: (userInfo: UserInfo) => boolean
}

interface Announcement {
  enabled: boolean
  announcementKey: string
  cardTitle: string
  cardDescription: string
  announcementType: AnnouncementType
  criteria: AnnouncementCriteria
  modalContent?: string | ((userInfo: UserInfo) => string) // Markdown string or function that returns string
  modalHeight?: string // CSS height string
  modalWidth?: string // CSS width string
  actionButtonLabel?: string
  hideClose?: boolean
  zIndex?: number // number to determine the order of the announcement, if multiple announcements are active
  allowDismiss?: boolean // Show 'x' on card, otherwise follows persistence behavior
  autoOpen?: boolean //  automatically open the modal
  announcementAction?: (
    navigate: NavigateFunction,
    userInfo?: UserInfo,
    trackEvent?: TrackEventFunction
  ) => void // custom action to be performed when the announcement is clicked
  preloadImages?: string[]
}

// exports
export { AudienceType, PersistanceBehavior, AnnouncementType }
export type { Announcement }
