import { LucideIcon, Table } from 'lucide-react'

import { SafeRecord } from 'utils/safe-types'

export enum FileType {
  C = 'text/x-c',
  CPP = 'text/x-c++',
  CSS = 'text/css',
  CSV = 'text/csv',
  EMAIL = 'message/rfc822',
  EXCEL = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  EXCEL_LEGACY = 'application/vnd.ms-excel',
  FLAC = 'audio/flac',
  GIF = 'image/gif',
  HTML = 'text/html',
  JAVA = 'text/x-java',
  JAVASCRIPT = 'text/javascript',
  JPEG = 'image/jpeg',
  JSON = 'application/json',
  M4A = 'audio/x-m4a',
  MARKDOWN = 'text/markdown',
  MP4 = 'audio/mp4',
  MPEG = 'audio/mpeg',
  OGG = 'audio/ogg',
  OUTLOOK = 'application/vnd.ms-outlook',
  PDF = 'application/pdf',
  PHP = 'text/x-php',
  PNG = 'image/png',
  POWERPOINT = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  POWERPOINT_LEGACY = 'application/vnd.ms-powerpoint',
  PYTHON = 'text/x-python',
  PYTHON_SCRIPT = 'text/x-script.python',
  RTF = 'application/rtf',
  RUBY = 'text/x-ruby',
  TAR = 'application/x-tar',
  TEX = 'text/x-tex',
  TEXT = 'text/plain',
  TYPESCRIPT = 'application/typescript',
  WAV = 'audio/wav',
  WEBM = 'audio/webm',
  WORD = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  WORD_LEGACY = 'application/msword',
  XML = 'application/xml',
  XML_TEXT = 'text/xml',
  ZIP = 'application/zip',
}

export const FileTypeToExtension: Record<FileType, string[]> = {
  [FileType.C]: ['.c'],
  [FileType.CPP]: ['.cpp'],
  [FileType.CSS]: ['.css'],
  [FileType.CSV]: ['.csv', '.CSV'],
  [FileType.EMAIL]: ['.eml'],
  [FileType.EXCEL_LEGACY]: ['.xls', '.XLS'],
  [FileType.EXCEL]: ['.xlsx', '.XLSX'],
  [FileType.FLAC]: ['.flac'],
  [FileType.GIF]: ['.gif'],
  [FileType.HTML]: ['.html'],
  [FileType.JAVA]: ['.java'],
  [FileType.JAVASCRIPT]: ['.js'],
  [FileType.JPEG]: ['.jpeg', '.jpg'],
  [FileType.JSON]: ['.json'],
  [FileType.M4A]: ['.m4a'],
  [FileType.MARKDOWN]: ['.md'],
  [FileType.MP4]: ['.m4a', '.mp4'],
  [FileType.MPEG]: ['.mp3', '.mpeg', '.mpga'],
  [FileType.OGG]: ['.ogg'],
  [FileType.OUTLOOK]: ['.msg', '.pst'],
  [FileType.PDF]: ['.pdf', '.PDF'],
  [FileType.PHP]: ['.php'],
  [FileType.PNG]: ['.png'],
  [FileType.POWERPOINT_LEGACY]: ['.ppt', '.PPT'],
  [FileType.POWERPOINT]: ['.pptx'],
  [FileType.PYTHON_SCRIPT]: ['.py'],
  [FileType.PYTHON]: ['.py'],
  [FileType.RTF]: ['.rtf'],
  [FileType.RUBY]: ['.rb'],
  [FileType.TAR]: ['.tar'],
  [FileType.TEX]: ['.tex'],
  [FileType.TEXT]: ['.txt'],
  [FileType.TYPESCRIPT]: ['.ts'],
  [FileType.WAV]: ['.wav'],
  [FileType.WEBM]: ['.webm'],
  [FileType.WORD_LEGACY]: ['.doc', '.DOC'],
  [FileType.WORD]: ['.docx', '.DOCX'],
  [FileType.XML_TEXT]: ['.xml'],
  [FileType.XML]: ['.xml'],
  [FileType.ZIP]: ['.zip', '.ZIP'],
}

export const FileExtenstionToIcon: SafeRecord<string, LucideIcon> = {
  xls: Table,
  xlsx: Table,
}

const LEGACY_SUFFIX = '(Legacy)'

export const FileTypeReadableName: Record<FileType, string> = {
  [FileType.C]: 'C Source File',
  [FileType.CPP]: 'C++ Source File',
  [FileType.CSS]: 'CSS',
  [FileType.CSV]: 'CSV',
  [FileType.EMAIL]: 'Email',
  [FileType.EXCEL_LEGACY]: `Excel ${LEGACY_SUFFIX}`,
  [FileType.EXCEL]: 'Excel',
  [FileType.FLAC]: 'FLAC Audio',
  [FileType.GIF]: 'GIF Image',
  [FileType.HTML]: 'HTML',
  [FileType.JAVA]: 'Java Source File',
  [FileType.JAVASCRIPT]: 'JavaScript',
  [FileType.JPEG]: 'JPEG Image',
  [FileType.JSON]: 'JSON',
  [FileType.M4A]: 'M4A Audio',
  [FileType.MARKDOWN]: 'Markdown',
  [FileType.MP4]: 'MP4 Audio',
  [FileType.MPEG]: 'MPEG Audio',
  [FileType.OGG]: 'Ogg Audio',
  [FileType.OUTLOOK]: 'Email (Outlook)',
  [FileType.PDF]: 'PDF',
  [FileType.PHP]: 'PHP Source File',
  [FileType.PNG]: 'PNG Image',
  [FileType.POWERPOINT_LEGACY]: `PowerPoint ${LEGACY_SUFFIX}`,
  [FileType.POWERPOINT]: 'PowerPoint',
  [FileType.PYTHON_SCRIPT]: 'Python Script',
  [FileType.PYTHON]: 'Python Source File',
  [FileType.RTF]: 'RTF',
  [FileType.RUBY]: 'Ruby Source File',
  [FileType.TAR]: 'Tar Archive',
  [FileType.TEX]: 'TeX',
  [FileType.TEXT]: 'Text',
  [FileType.TYPESCRIPT]: 'TypeScript',
  [FileType.WAV]: 'WAV Audio',
  [FileType.WEBM]: 'WebM Audio',
  [FileType.WORD_LEGACY]: `Word ${LEGACY_SUFFIX}`,
  [FileType.WORD]: 'Word',
  [FileType.XML_TEXT]: 'XML (Text)',
  [FileType.XML]: 'XML',
  [FileType.ZIP]: 'Zip',
}

const SPECIFICATION_REGEX = / \([^)]*\)/

export const removeSubsetDuplicates = (readableNames: string[]) => {
  const readablePrefixes = new Set()

  // Filter out subsets if their non-subset counterpart exists
  return readableNames.filter((name) => {
    const prefix = name.replace(SPECIFICATION_REGEX, '').trim()
    if (!readablePrefixes.has(prefix)) {
      readablePrefixes.add(prefix)
      return true
    }
    return !SPECIFICATION_REGEX.test(name)
  })
}
