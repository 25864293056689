import React from 'react'

import _ from 'lodash'
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'

import Services from 'services'
import { RequestError } from 'services/backend/backend'
import { Maybe } from 'types'

import { SafeRecord } from 'utils/safe-types'
import { displayErrorMessage, displaySuccessMessage } from 'utils/toast'

import { RecordBase } from 'components/filter/types/record-base'

// TODO: Consider merging some of this with history store

interface LibraryMetadataState {
  optimisticLibraryItemFavorite: SafeRecord<string, boolean>
  optimisticLibraryItemHidden: SafeRecord<string, boolean>
  optimisticLibraryItemFavoriteTimestamp: SafeRecord<string, Date>
  optimisticLibraryItemHiddenTimestamp: SafeRecord<string, Date>
  optimisticLibraryItemDeleted: SafeRecord<string, boolean>
  optimisticLibraryItemDeletedTimestamp: SafeRecord<string, Date>
  favoriteFolder?: LibraryItemFolder
}

export interface LibraryMetadataAction {
  getFavoriteStatus: (record: FavoriteRecord) => Maybe<boolean>
  getHiddenStatus: (record: HiddenRecord) => Maybe<boolean>
  updateLibraryItemFavorite: (
    libraryItemId: string,
    favorite: boolean
  ) => Promise<void>
  updateLibraryItemHidden: (
    libraryItemId: string,
    hidden: boolean
  ) => Promise<void>
  deleteLibraryItem: (libraryItemId: string) => Promise<void>
  getLibraryItemDeleted: (record: RecordBase & { id: string }) => boolean
}

const initialState = {
  optimisticLibraryItemFavorite: {},
  optimisticLibraryItemHidden: {},
  optimisticLibraryItemFavoriteTimestamp: {},
  optimisticLibraryItemDeleted: {},
  optimisticLibraryItemDeletedTimestamp: {},
}

export const useLibraryMetadataStore = create<
  LibraryMetadataState & LibraryMetadataAction
>()(
  devtools(
    immer((set, get) => ({
      ...initialState,
      getFavoriteStatus: (record: FavoriteRecord) => {
        const optimistic = getOptimisticValue<boolean>({
          libraryItemId: record.id,
          optimisticKey: 'optimisticLibraryItemFavorite',
          timestampKey: 'optimisticLibraryItemFavoriteTimestamp',
          updatedAt: new Date(record.updatedAt),
          get,
        })
        if (!_.isUndefined(optimistic)) {
          return optimistic
        }
        return record.starred
      },
      getHiddenStatus: (record: HiddenRecord) => {
        const optimistic = getOptimisticValue<boolean>({
          libraryItemId: record.id,
          optimisticKey: 'optimisticLibraryItemHidden',
          timestampKey: 'optimisticLibraryItemHiddenTimestamp',
          updatedAt: new Date(record.updatedAt),
          get,
        })
        if (!_.isUndefined(optimistic)) {
          return optimistic
        }
        return record.workspaceHidden
      },
      updateLibraryItemFavorite: async (
        libraryItemId: string,
        starred: boolean
      ) => {
        await optimisticUpdate<boolean>({
          set,
          get,
          libraryItemId,
          updateValue: starred,
          optimisticKey: 'optimisticLibraryItemFavorite',
          timestampKey: 'optimisticLibraryItemFavoriteTimestamp',
          updateFunction: async () =>
            await Services.Backend.Patch(
              `library/${libraryItemId}/starred`,
              { starred },
              { throwOnError: true }
            ),
        })
      },
      updateLibraryItemHidden: async (
        libraryItemId: string,
        hidden: boolean
      ) => {
        await optimisticUpdate<boolean>({
          set,
          get,
          libraryItemId,
          updateValue: hidden,
          optimisticKey: 'optimisticLibraryItemHidden',
          timestampKey: 'optimisticLibraryItemHiddenTimestamp',
          updateFunction: async () =>
            await Services.Backend.Patch(
              `library/${libraryItemId}/hidden`,
              { hidden },
              { throwOnError: true }
            ),
        })
      },
      getLibraryItemDeleted: (record: RecordBase & { id: string }) => {
        const optimisticDeleted = getOptimisticValue<boolean>({
          libraryItemId: record.id,
          optimisticKey: 'optimisticLibraryItemDeleted',
          timestampKey: 'optimisticLibraryItemDeletedTimestamp',
          updatedAt: new Date(record.updatedAt),
          get,
        })
        if (!_.isUndefined(optimisticDeleted)) {
          return optimisticDeleted
        }

        return !_.isNil(record.deletedAt)
      },
      deleteLibraryItem: async (libraryItemId: string) => {
        await optimisticUpdate<boolean>({
          set,
          get,
          libraryItemId,
          updateValue: true,
          optimisticKey: 'optimisticLibraryItemDeleted',
          timestampKey: 'optimisticLibraryItemDeletedTimestamp',
          updateFunction: async () =>
            await Services.Backend.Delete(
              `library/${libraryItemId}`,
              {},
              {
                throwOnError: true,
              }
            ).then(() => {
              displaySuccessMessage('Prompt deleted successfully', 1)
              return true
            }),
        })
      },
      optimisticLibraryItemHiddenTimestamp: {}, // Add this line
    }))
  )
)

type LibraryItemFolder = {
  id: number
  name: string
  kind: string
}

interface OptimisticUpdateParams<T> {
  set: (fn: (state: LibraryMetadataState) => void) => void
  get: () => LibraryMetadataState & LibraryMetadataAction
  libraryItemId: string
  updateValue: T
  optimisticKey: OptimisticUpdateKeys
  timestampKey: OptimisticUpdateKeys
  updateFunction: () => Promise<boolean | RequestError>
  optimisticUpdateSideEffect?: (state: LibraryMetadataState) => void
}

type OptimisticUpdateKeys = {
  [K in keyof LibraryMetadataState]: LibraryMetadataState[K] extends SafeRecord<
    string,
    boolean | Date | string | null
  >
    ? K
    : never
}[keyof LibraryMetadataState] &
  string

export interface FavoriteRecord extends RecordBase {
  id: string
  starred: boolean
}

export interface HiddenRecord extends RecordBase {
  id: string
  workspaceHidden: boolean
}

async function optimisticUpdate<T>({
  set,
  get,
  libraryItemId,
  updateValue,
  optimisticKey,
  timestampKey,
  updateFunction,
  optimisticUpdateSideEffect,
}: OptimisticUpdateParams<T>) {
  const previousValue = (get()[optimisticKey] as SafeRecord<string, T>)[
    libraryItemId
  ]
  const previousTimestamp = (get()[timestampKey] as SafeRecord<string, Date>)[
    libraryItemId
  ]

  // Optimistically update state and timestamp
  set((state) => {
    const optimisticState = state[optimisticKey] as SafeRecord<string, T>
    optimisticState[libraryItemId] = updateValue
    const timestampState = state[timestampKey] as SafeRecord<string, Date>
    timestampState[libraryItemId] = new Date()
    optimisticUpdateSideEffect?.(state)
  })

  try {
    const result = await updateFunction()
    if (result instanceof RequestError || result === false) {
      throw new Error(
        `Update ${optimisticKey} failed for LibraryItem ${libraryItemId}`
      )
    }
  } catch (error) {
    // Revert to previous state and timestamp in case of an error
    set((state) => {
      const optimisticState = state[optimisticKey] as SafeRecord<string, T>
      optimisticState[libraryItemId] = previousValue
      const timestampState = state[timestampKey] as SafeRecord<string, Date>
      timestampState[libraryItemId] = previousTimestamp
    })
    displayErrorMessage('An error occurred. Please try again.')
    console.error(error)
  }
}

function getOptimisticValue<T>({
  libraryItemId,
  optimisticKey,
  timestampKey,
  updatedAt,
  get,
}: {
  libraryItemId: string
  optimisticKey: keyof LibraryMetadataState
  timestampKey: keyof LibraryMetadataState
  updatedAt: Date
  get: () => LibraryMetadataState & LibraryMetadataAction
}): T | undefined {
  const optimisticValue = (get()[optimisticKey] as SafeRecord<string, T>)[
    libraryItemId
  ]
  const optimisticTimestamp = (get()[timestampKey] as SafeRecord<string, Date>)[
    libraryItemId
  ]

  if (
    !_.isUndefined(optimisticValue) &&
    !_.isUndefined(optimisticTimestamp) &&
    new Date(optimisticTimestamp) > updatedAt
  ) {
    return optimisticValue
  }

  return undefined
}

export const useGetLibraryItemDeleted = () => {
  const { getLibraryItemDeleted, optimisticLibraryItemDeleted } =
    useLibraryMetadataStore((state) => ({
      getLibraryItemDeleted: state.getLibraryItemDeleted,
      optimisticLibraryItemDeleted: state.optimisticLibraryItemDeleted,
    }))

  return React.useCallback(
    (record: RecordBase & { id: string }) => getLibraryItemDeleted(record),
    // Include optimisticLibraryItemDeleted in the dependency array to ensure reactivity.
    // This does not use optimisticLibraryItemDeleted directly, but its presence triggers re-renders.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getLibraryItemDeleted, optimisticLibraryItemDeleted]
  )
}
