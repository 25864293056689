import { GridApi } from 'ag-grid-community'
import { enableMapSet } from 'immer'
import _ from 'lodash'
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'

import { UploadedFile } from 'openapi/models/UploadedFile'
import { VaultFile } from 'openapi/models/VaultFile'
import { VaultFolder } from 'openapi/models/VaultFolder'
import { VaultFolderMetadata } from 'openapi/models/VaultFolderMetadata'

import { SafeRecord } from 'utils/safe-types'
import { AnnotationById, Source, TaskType } from 'utils/task'
import { HarveySocketTask } from 'utils/use-harvey-socket'

import {
  VaultItem,
  GenerateNNResponseMetadata,
  QueryQuestion,
  ReviewAnswer,
  MAX_QUESTION_CHAR_LENGTH,
  MIN_QUESTION_CHAR_LENGTH,
  ColumnDataType,
} from './vault'
import {
  generateEmptyMetadata,
  generateEmptyVaultCurrentStreamingState,
  generateEmptyVaultReviewSocketState,
  getInProgressQuery,
  updateProjectMetadata,
} from './vault-helpers'

// Enable the MapSet plugin for Immer
enableMapSet()

export interface VaultState {
  isLayoutLoading: boolean
  isProjectLayoutLoading: boolean
  currentFolderId: string | null
  currentProject: VaultFolder | null
  folderIdToVaultFolder: SafeRecord<string, VaultFolder>
  parentIdToVaultFolderIds: SafeRecord<string, string[]>
  rootVaultFolderIds: string[]
  fileIdToVaultFile: SafeRecord<string, VaultFile>
  folderIdToVaultFileIds: SafeRecord<string, string[]>
  localFileIds: Set<string>
  currentProjectMetadata: VaultFolderMetadata
  projectsMetadata: SafeRecord<string, VaultFolderMetadata>
  queryId: string
  isNewVaultReviewQuery: boolean
  showProcessingProgress: SafeRecord<string, boolean>
  showRecentQueries: boolean
  recentlyUploadedFileIds: string[]
  requiresProjectDataRefetch: boolean
  exampleProjectIds: Set<string>
  areExampleProjectsLoaded: boolean
  sharedProjectIds: Set<string>
}

interface VaultAction {
  setIsLayoutLoading: (isLayoutLoading: boolean) => void
  setIsProjectLayoutLoading: (isProjectLayoutLoading: boolean) => void
  setCurrentFolderId: (id: string | null) => void
  setCurrentProject: (
    project: VaultFolder | null,
    shouldUpdateProjectMetadata?: boolean
  ) => void
  // updateVaultFile: updates a single file in the store with new data
  updateVaultFile: (fileId: string, newData: Partial<VaultFile>) => void
  // upsertVaultFile: determines if the file needs to be updated and merges the new data with the existing data
  upsertVaultFiles: (files: VaultFile[]) => void
  upsertVaultFolders: (
    folders: VaultFolder[],
    currentUserId: string,
    isExampleProject?: boolean
  ) => void
  deleteVaultFiles: (fileIds: string[]) => void
  deleteVaultFolders: (folderIds: string[]) => void
  addToProjectsMetadata: (
    projectMetadata: Record<string, VaultFolderMetadata>
  ) => void
  updateProjectMetadata: () => void
  updateProjectMetadataLastFileUploadedAt: (lastFileUploadedAt: string) => void
  updateProjectMetadataClientMatterId: (clientMatterId: string) => void
  setQueryId: (queryId: string) => void
  setIsNewVaultReviewQuery: (isNewVaultReviewQuery: boolean) => void
  setShowProcessingProgress: (
    projectId: string,
    showProcessingProgress: boolean
  ) => void
  setShowRecentQueries: (showRecentQueries: boolean) => void
  setRecentlyUploadedFileIds: (fileIds: string[]) => void
  setRequiresProjectDataRefetch: (requiresProjectDataRefetch: boolean) => void
  setExampleProjectIds: (exampleProjectIds: Set<string>) => void
  setAreExampleProjectsLoaded: (areExampleProjectsLoaded: boolean) => void
  markInProgressReviewTask: (queryId: string) => void
}

export enum QUERY_TYPES {
  N1 = 'ask',
  NN = 'review',
}

interface VaultQueryBoxState {
  // The query that is currently being typed, will be submitted when the user clicks "Ask Harvey"
  pendingQuery: string
  // The file ids that are currently selected in the file explorer, will be used for querying
  pendingQueryFileIds: string[] | null
  pendingColumnIds: string[] | null
  isTextAreaFocused: boolean
  queryType: QUERY_TYPES
  questions: QueryQuestion[]
  selectedQuestions: QueryQuestion[]
  totalQuestionLength: number
  maxQuestionCharacterLength: number
  minQuestionCharacterLength: number
  isQuestionsOpen: boolean
  isWorkflowRepsWarranties: boolean
}

interface VaultQueryBoxAction {
  setPendingQuery: (pendingQuery: string) => void
  setPendingQueryFileIds: (pendingQueryFileIds: string[] | null) => void
  removeQueryFileIds: (fileIds: string[]) => void
  setPendingColumnIds: (pendingColumnIds: string[] | null) => void
  setIsTextAreaFocused: (isTextAreaFocused: boolean) => void
  setQueryType: (queryType: QUERY_TYPES) => void
  setQuestions: (questions: QueryQuestion[]) => void
  setSelectedQuestions: (
    selectedQuestions: QueryQuestion[],
    fromQuestions: QueryQuestion[]
  ) => void
  setMaxQuestionCharacterLength: (maxQuestionCharacterLength: number) => void
  setMinQuestionCharacterLength: (minQuestionCharacterLength: number) => void
  setIsQuestionsOpen: (isQuestionsOpen: boolean) => void
  setIsWorkflowRepsWarranties: (isWorkflowRepsWarranties: boolean) => void
}

interface VaultSheetState {
  activeDocument: UploadedFile | null
}

interface VaultSheetAction {
  setActiveDocument: (activeDocument: UploadedFile | null) => void
}

interface VaultDialogState {
  areUploadButtonsDisabled: boolean
  isCreateFolderDialogOpen: boolean
  currentCreateFolderFolderId: string | null
  isUploadFilesDialogOpen: boolean
  currentUploadFilesFolderId: string | null
  isDeleteDialogOpen: boolean
  deleteRecords: VaultItem[]
  isRenameDialogOpen: boolean
  renameRecord: VaultItem | null
  isMoveDialogOpen: boolean
  moveRecords: VaultItem[]
  isReviewQuerySelectionDialogOpen: boolean
  isExportDialogOpen: boolean
  isAddFilesDialogOpen: boolean
  isVaultAssistantModalOpen: boolean
  isEditClientMatterDialogOpen: boolean
  isDuplicateModalOpen: boolean
  isColumnBuilderDialogOpen: boolean
}

interface VaultDialogAction {
  setAreUploadButtonsDisabled: (areDisabled: boolean) => void
  setIsCreateFolderDialogOpen: (isOpen: boolean) => void
  setCurrentCreateFolderFolderId: (folderId: string | null) => void
  setIsUploadFilesDialogOpen: (isOpen: boolean) => void
  setCurrentUploadFilesFolderId: (folderId: string | null) => void
  setIsDeleteDialogOpen: (isOpen: boolean) => void
  setDeleteRecords: (records: VaultItem[]) => void
  setIsRenameDialogOpen: (isOpen: boolean) => void
  setRenameRecord: (record: VaultItem | null) => void
  setIsMoveDialogOpen: (isOpen: boolean) => void
  setMoveRecords: (records: VaultItem[]) => void
  setIsReviewQuerySelectionDialogOpen: (isOpen: boolean) => void
  setIsExportDialogOpen: (isOpen: boolean) => void
  setIsAddFilesDialogOpen: (isOpen: boolean) => void
  setIsVaultAssistantModalOpen: (isOpen: boolean) => void
  setIsEditClientMatterDialogOpen: (isOpen: boolean) => void
  setIsDuplicateModalOpen: (isOpen: boolean) => void
  setIsColumnBuilderDialogOpen: (isOpen: boolean) => void
}

export type VaultExtraSocketState = {
  isFromHistory: boolean
  dryRun: boolean
  title: string
  taskType: TaskType | null
  vaultFolderId: string | null
  numFiles: number
  numQuestions: number
  fileIds: string[]
  sourcedFileIds: string[]
  // When the query is first created
  createdAt: Date | null
  // When the query is started recently (could be a retry)
  startedAt: Date | null
  completedAt: Date | null
  cancelledAt: Date | null
  failedAt: Date | null
  maxTokenLimitReached: boolean
  creatorUserEmail: string | null
}

export type VaultReviewSocketState = {
  followUpQueries: { query: string; questionIds: string[] }[]
  questions: QueryQuestion[]
  questionsNotAnswered: QueryQuestion[]
  questionsLimit: number
  filesLimit: number | null
  maxQuestionCharacterLength: number
  minQuestionCharacterLength: number
  columnHeaders: { id: string; text: string; columnDataType?: ColumnDataType }[]
  answers: SafeRecord<string, ReviewAnswer[]>
  errors: SafeRecord<string, { columnId: string; text: string }[]>
  fileIdToSources: SafeRecord<string, Source[]>
  processedFileIds: string[]
  suppressedFileIds: string[]
  eta: string | null
  columnOrder: string[]
  isWorkflowRepsWarranties: boolean
  isVaultV2User: boolean
}

// This state is for either the query that VaultQueryDetail is serving right
// now, or the one that is being generated by the VaultQueryBox.
export type VaultCurrentStreamingState = VaultExtraSocketState & {
  query: string
  isLoading: boolean
  headerText: string
  response: string
  queryId: string
  progress: number
  sources: Source[]
  annotations: AnnotationById
}

// This state is for all the queries that are pending, or the cached result
// of the queries that have been completed and fetched.
interface VaultStreamingState {
  queryIdToState: SafeRecord<string, VaultCurrentStreamingState>
  queryIdToReviewState: SafeRecord<string, VaultReviewSocketState>
  agGridEnterpriseLicenseRegistered: boolean
  gridApi: GridApi | null
}

export type VaultSocketTask = HarveySocketTask &
  VaultExtraSocketState &
  VaultReviewSocketState

export type VaultSocketSetter = (task: Partial<VaultSocketTask>) => void

interface VaultStreamingAction {
  setTask: VaultSocketSetter
  clearPendingTask: () => void
  clearNewQueryState: () => void
  addQuestionToQuery: (question: QueryQuestion) => void
  updateQuestionInQuery: (question: QueryQuestion) => void
  deleteQuestionFromQuery: (questionId: string) => void
  setReviewTask: VaultSocketSetter
  setColumnOrder: (queryId: string, columnOrder: string[]) => void
  markInProgressTaskAsFromHistory: (taskType: TaskType) => void
  markHistoryTaskAsFromStreaming: (queryId: string) => void
  setAgGridEnterpriseLicenseRegistered: (
    agGridEnterpriseLicenseRegistered: boolean
  ) => void
  setGridApi: (gridApi: GridApi | null) => void
}

interface VaultErrorState {
  error: {
    message: string
    cta: { redirectUri: string; message: string }
  } | null
}

interface VaultErrorAction {
  setError: (error: VaultErrorState['error']) => void
}

export interface AddColumnPopoverPosition {
  left: number
  top: number
  question: string
  header: string
  columnDataType: ColumnDataType | undefined
  colId?: string
}

interface VaultStartFromScratchStore {
  addColumnPopoverPosition: AddColumnPopoverPosition | null
}

interface VaultStartFromScratchActions {
  setAddColumnPopoverPosition: (
    popoverPosition: AddColumnPopoverPosition | null
  ) => void
}

function fileEquals(file1: VaultFile, file2: VaultFile): boolean {
  return (
    file1.id === file2.id ||
    (file1.name === file2.name && file1.vaultFolderId === file2.vaultFolderId)
  )
}

function shouldUpdateFile(
  existingFile: VaultFile,
  newFile: VaultFile
): boolean {
  if (!fileEquals(existingFile, newFile)) {
    return false
  }
  // If the file has a newer updatedAt time, update it
  return (
    newFile.updatedAt > existingFile.updatedAt ||
    // If the file has a new URL, update it
    (!_.isEmpty(newFile.url) && newFile.url !== existingFile.url) ||
    (!_.isEmpty(newFile.docAsPdfUrl) &&
      newFile.docAsPdfUrl !== existingFile.docAsPdfUrl)
  )
}

function shouldUpdateFolder(
  existingFolder: VaultFolder,
  newFolder: VaultFolder
): boolean {
  // Update folder if it has been 1) updated or 2) recently opened
  return (
    newFolder.updatedAt > existingFolder.updatedAt ||
    (!!newFolder.lastOpenedAt &&
      !!existingFolder.lastOpenedAt &&
      newFolder.lastOpenedAt > existingFolder.lastOpenedAt) ||
    (!!newFolder.lastOpenedAt && !existingFolder.lastOpenedAt)
  )
}

function updatedFile(newFile: VaultFile, existingFile: VaultFile): VaultFile {
  if (!shouldUpdateFile(existingFile, newFile)) {
    return existingFile
  }
  let updatedFile = { ...existingFile }
  if (newFile.updatedAt > existingFile.updatedAt) {
    updatedFile = { ...newFile }
  }
  updatedFile.url = !_.isEmpty(newFile.url) ? newFile.url : existingFile.url
  updatedFile.docAsPdfUrl = !_.isEmpty(newFile.docAsPdfUrl)
    ? newFile.docAsPdfUrl
    : existingFile.docAsPdfUrl
  return updatedFile
}

function updatedFolder(
  newFolder: VaultFolder,
  existingFolder: VaultFolder
): VaultFolder {
  if (!shouldUpdateFolder(existingFolder, newFolder)) {
    return existingFolder
  }
  let updatedFolder = { ...existingFolder }
  if (newFolder.updatedAt > existingFolder.updatedAt) {
    updatedFolder = { ...newFolder }
  }
  updatedFolder.lastOpenedAt = newFolder.lastOpenedAt
    ? newFolder.lastOpenedAt
    : existingFolder.lastOpenedAt
  return updatedFolder
}

export const useVaultStore = create(
  devtools(
    immer<
      VaultState &
        VaultAction &
        VaultQueryBoxState &
        VaultQueryBoxAction &
        VaultDialogState &
        VaultDialogAction &
        VaultSheetState &
        VaultSheetAction &
        VaultStreamingState &
        VaultStreamingAction &
        VaultErrorState &
        VaultErrorAction &
        VaultStartFromScratchStore &
        VaultStartFromScratchActions
    >((set) => ({
      // VaultState
      isLayoutLoading: true,
      isProjectLayoutLoading: false,
      currentFolderId: null,
      currentProject: null,
      folderIdToVaultFolder: {},
      parentIdToVaultFolderIds: {},
      rootVaultFolderIds: [],
      fileIdToVaultFile: {},
      folderIdToVaultFileIds: {},
      localFileIds: new Set(),
      currentProjectMetadata: generateEmptyMetadata(''),
      projectsMetadata: {},
      queryId: '',
      isNewVaultReviewQuery: false,
      showProcessingProgress: {},
      showRecentQueries: true,
      recentlyUploadedFileIds: [],
      requiresProjectDataRefetch: false,
      exampleProjectIds: new Set(),
      areExampleProjectsLoaded: false,
      sharedProjectIds: new Set(),
      // VaultQueryBoxState
      pendingQuery: '',
      pendingQueryFileIds: null,
      pendingColumnIds: null,
      isTextAreaFocused: false,
      queryType: QUERY_TYPES.NN,
      questions: [],
      selectedQuestions: [],
      totalQuestionLength: 0,
      maxQuestionCharacterLength: MAX_QUESTION_CHAR_LENGTH,
      minQuestionCharacterLength: MIN_QUESTION_CHAR_LENGTH,
      isQuestionsOpen: false,
      isWorkflowRepsWarranties: false,
      // VaultDialogState
      areUploadButtonsDisabled: true,
      isCreateFolderDialogOpen: false,
      currentCreateFolderFolderId: null,
      isUploadFilesDialogOpen: false,
      currentUploadFilesFolderId: null,
      isDeleteDialogOpen: false,
      deleteRecords: [],
      isRenameDialogOpen: false,
      renameRecord: null,
      isMoveDialogOpen: false,
      moveRecords: [],
      isReviewQuerySelectionDialogOpen: false,
      isExportDialogOpen: false,
      isAddFilesDialogOpen: false,
      isVaultAssistantModalOpen: false,
      isEditClientMatterDialogOpen: false,
      isDuplicateModalOpen: false,
      isColumnBuilderDialogOpen: false,
      // VaultSheetState
      activeDocument: null,
      // VaultStreamingState
      queryIdToState: {},
      queryIdToReviewState: {},
      agGridEnterpriseLicenseRegistered: false,
      gridApi: null,
      // VaultErrorState
      error: null,
      // VaultStartFromScratchStore
      addColumnPopoverPosition: null,
      // VaultAction
      setIsLayoutLoading: (isLayoutLoading) => set({ isLayoutLoading }),
      setIsProjectLayoutLoading: (isProjectLayoutLoading) =>
        set({ isProjectLayoutLoading }),
      setCurrentFolderId: (folderId) => set({ currentFolderId: folderId }),
      setCurrentProject: (
        project,
        shouldUpdateProjectMetadataAndUserId = false
      ) =>
        set((state) => {
          if (!project) {
            return {
              currentProject: null,
              currentProjectMetadata: generateEmptyMetadata(''),
            }
          }

          const projectMetadata =
            state.projectsMetadata[project.id] ||
            generateEmptyMetadata(project.id)

          if (shouldUpdateProjectMetadataAndUserId && !projectMetadata.userId) {
            // Read the userId from the project object directly if it doesn't already
            // exist in the project metadata (e.g. when creating a new project)
            projectMetadata.userId = project.userId
            projectMetadata.userEmail = project.userEmail
          }

          state.currentProject = project
          state.currentProjectMetadata = projectMetadata

          if (shouldUpdateProjectMetadataAndUserId) {
            state.projectsMetadata[project.id] = projectMetadata
          }
        }),
      updateVaultFile: (fileId: string, newData: Partial<VaultFile>) =>
        set((state) => {
          const file = state.fileIdToVaultFile[fileId]
          if (!file) return
          state.fileIdToVaultFile[fileId] = { ...file, ...newData }
        }),
      upsertVaultFiles: (files) =>
        set((state) => {
          const updatedLocalFileIds = state.localFileIds
          files.forEach((file) => {
            // The existing file might be local file that has not been uploaded to the server yet
            // The id format is vaultFolderId-fileName
            const localFileId = `${file.vaultFolderId}-${file.name}`
            const existingFile =
              state.fileIdToVaultFile[file.id] ??
              state.fileIdToVaultFile[localFileId]
            if (localFileId in state.fileIdToVaultFile) {
              // Remove the local file id from dictionary
              delete state.fileIdToVaultFile[localFileId]
            }
            if (existingFile && shouldUpdateFile(existingFile, file)) {
              // Update the file
              state.fileIdToVaultFile[file.id] = updatedFile(file, existingFile)
              if (
                file.vaultFolderId !== existingFile.vaultFolderId ||
                file.id !== existingFile.id
              ) {
                state.folderIdToVaultFileIds[existingFile.vaultFolderId] =
                  state.folderIdToVaultFileIds[
                    existingFile.vaultFolderId
                  ]!.filter((id) => id !== existingFile.id)
                state.folderIdToVaultFileIds[file.vaultFolderId] ??= []
                state.folderIdToVaultFileIds[file.vaultFolderId]!.push(file.id)
              }
            } else if (!existingFile || !(file.id in state.fileIdToVaultFile)) {
              // Add the file if it's not in the dictionary
              state.fileIdToVaultFile[file.id] = file
              state.folderIdToVaultFileIds[file.vaultFolderId] ??= []
              state.folderIdToVaultFileIds[file.vaultFolderId]!.push(file.id)
            }
            // Update the local file ids
            if (file.id === localFileId) {
              updatedLocalFileIds.add(localFileId)
            } else {
              updatedLocalFileIds.delete(localFileId)
            }
          })

          state.localFileIds = updatedLocalFileIds
        }),
      upsertVaultFolders: (folders, currentUserId, isExampleProject) =>
        set((state) => {
          const updatedRootVaultFolderIds = new Set(state.rootVaultFolderIds)
          const updatedSharedProjectIds = new Set(state.sharedProjectIds)
          folders.forEach((folder) => {
            const existingFolder = state.folderIdToVaultFolder[folder.id]
            if (existingFolder && shouldUpdateFolder(existingFolder, folder)) {
              state.folderIdToVaultFolder[folder.id] = updatedFolder(
                folder,
                existingFolder
              )
              if (folder.parentId !== existingFolder.parentId) {
                if (existingFolder.parentId) {
                  state.parentIdToVaultFolderIds[existingFolder.parentId] =
                    state.parentIdToVaultFolderIds[
                      existingFolder.parentId
                    ]!.filter((id) => id !== existingFolder.id)
                } else {
                  updatedRootVaultFolderIds.delete(existingFolder.id)
                }
                if (folder.parentId) {
                  state.parentIdToVaultFolderIds[folder.parentId] ??= []
                  state.parentIdToVaultFolderIds[folder.parentId]!.push(
                    folder.id
                  )
                } else {
                  updatedRootVaultFolderIds.add(folder.id)
                }
              }
            } else if (!existingFolder) {
              state.folderIdToVaultFolder[folder.id] = folder
              if (folder.parentId) {
                state.parentIdToVaultFolderIds[folder.parentId] ??= []
                state.parentIdToVaultFolderIds[folder.parentId]!.push(folder.id)
              } else {
                updatedRootVaultFolderIds.add(folder.id)
              }
            }

            // mark as shared project if user id is not the current user id
            if (folder.userId !== currentUserId && !isExampleProject) {
              updatedSharedProjectIds.add(folder.id)
            }
          })

          state.rootVaultFolderIds = Array.from(updatedRootVaultFolderIds)
          state.sharedProjectIds = updatedSharedProjectIds
        }),
      deleteVaultFiles: (fileIds) =>
        set((state) => {
          const updatedFileIdToVaultFile = { ...state.fileIdToVaultFile }
          fileIds.forEach((id) => {
            delete updatedFileIdToVaultFile[id]
          })
          return {
            fileIdToVaultFile: updatedFileIdToVaultFile,
          }
        }),
      deleteVaultFolders: (folderIds) =>
        set((state) => {
          const updatedFolderIdToVaultFolder = {
            ...state.folderIdToVaultFolder,
          }
          folderIds.forEach((id) => {
            delete updatedFolderIdToVaultFolder[id]
          })
          return {
            folderIdToVaultFolder: updatedFolderIdToVaultFolder,
          }
        }),
      addToProjectsMetadata: (projectsMetadata) => {
        set((state) => {
          state.projectsMetadata = {
            ...state.projectsMetadata,
            ...projectsMetadata,
          }
          updateProjectMetadata(state)
        })
      },
      updateProjectMetadata: () => {
        set((state) => updateProjectMetadata(state))
      },
      updateProjectMetadataLastFileUploadedAt: (lastFileUploadedAt: string) => {
        set((state) => ({
          currentProjectMetadata: {
            ...state.currentProjectMetadata,
            lastFileUploadedAt: lastFileUploadedAt,
          },
        }))
      },
      updateProjectMetadataClientMatterId: (clientMatterId: string) => {
        set((state) => ({
          currentProjectMetadata: {
            ...state.currentProjectMetadata,
            clientMatterId: clientMatterId,
          },
        }))
      },
      setQueryId: (queryId) => set({ queryId }),
      setIsNewVaultReviewQuery: (isNewVaultReviewQuery) =>
        set({ isNewVaultReviewQuery }),
      removeQueryFileIds: (fileIdsToRemove: string[]) =>
        set((state) => {
          if (!state.pendingQueryFileIds) {
            return {
              pendingQueryFileIds: null,
            }
          }
          state.pendingQueryFileIds = state.pendingQueryFileIds.filter(
            (id) => !fileIdsToRemove.includes(id)
          )

          state.queryIdToState[state.queryId]!.fileIds = state.queryIdToState[
            state.queryId
          ]!.fileIds.filter((id) => !fileIdsToRemove.includes(id))
        }),
      setShowProcessingProgress: (projectId, showProcessingProgress) =>
        set((state) => ({
          showProcessingProgress: {
            ...state.showProcessingProgress,
            [projectId]: showProcessingProgress,
          },
        })),
      setShowRecentQueries: (showRecentQueries: boolean) =>
        set({ showRecentQueries }),
      setRecentlyUploadedFileIds: (fileIds: string[]) =>
        set({ recentlyUploadedFileIds: fileIds }),
      setRequiresProjectDataRefetch: (requiresProjectDataRefetch) =>
        set({ requiresProjectDataRefetch }),
      setExampleProjectIds: (exampleProjectIds: Set<string>) =>
        set({ exampleProjectIds }),
      setAreExampleProjectsLoaded: (areExampleProjectsLoaded: boolean) =>
        set({ areExampleProjectsLoaded }),
      markInProgressReviewTask: (queryId: string) =>
        set((state) => {
          if (state.queryIdToState[queryId]) {
            state.queryIdToState[queryId]!.isLoading = true
          }
        }),
      // VaultQueryBoxAction
      setPendingQuery: (pendingQuery) => set({ pendingQuery }),
      setPendingQueryFileIds: (pendingQueryFileIds) =>
        set({ pendingQueryFileIds }),
      setPendingColumnIds: (pendingColumnIds) =>
        set((state) => {
          if (!pendingColumnIds) {
            return {
              pendingColumnIds: null,
            }
          }
          return {
            pendingColumnIds: [
              ...(state.pendingColumnIds ?? []),
              ...pendingColumnIds,
            ],
          }
        }),
      setIsTextAreaFocused: (isTextAreaFocused) => set({ isTextAreaFocused }),
      setQueryType: (queryType) => set({ queryType }),
      setQuestions: (questions) => set({ questions }),
      setSelectedQuestions: (selectedQuestions, fromQuestions) =>
        set((state) => ({
          selectedQuestions:
            selectedQuestions.length > 0
              ? selectedQuestions.sort(
                  (a, b) => fromQuestions.indexOf(a) - fromQuestions.indexOf(b)
                )
              : [],
          totalQuestionLength:
            (state.queryId
              ? state.queryIdToReviewState[state.queryId]?.questions.length ?? 0
              : 0) + selectedQuestions.length,
        })),
      setMaxQuestionCharacterLength: (maxQuestionCharacterLength) =>
        set({ maxQuestionCharacterLength }),
      setMinQuestionCharacterLength: (minQuestionCharacterLength) =>
        set({ minQuestionCharacterLength }),
      setIsQuestionsOpen: (isQuestionsOpen) => set({ isQuestionsOpen }),
      setIsWorkflowRepsWarranties: (isWorkflowRepsWarranties) =>
        set({ isWorkflowRepsWarranties }),
      // VaultSheetAction
      setActiveDocument: (activeDocument) => set({ activeDocument }),
      // VaultDialogAction
      setAreUploadButtonsDisabled: (areDisabled) =>
        set({ areUploadButtonsDisabled: areDisabled }),
      setIsCreateFolderDialogOpen: (isOpen) =>
        set({ isCreateFolderDialogOpen: isOpen }),
      setCurrentCreateFolderFolderId: (folderId) =>
        set({ currentCreateFolderFolderId: folderId }),
      setIsUploadFilesDialogOpen: (isOpen) =>
        set({ isUploadFilesDialogOpen: isOpen }),
      setCurrentUploadFilesFolderId: (folderId) =>
        set({ currentUploadFilesFolderId: folderId }),
      setIsDeleteDialogOpen: (isOpen) => set({ isDeleteDialogOpen: isOpen }),
      setDeleteRecords: (records) => set({ deleteRecords: records }),
      setIsRenameDialogOpen: (isOpen) => set({ isRenameDialogOpen: isOpen }),
      setRenameRecord: (record) => set({ renameRecord: record }),
      setIsMoveDialogOpen: (isOpen) => set({ isMoveDialogOpen: isOpen }),
      setMoveRecords: (records) => set({ moveRecords: records }),
      setIsReviewQuerySelectionDialogOpen: (isOpen) =>
        set({ isReviewQuerySelectionDialogOpen: isOpen }),
      setIsExportDialogOpen: (isOpen) => set({ isExportDialogOpen: isOpen }),
      setIsAddFilesDialogOpen: (isOpen) =>
        set({ isAddFilesDialogOpen: isOpen }),
      setIsVaultAssistantModalOpen: (isOpen) =>
        set({ isVaultAssistantModalOpen: isOpen }),
      setIsEditClientMatterDialogOpen: (isOpen) =>
        set({ isEditClientMatterDialogOpen: isOpen }),
      setIsDuplicateModalOpen: (isOpen) =>
        set({ isDuplicateModalOpen: isOpen }),
      setIsColumnBuilderDialogOpen: (isOpen) =>
        set({ isColumnBuilderDialogOpen: isOpen }),
      // VaultStreamingAction
      addQuestionToQuery: (question: QueryQuestion) =>
        set((state) => {
          if (state.queryId === 'new' && !state.queryIdToReviewState['new']) {
            state.queryIdToReviewState['new'] =
              generateEmptyVaultReviewSocketState(true)
            state.queryIdToReviewState['new'].questions = [question]
            state.queryIdToReviewState['new'].columnHeaders = [
              {
                id: question.id,
                text: question.header ?? '',
                columnDataType: question.columnDataType,
              },
            ]
          } else {
            state.queryIdToReviewState[state.queryId]!.questions = [
              ...(state.queryIdToReviewState[state.queryId]?.questions ?? []),
              question,
            ]
            state.queryIdToReviewState[state.queryId]!.columnHeaders = [
              ...(state.queryIdToReviewState[state.queryId]?.columnHeaders ??
                []),
              {
                id: question.id,
                text: question.header ?? '',
                columnDataType: question.columnDataType,
              },
            ]
          }
        }),
      updateQuestionInQuery: (question: QueryQuestion) =>
        set((state) => {
          state.queryIdToReviewState[state.queryId]!.questions =
            state.queryIdToReviewState[state.queryId]!.questions.map((q) => {
              if (q.id === question.id) {
                return question
              }
              return q
            })
          state.queryIdToReviewState[state.queryId]!.columnHeaders =
            state.queryIdToReviewState[state.queryId]!.columnHeaders.map(
              (q) => {
                if (q.id === question.id) {
                  return {
                    id: question.id,
                    text: question.header ?? '',
                    columnDataType: question.columnDataType,
                  }
                }
                return q
              }
            )
        }),
      deleteQuestionFromQuery: (questionId: string) =>
        set((state) => {
          state.queryIdToReviewState[state.queryId]!.questions =
            state.queryIdToReviewState[state.queryId]!.questions.filter(
              (q) => q.id !== questionId
            )
        }),
      clearNewQueryState: () =>
        set((state) => {
          delete state.queryIdToState['new']
          delete state.queryIdToReviewState['new']
        }),
      setTask: (socketState: Partial<VaultSocketTask>) =>
        set((state) => {
          const queryId = socketState.queryId ?? state.queryId
          if (
            state.queryIdToState[queryId] &&
            (socketState.isFromHistory ?? false) !==
              state.queryIdToState[queryId]!.isFromHistory
          ) {
            // If the new state data source is not the same as the existing state, we don't
            // want to override the existing state with the new state.
            return
          }

          state.queryIdToState[queryId] ??=
            generateEmptyVaultCurrentStreamingState(queryId)

          if (
            _.isNil(socketState.metadata) ||
            !('type' in socketState.metadata)
          ) {
            // When we have metadata in the socket state but don't have type, it means
            // it's end of the streaming and we are getting the final response, or we
            // are getting the history event of a N:1 query.
            state.queryIdToState[queryId] = {
              ...state.queryIdToState[queryId]!,
              ...socketState,
              ...socketState.metadata,
            }
            return
          }

          // Reaching this condition means the metadata type is not empty
          // In theory, N:1 query metadata only includes title, but we re-use N:N metadata
          // because linter will complain of unnecessary check if property type is a single literal
          const metadata = socketState.metadata as GenerateNNResponseMetadata

          if (metadata.type === 'title' && socketState.response !== undefined) {
            state.queryIdToState[queryId]!.title = socketState.response
            return
          }

          if (metadata.type === 'max_token_limit_reached') {
            state.queryIdToState[queryId]!.maxTokenLimitReached = true
            return
          }

          if (
            metadata.type === 'sourced_file_ids' &&
            socketState.response !== undefined
          ) {
            // Update the sourced file ids of the query
            state.queryIdToState[queryId]!.sourcedFileIds =
              socketState.response.split(',')
            return
          }

          console.info('Socket state', socketState)
          console.error(
            `Unhandled socket state from Vault ask query during ${socketState.headerText}`
          )
        }),
      clearPendingTask: () =>
        set((state) => {
          delete state.queryIdToState['']
          delete state.queryIdToReviewState['']
        }),
      setReviewTask: (socketState: Partial<VaultSocketTask>) =>
        set((state) => {
          const queryId = socketState.queryId ?? state.queryId
          if (
            state.queryIdToState[queryId] &&
            socketState.isFromHistory !== undefined &&
            socketState.isFromHistory !==
              state.queryIdToState[queryId]!.isFromHistory
          ) {
            // If the new state data source is not the same as the existing state, we don't
            // want to override the existing state with the new state.
            return
          }

          state.queryIdToState[queryId] ??=
            generateEmptyVaultCurrentStreamingState(
              queryId,
              socketState.isVaultV2User
            )
          state.queryIdToReviewState[queryId] ??=
            generateEmptyVaultReviewSocketState(
              socketState.isVaultV2User ?? false
            )

          if (
            _.isNil(socketState.metadata) ||
            !('type' in socketState.metadata)
          ) {
            // When we have metadata in the socket state but don't have type, it means
            // it's end of the streaming and we are getting the final response, or we
            // are getting the history event of a review query.
            state.queryIdToState[queryId] = {
              ...state.queryIdToState[queryId]!,
              ...socketState,
              ...socketState.metadata,
            }
            state.queryIdToReviewState[queryId] = {
              ...state.queryIdToReviewState[queryId]!,
              ...socketState,
              ...socketState.metadata,
              fileIdToSources: {
                ...state.queryIdToReviewState[queryId]!.fileIdToSources,
                ..._.groupBy(
                  socketState.sources,
                  (source) => source.documentId
                ),
              },
            }
            return
          }

          const metadata = socketState.metadata as GenerateNNResponseMetadata

          if (metadata.type === 'title' && socketState.response !== undefined) {
            // Update the title of the query
            // TODO: setTask already handles this, but since setTask and setReviewTask are mutually exclusive,
            // we need to duplicate the code to handle this here.
            state.queryIdToState[queryId]!.title = socketState.response
            return
          }

          if (
            metadata.type === 'header' &&
            socketState.response !== undefined &&
            metadata.columnId
          ) {
            // Update the columns of the query
            state.queryIdToReviewState[queryId]!.columnHeaders.push({
              id: metadata.columnId,
              text: socketState.response,
              columnDataType: metadata.columnDataType,
            })
            return
          }

          if (
            metadata.type === 'cell' &&
            socketState.response !== undefined &&
            metadata.fileId &&
            metadata.columnId
          ) {
            // Update the response of a cell in the query
            state.queryIdToReviewState[queryId]!.answers[metadata.fileId] ??= []
            state.queryIdToReviewState[queryId]!.answers[metadata.fileId]!.push(
              {
                columnId: metadata.columnId,
                long: metadata.longResponse ?? false,
                text: socketState.response,
                columnDataType: metadata.columnDataType,
              }
            )
            // Clear error for this cell if it exists
            if (
              state.queryIdToReviewState[queryId]!.errors[
                metadata.fileId
              ]?.find((error) => error.columnId === metadata.columnId)
            ) {
              const updatedErrors = state.queryIdToReviewState[queryId]!.errors[
                metadata.fileId
              ]!.filter((error) => error.columnId !== metadata.columnId)
              if (updatedErrors.length === 0) {
                delete state.queryIdToReviewState[queryId]!.errors[
                  metadata.fileId
                ]
              } else {
                state.queryIdToReviewState[queryId]!.errors[metadata.fileId] =
                  updatedErrors
              }
            }
            return
          }

          if (
            metadata.type === 'cell_error' &&
            socketState.response !== undefined &&
            metadata.fileId &&
            metadata.columnId
          ) {
            // Update the error of a cell in the query
            state.queryIdToReviewState[queryId]!.errors[metadata.fileId] ??= []
            state.queryIdToReviewState[queryId]!.errors[metadata.fileId]!.push({
              columnId: metadata.columnId,
              text: socketState.response,
            })
            return
          }

          if (metadata.type === 'sources' && metadata.fileId) {
            // Update the sources of a file in the query
            state.queryIdToReviewState[queryId]!.fileIdToSources[
              metadata.fileId
            ] = socketState.sources
            return
          }

          if (metadata.type === 'eta' && socketState.response !== undefined) {
            // Update the ETA of the query
            state.queryIdToReviewState[queryId]!.eta = socketState.response
            return
          }

          if (
            metadata.type === 'processed_file_ids' &&
            socketState.response !== undefined
          ) {
            // Update the processed file ids of the query
            state.queryIdToReviewState[queryId]!.processedFileIds =
              socketState.response.split(',')
            return
          }

          console.info('Socket state', socketState)
          console.error(
            `Unhandled socket state from Vault review query during ${socketState.headerText}`
          )
        }),
      setColumnOrder: (queryId, columnOrder) =>
        set((state) => {
          if (state.queryIdToReviewState[queryId]) {
            state.queryIdToReviewState[queryId]!.columnOrder = columnOrder
          }
        }),
      markInProgressTaskAsFromHistory: (taskType) =>
        set((state) => {
          const inProgressQuery = getInProgressQuery(
            state.queryIdToState,
            taskType
          )
          if (inProgressQuery) {
            state.queryIdToState[inProgressQuery.queryId]!.isFromHistory = true
          }
        }),
      markHistoryTaskAsFromStreaming: (queryId) => {
        set((state) => {
          const historyQuery = state.queryIdToState[queryId]
          if (historyQuery && historyQuery.isFromHistory) {
            historyQuery.isFromHistory = false
          }
        })
      },
      setAgGridEnterpriseLicenseRegistered: (
        agGridEnterpriseLicenseRegistered: boolean
      ) => set({ agGridEnterpriseLicenseRegistered }),
      setGridApi: (gridApi) => set({ gridApi }),
      // VaultErrorAction
      setError: (error) => set({ error }),
      // VaultStartFromScratchActions
      setAddColumnPopoverPosition: (
        popoverPosition: AddColumnPopoverPosition | null
      ) => set({ addColumnPopoverPosition: popoverPosition }),
    }))
  )
)
