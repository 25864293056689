import React from 'react'

import { cn } from 'utils/utils'

interface SectionHeaderProps {
  title: string
  sectionDescription?: string
  children?: React.ReactNode
  breadcrumb?: React.ReactNode
  className?: string
}

export const SectionHeader: React.FC<SectionHeaderProps> = ({
  title,
  children,
  breadcrumb,
  className,
  sectionDescription,
}) => {
  return (
    <div>
      <div
        className={cn(
          'mb-4 flex min-h-16 w-full items-end justify-between border-b pb-2',
          className
        )}
      >
        <div>
          {breadcrumb}
          <p className="text-nowrap text-xl">{title}</p>
          {sectionDescription && (
            <div className="line-clamp-1 pb-1 pl-0.5 pt-1">
              <p className="text-base text-muted">{sectionDescription}</p>
            </div>
          )}
        </div>

        <>{children}</>
      </div>
    </div>
  )
}

export default SectionHeader
