import React from 'react'

interface FeedbackSubmittedBannerProps {
  error: boolean
}

const submitDisplayString = (error: boolean): JSX.Element | string => {
  return error
    ? 'An error occurred while submitting your feedback. Please contact support@harvey.ai if this issue persists.'
    : 'Thank you for your feedback. It has been submitted successfully.'
}

const FeedbackSubmittedBanner: React.FC<FeedbackSubmittedBannerProps> = ({
  error,
}) => {
  return (
    <p className="flex h-[38px] items-center px-4 py-3 text-sm">
      {submitDisplayString(error)}
    </p>
  )
}

export default FeedbackSubmittedBanner
