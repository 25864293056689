import React, { useState, useEffect } from 'react'

import { Modal, Input, Form, message } from 'antd'
import { isNil } from 'lodash'

import type { Perm } from 'models/perms'
import { Maybe } from 'types'

interface AddEditPermModalProps {
  perm: Maybe<Perm>
  open: boolean
  onClose: () => void
  onSave: (perm: Perm) => void
  onUpdate: (perm: Perm) => void
}

interface FieldType {
  permId: string
  name: string
  desc: string
}

const AddEditPermModal = ({
  perm,
  open,
  onClose,
  onSave,
  onUpdate,
}: AddEditPermModalProps): JSX.Element => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const editing = !isNil(perm)

  useEffect(() => {
    if (editing) {
      form.setFieldsValue(perm)
    } else {
      form.resetFields()
    }
  }, [open, perm, form, editing])

  const onSubmit = async (): Promise<void> => {
    setLoading(true)
    try {
      const values = await form.validateFields()
      editing ? onUpdate(values) : onSave(values)
    } catch (errorInfo) {
      await message.error('Error saving perm')
    }
    setLoading(false)
    onClose()
  }

  const onCancel = (): void => {
    onClose()
    form.resetFields()
  }

  return (
    <Modal
      key={editing ? perm.permId : 'new'}
      open={open}
      onCancel={onCancel}
      title={editing ? 'Edit perm' : 'Add perm'}
      okText={editing ? 'Save' : 'Add'}
      onOk={() => {
        void onSubmit()
      }}
    >
      <div>
        <Form
          form={form}
          name="perms-form"
          layout="vertical"
          initialValues={editing ? perm : { permId: '', name: '', desc: '' }}
          disabled={loading}
        >
          <Form.Item<FieldType>
            label="Perm id"
            name="permId"
            rules={[{ required: true, message: 'Please input a perm ID' }]}
          >
            <Input placeholder="create:document" disabled={editing} />
          </Form.Item>
          <Form.Item<FieldType>
            label="Name"
            name="name"
            rules={[{ required: true, message: 'Please input a perm name' }]}
          >
            <Input placeholder="Create documents" />
          </Form.Item>
          <Form.Item<FieldType>
            label="Description"
            name="desc"
            rules={[
              { required: false, message: 'Please input a perm description' },
            ]}
          >
            <Input.TextArea
              rows={2}
              placeholder="Gives users ability to create documents"
            />
          </Form.Item>
        </Form>
      </div>
    </Modal>
  )
}

export default AddEditPermModal
