import React from 'react'

interface LinkProps {
  href: string
  children: React.ReactNode
  download?: string
}

const Link: React.FC<LinkProps> = ({ href, children, download }) => {
  return (
    <a
      href={href}
      className="text-primary focus:outline-none focus-visible:ring focus-visible:ring-ring"
      download={download}
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </a>
  )
}

export default Link
