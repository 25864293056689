import { useEffect, useRef } from 'react'

import { useShallow } from 'zustand/react/shallow'

import { usePDFViewerStore } from 'stores/pdf-viewer-store'

export const useAnnotationCaches = () => {
  const sourceAnnotations = usePDFViewerStore(
    useShallow((s) => s.sourceAnnotations)
  )
  const updateSourceAnnotation = usePDFViewerStore(
    (s) => s.updateSourceAnnotation
  )
  const sourceAnnotationsRef = useRef(sourceAnnotations)
  const updateSourceAnnotationsRef = useRef(updateSourceAnnotation)
  useEffect(() => {
    updateSourceAnnotationsRef.current = updateSourceAnnotation
  }, [updateSourceAnnotation])
  useEffect(() => {
    sourceAnnotationsRef.current = sourceAnnotations
  }, [sourceAnnotations])

  const documentAnnotations = usePDFViewerStore(
    useShallow((s) => s.documentAnnotations)
  )
  const updateDocumentAnnotation = usePDFViewerStore(
    (s) => s.updateDocumentAnnotation
  )
  const documentAnnotationsRef = useRef(documentAnnotations)
  const updateDocumentAnnotationsRef = useRef(updateDocumentAnnotation)
  useEffect(() => {
    updateDocumentAnnotationsRef.current = updateDocumentAnnotation
  }, [updateDocumentAnnotation])
  useEffect(() => {
    documentAnnotationsRef.current = documentAnnotations
  }, [documentAnnotations])

  return {
    sourceAnnotationsRef,
    updateSourceAnnotationsRef,
    documentAnnotationsRef,
    updateDocumentAnnotationsRef,
  }
}
