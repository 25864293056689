import React, { useEffect, useState } from 'react'

import SettingsAppHeader from 'components/settings/settings-app-header'
import SettingsError from 'components/settings/settings-error'
import SettingsLayout from 'components/settings/settings-layout'
import { useSettingsState } from 'components/settings/settings-store'

import IntegrationAdmin from './integration-admin'
import IntegrationConnection from './integration-connection'
import { ConnectedIntegration, getIntegrationConnections } from './utils'

const IntegrationsPage = () => {
  const [connections, setConnections] = useState<ConnectedIntegration[]>([])
  useEffect(() => {
    const fetchConnections = async () => {
      const resp = await getIntegrationConnections()
      setConnections(resp)
    }
    fetchConnections()
  }, [])

  const settingsUser = useSettingsState((s) => s.settingsUser)
  if (!settingsUser?.isAnyIntegrationUser)
    return (
      <SettingsError
        description={`You don't have access to integrations for ${settingsUser?.workspace.clientName}`}
      />
    )

  return (
    <>
      <SettingsAppHeader />
      <SettingsLayout>
        <div className="space-y-10">
          {settingsUser.isIntegrationAdmin ? (
            <IntegrationAdmin
              userInfo={settingsUser}
              connections={connections}
            />
          ) : (
            <IntegrationConnection
              userInfo={settingsUser}
              connections={connections}
            />
          )}
        </div>
      </SettingsLayout>
    </>
  )
}

export default IntegrationsPage
