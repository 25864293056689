import { DestinationLanguageCode } from 'openapi/models/DestinationLanguageCode'
import { Language } from 'openapi/models/Language'

export const TRANSLATION_OPTIONS: Record<string, Language> = {
  [DestinationLanguageCode.AR]: {
    code: DestinationLanguageCode.AR,
    value: 'Arabic',
    label: 'Arabic',
    isRtl: true,
  },
  [DestinationLanguageCode.DA]: {
    code: DestinationLanguageCode.DA,
    value: 'Danish',
    label: 'Danish',
  },
  [DestinationLanguageCode.NL]: {
    code: DestinationLanguageCode.NL,
    label: 'Dutch',
    value: 'Dutch',
  },
  [DestinationLanguageCode.EN]: {
    code: DestinationLanguageCode.EN,
    label: 'English',
    value: 'English',
  },
  [DestinationLanguageCode.FR]: {
    code: DestinationLanguageCode.FR,
    label: 'French',
    value: 'French',
  },
  [DestinationLanguageCode.DE]: {
    code: DestinationLanguageCode.DE,
    label: 'German',
    value: 'German',
  },
  [DestinationLanguageCode.IT]: {
    code: DestinationLanguageCode.IT,
    label: 'Italian',
    value: 'Italian',
  },
  [DestinationLanguageCode.JA]: {
    code: DestinationLanguageCode.JA,
    label: 'Japanese',
    value: 'Japanese',
  },
  [DestinationLanguageCode.KO]: {
    code: DestinationLanguageCode.KO,
    label: 'Korean',
    value: 'Korean',
  },
  [DestinationLanguageCode.MS]: {
    code: DestinationLanguageCode.MS,
    label: 'Malay',
    value: 'Malay',
  },
  [DestinationLanguageCode.PT_BR]: {
    code: DestinationLanguageCode.PT_BR,
    label: 'Portuguese (Brazil)',
    value: 'Brazilian Portuguese',
  },
  [DestinationLanguageCode.PT_PT]: {
    code: DestinationLanguageCode.PT_PT,
    label: 'Portuguese (Portugal)',
    value: 'European Portuguese',
  },
  [DestinationLanguageCode.ZH_CN]: {
    code: DestinationLanguageCode.ZH_CN,
    label: 'Simplified Chinese',
    value: 'Simplified Chinese',
  },
  [DestinationLanguageCode.ES]: {
    code: DestinationLanguageCode.ES,
    label: 'Spanish',
    value: 'Spanish',
  },
}
export function labelFromCode(code: string): string {
  const language = TRANSLATION_OPTIONS[code]
  if (!language) {
    throw new Error(`No matching label found for code: ${code}`)
  }
  return language.label
}
