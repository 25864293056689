import React from 'react'

import { Button } from 'components/ui/button'
import {
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogFooter,
  DialogClose,
} from 'components/ui/dialog'

interface CTA {
  label: string
  onClick: () => void
}

interface ConfirmationDialogProps {
  title: string
  description: string
  cta: CTA
  secondaryCta: CTA
  variant?: 'default' | 'destructive'
  showCloseIcon?: boolean
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  title,
  description,
  variant = 'default',
  cta,
  secondaryCta,
  showCloseIcon = true,
}) => {
  return (
    <DialogContent showCloseIcon={showCloseIcon}>
      <DialogHeader>
        <DialogTitle>{title}</DialogTitle>
        <DialogDescription>{description}</DialogDescription>
      </DialogHeader>
      <DialogFooter>
        <DialogClose asChild>
          <Button variant="outline" onClick={secondaryCta.onClick}>
            {secondaryCta.label}
          </Button>
        </DialogClose>
        <DialogClose asChild>
          <Button variant={variant} onClick={cta.onClick}>
            {cta.label}
          </Button>
        </DialogClose>
      </DialogFooter>
    </DialogContent>
  )
}

export default ConfirmationDialog
