import React, { useState } from 'react'
import { FileRejection, useDropzone } from 'react-dropzone'

import { Alert, Card, List, Space, Spin, Typography, message } from 'antd'
import classnames from 'classnames'
import { format } from 'date-fns'
import emailAddresses, { ParsedMailbox } from 'email-addresses'
import _ from 'lodash'
import { UploadCloud } from 'lucide-react'

import { removePwcUsers } from 'models/users'
import { Maybe } from 'types'

import { MB_BYTES } from 'utils/file-utils'
import { displayErrorMessage, displaySuccessMessage } from 'utils/toast'

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from 'components/ui/accordion'
import { Badge } from 'components/ui/badge'
import { Button } from 'components/ui/button'
import CsvDownload from 'components/ui/csv-download'

export interface PWCCsvUser {
  email: string
  territory: string
}

const PWCUserRemove: React.FC = () => {
  const [loading, setLoading] = useState(false)
  const [failedUsers, setFailedUsers] = useState<string[]>([])
  const [deletedUsers, setDeletedUsers] = useState<string[]>([])
  const [error, setError] = useState<string>('')
  const [uploadedFile, setUploadedFile] = useState<File | null>(null)
  const [deleteCSVUsers, setDeleteCSVUsers] = useState<PWCCsvUser[]>([])
  const [failedCSVUsers, setFailedCSVUsers] = useState<string[]>([])

  const cleanState = (): void => {
    setFailedUsers([])
    setDeletedUsers([])
  }

  const onDrop = (
    acceptedFiles: File[],
    fileRejections: FileRejection[]
  ): void => {
    cleanState()
    if (fileRejections.length > 0) {
      setError(fileRejections[0].errors[0].message)
      return
    } // add toast here
    if (acceptedFiles.length > 0) {
      setUploadedFile(acceptedFiles[0])
    }
    const fileReader = new FileReader()
    fileReader.readAsText(acceptedFiles[0], 'UTF-8')
    fileReader.onload = function (evt) {
      const csv = evt?.target?.result as string
      if (csv) {
        const lines = csv.split('\n')
        const users: PWCCsvUser[] = []
        const failedUsers: string[] = []
        for (let i = 0; i < lines.length; i++) {
          const line = lines[i].split(',')
          if (line.length < 2) {
            failedUsers.push(line.join(','))
            continue
          }
          const email = line[0].trim().toLowerCase().replace(/"/g, '')
          const territory = line[1].trim()
          const parsedEmail: Maybe<ParsedMailbox> =
            emailAddresses.parseOneAddress(email) as Maybe<ParsedMailbox>
          if (parsedEmail && !_.isNil(parsedEmail.parts.address)) {
            users.push({ email: parsedEmail.address, territory })
          } else {
            failedUsers.push(email)
          }
        }
        setDeleteCSVUsers(users)
        setFailedCSVUsers(failedUsers)
      }
    }
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    // eslint-disable-next-line
    onDrop,
    multiple: false,
    maxSize: 50 * MB_BYTES,
    maxFiles: 5,
    accept: {
      'text/csv': ['.csv', '.CSV'],
    },
  })

  const onPwcCsvSubmit = async (): Promise<void> => {
    try {
      cleanState()
      setLoading(true)

      const response = await removePwcUsers(deleteCSVUsers)

      setFailedUsers(response.usersFailed)
      setDeletedUsers(response.usersDeleted)

      setLoading(false)
      if (
        _.isEmpty(response) ||
        (_.isEmpty(response.usersFailed) && _.isEmpty(response.usersDeleted))
      ) {
        displayErrorMessage('Error removing PWC users')
      } else {
        displaySuccessMessage('PWC users removed successfully')
      }
    } catch (errorInfo) {
      await message.error('Error removing users')
    }
  }

  const resultEmpty = _.isEmpty(failedUsers) && _.isEmpty(deletedUsers)

  const formattedNow = format(new Date(), 'yyyyMMdd')

  return (
    <Accordion type="single" collapsible className="rounded-md border">
      <AccordionItem value="user-remove">
        <AccordionTrigger className="p-4">
          <div className="flex justify-between">
            <h2 className="text-lg font-semibold">Bulk PWC user remove</h2>
          </div>
        </AccordionTrigger>
        <AccordionContent className="p-5 pb-0">
          <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
            <Typography>
              <b>Upload complete PWC license file.</b>
              <br />
              Active users which are not in the license file will have all their
              user perms and roles revoked.
            </Typography>
            <Typography>
              Please ensure the CSV only contains 2 columns, no headers or extra
              rows
            </Typography>
            <Typography>Format: Email , Territory</Typography>
            {!_.isEmpty(error) && (
              <Alert
                type="error"
                showIcon
                closable
                message={<Typography>{error}</Typography>}
              />
            )}
            {uploadedFile ? (
              <Card
                type="inner"
                title={
                  <Typography.Title level={5}>
                    {uploadedFile.name}
                  </Typography.Title>
                }
                extra={
                  <Button
                    onClick={() => {
                      setUploadedFile(null)
                    }}
                    disabled={loading}
                  >
                    Clear
                  </Button>
                }
              >
                {resultEmpty ? (
                  <Spin spinning={loading}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <List>
                        <List.Item>
                          <Typography>
                            Users parsed: {deleteCSVUsers.length}
                          </Typography>
                        </List.Item>
                        <List.Item>
                          <Typography>
                            Users incorrectly formatted:{' '}
                            {failedCSVUsers.join(', ')}{' '}
                          </Typography>
                        </List.Item>
                      </List>
                      <Button
                        onClick={onPwcCsvSubmit}
                        style={{ marginTop: '16px' }}
                        disabled={loading}
                      >
                        Submit
                      </Button>
                    </div>
                  </Spin>
                ) : (
                  <div className="mb-2">
                    <List>
                      <List.Item
                        actions={
                          _.isEmpty(deletedUsers)
                            ? []
                            : [
                                <CsvDownload
                                  key="deleteUsers"
                                  data={deletedUsers.map((email) => ({
                                    email,
                                  }))}
                                  buttonText="Download deleted users"
                                  filename={`PWC_deleted)users_${formattedNow}.csv`}
                                  headers={['email']}
                                />,
                              ]
                        }
                      >
                        Deleted users: {deletedUsers.length}
                      </List.Item>
                      <List.Item>
                        Users failed:{' '}
                        {failedUsers.length
                          ? _.sortBy(
                              failedUsers.map((email, index) => (
                                <Badge key={index} variant="ghost">
                                  {email}
                                </Badge>
                              ))
                            )
                          : '-'}
                      </List.Item>
                    </List>
                  </div>
                )}
              </Card>
            ) : (
              <div
                className={classnames({
                  'relative mb-4 flex h-full cursor-pointer items-center justify-center rounded-lg border border-dashed bg-secondary py-8 shadow-inner transition-colors hover:bg-secondary':
                    true,
                  isDragging: isDragActive,
                })}
                {...getRootProps()}
              >
                <input {...getInputProps()} />
                <div className="flex h-full w-full flex-col items-center justify-center space-y-2 text-muted">
                  <UploadCloud />
                  {isDragActive ? (
                    <span>Drop the file here</span>
                  ) : (
                    <div className="flex flex-col items-center">
                      <div className="mt-2 block font-semibold">
                        Click to upload or drag and drop.
                      </div>
                      <div className="mt-2 block">Max file size: 50MB</div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </Space>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  )
}

export default PWCUserRemove
