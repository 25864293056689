import React, { useCallback, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useMount, useUnmount } from 'react-use'

import _ from 'lodash'

import { useHistoryItemQuery } from 'models/queries/use-history-item-query'
import { EventKind } from 'openapi/models/EventKind'
import { WorkflowType } from 'types/workflows'

import { useNavigateWithQueryParams } from 'hooks/use-navigate-with-query-params'
import { feedbackClosedState } from 'utils/feedback'
import useHarveySocket from 'utils/use-harvey-socket'
import {
  HarveySocketCompletionStatus,
  hasCompletedStreaming,
} from 'utils/use-harvey-socket-utils'

import { useClientMattersStore } from 'components/client-matters/client-matters-store'
import useQueryAnalytics from 'components/common/analytics/use-query-analytics'
import { useAuthUser } from 'components/common/auth-context'
import { WorkflowTypeToDetails } from 'components/workflows/workflow-definitions'

import { CompanyProfileInputForm } from './input-form'
import CompanyProfileResponse from './response'
import { useCompanyProfileStore } from './store'

const ROUTE = 'company_profile'
const TASK_TYPE = 'COMPANY_PROFILE'

const CompanyProfileWorkflow: React.FC = () => {
  const {
    completionStatus,
    query,
    queryId,
    reset,
    response,
    setFeedback,
    setTask,
    selectedTaxonomies,
    loadTaxonomies,
  } = useCompanyProfileStore()
  const { id: historyId } = useParams<{ id: string }>()
  const { historyItem } = useHistoryItemQuery({ id: historyId ?? null })
  const userInfo = useAuthUser()
  const navigate = useNavigateWithQueryParams()
  const workflow = WorkflowTypeToDetails[WorkflowType.COMPANY_PROFILE]
  const { selectedClientMatter, setClientMatterSelectDisabled } =
    useClientMattersStore()

  const clearExistingQuery = useCallback(() => {
    setTask({ headerText: '', response: '', sources: [] })
    setFeedback(null)
  }, [setTask, setFeedback])

  const { initSocketAndSendQuery, sendCancelRequest } = useHarveySocket({
    path: ROUTE,
    setter: setTask,
    endCallback: () => {
      setClientMatterSelectDisabled(false)
    },
  })

  const {
    setQueryViewed,
    recordQueryCancel,
    recordQuerySubmitted,
    recordQueryCompletion,
    recordReset,
  } = useQueryAnalytics(EventKind.COMPANY_PROFILE)

  const handleCancel = () => {
    recordQueryCancel()
    sendCancelRequest()
  }

  useMount(() => {
    if (!_.isNil(historyItem)) {
      setTask({
        queryId: historyItem.id,
        query: historyItem.query,
        response: historyItem.response,
        completionStatus: HarveySocketCompletionStatus.Completed,
        annotations: historyItem.annotations,
      })
      setFeedback(feedbackClosedState)
      setQueryViewed()
    }
    loadTaxonomies()
  })

  useUnmount(() => {
    handleCancel()
    reset()
  })

  useEffect(() => {
    if (
      userInfo.IsHistoryUser &&
      queryId &&
      hasCompletedStreaming(completionStatus)
    ) {
      navigate(`${workflow.path}/${queryId}`, {
        replace: true,
        state: { fetchHistoryItem: false },
      })
      recordQueryCompletion()
    }
  }, [
    queryId,
    completionStatus,
    navigate,
    recordQueryCompletion,
    userInfo,
    workflow.path,
  ])

  const onSubmit = useCallback(() => {
    recordQuerySubmitted({
      event_kind: EventKind.COMPANY_PROFILE,
    })
    clearExistingQuery()
    const client_matter_id = userInfo.isClientMattersReadUser
      ? selectedClientMatter?.id
      : null

    setClientMatterSelectDisabled(true)
    initSocketAndSendQuery({
      query,
      additionalAuthParams: { task_type: TASK_TYPE, client_matter_id },
      additionalRequestParams: {
        company_profile_rows: selectedTaxonomies.flatMap((t) => t.rows),
        task_type: TASK_TYPE,
        // adding source_event_id if we have a query id
        // https://www.notion.so/harveyai/Make-sure-we-keep-honoring-event_source_id-04edcaaae0d8440a9b51808aef7af349?pvs=4
        source_event_id: queryId,
      },
    })
  }, [
    clearExistingQuery,
    initSocketAndSendQuery,
    query,
    queryId,
    selectedClientMatter,
    userInfo,
    selectedTaxonomies,
    setClientMatterSelectDisabled,
    recordQuerySubmitted,
  ])

  const onResetResponse = useCallback(() => {
    sendCancelRequest()
    reset()
    recordReset()
    navigate(workflow.path, { replace: true })
  }, [navigate, reset, recordReset, sendCancelRequest, workflow.path])

  const onResetInput = useCallback(() => {
    reset()
    navigate(workflow.path, { replace: true })
    // TODO MUST FIX
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset])

  return response?.length ? (
    <CompanyProfileResponse onReset={onResetResponse} onCancel={handleCancel} />
  ) : (
    <CompanyProfileInputForm onSubmit={onSubmit} onReset={onResetInput} />
  )
}

export default CompanyProfileWorkflow
