import { useCallback } from 'react'
import { NavigateOptions, useLocation, useNavigate } from 'react-router-dom'

import queryString from 'query-string'

export const getMergedQueryPath = (
  path: string,
  currentLocation: { search: string },
  removeParams?: string[]
) => {
  // Get the existing query parameters
  const existingQueryParams = queryString.parse(currentLocation.search)
  if (removeParams) {
    removeParams.forEach((param) => {
      delete existingQueryParams[param]
    })
  }

  // Get the query parameters from the path
  const urlObject = new URL(path, window.location.origin)
  const pathQueryParams = queryString.parse(urlObject.search)

  // Merge the query parameters
  const mergedQueryParams = { ...existingQueryParams, ...pathQueryParams }
  const mergedQueryString = queryString.stringify(mergedQueryParams)

  // Construct the new path with merged query parameters
  return mergedQueryString.length > 0
    ? `${urlObject.pathname}?${mergedQueryString}`
    : urlObject.pathname
}

/**
 * This function only calls navigate when we are navigating to a new path.  This helps avoid infinite rerenders when placed as a dependency in a react useEffect hook.
 */
export const useNavigateWithQueryParams = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const newNavigateFn = useCallback(
    (
      path: string,
      options?: NavigateOptions | undefined,
      removeParams?: string[]
    ) => {
      const newPath = getMergedQueryPath(path, location, removeParams)
      const existingPath = `${location.pathname}${location.search}`
      if (newPath !== existingPath) {
        navigate(newPath, options)
      }
    },
    [navigate, location]
  )

  return newNavigateFn
}
