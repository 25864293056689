import React from 'react'

import { ChevronsLeft, ChevronsRight } from 'lucide-react'
import { useShallow } from 'zustand/react/shallow'

import { AppHeader } from 'components/common/app-header'
import { Button } from 'components/ui/button'
import Icon from 'components/ui/icon/icon'

import { useSettingsState } from './settings-store'

export interface SettingsAppHeaderProps {
  title?: string
  subtitle?: string
  breadcrumbs?: React.ReactNode
  actions?: React.ReactNode
  isInternalAdmin?: boolean
}

const SettingsAppHeader = ({
  title,
  subtitle,
  breadcrumbs,
  actions,
  isInternalAdmin,
}: SettingsAppHeaderProps) => {
  const finalSubtitle = subtitle ?? 'Manage your settings'
  return (
    <AppHeader
      title={title ?? 'Settings'}
      subtitle={finalSubtitle}
      subtitleComponent={<SettingsAppHeaderSubtitle subtitle={finalSubtitle} />}
      breadcrumbs={breadcrumbs ?? <div />}
      actions={actions}
      isInternalAdmin={isInternalAdmin}
    />
  )
}

const SettingsAppHeaderSubtitle = ({ subtitle }: { subtitle: string }) => {
  const [isSettingsSidebarCollapsed, setIsSettingsSidebarCollapsed] =
    useSettingsState(
      useShallow((s) => [
        s.isSettingsSidebarCollapsed,
        s.setIsSettingsSidebarCollapsed,
      ])
    )
  return (
    <div className="flex items-center gap-1">
      <Button
        variant="ghost"
        size="xsIcon"
        className="flex shrink-0 sm:hidden"
        onClick={() =>
          setIsSettingsSidebarCollapsed(!isSettingsSidebarCollapsed)
        }
      >
        <Icon
          icon={isSettingsSidebarCollapsed ? ChevronsRight : ChevronsLeft}
          size="small"
        />
      </Button>
      <p className="truncate text-xs text-muted">{subtitle}</p>
    </div>
  )
}

export default SettingsAppHeader
