import React from 'react'
import { useEffect, useState } from 'react'

import { Maybe } from 'ag-grid-community/dist/types/core/columns/columnModel'
import _ from 'lodash'

import { EvaluationQuestionResponseOptionMultipleSelect } from 'openapi/models/EvaluationQuestionResponseOptionMultipleSelect'

import DeprecatedTagInput from 'components/common/deprecated-tag-input/deprecated-tag-input'
import { EvaluationQuestionResponseOptionInput } from 'components/settings/experiment/utils/experiment-utils'
import { Label } from 'components/ui/label'

// TODO check for extra re-renders
const EvaluationResponseOptionsMultipleSelectInput = ({
  responseOptions,
  setResponseOptions,
}: {
  responseOptions: Maybe<EvaluationQuestionResponseOptionInput>
  setResponseOptions: (
    responseOptions: Maybe<EvaluationQuestionResponseOptionInput>
  ) => void
}) => {
  // TODO need to deal with number vs string types, for now only use strings
  const [selectOptions, setSelectOptions] = useState<string[]>([])

  useEffect(() => {
    if (_.isEmpty(selectOptions)) {
      if (!_.isNil(responseOptions)) {
        setResponseOptions(undefined)
      }
      return
    }

    const newResponseOptions: EvaluationQuestionResponseOptionMultipleSelect = {
      selections: selectOptions,
    }
    // responseOptionsRef.current = responseOptions
    setResponseOptions(newResponseOptions)
  }, [selectOptions, responseOptions, setResponseOptions])

  return (
    <>
      <div className="my-5">
        <Label className="font-normal">Add select options</Label>
        <DeprecatedTagInput
          tags={selectOptions}
          setTags={setSelectOptions}
          closeableTags
          allowReset
          inputPlaceholder="Add an option"
          allowDuplicates={false}
        />
      </div>
    </>
  )
}

export default EvaluationResponseOptionsMultipleSelectInput
