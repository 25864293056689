import { useEffect } from 'react'

import {
  fetchEventFeedbackSentiments,
  submitEventFeedback,
  updateEventFeedback,
} from 'models/fetchers/event-fetcher'
import { HarvQueryKeyPrefix } from 'models/queries/all-query-keys'
import { useWrappedQuery } from 'models/queries/lib/use-wrapped-query'
import { UserInfo } from 'models/user-info'
import { Feedback } from 'openapi/models/Feedback'
import { MessageFeedback } from 'openapi/models/MessageFeedback'

import useVaultFeedbackStore from 'components/vault/utils/vault-feedback-store'

export const useFetchEventFeedbackSentiments = (
  eventId: number | null,
  userInfo: UserInfo
) => {
  const { data: feedbacks, isPending: isLoadingFeedbacks } = useWrappedQuery({
    queryKey: [HarvQueryKeyPrefix.EventFeedbacks, eventId],
    queryFn: () => fetchEventFeedbackSentiments(eventId!),
    // Disable refetch on window focus to prevent unnecessary re-fetches
    refetchOnWindowFocus: false,
    enabled: userInfo.IsFeedbackUser && !!eventId,
  })
  const setFeedback = useVaultFeedbackStore((s) => s.setFeedback)
  useEffect(() => {
    if (feedbacks && eventId) {
      feedbacks.forEach((feedback) => {
        if (!feedback.fileId || !feedback.questionId) return
        setFeedback({
          queryId: eventId.toString(),
          fileId: feedback.fileId,
          columnId: feedback.questionId,
          feedbackId: feedback.id,
          feedback: {
            sentiment: feedback.sentiment,
            selectedComments: getSelectedComments(feedback),
          },
        })
      })
    }
  }, [feedbacks, eventId, setFeedback])
  return { feedbacks, isLoadingFeedbacks }
}

export const useUpsertEventFeedback = () => {
  const getFeedback = useVaultFeedbackStore((s) => s.getFeedback)
  const setFeedback = useVaultFeedbackStore((s) => s.setFeedback)

  const upsertEventFeedback = async ({
    eventId,
    vaultFolderId,
    fileId,
    questionId,
    feedbackData,
  }: {
    eventId: number
    vaultFolderId: string
    fileId: string
    questionId: string
    feedbackData: MessageFeedback
  }) => {
    try {
      const existingFeedback = getFeedback(String(eventId), fileId, questionId)
      setFeedback({
        queryId: eventId.toString(),
        fileId: fileId,
        columnId: questionId,
        feedbackId: '',
        feedback: feedbackData,
      })
      let response
      if (existingFeedback && existingFeedback.id.length > 0) {
        // Update existing feedback
        response = await updateEventFeedback(
          eventId,
          existingFeedback.id,
          feedbackData
        )
      } else {
        // Submit new feedback
        response = await submitEventFeedback(eventId, {
          vaultFolderId: vaultFolderId,
          fileId: fileId,
          questionId: questionId,
          sentiment: feedbackData.sentiment,
          nonSensitiveMeta: { comments: feedbackData.selectedComments },
        })
      }
      setFeedback({
        queryId: response.eventId.toString(),
        fileId: response.fileId ?? '',
        columnId: response.questionId ?? '',
        feedbackId: response.id,
        feedback: {
          sentiment: response.sentiment,
          selectedComments: getSelectedComments(response),
        },
      })
      return response
    } catch (error) {
      console.error(error)
      setFeedback({
        queryId: eventId.toString(),
        fileId: fileId,
        columnId: questionId,
        feedbackId: '',
        feedback: {
          sentiment: 0,
          selectedComments: [],
        },
      })
      return null
    }
  }

  return { upsertEventFeedback }
}

const getSelectedComments = (feedback: Feedback) => {
  return feedback.nonSensitiveMeta
    ? (feedback.nonSensitiveMeta as { comments: string[] }).comments
    : []
}
