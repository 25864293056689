import React from 'react'

import {
  KnowledgeSourceConfig,
  useKnowledgeSources,
} from 'components/assistant/utils/assistant-knowledge-sources'
import {
  ASSISTANT_V2_OPEN_ENDED_HELP_INTRO,
  ASSISTANT_V2_WITHOUT_OPEN_ENDED_HELP_INTRO,
  ASSISTANT_V2_MODE_HELP,
  ASSISTANT_V2_OPEN_ENDED_HELP_REMINDERS,
  ASSISTANT_V2_WITHOUT_OPEN_ENDED_HELP_REMINDERS,
  ASSISTANT_KNOWLEDGE_SOURCES_INTRO,
  ASSISTANT_KNOWLEDGE_SOURCES_HEADER,
} from 'components/assistant/utils/strings'
import { useAuthUser } from 'components/common/auth-context'
import Markdown from 'components/common/markdown/markdown'
import Link from 'components/ui/link/link'

const AssistantTips = () => {
  const userInfo = useAuthUser()
  const isDocumentUser = userInfo.isDocumentUser
  const knowledgeSources = useKnowledgeSources(userInfo)

  let tipsText = isDocumentUser
    ? ASSISTANT_V2_OPEN_ENDED_HELP_INTRO
    : ASSISTANT_V2_WITHOUT_OPEN_ENDED_HELP_INTRO

  if (userInfo.IsAssistantDraftUser) {
    tipsText += ASSISTANT_V2_MODE_HELP
  }

  if (knowledgeSources.size > 0) {
    tipsText +=
      ASSISTANT_KNOWLEDGE_SOURCES_HEADER + ASSISTANT_KNOWLEDGE_SOURCES_INTRO
    knowledgeSources.forEach((source) => {
      const config = KnowledgeSourceConfig[source]
      if (config.tipsText) {
        tipsText += `<h4>${config.name}</h4>
            <p>${config.tipsText}</p>`
      }
    })
  }

  tipsText += isDocumentUser
    ? ASSISTANT_V2_OPEN_ENDED_HELP_REMINDERS
    : ASSISTANT_V2_WITHOUT_OPEN_ENDED_HELP_REMINDERS

  return (
    <>
      <Markdown content={tipsText} />
      {userInfo.IsViewHelpCenterUser && (
        <p className="mt-6 text-primary">
          For more information, please visit the{' '}
          <Link
            className="underline underline-offset-4"
            target="_blank"
            to="https://help.harvey.ai/en/articles/9740801-using-assistant-s-assist-and-draft-features"
          >
            Help Center
          </Link>
          .
        </p>
      )}
    </>
  )
}

export default AssistantTips
