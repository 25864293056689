import { GridApi } from 'ag-grid-community'

import { FilterType } from 'components/vault/utils/vault-data-grid-filters-store'

import { ColumnFilter } from './column-filter-types'

export class ClassifyColumnFilter extends ColumnFilter {
  getUniqueColumnValues(
    gridApi: GridApi,
    currentFilterQuestionId: string
  ): string[] {
    const uniqueValuesSet = new Set<string>()
    gridApi.forEachNode((node) => {
      if (node.group) return
      const value = node.data[currentFilterQuestionId]
      if (value != null) {
        const splitValues = value
          .toString()
          .split(',')
          .map((v: string) => v.trim())
        splitValues.forEach((v: string) => uniqueValuesSet.add(v))
      }
    })
    return Array.from(uniqueValuesSet).sort((a, b) => a.localeCompare(b))
  }

  getFilterType(): FilterType {
    return FilterType.CLASSIFY
  }
}
