import React, { useState } from 'react'

import { Trash2, CheckCircle, Loader2, XCircle } from 'lucide-react'

import { readableFormat } from 'utils/date-utils'
import { displayErrorMessage } from 'utils/toast'

import ConfirmationDialog from 'components/common/confirmation-dialog/confirmation-dialog'
import { Button } from 'components/ui/button'
import { Dialog, DialogTrigger } from 'components/ui/dialog'
import { ScrollArea } from 'components/ui/scroll-area'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from 'components/ui/table'

interface ExperimentUserOverviewTableProps {
  userOverviewEntries: ExperimentUserOverviewTableEntry[]
  handleRemoveUser: (email: string) => Promise<void>
}

export interface ExperimentUserOverviewTableEntry {
  email: string
  numEvaluationsCompleted: number
  numEvaluationsTotal: number
  userAddedAt: Date
  lastUserActivityAt?: Date
}

const ExperimentUserOverviewTable = ({
  userOverviewEntries,
  handleRemoveUser,
}: ExperimentUserOverviewTableProps) => {
  const [isLoadingEmailEntries, setIsLoadingEmailEntries] = useState<string[]>(
    []
  )

  const onRemoveUser = async (email: string) => {
    setIsLoadingEmailEntries([...isLoadingEmailEntries, email])
    try {
      await handleRemoveUser(email)
    } catch (error) {
      displayErrorMessage(`Error removing user: ${error}`)
    } finally {
      setIsLoadingEmailEntries(isLoadingEmailEntries.filter((e) => e !== email))
    }
  }

  return (
    <>
      <ScrollArea hasHorizontalScroll className="w-full">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Email</TableHead>
              <TableHead>Evals Complete</TableHead>
              <TableHead>Evals Total</TableHead>
              <TableHead>User Added At</TableHead>
              <TableHead>Last Response At</TableHead>
              <TableHead>Actions</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {userOverviewEntries.map((entry) => (
              <TableRow key={entry.email}>
                <TableCell>
                  <div className="flex items-center gap-2">
                    {/* NOTE figure out how to stop hover over background row when over button */}
                    {entry.email}{' '}
                    {entry.numEvaluationsCompleted ===
                    entry.numEvaluationsTotal ? (
                      <CheckCircle size={14} className="text-success" />
                    ) : (
                      <XCircle size={14} className="text-destructive" />
                    )}
                  </div>
                </TableCell>
                <TableCell>{entry.numEvaluationsCompleted}</TableCell>
                <TableCell>{entry.numEvaluationsTotal}</TableCell>
                <TableCell>{readableFormat(entry.userAddedAt)}</TableCell>
                <TableCell>
                  {entry.lastUserActivityAt
                    ? readableFormat(entry.lastUserActivityAt)
                    : '-'}
                </TableCell>
                <TableCell>
                  <Dialog>
                    <DialogTrigger
                      disabled={isLoadingEmailEntries.includes(entry.email)}
                    >
                      <Button
                        variant="outline"
                        size="icon"
                        disabled={isLoadingEmailEntries.includes(entry.email)}
                      >
                        {isLoadingEmailEntries.includes(entry.email) ? (
                          <Loader2 size={14} color="red" />
                        ) : (
                          <Trash2 size={14} color="red" />
                        )}
                      </Button>
                    </DialogTrigger>
                    <ConfirmationDialog
                      title={`Remove user ${entry.email}`}
                      description="Are you sure you want to remove this user from the experiment? 
                      This will remove the user and any of their existing evaluation responses. 
                      You will not be able to re-add them to the same experiment later."
                      cta={{
                        label: 'Remove',
                        onClick: () => onRemoveUser(entry.email),
                      }}
                      secondaryCta={{ label: 'Cancel', onClick: () => {} }}
                      variant="destructive"
                      showCloseIcon={false}
                    />
                  </Dialog>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </ScrollArea>
    </>
  )
}

export default ExperimentUserOverviewTable
