/* tslint:disable */
/* eslint-disable */
/**
 * Harvey Web App - Streaming Protocol (via websockets)
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Client-to-Server request message type
 * @export
 */
export const RequestMessageType = {
    AUTHENTICATE: 'AUTHENTICATE',
    CANCEL: 'CANCEL',
    REQUEST: 'REQUEST'
} as const;
export type RequestMessageType = typeof RequestMessageType[keyof typeof RequestMessageType];


export function instanceOfRequestMessageType(value: any): boolean {
    for (const key in RequestMessageType) {
        if (Object.prototype.hasOwnProperty.call(RequestMessageType, key)) {
            if (RequestMessageType[key as keyof typeof RequestMessageType] === value) {
                return true;
            }
        }
    }
    return false;
}

export function RequestMessageTypeFromJSON(json: any): RequestMessageType {
    return RequestMessageTypeFromJSONTyped(json, false);
}

export function RequestMessageTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): RequestMessageType {
    return json as RequestMessageType;
}

export function RequestMessageTypeToJSON(value?: RequestMessageType | null): any {
    return value as any;
}

