import React from 'react'
import { defaultUrlTransform } from 'react-markdown'

import { getHrvyInfoMetadata } from 'utils/source'
import { Source } from 'utils/task'

import { ScrollArea } from 'components/ui/scroll-area'

import Citation from './citation'
import Link from './link'
import Table from './table'

export const urlTransform = (url: string, key: string, node: any) => {
  if (
    node.tagName === 'img' &&
    key === 'src' &&
    url.startsWith('data:image/png;base64')
  ) {
    return url
  }
  return defaultUrlTransform(url)
}

export const getMarkdownComponents = ({
  getHrvyInfoMetadata,
  isLoading,
  sources,
  width,
}: {
  getHrvyInfoMetadata?: getHrvyInfoMetadata
  isLoading?: boolean
  sources?: Source[]
  width?: string
}) => ({
  span: ({ node, children, ...props }: any) => {
    if ('data-hrvy-id' in props && getHrvyInfoMetadata) {
      const harveyId = props['data-hrvy-id']
      const position = node.position.start
      const metadata = getHrvyInfoMetadata(harveyId, position)
      if (!metadata) return <span {...props}>{children}</span>
      const { url: href, onClick, ref } = metadata
      // when there is no url and no on click we just want to render normally
      // fixes issues with hallucination warnings & sec-edgar rendering
      if (!href && !onClick) return <span {...props}>{children}</span>

      const citationProps = {
        label: children,
        onClick,
        ...(href && { href }),
        hoverContent: metadata.hoverContent,
        isSelected: metadata.isSelected,
        citationRef: ref,
        eventData: metadata.eventData,
      }
      return <Citation {...citationProps} />
    }
    return <span {...props}>{children}</span>
  },
  a: ({ href, children, ...props }: any) => {
    // render email addresses as plain text
    if (href && href.startsWith('mailto:')) {
      return <span>{children}</span>
    }
    return (
      <Link href={href ?? ''} {...props}>
        {children}
      </Link>
    )
  },
  mark: ({ children }: any) => (
    <mark className="bg-highlight/60 text-xs text-highlight">{children}</mark>
  ),
  b: ({ children }: any) => <b className="font-semibold">{children}</b>,
  table: ({ children }: any) => {
    if (!width) {
      return <table>{children}</table>
    }

    if (isLoading) {
      return (
        <div className="overflow-x-auto" style={{ width }}>
          <table>{children}</table>
        </div>
      )
    }

    return (
      <Table sources={sources} width={width}>
        {children}
      </Table>
    )
  },
  ins: ({ children }: any) => (
    <ins className="font-inherit text-highlight no-underline">{children}</ins>
  ),
  del: ({ children }: any) => (
    <del className="font-inherit text-destructive">{children}</del>
  ),
  strong: ({ children }: any) => (
    <span className="font-semibold">{children}</span>
  ),
  code: ({ children }: any) => {
    if (!width) return <code>{children}</code>
    return (
      // code block has implicit padding of 16px, accounting for that
      <ScrollArea
        hasHorizontalScroll
        style={{ width: `calc(${width} - 32px)` }}
      >
        <code>{children}</code>
      </ScrollArea>
    )
  },
  p: ({ children }: any) => {
    // Sometimes the models will return <p><strong>Section Title</strong></p>. These should not be p tags, but h4 tags. P have different spacing rules, specifically they have equal margin top and bottom. Which is not ideal for this case.
    if (React.isValidElement(children)) {
      const childrenArray = React.Children.toArray(children)
      const hasStrongElement = childrenArray.some(
        (child) => React.isValidElement(child) && child.type === 'strong'
      )
      if (hasStrongElement && childrenArray.length === 1) {
        return <h4>{childrenArray[0]}</h4>
      }
    }

    return <p>{children}</p>
  },
})
