import { IntegrationType } from 'openapi/models/IntegrationType'
import Services from 'services'

interface ConnectOauthIntegrationResponse {
  authorizationUrl: string
}

export interface ConnectedIntegration {
  integrationType: IntegrationType
  expiresAt: string
  scopes: string[]
}

export interface ConnectedIntegrationToken extends ConnectedIntegration {
  accessToken: string
}

export const connectOauthIntegration = async (
  integrationType: IntegrationType
): Promise<void> => {
  const resp = await Services.Backend.Post<ConnectOauthIntegrationResponse>(
    `settings/integration/${integrationType}/authenticate`,
    { throwOnError: true }
  )
  if (resp.authorizationUrl) {
    window.open(resp.authorizationUrl)
  } else {
    throw new Error('Failed to connect to integration')
  }
}

export const disconnectIntegration = async (
  integrationType: IntegrationType
) => {
  await Services.Backend.Delete(
    `settings/integrations/${integrationType}/disconnect`,
    { throwOnError: true }
  )
}

export const fetchIntegrationToken = async (
  integrationType: IntegrationType
): Promise<ConnectedIntegrationToken> => {
  return Services.Backend.Get(`settings/integration/${integrationType}/token`, {
    throwOnError: true,
  })
}

export const getIntegrationConnections = async (): Promise<
  ConnectedIntegration[]
> => {
  const resp = await Services.Backend.Get<ConnectedIntegration[]>(
    'settings/integrations/connections',
    { throwOnError: true }
  )
  return resp
}
