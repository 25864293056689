import React, { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import { useShallow } from 'zustand/react/shallow'

import { QueryCapRuleUnitLevel } from 'openapi/models/QueryCapRuleUnitLevel'

import { useAuthUser } from 'components/common/auth-context'
import { Button } from 'components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from 'components/ui/dialog'
import VaultFileExplorer from 'components/vault/components/file-explorer/vault-file-explorer'
import { useRunReview } from 'components/vault/components/vault-app-header/use-run-review'
import {
  addFilesToGrid,
  GridTransactionAction,
} from 'components/vault/query-detail/data-grid-helpers'
import useVaultQueryDetailStore, {
  ReviewHistoryItem,
} from 'components/vault/query-detail/vault-query-detail-store'
import { GenerateNNResponseProps } from 'components/vault/utils/vault'
import { useVaultDataGridFilterStore } from 'components/vault/utils/vault-data-grid-filters-store'
import { useVaultFileExplorerStore } from 'components/vault/utils/vault-file-explorer-store'
import {
  getSelectedFiles,
  getSortedFilesBasedOnReviewQueryOrder,
  retryReview,
} from 'components/vault/utils/vault-helpers'
import { useVaultStore } from 'components/vault/utils/vault-store'
import {
  getQueryUsageStringWithUnit,
  useVaultUsageStore,
} from 'components/vault/utils/vault-usage-store'

interface VaultAddFilesDialogProps {
  generateNNResponse?: (props: GenerateNNResponseProps) => Promise<void>
}

export const VaultAddFilesDialog = ({
  generateNNResponse,
}: VaultAddFilesDialogProps) => {
  const { projectId, queryId } = useParams()

  const userInfo = useAuthUser()

  const [selectedRows, setSelectedRows] = useVaultFileExplorerStore(
    useShallow((s) => [s.selectedRows, s.setSelectedRows])
  )

  const [
    isAddFilesDialogOpen,
    folderIdToVaultFolder,
    queryIdToState,
    queryIdToReviewState,
    setIsAddFilesDialogOpen,
    setReviewTask,
    markHistoryTaskAsFromStreaming,
  ] = useVaultStore(
    useShallow((s) => [
      s.isAddFilesDialogOpen,
      s.folderIdToVaultFolder,
      s.queryIdToState,
      s.queryIdToReviewState,
      s.setIsAddFilesDialogOpen,
      s.setReviewTask,
      s.markHistoryTaskAsFromStreaming,
    ])
  )

  const [gridApi, historyItem, pendingQueryFileIds, addToPendingQueryFileIds] =
    useVaultQueryDetailStore(
      useShallow((s) => [
        s.gridApi,
        s.historyItem,
        s.pendingQueryFileIds,
        s.addToPendingQueryFileIds,
      ])
    )
  const [setDisplayedRows] = useVaultDataGridFilterStore(
    useShallow((state) => [state.setDisplayedRows])
  )

  const [
    reviewQueryLimitUnitLevel,
    reviewQueryUnit,
    reviewQueryDenominator,
    reviewQueryUsage,
    reviewQueryLimit,
  ] = useVaultUsageStore(
    useShallow((s) => [
      s.reviewQueryLimitUnitLevel,
      s.reviewQueryUnit,
      s.reviewQueryDenominator,
      s.reviewQueryUsage,
      s.reviewQueryLimit,
    ])
  )
  const reviewQueryRemaining = reviewQueryLimit
    ? reviewQueryLimit - reviewQueryUsage
    : null

  const { handleRun } = useRunReview()

  const [isAddingToQuery, setIsAddingToQuery] = useState(false)

  const reviewEvent = historyItem as ReviewHistoryItem | undefined
  const reviewEventColumns = reviewEvent
    ? reviewEvent.columns.filter((column) => !column.isHidden)
    : []
  const existingFileIds = useMemo(() => {
    const fileIds = reviewEvent
      ? reviewEvent.rows.map((row) => row.fileId)
      : queryIdToState[queryId!]?.fileIds ?? []
    const pendingFileIds = pendingQueryFileIds ?? []
    return new Set([...fileIds, ...pendingFileIds])
  }, [reviewEvent, queryId, queryIdToState, pendingQueryFileIds])
  const selectedFiles = useMemo(() => {
    const files = getSelectedFiles(selectedRows, existingFileIds)
    return getSortedFilesBasedOnReviewQueryOrder(files, folderIdToVaultFolder)
  }, [selectedRows, folderIdToVaultFolder, existingFileIds])

  const selectedFilesUsage = useMemo(() => {
    if (reviewQueryLimitUnitLevel === QueryCapRuleUnitLevel.CELL) {
      return reviewEventColumns.length * selectedFiles.length
    } else if (reviewQueryLimitUnitLevel === QueryCapRuleUnitLevel.FILE) {
      return selectedFiles.length
    }
    return 0
  }, [
    reviewEventColumns.length,
    selectedFiles.length,
    reviewQueryLimitUnitLevel,
  ])

  const selectedFilesUsageString =
    selectedFilesUsage > 0
      ? `Uses ${getQueryUsageStringWithUnit(
          selectedFilesUsage,
          reviewQueryUnit,
          reviewQueryDenominator
        )}`
      : ''

  const areSelectedFilesUsageExceedingLimit = reviewQueryRemaining
    ? selectedFilesUsage > reviewQueryRemaining
    : false

  const isAddFilesDisabled =
    areSelectedFilesUsageExceedingLimit ||
    selectedFiles.length === 0 ||
    isAddingToQuery

  const getAddFilesDisabledTooltip = () => {
    if (areSelectedFilesUsageExceedingLimit) {
      return 'Selected files exceed your review query limit'
    } else if (selectedFiles.length === 0) {
      return 'Please select at least one file to add'
    }
    return undefined
  }

  const onSubmitExtraFiles = async () => {
    if (!userInfo.IsVaultV2User && generateNNResponse) {
      setIsAddingToQuery(true)
      await retryReview({
        generateNNResponse,
        setReviewTask,
        markHistoryTaskAsFromStreaming,
        projectId: projectId!,
        queryId: queryId!,
        fileIds: selectedFiles.map((file) => file.id),
        queryIdToState,
        queryIdToReviewState,
        requestType: 'extra_files',
      })
      setIsAddingToQuery(false)
    } else if (gridApi) {
      setIsAddingToQuery(true)
      const newPendingQueryFileIds = selectedFiles.map((file) => file.id)
      addToPendingQueryFileIds(newPendingQueryFileIds)
      addFilesToGrid({
        gridApi,
        setDisplayedRows: setDisplayedRows,
        files: selectedFiles,
        folderIdToVaultFolder,
        isLoading: false,
        gridAction: GridTransactionAction.ADD,
      })
      if (reviewEventColumns.length > 0) {
        // Only run if there are questions in the review event
        await handleRun({
          pendingFileIds: [
            ...(pendingQueryFileIds ?? []),
            ...newPendingQueryFileIds,
          ],
        })
      }
      setIsAddingToQuery(false)
    }
    setIsAddFilesDialogOpen(false)
  }

  return (
    <Dialog open={isAddFilesDialogOpen} onOpenChange={setIsAddFilesDialogOpen}>
      <DialogContent hasContainer={false} innerClassName="flex flex-col p-0">
        <DialogHeader className="px-6 pt-6">
          <DialogTitle>Add Files</DialogTitle>
          <DialogDescription>
            Select files to add to the query
          </DialogDescription>
        </DialogHeader>
        <VaultFileExplorer
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          isAddingFilesToQuery
          existingSelectedFileIds={existingFileIds}
          projectId={projectId}
        />
        <div className="flex items-center justify-between px-6 pb-6">
          <p>{selectedFilesUsageString}</p>
          <div className="flex items-center space-x-2">
            <Button
              variant="outline"
              onClick={() => setIsAddFilesDialogOpen(false)}
              disabled={isAddingToQuery}
            >
              Cancel
            </Button>
            <Button
              onClick={onSubmitExtraFiles}
              disabled={isAddFilesDisabled}
              tooltip={getAddFilesDisabledTooltip()}
              isLoading={isAddingToQuery}
            >
              Add
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}
