import React from 'react'

import { Source } from 'openapi/models/Source'

import { cn } from 'utils/utils'

import Markdown from 'components/common/markdown/markdown'

const SourcePopover = ({
  source,
  onClick,
  hideText = false,
  url,
}: {
  source: Source
  onClick: (e: React.MouseEvent) => void
  hideText?: boolean
  url?: string
}) => {
  const HeaderComp = url ? 'a' : 'button'
  return (
    <div className="max-w-lg">
      <HeaderComp
        className={cn(
          '-m-4 mb-3 block w-[calc(100%+2rem)] border-b p-4 pb-3 text-left no-underline hover:bg-button-secondary',
          { '-mb-3': hideText }
        )}
        onClick={onClick}
        href={url}
        target="_blank"
        rel="noreferrer"
      >
        <p className="not-prose line-clamp-3 font-semibold">
          {source.documentName}
        </p>
      </HeaderComp>
      {!hideText && (
        <Markdown
          content={source.text
            .replaceAll(/\n\s*(\n\s*)+/g, '\n\n')
            .replaceAll('\n\n', '<br /><br />')}
          className="prose-xs hyphens-auto break-words"
        />
      )}
    </div>
  )
}

export default SourcePopover
