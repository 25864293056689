import React from 'react'
import { DateRange } from 'react-day-picker'

import {
  addDays,
  format,
  isToday,
  parse,
  subMilliseconds,
  subMonths,
} from 'date-fns'

import Services from 'services'

import { HARVEY_START_DATE } from 'utils/utils'

import {
  FILTER_CHANGED_EVENT_NAME,
  AnalyticsContextType,
} from 'components/common/analytics/analytics-context'
import { DateRangeRecord } from 'components/filter/types/date-range-record'
import DateRangePicker from 'components/ui/date-range-picker'

export const DefaultDateRange = {
  from: subMonths(new Date(), 1),
  to: new Date(),
}

interface DateRangeFilterProps extends AnalyticsContextType {
  filterKey: string
  value?: string
  setValue: (value: string) => void
  workspaceRetentionPolicyInSeconds?: number
}

const DateRangeFilter = (props: DateRangeFilterProps) => {
  const {
    filterKey,
    value,
    setValue,
    workspaceRetentionPolicyInSeconds,
    trackEvent,
  } = props
  const retentionDate =
    workspaceRetentionPolicyInSeconds && workspaceRetentionPolicyInSeconds > 0
      ? new Date(Date.now() - workspaceRetentionPolicyInSeconds * 1000)
      : undefined
  const selectedDateRange = value
    ? parseStringToDateRange(value)
    : retentionDate && retentionDate >= DefaultDateRange.from
    ? {
        from: retentionDate,
        to: new Date(),
      }
    : DefaultDateRange
  const upperBound = selectedDateRange.from
    ? subMilliseconds(
        addDays(selectedDateRange.to || selectedDateRange.from, 1),
        1
      )
    : undefined

  const render: React.FC = () => {
    if (retentionDate && isToday(retentionDate)) {
      // If the retention policy is set to today, we don't need to show the date range picker
      return null
    }

    return (
      <DateRangePicker
        key={filterKey}
        selectedDateRange={selectedDateRange}
        onSelectDateRange={(dateRange) => {
          Services.HoneyComb.Record({
            metric: 'ui.history_date_range_filter',
            date_range_from: dateRange?.from
              ? convertDateToYYYYMMDD(dateRange.from)
              : undefined,
            date_range_to: dateRange?.to
              ? convertDateToYYYYMMDD(dateRange.to)
              : undefined,
          })
          trackEvent(FILTER_CHANGED_EVENT_NAME, {
            entity_name: 'history table',
            field_changed: 'date range',
            date_range_from: dateRange?.from
              ? convertDateToYYYYMMDD(dateRange.from)
              : undefined,
            date_range_to: dateRange?.to
              ? convertDateToYYYYMMDD(dateRange.to)
              : undefined,
          })
          setValue(convertDateRangeToString(dateRange))
        }}
        disableFutureDates
        disablePastDatesBeforeDate={retentionDate ?? HARVEY_START_DATE}
      />
    )
  }

  const filterLogic = (record: DateRangeRecord) => {
    if (!selectedDateRange.from || !upperBound) {
      return true
    }
    const createdDate = new Date(record.created)
    return createdDate >= selectedDateRange.from && createdDate <= upperBound
  }

  return { filterKey, displayName: 'Date range', render, filterLogic }
}

const parseYYYYMMDDToDate = (dateStr: string): Date => {
  return parse(dateStr, 'yyyyMMdd', new Date())
}

export const parseStringToDateRange = (value: string): DateRange => {
  const parsedValue = JSON.parse(value)
  const parseDate = (dateStr: string) => {
    if (!dateStr) return undefined
    return parseYYYYMMDDToDate(dateStr)
  }
  return {
    from: parsedValue.f ? parseDate(parsedValue.f) : undefined,
    to: parsedValue.t ? parseDate(parsedValue.t) : undefined,
  }
}

const convertDateToYYYYMMDD = (date: Date): string => {
  return format(date, 'yyyyMMdd')
}

const convertDateRangeToString = (dateRange: DateRange | undefined): string => {
  const serialized = {
    f: dateRange?.from ? convertDateToYYYYMMDD(dateRange.from) : undefined,
    t: dateRange?.to ? convertDateToYYYYMMDD(dateRange.to) : undefined,
  }
  return JSON.stringify(serialized)
}

export default DateRangeFilter
