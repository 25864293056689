import React, { useState } from 'react'

import { ChevronDown, ChevronUp } from 'lucide-react'

import Services from 'services'

import FeedbackSubmittedBanner from 'components/common/feedback/feedback-submitted-banner'
import { FeedbackResult } from 'components/common/feedback/feedback-with-comments'
import BasicTransition from 'components/ui/basic-transition'
import { Button } from 'components/ui/button'
import * as Card from 'components/ui/card'
import { Checkbox } from 'components/ui/checkbox'
import { Label } from 'components/ui/label'
import { RadioGroup, RadioGroupItem } from 'components/ui/radio-group'
import { Textarea } from 'components/ui/text-area'

const SENTIMENT_OPTIONS = [
  '1. Not at all',
  '2. Gave me some useful information',
  '3. Gave me a strong start',
  '4. Almost fully answered my question',
  '5. Fully answered my question',
]

interface FeedbackCaselawMeta {
  sentiment: number
  hasInaccurateAnswer: boolean
  hasIncorrectCases: boolean
  hasIncorrectCitations: boolean
  hasOtherIssues: boolean
  comments: string
}

export interface FeedbackCaselawProps {
  queryId: string
  saveFeedback: (result: FeedbackResult) => void
}

export const UsCaseLawFeedback: React.FC<FeedbackCaselawProps> = ({
  queryId,
}) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [error, setError] = useState(false)
  const [meta, setMeta] = useState<FeedbackCaselawMeta>({
    sentiment: -1,
    hasInaccurateAnswer: false,
    hasIncorrectCases: false,
    hasIncorrectCitations: false,
    hasOtherIssues: false,
    comments: '',
  })
  const multiSelectOptions = [
    {
      text: 'Answer was inaccurate or contained hallucinations',
      onClick: () => {
        setMeta({ ...meta, hasInaccurateAnswer: !meta.hasInaccurateAnswer })
      },
    },
    {
      text: 'The reference cases are inaccurate or unhelpful (select this option if the cases provided by the model are unhelpful)',
      onClick: () => {
        setMeta({ ...meta, hasIncorrectCases: !meta.hasIncorrectCases })
      },
    },
    {
      text: 'The citations provided by the model are inaccurate or unhelpful (select this option if the pin citations within the cases provided by the model are unhelpful)',
      onClick: () => {
        setMeta({ ...meta, hasIncorrectCitations: !meta.hasIncorrectCitations })
      },
    },
    {
      text: 'Other issues (format, style, tone, etc.)',
      onClick: () => {
        setMeta({ ...meta, hasOtherIssues: !meta.hasOtherIssues })
      },
    },
  ]
  const handleSubmit = async (): Promise<void> => {
    const { sentiment } = meta
    const { comments, ...feedbackMeta } = meta
    const data = {
      sentiment,
      comments,
      feedbackMeta,
    }

    setIsSubmitted(true)
    await Services.Backend.Post(`feedback/${queryId}`, data, {
      throwOnError: true,
    }).catch(() => setError(true))
  }

  const collapse = () => {
    setIsExpanded((prev) => !prev)
    setMeta({
      sentiment: -1,
      hasInaccurateAnswer: false,
      hasIncorrectCases: false,
      hasIncorrectCitations: false,
      hasOtherIssues: false,
      comments: '',
    })
  }
  return (
    <Card.Card
      className="relative px-4 py-2 transition"
      data-testid="input-panel--tax-feedback-card"
    >
      {!isSubmitted && (
        <>
          <button
            className="flex w-full cursor-pointer items-center justify-between"
            onClick={collapse}
            tabIndex={0}
          >
            <p>How much did this response facilitate your legal research?</p>
            <Button variant="ghost">
              {isExpanded ? (
                <ChevronUp className="inline-block h-4 w-4" />
              ) : (
                <ChevronDown className="inline-block h-4 w-4" />
              )}
            </Button>
          </button>
          <BasicTransition show={isExpanded}>
            <RadioGroup className="mt-2">
              {SENTIMENT_OPTIONS.map((option, index) => (
                <RadioGroupItem
                  key={index}
                  value={(index + 1).toString()}
                  onClick={() => setMeta({ ...meta, sentiment: index + 1 })}
                  id={`uscaselaw-feedback-radio-${index}`}
                  label={option}
                />
              ))}
            </RadioGroup>
            {meta.sentiment && (
              <div className="pt-4">
                <p className="pb-1">
                  What could have improved about the answer?
                </p>
                <div className="grid gap-2 ">
                  {multiSelectOptions.map((option, index) => (
                    <div className="flex h-5 flex-1 space-x-2" key={index}>
                      <Checkbox
                        id={`usecaselaw-feedback-checkbox-${index}`}
                        onClick={option.onClick}
                        label={option.text}
                      />
                    </div>
                  ))}
                </div>
                <div className="py-4">
                  <Label>Please explain why you picked these answers</Label>
                </div>
                <Textarea
                  placeholder="Please explain why you picked these answers"
                  onChange={(e) => {
                    setMeta({ ...meta, comments: e.target.value })
                  }}
                />
              </div>
            )}
            <div className="flex w-full justify-end">
              <Button size="sm" className="mt-2" onClick={handleSubmit}>
                Submit
              </Button>
            </div>
          </BasicTransition>
        </>
      )}
      {isSubmitted && <FeedbackSubmittedBanner error={error} />}
    </Card.Card>
  )
}
