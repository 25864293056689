/* tslint:disable */
/* eslint-disable */
/**
 * Contracts Processing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { UploadedFile } from './UploadedFile';
import {
    UploadedFileFromJSON,
    UploadedFileFromJSONTyped,
    UploadedFileToJSON,
} from './UploadedFile';
import type { ContractsDocumentExtractedTermsValue } from './ContractsDocumentExtractedTermsValue';
import {
    ContractsDocumentExtractedTermsValueFromJSON,
    ContractsDocumentExtractedTermsValueFromJSONTyped,
    ContractsDocumentExtractedTermsValueToJSON,
} from './ContractsDocumentExtractedTermsValue';

/**
 * 
 * @export
 * @interface ContractsDocument
 */
export interface ContractsDocument {
    /**
     * 
     * @type {boolean}
     * @memberof ContractsDocument
     */
    isLoading?: boolean;
    /**
     * 
     * @type {UploadedFile}
     * @memberof ContractsDocument
     */
    file: UploadedFile;
    /**
     * 
     * @type {string}
     * @memberof ContractsDocument
     */
    type?: string;
    /**
     * 
     * @type {{ [key: string]: ContractsDocumentExtractedTermsValue; }}
     * @memberof ContractsDocument
     */
    extractedTerms: { [key: string]: ContractsDocumentExtractedTermsValue; };
    /**
     * Term extraction options for this contract
     * @type {Array<string>}
     * @memberof ContractsDocument
     */
    specializedTerms: Array<string>;
}

/**
 * Check if a given object implements the ContractsDocument interface.
 */
export function instanceOfContractsDocument(value: object): value is ContractsDocument {
    if (!('file' in value) || value['file'] === undefined) return false;
    if (!('extractedTerms' in value) || value['extractedTerms'] === undefined) return false;
    if (!('specializedTerms' in value) || value['specializedTerms'] === undefined) return false;
    return true;
}

export function ContractsDocumentFromJSON(json: any): ContractsDocument {
    return ContractsDocumentFromJSONTyped(json, false);
}

export function ContractsDocumentFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContractsDocument {
    if (json == null) {
        return json;
    }
    return {
        
        'isLoading': json['is_loading'] == null ? undefined : json['is_loading'],
        'file': UploadedFileFromJSON(json['file']),
        'type': json['type'] == null ? undefined : json['type'],
        'extractedTerms': (mapValues(json['extracted_terms'], ContractsDocumentExtractedTermsValueFromJSON)),
        'specializedTerms': json['specialized_terms'],
    };
}

export function ContractsDocumentToJSON(value?: ContractsDocument | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'is_loading': value['isLoading'],
        'file': UploadedFileToJSON(value['file']),
        'type': value['type'],
        'extracted_terms': (mapValues(value['extractedTerms'], ContractsDocumentExtractedTermsValueToJSON)),
        'specialized_terms': value['specializedTerms'],
    };
}

