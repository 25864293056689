import React, { useCallback, useMemo, useRef } from 'react'
import { useMount, useUnmount } from 'react-use'

import { ColDef } from 'ag-grid-community'
import { capitalize } from 'lodash'
import { FilePlus2, SquarePlus } from 'lucide-react'
import { useShallow } from 'zustand/react/shallow'

import { useGeneralStore } from 'stores/general-store'
import { SIDEBAR_CLOSED_WIDTH, SIDEBAR_OPEN_WIDTH } from 'types/ui-constants'

import { cn } from 'utils/utils'

import { useAnalytics } from 'components/common/analytics/analytics-context'
import { useAuthUser } from 'components/common/auth-context'
import { Button } from 'components/ui/button'
import { Icon } from 'components/ui/icon/icon'
import { Input } from 'components/ui/input'
import { Label } from 'components/ui/label'
import {
  Select,
  SelectTrigger,
  SelectContent,
  SelectItem,
  SelectValue,
} from 'components/ui/select'
import { Textarea } from 'components/ui/text-area'
import {
  EXCLUDED_HEADER_NAMES_FROM_ADD_COLUMN,
  QuestionColumnDef,
} from 'components/vault/components/data-grid/vault-query-result-table'
import useSharingPermissions from 'components/vault/hooks/use-sharing-permissions'
import {
  COLUMN_DATA_TYPES_NOT_TO_BE_USED_FOR_QUESTION,
  ColumnDataType,
  GenerateNNResponseProps,
  QueryQuestion,
} from 'components/vault/utils/vault'
import { useVaultDataGridFilterStore } from 'components/vault/utils/vault-data-grid-filters-store'
import { getQuestionsLimit } from 'components/vault/utils/vault-helpers'
import {
  QUERY_TYPES,
  AddColumnPopoverPosition,
  useVaultStore,
} from 'components/vault/utils/vault-store'
import { useVaultUsageStore } from 'components/vault/utils/vault-usage-store'

import DisplayDropdown from './display-dropdown'
import ErrorsPopover from './errors-popover'
import FilterDropdown from './filter-dropdown'

export const ADD_COLUMN_POPOVER_WIDTH = 256

const AddFileButton = ({
  isAddFilesDisabled,
}: {
  isAddFilesDisabled: boolean
}) => {
  const setIsAddFilesDialogOpen = useVaultStore(
    useShallow((state) => state.setIsAddFilesDialogOpen)
  )

  const addFileHandler = () => {
    setIsAddFilesDialogOpen(true)
  }

  return (
    <Button
      onClick={addFileHandler}
      className="flex items-center gap-1"
      variant="outline"
      size="sm"
      disabled={isAddFilesDisabled}
    >
      <Icon icon={FilePlus2} size="small" />
      <p className="truncate text-xs">Add File</p>
    </Button>
  )
}

const AddColumnPopover = () => {
  const gridApi = useVaultStore((state) => state.gridApi)

  const pendingColumnIds = useVaultStore(
    useShallow((state) => state.pendingColumnIds)
  )
  const addColumnPopoverPosition = useVaultStore(
    useShallow((state) => state.addColumnPopoverPosition)
  )

  const setAddColumnPopoverPosition = useVaultStore(
    useShallow((state) => state.setAddColumnPopoverPosition)
  )
  const setPendingColumnIds = useVaultStore(
    useShallow((state) => state.setPendingColumnIds)
  )
  const addQuestionToQuery = useVaultStore(
    useShallow((state) => state.addQuestionToQuery)
  )
  const updateQuestionInQuery = useVaultStore(
    useShallow((state) => state.updateQuestionInQuery)
  )

  const queryId = useVaultStore((state) => state.queryId)
  const queryIdToReviewState = useVaultStore(
    (state) => state.queryIdToReviewState
  )

  const ref = useRef<HTMLDivElement>(null)

  const isAddColumnDisabled =
    !addColumnPopoverPosition?.header ||
    !addColumnPopoverPosition.columnDataType ||
    !addColumnPopoverPosition.question
  const isPendingQuestion =
    pendingColumnIds?.includes(addColumnPopoverPosition?.colId ?? '') ||
    !addColumnPopoverPosition?.colId

  const existingQuestionsLength =
    queryIdToReviewState[queryId]?.questions.length ?? 0

  const cancelHandler = () => {
    setAddColumnPopoverPosition(null)
  }

  const updateAddColumnPopoverPosition = (
    key: keyof AddColumnPopoverPosition,
    value: string
  ) => {
    if (!addColumnPopoverPosition) return
    setAddColumnPopoverPosition({ ...addColumnPopoverPosition, [key]: value })
  }

  const addOrUpdateColumnHandler = () => {
    if (!addColumnPopoverPosition) return

    const questionId =
      addColumnPopoverPosition.colId || `${existingQuestionsLength + 1}`
    const queryQuestion = {
      id: questionId,
      header: addColumnPopoverPosition.header,
      text: addColumnPopoverPosition.question,
      columnDataType: addColumnPopoverPosition.columnDataType,
    }
    if (!addColumnPopoverPosition.colId) {
      setPendingColumnIds([questionId])
      addQuestionToQuery(queryQuestion as QueryQuestion)
    } else if (gridApi) {
      updateQuestionInQuery(queryQuestion as QueryQuestion)
      const columnDefs = gridApi.getColumnDefs() ?? []
      const questionColumnDef = columnDefs.find(
        (columnDef: ColDef) =>
          'colId' in columnDef && columnDef.colId === questionId
      ) as QuestionColumnDef

      questionColumnDef.headerName = queryQuestion.header
      questionColumnDef.originalQuestion = queryQuestion.text
      questionColumnDef.columnDataType =
        queryQuestion.columnDataType as ColumnDataType
      gridApi.setGridOption('columnDefs', columnDefs)

      // TODO: make api request to update question in backend
    }
    setAddColumnPopoverPosition(null)
  }

  if (!addColumnPopoverPosition) return null

  return (
    <div
      ref={ref}
      className="fixed left-24 top-24 z-50 flex h-80 min-h-80 w-64 min-w-64 flex-col gap-4 rounded-sm border bg-primary px-4 py-2 shadow-md"
      style={{
        left: addColumnPopoverPosition.left,
        top: addColumnPopoverPosition.top,
      }}
    >
      <div>
        <Label>Header</Label>
        <Input
          required
          className="h-8"
          placeholder="Signing Date"
          value={addColumnPopoverPosition.header}
          onChange={(e) =>
            updateAddColumnPopoverPosition('header', e.target.value)
          }
        />
      </div>
      <div>
        <Label>Type</Label>
        <Select
          disabled={!isPendingQuestion}
          value={addColumnPopoverPosition.columnDataType}
          onValueChange={(value) =>
            updateAddColumnPopoverPosition(
              'columnDataType',
              value as ColumnDataType
            )
          }
        >
          <SelectTrigger className="h-8 w-full text-nowrap">
            <SelectValue placeholder="Date" />
          </SelectTrigger>
          <SelectContent>
            {Object.values(ColumnDataType)
              .filter(
                (value) =>
                  !COLUMN_DATA_TYPES_NOT_TO_BE_USED_FOR_QUESTION.includes(value)
              )
              .map((value) => (
                <SelectItem key={value} value={value}>
                  {capitalize(value.replace(/_/g, ' '))}
                </SelectItem>
              ))}
          </SelectContent>
        </Select>
      </div>
      <div>
        <Label>Question</Label>
        <Textarea
          required
          className="resize-none"
          placeholder="What is the signing date of this agreement?"
          disabled={!isPendingQuestion}
          value={addColumnPopoverPosition.question}
          onChange={(e) =>
            updateAddColumnPopoverPosition('question', e.target.value)
          }
        />
      </div>
      <div className="flex w-full justify-end gap-2">
        <Button variant="outline" onClick={cancelHandler}>
          Cancel
        </Button>
        <Button
          disabled={isAddColumnDisabled}
          onClick={addOrUpdateColumnHandler}
        >
          {addColumnPopoverPosition.colId ? 'Update Column' : 'Add Column'}
        </Button>
      </div>
    </div>
  )
}

const AddColumnButton = ({
  isAddColumnDisabled,
  fileIds,
}: {
  isAddColumnDisabled: boolean
  fileIds: string[]
}) => {
  const { trackEvent } = useAnalytics()
  const userInfo = useAuthUser()

  const isSidebarOpen = useGeneralStore((state) => state.isSidebarOpen)

  const gridApi = useVaultStore((state) => state.gridApi)
  const queryId = useVaultStore((state) => state.queryId)
  const queryIdToReviewState = useVaultStore(
    (state) => state.queryIdToReviewState
  )
  const setIsTextAreaFocused = useVaultStore(
    (state) => state.setIsTextAreaFocused
  )
  const setQueryType = useVaultStore((state) => state.setQueryType)
  const setPendingQuery = useVaultStore((state) => state.setPendingQuery)
  const setPendingQueryFileIds = useVaultStore(
    (state) => state.setPendingQueryFileIds
  )
  const setAddColumnPopoverPosition = useVaultStore(
    (state) => state.setAddColumnPopoverPosition
  )

  const showAddColumnButton =
    !queryIdToReviewState[queryId]?.isWorkflowRepsWarranties

  const onAddColumn = () => {
    trackEvent('Vault Review Query Add Column Button Clicked', {
      query_id: queryId,
    })
    if (!userInfo.IsVaultV2User) {
      setIsTextAreaFocused(true)
      setQueryType(QUERY_TYPES.NN)
      setPendingQuery('')
      const pendingQueryFileIds = fileIds.length > 0 ? fileIds : null
      setPendingQueryFileIds(pendingQueryFileIds)
    } else {
      const sidebarWidth = isSidebarOpen
        ? SIDEBAR_OPEN_WIDTH
        : SIDEBAR_CLOSED_WIDTH

      const currentGridColumns = gridApi
        ?.getAllDisplayedColumns()
        .filter(
          (column) =>
            !EXCLUDED_HEADER_NAMES_FROM_ADD_COLUMN.includes(column.getColId())
        )
      const currentGridColumnWidths = currentGridColumns?.map((column) =>
        column.getActualWidth()
      )
      // 304 is the width of the row + name column
      const totalWidthOfColumns =
        currentGridColumnWidths?.reduce((acc, width) => acc + width, 0) ?? 304

      // the left position will be:
      // the left position is the right most possible position the popover can be in
      const left = Math.min(
        totalWidthOfColumns + sidebarWidth + 16,
        window.innerWidth - sidebarWidth - ADD_COLUMN_POPOVER_WIDTH
      )

      // the top position is 164
      // 99px for the app header + 49px for the data-grid-header + 16px for an offset
      const top = 164

      if (
        totalWidthOfColumns >
        window.innerWidth - sidebarWidth - ADD_COLUMN_POPOVER_WIDTH
      ) {
        gridApi?.ensureColumnVisible('gutter')
      }

      setAddColumnPopoverPosition({
        left: left,
        top: top,
        header: '',
        columnDataType: undefined,
        question: '',
      })
    }
  }

  if (!showAddColumnButton) return null

  return (
    <>
      <AddColumnPopover />
      <Button
        className="flex items-center gap-1"
        variant="outline"
        size="sm"
        onClick={onAddColumn}
        disabled={isAddColumnDisabled}
      >
        <Icon icon={SquarePlus} size="small" />
        <p className="truncate text-xs">Add Column</p>
      </Button>
    </>
  )
}

const VaultDataGridHeader = ({
  columnIdToHeader,
  columnIdToDataType,
  generateNNResponse,
}: {
  columnIdToHeader: (columnId: string, question: QueryQuestion) => string
  columnIdToDataType: (columnId: string) => ColumnDataType
  generateNNResponse: (props: GenerateNNResponseProps) => Promise<void>
}) => {
  const userInfo = useAuthUser()

  const gridApi = useVaultStore((state) => state.gridApi)
  const currentProject = useVaultStore((state) => state.currentProject)
  const exampleProjectIds = useVaultStore((state) => state.exampleProjectIds)
  const fileIdToVaultFile = useVaultStore((state) => state.fileIdToVaultFile)
  const queryId = useVaultStore((state) => state.queryId)
  const queryIdToState = useVaultStore((state) => state.queryIdToState)
  const queryIdToReviewState = useVaultStore(
    (state) => state.queryIdToReviewState
  )

  const reviewQuestionsPerQueryLimit = useVaultUsageStore(
    (s) => s.reviewQuestionsPerQueryLimit
  )
  const reviewFilesPerQueryLimit = useVaultUsageStore(
    (s) => s.reviewFilesPerQueryLimit
  )

  const { doesCurrentUserHaveEditPermission } = useSharingPermissions({
    projectId: currentProject?.id,
  })

  const agGridHeaderHeight = useVaultDataGridFilterStore(
    (state) => state.agGridHeaderHeight
  )

  const fileIds = useMemo(
    () =>
      queryIdToState[queryId]?.fileIds.filter(
        (id) => !!fileIdToVaultFile[id]
      ) || [],
    [queryIdToState, fileIdToVaultFile, queryId]
  )

  const isQueryLoading = queryIdToState[queryId]?.isLoading || false
  const filesLimit =
    queryIdToReviewState[queryId]?.filesLimit ?? reviewFilesPerQueryLimit
  const questions = queryIdToReviewState[queryId]?.questions || []
  const questionsLimit = getQuestionsLimit(
    queryIdToReviewState[queryId]?.questionsLimit,
    reviewQuestionsPerQueryLimit
  )

  const isExampleProject =
    currentProject && exampleProjectIds.has(currentProject.id)
  const canCurrentUserEditProject =
    !isExampleProject && doesCurrentUserHaveEditPermission

  const isAddFilesDisabled =
    !canCurrentUserEditProject ||
    isQueryLoading ||
    !!(filesLimit && fileIds.length >= filesLimit)

  const isAddColumnDisabled =
    !canCurrentUserEditProject ||
    isQueryLoading ||
    fileIds.length === 0 ||
    questions.length >= questionsLimit

  const setAddColumnPopoverPosition = useVaultStore(
    (state) => state.setAddColumnPopoverPosition
  )

  // filter dropdown
  const setIsShowingLongResponses = useVaultDataGridFilterStore(
    (state) => state.setIsShowingLongResponses
  )
  const resetFilterState = useVaultDataGridFilterStore(
    (state) => state.resetFilterState
  )

  // clean-up selected rows
  const clearSelectedRows = useVaultDataGridFilterStore(
    (state) => state.clearSelectedRows
  )

  const clearFilters = useCallback(() => {
    resetFilterState()
    gridApi?.setFilterModel(null)
  }, [gridApi, resetFilterState])

  useMount(() => {
    if (userInfo.IsVaultV2User) {
      setIsShowingLongResponses(true)
    }
  })

  useUnmount(() => {
    resetFilterState()
    clearSelectedRows()
    setAddColumnPopoverPosition(null)
    if (!userInfo.IsVaultV2User) {
      setIsShowingLongResponses(false)
    }
  })

  // setting the min-h to 49 to align with the pdf-viewer header height
  return (
    <div
      className={cn(
        'flex max-h-[49px] min-h-[49px] w-full shrink-0 items-center justify-between gap-2 px-4 py-2',
        {
          'border-b': agGridHeaderHeight === 0,
        }
      )}
    >
      <div className="flex items-center gap-2">
        {!userInfo.IsVaultV2User && (
          <DisplayDropdown clearFilters={clearFilters} />
        )}
        <FilterDropdown
          clearFilters={clearFilters}
          columnIdToHeader={columnIdToHeader}
          columnIdToDataType={columnIdToDataType}
        />
        <div className="min-h-6 w-px border-r border-primary" />
        <AddFileButton isAddFilesDisabled={isAddFilesDisabled} />
        <AddColumnButton
          isAddColumnDisabled={isAddColumnDisabled}
          fileIds={fileIds}
        />
      </div>
      <ErrorsPopover generateNNResponse={generateNNResponse} />
    </div>
  )
}

export default VaultDataGridHeader
