import React, { useEffect, useState } from 'react'

import { useShallow } from 'zustand/react/shallow'

import { setIntegrationEnablement } from 'models/workspace'
import { IntegrationType } from 'openapi/models/IntegrationType'
import { useIntegrationsStore } from 'stores/integrations-store'
import { Maybe } from 'types'

import { displayErrorMessage, displaySuccessMessage } from 'utils/toast'

import { Button } from 'components/ui/button'
import { Card } from 'components/ui/card'
import { Separator } from 'components/ui/separator'
import { Switch } from 'components/ui/switch'

import { IntegrationDefinition } from './integration-definitions'
import IntegrationDisableConfirmationDialog from './integration-disable-confirmation-dialog'
import IntegrationEnableConfirmationDialog from './integration-enable-confirmation-dialog'
import { useOauthConnect } from './use-oauth-connect'
import { disconnectIntegration } from './utils'

interface IntegrationAdminCardProps {
  integrationType: IntegrationType
  enabled: boolean
  connected: boolean
  workspaceId: number
  integrationDefinition: IntegrationDefinition
}

const IntegrationAdminCard: React.FC<IntegrationAdminCardProps> = ({
  integrationType,
  enabled,
  workspaceId,
  connected,
  integrationDefinition,
}) => {
  const [isEnabled, setIsEnabled] = useState(enabled)
  const [isConnected, setIsConnected] = useState(connected)
  const [isEnableConfirmationOpen, setIsEnableConfirmationOpen] =
    useState(false)
  const [isDisableConfirmationOpen, setIsDisableConfirmationOpen] =
    useState(false)

  useEffect(() => {
    setIsConnected(connected)
  }, [connected])

  const handleEnablement = async (
    enable: boolean,
    resourceUrl?: Maybe<string>
  ) => {
    try {
      await setIntegrationEnablement({
        integration: integrationType,
        enabled: enable,
        workspaceId: workspaceId,
        resourceUrl: resourceUrl,
      })
    } catch (e) {
      console.error(e)
      displayErrorMessage(
        `Failed to ${enable ? 'disable' : 'enable'} ${
          integrationDefinition.title
        }`
      )
      return
    }
    displaySuccessMessage(
      `${integrationDefinition.title} ${
        enable ? 'enabled' : 'disabled'
      } for your workspace`
    )
    setIsEnabled(enable)
  }

  const handleToggle = () => {
    if (isEnabled) {
      setIsDisableConfirmationOpen(true)
    } else {
      setIsEnableConfirmationOpen(true)
    }
  }

  const {
    isConnecting,
    isConnected: isOauthConnected,
    handleConnect,
  } = useOauthConnect({ integrationType })

  const [clearIntegrationToken] = useIntegrationsStore(
    useShallow((state) => [state.clearIntegrationToken])
  )

  const handleDisconnect = async () => {
    try {
      await disconnectIntegration(integrationType)
      clearIntegrationToken(integrationType)
    } catch (e) {
      console.error(e)
      displayErrorMessage(
        `Failed to disconnect from ${integrationDefinition.title}`
      )
      return
    }
    displaySuccessMessage(`Disconnected from ${integrationDefinition.title}`)
    setIsConnected(false)
  }

  return (
    <>
      <Card className="mt-4 ">
        <div className="flex items-center justify-between p-3 ">
          <div className="ml-4 flex items-center space-x-4">
            <integrationDefinition.Logo className="size-8" />
            <div>
              <p className="font-semibold">{integrationDefinition.title}</p>
              <p>{integrationDefinition.description}</p>
            </div>
          </div>
          <div className="pr-3">
            <Switch checked={isEnabled} onCheckedChange={handleToggle} />
          </div>
        </div>
        {isEnabled && (
          <>
            <Separator />
            <div className="flex items-center justify-between px-3 py-4">
              <p className="pl-5">Link to your work account</p>
              {isConnecting ? (
                <Button variant="outline" className="mr-3" disabled>
                  Loading...
                </Button>
              ) : isConnected || isOauthConnected ? (
                <Button
                  variant="destructive"
                  className="mr-3"
                  onClick={handleDisconnect}
                >
                  Disconnect
                </Button>
              ) : (
                <Button
                  variant="outline"
                  className="mr-3"
                  onClick={handleConnect}
                >
                  Connect
                </Button>
              )}
            </div>
          </>
        )}
      </Card>
      <IntegrationEnableConfirmationDialog
        open={isEnableConfirmationOpen}
        onOpenChange={setIsEnableConfirmationOpen}
        onConfirm={handleEnablement}
        integrationDefinition={integrationDefinition}
      />
      <IntegrationDisableConfirmationDialog
        open={isDisableConfirmationOpen}
        onOpenChange={setIsDisableConfirmationOpen}
        onConfirm={handleEnablement}
        title={integrationDefinition.title}
      />
    </>
  )
}

export default IntegrationAdminCard
