import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'

import { SharePermissionByUser } from 'openapi/models/SharePermissionByUser'
import { SharePermissionByWorkspace } from 'openapi/models/SharePermissionByWorkspace'
import { SharingWorkspaceUsersApiResponseDataUsersInner } from 'openapi/models/SharingWorkspaceUsersApiResponseDataUsersInner'
import { PermissionLevel, SharePopoverScreens } from 'types/sharing'

interface ShareStatus {
  permissionsByWorkspace: Array<SharePermissionByWorkspace>
  permissionsByUser: Array<SharePermissionByUser>
}

interface SharingState {
  currentScreen: SharePopoverScreens
  sharingUsersForWorkspace: Array<SharingWorkspaceUsersApiResponseDataUsersInner>
  didFetchSharingUsersForWorkspaceFail: boolean
  didFetchSharingStatusFail: boolean
  isSharing: boolean
  sharingStatus: ShareStatus | null
  availablePermissions: Array<PermissionLevel>
}

interface SharingAction {
  reset: () => void
  setCurrentScreen: (screen: SharePopoverScreens) => void
  setSharingUsersForWorkspace: (
    users: Array<SharingWorkspaceUsersApiResponseDataUsersInner>
  ) => void
  setDidFetchSharingUsersForWorkspaceFail: (didFail: boolean) => void
  setDidFetchSharingStatusFail: (didFail: boolean) => void
  setIsSharing: (isSharing: boolean) => void
  setSharingStatus: (status: ShareStatus | null) => void
  setAvailablePermissions: (permissions: Array<PermissionLevel>) => void
}

const initialState: SharingState = {
  currentScreen: SharePopoverScreens.SHARE,
  sharingUsersForWorkspace: [],
  isSharing: false,
  didFetchSharingUsersForWorkspaceFail: false,
  didFetchSharingStatusFail: false,
  availablePermissions: Object.values(PermissionLevel),
  sharingStatus: null,
}

export const useSharingStore = create(
  devtools(
    immer<SharingState & SharingAction>((set) => ({
      ...initialState,
      reset: () => set(initialState),
      setCurrentScreen: (screen) => set({ currentScreen: screen }),
      setSharingUsersForWorkspace: (users) =>
        set({
          sharingUsersForWorkspace: users.sort((a, b) =>
            a.userEmail.localeCompare(b.userEmail)
          ),
        }),
      setDidFetchSharingStatusFail: (didFail) =>
        set({ didFetchSharingStatusFail: didFail }),
      setDidFetchSharingUsersForWorkspaceFail: (didFail) =>
        set({ didFetchSharingUsersForWorkspaceFail: didFail }),
      setIsSharing: (isSharing) => set({ isSharing }),
      setSharingStatus: (status) => set({ sharingStatus: status }),
      setAvailablePermissions: (permissions) =>
        set({ availablePermissions: permissions }),
    }))
  )
)
