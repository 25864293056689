import React from 'react'

import { ChevronDown, ChevronRight, ChevronUp } from 'lucide-react'

import { cn } from 'utils/utils'

import BasicTransition from './basic-transition'
import { Button, ButtonVariantProps } from './button'
import Icon from './icon/icon'

interface CollapsibleProps extends ButtonVariantProps {
  title: React.ReactNode
  children: React.ReactNode
  addPrefix?: boolean
  defaultCollapsed?: boolean
  onToggle?: (collapsed: boolean) => void
  togglePosition?: 'top' | 'bottom'
  className?: string
}

const Collapsible: React.FC<CollapsibleProps> = ({
  title,
  children,
  addPrefix,
  defaultCollapsed,
  onToggle,
  togglePosition = 'top',
  className,
  variant,
}) => {
  const [collapsed, setCollapsed] = React.useState(defaultCollapsed ?? false)

  const toggle = (
    <Button
      variant={variant}
      onClick={() => {
        setCollapsed(!collapsed)
        onToggle?.(!collapsed)
      }}
      className={cn('flex w-full items-center justify-start px-0', className)}
    >
      <Icon
        icon={
          collapsed
            ? ChevronRight
            : togglePosition === 'top'
            ? ChevronDown
            : ChevronUp
        }
        className="mr-2"
      />
      {addPrefix && `${collapsed ? 'View' : 'Hide'} `}
      {title}
    </Button>
  )

  return (
    <div>
      {togglePosition === 'top' && toggle}
      <BasicTransition show={!collapsed}>{children}</BasicTransition>
      {togglePosition === 'bottom' && toggle}
    </div>
  )
}

export { Collapsible }
