import {
  Building2,
  FileDiff,
  FileText,
  Languages,
  PencilRuler,
  Table2,
  ScrollText,
  LayoutList,
  ScanSearch,
} from 'lucide-react'

import { UserInfo } from 'models/user-info'
import { WorkflowDetails, WorkflowType } from 'types/workflows'

import { WorkflowTypeToWorkflowConfig } from './workflow-config'
import CompanyProfileWorkflow from './workflow/company-profile/company-profile-page'
import { getCompetitiveAnalysisPageRoutes } from './workflow/competitive-analysis/pages'
import { getContractsPageRoutes } from './workflow/contracts/pages'
import DiligenceTranscriptsWorkflow from './workflow/diligence-transcripts/diligence-transcripts-page'
import { getDiligencePageRoutes } from './workflow/discovery/pages'
import DocumentComparisonWorkflow from './workflow/document-comparison/document-comparison-page'
import LegacyDraftingWorkflow from './workflow/legacy_drafting/legacy-drafting-page'
import OgcWorkflow from './workflow/ogc/ogc-page'
import RedlinesWorkflow from './workflow/redlines/redlines-page'
import { getTranscriptsPageRoutes } from './workflow/transcripts/pages'
import TranslationWorkflow from './workflow/translation/translation-page'

// If you want a workflow w/ unique routing, add the route definitions here
export const getWorkflowSpecificRouting = (userInfo: UserInfo) => {
  return [
    ...getTranscriptsPageRoutes(userInfo),
    ...getContractsPageRoutes(userInfo),
    ...getDiligencePageRoutes(userInfo),
    ...getCompetitiveAnalysisPageRoutes(userInfo),
  ]
}

export const WorkflowTypeToDetails: Record<WorkflowType, WorkflowDetails> = {
  [WorkflowType.COMPANY_PROFILE]: {
    ...WorkflowTypeToWorkflowConfig[WorkflowType.COMPANY_PROFILE],
    icon: Building2,
    component: () => CompanyProfileWorkflow,
  },
  [WorkflowType.REDLINE_ANALYSIS]: {
    ...WorkflowTypeToWorkflowConfig[WorkflowType.REDLINE_ANALYSIS],
    icon: Table2,
    component: () => RedlinesWorkflow,
  },
  [WorkflowType.TRANSLATION]: {
    ...WorkflowTypeToWorkflowConfig[WorkflowType.TRANSLATION],
    icon: Languages,
    component: () => TranslationWorkflow,
  },
  [WorkflowType.OGC]: {
    ...WorkflowTypeToWorkflowConfig[WorkflowType.OGC],
    icon: FileText,
    component: () => OgcWorkflow,
  },
  [WorkflowType.LEGACY_DRAFTING]: {
    ...WorkflowTypeToWorkflowConfig[WorkflowType.LEGACY_DRAFTING],
    icon: PencilRuler,
    component: () => LegacyDraftingWorkflow,
  },
  [WorkflowType.DOCUMENT_COMPARISON]: {
    ...WorkflowTypeToWorkflowConfig[WorkflowType.DOCUMENT_COMPARISON],
    icon: FileDiff,
    component: () => DocumentComparisonWorkflow,
  },
  [WorkflowType.CONTRACTS]: {
    ...WorkflowTypeToWorkflowConfig[WorkflowType.CONTRACTS],
    icon: LayoutList,
    component: null,
  },
  [WorkflowType.TRANSCRIPTS]: {
    ...WorkflowTypeToWorkflowConfig[WorkflowType.TRANSCRIPTS],
    icon: ScrollText,
    component: null,
  },
  [WorkflowType.DILIGENCE]: {
    ...WorkflowTypeToWorkflowConfig[WorkflowType.DILIGENCE],
    icon: ScanSearch,
    component: null,
  },
  [WorkflowType.COMPETITIVE_ANALYSIS]: {
    ...WorkflowTypeToWorkflowConfig[WorkflowType.COMPETITIVE_ANALYSIS],
    icon: ScanSearch,
    component: null,
  },
  [WorkflowType.DILIGENCE_TRANSCRIPTS]: {
    ...WorkflowTypeToWorkflowConfig[WorkflowType.DILIGENCE_TRANSCRIPTS],
    icon: ScrollText,
    component: () => DiligenceTranscriptsWorkflow,
  },
}
