import * as React from 'react'

import * as TabsPrimitive from '@radix-ui/react-tabs'

import { cn } from 'utils/utils'

import { Tooltip, TooltipContent, TooltipTrigger } from './tooltip'

const Tabs = TabsPrimitive.Root

interface TabsListProps
  extends React.ComponentPropsWithoutRef<typeof TabsPrimitive.List> {
  variant?: 'default' | 'minimal'
}

const TabsList = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.List>,
  TabsListProps
>(({ className, variant = 'default', ...props }, ref) => {
  return (
    <TabsPrimitive.List
      ref={ref}
      className={cn(
        'inline-flex h-10 items-center justify-center rounded-md bg-button-secondary p-1 text-muted',
        {
          'h-auto justify-start space-x-3 rounded-none border-b bg-transparent p-0':
            variant === 'minimal',
        },
        className
      )}
      {...props}
    />
  )
})
TabsList.displayName = TabsPrimitive.List.displayName

interface TabsTriggerProps
  extends React.ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger> {
  tooltip?: string
  variant?: 'default' | 'minimal'
}

const TabsTrigger = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Trigger>,
  TabsTriggerProps
>(({ className, variant = 'default', tooltip, ...props }, ref) => {
  const tabTrigger = (
    <TabsPrimitive.Trigger
      ref={ref}
      className={cn(
        'inline-flex items-center justify-center whitespace-nowrap py-1.5 text-sm ring-offset-primary transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 data-[state=active]:bg-primary data-[state=active]:text-primary',
        {
          'rounded-sm px-3 data-[state=active]:shadow-sm':
            variant === 'default',
          'data-[state=active]:bg-transparent data-[state=active]:shadow-[inset_0_-1px_0_0_hsl(var(--text-primary)),0_1px_0_0_hsl(var(--text-primary))]':
            variant === 'minimal',
        },
        className
      )}
      {...props}
    />
  )
  if (tooltip) {
    return (
      <Tooltip disableHoverableContent>
        <TooltipTrigger asChild>
          {/* Wrap in span w/ asChild use above so we don't have nested <button> triggers */}
          <span className="leading-none">{tabTrigger}</span>
        </TooltipTrigger>
        <TooltipContent>{tooltip}</TooltipContent>
      </Tooltip>
    )
  }
  return tabTrigger
})
TabsTrigger.displayName = TabsPrimitive.Trigger.displayName

const TabsContent = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Content>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.Content
    ref={ref}
    className={cn(
      'mt-2 ring-offset-primary focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2',
      className
    )}
    {...props}
  />
))
TabsContent.displayName = TabsPrimitive.Content.displayName

export { Tabs, TabsList, TabsTrigger, TabsContent }
