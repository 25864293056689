import React from 'react'

import _ from 'lodash'

import { DestinationLanguageCode } from 'openapi/models/DestinationLanguageCode'
import { TaskType } from 'types/task'

import { getHrvyInfoMetadata } from 'utils/source'
import { cn } from 'utils/utils'

import Feedback, {
  FeedbackResult,
} from 'components/common/feedback/feedback-with-comments'
import LoadingBar from 'components/common/loading-bar'
import Markdown from 'components/common/markdown/markdown'
import {
  isEmptyState,
  isResponseStarted,
} from 'components/research/research-helpers'
import { Button } from 'components/ui/button'
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from 'components/ui/card'
import Skeleton from 'components/ui/skeleton'
import { Spinner } from 'components/ui/spinner'
import { TRANSLATION_OPTIONS } from 'components/workflows/workflow/translation/translation-utils'

type Props = {
  className?: string
  markdown: string
  isLoading?: boolean
  progress?: number
  title?: string
  description?: string
  headerText?: string // The loading text streamed during the response
  handleCancel?: () => void
  emptyStateText?: string
  getHrvyInfoMetadata?: getHrvyInfoMetadata
  expectImageDataUri?: boolean
  footerChildren?: React.ReactNode
  queryId?: string
  handleSaveFeedback?: (result: FeedbackResult) => void
  taskType?: TaskType
  skeleton?: 'long' | 'short'
  answerContainerRef?: React.RefObject<HTMLDivElement>
  answerWidth?: string
  hideSpinner?: boolean
  language?: string
}

enum ResponseContent {
  EMPTY = 'empty',
  LOADING = 'loading',
  MARKDOWN = 'markdown',
}

const Response = ({
  className,
  markdown,
  isLoading,
  progress = 0,
  title,
  description,
  headerText = '',
  handleCancel,
  emptyStateText = '',
  getHrvyInfoMetadata,
  footerChildren,
  queryId,
  handleSaveFeedback,
  taskType,
  skeleton = 'long',
  expectImageDataUri,
  answerWidth,
  answerContainerRef,
  hideSpinner,
  language,
}: Props) => {
  const isRTL =
    !!TRANSLATION_OPTIONS[language as DestinationLanguageCode]?.isRtl
  const dir = isRTL ? 'rtl' : 'ltr'

  const content: ResponseContent =
    isEmptyState(markdown, headerText) || (!isLoading && _.isEmpty(markdown))
      ? ResponseContent.EMPTY
      : _.isEmpty(markdown) && isResponseStarted(headerText)
      ? ResponseContent.LOADING
      : ResponseContent.MARKDOWN

  const hasHeaderContent = isLoading || title || description

  return (
    <Card className={className} data-testid="response--progress-card">
      <CardHeader
        className={cn('space-y-0', {
          'py-2': !hasHeaderContent,
        })}
      >
        {title && <CardTitle>{title}</CardTitle>}
        {isLoading && (
          <div
            data-testid="response-loader"
            className={cn('space-y-1.5', {
              'pt-1.5': title,
            })}
          >
            <LoadingBar show progress={progress} />
            <div className="flex items-center">
              {!hideSpinner && <Spinner className="h-3 w-3" />}
              <p className="grow text-xs">
                {headerText.trim() !== ''
                  ? `${headerText.trim()}…`
                  : 'Loading…'}
              </p>
              {handleCancel && (
                <Button
                  variant="secondary"
                  size="sm"
                  onClick={handleCancel}
                  data-testid="cancel-query-button"
                >
                  Cancel query
                </Button>
              )}
            </div>
          </div>
        )}
        {!isEmptyState(markdown, headerText) && description && (
          <CardDescription>
            <Markdown content={description} className="mt-1 line-clamp-1" />
          </CardDescription>
        )}
      </CardHeader>
      {queryId && handleSaveFeedback && (
        <Feedback
          queryId={queryId}
          saveFeedback={handleSaveFeedback}
          className="mb-4"
          taskType={taskType}
        />
      )}
      <CardContent ref={answerContainerRef}>
        {content === ResponseContent.EMPTY && (
          <div data-testid="response-help-text-container">
            <Markdown content={emptyStateText} />
          </div>
        )}
        {content === ResponseContent.LOADING && (
          <div className="relative space-y-4">
            {skeleton === 'short' ? (
              <Skeleton rowHeight="h-4" rows={4} />
            ) : (
              <Skeleton rows={16} rowHeight="h-6" />
            )}
          </div>
        )}
        {content === ResponseContent.MARKDOWN && (
          <div data-testid="answer-body" lang={language} dir={dir}>
            <Markdown
              content={markdown}
              getHrvyInfoMetadata={getHrvyInfoMetadata}
              expectImageDataUri={expectImageDataUri}
              width={answerWidth}
            />
            {footerChildren}
          </div>
        )}
      </CardContent>
    </Card>
  )
}

export default Response
