import { useEffect, useCallback, useMemo } from 'react'
import { useUnmount, useInterval } from 'react-use'

import {
  WebPubSubClient,
  ServerDataMessage,
  GroupDataMessage,
  OnServerDataMessageArgs,
  OnGroupDataMessageArgs,
} from '@azure/web-pubsub-client'
import { useShallow } from 'zustand/react/shallow'

import { NegotiateApiResponseData } from 'openapi/models/NegotiateApiResponseData'
import { ServerMessage } from 'openapi/models/ServerMessage'
import { ServerMessageScope } from 'openapi/models/ServerMessageScope'
import { VaultServerMessage } from 'openapi/models/VaultServerMessage'
import Services from 'services'
import { useGeneralStore } from 'stores/general-store'

import { ToFrontendKeys } from 'utils/utils'

import { useAuthUser } from 'components/common/auth-context'

// 23 hours + 55 minutes
const AZURE_WEB_PUBSUB_REFRESH_INTERVAL = 23 * 60 * 60 * 1000 + 55 * 60 * 1000

const FetchAzureWebPubSubToken = async () => {
  const requestPath = 'negotiate'
  try {
    const clientAccessTokenResponse =
      await Services.Backend.Post<NegotiateApiResponseData>(requestPath, {
        throwOnError: true,
      })

    const clientAccessUrl = clientAccessTokenResponse.url
    return clientAccessUrl
  } catch (error) {
    console.error('Error fetching Azure Web PubSub token', error)
    return null
  }
}

const useAzureWebPubSub = () => {
  const userInfo = useAuthUser()
  const isVaultInternalOnlyUser = userInfo.IsVaultInternalOnlyUser

  const azureWebPubSubClient = useGeneralStore(
    useShallow((state) => state.azureWebPubSubClient)
  )

  const setAzureWebPubSubClient = useGeneralStore(
    useShallow((state) => state.setAzureWebPubSubClient)
  )

  const stopAzureWebPubSubClient = useGeneralStore(
    useShallow((state) => state.stopAzureWebPubSubClient)
  )

  const onVaultServerMessage = useCallback(
    async (m: VaultServerMessage) => {
      const message = ToFrontendKeys(m)
      console.info('onVaultServerMessage', message)

      const callerId = message.callerId
      if (callerId === userInfo.dbId) return
    },
    [userInfo.dbId]
  )

  const SCOPE_HANDLER_LOOKUP_TABLE = useMemo(() => {
    return {
      [ServerMessageScope.VAULT]: onVaultServerMessage,
    }
  }, [onVaultServerMessage])

  const setupAzureWebPubSubClient = useCallback(async () => {
    if (!isVaultInternalOnlyUser) return

    // 1. fetch token
    const clientAccessUrl = await FetchAzureWebPubSubToken()
    if (!clientAccessUrl) return

    // 2. create client
    const newClient = new WebPubSubClient(clientAccessUrl, {
      autoReconnect: false,
    })

    // 3. start client
    await newClient.start()

    // 4. subscribe to messages
    newClient.on('connected', () => {
      setAzureWebPubSubClient(newClient)
    })

    // 5. subscribe to messages
    // server-message is a message that is sent to the client with send_to_user
    newClient.on('server-message', (e: OnServerDataMessageArgs) => {
      const message = e.message as ServerDataMessage
      const messageData = message.data as ServerMessage
      const scope = messageData.scope as ServerMessageScope

      const handlerKey = scope as keyof typeof SCOPE_HANDLER_LOOKUP_TABLE
      const handler = SCOPE_HANDLER_LOOKUP_TABLE[handlerKey]
      // eslint-disable-next-line
      if (handlerKey === ServerMessageScope.VAULT) {
        void handler(messageData as VaultServerMessage)
      }
    })

    // 6. subscribe to group messages
    // group-message is a message that is sent to a group of users
    // currently the only group a user is subscribed to is the workspace they are in
    newClient.on('group-message', (e: OnGroupDataMessageArgs) => {
      const message = e.message as GroupDataMessage
      const messageData = message.data as ServerMessage
      const scope = messageData.scope as ServerMessageScope

      const handlerKey = scope as keyof typeof SCOPE_HANDLER_LOOKUP_TABLE
      const handler = SCOPE_HANDLER_LOOKUP_TABLE[handlerKey]
      // eslint-disable-next-line
      if (handlerKey === ServerMessageScope.VAULT) {
        void handler(messageData as VaultServerMessage)
      }
    })

    // 7. subscribe on disconnect
    newClient.on('disconnected', () => {
      console.info('Azure Web PubSub Client disconnected')
      setAzureWebPubSubClient(null)
    })

    // 8. subscribe to stop
    newClient.on('stopped', () => {
      console.info('Azure Web PubSub Client stopped')
      setAzureWebPubSubClient(null)
    })

    // 9. subscribe to rejoin group failures
    newClient.on('rejoin-group-failed', () => {
      console.info('Azure Web PubSub Client rejoin group failed')
      setAzureWebPubSubClient(null)
    })
  }, [
    isVaultInternalOnlyUser,
    SCOPE_HANDLER_LOOKUP_TABLE,
    setAzureWebPubSubClient,
  ])

  useEffect(() => {
    if (!azureWebPubSubClient) {
      setupAzureWebPubSubClient().catch((error) => {
        console.error('Error setting up Azure Web PubSub Client:', error)
      })
    }
  }, [azureWebPubSubClient, setupAzureWebPubSubClient])

  useUnmount(() => {
    stopAzureWebPubSubClient()
  })

  // 10. refresh access token and setup a new connection every 55 minutes
  // Using this interval to establish a new connection before the access token expires
  // This is to ensure that the client is always connected and to avoid disruptions
  // Unfortunately the access token expiring does not trigger a disconnect event on the client
  useInterval(
    async () => {
      // only stoping the client because it will get set to null and recreated in the useEffect above
      stopAzureWebPubSubClient()
    },
    isVaultInternalOnlyUser ? AZURE_WEB_PUBSUB_REFRESH_INTERVAL : null
  )
}

export default useAzureWebPubSub
