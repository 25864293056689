import React from 'react'

import _ from 'lodash'
import { RotateCcw } from 'lucide-react'

import { LibrarySaveExample } from 'components/library/library-save-example'
import { Button } from 'components/ui/button'

interface AppHeaderActionsProps {
  handleReset?: () => void
  resetDisabled?: boolean
  showReset?: boolean
  saveExample?: {
    params: {
      eventId: string
    }
    disabled: boolean
    show: boolean
  }
}

const AppHeaderActions = ({
  handleReset = _.noop,
  resetDisabled = true,
  showReset = true,
  saveExample,
}: AppHeaderActionsProps) => {
  return (
    <div className="flex space-x-2">
      {saveExample?.show && (
        <LibrarySaveExample
          saveExampleDisabled={saveExample.disabled}
          {...saveExample.params}
        />
      )}
      {showReset && (
        <Button
          variant="outline"
          onClick={handleReset}
          disabled={resetDisabled}
          id="app-header--reset"
        >
          <RotateCcw className="mr-1 h-4 w-4" />
          Reset
        </Button>
      )}
    </div>
  )
}

export { AppHeaderActions }
