import { CreateEvaluationQuestionsRequest } from 'openapi/models/CreateEvaluationQuestionsRequest'
import { EvaluationQuestion } from 'openapi/models/EvaluationQuestion'
import { EvaluationQuestionInput } from 'openapi/models/EvaluationQuestionInput'
import { Experiment } from 'openapi/models/Experiment'
import { ExperimentMetadata } from 'openapi/models/ExperimentMetadata'
import { ModelResponse } from 'openapi/models/ModelResponse'
import { UpdateUsersRequest } from 'openapi/models/UpdateUsersRequest'
import { User } from 'openapi/models/User'
import { UserEvaluation } from 'openapi/models/UserEvaluation'
import { UserEvaluationEnriched } from 'openapi/models/UserEvaluationEnriched'
import { UserEvaluationResponse } from 'openapi/models/UserEvaluationResponse'
import Services from 'services'

import { ToBackendKeys } from 'utils/utils'

import { UserEvaluationResponseInput } from './experiment-utils'

interface CreateExperimentParams {
  experimentName: string
  experimentSlug: string
  experimentMetadata: ExperimentMetadata
  csvFile: File
}

// TODO add better error handling in case these backend calls return errors

const CreateExperiment = async ({
  experimentName,
  experimentSlug,
  experimentMetadata,
  csvFile,
}: CreateExperimentParams): Promise<Experiment> => {
  const formData = new FormData()
  formData.append('name', experimentName)
  formData.append('slug', experimentSlug)
  // ToBackendKeys doesn't run automatically for FormData payloads
  formData.append('meta', JSON.stringify(ToBackendKeys(experimentMetadata)))
  formData.append('file.csv', csvFile)

  const response = await Services.Backend.Post<Experiment>(
    'eval/experiment',
    formData,
    { throwOnError: true }
  )
  return response
}

const CreateEvaluationQuestions = async (
  evaluationQuestionInputs: EvaluationQuestionInput[]
): Promise<EvaluationQuestion[]> => {
  const request: CreateEvaluationQuestionsRequest = {
    evaluationQuestionInputs,
  }
  const response = await Services.Backend.Post<EvaluationQuestion[]>(
    'eval/evaluation_questions',
    request,
    { throwOnError: true }
  )
  return response
}

const DeleteEvaluationQuestion = async (questionId: string): Promise<void> => {
  await Services.Backend.Delete(`eval/evaluation_question/${questionId}`, {
    throwOnError: true,
  })
}

// NOTE: in the future we can add pagination if data size is very high
const FetchEvaluationQuestions = async (
  includeDeleted: boolean = false // we want to include deleted for existing experiments, but not for new ones
): Promise<EvaluationQuestion[]> => {
  const response = await Services.Backend.Get<EvaluationQuestion[]>(
    `eval/evaluation_questions?include_deleted=${includeDeleted}`,
    { throwOnError: true }
  )
  return response
}

const AddUsersToExperiment = async (
  experimentId: string,
  userEmails: string[]
): Promise<string[]> => {
  // returns list of emails added

  const request: UpdateUsersRequest = {
    userEmails,
  }

  const response = await Services.Backend.Post<string[]>(
    `eval/experiment/${experimentId}/users`,
    request,
    { throwOnError: true }
  )

  return response
}

const RemoveUsersFromExperiment = async (
  experimentId: string,
  userEmails: string[]
): Promise<string[]> => {
  // returns list of emails removed

  const request: UpdateUsersRequest = {
    userEmails,
  }

  const response = await Services.Backend.Delete<string[]>(
    `eval/experiment/${experimentId}/users`,
    request,
    { throwOnError: true }
  )

  return response
}

const FetchUsersInExperiment = async (
  experimentId: string
): Promise<User[]> => {
  const response = await Services.Backend.Get<User[]>(
    `eval/experiment/${experimentId}/users`,
    { throwOnError: true }
  )
  return response
}

// USER EVALUATIONS

// user evaluations should already be orderd based on evaluation order rank for given experiment
const FetchUserEvaluations = async (
  experimentId: string
): Promise<UserEvaluationEnriched[]> => {
  const response = await Services.Backend.Get<UserEvaluationEnriched[]>(
    `eval/experiment/${experimentId}/user_evaluations`,
    { throwOnError: true }
  )
  return response
}

const FetchModelResponses = async (
  evaluationId: string
): Promise<ModelResponse[]> => {
  const response = await Services.Backend.Get<ModelResponse[]>(
    `eval/evaluation/${evaluationId}/model_responses`,
    { throwOnError: true }
  )
  return response
}

const FetchUserEvaluationResponses = async (
  userEvaluationId: string
): Promise<UserEvaluationResponse[]> => {
  const response = await Services.Backend.Get<UserEvaluationResponse[]>(
    `eval/user_evaluation/${userEvaluationId}/responses`,
    { throwOnError: true }
  )
  return response
}

const FetchExperimentBySlug = async (
  experimentSlug: string
): Promise<Experiment> => {
  const response = await Services.Backend.Get<Experiment>(
    `eval/experiment/slug/${experimentSlug}`,
    { throwOnError: true }
  )
  return response
}

const RecordUserEvaluationResponses = async (
  userEvaluationId: string,
  userEvaluationResponseInputs: UserEvaluationResponseInput[]
): Promise<UserEvaluationEnriched> => {
  const request = {
    evaluationResponses: userEvaluationResponseInputs,
  }
  const response = await Services.Backend.Post<UserEvaluationEnriched>(
    `eval/user_evaluation/${userEvaluationId}/responses`,
    request,
    { throwOnError: true }
  )
  return response
}

// IN-APP ANALYTICS

const FetchUserEvaluationResponsesByExperimentId = async (
  experimentId: string
): Promise<UserEvaluationResponse[]> => {
  const response = await Services.Backend.Get<UserEvaluationResponse[]>(
    `eval/experiment/${experimentId}/user_evaluation_responses`,
    { throwOnError: true }
  )
  return response
}

const FetchModelResponsesByExperimentId = async (
  experimentId: string
): Promise<ModelResponse[]> => {
  const response = await Services.Backend.Get<ModelResponse[]>(
    `eval/experiment/${experimentId}/model_responses`,
    { throwOnError: true }
  )
  return response
}

const FetchExperimentResultsExport = async (
  experimentId: string
): Promise<string> => {
  const response = await Services.Backend.Get<string>(
    `eval/experiment/${experimentId}/export`,
    { throwOnError: true }
  )

  return response
}

const FetchAllUserEvaluations = async (
  experimentId: string,
  userEmail?: string // optional filter
): Promise<UserEvaluation[]> => {
  let url = `eval/experiment/${experimentId}/user_evaluations/all`
  if (userEmail) {
    url += `?email=${encodeURIComponent(userEmail)}`
  }
  const response = await Services.Backend.Get<UserEvaluation[]>(url, {
    throwOnError: true,
  })
  return response
}

// User management

const FetchExperimentsForUser = async (
  userEmail: string
): Promise<Experiment[]> => {
  const response = await Services.Backend.Get<Experiment[]>(
    `eval/user/experiments?email=${encodeURIComponent(userEmail)}`,
    { throwOnError: true }
  )
  return response
}

// Experiments overview

const FetchAllExperiments = async (): Promise<Experiment[]> => {
  const response = await Services.Backend.Get<Experiment[]>(
    'eval/experiments/all',
    { throwOnError: true }
  )
  return response
}

const DeleteExperiment = async (experimentId: string): Promise<void> => {
  await Services.Backend.Delete(`eval/experiment/${experimentId}`, {
    throwOnError: true,
  })
}

export {
  CreateExperiment,
  CreateEvaluationQuestions,
  DeleteEvaluationQuestion,
  AddUsersToExperiment,
  RemoveUsersFromExperiment,
  FetchUsersInExperiment,
  FetchEvaluationQuestions,
  FetchUserEvaluations,
  FetchModelResponses,
  FetchUserEvaluationResponses,
  FetchExperimentBySlug,
  RecordUserEvaluationResponses,
  FetchUserEvaluationResponsesByExperimentId,
  FetchModelResponsesByExperimentId,
  FetchExperimentResultsExport,
  FetchAllUserEvaluations,
  FetchExperimentsForUser,
  FetchAllExperiments,
  DeleteExperiment,
}
