import { FileRejection, useDropzone } from 'react-dropzone'

import { TranscriptsDocument } from 'openapi/models/TranscriptsDocument'

import { onDrop } from 'utils/dropzone'
import { createAcceptObject, mbToBytes } from 'utils/file-utils'
import useHarveySocket, {
  HarveySocketSetter,
  InitSocketAndSendQuery,
} from 'utils/use-harvey-socket'

import * as config from 'components/workflows/workflow/transcripts/config'

type Props = {
  // TODO:
  handleNewFiles: (files: File[], cb: InitSocketAndSendQuery) => Promise<void>
  handleNewFilesCompleted: () => void
  documents: TranscriptsDocument[]
  setter: HarveySocketSetter
}

export const useTranscriptsDropzone = ({
  handleNewFiles,
  handleNewFilesCompleted,
  documents,
  setter,
}: Props) => {
  const { initSocketAndSendQuery } = useHarveySocket({
    path: config.ROUTE,
    setter,
    endCallback: handleNewFilesCompleted,
    closeOnUnmount: false,
  })

  return useDropzone({
    accept: createAcceptObject(config.FILE_TYPES),
    onDrop: (acceptedFiles: File[], fileRejections: FileRejection[]) =>
      onDrop({
        acceptedFiles,
        fileRejections,
        currentFileCount: documents.length ?? 0,
        maxFiles: config.MAX_FILES,
        acceptedFileTypes: config.FILE_TYPES,
        maxFileSize: mbToBytes(config.MAX_FILE_SIZE_MB),
        handleAcceptedFiles: (files: File[]) =>
          handleNewFiles(files, initSocketAndSendQuery),
        maxTotalFileSizeProps: {
          maxTotalFileSize: mbToBytes(config.MAX_TOTAL_FILE_SIZE_MB),
          currentTotalFileSize:
            documents.reduce((acc, doc) => acc + (doc.file.size ?? 0), 0) ?? 0,
        },
      }),
    maxFiles: config.MAX_FILES,
    maxSize: mbToBytes(config.MAX_FILE_SIZE_MB),
  })
}
