import { useState } from 'react'

import { LibraryVisbilityScope } from 'openapi/models/LibraryVisbilityScope'

import { displayErrorMessage } from 'utils/toast'
import { download } from 'utils/utils'

import { TrackEventFunction } from 'components/common/analytics/analytics-context'

import { getLibraryItemsExportURL } from './library-fetcher'
import { LibraryItemKind } from './library-types'

const EXPORT_ERROR_MESSAGE =
  'Sorry, we were unable to export the library items.'

export const useLibraryExport = (trackEvent: TrackEventFunction) => {
  const [isExporting, setIsExporting] = useState(false)

  const trackExport = (
    kind: LibraryItemKind,
    visibilityScope: LibraryVisbilityScope
  ) => {
    trackEvent('Library Exported', {
      kind: kind,
      visibility_scope: visibilityScope,
    })
  }

  const handleExport = async (
    kind: LibraryItemKind,
    visibilityScope: LibraryVisbilityScope
  ) => {
    setIsExporting(true)
    try {
      trackExport(kind, visibilityScope)
      const url = await getLibraryItemsExportURL(kind, visibilityScope)
      download(url, false)
    } catch (error) {
      console.error('Error while exporting library items:', error)
      displayErrorMessage(EXPORT_ERROR_MESSAGE)
    } finally {
      setIsExporting(false)
    }
  }

  return { isExporting, handleExport }
}
