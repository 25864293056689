import * as Sentry from '@sentry/browser'

import { FetchResearchTaxonomies } from 'models/fetchers/research-taxonomy-fetcher'
import { ResearchArea } from 'openapi/models/ResearchArea'
import Services from 'services'

import { HarvQueryKeyPrefix } from './all-query-keys'
import { useWrappedQuery } from './lib/use-wrapped-query'

export const researchTaxonomyQuery = (area: ResearchArea | null) => ({
  queryKey: [HarvQueryKeyPrefix.ResearchTaxonomiesQuery, area],
  queryFn: async () => FetchResearchTaxonomies(area),
})

export const useResearchTaxonomyQuery = (
  area: ResearchArea | null,
  isEnabled: boolean = true,
  refetchOnWindowFocus = true
) => {
  const {
    isPending,
    error,
    data: taxonomy,
    isLoading,
    isFetching,
    isFetched,
  } = useWrappedQuery({
    enabled: isEnabled,
    refetchOnWindowFocus,
    ...researchTaxonomyQuery(area),
  })

  if (error) {
    Sentry.captureException(error)
    Services.HoneyComb.RecordError(error)
  }

  return {
    taxonomy: taxonomy ?? [],
    isLoading,
    isPending,
    isFetching,
    isFetched,
    error,
  }
}
