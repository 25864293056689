// This is a markdown file, exported as a single javascript string

const markdownContent = `
## Client matter numbers have been enabled

Your users can now attach a client matter number to each query they run in Harvey. You can update which client matter numbers users see in Settings. If you have any questions, please reach out to your Harvey Customer Success Manager.

`

export default markdownContent
