import React, { useRef, useState } from 'react'

import { Pencil } from 'lucide-react'

import { cn } from 'utils/utils'

import { Badge } from 'components/ui/badge'
import Icon from 'components/ui/icon/icon'
import { Input } from 'components/ui/input'
import { Label } from 'components/ui/label'

const VaultEditOption = ({
  label,
  value,
  setValue,
  initialEditing = false,
  className,
}: {
  label: string
  value: number
  setValue: (value: number) => void
  initialEditing?: boolean
  className?: string
}) => {
  const [editing, setEditing] = useState(initialEditing)
  const inputRef = useRef<HTMLInputElement>(null)
  return (
    <div
      className={cn(
        'flex items-center gap-2',
        { 'flex-col items-start': editing },
        className
      )}
    >
      <Label htmlFor="value" className="w-2/3">
        {label}
      </Label>
      {editing ? (
        <Input
          ref={inputRef}
          id="value"
          type="number"
          value={value}
          onChange={(e) => setValue(parseInt(e.target.value))}
          placeholder="Enter value"
          min={0}
          className="h-8 w-[200px]"
        />
      ) : (
        <Badge
          variant="outline"
          onClick={() => {
            setEditing(true)
            setTimeout(() => {
              inputRef.current?.focus()
            }, 0)
          }}
          className="cursor-pointer"
        >
          {value}
          <Icon
            icon={Pencil}
            size="small"
            variant="secondary"
            className="ml-2"
          />
        </Badge>
      )}
    </div>
  )
}

export default VaultEditOption
