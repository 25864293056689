import React, { useEffect, useState } from 'react'

import { Table, message } from 'antd'
import _ from 'lodash'
import pluralize from 'pluralize'

import { CreatePerm, FetchAllPerms, UpdatePerm } from 'models/perms'
import type { Perm } from 'models/perms'

import { useNavigateWithQueryParams } from 'hooks/use-navigate-with-query-params'

import { useAuthUser } from 'components/common/auth-context'
import SettingsAppHeader from 'components/settings/settings-app-header'
import SettingsLayout from 'components/settings/settings-layout'
import SearchInput from 'components/ui/search-input'

import AddEditPermModal from './add-edit-perm-modal'

const Permissions = () => {
  const userInfo = useAuthUser()
  const navigate = useNavigateWithQueryParams()
  const [loading, setLoading] = useState(false)
  const [perms, setPerms] = useState<Perm[]>([])
  const [addEditModalOpen, setAddEditModalOpen] = useState(false)
  const [editPerm, setEditPerm] = useState<Perm | null>(null)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [filterPerm, setFilterPerm] = useState('')
  const [filteredPerms, setFilteredPerms] = useState<Perm[]>([])

  useEffect(() => {
    if (userInfo.IsInternalAdminReader) {
      void fetchAllPerms()
    }
  }, [userInfo])

  const fetchAllPerms = async (): Promise<void> => {
    setLoading(true)
    const data = await FetchAllPerms()
    const sortedPerms = _.sortBy(data, 'name')
    setPerms(sortedPerms)
    setFilteredPerms(sortedPerms)
    setLoading(false)
  }

  const handleCreate = async (values: Perm): Promise<void> => {
    setEditPerm(null)
    await CreatePerm(values)
    await message.success(`Perm created successfully `)
    await fetchAllPerms()
  }

  const handleUpdate = async (values: Perm): Promise<void> => {
    setEditPerm(null)
    await UpdatePerm(values)
    await fetchAllPerms()
  }

  const onModalClose = (): void => {
    setEditPerm(null)
    setAddEditModalOpen(false)
  }

  const tableOnCell = (record: Perm) => {
    return {
      onClick: () => {
        navigate(
          `/settings/internal_admin/permissions/users?permId=${record.permId}&permName=${record.name}`,
          undefined,
          ['workspace']
        )
      },
    }
  }

  let columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: '25%',
      render: (text: string) => <span className="font-semibold">{text}</span>,
      onCell: tableOnCell,
    },
    {
      title: 'Perm ID',
      dataIndex: 'permId',
      key: 'permId',
      width: '25%',
      onCell: tableOnCell,
    },
    {
      title: 'Description',
      dataIndex: 'desc',
      key: 'desc',
      width: '50%',
      onCell: tableOnCell,
    },
  ]

  if (!userInfo.IsInternalAdminReader) return null

  if (!userInfo.isUserManagement) {
    columns = columns.filter((c) => c.key !== 'actions')
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleFilterPerm = (filterValue: string): void => {
    if (_.isEmpty(filterValue)) {
      setFilteredPerms(perms)
      setFilterPerm('')
      return
    }
    setFilterPerm(filterValue)
    const filteredData = perms.filter((perm) => {
      return (
        perm.name.toLowerCase().includes(filterValue.toLowerCase()) ||
        perm.permId.toLowerCase().includes(filterValue.toLowerCase())
      )
    })
    setFilteredPerms(filteredData)
  }

  return (
    <>
      <SettingsAppHeader
        actions={
          <SearchInput
            value={filterPerm}
            setValue={handleFilterPerm}
            withIcon
          />
        }
        isInternalAdmin
      />
      <SettingsLayout>
        <Table
          dataSource={filteredPerms}
          columns={columns}
          loading={loading}
          pagination={false}
          scroll={{ x: 1500 }}
          bordered
          virtual={false}
          footer={() => (
            <div className="flex justify-end">
              <p>
                {filteredPerms.length}{' '}
                {pluralize('permissions', filteredPerms.length)}{' '}
              </p>
            </div>
          )}
          className="cursor-pointer"
        />
      </SettingsLayout>
      <AddEditPermModal
        open={addEditModalOpen}
        perm={editPerm}
        onClose={onModalClose}
        onSave={(values: Perm) => {
          void handleCreate(values)
        }}
        onUpdate={(values: Perm) => {
          void handleUpdate(values)
        }}
      />
    </>
  )
}

export default Permissions
