import { useCallback } from 'react'

import { useShallow } from 'zustand/react/shallow'

import { HarveySocketCompletionStatus } from 'utils/use-harvey-socket-utils'

import { useAssistantStore } from 'components/assistant/stores/assistant-store'

export const useSocketCompletedCallback = () => {
  const [setStreamingMessage, moveStreamingMessageToMessages, restoreState] =
    useAssistantStore(
      useShallow((s) => [
        s.setStreamingMessage,
        s.moveStreamingMessageToMessages,
        s.restoreState,
      ])
    )

  const socketCompletedCallback = useCallback(
    (_queryId: string, completionStatus: HarveySocketCompletionStatus) => {
      if (completionStatus === HarveySocketCompletionStatus.Completed) {
        moveStreamingMessageToMessages()
      } else if (
        [
          HarveySocketCompletionStatus.Error,
          HarveySocketCompletionStatus.Cancelled,
        ].includes(completionStatus)
      ) {
        setStreamingMessage(null)
        restoreState()
      }
    },
    [moveStreamingMessageToMessages, setStreamingMessage, restoreState]
  )

  return { socketCompletedCallback }
}
