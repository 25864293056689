import React, { useState } from 'react'

import { Alert, AlertTitle } from 'components/ui/alert'
import { Button } from 'components/ui/button'
import { Checkbox } from 'components/ui/checkbox'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogTitle,
} from 'components/ui/dialog'
import { Spinner } from 'components/ui/spinner'

interface BulkDeleteWorkspaceHistoryModalProps {
  selectedDate: Date
  requestResetUsage?: boolean
  open: boolean
  onOpenChange: (open: boolean) => void
  onDelete: () => void
}

const DeleteAllWorkspaceHistoryModal = ({
  selectedDate,
  requestResetUsage,
  open,
  onOpenChange,
  onDelete,
}: BulkDeleteWorkspaceHistoryModalProps) => {
  const [deleteAcknowledge, setDeleteAcknowledge] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)

  const handleDelete = async () => {
    setIsDeleting(true)
    try {
      await onDelete()
    } finally {
      setIsDeleting(false)
      setDeleteAcknowledge(false)
      onOpenChange(false)
    }
  }

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="max-w-[600px]">
        <DialogTitle>
          <div className="text-destructive">Delete all history</div>
        </DialogTitle>
        <DialogDescription>
          <p>
            This is non-reversible. It will delete all workspace history data
            {requestResetUsage ? ', and reset usage, ' : ''} before the date:{' '}
            <b>{selectedDate.toDateString()}</b>.
          </p>
          {!isDeleting && (
            <Alert className="mt-4 h-14" variant="destructive">
              <AlertTitle className="-mt-2 flex justify-between">
                {`Please acknowledge you want to delete all workspace history data${
                  requestResetUsage ? ', and reset usage, ' : ''
                } before the selected date: ${selectedDate.toDateString()}`}
                <Checkbox
                  checked={deleteAcknowledge}
                  onCheckedChange={() =>
                    setDeleteAcknowledge(!deleteAcknowledge)
                  }
                  checkboxClassName="border border-destructive"
                />
              </AlertTitle>
            </Alert>
          )}
          {isDeleting && (
            <div className="mt-4 flex items-center">
              <Spinner className="mr-2" />
              <span>This operation could take up to a minute...</span>
            </div>
          )}
        </DialogDescription>
        <DialogFooter>
          <Button
            className="btn"
            variant="secondary"
            onClick={() => {
              setDeleteAcknowledge(false)
              onOpenChange(false)
            }}
            disabled={isDeleting}
          >
            Cancel
          </Button>
          <Button
            className="btn btn-primary"
            onClick={handleDelete}
            disabled={!deleteAcknowledge || isDeleting}
            variant="destructive"
          >
            Delete
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default DeleteAllWorkspaceHistoryModal
