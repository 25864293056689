import React from 'react'
import LoadingBar from 'react-top-loading-bar'

const LoadingBarBelowAppHeader = ({ progress }: { progress: number }) => {
  return (
    <LoadingBar
      color="#1352F1"
      progress={progress}
      containerStyle={{
        zIndex: 20,
        position: 'relative',
      }}
    />
  )
}

export default LoadingBarBelowAppHeader
