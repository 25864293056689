import React from 'react'

import _ from 'lodash'

import { useAllTaskLabelLookup } from 'utils/task-definitions'

import {
  LibraryFilterComponent,
  UseFilterComponentsProps,
} from './filter-components'
import { useAuthUser } from 'components/common/auth-context'
import GenericSelectFilter from 'components/filter/instances/generic-multi-select-filter'
import SearchFilter from 'components/filter/instances/search-filter'
import StarredFilter from 'components/filter/instances/starred-filter'
import TaskTypeFilter from 'components/filter/instances/task-type-filter'

import { LibraryFilterKey } from './library-filter-store'
import { useLibraryMetadataStore } from './library-metadata-store'
import { Example } from './library-types'

export const useExamplesFilterComponents = ({
  items,
}: UseFilterComponentsProps<Example>) => {
  const userInfo = useAuthUser()
  const taskLabelLookup = useAllTaskLabelLookup(userInfo)
  const getFavoriteStatus = useLibraryMetadataStore((s) => s.getFavoriteStatus)

  const allTaskTypeStrings = React.useMemo(() => {
    const itemValues = Object.values(items)
    return new Set(
      itemValues.map((item) => item.eventKind?.toString()).filter(Boolean)
    )
  }, [items])
  const sortedTaskTypes = React.useMemo(
    () =>
      _.sortBy(
        Object.keys(taskLabelLookup)
          .filter((type) => allTaskTypeStrings.has(type))
          .reduce((acc: { text: string; value: string }[], type: string) => {
            const text = taskLabelLookup[type]
            const index = acc.findIndex((item) => item.text === text)

            if (index === -1) {
              acc.push({ text, value: type })
            }

            return acc
          }, []),
        'text'
      ),
    [taskLabelLookup, allTaskTypeStrings]
  )

  const allCategoriesSorted = React.useMemo(() => {
    const itemValues = Object.values(items)
    return Array.from(
      new Set(itemValues.flatMap((item) => item.categories).filter(Boolean))
    )
      .sort()
      .map((category) => ({
        text: category,
        value: category,
      }))
  }, [items])

  const allPracticeAreasSorted = React.useMemo(() => {
    const itemValues = Object.values(items)
    return Array.from(
      new Set(itemValues.flatMap((item) => item.practiceAreas).filter(Boolean))
    )
      .sort()
      .map((type) => ({
        text: type,
        value: type,
      }))
  }, [items])

  const allDocumentTypesSorted = React.useMemo(() => {
    const itemValues = Object.values(items)
    return Array.from(
      new Set(itemValues.flatMap((item) => item.documentTypes).filter(Boolean))
    )
      .sort()
      .map((type) => ({
        text: type,
        value: type,
      }))
  }, [items])

  const allSourcesSorted = React.useMemo(() => {
    const itemValues = Object.values(items)
    return Array.from(
      new Set(itemValues.flatMap((item) => item.source).filter(Boolean))
    )
      .sort()
      .map((source) => ({
        text: source,
        value: source,
      }))
  }, [items])

  return React.useMemo(() => {
    return [
      {
        Component: SearchFilter,
        filterKey: LibraryFilterKey.SEARCH,
        leading: true,
        placeholderText: `Search examples`,
      },
      {
        Component: StarredFilter,
        filterKey: LibraryFilterKey.STARRED,
        isStarred: getFavoriteStatus as (record: Example) => boolean,
      },
      {
        Component: TaskTypeFilter,
        filterKey: LibraryFilterKey.TASK_TYPE,
        userInfo,
        taskLabelLookup,
        sortedTaskTypes,
        entityName: 'library table',
      },
      {
        Component: GenericSelectFilter,
        filterKey: LibraryFilterKey.CATEGORY,
        sortedItems: allCategoriesSorted,
        displayName: 'Category',
        accessor: (record: Example) => record.categories,
        entityName: 'library table',
      },
      {
        Component: GenericSelectFilter,
        filterKey: LibraryFilterKey.PRACTICE_AREA,
        sortedItems: allPracticeAreasSorted,
        displayName: 'Practice area',
        accessor: (record: Example) => record.practiceAreas,
        entityName: 'library table',
      },
      {
        Component: GenericSelectFilter,
        filterKey: LibraryFilterKey.DOCUMENT_TYPE,
        sortedItems: allDocumentTypesSorted,
        displayName: 'Document type',
        accessor: (record: Example) => record.documentTypes,
        entityName: 'library table',
      },
      {
        Component: GenericSelectFilter,
        filterKey: LibraryFilterKey.SOURCES,
        sortedItems: allSourcesSorted,
        displayName: 'Source',
        accessor: (record: Example) => record.source,
        entityName: 'library table',
      },
    ] as LibraryFilterComponent<Example>[]
  }, [
    getFavoriteStatus,
    taskLabelLookup,
    sortedTaskTypes,
    userInfo,
    allCategoriesSorted,
    allPracticeAreasSorted,
    allDocumentTypesSorted,
    allSourcesSorted,
  ])
}
