import React from 'react'

import { X } from 'lucide-react'

import { cn } from 'utils/utils'

import { Button } from 'components/ui/button'
import { useVaultStore } from 'components/vault/utils/vault-store'

interface Props {
  onClose: () => void
}

export const Close: React.FC<Props> = ({ onClose }) => {
  const isTextAreaFocused = useVaultStore((state) => state.isTextAreaFocused)
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    onClose()
  }

  return (
    <div
      className={cn('absolute right-4 top-4 opacity-0', {
        'opacity-100': isTextAreaFocused,
      })}
    >
      <Button variant="ghost" size="xsIcon" onClick={handleClick}>
        <X className="size-4" />
      </Button>
    </div>
  )
}

export default Close
