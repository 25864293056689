import React from 'react'

import { ExcelExportModule } from '@ag-grid-enterprise/excel-export'
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping'
import {
  GridOptions,
  ColDef,
  ColTypeDef,
  ColumnResizedEvent,
  FilterChangedEvent,
  SortChangedEvent,
  RowGroupOpenedEvent,
  RowHeightParams,
  GetRowIdParams,
  GridApi,
  ColumnMovedEvent,
} from 'ag-grid-community'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-quartz.css'
import { CustomNoRowsOverlayProps } from 'ag-grid-react'
import { AgGridReact } from 'ag-grid-react'

import './data-grid.css'

interface DataGridProps<T> {
  gridOptions?: GridOptions
  rowData?: T[]
  columnDefs?: ColDef[]
  defaultColDef?: ColDef
  columnTypes?: Record<string, ColTypeDef>
  marginTop?: number
  setGridApi?: (gridApi: GridApi | null) => void
  onColumnResized?: (e: ColumnResizedEvent) => void
  onColumnMoved?: (e: ColumnMovedEvent) => void
  onFilterChanged?: (e: FilterChangedEvent) => void
  onSortChanged?: (e: SortChangedEvent) => void
  onRowGroupOpened?: (e: RowGroupOpenedEvent) => void
  getRowHeight?: (e: RowHeightParams) => number | null
  noRowsOverlayComponent?: React.ComponentType<CustomNoRowsOverlayProps>
}

export const DataGrid = <T extends object>({
  gridOptions,
  rowData,
  columnDefs,
  defaultColDef,
  columnTypes,
  setGridApi,
  onColumnResized,
  onColumnMoved,
  onFilterChanged,
  onSortChanged,
  onRowGroupOpened,
  getRowHeight,
  noRowsOverlayComponent,
}: DataGridProps<T>) => {
  return (
    <div className="ag-theme-quartz min-h-0 w-full grow">
      <AgGridReact<T>
        gridOptions={gridOptions}
        rowData={rowData}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        columnTypes={columnTypes}
        modules={[ExcelExportModule, RowGroupingModule]}
        getRowId={(params: GetRowIdParams) => {
          return params.data.id
        }}
        onGridReady={(params) => {
          setGridApi && setGridApi(params.api)
        }}
        onGridPreDestroyed={() => {
          setGridApi && setGridApi(null)
        }}
        onColumnResized={(e: ColumnResizedEvent) => {
          onColumnResized && onColumnResized(e)
        }}
        onColumnMoved={(e: ColumnMovedEvent) => {
          onColumnMoved && onColumnMoved(e)
        }}
        onFilterChanged={(e: FilterChangedEvent) => {
          onFilterChanged && onFilterChanged(e)
        }}
        onSortChanged={(e: SortChangedEvent) => {
          onSortChanged && onSortChanged(e)
        }}
        onRowGroupOpened={(e: RowGroupOpenedEvent) => {
          onRowGroupOpened && onRowGroupOpened(e)
        }}
        getRowHeight={(e: RowHeightParams) => {
          return getRowHeight ? getRowHeight(e) : null
        }}
        noRowsOverlayComponent={noRowsOverlayComponent}
        suppressBrowserResizeObserver
      />
    </div>
  )
}
