import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import pluralize from 'pluralize'

import { WorkflowType } from 'types/workflows'

import { mbToReadable } from 'utils/file-utils'

import { AppHeaderActions } from 'components/common/app-header-actions'
import { Dropzone } from 'components/common/dropzone/dropzone'
import DropzoneDescription from 'components/common/dropzone/dropzone-description'
import ExportDialog from 'components/common/export/export-dialog'
import { DataTable } from 'components/ui/data-table/data-table'
import * as config from 'components/workflows/workflow/contracts/config'
import ContractsHeader from 'components/workflows/workflow/contracts/contracts-header'
import {
  ContractsToolbarType,
  useContractsStore,
} from 'components/workflows/workflow/contracts/contracts-store'
import { ExtractTermsSheet } from 'components/workflows/workflow/contracts/extract-terms-sheet/extract-terms-sheet'
import ExtractionInProgress from 'components/workflows/workflow/contracts/file-explorer/extraction-in-progress'
import { useContractsDropzone } from 'components/workflows/workflow/contracts/hooks/use-dropzone'
import { useContractsSocket } from 'components/workflows/workflow/contracts/hooks/use-socket'
import { useContractsTable } from 'components/workflows/workflow/contracts/hooks/use-table'
import { ContractsPages } from 'components/workflows/workflow/contracts/pages'
import { exportToExcel } from 'components/workflows/workflow/contracts/utils/export-to-excel'
import WorkflowLayout from 'components/workflows/workflow/workflow-layout'

import { SmartFilter } from './smart-filter'

const ContractsFileExplorer: React.FC = () => {
  const workflowType = WorkflowType.CONTRACTS
  const {
    customTerms,
    documents,
    filters,
    handleCreateCustomTerm,
    handleCreateCustomTermCompleted,
    handleCreateSmartFilter,
    handleCreateSmartFilterCompleted,
    handleExtractTerms,
    handleExtractTermsCompleted,
    handleNewFiles,
    handleNewFilesCompleted,
    isFilterLoading,
    removeDocument,
    selectedTerms,
    setCustomTerms,
    setSelectedTerms,
    setToolbar,
    setter,
    toolbar,
    reset,
  } = useContractsStore()
  const navigate = useNavigate()

  const [showExtractTermsSheet, setShowExtractTermsSheet] = useState(false)

  const createTermSocket = useContractsSocket({
    setter,
    endCallback: handleCreateCustomTermCompleted,
  })

  const extractTermsSocket = useContractsSocket({
    setter,
    endCallback: handleExtractTermsCompleted,
  })

  const createSmartFilterSocket = useContractsSocket({
    setter,
    endCallback: handleCreateSmartFilterCompleted,
  })

  const { getRootProps, getInputProps, open } = useContractsDropzone({
    handleNewFiles,
    handleNewFilesCompleted,
    documents,
    setter,
  })

  const handleToolbarItemClick = (item: ContractsToolbarType) => {
    if (item === ContractsToolbarType.SMART_FILTER) {
      setToolbar(
        toolbar === ContractsToolbarType.SMART_FILTER
          ? null
          : ContractsToolbarType.SMART_FILTER
      )
    }
  }

  const handleRowClick = (row: any) => {
    if (row.original.isLoading) return
    navigate(
      ContractsPages.FILE_VIEW.replace(
        ':filename',
        encodeURIComponent(row.original.file.name)
      )
    )
  }

  const table = useContractsTable({
    documents,
    filters,
    removeDocument,
  })

  const loadingDocuments = documents.filter((d) => d.isLoading)

  const getLoadingMessage = () => {
    if (loadingDocuments.length > 0) {
      return `Extraction in progress: ${loadingDocuments.length} ${pluralize(
        'Document',
        loadingDocuments.length
      )} Remaining`
    } else if (isFilterLoading) {
      return 'Smart filter in progress'
    }
  }
  const isLoading = getLoadingMessage() !== undefined

  const handleExport = () => exportToExcel(documents)

  if (!documents.length) {
    return (
      <WorkflowLayout workflowType={workflowType} title="Contracts">
        <div className="flex h-full w-full justify-center">
          <Dropzone
            className="h-full w-full"
            isLoading={isLoading}
            dropzone={{ getRootProps, getInputProps }}
            description={
              <DropzoneDescription
                fileTypes={config.FILE_TYPES}
                maxSize={mbToReadable(config.MAX_FILE_SIZE_MB)}
              />
            }
          />
        </div>
      </WorkflowLayout>
    )
  }

  return (
    <WorkflowLayout
      workflowType={workflowType}
      title="Contracts"
      className="h-full"
      appHeaderActions={
        <>
          <ContractsHeader
            isLoading={isLoading}
            onUpload={open}
            onExtractTerms={() => setShowExtractTermsSheet(true)}
            onSmartFilter={() =>
              handleToolbarItemClick(ContractsToolbarType.SMART_FILTER)
            }
            searchQuery={
              (table.getColumn('file')?.getFilterValue() as string) ?? ''
            }
            onSearch={(event) =>
              table.getColumn('file')?.setFilterValue(event.target.value)
            }
          />
          <AppHeaderActions handleReset={reset} resetDisabled={isLoading} />
          <ExportDialog
            onExport={handleExport}
            disabled={isLoading || !documents.length}
          />
        </>
      }
    >
      <ExtractTermsSheet
        documents={documents}
        isLoading={isLoading}
        selectedTerms={selectedTerms}
        setSelectedTerms={setSelectedTerms}
        runExtraction={() =>
          handleExtractTerms(extractTermsSocket.initSocketAndSendQuery)
        }
        showSheet={showExtractTermsSheet}
        setShowSheet={setShowExtractTermsSheet}
        setCustomTerms={setCustomTerms}
        customTerms={customTerms}
        handleTermAdd={(type: string, term: string) =>
          handleCreateCustomTerm(
            term,
            type,
            createTermSocket.initSocketAndSendQuery
          )
        }
      />
      <div className="h-full w-full space-y-4">
        <SmartFilter
          isLoading={isLoading}
          documents={documents}
          onCreateFilter={(query: string, type: string, termName: string) =>
            handleCreateSmartFilter({
              initSocketAndSendQuery:
                createSmartFilterSocket.initSocketAndSendQuery,
              contractType: type,
              termName,
              query,
            })
          }
          isFilterLoading={isFilterLoading}
        />
        <div>
          <ExtractionInProgress
            isInProgress={isLoading}
            message={getLoadingMessage()!}
          />
          <DataTable
            table={table}
            onRowClick={handleRowClick}
            className="h-[calc(100vh-376px)]"
          />
        </div>
      </div>
    </WorkflowLayout>
  )
}

export default ContractsFileExplorer
