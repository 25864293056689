import React from 'react'

import { AppHeader } from 'components/common/app-header'
import { AppMain } from 'components/common/app-main'

import AvailableWorkflows from './available-workflows'

const WorkflowsPage: React.FC = () => {
  return (
    <AppMain hasContainer data-testid="workflows-container">
      <AppHeader
        title="Workflows"
        subtitle="Use specialized workflows to tackle complex matters"
      />
      <AvailableWorkflows />
    </AppMain>
  )
}

export default WorkflowsPage
