import useHarveySocket, { HarveySocketSetter } from 'utils/use-harvey-socket'

import * as config from 'components/workflows/workflow/contracts/config'

type Params = {
  setter: HarveySocketSetter
  endCallback: () => void
}

export const useContractsSocket = ({ setter, endCallback }: Params) =>
  useHarveySocket({
    path: config.ROUTE,
    setter,
    endCallback,
    closeOnUnmount: false,
  })
