import { useEffect } from 'react'

import { useShallow } from 'zustand/react/shallow'

import { uploadFile } from 'api'
import { EventKind } from 'openapi/models/EventKind'
import { UploadedFile } from 'openapi/models/UploadedFile'
import Services from 'services'

import { cloneFileWithNewName, createFileName } from 'utils/file-utils'

import { AssistantMode } from 'components/assistant/components/assistant-mode-select'
import {
  FileUploadingState,
  useAssistantStore,
} from 'components/assistant/stores/assistant-store'
import { isFileUploading } from 'components/assistant/utils/assistant-helpers'

import { useAssistantAnalytics } from './use-assistant-analytics'

export const useAssistantFileUpload = (mode: AssistantMode) => {
  const [
    eventId,
    setEventId,
    setDocuments,
    documents,
    documentsUploading,
    setDocumentsUploading,
    processingFilesBatchPromise,
    processFilesBatch,
  ] = useAssistantStore(
    useShallow((s) => [
      s.eventId,
      s.setEventId,
      s.setDocuments,
      s.documents,
      s.documentsUploading,
      s.setDocumentsUploading,
      s.processingFilesBatchPromise,
      s.processFilesBatch,
    ])
  )
  const trackEvent = useAssistantAnalytics()

  useEffect(() => {
    if (!processingFilesBatchPromise) {
      processFilesBatch()
    }
  }, [processingFilesBatchPromise, processFilesBatch, documentsUploading])

  const handleFileUpload = async (files: File[]) => {
    const kind =
      mode === AssistantMode.ASSIST
        ? EventKind.ASSISTANT_CHAT
        : EventKind.ASSISTANT_DRAFT

    let currEventId = eventId
    if (!currEventId) {
      // Create a PENDING event that files will be associated with
      const { id } = await Services.Backend.Post<{ id: string }>('event', {
        kind,
      })
      setEventId(id)
      currEventId = id
    }

    const existingNames = documents
      .map((doc) => doc.name)
      .concat(documentsUploading.map((doc) => doc.name))
    const existingNamesSet = new Set(existingNames)
    const uploadPromises = files.map((file) => {
      const controller = new AbortController()
      const signal = controller.signal
      const name = createFileName(file.name, existingNamesSet)
      const newFile = cloneFileWithNewName(file, name)
      return {
        name,
        size: file.size,
        promise: uploadFile(newFile, false, {
          eventId: currEventId || undefined,
          signal,
        }),
        controller,
      }
    })

    setDocumentsUploading([
      ...useAssistantStore.getState().documentsUploading,
      ...uploadPromises,
    ])

    trackEvent('Documents Uploaded', {
      num_documents: files.length,
      total_file_size_bytes: files.reduce(
        (total, file) => total + file.size,
        0
      ),
    })
  }

  const handleRemoveFile = (file: UploadedFile | FileUploadingState) => {
    const isUploading = isFileUploading(file)
    if (isUploading) {
      const doc = documentsUploading.find((doc) => doc.name === file.name)
      if (doc) doc.controller.abort()

      setDocumentsUploading(
        documentsUploading.filter((doc) => doc.name !== file.name)
      )
    } else {
      setDocuments(documents.filter((doc) => doc.id !== file.id))
    }

    trackEvent('Document Removed', {
      file_size_bytes: file.size,
    })
  }

  const handleRemoveAllFiles = () => {
    documentsUploading.forEach((doc) => doc.controller.abort())
    setDocumentsUploading([])
    setDocuments([])
  }

  return {
    handleFileUpload,
    handleRemoveFile,
    handleRemoveAllFiles,
  }
}
