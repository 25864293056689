import _ from 'lodash'

import { environment, mixpanelToken } from './server-data'

export enum Env {
  PRODUCTION = 'production',
  STAGING = 'staging',
  DEVELOPMENT = 'development',
  LOCAL = 'local',
}

// Which environments have logging; dev is noisy
export const isSentryLoggingEnabled = [Env.PRODUCTION, Env.STAGING].includes(
  environment
)

export const isMixpanelLoggingEnabled =
  [Env.PRODUCTION, Env.STAGING].includes(environment) &&
  !_.isEmpty(mixpanelToken)
