import React from 'react'

import { Boxes, Upload } from 'lucide-react'
import { Search } from 'lucide-react'

import { Button } from 'components/ui/button'
import { Input } from 'components/ui/input'

interface ContractsHeaderProps {
  onUpload: () => void
  onExtractTerms: () => void
  onSmartFilter: () => void
  onSearch: (e: React.ChangeEvent<HTMLInputElement>) => void
  isLoading: boolean
  searchQuery?: string
}

const ContractsHeader: React.FC<ContractsHeaderProps> = ({
  onUpload,
  onExtractTerms,
  isLoading,
  onSearch,
  searchQuery = '',
}) => {
  return (
    <div className="flex justify-end space-x-2">
      <Button variant="outline" onClick={onUpload} disabled={isLoading}>
        <Upload size={16} className="mr-1.5 shrink-0" />
        Upload
      </Button>
      <Button variant="outline" onClick={onExtractTerms} disabled={isLoading}>
        <Boxes size={16} className="mr-1.5 shrink-0" />
        Extract terms
      </Button>

      <div className="relative">
        <div className="absolute flex h-full items-center px-3">
          <Search size={16} />
        </div>
        <Input
          placeholder="Search"
          className="h-8 pl-9"
          onChange={onSearch}
          value={searchQuery}
        />
      </div>
    </div>
  )
}

export default ContractsHeader
