import { useEffect, useRef } from 'react'

import { EventKind } from 'openapi/models/EventKind'

import { useAnalytics } from 'components/common/analytics/analytics-context'

// useQueryAnalytics hook manages common analytics we might want to capture.
//
// This hook expects the following arguments:
// - `metricPrefix`: The prefix to add to the metric name
//      It will be used like `ui.{metricPrefix}_metric`
//
// This hook returns an object containing:
// - `setQueryStarted`: Should be called when a query is started (aka on 'Ask Harvey')
// - `setQueryViewed`: Should be called if a query is viewed on mount (i.e. it was loaded from history)
// - `recordExport`: Called when query response is exported.
// - `recordReset`: Called when query response is reset.
// - `recordQueryCancel`: Called when canceling a query
// - `recordQueryCompletion`: Called when a query is complete. This handles setting `viewed` too, so that
//      does not need to be called separately.

const useQueryAnalytics = (eventKind: EventKind | string) => {
  const { trackEvent } = useAnalytics()

  const queryStartedAt = useRef<number | undefined>()
  const numStreamingClicksAway = useRef<number>(0)
  const queryCompletedAt = useRef<number | undefined>()
  const queryViewedAt = useRef<number | undefined>()
  const viewDuration = useRef<number>(0)

  const handleClickAway = () => {
    if (queryStartedAt.current && !queryCompletedAt.current) {
      numStreamingClicksAway.current += 1
    }
    if (queryViewedAt.current) {
      // Reset view time when they navigate away
      viewDuration.current += Date.now() - queryViewedAt.current
      queryViewedAt.current = 0
    }
  }

  const handleWindowFocus = () => {
    if (queryViewedAt.current === 0) {
      queryViewedAt.current = Date.now()
    }
  }

  useEffect(() => {
    window.addEventListener('blur', handleClickAway)
    window.addEventListener('focus', handleWindowFocus)
    return () => {
      window.removeEventListener('blur', handleClickAway)
      window.removeEventListener('focus', handleWindowFocus)
    }
  }, [])

  const setQueryViewed = () => (queryViewedAt.current = Date.now())
  const setQueryStarted = () => {
    queryStartedAt.current = Date.now()
    queryCompletedAt.current = undefined
  }
  const setQueryCompleted = () => {
    queryCompletedAt.current = Date.now()
    queryStartedAt.current = undefined
  }

  const recordQuerySubmitted = (
    fields?: Record<string, string | number | string[]>
  ) => {
    setQueryStarted()
    trackEvent(
      'Query Submitted',
      {
        event_kind: eventKind,
        ...(fields || {}),
      },
      /* suppressPageView */ true
    )
  }

  const recordQueryCompletion = (
    fields?: Record<string, string | number | string[]>
  ) => {
    if (!queryCompletedAt.current && queryStartedAt.current) {
      const queryDuration = Date.now() - queryStartedAt.current
      setQueryCompleted()
      setQueryViewed()

      trackEvent(
        'Query Completed',
        {
          event_kind: eventKind,
          query_duration_ms: queryDuration,
          streaming_clicks_away: numStreamingClicksAway.current,
          ...(fields || {}),
        },
        /* suppressPageView */ true
      )
    }
  }

  const recordQueryCancel = (
    fields?: Record<string, string | number | string[]>
  ) => {
    if (queryStartedAt.current && !queryCompletedAt.current) {
      const queryDuration = Date.now() - queryStartedAt.current
      setQueryCompleted()
      trackEvent('Query Canceled', {
        event_kind: eventKind,
        query_duration_ms: queryDuration,
        ...(fields || {}),
      })
    }
  }

  const recordQueryError = (
    fields?: Record<string, string | number | string[]>
  ) => {
    if (queryStartedAt.current && !queryCompletedAt.current) {
      const queryDuration = Date.now() - queryStartedAt.current
      setQueryCompleted()
      trackEvent('Query Error', {
        event_kind: eventKind,
        query_duration_ms: queryDuration,
        ...(fields || {}),
      })
    }
  }

  const recordExport = (
    eventId: string,
    export_kind: string,
    includeAnnotation?: boolean
  ) => {
    trackEvent('Response Exported', {
      event_id: eventId,
      event_kind: eventKind,
      export_kind: export_kind,
      citations_included: includeAnnotation,
    })
  }

  const recordReset = () => {
    trackEvent(
      'Query Reset',
      {
        event_kind: eventKind,
      },
      /* suppressPageView */ true
    )
  }

  return {
    setQueryStarted,
    setQueryViewed,
    recordExport,
    recordQuerySubmitted,
    recordQueryCompletion,
    recordQueryCancel,
    recordQueryError,
    recordReset,
  }
}

export default useQueryAnalytics
