import React from 'react'

interface TaxFeedbackUrlProps {
  url: string
}

const TaxFeedbackUrl: React.FC<TaxFeedbackUrlProps> = ({ url }) => (
  <p>
    If you would like to provide more detailed feedback or suggest new tax
    sources, please use{' '}
    <a
      href={url}
      className="text-sm underline"
      rel="noreferrer"
      target="_blank"
    >
      this form
    </a>
    .
  </p>
)

export default TaxFeedbackUrl
