import { GridApi, ColDef, IRowNode, ValueGetterParams } from 'ag-grid-community'
import { capitalize, isNil } from 'lodash'
import {
  Calendar,
  CircleDollarSign,
  Clock9,
  Hash,
  Text,
  Type,
  Waypoints,
} from 'lucide-react'

import { VaultFile } from 'openapi/models/VaultFile'
import { VaultFolder } from 'openapi/models/VaultFolder'
import { useGeneralStore } from 'stores/general-store'
import { HistoryItem } from 'types/history'
import { SIDEBAR_CLOSED_WIDTH, SIDEBAR_OPEN_WIDTH } from 'types/ui-constants'

import { SafeRecord } from 'utils/safe-types'
import { TaskStatus } from 'utils/task'

import {
  ReviewColumn,
  QueryQuestion,
  ColumnDataType,
  ReviewAnswer,
  ReviewError,
  COLUMN_DATA_TYPES_NOT_TO_BE_USED_FOR_QUESTION,
  ReviewRow,
} from 'components/vault/utils/vault'
import { FilterType } from 'components/vault/utils/vault-data-grid-filters-store'
import {
  getFoldersOnPath,
  getDisplayAnswer,
} from 'components/vault/utils/vault-helpers'

import classifyIllustration from './data-grid/cells/assets/classify-example.svg'
import currencyIllustration from './data-grid/cells/assets/currency-example.svg'
import dateIllustration from './data-grid/cells/assets/date-example.svg'
import durationIllustration from './data-grid/cells/assets/duration-example.svg'
import extractionIllustration from './data-grid/cells/assets/extraction-example.svg'
import freeResponseIllustration from './data-grid/cells/assets/free-response-example.svg'
import numericIllustration from './data-grid/cells/assets/numeric-example.svg'
import { cellComparator } from './data-grid/cells/cell-comparator/cell-comparator'
import { EXCLUDED_HEADER_NAMES_FROM_DELETE_COLUMN } from './vault-query-detail'
import { ReviewHistoryItem } from './vault-query-detail-store'

export type Pinned = boolean | 'left' | 'right' | null
export const ROOT_NODE_ID = 'ROOT_NODE_ID'
const ROW_NUMBER_COLUMN_WIDTH = 48
const NAME_COLUMN_WIDTH = 256
const DEFAULT_COLUMN_WIDTH = 320
const MIN_COLUMN_WIDTH = 64
export const ADD_COLUMN_WIDTH_WITH_TEXT = 112
export const ADD_COLUMN_WIDTH_WITH_ICON = 48
export const ADD_COLUMN_FIELD = 'addColumn'

export interface QuestionColumnDef extends ColDef {
  originalQuestion: string
  questionId: string
  folderPath: string
  columnDataType: ColumnDataType
  eventId?: number
  backingReviewColumn?: ReviewColumn
  options?: string
}

const handleMouseEnter = (gridApi: GridApi, isIconOnly: boolean) => {
  if (isIconOnly) {
    const columnDefs = gridApi.getColumnDefs()
    const addColumnDef = columnDefs?.find((columnDef) => {
      const hasField = 'field' in columnDef
      return hasField && columnDef.field === ADD_COLUMN_FIELD
    }) as ColDef
    const filteredColumnDefs = columnDefs?.filter((columnDef) => {
      const hasField = 'field' in columnDef
      return hasField && columnDef.field !== ADD_COLUMN_FIELD
    })
    if (addColumnDef) {
      addColumnDef.width = ADD_COLUMN_WIDTH_WITH_TEXT
      addColumnDef.maxWidth = ADD_COLUMN_WIDTH_WITH_TEXT
      const newColumnDefs = [...(filteredColumnDefs ?? []), addColumnDef]
      gridApi.setGridOption('columnDefs', newColumnDefs)
      document
        .querySelector('.ag-header-cell[col-id="addColumn"]')
        ?.classList.add('border-l', 'border-l-ring')
      document
        .querySelectorAll('.ag-cell[col-id="addColumn"]')
        .forEach(
          (cell) =>
            cell.firstElementChild?.classList.add('!border-l', '!border-l-ring')
        )
    }
  }

  const lastColumns = document.getElementsByClassName('ag-column-last')
  Array.from(lastColumns).forEach((column) => {
    const isHeaderCell = column.classList.contains('ag-header-cell')
    if (isHeaderCell) {
      const headerCellParent = column.parentElement
      // 1. add bg-skeleton-dark to the resize element (left border)
      const colIndex = column.getAttribute('aria-colindex')
      const prevColIndex = Number(colIndex) - 1
      const previousHeaderCell = headerCellParent?.querySelector(
        `[aria-colindex="${prevColIndex}"]`
      )
      // if there is a previous header cell, then add bg-skeleton-dark to the resize element
      // otherwise we need to add the bg-skeleton-dark to the pinned left header cell
      if (previousHeaderCell) {
        const resizeElement = previousHeaderCell.firstElementChild
        const isResizeElementVisible =
          !resizeElement?.classList.contains('ag-hidden')
        if (resizeElement && isResizeElementVisible) {
          resizeElement.classList.add('after:bg-skeleton-dark')
        } else {
          const headerCellCompWrapper = previousHeaderCell.querySelector(
            '.ag-header-cell-comp-wrapper'
          )
          const headerCellElement = headerCellCompWrapper?.firstElementChild
          headerCellElement?.classList.add('border-r-ring')
        }
      } else {
        const pinnedLeftHeader = document.querySelector(
          '.ag-pinned-left-header'
        )
        const pinnedHeaderRow = pinnedLeftHeader?.firstElementChild
        const lastPinnedHeaderCell = pinnedHeaderRow?.lastElementChild
        const lastPinnedHeaderCellResizeElement =
          lastPinnedHeaderCell?.firstElementChild
        lastPinnedHeaderCellResizeElement?.classList.add(
          'after:bg-skeleton-dark'
        )
      }
      // 2. add border to the header cell button (right border)
      const headerCellButton = column.lastElementChild?.firstElementChild
      headerCellButton?.classList.add('!border-r', '!border-r-ring')
      // 3. add border to the column (top border)
      column?.classList.add('!border-t', '!border-t-ring')
    } else {
      column.classList.add('!border-r', '!border-r-ring')
      // Get the previous cell
      const columnParent = column.parentElement
      const colIndex = column.getAttribute('aria-colindex')
      const prevColIndex = Number(colIndex) - 1
      const previousColumnCell = columnParent?.querySelector(
        `[aria-colindex="${prevColIndex}"]`
      )
      // if there is a previous column, then add border to it
      // otherwise, there is only the pinned left columns
      if (previousColumnCell) {
        previousColumnCell.classList.add('!border-r', '!border-r-ring')
      } else {
        const rowIndex = column.parentElement?.getAttribute('row-index')
        const leftPinnedColsContainer = document.querySelector(
          '.ag-pinned-left-cols-container'
        )
        const leftPinnedRow = leftPinnedColsContainer?.querySelector(
          `[row-index="${rowIndex}"]`
        )
        const leftPinnedCell = leftPinnedRow?.lastElementChild
        if (leftPinnedCell) {
          leftPinnedCell.classList.add('!border-r', '!border-r-ring')
        }
      }
      if (column.parentElement?.classList.contains('ag-row-last')) {
        column.classList.remove('border-b')
        column.classList.add('!border-b-ring')
      }
    }
  })
}

const handleMouseLeave = (
  e: React.MouseEvent,
  gridApi: GridApi,
  isPinned: boolean
) => {
  // if the related target is the add column cell or a group row, do nothing
  const relatedTarget = e.relatedTarget as HTMLElement
  if (relatedTarget instanceof Window) return

  let isRelatedTargetAddColumnCell = false
  let isGroupRow = false

  if (!relatedTarget) return

  if (relatedTarget.hasAttribute('col-id')) {
    isRelatedTargetAddColumnCell =
      relatedTarget.getAttribute('col-id') === ADD_COLUMN_FIELD
  } else {
    isRelatedTargetAddColumnCell =
      relatedTarget.parentElement?.getAttribute('col-id') === ADD_COLUMN_FIELD
  }
  if (relatedTarget.hasAttribute('row-id')) {
    isGroupRow =
      relatedTarget.getAttribute('row-id')?.startsWith('row-group-') ?? false
  } else {
    isGroupRow =
      relatedTarget.parentElement
        ?.getAttribute('row-id')
        ?.startsWith('row-group-') ?? false
  }
  if (isRelatedTargetAddColumnCell || isGroupRow) return

  // if the column is pinned, we need to adjust the add column cell width
  if (isPinned) {
    const columnDefs = gridApi.getColumnDefs()
    const addColumnDef = columnDefs?.find((columnDef) => {
      const hasField = 'field' in columnDef
      return hasField && columnDef.field === ADD_COLUMN_FIELD
    }) as ColDef
    const filteredColumnDefs = columnDefs?.filter((columnDef) => {
      const hasField = 'field' in columnDef
      return hasField && columnDef.field !== ADD_COLUMN_FIELD
    })
    if (addColumnDef) {
      addColumnDef.width = ADD_COLUMN_WIDTH_WITH_ICON
      addColumnDef.maxWidth = ADD_COLUMN_WIDTH_WITH_ICON
      const newColumnDefs = [...(filteredColumnDefs ?? []), addColumnDef]
      gridApi.setGridOption('columnDefs', newColumnDefs)
      document
        .querySelector('.ag-header-cell[col-id="addColumn"]')
        ?.classList.remove('border-l', 'border-l-ring')
      document
        .querySelectorAll('.ag-cell[col-id="addColumn"]')
        .forEach(
          (cell) =>
            cell.firstElementChild?.classList.remove(
              '!border-l',
              '!border-l-ring'
            )
        )
    }
  }

  const lastColumns = document.getElementsByClassName('ag-column-last')
  Array.from(lastColumns).forEach((column) => {
    const isHeaderCell = column.classList.contains('ag-header-cell')
    if (isHeaderCell) {
      // 1. remove bg-skeleton-dark from the resize element (left border)
      const headerCellParent = column.parentElement
      const colIndex = column.getAttribute('aria-colindex')
      const prevColIndex = Number(colIndex) - 1
      const previousHeaderCell = headerCellParent?.querySelector(
        `[aria-colindex="${prevColIndex}"]`
      )
      if (previousHeaderCell) {
        const resizeElement = previousHeaderCell.firstElementChild
        const isResizeElementVisible =
          !resizeElement?.classList.contains('ag-hidden')
        if (resizeElement && isResizeElementVisible) {
          resizeElement.classList.remove('after:bg-skeleton-dark')
        } else {
          const headerCellCompWrapper = previousHeaderCell.querySelector(
            '.ag-header-cell-comp-wrapper'
          )
          const headerCellElement = headerCellCompWrapper?.firstElementChild
          headerCellElement?.classList.remove('border-r-ring')
        }
      } else {
        const pinnedLeftHeader = document.querySelector(
          '.ag-pinned-left-header'
        )
        const pinnedHeaderRow = pinnedLeftHeader?.firstElementChild
        const lastPinnedHeaderCell = pinnedHeaderRow?.lastElementChild
        const lastPinnedHeaderCellResizeElement =
          lastPinnedHeaderCell?.firstElementChild
        lastPinnedHeaderCellResizeElement?.classList.remove(
          'after:bg-skeleton-dark'
        )
      }
      // 2. remove border from the header cell button (right border)
      const headerCell = column.lastElementChild
      const headerCellButton = headerCell?.firstElementChild
      headerCellButton?.classList.remove('!border-r', '!border-r-ring')
      // 3. remove border from the column (top border)
      column?.classList.remove('!border-t', '!border-t-ring')
    } else {
      column.classList.remove('!border-r', '!border-r-ring')

      // Get the previous column cell
      const columnParent = column.parentElement
      const colIndex = column.getAttribute('aria-colindex')
      const prevColIndex = Number(colIndex) - 1
      const previousColumnCell = columnParent?.querySelector(
        `[aria-colindex="${prevColIndex}"]`
      )

      if (previousColumnCell) {
        previousColumnCell.classList.remove('!border-r', '!border-r-ring')
      } else {
        const rowIndex = column.parentElement?.getAttribute('row-index')
        const leftPinnedColsContainer = document.querySelector(
          '.ag-pinned-left-cols-container'
        )
        const leftPinnedRow = leftPinnedColsContainer?.querySelector(
          `[row-index="${rowIndex}"]`
        )
        const leftPinnedCell = leftPinnedRow?.lastElementChild
        if (leftPinnedCell) {
          leftPinnedCell.classList.remove('!border-r', '!border-r-ring')
        }
      }
      if (column.parentElement?.classList.contains('ag-row-last')) {
        column.classList.remove('!border-b-ring')
        column.classList.add('border-b')
      }
    }
  })
}

const computeIsQueryLoading = (
  historyItem: HistoryItem | ReviewHistoryItem | null
) => {
  if (!historyItem) return false

  return (
    historyItem.status != TaskStatus.COMPLETED &&
    historyItem.status != TaskStatus.CANCELLED &&
    historyItem.status != TaskStatus.ERRORED
  )
}

const getDisplayedRows = (gridApi: GridApi) => {
  const displayedRows: string[] = []
  gridApi.forEachNodeAfterFilterAndSort((node) => {
    if (node.data && node.data.file) {
      displayedRows.push(node.data.file.id)
    } else if (node.id) {
      displayedRows.push(node.id)
    }
  })
  return displayedRows
}

const reorderColumnIds = (
  columnIds: string[],
  toIndex: number,
  movedColumnId: string
) => {
  // 1. remove the moved columnId from the original position
  const originalIndex = columnIds.indexOf(movedColumnId)
  if (originalIndex === -1) {
    return columnIds
  }
  // 2. remove the column from its original position
  const newColumnIds = columnIds.splice(originalIndex, 1)

  // 3. insert the moved columnId at the new position
  newColumnIds.splice(toIndex, 0, movedColumnId)
  return newColumnIds
}

export const mapReviewColumnToColumnDef = (
  eventColumn: ReviewColumn,
  eventId: number
) => {
  return {
    field: eventColumn.displayId.toString(),
    eventId: eventId,
    columnId: eventColumn.id.toString(),
    questionId: eventColumn.displayId.toString(),
    backingReviewColumn: eventColumn,
    headerName: eventColumn.header,
    columnDataType: eventColumn.dataType,
    originalQuestion: eventColumn.fullText,
    width: DEFAULT_COLUMN_WIDTH,
    minWidth: MIN_COLUMN_WIDTH,
    type:
      eventColumn.dataType === ColumnDataType.date
        ? FilterType.DATE
        : FilterType.TEXT,
    options: eventColumn.options?.join(', '),
    // eslint-disable-next-line max-params
    comparator: (
      valueA: string,
      valueB: string,
      nodeA: IRowNode<any>,
      nodeB: IRowNode<any>,
      isDescending: boolean
    ) =>
      cellComparator({
        valueA,
        valueB,
        nodeA,
        nodeB,
        isDescending,
        questionId: eventColumn.displayId.toString(),
        columnDataType: eventColumn.dataType,
      }),
  }
}

interface CreateGridColumnDefsProps {
  gridApi: GridApi
  shouldGroupRows: boolean
  historyColumns: ReviewColumn[]
  pendingQueryQuestions: QueryQuestion[]
  isQueryLoading: boolean
  eventId?: number
}

const createGridColumnDefs = ({
  gridApi,
  shouldGroupRows,
  historyColumns,
  pendingQueryQuestions,
  isQueryLoading,
  eventId,
}: CreateGridColumnDefsProps) => {
  const columnDefs: Array<ColDef | QuestionColumnDef> = [
    {
      valueGetter: (params: ValueGetterParams) => {
        // when we have row grouping, we need to get the index from the parent
        // and subtract it from the row index because group rows also have an index
        // and we don't want to count the group rows when determining the row index
        const node = params.node
        const rowIndex = node?.rowIndex ?? 0
        if (isNil(node?.parent?.rowIndex)) return rowIndex + 1
        const parentRowChildIndex = node?.parent?.childIndex ?? 0
        return rowIndex - parentRowChildIndex
      },
      field: 'row',
      headerName: '#',
      type: 'number',
      width: ROW_NUMBER_COLUMN_WIDTH,
      minWidth: ROW_NUMBER_COLUMN_WIDTH,
      resizable: false,
      pinned: 'left',
    },
    {
      field: 'name',
      headerName: 'Name',
      resizable: true,
      columnDataType: ColumnDataType.string,
      type: 'document',
      pinned: 'left',
      width: NAME_COLUMN_WIDTH,
      minWidth: NAME_COLUMN_WIDTH,
    },
  ]

  if (shouldGroupRows) {
    columnDefs.push({
      field: 'folderPath',
      headerName: 'Folder path',
      type: 'hidden',
      rowGroup: true,
    })
  }

  const hasPendingColumns = pendingQueryQuestions.length > 0

  historyColumns
    .toSorted((a, b) => a.order - b.order)
    .forEach((eventColumn: ReviewColumn) => {
      if (eventColumn.isHidden || !eventId) {
        return
      }
      const column = mapReviewColumnToColumnDef(eventColumn, eventId)
      columnDefs.push({
        suppressMovable: hasPendingColumns,
        resizable: !isQueryLoading,
        ...column,
      })
    })

  pendingQueryQuestions.forEach((question: QueryQuestion) => {
    const column = {
      field: question.id,
      questionId: question.id,
      backingReviewColumn: question.backingReviewColumn,
      headerName: question.header,
      columnDataType: question.columnDataType,
      originalQuestion: question.text,
      width: DEFAULT_COLUMN_WIDTH,
      minWidth: MIN_COLUMN_WIDTH,
      suppressMovable: hasPendingColumns,
      resizable: !isQueryLoading,
      // eslint-disable-next-line max-params
      comparator: (
        valueA: string,
        valueB: string,
        nodeA: IRowNode<any>,
        nodeB: IRowNode<any>,
        isDescending: boolean
      ) =>
        cellComparator({
          valueA,
          valueB,
          nodeA,
          nodeB,
          isDescending,
          questionId: question.id,
          columnDataType:
            question.columnDataType ?? ColumnDataType.freeResponse,
        }),
      type:
        question.columnDataType === ColumnDataType.date
          ? FilterType.DATE
          : FilterType.TEXT,
    }
    columnDefs.push(column)
  })

  const addColumnDef = {
    field: ADD_COLUMN_FIELD,
    headerName: ADD_COLUMN_FIELD,
    type: ADD_COLUMN_FIELD,
    width: ADD_COLUMN_WIDTH_WITH_TEXT,
    maxWidth: ADD_COLUMN_WIDTH_WITH_TEXT,
    pinned: false as Pinned,
  }
  columnDefs.push(addColumnDef)
  updateAddColumnDef(columnDefs)
  gridApi.updateGridOptions({
    columnDefs: columnDefs,
  })
}

export enum GridTransactionAction {
  ADD = 'add',
  UPDATE = 'update',
}
interface AddFilesToGridProps {
  gridApi: GridApi
  setDisplayedRows: (displayedRows: string[]) => void
  files: VaultFile[]
  folderIdToVaultFolder: SafeRecord<string, VaultFolder>
  isLoading: boolean
  gridAction: GridTransactionAction
  isDryRun?: boolean
  fileIdToAnswers?: SafeRecord<string, ReviewAnswer[]>
  fileIdToErrors?: SafeRecord<string, ReviewError[]>
  previouslyErroredFileIds?: string[]
  questions?: QueryQuestion[]
  reviewRows?: ReviewRow[]
  suppressedFileIdsSet?: Set<string>
  processedFileIdsSet?: Set<string>
}

interface GetDisplayTextProps {
  answer: ReviewAnswer | undefined
  index: number
  isDryRun: boolean
  isLoading: boolean
  isErrorSuppressed: boolean
}

const getDisplayText = ({
  answer,
  index,
  isDryRun,
  isLoading,
  isErrorSuppressed,
}: GetDisplayTextProps) => {
  if (isDryRun && index > 0) {
    // if we are in dry run mode, then we want to show processing text for
    // the first row and then show empty text for the rest of the rows
    return '<DRY_RUN_EMPTY_CELL>'
  }
  if (!answer?.text && isLoading && !isErrorSuppressed) {
    // If the answer is empty and the file is still processing, then we want to show processing text
    return 'Processing…'
  }
  if (!answer?.text) {
    // If the answer is empty, then we want to show empty text
    return ''
  }
  return getDisplayAnswer(answer)
}

const addFilesToGrid = ({
  gridApi,
  setDisplayedRows,
  files,
  folderIdToVaultFolder,
  isLoading,
  gridAction,
  isDryRun,
  fileIdToAnswers,
  fileIdToErrors,
  previouslyErroredFileIds,
  suppressedFileIdsSet,
  questions,
  reviewRows,
}: AddFilesToGridProps) => {
  const rowData = files.map((file, idx) => {
    const fileId = file.id
    const fileName = file.name

    const backingReviewRow = reviewRows?.find((row) => row.fileId === fileId)
    const backingReviewRowId = backingReviewRow?.id

    const cells: { [key: string]: string } = {}
    const answers = fileIdToAnswers ? fileIdToAnswers[fileId] || [] : []
    const errors = fileIdToErrors ? fileIdToErrors[fileId] || [] : []
    const isErrorSuppressed = suppressedFileIdsSet
      ? suppressedFileIdsSet.has(fileId)
      : false

    const folderId = file.vaultFolderId
    const foldersOnPath =
      getFoldersOnPath(folderId, folderIdToVaultFolder) ?? []

    // once we get the folders on path, we want to drop the first folder because it is the root folder
    // and we are not showing the root folder in the group row
    const folderPath = foldersOnPath
      .slice(1)
      .map((f: VaultFolder) => f.name)
      .join('/')

    questions?.forEach((question) => {
      const questionId = question.id
      const questionAnswer = answers.find(
        (answer) => answer.columnId === questionId && !answer.long
      )
      const shortDisplayText = getDisplayText({
        answer: questionAnswer,
        index: idx,
        isDryRun: isDryRun ?? false,
        isLoading: isLoading,
        isErrorSuppressed: isErrorSuppressed,
      })

      cells[questionId] = shortDisplayText
    })

    return {
      id: fileId,
      backingReviewRowId,
      name: fileName,
      file: file,
      folderPath: folderPath,
      errors: isErrorSuppressed ? [] : errors,
      answers: answers,
      ...cells,
    }
  })

  gridApi.applyTransactionAsync({ [gridAction]: rowData }, () => {
    setDisplayedRows(getDisplayedRows(gridApi))
    if (!fileIdToErrors) return
    const fileIdsWithErrors = new Set([
      ...Object.keys(fileIdToErrors),
      ...(previouslyErroredFileIds ?? []),
    ])
    if (fileIdsWithErrors.size === 0) return
    const rowsToRefresh = rowData
      .map((row) => gridApi.getRowNode(row.id))
      .filter(Boolean)
      .filter((row) => fileIdsWithErrors.has(row!.id!)) as IRowNode<any>[]
    gridApi.refreshCells({
      force: true,
      rowNodes: rowsToRefresh,
    })
  })
}

const computeNextColumnId = (columns: (QueryQuestion | ReviewColumn)[]) => {
  if (!columns || columns.length === 0) return 1
  const columnIds = columns.map((column) => {
    if ('displayId' in column) {
      return column.displayId
    }
    return parseInt(column.id)
  })
  return Math.max(...columnIds) + 1
}

const updateAddColumnDef = (columnDefs: ColDef[]) => {
  const haveToPinAddColumn = shouldPinAddColumn(columnDefs, false)

  const addColumnDef = columnDefs.find((columnDef) => {
    const hasField = 'field' in columnDef
    return hasField && columnDef.field === ADD_COLUMN_FIELD
  }) as ColDef
  if (haveToPinAddColumn) {
    addColumnDef.pinned = 'right' as Pinned
    addColumnDef.width = ADD_COLUMN_WIDTH_WITH_ICON
    addColumnDef.maxWidth = ADD_COLUMN_WIDTH_WITH_ICON
  } else {
    addColumnDef.pinned = false
    addColumnDef.width = ADD_COLUMN_WIDTH_WITH_TEXT
    addColumnDef.maxWidth = ADD_COLUMN_WIDTH_WITH_TEXT
  }
  const addColumnIndex = columnDefs.findIndex(
    (columnDef) => 'field' in columnDef && columnDef.field === ADD_COLUMN_FIELD
  )
  if (addColumnIndex !== -1) {
    columnDefs[addColumnIndex] = addColumnDef
  }

  return haveToPinAddColumn
}

const shouldPinAddColumn = (
  existingColumnDefs: ColDef[],
  addNewColumnWidth: boolean
) => {
  // get the available width of the viewport
  const viewportWidth = window.innerWidth

  // compute the available width for the columns
  // the unavailable width is:
  // - width of the sidebar
  // - width of the row number column
  // - width of the name column
  const sidebarWidth = useGeneralStore.getState().isSidebarOpen
    ? SIDEBAR_OPEN_WIDTH
    : SIDEBAR_CLOSED_WIDTH
  const rowNumberColumnWidth = ROW_NUMBER_COLUMN_WIDTH
  const nameColumnWidth =
    existingColumnDefs.find((columnDef) => columnDef.field === 'name')?.width ??
    NAME_COLUMN_WIDTH
  const existingDOMWidth = sidebarWidth + rowNumberColumnWidth + nameColumnWidth

  // get the width of the existing columns
  // adding 320 for the new column
  const newColumnWidth = addNewColumnWidth ? DEFAULT_COLUMN_WIDTH : 0
  const existingColumnWidths =
    newColumnWidth +
    existingColumnDefs.reduce((sum, columnDef) => {
      const hasWidth = 'width' in columnDef
      if (
        !hasWidth ||
        EXCLUDED_HEADER_NAMES_FROM_DELETE_COLUMN.includes(columnDef.field!)
      )
        return sum
      return sum + (columnDef.width || 0)
    }, 0)

  return existingColumnWidths >= viewportWidth - existingDOMWidth
}

const addNewColumn = (
  gridApi: GridApi,
  allColumns: (QueryQuestion | ReviewColumn)[],
  addToPendingQueryQuestions: (queryQuestion: QueryQuestion) => void,
  newQueryQuestion?: Omit<QueryQuestion, 'id'>
  // eslint-disable-next-line max-params
): QueryQuestion => {
  // 1. add the column
  const id = computeNextColumnId(allColumns).toString()
  const queryQuestion = {
    id: id,
    text: newQueryQuestion?.text ?? '',
    header: newQueryQuestion?.header ?? 'Untitled',
    columnDataType:
      newQueryQuestion?.columnDataType ?? ColumnDataType.freeResponse,
    options: newQueryQuestion?.options,
  }
  const newColumnDef = {
    field: id.toString(),
    questionId: id,
    headerName: newQueryQuestion?.header ?? 'Untitled',
    columnDataType:
      newQueryQuestion?.columnDataType ?? ColumnDataType.freeResponse,
    options: newQueryQuestion?.options,
    originalQuestion: newQueryQuestion?.text ?? '',
    width: DEFAULT_COLUMN_WIDTH,
    minWidth: MIN_COLUMN_WIDTH,
    type: FilterType.TEXT,
  }
  const existingColumnDefs = gridApi.getColumnDefs() ?? []
  const existingColumnDefsWithoutAddColumn = existingColumnDefs.filter(
    (columnDef) => {
      const hasField = 'field' in columnDef
      if (!hasField) return true
      return columnDef.field !== ADD_COLUMN_FIELD
    }
  )
  const addColumnDef = {
    field: ADD_COLUMN_FIELD,
    headerName: ADD_COLUMN_FIELD,
    type: ADD_COLUMN_FIELD,
    width: ADD_COLUMN_WIDTH_WITH_TEXT,
    maxWidth: ADD_COLUMN_WIDTH_WITH_TEXT,
    pinned: false as Pinned,
    hide: true,
  }

  const haveToPinAddColumn = updateAddColumnDef([
    ...existingColumnDefsWithoutAddColumn,
    newColumnDef,
    addColumnDef,
  ])

  const newColumnDefs = [
    ...existingColumnDefsWithoutAddColumn,
    newColumnDef,
    addColumnDef,
  ]

  gridApi.setGridOption('columnDefs', newColumnDefs)
  gridApi.ensureColumnVisible(haveToPinAddColumn ? id : ADD_COLUMN_FIELD, 'end')

  // 2. add to pending query questions
  addToPendingQueryQuestions(queryQuestion)

  // 3. scroll to the new column
  // if we have to pin the add column, then we scroll to the new column
  // otherwise, we scroll to the add column
  gridApi.ensureColumnVisible(id, 'end')

  return queryQuestion
}

const getDisplayDataType = (dataType: ColumnDataType) => {
  const dataTypeValue = COLUMN_DATA_TYPES_NOT_TO_BE_USED_FOR_QUESTION.includes(
    dataType
  )
    ? ColumnDataType.freeResponse
    : dataType === ColumnDataType.numeric
    ? 'number'
    : dataType === ColumnDataType.extraction
    ? 'verbatim'
    : dataType

  return capitalize(dataTypeValue.replace(/_/g, ' '))
}

const getPlaceholderForDataType = (dataType: ColumnDataType) => {
  switch (dataType) {
    case ColumnDataType.date:
      return 'What is the signing date of this agreement?'
    case ColumnDataType.duration:
      return 'What is the cure period in the event of a breach?'
    case ColumnDataType.classify:
      return 'Are the parties required to return or destroy any confidential information?s'
    case ColumnDataType.currency:
      return 'What is the purchase price of this agreement?'
    case ColumnDataType.numeric:
      return 'How many shares are being purchased?'
    case ColumnDataType.extraction:
      return 'What is the notice provision?'
    case ColumnDataType.freeResponse:
      return 'Who is the customer?'
  }
}

const getIconForDataType = (dataType: ColumnDataType) => {
  switch (dataType) {
    case ColumnDataType.date:
      return Calendar
    case ColumnDataType.duration:
      return Clock9
    case ColumnDataType.classify:
      return Waypoints
    case ColumnDataType.currency:
      return CircleDollarSign
    case ColumnDataType.numeric:
      return Hash
    case ColumnDataType.extraction:
      return Type
    case ColumnDataType.freeResponse:
    default:
      return Text
  }
}

const getDescriptionForDataType = (dataType: ColumnDataType) => {
  switch (dataType) {
    case ColumnDataType.date:
      return 'Extract a specific date from each document.'
    case ColumnDataType.duration:
      return 'Extract a period of time from each document.'
    case ColumnDataType.classify:
      return 'Classify each document into categories among a set of options, from Yes/No to more complicated choices.'
    case ColumnDataType.currency:
      return 'Extract a monetary value from each document.'
    case ColumnDataType.numeric:
      return 'Extract a number, including decimals or percentages, from each document.'
    case ColumnDataType.extraction:
      return 'Extract a specific text from each document.'
    case ColumnDataType.freeResponse:
      return 'Ask any question and get a text response for each document.'
  }
}

const getIllustrationForDataType = (dataType: ColumnDataType) => {
  switch (dataType) {
    case ColumnDataType.extraction:
      return extractionIllustration
    case ColumnDataType.classify:
      return classifyIllustration
    case ColumnDataType.date:
      return dateIllustration
    case ColumnDataType.duration:
      return durationIllustration
    case ColumnDataType.currency:
      return currencyIllustration
    case ColumnDataType.numeric:
      return numericIllustration
    case ColumnDataType.freeResponse:
      return freeResponseIllustration
    default:
      return undefined
  }
}

export {
  handleMouseEnter,
  handleMouseLeave,
  computeIsQueryLoading,
  getDisplayedRows,
  reorderColumnIds,
  createGridColumnDefs,
  addFilesToGrid,
  computeNextColumnId,
  updateAddColumnDef,
  shouldPinAddColumn,
  addNewColumn,
  getDisplayDataType,
  getPlaceholderForDataType,
  getIconForDataType,
  getDescriptionForDataType,
  getIllustrationForDataType,
}
