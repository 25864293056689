import React, { useEffect, useRef, useState } from 'react'

import { Table } from '@tanstack/react-table'
import {
  ChevronLeft,
  ChevronRight,
  ChevronsLeft,
  ChevronsRight,
} from 'lucide-react'

import { cn } from 'utils/utils'

import { Button } from 'components/ui/button'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/ui/select'

export const DataTablePageSizes = [10, 20, 30, 40, 50]

interface DataTablePaginationProps<TData> {
  table: Table<TData>
  children?: React.ReactNode
  isLoading?: boolean
}

export function DataTableFooter<TData>({
  table,
  children,
  isLoading = false,
}: DataTablePaginationProps<TData>) {
  const ref = useRef<HTMLDivElement>(null)
  const [shouldCollapse, setShouldCollapse] = useState(false)

  const handlePageSizeChange = (value: string) => {
    table.setPageSize(Number(value))
  }

  const handleFirstPageClick = () => {
    table.setPageIndex(0)
  }

  const handlePreviousPageClick = () => {
    table.previousPage()
  }

  const handleNextPageClick = () => {
    table.nextPage()
  }

  const handleLastPageClick = () => {
    table.setPageIndex(table.getPageCount() - 1)
  }

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      setShouldCollapse(entries[0].target.clientWidth < 640)
    })

    const currentRef = ref.current // Capture the current value of ref.current

    if (currentRef) {
      resizeObserver.observe(currentRef)
    }

    return () => {
      if (currentRef) {
        // Use the captured reference in the cleanup function
        resizeObserver.unobserve(currentRef)
      }
    }
  }, [ref]) // Depend on ref itself, not ref.current

  return (
    <div
      className={cn('mt-2 flex items-center justify-between gap-2 px-2', {
        'items-start': shouldCollapse,
      })}
      ref={ref}
    >
      <div className="flex grow">{children}</div>
      <div
        className={cn('flex items-center space-x-6 lg:space-x-8', {
          'flex-col items-end space-x-0 space-y-2 lg:space-x-0': shouldCollapse,
        })}
      >
        <div className="flex items-center space-x-2">
          <p className="text-nowrap text-sm">Rows per page</p>
          <Select
            value={`${table.getState().pagination.pageSize}`}
            onValueChange={handlePageSizeChange}
          >
            <SelectTrigger className="h-8 w-20">
              <SelectValue />
            </SelectTrigger>
            <SelectContent side="top">
              {DataTablePageSizes.map((pgSize) => (
                <SelectItem key={pgSize} value={`${pgSize}`}>
                  {pgSize}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        {isLoading ? (
          <p>Loading…</p>
        ) : (
          <div
            className={cn(
              'flex w-[100px] items-center justify-center text-sm',
              shouldCollapse && 'hidden'
            )}
          >
            Page {table.getState().pagination.pageIndex + 1} of{' '}
            {table.getPageCount() === 0 ? 1 : table.getPageCount()}
          </div>
        )}
        <div className="flex shrink-0 items-center space-x-2">
          <Button
            variant="outline"
            size="smIcon"
            onClick={handleFirstPageClick}
            disabled={!table.getCanPreviousPage()}
          >
            <span className="sr-only">Go to first page</span>
            <ChevronsLeft className="h-4 w-4" />
          </Button>
          <Button
            variant="outline"
            size="smIcon"
            onClick={handlePreviousPageClick}
            disabled={!table.getCanPreviousPage()}
          >
            <span className="sr-only">Go to previous page</span>
            <ChevronLeft className="h-4 w-4" />
          </Button>
          <Button
            variant="outline"
            size="smIcon"
            onClick={handleNextPageClick}
            disabled={!table.getCanNextPage()}
          >
            <span className="sr-only">Go to next page</span>
            <ChevronRight className="h-4 w-4" />
          </Button>
          <Button
            variant="outline"
            size="smIcon"
            onClick={handleLastPageClick}
            disabled={!table.getCanNextPage()}
          >
            <span className="sr-only">Go to last page</span>
            <ChevronsRight className="h-4 w-4" />
          </Button>
        </div>
      </div>
    </div>
  )
}

export default DataTableFooter
