import React from 'react'
import LoadingBar from 'react-top-loading-bar'

import cn from 'classnames'

interface FullscreenLoadingProps {
  isLoading: boolean
  progress?: number
  zIndex?: string
}

const FullscreenLoading: React.FC<FullscreenLoadingProps> = ({
  isLoading,
  progress,
  zIndex = 'z-20',
}) => {
  if (!isLoading) {
    if (typeof Cypress !== 'undefined') {
      // Code here will only run in the Cypress environment
      return null
    }
  }
  const progressValue = progress ?? (isLoading ? 65 : 99)

  return (
    <div
      className={cn(
        `pointer-events-none absolute inset-0 flex h-full w-screen items-center justify-center bg-primary/70 opacity-0 backdrop-blur ${zIndex}`,
        {
          'opacity-100': isLoading,
        }
      )}
      data-testid="fullscreen-loading"
    >
      <LoadingBar
        color="hsl(40 14% 5%)"
        // TODO: this is required to make sure that the loading lightsaber issue is not visible
        // This is needed because the loading bar is above the app-header
        shadow={false}
        progress={progressValue}
      />
    </div>
  )
}

export default FullscreenLoading
