import React from 'react'

import { Transition } from '@headlessui/react'

import { cn } from 'utils/utils'

interface BasicTransitionProps {
  children: React.ReactNode
  show: boolean
  className?: string
  entryTransitionDuration?: 'default' | 'fade' | 'slow'
  exitTransitionDuration?: 'default' | 'fade' | 'slow'
}

const BasicTransition: React.FC<BasicTransitionProps> = ({
  show,
  children,
  className,
  entryTransitionDuration = 'default',
}) => {
  let entryTransition = 'duration-100'
  if (entryTransitionDuration === 'fade') {
    entryTransition = 'duration-300'
  } else if (entryTransitionDuration === 'slow') {
    entryTransition = 'duration-500'
  }

  let exitTransition = 'duration-150'
  if (entryTransitionDuration === 'fade') {
    exitTransition = 'duration-300'
  } else if (entryTransitionDuration === 'slow') {
    exitTransition = 'duration-500'
  }

  return (
    <Transition
      show={show}
      enter={cn('transition-opacity', entryTransition)}
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave={cn('transition-opacity', exitTransition)}
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      className={className}
    >
      {children}
    </Transition>
  )
}

export default BasicTransition
