import React, { useState, useEffect } from 'react'

import _ from 'lodash'

import {
  QueryCapRule,
  levelToDisplayString,
  timeFrameToDisplayString,
  unitLevelToDisplayString,
} from 'models/query-cap-rule'
import { UserInfo } from 'models/user-info'
import { Workspace } from 'models/workspace'
import { QueryCapRuleLevel } from 'openapi/models/QueryCapRuleLevel'
import { QueryCapRuleTimeFrame } from 'openapi/models/QueryCapRuleTimeFrame'
import { QueryCapRuleUnitLevel } from 'openapi/models/QueryCapRuleUnitLevel'
import { Maybe } from 'types'

import { TaskType } from 'utils/task'
import { useAllTaskLabelLookup } from 'utils/task-definitions'
import { displayErrorMessage } from 'utils/toast'

import { Button } from 'components/ui/button'
import Combobox from 'components/ui/combobox/combobox'
import { Dialog, DialogContent } from 'components/ui/dialog'
import { Input } from 'components/ui/input'
import { Label } from 'components/ui/label'
import {
  MAX_REVIEW_QUESTIONS_LIMIT_FOR_VAULT_ADD_ON,
  MAX_REVIEW_FILES_LIMIT_FOR_VAULT_ADD_ON,
} from 'components/vault/utils/vault'
import { getQueryUsageStringWithUnit } from 'components/vault/utils/vault-usage-store'

interface QueryCapDialogProps {
  workspace: Workspace
  queryCapModalOpen: boolean
  setQueryCapModalOpen: (open: boolean) => void
  onQueryCapAdd: (queryCapRule: QueryCapRule) => Promise<void>
  onQueryCapEdit: (queryCapRule: QueryCapRule) => Promise<void>
  editQueryCapRule?: Maybe<QueryCapRule>
  userInfo?: UserInfo
}

const TaskTypeToValueRange: Partial<
  Record<TaskType, { min: number; max: number }>
> = {
  [TaskType.VAULT_REVIEW]: {
    min: 1,
    max: 1_000_000,
  },
}

const QueryCapDialog: React.FC<QueryCapDialogProps> = ({
  workspace,
  queryCapModalOpen,
  setQueryCapModalOpen,
  onQueryCapAdd,
  onQueryCapEdit,
  editQueryCapRule,
  userInfo,
}) => {
  const taskLabelLookup = useAllTaskLabelLookup(userInfo)
  const [level, setLevel] = useState<string>(editQueryCapRule?.level || '')
  const [taskType, setTaskType] = useState<string>(
    editQueryCapRule?.taskType || ''
  )
  const [timeFrame, setTimeFrame] = useState<string>(
    editQueryCapRule?.timeFrame || ''
  )
  const [unitLevel, setUnitLevel] = useState<string>(
    editQueryCapRule?.unitLevel || ''
  )
  const [value, setValue] = useState<number>(editQueryCapRule?.value || 0)

  const isEditing = !!editQueryCapRule

  useEffect(() => {
    setLevel(editQueryCapRule?.level || '')
    setTaskType(editQueryCapRule?.taskType || '')
    setTimeFrame(editQueryCapRule?.timeFrame || '')
    setUnitLevel(editQueryCapRule?.unitLevel || '')
    setValue(editQueryCapRule?.value || 0)
  }, [editQueryCapRule])

  const handleSubmit = async () => {
    if (!level || !taskType || !timeFrame || !unitLevel || !value) {
      displayErrorMessage('All fields are required')
      return
    }
    if (
      TaskTypeToValueRange[taskType as TaskType] &&
      (value < (TaskTypeToValueRange[taskType as TaskType]?.min ?? 0) ||
        value > (TaskTypeToValueRange[taskType as TaskType]?.max ?? 0))
    ) {
      displayErrorMessage(
        `Value must be between ${TaskTypeToValueRange[taskType as TaskType]
          ?.min} and ${TaskTypeToValueRange[taskType as TaskType]?.max}`
      )
      return
    }
    const queryCapRule: QueryCapRule = {
      id: editQueryCapRule?.id || '',
      level: level as QueryCapRuleLevel,
      workspaceId: editQueryCapRule?.workspaceId || workspace.id,
      userId: editQueryCapRule?.userId || userInfo?.dbId,
      taskType: taskType as TaskType,
      timeFrame: timeFrame as QueryCapRuleTimeFrame,
      unitLevel: unitLevel as QueryCapRuleUnitLevel,
      value,
    }
    if (isEditing) {
      await onQueryCapEdit(queryCapRule)
      setQueryCapModalOpen(false)
    } else {
      await onQueryCapAdd(queryCapRule)
      setQueryCapModalOpen(false)
    }
  }

  return (
    <Dialog open={queryCapModalOpen} onOpenChange={setQueryCapModalOpen}>
      <DialogContent showCloseIcon={false}>
        <div>
          <p className="text-xl">{isEditing ? 'Edit' : 'Add'} Query cap rule</p>
          <p className="text-sm text-muted">
            For <b>{userInfo ? userInfo.id : workspace.clientName ?? ''}</b>
          </p>
          <div className="mt-4">
            <Label htmlFor="level">Level</Label>
            <Combobox
              value={level}
              setValue={setLevel}
              inputPlaceholder="Enter level"
              defaultText="Select level"
              options={Object.keys(QueryCapRuleLevel)
                .map((level) => ({
                  value: level,
                  label: levelToDisplayString[level as QueryCapRuleLevel],
                }))
                .sort((a, b) => a.label.localeCompare(b.label))}
            />
          </div>
          <div className="mt-4">
            <Label htmlFor="task-type">Task type</Label>
            <Combobox
              value={taskType}
              setValue={setTaskType}
              inputPlaceholder="Enter task type"
              defaultText="Select task type"
              options={Object.keys(taskLabelLookup)
                // Only show vault review task type for now
                .filter((taskType) => taskType === TaskType.VAULT_REVIEW)
                .map((taskType) => ({
                  value: taskType,
                  label: taskLabelLookup[taskType],
                }))
                .sort((a, b) => a.label.localeCompare(b.label))}
            />
          </div>
          <div className="mt-4">
            <Label htmlFor="time-frame">Time frame</Label>
            <Combobox
              value={timeFrame}
              setValue={setTimeFrame}
              inputPlaceholder="Enter time frame"
              defaultText="Select time frame"
              options={Object.keys(QueryCapRuleTimeFrame)
                .map((timeFrame) => ({
                  value: timeFrame,
                  label:
                    timeFrameToDisplayString[
                      timeFrame as QueryCapRuleTimeFrame
                    ],
                }))
                .sort((a, b) => a.label.localeCompare(b.label))}
            />
          </div>
          <div className="mt-4">
            <Label htmlFor="unit-level">Unit level</Label>
            <Combobox
              value={unitLevel}
              setValue={setUnitLevel}
              inputPlaceholder="Enter unit level"
              defaultText="Select unit level"
              options={Object.keys(QueryCapRuleUnitLevel)
                .map((unitLevel) => ({
                  value: unitLevel,
                  label:
                    unitLevelToDisplayString[
                      unitLevel as QueryCapRuleUnitLevel
                    ],
                }))
                .sort((a, b) => a.label.localeCompare(b.label))}
            />
          </div>
          <div className="mt-4">
            <Label htmlFor="value">Value</Label>
            <div className="flex items-center space-x-2">
              <Input
                id="value"
                type="number"
                value={value.toString()}
                onChange={(e) => setValue(parseInt(e.target.value))}
                placeholder="Enter value"
                min={TaskTypeToValueRange[taskType as TaskType]?.min ?? 0}
                max={TaskTypeToValueRange[taskType as TaskType]?.max ?? 0}
                className="w-[200px]"
              />
              {taskType === TaskType.VAULT_REVIEW &&
                unitLevel === QueryCapRuleUnitLevel.CELL &&
                _.isNumber(value) &&
                value > 0 && (
                  <p className="text-sm text-muted">
                    {`Equals to ${getQueryUsageStringWithUnit(
                      value,
                      'review query',
                      MAX_REVIEW_QUESTIONS_LIMIT_FOR_VAULT_ADD_ON *
                        MAX_REVIEW_FILES_LIMIT_FOR_VAULT_ADD_ON
                    )}`}
                  </p>
                )}
            </div>
          </div>
          <div className="mt-6 flex flex-row justify-end space-x-2">
            <Button variant="ghost" onClick={() => setQueryCapModalOpen(false)}>
              Cancel
            </Button>
            <Button onClick={handleSubmit}>
              {isEditing ? 'Edit' : 'Add'} query cap rule
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default QueryCapDialog
