// Set an arbitrary const
const NUMBER_OF_RETRY = 2

// Function to perform a retry on failure
const retryOperation = async <T>(
  operation: () => Promise<T>,
  retries: number = NUMBER_OF_RETRY
): Promise<T> => {
  try {
    return await operation()
  } catch (error) {
    if (retries > 0) {
      console.warn(`Retrying operation, attempts remaining: ${retries}`)
      return retryOperation(operation, retries - 1)
    } else {
      throw error
    }
  }
}

// exports
export { retryOperation }
