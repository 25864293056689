import React, { useState, useMemo } from 'react'

import { CustomCellRendererProps } from 'ag-grid-react'
import { isEmpty } from 'lodash'
import { Folder, Dot, ChevronDown, ChevronRight } from 'lucide-react'
import { useShallow } from 'zustand/react/shallow'

import { cn } from 'utils/utils'

import { Button } from 'components/ui/button'
import { Icon } from 'components/ui/icon/icon'
import useVaultQueryDetailStore, {
  ReviewHistoryItem,
} from 'components/vault/query-detail/vault-query-detail-store'
import { useVaultDataGridFilterStore } from 'components/vault/utils/vault-data-grid-filters-store'
import { getFileCountHeaderString } from 'components/vault/utils/vault-helpers'

const VaultGroupRowRenderer = (props: CustomCellRendererProps) => {
  const { value: groupName, node } = props
  const childrenFileIds = node.allLeafChildren.map((child) => child.data.id)

  const currentAppliedFilters = useVaultDataGridFilterStore(
    useShallow((state) => state.currentAppliedFilters)
  )
  const [historyItem] = useVaultQueryDetailStore(
    useShallow((state) => [state.historyItem])
  )

  const reviewEvent = historyItem as ReviewHistoryItem
  const processedFileIds = useMemo(() => {
    return reviewEvent?.processedFileIds ?? []
  }, [reviewEvent])

  const groupPath = groupName.split(/(\/)/g)
  const isFolderIconHidden = groupPath.length === 1 && isEmpty(groupPath[0])

  const [isOpen, setIsOpen] = useState(node.expanded)

  const expandCollapseHandler = () => {
    const newIsOpen = !isOpen
    setIsOpen(newIsOpen)
    node.setExpanded(newIsOpen)
  }

  const numFilesInGroupDisplayText = useMemo(() => {
    const processedChildrenFileIds =
      processedFileIds.length > 0
        ? childrenFileIds.filter((id) => processedFileIds.includes(id))
        : undefined

    const visibleChildrenLength = node.allLeafChildren.filter(
      (child) => child.displayed
    ).length

    return getFileCountHeaderString({
      numFiles: childrenFileIds.length,
      processedFileIds: processedChildrenFileIds,
      isFilterActive: currentAppliedFilters.length > 0,
      visibleChildrenLength,
    })
  }, [
    childrenFileIds,
    processedFileIds,
    node.allLeafChildren,
    currentAppliedFilters,
  ])

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault() // Prevent scrolling when space is pressed
      expandCollapseHandler()
    }
  }

  return (
    <div
      className="flex h-full cursor-pointer items-end bg-accent pr-4"
      role="button"
      tabIndex={0}
      onKeyDown={handleKeyDown}
      onClick={expandCollapseHandler}
    >
      <Button
        size="xsIcon"
        className="m-1 shrink-0"
        variant="ghost"
        onClick={expandCollapseHandler}
      >
        {isOpen ? (
          <Icon icon={ChevronDown} size="small" />
        ) : (
          <Icon icon={ChevronRight} size="small" />
        )}
      </Button>
      <div className="mb-2 flex items-center space-x-1">
        {!isFolderIconHidden && <Icon icon={Folder} size="small" />}
        {groupPath.map((folderName: string, idx: number) => {
          return (
            <p
              key={idx}
              className={cn('truncate text-xs', {
                'text-muted': idx !== groupPath.length - 1,
              })}
            >
              {folderName}
            </p>
          )
        })}
        {!isFolderIconHidden && <Icon icon={Dot} size="small" />}
        <p className="text-xs text-muted">{numFilesInGroupDisplayText}</p>
      </div>
    </div>
  )
}

export default VaultGroupRowRenderer
