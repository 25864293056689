import React from 'react'

import { useShallow } from 'zustand/react/shallow'

import CompetativeAnalysisHeader from './components/header'
import Landing from './components/landing'
import ResultsTable from './components/results-table'

import { useCompetitiveAnalysisStore } from './stores/competitive-analysis-store'

const CompetitiveAnalysisLayout = () => {
  const excelUrl = useCompetitiveAnalysisStore(useShallow((s) => s.excelUrl))

  if (excelUrl) {
    return <ResultsTable />
  }

  return <Landing />
}

const CompetitiveAnalysis = () => {
  return (
    <>
      <CompetativeAnalysisHeader />
      <CompetitiveAnalysisLayout />
    </>
  )
}

export default CompetitiveAnalysis
