import _ from 'lodash'

import { ContractsCustomTerm } from 'openapi/models/ContractsCustomTerm'
import { ContractsDocument } from 'openapi/models/ContractsDocument'
import { ContractsSmartFilter } from 'openapi/models/ContractsSmartFilter'

export const ALL_FILTER = {
  label: 'View all',
  value: 'View all',
}

export interface GroupedTerms {
  [key: string]: string[]
}

// Returns all unique terms grouped by ContractsDocument.type
export const groupTermsByType = (
  documents: ContractsDocument[],
  customTerms: ContractsCustomTerm[]
): GroupedTerms => {
  const groupedTerms: any = {}

  documents
    .filter((doc) => !!doc.type)
    .forEach((item) => {
      if (!groupedTerms[item.type as string]) {
        groupedTerms[item.type as string] = []
      }

      item.specializedTerms.forEach((term) => {
        if (!groupedTerms[item.type as string].includes(term)) {
          groupedTerms[item.type as string].push(term)
        }
      })
    })

  // Add custom terms
  customTerms.forEach((term) => {
    if (!groupedTerms[term.contractType as string]) {
      groupedTerms[term.contractType as string] = []
    }

    if (!groupedTerms[term.contractType as string].includes(term.userInput)) {
      groupedTerms[term.contractType as string].push(term.userInput)
    }
  })

  Object.keys(groupedTerms).forEach((type) => {
    groupedTerms[type] = _.sortBy(groupedTerms[type])
  })

  return groupedTerms
}

export const getCommonExtractedTermKeys = (documents: ContractsDocument[]) => {
  if (documents.length === 0) return []

  let commonKeys: string[] = Object.keys(documents[0].extractedTerms)

  for (let i = 1; i < documents.length; i++) {
    const objKeys = Object.keys(documents[i].extractedTerms)
    commonKeys = commonKeys.filter((key) => objKeys.includes(key))
  }

  return commonKeys
}

export const getExtractedTermsForAllTypes = (
  documents: ContractsDocument[]
) => {
  const allTerms: Record<string, string[]> = {}

  // Get all unique document types
  const types = Array.from(new Set(documents.map((item) => item.type)))

  types
    .filter((type) => !!type)
    .forEach((type) => {
      allTerms[type as string] = getCommonExtractedTermKeys(
        documents.filter((doc) => doc.type === type)
      )
    })

  return allTerms
}

export const getFilteredDocuments = (
  documents: ContractsDocument[],
  filters: ContractsSmartFilter[]
) => {
  return documents.filter((doc) =>
    filters.every((filter) =>
      filter.results
        .filter((f) => f.include)
        .map((f) => f.fileName)
        .includes(doc.file.name)
    )
  )
}
