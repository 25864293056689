import React, { memo } from 'react'
import { Routes, Route } from 'react-router-dom'
import { useUnmount } from 'react-use'

import VaultReviewQuerySelectionDialog from './components/vault-review-query-selection-dialog'
import RedirectWithQuery from 'components/common/redirects/redirect-with-query'
import VaultCreateFolderDialog from 'components/vault/dialogs/vault-create-folder-dialog'
import VaultDeleteDialog from 'components/vault/dialogs/vault-delete-dialog'
import VaultRenameDialog from 'components/vault/dialogs/vault-rename-dialog'
import { useVaultStore } from 'components/vault/utils/vault-store'

import VaultAssistantModal from './dialogs/vault-assistant-modal'
import VaultMoveDialog from './dialogs/vault-move-dialog'
import VaultUploadFilesDialog from './dialogs/vault-upload-files-dialog'
import { newProjectPath, projectsPath, projectsPathRaw } from './utils/vault'
import useVaultStreamingHandler from './utils/vault-streaming-handler'
import VaultHome from './vault-home'
import VaultLayout from './vault-layout'
import VaultNewProject from './vault-new-project'
import VaultProjectLayout from './vault-project-layout'

const Vault = (): JSX.Element => {
  const setError = useVaultStore((state) => state.setError)

  const {
    generateQuestions,
    generateNNResponse,
    generateN1Response,
    sendCancelRequestN1,
  } = useVaultStreamingHandler()

  useUnmount(() => {
    setError(null)
  })

  return (
    <>
      <VaultCreateFolderDialog />
      <VaultUploadFilesDialog />
      <VaultDeleteDialog />
      <VaultRenameDialog />
      <VaultMoveDialog />
      <VaultAssistantModal />
      <VaultReviewQuerySelectionDialog />
      <Routes>
        <Route path="/" element={<VaultLayout />}>
          <Route index element={<RedirectWithQuery to={projectsPathRaw} />} />
          <Route path={projectsPath} element={<VaultHome />} />
          <Route path={newProjectPath} element={<VaultNewProject />} />
          <Route
            path={`${projectsPath}:projectId/*`}
            element={
              <VaultProjectLayout
                sendCancelRequestN1={sendCancelRequestN1}
                generateQuestions={generateQuestions}
                generateNNResponse={generateNNResponse}
                generateN1Response={generateN1Response}
              />
            }
          />
        </Route>
      </Routes>
    </>
  )
}

export default memo(Vault)
