import React, { useMemo, useState } from 'react'

import { useShallow } from 'zustand/react/shallow'

import { VaultFolderAccessPermission } from 'openapi/models/VaultFolderAccessPermission'
import { useSharingStore } from 'stores/sharing-store'
import {
  PermissionLevel,
  PermissionsByUser,
  PermissionsByWorkspace,
  SHARE_ERROR_MESSAGE,
  SHARE_SUCCESS_MESSAGE,
  ShareType,
} from 'types/sharing'

import { displayErrorMessage, displaySuccessMessage } from 'utils/toast'

import { useAuthUser } from 'components/common/auth-context'
import ConfirmationDialog from 'components/common/confirmation-dialog/confirmation-dialog'
import SharePopover from 'components/common/sharing/share-popover'
import { Dialog } from 'components/ui/dialog'
import useSharingPermissions from 'components/vault/hooks/use-sharing-permissions'
import {
  ShareVaultFolder,
  ShareVaultFolderParams,
  UpdatedWorkspaceAndUserSharingInfo,
  UpdateVaultFolderShare,
  UpdateVaultFolderShareParams,
} from 'components/vault/utils/vault-fetcher'
import { useVaultSharingStore } from 'components/vault/utils/vault-sharing-store'
import { useVaultStore } from 'components/vault/utils/vault-store'

const VaultShareButton = () => {
  const userInfo = useAuthUser()
  const [currentProject, currentProjectMetadata] = useVaultStore(
    useShallow((s) => [s.currentProject, s.currentProjectMetadata])
  )
  const [isFetchingFolderShareStatus, permissionsByProjectId] =
    useVaultSharingStore((s) => [
      s.isFetchingFolderShareStatus,
      s.permissionsByProjectId,
    ])
  const [setPermissionsForProjectId, appendPermissionsForProjectId] =
    useVaultSharingStore((s) => [
      s.setPermissionsForProjectId,
      s.appendPermissionsForProjectId,
    ])
  const sharingUsersForWorkspace = useSharingStore(
    (s) => s.sharingUsersForWorkspace
  )
  const setIsSharing = useSharingStore((s) => s.setIsSharing)

  const { canCurrentUserCreateShares } = useSharingPermissions({
    projectId: currentProject?.id,
  })

  const onChangeSharedWithWorkspace = async (sharedWithWorkspace: boolean) => {
    if (!currentProject) return
    const isWorkspaceAlreadyShared = permissionsByProjectId[
      currentProject.id
    ]?.permissionsByWorkspace?.some(
      (workspace) => workspace.workspaceId === userInfo.workspace.id
    )

    // do nothing if share setting is not changing
    if (sharedWithWorkspace === isWorkspaceAlreadyShared) {
      return
    }

    if (sharedWithWorkspace && !isWorkspaceAlreadyShared) {
      // if not previously shared, share vault folder with workspace
      try {
        setIsSharing(true)
        const response = await ShareVaultFolder({
          projectId: currentProject.id,
          shareWithWorkspaces: [
            {
              workspaceId: userInfo.workspace.id,
              permissionLevel: VaultFolderAccessPermission.VIEW,
            },
          ],
        })
        appendPermissionsForProjectId({
          projectId: currentProject.id,
          userId: userInfo.dbId,
          workspaceId: userInfo.workspace.id,
          permissions: response.shareStatus,
        })
        displaySuccessMessage(SHARE_SUCCESS_MESSAGE)
      } catch (error) {
        displayErrorMessage(SHARE_ERROR_MESSAGE)
      }
      setIsSharing(false)
      return
    }

    const updateParams: UpdateVaultFolderShareParams = {
      projectId: currentProject.id,
    }

    if (sharedWithWorkspace && isWorkspaceAlreadyShared) {
      updateParams.updateShareWithWorkspaces = [
        {
          workspaceId: userInfo.workspace.id,
          permissionLevel: VaultFolderAccessPermission.VIEW,
        },
      ]
    } else {
      updateParams.removeShareWithWorkspaces = [userInfo.workspace.id]
    }
    try {
      setIsSharing(true)
      const response = await UpdateVaultFolderShare(updateParams)
      setPermissionsForProjectId({
        projectId: currentProject.id,
        userId: userInfo.dbId,
        workspaceId: userInfo.workspace.id,
        permissions: response.shareStatus,
      })
      displaySuccessMessage(SHARE_SUCCESS_MESSAGE)
    } catch (error) {
      displayErrorMessage(SHARE_ERROR_MESSAGE)
    }
    setIsSharing(false)
  }

  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false)
  const [pendingUpdateParams, setPendingUpdateParams] =
    useState<UpdatedWorkspaceAndUserSharingInfo | null>(null)
  const isRemovingSelf = useMemo(() => {
    return pendingUpdateParams?.removeShareWithUsers?.includes(userInfo.dbId)
  }, [pendingUpdateParams, userInfo.dbId])
  const updateUserPermissions = async (
    params: UpdatedWorkspaceAndUserSharingInfo
  ) => {
    if (!currentProject) return
    try {
      setIsSharing(true)
      const response = await UpdateVaultFolderShare({
        projectId: currentProject.id,
        ...params,
      })
      setPermissionsForProjectId({
        projectId: currentProject.id,
        userId: userInfo.dbId,
        workspaceId: userInfo.workspace.id,
        permissions: response.shareStatus,
      })
      displaySuccessMessage(SHARE_SUCCESS_MESSAGE)
    } catch (error) {
      displayErrorMessage(SHARE_ERROR_MESSAGE)
    }
    setIsSharing(false)
  }

  const onUpdateSharePermissions = async (
    params: UpdatedWorkspaceAndUserSharingInfo
  ) => {
    const updateSelf = params.updateShareWithUsers?.find(
      (user) => user.userId === userInfo.dbId
    )
    if (
      params.removeShareWithUsers?.includes(userInfo.dbId) ||
      (updateSelf &&
        updateSelf.permissionLevel !== VaultFolderAccessPermission.FULL_ACCESS)
    ) {
      setPendingUpdateParams(params)
      setShowConfirmationDialog(true)
      return
    }
    await updateUserPermissions(params)
  }

  const onGrantUserPermissions = async (
    userIds: string[],
    permissionLevel: PermissionLevel
  ) => {
    if (!currentProject) return
    const params: ShareVaultFolderParams = {
      projectId: currentProject.id,
      shareWithUsers: userIds.map((userId) => ({
        userId,
        permissionLevel: permissionLevel as VaultFolderAccessPermission,
      })),
    }
    try {
      setIsSharing(true)
      const response = await ShareVaultFolder(params)
      appendPermissionsForProjectId({
        projectId: currentProject.id,
        userId: userInfo.dbId,
        workspaceId: userInfo.workspace.id,
        permissions: response.shareStatus,
      })
      displaySuccessMessage(SHARE_SUCCESS_MESSAGE)
    } catch (error) {
      displayErrorMessage(SHARE_ERROR_MESSAGE)
    }
    setIsSharing(false)
  }

  // If the current user can share the project, they can add new users to the project and
  // need sharingUsersForWorkspace to be loaded to do so
  const needsToLoadSharingUsersForWorkspace =
    sharingUsersForWorkspace.length === 0 && canCurrentUserCreateShares

  const isShareButtonDisabled =
    isFetchingFolderShareStatus || needsToLoadSharingUsersForWorkspace

  const shareButtonDisabledTooltip = isFetchingFolderShareStatus
    ? 'Fetching project share status'
    : needsToLoadSharingUsersForWorkspace
    ? 'Fetching workspace users eligible for sharing'
    : undefined

  if (!currentProject) return null

  return (
    <>
      <SharePopover
        shareType={ShareType.VAULT_PROJECT}
        isReadOnly={!canCurrentUserCreateShares}
        ownerUserEmail={currentProjectMetadata.userEmail ?? null}
        permissionsByWorkspace={
          (permissionsByProjectId[currentProject.id]?.permissionsByWorkspace ??
            []) as PermissionsByWorkspace
        }
        permissionsByUser={
          (permissionsByProjectId[currentProject.id]?.permissionsByUser ??
            []) as PermissionsByUser
        }
        onChangeSharedWithWorkspace={onChangeSharedWithWorkspace}
        onGrantUserPermissions={onGrantUserPermissions}
        onUpdateSharePermissions={onUpdateSharePermissions}
        disabled={isShareButtonDisabled}
        disabledTooltip={shareButtonDisabledTooltip}
      />
      <Dialog
        open={showConfirmationDialog}
        onOpenChange={setShowConfirmationDialog}
      >
        <ConfirmationDialog
          title={isRemovingSelf ? 'Remove access' : 'Update access'}
          description={
            isRemovingSelf
              ? 'Are you sure you want to remove your own access to this project?'
              : 'Are you sure you want to update your own access to this project? You will still be able to access this project but will not be able to manage anyone’s access to it.'
          }
          cta={{
            label: 'Confirm',
            onClick: async () => {
              if (pendingUpdateParams) {
                await updateUserPermissions(pendingUpdateParams)
              }
            },
          }}
          secondaryCta={{
            label: 'Cancel',
            onClick: () => {
              setShowConfirmationDialog(false)
            },
          }}
          variant="destructive"
          showCloseIcon={false}
        />
      </Dialog>
    </>
  )
}

export default VaultShareButton
