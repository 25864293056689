import { useEffect, useRef } from 'react'
import { useMount } from 'react-use'

import {
  useAnalytics,
  PAGE_VIEW_EVENT_NAME,
  useOptionalLocation,
} from './analytics-context'

const ENGAGEMENT_TIMEOUT_MS = 300000 // 5 minutes timeout to flush engagement data
const MOUSE_PRESENCE_ENGAGEMENT_INTERVAL_MS = 1000 // 1 second interval for mouse presence engagement

const EngagementTracker = () => {
  const { trackEvent } = useAnalytics()
  const { pathname } = useOptionalLocation()
  const prevPathname = useRef('')

  const engagementData = useRef({
    num_clicks: 0,
    num_keydown: 0,
    mouse_time_on_document_seconds: 0,
    duration_seconds: ENGAGEMENT_TIMEOUT_MS / 1000,
  })
  const isMouseOverDocument = useRef(false)
  const mousePresenceInterval = useRef<ReturnType<typeof setInterval> | null>(
    null
  )
  const isEngagementTrackerInitialized = useRef(false)
  const lastTrackTimestamp = useRef(Date.now())

  const clearEngagementData = () => {
    engagementData.current = {
      num_clicks: 0,
      num_keydown: 0,
      mouse_time_on_document_seconds: 0,
      duration_seconds: ENGAGEMENT_TIMEOUT_MS / 1000,
    }
  }

  const handleClick = () => {
    engagementData.current.num_clicks++
  }

  const handleKeydown = () => {
    engagementData.current.num_keydown++
  }

  const handleMouseEnter = () => {
    isMouseOverDocument.current = true
    if (!mousePresenceInterval.current) {
      mousePresenceInterval.current = setInterval(() => {
        if (isMouseOverDocument.current) {
          engagementData.current.mouse_time_on_document_seconds += 1
        }
      }, MOUSE_PRESENCE_ENGAGEMENT_INTERVAL_MS)
    }
  }

  const handleMouseLeave = () => {
    isMouseOverDocument.current = false
  }

  const trackEngagement = () => {
    const currentTime = Date.now()
    if (currentTime - lastTrackTimestamp.current >= ENGAGEMENT_TIMEOUT_MS) {
      trackEvent('User Engagement', engagementData.current)
      clearEngagementData()
      lastTrackTimestamp.current = currentTime
    }
  }

  useMount(() => {
    if (isEngagementTrackerInitialized.current) {
      return null
    }

    isEngagementTrackerInitialized.current = true

    window.addEventListener('click', handleClick)
    window.addEventListener('keydown', handleKeydown)
    document.addEventListener('mouseenter', handleMouseEnter)
    document.addEventListener('mouseleave', handleMouseLeave)

    const engagementInterval = setInterval(
      trackEngagement,
      MOUSE_PRESENCE_ENGAGEMENT_INTERVAL_MS
    )

    return () => {
      window.removeEventListener('click', handleClick)
      window.removeEventListener('keydown', handleKeydown)
      document.removeEventListener('mouseenter', handleMouseEnter)
      document.removeEventListener('mouseleave', handleMouseLeave)
      if (mousePresenceInterval.current) {
        clearInterval(mousePresenceInterval.current)
      }
      clearInterval(engagementInterval)
      isEngagementTrackerInitialized.current = false
    }
  })

  useEffect(() => {
    if (prevPathname.current !== pathname) {
      trackEvent(PAGE_VIEW_EVENT_NAME, { path: pathname })
      prevPathname.current = pathname
    }
  }, [pathname, trackEvent])

  return null
}

export default EngagementTracker
