import React from 'react'

import _ from 'lodash'

import { useAllTaskLabelLookup } from 'utils/task-definitions'

import {
  LibraryFilterComponent,
  UseFilterComponentsProps,
} from './filter-components'
import { useAuthUser } from 'components/common/auth-context'
import CategoriesFilter from 'components/filter/instances/category-filter'
import GenericSelectFilter from 'components/filter/instances/generic-multi-select-filter'
import SearchFilter from 'components/filter/instances/search-filter'
import StarredFilter from 'components/filter/instances/starred-filter'
import TaskTypeFilter from 'components/filter/instances/task-type-filter'

import { LibraryFilterKey } from './library-filter-store'
import { useLibraryMetadataStore } from './library-metadata-store'
import { Prompt } from './library-types'

export const usePromptsFilterComponents = ({
  items,
}: UseFilterComponentsProps<Prompt>) => {
  const userInfo = useAuthUser()
  const taskLabelLookup = useAllTaskLabelLookup(userInfo)
  const getFavoriteStatus = useLibraryMetadataStore((s) => s.getFavoriteStatus)

  const [allTaskTypeStrings, sortedCategories, sortedPracticeAreas] =
    React.useMemo(() => {
      const allTaskTypeStrings = new Set()
      const allCategories = new Set()
      const allPracticeAreas = new Set()

      Object.values(items).forEach((item) => {
        if (item.eventKind.toString())
          allTaskTypeStrings.add(item.eventKind.toString())
        if (item.categories)
          item.categories.forEach((c) => allCategories.add(c))
        if (item.practiceAreas)
          item.practiceAreas.forEach((p) => allPracticeAreas.add(p))
      })

      const sortedCategories = _.sortBy(
        Array.from(allCategories).map((category) => ({
          text: category,
          value: category,
        })),
        'text'
      )
      const sortedPracticeAreas = _.sortBy(
        Array.from(allPracticeAreas).map((practiceArea) => ({
          text: practiceArea,
          value: practiceArea,
        })),
        'text'
      )

      return [allTaskTypeStrings, sortedCategories, sortedPracticeAreas]
    }, [items])

  const sortedTaskTypes = React.useMemo(
    () =>
      _.sortBy(
        Object.keys(taskLabelLookup)
          .filter((taskType) => allTaskTypeStrings.has(taskType))
          .map((taskType) => ({
            text: taskLabelLookup[taskType],
            value: taskType,
          })),
        'text'
      ),
    [taskLabelLookup, allTaskTypeStrings]
  )

  return React.useMemo(() => {
    return [
      {
        Component: SearchFilter,
        filterKey: LibraryFilterKey.SEARCH,
        leading: true,
        placeholderText: 'Search prompts',
      },
      {
        Component: StarredFilter,
        filterKey: LibraryFilterKey.STARRED,
        isStarred: getFavoriteStatus as (record: Prompt) => boolean,
      },
      {
        Component: TaskTypeFilter,
        filterKey: LibraryFilterKey.TASK_TYPE,
        userInfo,
        taskLabelLookup,
        sortedTaskTypes,
        entityName: 'library table',
      },
      {
        Component: CategoriesFilter,
        filterKey: LibraryFilterKey.CATEGORY,
        sortedCategories,
      },
      {
        Component: GenericSelectFilter,
        filterKey: LibraryFilterKey.PRACTICE_AREA,
        sortedItems: sortedPracticeAreas,
        displayName: 'Practice areas',
        entityName: 'library table',
        accessor: (record: Prompt) => record.practiceAreas,
      },
    ] as LibraryFilterComponent<Prompt>[]
  }, [
    getFavoriteStatus,
    taskLabelLookup,
    sortedTaskTypes,
    sortedCategories,
    sortedPracticeAreas,
    userInfo,
  ])
}
