import React from 'react'

interface OneDriveIconProps {
  className?: string
}

const OneDriveIcon: React.FC<OneDriveIconProps> = ({ className = '' }) => {
  return (
    <img className={className} src="/img/logos/onedrive.svg" alt="OneDrive" />
  )
}

export default OneDriveIcon
