import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'

import { LibraryVisbilityScope } from 'openapi/models/LibraryVisbilityScope'
import Services from 'services'

import { displayErrorMessage } from 'utils/toast'

import { LibraryItem, Example, Prompt, LibraryItemKind } from './library-types'

interface LibraryItemState<T extends LibraryItem> {
  isLoading: boolean
  lastUpdatedTime: Date | null
  items: Record<string, T>
}

interface LibraryItemActions<T extends LibraryItem> {
  setIsLoading: (isLoading: boolean) => void
  updateLastUpdatedTime: () => void
  setItems: (items: T[]) => void
  updateVisibility: (
    itemId: string,
    visible: LibraryVisbilityScope
  ) => Promise<void>
  updateName: (itemId: string, name: string) => Promise<void>
}

export function createLibraryItemStore<T extends LibraryItem>(
  libraryItemKind: LibraryItemKind
) {
  const libraryResource = libraryItemKind.toLowerCase()
  return create<LibraryItemState<T> & LibraryItemActions<T>>()(
    devtools(
      immer((set, get) => ({
        isLoading: false,
        lastUpdatedTime: null,
        items: {},
        setIsLoading: (isLoading: boolean) => set(() => ({ isLoading })),
        updateLastUpdatedTime: () =>
          set(() => ({
            lastUpdatedTime: new Date(),
          })),
        setItems: (items: T[]) =>
          set(() => ({
            items: Object.fromEntries(items.map((item) => [item.id, item])),
          })),
        updateVisibility: async (
          itemId: string,
          visibilityScope: LibraryVisbilityScope
        ) => {
          const previousVisibility = get().items[itemId].visibilityScope

          set((state) => {
            state.items[itemId].visibilityScope = visibilityScope
          })

          try {
            await Services.Backend.Patch(
              `library/${libraryResource}/${itemId}/visibility`,
              { visibilityScope },
              { throwOnError: true }
            )
          } catch (error) {
            set((state) => {
              state.items[itemId].visibilityScope = previousVisibility
            })
            displayErrorMessage(
              'Failed to update visibility. Please try again.'
            )
          }
        },
        updateName: async (itemId: string, name: string) => {
          const previousName = get().items[itemId].name
          set((state) => {
            state.items[itemId].name = name
          })

          try {
            await Services.Backend.Patch(
              `library/${libraryResource}/${itemId}/name`,
              { name },
              { throwOnError: true }
            )
          } catch (error) {
            set((state) => {
              state.items[itemId].name = previousName
            })
            displayErrorMessage('Failed to update name. Please try again.')
          }
        },
      }))
    )
  )
}

export const useExampleItemsStore = createLibraryItemStore<Example>(
  LibraryItemKind.EXAMPLE
)
export const usePromptItemsStore = createLibraryItemStore<Prompt>(
  LibraryItemKind.PROMPT
)
