import React from 'react'

import { CustomCellRendererProps } from 'ag-grid-react'
import { useShallow } from 'zustand/react/shallow'

import { cn } from 'utils/utils'

import { Checkbox } from 'components/ui/checkbox'
import { useCompetitiveAnalysisDataGridStore } from 'components/workflows/workflow/competitive-analysis/stores/competitive-analysis-data-grid-store'

const RowNumberCell = (props: CustomCellRendererProps) => {
  const { node, value } = props

  const selectedRows = useCompetitiveAnalysisDataGridStore(
    useShallow((s) => s.selectedRows)
  )

  const addSelectedRow = useCompetitiveAnalysisDataGridStore(
    (s) => s.addSelectedRow
  )
  const removeSelectedRow = useCompetitiveAnalysisDataGridStore(
    (s) => s.removeSelectedRow
  )

  const isSelected = selectedRows.includes(node.data.id)

  const onCheckedChange = () => {
    const nextIsSelectedValue = !isSelected
    if (nextIsSelectedValue) {
      addSelectedRow(node.data.id)
    } else {
      removeSelectedRow(node.data.id)
    }
    node.setSelected(nextIsSelectedValue)
  }

  return (
    <div className="group relative flex h-full w-full items-center justify-center px-4 text-left text-xs text-muted">
      <Checkbox
        checked={isSelected}
        onCheckedChange={onCheckedChange}
        className={cn(
          'absolute inset-0 flex h-full w-full items-center justify-center',
          {
            'opacity-100': isSelected,
            'opacity-0 group-hover:opacity-100': !isSelected,
          }
        )}
      />
      <p
        className={cn(
          'pointer-events-none absolute inset-0 flex h-full w-full items-center justify-center text-xs group-hover:opacity-0',
          {
            'opacity-100': !isSelected,
            'opacity-0 group-hover:opacity-0': isSelected,
          }
        )}
      >
        {value}
      </p>
    </div>
  )
}

export default RowNumberCell
