import { createContext, useContext } from 'react'

import { UserInfo } from 'models/user-info'

interface AuthContextType {
  userInfo: UserInfo
}

export const AuthContext = createContext<AuthContextType | null>(null)

export const useAuthContext = () => {
  const authContext = useContext(AuthContext)
  if (!authContext) {
    throw new Error(
      'useAuthContext must be called within <AuthContext.Provider>'
    )
  }
  return authContext
}

export const useAuthUser = () => {
  const authContext = useAuthContext()
  return authContext.userInfo
}
