import React from 'react'

import _ from 'lodash'

import Services from 'services'

import { cn } from 'utils/utils'

import {
  FILTER_CHANGED_EVENT_NAME,
  AnalyticsContextType,
} from 'components/common/analytics/analytics-context'
import { MultiSelect } from 'components/ui/multi-select'

interface Props extends AnalyticsContextType {
  filterKey: string
  accessor: (record: any) => string
  displayName?: string
  value?: string
  setValue: (value: string) => void
  labelLookup?: Record<string, string>
  sortedItems?: Array<{ text: string; value: string }>
  metricName?: string
  placeholder?: string
  widthClass?: string
  align?: 'start' | 'center' | 'end'
  entityName?: string
}

const GenericMultiSelectFilter = ({
  filterKey,
  accessor,
  displayName,
  value,
  setValue,
  labelLookup = {},
  sortedItems = [],
  metricName = 'ui.generic_select_filter',
  placeholder = 'Select…',
  widthClass = 'w-40',
  align = 'end',
  entityName,
  trackEvent,
}: Props) => {
  const selectedValues = value?.split(',') || []
  const selectedLabels = new Set(
    selectedValues.map((val) => labelLookup[val] || val)
  )

  const handleSetSelectedValues = (selectedValues: string[]) => {
    Services.HoneyComb.Record({
      metric: metricName,
      display_name: displayName,
      values: selectedValues.join(','),
    })
    trackEvent(FILTER_CHANGED_EVENT_NAME, {
      entity_name: entityName || 'generic multiselect',
      display_name: displayName,
      values: selectedValues.join(','),
    })
    setValue(selectedValues.join(','))
  }

  const render = () => (
    <MultiSelect
      key={filterKey}
      placeholder={placeholder}
      sortedEntries={sortedItems}
      selectedValues={selectedValues}
      setSelectedValues={handleSetSelectedValues}
      className={cn(widthClass, {
        'text-muted': _.isEmpty(selectedValues),
      })}
      align={align}
    />
  )

  const filterLogic = (record: any) => {
    if (selectedValues.length === 0) return true

    const accessorValue = accessor(record)

    if (Array.isArray(accessorValue)) {
      return accessorValue.some((value) => selectedLabels.has(value))
    }

    return selectedLabels.has(accessorValue)
  }

  return { filterKey, displayName, render, filterLogic }
}

export default GenericMultiSelectFilter
