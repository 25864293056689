import { useEffect } from 'react'

import { HarvQueryKeyPrefix } from 'models/queries/all-query-keys'
import { useWrappedQuery } from 'models/queries/lib/use-wrapped-query'

import { FetchVaultWorkflows } from 'components/vault/utils/vault-fetcher'

import useVaultWorkflowStore from './vault-workflow-store'

export const useVaultWorkflows = () => {
  const setWorkflows = useVaultWorkflowStore((state) => state.setWorkflows)

  const { data, isFetching } = useWrappedQuery({
    queryKey: [HarvQueryKeyPrefix.VaultWorkflows],
    queryFn: FetchVaultWorkflows,
  })

  useEffect(() => {
    if (data) {
      setWorkflows(data.workflows)
    }
  }, [data, setWorkflows])

  return { workflows: data?.workflows, isFetching }
}
