import React from 'react'
import { useState } from 'react'

import emailAddresses from 'email-addresses'
import _ from 'lodash'

import {
  displayErrorMessage,
  displaySuccessMessage,
  displayWarningMessage,
} from 'utils/toast'

import { Button } from 'components/ui/button'
import { Textarea } from 'components/ui/text-area'

const ExperimentMultiUserManagement = ({
  experimentId,
  modifyExperimentUsers,
  buttonText,
}: {
  experimentId: string
  modifyExperimentUsers: (
    experimentId: string,
    userEmails: string[]
  ) => Promise<string[]> // emails
  buttonText: string
}) => {
  const [emails, setEmails] = useState('')
  const [isModifying, setIsModifying] = useState(false)

  const handleModifyUsers = async (): Promise<void> => {
    setIsModifying(true)
    try {
      const cleanedEmails = emails
        .split('\n')
        .map((email: string) => email.trim())
        .filter((email: string) => !_.isEmpty(email))
      const [validEmails, invalidEmails] = _.partition(
        cleanedEmails,
        (email: string) => emailAddresses.parseOneAddress(email)
      )
      if (!_.isEmpty(invalidEmails)) {
        displayWarningMessage(
          `Ignoring invalid emails: ${invalidEmails.join(', ')}`
        )
      }
      if (!_.isEmpty(validEmails)) {
        const modifiedUserEmails = await modifyExperimentUsers(
          experimentId,
          validEmails
        )
        const numUsersModified = modifiedUserEmails?.length || 0
        if (numUsersModified == 0) {
          displayWarningMessage('No experiment users modified')
        } else {
          displaySuccessMessage(
            `${numUsersModified} experiment users modified successfully`
          )
        }
      }
    } catch (errorInfo) {
      displayErrorMessage(`Error modifying experiment users ${errorInfo}`)
    }
    setIsModifying(false)
  }

  return (
    <div className="flex flex-col space-y-4">
      <div>
        <Textarea
          name="emails"
          placeholder="Enter user emails, one email per line"
          value={emails}
          onChange={(e) => setEmails(e.target.value)}
        />
      </div>
      <div>
        <Button
          className="float-right mt-4"
          variant="secondary"
          disabled={isModifying}
          onClick={handleModifyUsers}
        >
          {buttonText}
        </Button>
      </div>
    </div>
  )
}

export default ExperimentMultiUserManagement
