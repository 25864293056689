import React from 'react'
import { useEffect } from 'react'

import Cookies from 'js-cookie'

import { Announcement } from 'components/common/announcements/announcement.types'

/*
  This function sets a cookie to indicate that a user has seen and dismissed an announcement
*/
const setHasDismissedCookie = (anmt: Announcement) => {
  const cookieKey = `has-seen-announcement-${anmt.announcementKey}`
  Cookies.set(cookieKey, 'true', { expires: 365 })
}

/*
    This function gets a cookie to indicate that a user has seen and dismissed an announcement
  */
const getHasDismissedCookie = (anmt: Announcement) => {
  const cookieKey = `has-seen-announcement-${anmt.announcementKey}`
  return Cookies.get(cookieKey)
}

/*
  This function sets a cookie to indicate that a user was shown an announcement
*/
const setWasShownCookie = (anmt: Announcement) => {
  const cookieKey = `was-shown-announcement-${anmt.announcementKey}`
  Cookies.set(cookieKey, 'true', { expires: 365 })
}

/*
    This function gets a cookie to indicate that a user was shown an announcement
  */
const getWasShownCookie = (anmt: Announcement) => {
  const cookieKey = `was-shown-announcement-${anmt.announcementKey}`
  return Cookies.get(cookieKey)
}

/*
  This hook checks if an announcement has been dismissed by the user, and provides function to dismiss
*/
const useIsDismissed = (announcement: Announcement) => {
  const [isDismissed, setIsDismissed] = React.useState(false)
  const [wasShown, setWasShown] = React.useState(false)

  useEffect(() => {
    const wasShownAnnouncement = getWasShownCookie(announcement)
    if (wasShownAnnouncement) {
      setWasShown(true)
    }
    const hasDismissedAnnouncement = getHasDismissedCookie(announcement)
    if (hasDismissedAnnouncement) {
      setIsDismissed(true)
    }
  }, [announcement])

  const dismiss = () => {
    setHasDismissedCookie(announcement)
    setIsDismissed(true)
  }

  const show = () => {
    setWasShownCookie(announcement)
    setWasShown(true)
  }

  return { isDismissed, dismiss, wasShown, show }
}

// exports
export { useIsDismissed, getHasDismissedCookie }
