import React, { useCallback, useState } from 'react'

import {
  DEFAULT_VAULT_FILES_COUNT_LIMIT,
  setVaultEnablement,
  setVaultSettings,
  VaultSettingsObject,
  Workspace,
} from 'models/workspace'
import { WorkspaceFeature } from 'openapi/models/WorkspaceFeature'

import { displayErrorMessage, displaySuccessMessage } from 'utils/toast'

import { Button } from 'components/ui/button'
import { Dialog, DialogContent, DialogFooter } from 'components/ui/dialog'

import VaultEditOption from './vault-edit-option'

const DEFAULT_VAULT_REVIEW_QUESTIONS_COUNT_LIMIT = 50
const DEFAULT_VAULT_PROJECTS_COUNT_LIMIT = 10

interface VaultReviewPackDialogProps {
  type: 'add' | 'edit'
  open: boolean
  onOpenChange: (open: boolean) => void
  workspace: Workspace
  onEnable: () => Promise<void>
}

const VaultReviewPackDialog = ({
  type,
  open,
  onOpenChange,
  workspace,
  onEnable,
}: VaultReviewPackDialogProps) => {
  const vaultSettings: VaultSettingsObject = workspace.vaultReviewPackSettings

  const [
    vaultReviewQuestionsCountLimitPerQuery,
    setVaultReviewQuestionsCountLimitPerQuery,
  ] = useState<number>(
    vaultSettings.vault_review_questions_count_limit_per_query ??
      DEFAULT_VAULT_REVIEW_QUESTIONS_COUNT_LIMIT
  )
  const [vaultProjectsCountLimitPerUser, setVaultProjectsCountLimitPerUser] =
    useState<number>(
      vaultSettings.vault_projects_count_limit_per_user ??
        DEFAULT_VAULT_PROJECTS_COUNT_LIMIT
    )
  const [vaultFilesCountLimitPerProject, setVaultFilesCountLimitPerProject] =
    useState<number>(
      vaultSettings.vault_files_count_limit_per_project ??
        DEFAULT_VAULT_FILES_COUNT_LIMIT
    )
  const [
    vaultReviewFilesCountLimitPerWorkspacePerYear,
    setVaultReviewFilesCountLimitPerWorkspacePerYear,
  ] = useState<number>(
    vaultSettings.vault_review_files_count_limit_per_workspace_per_year ?? 0
  )

  const vaultSettingSetter = useCallback(async () => {
    const vaultSettings = {
      vaultReviewQuestionsCountLimitPerQuery,
      vaultProjectsCountLimitPerUser,
      vaultFilesCountLimitPerProject,
      vaultReviewFilesCountLimitPerWorkspacePerYear,
    }
    return setVaultSettings(
      workspace.id,
      vaultSettings,
      WorkspaceFeature.VAULT_REVIEW_PACK
    )
  }, [
    vaultReviewQuestionsCountLimitPerQuery,
    vaultProjectsCountLimitPerUser,
    vaultFilesCountLimitPerProject,
    vaultReviewFilesCountLimitPerWorkspacePerYear,
    workspace.id,
  ])

  const onAdd = async () => {
    // API call to enable vault review pack
    const enabled = await setVaultEnablement(
      WorkspaceFeature.VAULT_REVIEW_PACK,
      workspace.id,
      true
    )

    if (enabled) {
      const updated = await onSetVaultSettings(true)
      if (!updated) {
        const disabled = await setVaultEnablement(
          WorkspaceFeature.VAULT_REVIEW_PACK,
          workspace.id,
          false
        )
        if (!disabled) {
          displayErrorMessage('Failed to disable vault review pack')
        }
      }
    } else {
      displayErrorMessage('Failed to enable vault review pack')
    }
  }

  const onSetVaultSettings = async (initialAdd: boolean) => {
    try {
      await vaultSettingSetter()
      await onEnable()
      onOpenChange(false)
      displaySuccessMessage(
        initialAdd
          ? 'Vault review pack enabled successfully'
          : 'Vault review pack updated successfully'
      )
      return true
    } catch (e) {
      displayErrorMessage(
        e instanceof Error ? e.message : 'Failed to set vault settings'
      )
      return false
    }
  }

  const buttonDisabled =
    isNaN(vaultReviewFilesCountLimitPerWorkspacePerYear) ||
    vaultReviewFilesCountLimitPerWorkspacePerYear < 1 ||
    isNaN(vaultReviewQuestionsCountLimitPerQuery) ||
    isNaN(vaultProjectsCountLimitPerUser) ||
    isNaN(vaultFilesCountLimitPerProject)

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent showCloseIcon={false}>
        {type === 'add' ? (
          <p className="text-xl">
            Enable vault review pack for {workspace.friendlyName}
          </p>
        ) : (
          <p className="text-xl">
            Edit vault review pack settings for {workspace.friendlyName}
          </p>
        )}

        {type === 'add' && (
          <p>
            This will create a new vault review pack role for the workspace with
            appropriate permission
          </p>
        )}

        <VaultEditOption
          label="Annual review files limit per workspace"
          value={vaultReviewFilesCountLimitPerWorkspacePerYear}
          setValue={setVaultReviewFilesCountLimitPerWorkspacePerYear}
          initialEditing
        />
        <VaultEditOption
          label="Vault projects count limit per user"
          value={vaultProjectsCountLimitPerUser}
          setValue={setVaultProjectsCountLimitPerUser}
        />
        <VaultEditOption
          label="Vault files count limit per project"
          value={vaultFilesCountLimitPerProject}
          setValue={setVaultFilesCountLimitPerProject}
        />
        <VaultEditOption
          label="Vault review questions count limit per query"
          value={vaultReviewQuestionsCountLimitPerQuery}
          setValue={setVaultReviewQuestionsCountLimitPerQuery}
        />
        <DialogFooter>
          <Button variant="secondary" onClick={() => onOpenChange(false)}>
            Cancel
          </Button>
          <Button
            disabled={buttonDisabled}
            onClick={async () => {
              if (type === 'add') {
                await onAdd()
              } else {
                await onSetVaultSettings(false)
              }
            }}
          >
            {type === 'add' ? 'Enable' : 'Update'} Vault Review Pack
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default VaultReviewPackDialog
