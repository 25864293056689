import { GridApi } from 'ag-grid-community'

import { ColumnDataType, ReviewAnswer } from 'components/vault/utils/vault'
import { FilterType } from 'components/vault/utils/vault-data-grid-filters-store'

import { ColumnFilter } from './column-filter-types'
import { TextColumnFilter } from './text-column-filter'

export class NumericColumnFilter extends ColumnFilter {
  getUniqueColumnValues(
    gridApi: GridApi,
    currentFilterQuestionId: string
  ): string[] {
    const uniqueNumbersSet = new Set<number>()
    let hasDataTypeMismatch = false
    gridApi.forEachNode((node) => {
      if (node.group) return
      const answer = (node.data.answers as ReviewAnswer[]).find(
        (answer) => answer.columnId === currentFilterQuestionId
      )

      if (answer != null) {
        if (answer.columnDataType === ColumnDataType.numeric) {
          const number = parseFloat(answer.text)
          if (!isNaN(number)) {
            uniqueNumbersSet.add(number)
          }
        } else {
          hasDataTypeMismatch = true
        }
      }
    })

    // if any answers are non-numeric column type, use text column filter instead
    if (hasDataTypeMismatch) {
      return new TextColumnFilter().getUniqueColumnValues(
        gridApi,
        currentFilterQuestionId
      )
    }

    return Array.from(uniqueNumbersSet)
      .sort((a, b) => a - b)
      .map((number) => {
        const formatter = new Intl.NumberFormat(navigator.language, {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
          useGrouping: true,
        })
        return formatter.format(number)
      })
  }

  getFilterType(): FilterType {
    return FilterType.NUMERIC
  }
}
