import React from 'react'

import { Spinner } from 'components/ui/spinner'

interface ExtractionInProgressProps {
  isInProgress: boolean
  message: string
}

const ExtractionInProgress: React.FC<ExtractionInProgressProps> = ({
  isInProgress,
  message,
}) => {
  if (!isInProgress) return null

  return (
    <div className="flex h-8 items-center justify-end pr-2">
      <Spinner className="mr-2 h-3 w-3" />
      <p>{message}</p>
    </div>
  )
}

export default ExtractionInProgress
