import React, { useCallback } from 'react'
import { useState } from 'react'

import emailAddresses from 'email-addresses'
import { CheckCircle, Loader2, XCircle } from 'lucide-react'

import { Experiment } from 'openapi/models/Experiment'
import { UserEvaluation } from 'openapi/models/UserEvaluation'

import { readableFormat } from 'utils/date-utils'
import { displayErrorMessage } from 'utils/toast'
import { parseIsoString } from 'utils/utils'

import { SettingsPath } from 'components/base-app-path'
import { Button } from 'components/ui/button'
import { Card, CardContent, CardHeader, CardTitle } from 'components/ui/card'
import { Input } from 'components/ui/input'
import { Label } from 'components/ui/label'
import { ScrollArea } from 'components/ui/scroll-area'
import { Separator } from 'components/ui/separator'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from 'components/ui/table'

import {
  FetchAllUserEvaluations,
  FetchExperimentsForUser,
} from './utils/experiment-fetcher'

interface ExperimentWithUserEvaluations {
  experiment: Experiment
  userEvaluations: UserEvaluation[]
}

const ExperimentUserInspectorCard = () => {
  const [userEmail, setUserEmail] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [
    userExperimentsWithUserEvaluations,
    setUserExperimentsWithUserEvaluations,
  ] = useState<ExperimentWithUserEvaluations[]>([])

  const handleSubmit = useCallback(async () => {
    setIsLoading(true)
    try {
      if (!emailAddresses.parseOneAddress(userEmail)) {
        displayErrorMessage('Please enter a valid email')
        setUserExperimentsWithUserEvaluations([])
      } else {
        const experiments = await FetchExperimentsForUser(userEmail)
        const experimentsWithUserEvaluations = await Promise.all(
          experiments.map(async (experiment) => {
            const userEvaluations = await FetchAllUserEvaluations(
              experiment.id,
              userEmail
            )
            return { experiment, userEvaluations }
          })
        )
        setUserExperimentsWithUserEvaluations(experimentsWithUserEvaluations)
      }
    } catch (error) {
      displayErrorMessage(`Error fetching user experiments: ${error}`)
      setUserExperimentsWithUserEvaluations([])
    } finally {
      setIsLoading(false)
    }
  }, [userEmail])

  const handleReset = () => {
    setUserExperimentsWithUserEvaluations([])
    setUserEmail('')
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle>
          <div className="font-semibold">Experiment participant inspector</div>
        </CardTitle>
      </CardHeader>
      <CardContent>
        <Label>
          {' '}
          User Email
          <Input
            type="text"
            placeholder="e.g. user@harvey.ai"
            value={userEmail}
            onChange={(e) => setUserEmail(e.target.value)}
          />
        </Label>
        <div className="flex justify-start gap-2">
          <Button
            className="mt-4"
            variant="secondary"
            onClick={handleSubmit}
            disabled={isLoading}
          >
            {isLoading ? (
              <Loader2 className="h-4 w-4 animate-spin" />
            ) : (
              'Inspect'
            )}
          </Button>
          {userExperimentsWithUserEvaluations.length > 0 && (
            <Button className="mt-4" variant="secondary" onClick={handleReset}>
              Reset
            </Button>
          )}
        </div>
        {!isLoading && userExperimentsWithUserEvaluations.length > 0 && (
          <>
            <Separator className="my-4" />
            <ScrollArea hasHorizontalScroll className="w-full">
              {/* NOTE can add pagination here later with data table */}
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>Name</TableHead>
                    <TableHead>Slug</TableHead>
                    <TableHead>Added at</TableHead>
                    <TableHead>Progress</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {userExperimentsWithUserEvaluations.map(
                    ({ experiment, userEvaluations }) => {
                      const numCompletedUserEvals = userEvaluations.filter(
                        (ue) => ue.isComplete
                      ).length
                      const numTotalUserEvals = userEvaluations.length
                      return (
                        <TableRow
                          key={experiment.id}
                          className="cursor-pointer"
                          onClick={() =>
                            window.open(
                              `${SettingsPath.InternalAdminExperimentManagement}/${experiment.slug}`,
                              '_blank'
                            )
                          }
                        >
                          <TableCell>{experiment.name}</TableCell>
                          <TableCell>{experiment.slug}</TableCell>
                          <TableCell>
                            {readableFormat(
                              new Date(parseIsoString(experiment.createdAt))
                            )}
                          </TableCell>
                          <TableCell>
                            <div className="flex items-center gap-2">
                              {numCompletedUserEvals === numTotalUserEvals ? (
                                <CheckCircle
                                  size={14}
                                  className="text-success"
                                />
                              ) : (
                                <XCircle
                                  size={14}
                                  className="text-destructive"
                                />
                              )}
                              {numCompletedUserEvals}/{numTotalUserEvals}
                            </div>
                          </TableCell>
                        </TableRow>
                      )
                    }
                  )}
                </TableBody>
              </Table>
            </ScrollArea>
          </>
        )}
      </CardContent>
    </Card>
  )
}

export default ExperimentUserInspectorCard
