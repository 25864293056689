export interface NavItem {
  to: string
  label: string
  available: boolean
}

export enum Period {
  Daily = 'Daily',
  Monthly = 'Monthly',
  Yearly = 'Yearly',
}

export interface StatCard {
  unit: string
  endpoint: string
  workspaceSlug: string
  period?: Period
  timeframePicker?: React.ReactNode
}

export type Maybe<T> = T | null | undefined
