import React, { createContext, useContext, useRef } from 'react'

interface AbortControllerContextType {
  addAbortController: (key: string, controller: AbortController) => void
  removeAbortController: (key: string) => void
  getAbortController: (key: string) => AbortController | undefined
}

interface AbortControllerRefs {
  [key: string]: AbortController
}

const initialAbortControllerContext: AbortControllerContextType = {
  addAbortController: () => {},
  removeAbortController: () => {},
  getAbortController: () => undefined,
}

const AbortControllerContext = createContext<AbortControllerContextType>(
  initialAbortControllerContext
)

export const useAbortController = () => {
  return useContext(AbortControllerContext)
}

export const AbortControllerProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const abortControllerRefs = useRef<AbortControllerRefs>({})

  const addAbortController = (key: string, controller: AbortController) => {
    abortControllerRefs.current[key] = controller
  }

  const removeAbortController = (key: string) => {
    delete abortControllerRefs.current[key]
  }

  const getAbortController = (key: string) => {
    return abortControllerRefs.current[key]
  }

  return (
    <AbortControllerContext.Provider
      value={{ addAbortController, removeAbortController, getAbortController }}
    >
      {children}
    </AbortControllerContext.Provider>
  )
}
