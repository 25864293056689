import React, { useMemo, useCallback, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

import { isEmpty, isEqual } from 'lodash'
import { Filter, PlusCircle, X } from 'lucide-react'
import { v4 as uuidv4 } from 'uuid'
import { useShallow } from 'zustand/react/shallow'

import { SafeRecord } from 'utils/safe-types'
import { displayErrorMessage } from 'utils/toast'
import { cn } from 'utils/utils'

import {
  FILTER_CHANGED_EVENT_NAME,
  useAnalytics,
} from 'components/common/analytics/analytics-context'
import { Badge } from 'components/ui/badge'
import { Button } from 'components/ui/button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from 'components/ui/dropdown-menu'
import Icon from 'components/ui/icon/icon'
import { Input } from 'components/ui/input'
import { MultiSelect } from 'components/ui/multi-select'
import {
  Select,
  SelectTrigger,
  SelectContent,
  SelectItem,
  SelectValue,
} from 'components/ui/select'
import {
  EXCLUDED_HEADER_NAMES,
  FILTER_HEADER_NAMES,
} from 'components/vault/components/data-grid/vault-query-result-table'
import { QuestionColumnDef } from 'components/vault/query-detail/data-grid-helpers'
import { EXCLUDED_COLIDS_FROM_RESIZE_FILTER } from 'components/vault/query-detail/vault-query-detail'
import useVaultQueryDetailStore from 'components/vault/query-detail/vault-query-detail-store'
import {
  ColumnDataType,
  fileIdSearchParamKey,
  filtersSearchParamKey,
} from 'components/vault/utils/vault'
import {
  useVaultDataGridFilterStore,
  ColumnFilter,
  FilterModel,
  TypeOperator,
  operatorLabels,
  JoinOperator,
  FilterModelValue,
} from 'components/vault/utils/vault-data-grid-filters-store'

import { columnFilterMap } from './column-filter/column-filter'

interface FilterSelectionProps {
  filterId: string
  columnHeaderNames: string[]
  questionIdToColumnHeader: SafeRecord<
    string,
    { header: string; dataType: ColumnDataType }
  >
  applyFilterHandler: ({
    nextFilters,
    dismissDropdown,
  }: {
    nextFilters?: ColumnFilter[]
    dismissDropdown?: boolean
  }) => void
}

const FilterSelection = ({
  filterId,
  columnHeaderNames,
  questionIdToColumnHeader,
  applyFilterHandler,
}: FilterSelectionProps) => {
  const { trackEvent } = useAnalytics()
  const gridApi = useVaultQueryDetailStore((s) => s.gridApi)
  const [filters, currentAppliedFilters, updateFilter, removeFilter] =
    useVaultDataGridFilterStore(
      useShallow((s) => [
        s.filters,
        s.currentAppliedFilters,
        s.updateFilter,
        s.removeFilter,
      ])
    )

  const currentFilter = filters.find((f) => f.id === filterId)

  const uniqueColumnValues = useMemo(() => {
    if (!gridApi) return []

    const currentFilterQuestionId = currentFilter?.questionId as string

    const columnFilter =
      columnFilterMap[
        questionIdToColumnHeader[currentFilterQuestionId]?.dataType ??
          ColumnDataType.freeResponse
      ]
    return columnFilter.getUniqueColumnValues(gridApi, currentFilterQuestionId)
  }, [currentFilter, gridApi, questionIdToColumnHeader])

  const onColumnChange = (questionId: string) => {
    updateFilter(filterId, {
      headerName: questionIdToColumnHeader[questionId]?.header,
      questionId: questionId,
      value: [],
    })
  }

  const operatorChangeHandler = (newValue: TypeOperator) => {
    updateFilter(filterId, {
      operator: newValue,
      value: [],
    })
  }

  const setSelectedValuesHandler = (selectedValues: string[]) => {
    trackEvent(FILTER_CHANGED_EVENT_NAME, {
      entity_name: 'vault review query table',
      field_changed: currentFilter?.headerName,
    })
    updateFilter(filterId, {
      value: selectedValues,
    })
  }

  const inputChangeHandler = (
    e: React.ChangeEvent<HTMLInputElement> | string
  ) => {
    const newValue = typeof e === 'string' ? e : e.target.value
    updateFilter(filterId, {
      value: [newValue],
    })
  }

  const removeFilterHandler = () => {
    const nextFilters = filters.filter((f) => f.id !== filterId)
    const currentAppliedFilterIds = currentAppliedFilters.map((f) => f.id)

    if (currentAppliedFilterIds.includes(filterId)) {
      applyFilterHandler({ nextFilters, dismissDropdown: false })
    }
    removeFilter(filterId)
  }
  return (
    <div className="flex items-center gap-2">
      <div className="flex flex-wrap items-center gap-2">
        <Select
          onValueChange={onColumnChange}
          value={currentFilter?.questionId ?? undefined}
        >
          <SelectTrigger className="h-8 min-w-48">
            <SelectValue placeholder="Select column" />
          </SelectTrigger>
          <SelectContent className="max-h-64 overflow-y-clip">
            {Object.keys(questionIdToColumnHeader)
              .filter((questionId) => {
                const header = questionIdToColumnHeader[questionId]?.header
                return (
                  !EXCLUDED_HEADER_NAMES.includes(header ?? '') ||
                  FILTER_HEADER_NAMES.includes(header ?? '')
                )
              })
              .sort((a, b) => {
                const headerA = questionIdToColumnHeader[a]?.header ?? ''
                const headerB = questionIdToColumnHeader[b]?.header ?? ''

                if (headerA.toLowerCase() === 'name') return -1
                if (headerB.toLowerCase() === 'name') return 1

                return (
                  columnHeaderNames.indexOf(headerA) -
                  columnHeaderNames.indexOf(headerB)
                )
              })
              .map((questionId) => (
                <SelectItem
                  key={questionId}
                  value={questionId}
                  className="max-w-96 transition hover:cursor-pointer hover:bg-secondary-hover"
                >
                  <p className="truncate">
                    {questionIdToColumnHeader[questionId]?.header ?? ''}
                  </p>
                </SelectItem>
              ))}
          </SelectContent>
        </Select>
        <Select
          onValueChange={operatorChangeHandler}
          value={currentFilter?.operator}
          disabled={currentFilter?.questionId === undefined}
        >
          <SelectTrigger className="h-8 min-w-40">
            <SelectValue placeholder="Contains" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value={TypeOperator.CONTAINS}>
              {operatorLabels[TypeOperator.CONTAINS]}
            </SelectItem>
            <SelectItem value={TypeOperator.EQUALS}>
              {operatorLabels[TypeOperator.EQUALS]}
            </SelectItem>
            <SelectItem value={TypeOperator.NOT_EQUALS}>
              {operatorLabels[TypeOperator.NOT_EQUALS]}
            </SelectItem>
          </SelectContent>
        </Select>
        {currentFilter?.operator === TypeOperator.EQUALS ||
        currentFilter?.operator === TypeOperator.NOT_EQUALS ? (
          <MultiSelect
            placeholder="Select values"
            selectedValues={currentFilter.value}
            setSelectedValues={setSelectedValuesHandler}
            sortedEntries={uniqueColumnValues.map((value) => ({
              text: value,
              value: value,
            }))}
            className="w-[320px]"
            disabled={currentFilter.questionId === undefined}
          />
        ) : (
          <Input
            placeholder="Enter contains value"
            className="h-8 w-[320px]"
            value={currentFilter?.value || ''}
            onChange={inputChangeHandler}
          />
        )}
      </div>
      <Button
        size="xsIcon"
        variant="ghost"
        onClick={removeFilterHandler}
        className="shrink-0"
      >
        <Icon icon={X} size="small" />
      </Button>
    </div>
  )
}

interface FilterPopoverContentProps {
  applyFilterHandler: ({
    nextFilters,
    dismissDropdown,
  }: {
    nextFilters?: ColumnFilter[]
    dismissDropdown?: boolean
  }) => void
}

const FilterPopoverContent = ({
  applyFilterHandler,
}: FilterPopoverContentProps) => {
  const gridApi = useVaultQueryDetailStore((s) => s.gridApi)
  const [filters, addFilter] = useVaultDataGridFilterStore(
    useShallow((state) => [state.filters, state.addFilter])
  )

  const shouldDisableFilterActions =
    filters.some((f) => !f.headerName) ||
    filters.some((f) => f.value.length === 0)

  const questionIdToColumnHeader = useMemo(() => {
    if (!gridApi) return {}
    const qIdToHeader: Record<
      string,
      { header: string; dataType: ColumnDataType }
    > = {}
    const columnDefs = gridApi.getColumnDefs() ?? []
    columnDefs.forEach((columnDef) => {
      const questionColumnDef = columnDef as QuestionColumnDef
      if (
        questionColumnDef.colId &&
        !EXCLUDED_COLIDS_FROM_RESIZE_FILTER.includes(questionColumnDef.colId)
      ) {
        const questionHeaderName = questionColumnDef.headerName || ''
        qIdToHeader[questionColumnDef.colId] = {
          header: questionHeaderName,
          dataType: questionColumnDef.columnDataType,
        }
      }
    })
    return qIdToHeader
  }, [gridApi])

  const columnHeaderNames = useMemo(() => {
    return Object.values(questionIdToColumnHeader).map((c) => c.header)
  }, [questionIdToColumnHeader])

  const addFilterHandler = () => {
    addFilter()
  }

  return (
    <div>
      <div className="flex flex-1 flex-col">
        <div className="space-y-2 px-4 py-3">
          <p className="text-xs font-semibold">Filter by Column</p>
          {filters.map((filter: ColumnFilter) => {
            return (
              <FilterSelection
                key={filter.id}
                filterId={filter.id}
                columnHeaderNames={columnHeaderNames}
                questionIdToColumnHeader={questionIdToColumnHeader}
                applyFilterHandler={applyFilterHandler}
              />
            )
          })}
        </div>
      </div>
      <hr />
      <div className="flex items-center justify-between px-4 py-3">
        <Button
          variant="outline"
          size="sm"
          disabled={
            shouldDisableFilterActions ||
            filters.length === Object.keys(questionIdToColumnHeader).length
          }
          onClick={addFilterHandler}
        >
          <PlusCircle className="mr-1 h-3 w-3" />
          Add filter
        </Button>
        <Button
          size="sm"
          disabled={shouldDisableFilterActions}
          onClick={() => applyFilterHandler({ dismissDropdown: true })}
        >
          Apply
        </Button>
      </div>
    </div>
  )
}

interface FilterDropdownProps {
  clearFilters: () => void
}

const FilterDropdown = ({ clearFilters }: FilterDropdownProps) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const fileId = searchParams.get(fileIdSearchParamKey)
  const queryParamFilters = searchParams.get(filtersSearchParamKey)

  const [gridApi, isQueryLoading, hasOnGridReadyExecuted] =
    useVaultQueryDetailStore(
      useShallow((s) => [s.gridApi, s.isQueryLoading, s.hasOnGridReadyExecuted])
    )
  const [
    filters,
    currentAppliedFilters,
    isFilterDropdownOpen,
    setIsFilterDropdownOpen,
    setFilters,
    resetFilterState,
    setCurrentAppliedFilters,
  ] = useVaultDataGridFilterStore(
    useShallow((s) => [
      s.filters,
      s.currentAppliedFilters,
      s.isFilterDropdownOpen,
      s.setIsFilterDropdownOpen,
      s.setFilters,
      s.resetFilterState,
      s.setCurrentAppliedFilters,
    ])
  )

  const currentPendingColumnId = useVaultQueryDetailStore(
    (s) => s.currentPendingColumnId
  )

  const isDropdownDisabled =
    !gridApi || isQueryLoading || !isEmpty(fileId) || !!currentPendingColumnId

  const onDropdownOpenChangeHandler = (open: boolean) => {
    setIsFilterDropdownOpen(open)
    // if the dropdown is closed, we want to set the filters to the current applied filters
    // so the view of the data-grid matches the applied filters
    if (!open) {
      if (currentAppliedFilters.length > 0) {
        setFilters(currentAppliedFilters)
      } else {
        resetFilterState()
      }
    }
  }

  const applyFilterHandler = useCallback(
    ({
      nextFilters,
      dismissDropdown,
      updateSearchParams = true,
    }: {
      nextFilters?: ColumnFilter[]
      dismissDropdown?: boolean
      updateSearchParams?: boolean
    }) => {
      if (!gridApi) return
      if (filters.length === 0) {
        clearFilters()
        return
      }
      const filtersToApply = nextFilters ?? filters

      const filterModel: FilterModel = {}
      filtersToApply.forEach((filter) => {
        if (!filter.questionId || !Array.isArray(filter.value)) return
        const filterColId = filter.questionId
        const columnDef = gridApi.getColumnDef(filterColId) as QuestionColumnDef
        const columnFilter = columnFilterMap[columnDef.columnDataType]
        const filterType = columnFilter.getFilterType()

        if (filter.value.length > 1) {
          const conditions = filter.value.map(
            (selectedValue) =>
              ({
                filterType,
                type: filter.operator,
                filter: selectedValue,
              }) as FilterModelValue
          )
          filterModel[filter.questionId] = {
            filterType,
            operator:
              filter.operator === TypeOperator.EQUALS
                ? JoinOperator.OR
                : JoinOperator.AND,
            conditions: conditions,
          }
        } else {
          filterModel[filter.questionId] = {
            filterType,
            type: filter.operator,
            filter: filter.value[0],
          }
        }
      })

      setCurrentAppliedFilters(filtersToApply)
      gridApi.setFilterModel(filterModel)

      if (dismissDropdown) {
        setIsFilterDropdownOpen(false)
      }
      if (updateSearchParams) {
        setSearchParams((prev) => {
          const newParams = new URLSearchParams(prev)

          const filtersToApplyStripped = filtersToApply.map(
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            ({ id, ...rest }) => rest
          )
          if (filtersToApplyStripped.length > 0) {
            newParams.set(
              filtersSearchParamKey,
              JSON.stringify(filtersToApplyStripped)
            )
          } else {
            newParams.delete(filtersSearchParamKey)
          }
          return newParams
        })
      }
    },
    [
      gridApi,
      filters,
      clearFilters,
      setIsFilterDropdownOpen,
      setCurrentAppliedFilters,
      setSearchParams,
    ]
  )

  useEffect(() => {
    if (!gridApi || !hasOnGridReadyExecuted) return
    try {
      const parsedQueryParamFilters = JSON.parse(queryParamFilters || '[]')
      const parsedCurrentAppliedFilters = currentAppliedFilters.map(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        ({ id, ...rest }) => rest
      )
      if (!isEqual(parsedQueryParamFilters, parsedCurrentAppliedFilters)) {
        const enhancedQueryParamFilters = parsedQueryParamFilters.map(
          (filter: ColumnFilter) => ({
            ...filter,
            id: uuidv4(), // Assign a new UUID to each filter object
          })
        )
        if (enhancedQueryParamFilters.length > 0) {
          setFilters(enhancedQueryParamFilters)
        } else {
          resetFilterState()
        }
        applyFilterHandler({
          nextFilters: enhancedQueryParamFilters,
          updateSearchParams: false,
        })
      }
    } catch (e) {
      setSearchParams((prev) => {
        const newParams = new URLSearchParams(prev)
        newParams.delete(filtersSearchParamKey)
        return newParams
      })
      displayErrorMessage(
        'There was an error applying the filters. Please try again.',
        5
      )
    }
  }, [
    gridApi,
    hasOnGridReadyExecuted,
    queryParamFilters,
    currentAppliedFilters,
    applyFilterHandler,
    setSearchParams,
    setFilters,
    resetFilterState,
  ])

  return (
    <DropdownMenu
      open={isFilterDropdownOpen}
      onOpenChange={onDropdownOpenChangeHandler}
    >
      <DropdownMenuTrigger
        asChild
        disabled={isDropdownDisabled}
        className={cn('data-[state=open]:bg', {
          'cursor-not-allowed': isDropdownDisabled,
        })}
      >
        <Button className="flex items-center gap-1" variant="outline" size="sm">
          <Icon icon={Filter} size="small" />
          <p className="truncate text-xs">Filter</p>
          {currentAppliedFilters.length > 0 && (
            <Badge
              variant="secondary"
              className="flex h-4 w-4 items-center justify-center p-0"
            >
              {currentAppliedFilters.length}
            </Badge>
          )}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        className="mr-2 max-w-[calc(100vw-1rem)] p-0"
        align="start"
      >
        <FilterPopoverContent applyFilterHandler={applyFilterHandler} />
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

export default FilterDropdown
