import React, { useCallback, useEffect, useState } from 'react'
import { useMount } from 'react-use'

import { DialogDescription } from '@radix-ui/react-dialog'
import { Select } from 'antd'
import emailAddresses, { ParsedMailbox } from 'email-addresses'
import _ from 'lodash'
import pluralize from 'pluralize'

import { addUsersToRole, WorkspaceRole } from 'models/roles'
import { API_USER_SENTINEL } from 'models/user-info'
import { fetchAllActiveWorkspaceUsersInternal } from 'models/users'
import { FetchWorkspace, Workspace } from 'models/workspace'
import { DefaultRole } from 'openapi/models/DefaultRole'

import { displayErrorMessage, displaySuccessMessage } from 'utils/toast'
import { cn } from 'utils/utils'

import MultiEmailTextarea, {
  getCleanEmails,
} from 'components/settings/user-management/multi-email-textarea'
import { Alert } from 'components/ui/alert'
import { Badge } from 'components/ui/badge'
import { Button } from 'components/ui/button'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogTitle,
} from 'components/ui/dialog'
import { ScrollArea } from 'components/ui/scroll-area'
import { Spinner } from 'components/ui/spinner'

const MAX_NUM_USERS = 200

interface RoleBulkAddUsersProps {
  role: WorkspaceRole
  currentUsers: Set<string>
  fetchRoleUsersCallback: () => void
  rolePermCount: number
  workspace: Workspace
}

const RoleBulkAddUsers = ({
  role,
  currentUsers,
  fetchRoleUsersCallback,
  rolePermCount,
  workspace,
}: RoleBulkAddUsersProps) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [userEmailTextArea, setUserEmailTextArea] = useState<string>('')
  const [userLimitExceeded, setUserLimitExceeded] = useState<boolean>(false)
  const [textAreaLines, setTextAreaLines] = useState<number>(0)
  const [availableUsers, setAvailableUsers] = useState<string[]>([])
  const [selectedUsers, setSelectedUsers] = useState<string[]>([])
  const [showConfirmationDialog, setShowConfirmationDialog] =
    useState<boolean>(false)
  const [usersToAdd, setUsersToAdd] = useState<string[]>([])
  const [invalidEmails, setInvalidEmails] = useState<string[]>([])
  const [duplicateEmails, setDuplicateEmails] = useState<string[]>([])
  const [mismatchDomainUsers, setMismatchDomainUsers] = useState<string[]>([])
  const [domains, setDomains] = useState<string[]>([])
  const [addedUsers, setAddedUsers] = useState<number>(0)

  useMount(async () => {
    if (workspace.parentId) {
      const parent = await FetchWorkspace(workspace.parentId).catch(() =>
        displayErrorMessage('Failed to fetch parent workspace')
      )
      setDomains([...(parent?.domains ?? []), ...workspace.domains])
      return
    }
    setDomains(workspace.domains)
  })

  const isApiRole = role.roleId === `${workspace.slug}-${DefaultRole.API}`

  useEffect(() => {
    const fetchWorkspaceUsers = async () => {
      const users = await fetchAllActiveWorkspaceUsersInternal(role.workspaceId)
      const userEmails = users
        .map((user) => user.email)
        .filter((email) => !currentUsers.has(email))
        .filter((email) =>
          isApiRole
            ? email.startsWith(API_USER_SENTINEL)
            : !email.startsWith(API_USER_SENTINEL)
        )
      setAvailableUsers(userEmails)
    }
    void fetchWorkspaceUsers()
  }, [currentUsers, role, isApiRole])

  const onAddUsers = useCallback(() => {
    let textAreaEmails = getCleanEmails(userEmailTextArea).map((email) =>
      email.toLowerCase()
    )

    if (textAreaEmails.length > MAX_NUM_USERS) {
      displayErrorMessage(
        `Exceeded maximum number of users (${MAX_NUM_USERS}) allowed`
      )
      setLoading(false)
      return
    }
    const [validEmails, invalidEmails] = _.partition(
      textAreaEmails,
      (email) => {
        const parsed = emailAddresses.parseOneAddress(email)
        return parsed && /^[^@]+@[^@]+\.[^@]+$/.test(email) // Regex to ensure domain part has a dot
      }
    )
    setInvalidEmails(invalidEmails)

    // also remove any emails in currentUsers from validEmails
    textAreaEmails = validEmails.filter((email) => !currentUsers.has(email))
    const dupeTextAreaEmails = _.intersection(
      validEmails,
      Array.from(currentUsers)
    )

    // dedupe emails
    const emails = _.uniq([...textAreaEmails, ...selectedUsers])
    const dupes = _.intersection(textAreaEmails, selectedUsers)

    let mismatchDomainUsers: string[] = []
    if (!_.isEmpty(domains)) {
      mismatchDomainUsers = emails.filter((email) => {
        const parsed = emailAddresses.parseOneAddress(email)
        const domain =
          parsed && 'domain' in parsed ? (parsed as ParsedMailbox).domain : ''
        return !domains.includes(domain)
      })
    }

    // remove mismatched domain users from emails
    // final cleanup
    const userEmails = emails
      .filter((email) => !mismatchDomainUsers.includes(email))
      .map((email) => email.toLowerCase().trim())

    setMismatchDomainUsers(mismatchDomainUsers)
    setDuplicateEmails([...dupes, ...dupeTextAreaEmails])
    setUsersToAdd(userEmails)
    setShowConfirmationDialog(true)
  }, [userEmailTextArea, selectedUsers, currentUsers, domains])

  const handleAddUserSubmit = useCallback(async () => {
    // add users to role
    setLoading(true)
    try {
      const numAdded = await addUsersToRole(role.rolePk, usersToAdd)
      displaySuccessMessage(`Successfully added ${numAdded} users to role`, 10)
      setAddedUsers(numAdded)
      fetchRoleUsersCallback()
    } catch (error) {
      displayErrorMessage('Failed to add users to role', 10)
    }
    setLoading(false)
  }, [role, usersToAdd, fetchRoleUsersCallback])

  const onClear = () => {
    setUserEmailTextArea('')
    setSelectedUsers([])
    setUsersToAdd([])
    setAddedUsers(0)
    setInvalidEmails([])
    setDuplicateEmails([])
    setTextAreaLines(0)
    setUserLimitExceeded(false)
  }

  const numUsersToAdd = textAreaLines + selectedUsers.length
  const dialogUsersToAdd = usersToAdd.length

  return (
    <div className="space-y-4 p-1">
      <div className="mt-1">
        <p>
          Select users from dropdown or paste a list of users to add them to
          role {role.roleId}
        </p>
      </div>
      <div className="mt-6">
        <Select
          mode="multiple"
          placeholder="Select users"
          virtual={false}
          style={{ width: '100%' }}
          options={_.sortBy(
            availableUsers.map((user) => {
              return { value: user, label: user }
            }),
            'label'
          )}
          loading={_.isEmpty(availableUsers)}
          optionFilterProp="label"
          onChange={(value) => setSelectedUsers(value)}
          value={selectedUsers}
        />
      </div>
      <div className="mt-2">
        <MultiEmailTextarea
          value={userEmailTextArea}
          onChange={(emails: string) => setUserEmailTextArea(emails)}
          maxNumUsers={MAX_NUM_USERS}
          emailsValidCallback={(valid: boolean) => setUserLimitExceeded(!valid)}
          placeholder={`Enter new user emails to add to role ${_.startCase(
            role.roleId
          )}, one email per line`}
          setNumEmails={(numEmails: number) => setTextAreaLines(numEmails)}
        />
      </div>
      {addedUsers === 0 ? (
        <div className="flex w-full justify-end">
          {loading ? (
            <Spinner />
          ) : (
            <Button
              disabled={
                userLimitExceeded ||
                (_.isEmpty(selectedUsers) && textAreaLines === 0)
              }
              onClick={onAddUsers}
            >
              Add{' '}
              {numUsersToAdd === 0
                ? 'users'
                : `${numUsersToAdd} ${pluralize('users', numUsersToAdd)}`}{' '}
              to {role.roleId}
            </Button>
          )}
        </div>
      ) : (
        <div className="flex justify-between">
          <span>
            <p>
              {addedUsers} users have been added to role {role.roleId}
            </p>
          </span>
          <Button variant="secondary" onClick={onClear}>
            Clear
          </Button>
        </div>
      )}
      <Dialog
        open={showConfirmationDialog}
        onOpenChange={() => setShowConfirmationDialog(!showConfirmationDialog)}
      >
        <DialogContent>
          <DialogTitle>
            {!_.isEmpty(usersToAdd)
              ? `Add ${dialogUsersToAdd} ${pluralize(
                  'users',
                  dialogUsersToAdd
                )} to role ${role.roleId}`
              : `No users to add`}
          </DialogTitle>
          <DialogDescription>
            <div>
              {!_.isEmpty(usersToAdd)
                ? `This will grant ${rolePermCount} ${pluralize(
                    'permissions',
                    rolePermCount
                  )} to ${dialogUsersToAdd} new ${pluralize(
                    'users',
                    dialogUsersToAdd
                  )}`
                : ``}
            </div>
            {usersToAdd.length > 0 && (
              <Alert className="mt-4 p-3" variant="success">
                <div>
                  <p>Users to add:</p>
                  <ScrollArea className="max-h-64">
                    <div className="max-h-64">
                      {usersToAdd.map((email, idx) => (
                        <Badge
                          key={idx}
                          variant="outline"
                          className="mr-1 mt-1"
                        >
                          {email}
                        </Badge>
                      ))}
                    </div>
                  </ScrollArea>
                </div>
              </Alert>
            )}
            {duplicateEmails.length > 0 && (
              <Alert className="mt-4 p-3" variant="warning">
                {duplicateEmails.length > 0 && (
                  <div>
                    <p>Duplicate users:</p>
                    <ScrollArea className="max-h-48">
                      <div className="max-h-48">
                        {duplicateEmails.map((email, idx) => (
                          <Badge
                            key={idx}
                            variant="outline"
                            className="mr-1 mt-1"
                          >
                            {email}
                          </Badge>
                        ))}
                      </div>
                    </ScrollArea>
                  </div>
                )}
              </Alert>
            )}
            {mismatchDomainUsers.length + invalidEmails.length > 0 && (
              <Alert className="mt-4 p-3" variant="destructive">
                <div>
                  {mismatchDomainUsers.length > 0 && (
                    <div>
                      <p>Mismatched domain users:</p>
                      <ScrollArea className="max-h-48">
                        <div className="max-h-48">
                          {mismatchDomainUsers.map((email, idx) => (
                            <Badge
                              key={idx}
                              variant="outline"
                              className="mr-1 mt-1"
                            >
                              {email}
                            </Badge>
                          ))}
                        </div>
                      </ScrollArea>
                    </div>
                  )}
                  {invalidEmails.length > 0 && (
                    <div
                      className={cn({
                        'mt-1.5': mismatchDomainUsers.length > 0,
                      })}
                    >
                      <p>Invalid users:</p>
                      <ScrollArea className="max-h-48">
                        <div className="max-h-48">
                          {invalidEmails.map((email, idx) => (
                            <Badge
                              key={idx}
                              variant="outline"
                              className="mr-1 mt-1"
                            >
                              {email}
                            </Badge>
                          ))}
                        </div>
                      </ScrollArea>
                    </div>
                  )}
                </div>
              </Alert>
            )}
          </DialogDescription>
          <DialogFooter>
            <DialogClose asChild>
              <Button
                variant="outline"
                onClick={() => setShowConfirmationDialog(false)}
              >
                Cancel
              </Button>
            </DialogClose>
            <DialogClose asChild>
              <Button
                onClick={handleAddUserSubmit}
                disabled={dialogUsersToAdd === 0}
              >
                {`Add ${dialogUsersToAdd} ${pluralize(
                  'users',
                  dialogUsersToAdd
                )}`}
              </Button>
            </DialogClose>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default RoleBulkAddUsers
