import React, { useState, useEffect, useCallback } from 'react'

import { throttle, isEmpty } from 'lodash'
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from 'recharts'

import Services from 'services'
import { Period } from 'types'

import { useAllTaskLabelLookup } from 'utils/task-definitions'
import { ensureFreshData, readableNumber } from 'utils/utils'

import { Card, CardContent, CardHeader, CardTitle } from 'components/ui/card'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/ui/select'
import Skeleton from 'components/ui/skeleton'
import { Tabs, TabsList, TabsTrigger } from 'components/ui/tabs'

interface StatCardProps {
  unit: string
  endpoint: string
  workspaceSlug: string
}

type Datum = Record<string, number> & { name: string }

interface StatsData {
  aggregate: number
  timeseries: Datum[]
}

const StatCard: React.FC<StatCardProps> = ({
  unit,
  endpoint,
  workspaceSlug,
}) => {
  const [period, setPeriod] = useState<Period>(Period.Monthly)
  const [isLoading, setIsLoading] = useState(false)

  const [data, setData] = useState<Datum[]>([])
  const [aggregateNum, setAggregateNum] = useState<number>(0)

  let aggKey = 'value'
  if (endpoint === 'queries/graph') {
    aggKey = 'Total ' + unit
  }
  const [graphTotals, setGraphTotals] = useState<Record<string, number>>({})
  const [graphKeys, setGraphKeys] = useState<string[]>([])
  const [selectedKey, setSelectedKey] = useState<string>(aggKey)

  const taskLabelLookup = useAllTaskLabelLookup()

  const fetchData = useCallback(
    (workspaceSlug: string, period: Period) => {
      const fn = throttle(
        async (workspaceSlug: string, period: Period) => {
          setIsLoading(true)
          const startTime = new Date()

          const params = new URLSearchParams()
          params.append('period', period)
          params.append(
            'user_tz',
            Intl.DateTimeFormat().resolvedOptions().timeZone
          )
          params.append('workspace_slug', workspaceSlug)

          const response = await Services.Backend.Get<StatsData>(
            `dashboard/${endpoint}?${params.toString()}`
          )
          if (isEmpty(response)) {
            setData([])
          } else {
            const graphTotalsRaw: Record<string, number> = {}
            const rawData = (response.timeseries ?? []).map((datum: Datum) => {
              let aggPerTimestamp = 0
              Object.keys(datum).forEach((key) => {
                if (key !== 'name') {
                  const newKey = taskLabelLookup[key] ?? key
                  if (newKey !== key) {
                    datum[newKey] = datum[key] ?? 0
                    delete datum[key] // eslint-disable-line
                  }
                  graphTotalsRaw[newKey] =
                    (graphTotalsRaw[newKey] ?? 0) + datum[newKey]
                  aggPerTimestamp += datum[newKey]
                }
              })

              if (endpoint === 'queries/graph') {
                datum[aggKey] = aggPerTimestamp
              }

              return datum
            })
            setData(rawData)
            setAggregateNum(response.aggregate ?? 0)
            if (endpoint === 'queries/graph') {
              graphTotalsRaw[aggKey] = response.aggregate ?? 0
              setGraphTotals(graphTotalsRaw)
              setGraphKeys(
                Object.keys(graphTotalsRaw).sort(
                  (a, b) => graphTotalsRaw[b] - graphTotalsRaw[a]
                )
              )
            }
          }
          const endTime = new Date()
          const duration = endTime.getTime() - startTime.getTime()
          // delay by at least 1 second total if data fetch takes less than 1 second
          await new Promise((resolve) =>
            setTimeout(resolve, Math.max(0, 1000 - duration))
          )
          setIsLoading(false)
        },
        100,
        { leading: true, trailing: false }
      )
      return fn(workspaceSlug, period)
    },
    [aggKey, endpoint, taskLabelLookup]
  )

  useEffect(() => {
    const loader = (): void => {
      void fetchData(workspaceSlug, period ?? Period.Monthly)
    }

    return ensureFreshData(loader)
  }, [fetchData, workspaceSlug, period])

  const formatYAxisTick = (value: number): string => {
    return readableNumber(value)
  }

  const renderAreas = (): JSX.Element => {
    return (
      <React.Fragment key={selectedKey ?? 'value'}>
        <defs>
          <linearGradient id="gradient-blue" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor="#1452F1" stopOpacity={0.6} />
            <stop offset="100%" stopColor="#1452F1" stopOpacity={0} />
          </linearGradient>
        </defs>
        <Area
          type="monotone"
          dataKey={selectedKey ?? 'value'}
          stackId="1"
          stroke="#1452F1"
          fill="url(#gradient-blue)"
        />
      </React.Fragment>
    )
  }

  const total = graphTotals[selectedKey] ?? aggregateNum

  return (
    <Card className="rounded-lg border">
      <CardHeader>
        <CardTitle className="flex flex-wrap items-center justify-between pl-6">
          <div>
            <span className="mr-2 text-xl">{readableNumber(total)}</span>
            <span className="text-base text-muted">{unit}</span>
          </div>
          <div className="flex flex-row flex-wrap items-center gap-2 pb-2 pt-2">
            {endpoint === 'queries/graph' && (
              <Select onValueChange={setSelectedKey}>
                <SelectTrigger className="min-w-[200px]">
                  <SelectValue>{selectedKey}</SelectValue>
                </SelectTrigger>
                <SelectContent className="h-60 overflow-y-auto">
                  {graphKeys.map((key) => (
                    <SelectItem key={key} value={key}>
                      {key}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            )}
            <Tabs defaultValue="Month">
              <TabsList>
                <TabsTrigger
                  value="Day"
                  onClick={() => setPeriod(Period.Daily)}
                >
                  Day
                </TabsTrigger>
                <TabsTrigger
                  value="Month"
                  onClick={() => setPeriod(Period.Monthly)}
                >
                  Month
                </TabsTrigger>
                <TabsTrigger
                  value="Year"
                  onClick={() => setPeriod(Period.Yearly)}
                >
                  Year
                </TabsTrigger>
              </TabsList>
            </Tabs>
          </div>
        </CardTitle>
      </CardHeader>
      <CardContent className="h-[360px]">
        {isLoading && <Skeleton />}
        {!isLoading && (
          <ResponsiveContainer>
            <AreaChart data={data}>
              {/* https://github.com/recharts/recharts/pull/3768 */}
              <XAxis
                dataKey="name"
                interval="equidistantPreserveStart"
                padding={{ right: 12, left: 0 }} // avoids collision for right-most tick
              />
              <YAxis tickFormatter={formatYAxisTick} />
              <Tooltip
                formatter={(value, name) => [
                  value,
                  name !== 'value' ? name : unit,
                ]}
              />
              {renderAreas()}
            </AreaChart>
          </ResponsiveContainer>
        )}
      </CardContent>
    </Card>
  )
}

export default StatCard
