import React from 'react'

import { useNavigateWithQueryParams } from 'hooks/use-navigate-with-query-params'

import ErrorPage, { ErrorPageTitle } from 'components/common/error/error'

import { REMOVE_PARAMS } from './utils/vault'
import { useVaultStore } from './utils/vault-store'

const VaultErrorPage = () => {
  const navigate = useNavigateWithQueryParams()

  const error = useVaultStore((state) => state.error)
  const setError = useVaultStore((state) => state.setError)

  if (!error) {
    return null
  }

  return (
    <ErrorPage
      title={ErrorPageTitle.PAGE_NOT_FOUND}
      description={error.message}
      primaryCTA={{
        text: error.cta.message,
        onClick: () => {
          setError(null)
          navigate(error.cta.redirectUri, { replace: true }, REMOVE_PARAMS)
        },
      }}
    />
  )
}
export default VaultErrorPage
