/* tslint:disable */
/* eslint-disable */
/**
 * Contracts Processing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ContractsSmartFilterResult } from './ContractsSmartFilterResult';
import {
    ContractsSmartFilterResultFromJSON,
    ContractsSmartFilterResultFromJSONTyped,
    ContractsSmartFilterResultToJSON,
} from './ContractsSmartFilterResult';
import type { ContractsSmartFilterUserInput } from './ContractsSmartFilterUserInput';
import {
    ContractsSmartFilterUserInputFromJSON,
    ContractsSmartFilterUserInputFromJSONTyped,
    ContractsSmartFilterUserInputToJSON,
} from './ContractsSmartFilterUserInput';
import type { ContractsSmartFilterQuery } from './ContractsSmartFilterQuery';
import {
    ContractsSmartFilterQueryFromJSON,
    ContractsSmartFilterQueryFromJSONTyped,
    ContractsSmartFilterQueryToJSON,
} from './ContractsSmartFilterQuery';

/**
 * 
 * @export
 * @interface ContractsSmartFilter
 */
export interface ContractsSmartFilter {
    /**
     * 
     * @type {ContractsSmartFilterUserInput}
     * @memberof ContractsSmartFilter
     */
    userInput: ContractsSmartFilterUserInput;
    /**
     * 
     * @type {Array<ContractsSmartFilterResult>}
     * @memberof ContractsSmartFilter
     */
    results: Array<ContractsSmartFilterResult>;
    /**
     * 
     * @type {ContractsSmartFilterQuery}
     * @memberof ContractsSmartFilter
     */
    query: ContractsSmartFilterQuery;
}

/**
 * Check if a given object implements the ContractsSmartFilter interface.
 */
export function instanceOfContractsSmartFilter(value: object): value is ContractsSmartFilter {
    if (!('userInput' in value) || value['userInput'] === undefined) return false;
    if (!('results' in value) || value['results'] === undefined) return false;
    if (!('query' in value) || value['query'] === undefined) return false;
    return true;
}

export function ContractsSmartFilterFromJSON(json: any): ContractsSmartFilter {
    return ContractsSmartFilterFromJSONTyped(json, false);
}

export function ContractsSmartFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContractsSmartFilter {
    if (json == null) {
        return json;
    }
    return {
        
        'userInput': ContractsSmartFilterUserInputFromJSON(json['user_input']),
        'results': ((json['results'] as Array<any>).map(ContractsSmartFilterResultFromJSON)),
        'query': ContractsSmartFilterQueryFromJSON(json['query']),
    };
}

export function ContractsSmartFilterToJSON(value?: ContractsSmartFilter | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'user_input': ContractsSmartFilterUserInputToJSON(value['userInput']),
        'results': ((value['results'] as Array<any>).map(ContractsSmartFilterResultToJSON)),
        'query': ContractsSmartFilterQueryToJSON(value['query']),
    };
}

