import { useCallback, useEffect, useRef } from 'react'

// This hook manages custom history in an input for Undo/Redo.
// This is helpful when an outside action updates the value, but we want to
// preserve what the user might have typed before.
//
// This hook expects the following arguments:
// - `setter`: The function to set the input value.
//      Probably a zustand set{Name}, or native React setter from useState
//
// This hook returns an object containing:
// - `handleKeyDown`: Keydown handler that should be passed to the input component
// - `setValue`: A wrapped function that should replace when you would normally
//      have called the `setter` passed in

const useChangeHistory = (setter: (value: string) => void) => {
  const historyIndex = useRef<number>(-Infinity)
  const historyRefs = useRef<string[]>([])

  const saveTimeout = useRef<ReturnType<typeof setTimeout> | null>(null)
  const clearSaveTimeout = () => {
    if (saveTimeout.current) {
      clearTimeout(saveTimeout.current)
      saveTimeout.current = null
    }
  }
  const handleSaveHistory = useCallback((q: string) => {
    clearSaveTimeout()
    saveTimeout.current = setTimeout(() => {
      if (historyIndex.current !== -Infinity) {
        // We were in the middle of an undo/redo stack
        historyRefs.current = historyRefs.current.slice(
          0,
          Math.max(0, historyIndex.current)
        )
        historyIndex.current = -Infinity
      }
      historyRefs.current.push(q)
      clearSaveTimeout()
    }, 100)
  }, [])

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent) => {
      if (!historyRefs.current.length) return
      // Note: Custom undo/redo probably won't work if user has custom mapped keys.
      if (
        e.key === 'Redo' ||
        (e.metaKey && e.shiftKey && e.code === 'KeyZ') ||
        (e.ctrlKey && e.code === 'KeyY')
      ) {
        if (historyIndex.current === -Infinity) {
          historyIndex.current = historyRefs.current.length - 1
        }
        if (historyIndex.current < historyRefs.current.length - 1) {
          historyIndex.current += 1
        }
        setter(historyRefs.current[historyIndex.current] || '')
      } else if (
        e.key === 'Undo' ||
        ((e.ctrlKey || e.metaKey) && e.code === 'KeyZ')
      ) {
        e.preventDefault()
        if (historyIndex.current === -Infinity) {
          historyIndex.current = historyRefs.current.length - 1
        }
        if (historyIndex.current >= 0) {
          historyIndex.current -= 1
        }
        setter(historyRefs.current[historyIndex.current] || '')
      }
    },
    [setter]
  )

  const setValue = useCallback(
    (value: string) => {
      handleSaveHistory(value)
      setter(value)
    },
    [handleSaveHistory, setter]
  )

  useEffect(() => {
    return () => clearSaveTimeout()
  }, [])

  return { handleKeyDown, setValue }
}

export default useChangeHistory
