import React, { useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { useMount, useUnmount } from 'react-use'

import * as Sentry from '@sentry/browser'
import _ from 'lodash'
import { Instance } from 'pspdfkit'

import { useHistoryItemQuery } from 'models/queries/use-history-item-query'
import { EventKind } from 'openapi/models/EventKind'
import Services from 'services'
import { WorkflowType, WorkflowTypeToTaskType } from 'types/workflows'

import { useNavigateWithQueryParams } from 'hooks/use-navigate-with-query-params'
import { exportTaskDocumentFromBackend } from 'utils/export'
import { feedbackClosedState } from 'utils/feedback'
import { displayErrorMessage } from 'utils/toast'
import {
  HarveySocketCompletionStatus,
  hasCompletedStreaming,
} from 'utils/use-harvey-socket-utils'
import { isUserInputEmpty } from 'utils/utils'

import { useClientMattersStore } from 'components/client-matters/client-matters-store'
import useQueryAnalytics from 'components/common/analytics/use-query-analytics'
import { AppHeaderActions } from 'components/common/app-header-actions'
import { useAuthUser } from 'components/common/auth-context'
import ExportDialog from 'components/common/export/export-dialog'
import Response from 'components/common/response/response'
import { OGC_REVIEW_HELP } from 'components/workflows/constants'
import { WorkflowTypeToDetails } from 'components/workflows/workflow-definitions'
import WorkflowFileInput from 'components/workflows/workflow/components/workflow-file-input'
import { WorkflowsDualPaneLayout } from 'components/workflows/workflow/layouts/dual-pane'
import WorkflowLayout from 'components/workflows/workflow/workflow-layout'

import OgcPromptInput from './ogc-prompt-input'
import { useOgcStore } from './ogc-store'

const ROUTE = 'ogc_review'

const OgcWorkflow: React.FC = () => {
  const userInfo = useAuthUser()
  const workflowType = WorkflowType.OGC
  const workflow = WorkflowTypeToDetails[workflowType]
  const navigate = useNavigateWithQueryParams()
  const pspdfInstanceRef = useRef<Instance | null>(null)
  const { id: historyId } = useParams<{ id: string }>()
  const { historyItem } = useHistoryItemQuery({ id: historyId ?? null })

  const {
    response,
    setTask,
    queryId,
    feedback,
    setFeedback,
    headerText,
    isLoading,
    setActiveDocument,
    activeDocument,
    exportUrl,
    setExportUrl,
    reset,
    completionStatus,
  } = useOgcStore()

  const {
    setQueryViewed,
    recordExport,
    recordQueryCancel,
    recordQuerySubmitted,
    recordQueryCompletion,
    recordReset,
  } = useQueryAnalytics(EventKind.OGC_REVIEW)

  const handleReset = () => {
    navigate(workflow.path, { replace: true })
    reset()
    recordReset()
  }

  const abortControllerRef = useRef<AbortController | null>(null)
  const { selectedClientMatter, setClientMatterSelectDisabled } =
    useClientMattersStore()

  useMount(() => {
    if (!_.isNil(historyItem)) {
      setTask({
        queryId: historyItem.id,
        query: historyItem.query,
        response: historyItem.response,
        completionStatus: HarveySocketCompletionStatus.Completed,
      })
      setFeedback(feedbackClosedState)
      const document = historyItem.documents?.find((doc) => {
        return doc.kind === undefined || doc.kind === 'doc' || doc.kind === ''
      })
      if (!document) {
        displayErrorMessage('Couldn’t find document in history')
        Sentry.captureException('Couldn’t find document in history', {
          extra: { documents: historyItem.documents },
        })
        return
      }

      // TODO: OGC history doesn't contain the word export url, disable for now
      // setExportUrl(...)

      setActiveDocument(document)
      setQueryViewed()
    }
  })

  useUnmount(() => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort()
    }
    reset()
  })

  useEffect(() => {
    if (
      userInfo.IsHistoryUser &&
      queryId &&
      hasCompletedStreaming(completionStatus)
    ) {
      navigate(`${workflow.path}/${queryId}`, {
        replace: true,
        state: { fetchHistoryItem: false },
      })
      recordQueryCompletion()
    }
  }, [
    queryId,
    completionStatus,
    navigate,
    recordQueryCompletion,
    workflow.path,
    userInfo,
  ])

  const clearExistingQuery = () => {
    setTask({ headerText: '', response: '', sources: [] })
    setFeedback(null)
  }

  const onSubmit = async () => {
    if (!activeDocument) {
      return null
    } else if (!(activeDocument instanceof File)) {
      displayErrorMessage('Cannot submit an already uploaded file')
    }

    recordQuerySubmitted({
      event_kind: EventKind.OGC_REVIEW,
    })
    clearExistingQuery()

    setTask({
      isLoading: true,
      completionStatus: HarveySocketCompletionStatus.Loading,
    })

    const client_matter_id = userInfo.isClientMattersReadUser
      ? selectedClientMatter?.id
      : null

    const formData = new FormData()
    formData.append('file', activeDocument as File)
    if (client_matter_id) {
      formData.append('client_matter_id', client_matter_id)
    }

    abortControllerRef.current = new AbortController()
    setClientMatterSelectDisabled(true)

    await Services.Backend.Post<any>(ROUTE, formData, {
      signal: abortControllerRef.current.signal,
      // TODO: We should be able to handle errors here
      throwOnError: false,
    })
      .then((res) => {
        setTask({ completionStatus: HarveySocketCompletionStatus.Completed })
        setTask({ response: res?.response, queryId: res?.queryId })
        setExportUrl(res?.url)
      })
      .catch(() => {
        setTask({ completionStatus: HarveySocketCompletionStatus.Error })
        displayErrorMessage('Error while running OGC review')
      })
      .finally(() => {
        setTask({ isLoading: false })
        setClientMatterSelectDisabled(false)
      })
  }

  const handleCancel = () => {
    if (abortControllerRef.current) {
      recordQueryCancel()
      setTask({ completionStatus: HarveySocketCompletionStatus.Cancelled })
      abortControllerRef.current.abort()
    }
  }

  const handleFile = (file: File) => {
    setActiveDocument(file)
  }

  // NOTE(Adam): This document has a special export format with a PwC template and header
  // Therefore, we just need an export button, not an export dropdown with options
  // This is because "export with inline citations" doesn't make sense for this document
  const exportWordDocument = async () => {
    if (!exportUrl) {
      displayErrorMessage('Unable to export document')
      return
    }

    await exportTaskDocumentFromBackend(
      exportUrl,
      WorkflowTypeToTaskType[WorkflowType.OGC],
      queryId
    )
    recordExport(String(queryId), 'word', false)
  }

  const showFeedback =
    hasCompletedStreaming(completionStatus) && !feedback?.closed

  const emptyUserInput = isUserInputEmpty([activeDocument])
  const resetDisabled = emptyUserInput || isLoading

  return (
    <WorkflowLayout
      workflowType={workflow.type}
      title="OGC"
      appHeaderActions={
        <>
          <AppHeaderActions
            handleReset={handleReset}
            resetDisabled={resetDisabled}
            saveExample={{
              show: userInfo.canSeeSaveExample,
              disabled: !hasCompletedStreaming(completionStatus),
              params: {
                eventId: String(queryId),
              },
            }}
          />
          <ExportDialog
            onExport={exportWordDocument}
            disabled={
              !(exportUrl ? hasCompletedStreaming(completionStatus) : false)
            }
          />
        </>
      }
    >
      <WorkflowsDualPaneLayout
        input={
          <WorkflowFileInput
            eventKind={EventKind.OGC_REVIEW}
            canMaximize={false}
            handleFile={handleFile}
            store={useOgcStore}
            pspdfInstanceRef={pspdfInstanceRef}
          >
            <OgcPromptInput onSubmit={onSubmit} />
          </WorkflowFileInput>
        }
        output={
          <Response
            markdown={response}
            isLoading={isLoading}
            headerText={headerText}
            handleCancel={handleCancel}
            emptyStateText={
              userInfo.GetHelpPanel(WorkflowTypeToTaskType[workflowType])
                ?.content ?? OGC_REVIEW_HELP
            }
            queryId={queryId}
            handleSaveFeedback={showFeedback ? setFeedback : undefined}
            taskType={WorkflowTypeToTaskType[workflowType]}
          />
        }
      />
    </WorkflowLayout>
  )
}

export default OgcWorkflow
