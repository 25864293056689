import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useQueryClient } from '@tanstack/react-query'
import { Divider } from 'antd'
import _ from 'lodash'

import {
  ClientMatterWithStats,
  ClientMattersSettingsStore,
  getClientMattersWithStats,
} from 'models/client-matters'
import { HarvQueryKeyPrefix } from 'models/queries/all-query-keys'
import { useSettingsClientMattersQuery } from 'models/queries/use-settings-client-matters-query'
import { Maybe } from 'types'

import {
  ClientMatterAdd,
  CLIENT_MATTER_URL_PARAM,
  handleAddClientMatter,
  handleClientMatterEdit,
  handleDeleteClientMatter,
  mergeAddedClientMatters,
} from 'components/client-matters/client-matter-utils'
import { useClientMattersStore } from 'components/client-matters/client-matters-store'
import { useAnalytics } from 'components/common/analytics/analytics-context'
import SettingsAppHeader from 'components/settings/settings-app-header'
import SettingsError from 'components/settings/settings-error'
import SettingsLayout from 'components/settings/settings-layout'
import { useSettingsState } from 'components/settings/settings-store'
import { Button } from 'components/ui/button'
import DataTableHeader from 'components/ui/data-table/data-table-header'

import ClientMattersAddEditDialog from './client-matters-add-edit-dialog'
import ClientMattersTable from './client-matters-table'
import SettingsClientMattersAdmin from './settings-client-matters-admin'

const SettingsClientMatters: React.FC = () => {
  const {
    selectedClientMatter,
    clientMatters,
    setClientMatters,
    setSelectedClientMatter,
    canCmUserManage,
  } = useClientMattersStore()
  const navigate = useNavigate()
  const [searchQuery, setSearchQuery] = useState('')

  const [addClientMatterModalOpen, setAddClientMatterModalOpen] =
    useState(false)

  const settingsUser = useSettingsState((state) => state.settingsUser)
  const {
    cmData: { clientMattersWithStats, workspaces },
    isCmPending,
  } = useSettingsClientMattersQuery(
    settingsUser,
    settingsUser?.isClientMattersReadUser
  )

  const [userClientMatters, setUserClientMatters] = useState<
    ClientMatterWithStats[]
  >(clientMattersWithStats)

  const selectedWorkspace = settingsUser?.workspace ?? workspaces[0]

  const [editCmName, setEditCmName] = useState('')
  const [editCmDesc, setEditCmDesc] = useState('')
  const [cmAllowed, setCmAllowed] = useState<boolean>(true)

  useEffect(() => {
    if (!addClientMatterModalOpen) {
      setEditCmName('')
      setEditCmDesc('')
      setCmAllowed(true)
    }
  }, [addClientMatterModalOpen])

  useEffect(() => {
    setUserClientMatters(clientMattersWithStats)
  }, [clientMattersWithStats])

  useEffect(() => {
    const fetchUserData = async () => {
      if (_.isNil(selectedWorkspace)) {
        return
      }
      const clientMatters = await getClientMattersWithStats(
        selectedWorkspace.id
      )
      setUserClientMatters(clientMatters)
    }
    fetchUserData()
  }, [selectedWorkspace])

  const { trackEvent } = useAnalytics()

  const queryClient = useQueryClient()

  if (!settingsUser?.isClientMattersReadUser)
    return (
      <SettingsError
        description={`You don’t have access to client matter settings for ${settingsUser?.workspace.clientName}`}
      />
    )

  const onAddClientMatter = async (
    addCms: ClientMatterAdd[],
    dialogCallback: () => void,
    metricName: string
  ) => {
    const successCallback = (successClientMatters: ClientMatterWithStats[]) => {
      setClientMatters([...clientMatters, ...successClientMatters])
      setAddClientMatterModalOpen(false)
      dialogCallback()
      // navigateWithQueryParams('/settings/client-matters')
      queryClient.setQueryData(
        [HarvQueryKeyPrefix.SettingsClientMatters],
        (oldData: ClientMattersSettingsStore) =>
          mergeAddedClientMatters(oldData, successClientMatters)
      )
    }

    await handleAddClientMatter(
      addCms,
      successCallback,
      metricName,
      settingsUser,
      selectedWorkspace.id,
      trackEvent
    )
  }

  const onDialogClientMatterEdit = async (
    editedCmName: string,
    editedCmDesc: Maybe<string>,
    cmAllowed: boolean
  ) => {
    const editCm = clientMatters.find((cm) => cm.name === editCmName)
    if (!editCm) {
      console.error('Edit client Matter not found')
      return
    }
    const successCallback = (editedClientMatter: ClientMatterWithStats) => {
      const newClientMatters = clientMatters.filter(
        (cm) => cm.name !== editCm.name
      )
      newClientMatters.push(editedClientMatter)
      setClientMatters(newClientMatters)

      queryClient.setQueryData(
        [HarvQueryKeyPrefix.SettingsClientMatters],
        (oldData: ClientMattersSettingsStore) => {
          if (!oldData)
            return {
              clientMattersWithStats: [editedClientMatter],
              workspaces: [],
            }

          // Iterate through the existing cache to update items or leave them unchanged
          const updatedClientMattersWithStats =
            oldData.clientMattersWithStats.map((item) => {
              if (item.id === editedClientMatter.id) {
                return editedClientMatter
              } else {
                return item
              }
            })

          // Combine the updated existing items with any new items
          return {
            ...oldData,
            clientMattersWithStats: [...updatedClientMattersWithStats],
          }
        }
      )
    }
    await handleClientMatterEdit(
      editCm.id,
      editedCmName,
      editedCmDesc,
      cmAllowed,
      selectedWorkspace.id,
      successCallback,
      settingsUser,
      trackEvent
    )
    setAddClientMatterModalOpen(false)
  }

  // only single CM deletes supported
  const onDeleteClientMatter = async (deleteClientMatter: string) => {
    const successCallback = (deletedClientMatter: ClientMatterWithStats) => {
      const newClientMatters = clientMatters.filter(
        (cm) => cm.name !== deletedClientMatter.name
      )
      setClientMatters(newClientMatters)
      // if selected CM is deleted, remove the selected CM and remove it from URL

      queryClient.setQueryData(
        [HarvQueryKeyPrefix.SettingsClientMatters],
        (oldData: ClientMattersSettingsStore) => {
          if (!oldData)
            return {
              clientMattersWithStats: [deletedClientMatter],
              workspaces: [],
            }

          // Iterate through the existing cache to update items or leave them unchanged
          const updatedClientMattersWithStats =
            oldData.clientMattersWithStats.map((item) => {
              if (item.id === deletedClientMatter.id) {
                return deletedClientMatter
              } else {
                return item
              }
            })

          // Combine the updated existing items with any new items
          return {
            ...oldData,
            clientMattersWithStats: [...updatedClientMattersWithStats],
          }
        }
      )

      if (selectedClientMatter?.name === deletedClientMatter.name) {
        const searchParams = new URLSearchParams(location.search)
        searchParams.delete(CLIENT_MATTER_URL_PARAM)
        setSelectedClientMatter(null)
        navigate(`${location.pathname}?${searchParams.toString()}`, {
          replace: true,
        })
      }
    }
    await handleDeleteClientMatter(
      deleteClientMatter,
      settingsUser,
      selectedWorkspace.id,
      successCallback,
      trackEvent
    )
  }

  const onEditClientMatter = (
    cmName: string,
    cmDesc: Maybe<string>,
    cmAllowed: boolean
  ) => {
    setEditCmName(cmName)
    setEditCmDesc(cmDesc ?? '')
    setCmAllowed(cmAllowed)
    setAddClientMatterModalOpen(true)
  }

  const modalOpenButton = (
    <Button
      id="client-matter-add-button"
      onClick={() => setAddClientMatterModalOpen(true)}
    >
      Add
    </Button>
  )
  const cmData = _.orderBy(
    userClientMatters,
    [
      // First, sort by a combined condition of allowed and not deleted
      (item) => !(item.allowed && _.isNil(item.deletedAt)),
      // Then sort by deletedAt
      (item) => !_.isNil(item.deletedAt),
      // Finally, sort by createdAt
      'createdAt',
    ],
    [
      'asc', // Sort the combined condition in ascending order
      'asc', // Sort deletedAt in ascending order (nulls first)
      'desc', // Sort createdAt in descending order
    ]
  )

  const clientMattersDescription = settingsUser?.IsTerritoryAdmin
    ? 'View your organization’s saved client matter numbers and related information'
    : 'View your saved client matter numbers and related information'

  const showCmManage = canCmUserManage(settingsUser)

  return (
    <>
      <SettingsAppHeader
        actions={
          <DataTableHeader searchable={{ searchQuery, setSearchQuery }}>
            {showCmManage && <div className="mr-3">{modalOpenButton}</div>}
          </DataTableHeader>
        }
      />
      <SettingsLayout id="settings-client-matters">
        <p className="mb-2">{clientMattersDescription}</p>

        <ClientMattersTable
          data={cmData}
          filter={searchQuery}
          setFilter={setSearchQuery}
          onAddClientMatter={onAddClientMatter}
          onEditClientMatter={onEditClientMatter}
          onDeleteClientMatter={onDeleteClientMatter}
          isLoading={isCmPending}
          showCmManagement={showCmManage}
          id="settings-client-matters-table"
        />
        {showCmManage && (
          <ClientMattersAddEditDialog
            clientMatterModalOpen={addClientMatterModalOpen}
            setClientMatterModalOpen={setAddClientMatterModalOpen}
            onCmAdd={onAddClientMatter}
            onCmEdit={onDialogClientMatterEdit}
            editCmName={editCmName}
            editCmDesc={editCmDesc}
            cmAllowed={cmAllowed}
          />
        )}
        {settingsUser?.isClientMattersManagementUser && (
          <div className="mt-12 pb-8" id="client-matter-admin-settings">
            <Divider>Admin settings</Divider>
            <SettingsClientMattersAdmin workspaceId={selectedWorkspace.id} />
          </div>
        )}
      </SettingsLayout>
    </>
  )
}

export default SettingsClientMatters
