import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'

import { TranscriptType } from 'openapi/models/TranscriptType'
import {
  TranscriptsDocument,
  TranscriptsDocumentFromJSON,
  instanceOfTranscriptsDocument,
} from 'openapi/models/TranscriptsDocument'
import {
  TranscriptsQuestionAnswer,
  TranscriptsQuestionAnswerFromJSON,
  instanceOfTranscriptsQuestionAnswer,
} from 'openapi/models/TranscriptsQuestionAnswer'
import {
  TranscriptsQuestionRequest,
  TranscriptsQuestionRequestToJSON,
} from 'openapi/models/TranscriptsQuestionRequest'
import { TranscriptsTask } from 'openapi/models/TranscriptsTask'

import {
  HarveySocketSetter,
  HarveySocketTask,
  InitSocketAndSendQuery,
} from 'utils/use-harvey-socket'

import { newFileHandler } from './new-file-handler-util'

export interface TranscriptsState {
  documents: TranscriptsDocument[]
  questions: TranscriptsQuestionAnswer[]
  currentQuestionAnswer: TranscriptsQuestionAnswer | null
  streamQuestionAnswer: boolean
}

export interface TranscriptsActions {
  setter: HarveySocketSetter
  setDocuments: (documents: TranscriptsDocument[]) => void
  reset: () => void
  removeDocument: (fileName: string) => void
  setCurrentQuestionAnswer: (qa: TranscriptsQuestionAnswer | null) => void
  addCurrentQuestionAnswerToQuestions: () => void
  handleNewFiles: (
    acceptedFiles: File[],
    initSocketAndSendQuery: InitSocketAndSendQuery
  ) => Promise<void>
  setStreamQuestionAnswer: (streamQuestionAnswer: boolean) => void
  handleNewFilesCompleted: () => void
  handleQuestion: (
    question: string,
    docType: TranscriptType,
    initSocketAndSendQuery: InitSocketAndSendQuery
  ) => void
  handleQuestionCompleted: () => void
}

const initialState: TranscriptsState = {
  documents: [],
  questions: [],
  currentQuestionAnswer: null,
  streamQuestionAnswer: false,
}

export const useTranscriptsStore = create(
  devtools(
    immer<TranscriptsState & TranscriptsActions>((set, get) => ({
      ...initialState,
      reset: () => ({ ...initialState }),
      setDocuments: (documents: TranscriptsDocument[]) => set({ documents }),
      setQuestions: (questions: TranscriptsQuestionAnswer[]) =>
        set({ questions }),
      setCurrentQuestionAnswer: (qa: TranscriptsQuestionAnswer | null) =>
        set({ currentQuestionAnswer: qa }),
      removeDocument: (fileName: string) =>
        set((state) => ({
          documents: state.documents.filter(
            (doc) => doc.file.name !== fileName
          ),
        })),
      addCurrentQuestionAnswerToQuestions: () =>
        set((state) => ({
          questions: [...get().questions, state.currentQuestionAnswer],
          currentQuestionAnswer: null,
        })),
      handleNewFiles: async (
        files: File[],
        initSocketAndSendQuery: InitSocketAndSendQuery
      ) => {
        return newFileHandler({ files, initSocketAndSendQuery, get })
      },
      setStreamQuestionAnswer: (streamQuestionAnswer: boolean) =>
        set({ streamQuestionAnswer }),
      handleNewFilesCompleted: () => {
        const { documents, removeDocument } = get()
        for (const doc of documents) {
          if (doc.isLoading) {
            // TODO: Tell user that the file failed to process
            removeDocument(doc.file.name)
          }
        }
      },
      handleQuestion: (
        question: string,
        docType: TranscriptType,
        initSocketAndSendQuery
      ) => {
        const request: TranscriptsQuestionRequest = {
          question,
          documentType: docType,
          documents: get().documents.filter(
            (doc) => doc.metadata?.transcriptType === docType
          ),
        }

        get().setCurrentQuestionAnswer({
          question,
          documentType: docType,
          answer: { text: 'Loading…' },
          isLoading: true,
        })

        initSocketAndSendQuery({
          query: TranscriptsTask.QUESTION,
          additionalAuthParams: {},
          additionalRequestParams: TranscriptsQuestionRequestToJSON(request),
        })
      },
      handleQuestionCompleted: () => {
        const { currentQuestionAnswer, setCurrentQuestionAnswer } = get()
        if (currentQuestionAnswer) {
          setCurrentQuestionAnswer({
            ...currentQuestionAnswer,
            isLoading: false,
          })
        } else {
          setCurrentQuestionAnswer(null)
        }
      },
      setter: (data: Partial<HarveySocketTask>) => {
        const upsertDocument = (docToUpdate: TranscriptsDocument) =>
          set((state) => {
            const index = state.documents.findIndex(
              (doc) => doc.file.name === docToUpdate.file.name
            )
            if (index !== -1) state.documents[index] = docToUpdate
            else state.documents.push(docToUpdate)
          })

        const handleQuestionStream = (qa: TranscriptsQuestionAnswer) => {
          // Only update when streaming enabled or QA complete
          if (get().streamQuestionAnswer || !qa.isLoading) {
            set(() => ({
              currentQuestionAnswer: qa,
            }))
          }
        }

        const responseHandlers = [
          {
            transformer: TranscriptsDocumentFromJSON,
            instanceOf: instanceOfTranscriptsDocument,
            handler: upsertDocument,
          },
          {
            transformer: TranscriptsQuestionAnswerFromJSON,
            instanceOf: instanceOfTranscriptsQuestionAnswer,
            handler: handleQuestionStream,
          },
        ]

        const handleResponse = (response: string) => {
          const data = JSON.parse(response)
          responseHandlers.some(({ transformer, instanceOf, handler }) => {
            try {
              const transformed = transformer(data)
              if (instanceOf(transformed)) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                handler(transformed as any)
                return true // Breaks the loop
              }
              // eslint-disable-next-line no-empty
            } catch (e) {}
            return false
          })
        }

        try {
          if (!data.response) return
          const responses = Array.isArray(data.response)
            ? data.response
            : [data.response]
          responses.forEach(handleResponse)
        } catch (e) {
          console.error('Error handling response', e)
        }
      },
    }))
  )
)
