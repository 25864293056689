import React from 'react'

import { eyesOffTierToReadableName, Workspace } from 'models/workspace'
import { WorkspaceEyesOffTier } from 'openapi/models/WorkspaceEyesOffTier'

import Banner from 'components/ui/banner'
import { Button } from 'components/ui/button'
import { Dialog, DialogContent } from 'components/ui/dialog'
import { Spinner } from 'components/ui/spinner'

interface Props {
  modalOpen: boolean
  setModalOpen: (open: boolean) => void
  onUpdateSubmit: () => void
  isUpdating: boolean
  workspace: Workspace
  newEyesOffTier: WorkspaceEyesOffTier
}

const WorkspaceEyesOffTierUpdateModal: React.FC<Props> = ({
  modalOpen,
  setModalOpen,
  onUpdateSubmit,
  isUpdating,
  workspace,
  newEyesOffTier,
}) => {
  return (
    <Dialog
      open={modalOpen}
      onOpenChange={(open) => {
        setModalOpen(open)
      }}
    >
      <DialogContent showCloseIcon={false}>
        <div>
          <p className="text-xl">Updating workspace eyes-off tier</p>
          <Banner
            title="Are you sure?"
            description={
              <p>
                This will change the eyes-off tier of the workspace from{' '}
                <b>{eyesOffTierToReadableName[workspace.eyesOffTier]}</b> to{' '}
                <b>{eyesOffTierToReadableName[newEyesOffTier]}</b>.
              </p>
            }
            className="mt-4"
          />
          <div className="mt-6 flex flex-row justify-end space-x-2">
            <Button
              variant="ghost"
              onClick={() => {
                setModalOpen(false)
              }}
            >
              Cancel
            </Button>
            <Button
              disabled={isUpdating}
              onClick={onUpdateSubmit}
              className="*:text-sm"
            >
              {isUpdating ? (
                <div className="flex items-center">
                  <Spinner className="top-3 mr-2 h-3 w-3" />
                  Updating…
                </div>
              ) : (
                <span>Update</span>
              )}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default WorkspaceEyesOffTierUpdateModal
