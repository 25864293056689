import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useMount, useUnmount } from 'react-use'

import * as Sentry from '@sentry/browser'
import { MD5 } from 'crypto-js'
import _ from 'lodash'
import { Info, Sparkles } from 'lucide-react'
import { Instance } from 'pspdfkit'

import { uploadFile } from 'api'
import { useHistoryItemQuery } from 'models/queries/use-history-item-query'
import { EventKind } from 'openapi/models/EventKind'
import { UploadedFileToJSON } from 'openapi/models/UploadedFile'
import Services from 'services'
import { FileType } from 'types/file'
import { WorkflowType, WorkflowTypeToTaskType } from 'types/workflows'

import { useNavigateWithQueryParams } from 'hooks/use-navigate-with-query-params'
import { feedbackClosedState } from 'utils/feedback'
import { mbToReadable } from 'utils/file-utils'
import { exportWordWithQuery } from 'utils/markdown'
import { TaskType } from 'utils/task'
import { displayErrorMessage } from 'utils/toast'
import {
  AUTO_ISSUES_LIST_DISABLED_HELP_TEXT,
  AUTO_ISSUES_LIST_ENABLED_HELP_TEXT,
  EMPTY_QUERY_HELP_TEXT,
  ISSUES_LIST_ADD_TOPIC_HELP_TEXT,
  IS_LOADING_HELP_TEXT,
  UPLOAD_DOCUMENT_HELP_TEXT,
} from 'utils/tooltip-texts'
import {
  useBroadcastChannelReceive,
  useBroadcastChannelSend,
} from 'utils/use-broadcast-channel'
import {
  BroadcastChannelType,
  IssuesListRedirectData,
  RegisteredHarveyBroadcastChannels,
} from 'utils/use-broadcast-channel-utils'
import useHarveySocket from 'utils/use-harvey-socket'
import {
  HarveySocketCompletionStatus,
  hasCompletedStreaming,
} from 'utils/use-harvey-socket-utils'
import { isUserInputEmpty } from 'utils/utils'

import ClientMatterSelectionDialog from 'components/client-matters/client-matter-selection-dialog'
import { useClientMattersStore } from 'components/client-matters/client-matters-store'
import { useAnalytics } from 'components/common/analytics/analytics-context'
import useQueryAnalytics from 'components/common/analytics/use-query-analytics'
import { AppHeaderActions } from 'components/common/app-header-actions'
import AskHarveyButton from 'components/common/ask-harvey-button'
import { useAuthUser } from 'components/common/auth-context'
import { BasicInput } from 'components/common/basic-input/basic-input'
import DeprecatedTagInput from 'components/common/deprecated-tag-input/deprecated-tag-input'
import DropzoneDescription from 'components/common/dropzone/dropzone-description'
import ExportDialog from 'components/common/export/export-dialog'
import { ExportOptionValues } from 'components/common/export/types'
import Response from 'components/common/response/response'
import Banner from 'components/ui/banner'
import { Button } from 'components/ui/button'
import { Tabs, TabsContent, TabsList, TabsTrigger } from 'components/ui/tabs'
import { TextLink } from 'components/ui/text-link'
import { ISSUES_LIST_HELP, REDLINES_HELP } from 'components/workflows/constants'
import { WorkflowTypeToDetails } from 'components/workflows/workflow-definitions'
import WorkflowFileInput from 'components/workflows/workflow/components/workflow-file-input'
import WorkflowResponseSources, {
  onAnnotationClick,
} from 'components/workflows/workflow/components/workflow-response-sources'
import { WorkflowsDualPaneLayout } from 'components/workflows/workflow/layouts/dual-pane'
import WorkflowLayout from 'components/workflows/workflow/workflow-layout'
import {
  AUTO_GENERATE_ISSUES_CLICKED_METRIC,
  ISSUES_LIST_ASSISTANT_REDIRECT_CLICKED_METRIC,
  ISSUES_LIST_NON_REDLINES_UPLOADED_METRIC,
} from 'components/workflows/workflows-helpers'

import { useRedlinesStore } from './redlines-store'

const SERVER_ROUTE = 'redlines'
const MIN_ISSUE_LENGTH = 3
const MAX_ISSUE_LENGTH = 100
const MAX_QUERY_LENGTH = 4_000

enum RedlinesTabs {
  IssuesList = 'issues-list',
  OpenEnded = 'open-ended',
}

const RedlinesWorkflow: React.FC = () => {
  const userInfo = useAuthUser()
  const navigate = useNavigateWithQueryParams()
  const pspdfInstanceRef = useRef<Instance | null>(null)
  const { id: historyId } = useParams<{ id: string }>()
  const { historyItem } = useHistoryItemQuery({ id: historyId ?? null })
  const {
    query,
    response,
    annotations,
    setTask,
    setActiveDocument,
    queryId,
    feedback,
    setFeedback,
    isLoading,
    headerText,
    sources,
    activeDocument,
    reset,
    completionStatus,
    hasRedlinesDocument,
    setHasRedlinesDocument,
    metadata,
    issuesTags,
    setIssuesTags,
  } = useRedlinesStore()

  const {
    setQueryViewed,
    recordExport,
    recordQueryCancel,
    recordQuerySubmitted,
    recordQueryCompletion,
    recordReset,
  } = useQueryAnalytics(EventKind.REDLINES)

  const handleReset = () => {
    navigate(workflow.path, { replace: true })
    reset()
    recordReset()
  }

  const [isNewTab, setIsNewTab] = useState(true)
  const [loadingEmptyStateText, setloadingEmptyStateText] = useState('')

  const [activeTab, setActiveTab] = useState<RedlinesTabs>(
    RedlinesTabs.IssuesList
  )

  const { trackEvent } = useAnalytics()

  const TASK_TYPE =
    activeTab === RedlinesTabs.IssuesList
      ? TaskType.ISSUES_LIST
      : TaskType.REDLINES

  const workflowType = WorkflowType.REDLINE_ANALYSIS
  const workflow = WorkflowTypeToDetails[workflowType]

  useEffect(() => {
    // After the component mounts, set isNewTab to false after a delay
    const timeoutId = setTimeout(() => {
      setIsNewTab(false)
    }, 1000)

    return () => {
      clearTimeout(timeoutId)
    }
  }, [])

  useEffect(() => {
    if (!_.isEmpty(metadata?.topics)) {
      setIssuesTags(metadata?.topics)
    }
  }, [metadata, setIssuesTags])

  useBroadcastChannelReceive<IssuesListRedirectData>(
    RegisteredHarveyBroadcastChannels[
      BroadcastChannelType.ASSISTANT_ISSUES_LIST_REDIRECT
    ],
    (data: IssuesListRedirectData) => {
      if (!_.isNil(activeDocument) || !isNewTab) {
        return
      }
      const doc = data.uploadedDoc
      setActiveDocument(doc)
    }
  )

  const sendBroadcastMessage = useBroadcastChannelSend<IssuesListRedirectData>(
    RegisteredHarveyBroadcastChannels[
      BroadcastChannelType.ISSUES_LIST_ASSISTANT_REDIRECT
    ]
  )

  const handleCancel = () => {
    recordQueryCancel()
    sendCancelRequest()
  }

  useMount(() => {
    if (!_.isNil(historyItem)) {
      setTask({
        queryId: historyItem.id,
        response: historyItem.response,
        sources: historyItem.sources,
        annotations: historyItem.annotations,
        completionStatus: HarveySocketCompletionStatus.Completed,
      })
      setFeedback(feedbackClosedState)
      if (historyItem.kind === TaskType.ISSUES_LIST) {
        const issuesTags =
          historyItem.query.length > 0 ? JSON.parse(historyItem.query) : []
        setIssuesTags(issuesTags)
        setActiveTab(RedlinesTabs.IssuesList)
      } else if (historyItem.kind === TaskType.REDLINES) {
        setTask({ query: historyItem.query })
        setActiveTab(RedlinesTabs.OpenEnded)
      }
      const document = historyItem.documents?.find((doc) => {
        return (
          (doc.kind === undefined || doc.kind === 'doc' || doc.kind === '') &&
          doc.url !== ''
        )
      })
      if (!document) {
        displayErrorMessage('Couldn’t find document in history')
        Sentry.captureException('Couldn’t find document in history', {
          extra: { documents: historyItem.documents },
        })
        return
      }

      setActiveDocument(document)
      setQueryViewed()
    }
  })

  useUnmount(() => {
    handleCancel()
    reset()
  })

  useEffect(() => {
    if (
      userInfo.IsHistoryUser &&
      queryId &&
      hasCompletedStreaming(completionStatus)
    ) {
      navigate(`${workflow.path}/${queryId}`, {
        replace: true,
        state: { fetchHistoryItem: false },
      })
      recordQueryCompletion()
    }
  }, [
    queryId,
    completionStatus,
    navigate,
    recordQueryCompletion,
    userInfo,
    workflow.path,
  ])

  const handleFile = async (file: File) => {
    const response = await uploadFile(file, false, { notRedlinesCheck: true })

    if (response.isNotRedlinesDocument) {
      setHasRedlinesDocument(false)
      sendBroadcastMessage({
        file,
        uploadedDoc: response,
      })
      Services.HoneyComb.Record({
        metric: ISSUES_LIST_NON_REDLINES_UPLOADED_METRIC,
        user: userInfo.id,
        workspace: userInfo.workspace.slug,
      })
    } else {
      setHasRedlinesDocument(true)
    }
    setActiveDocument(response)
  }

  const {
    selectedClientMatter,
    setClientMatterSelectDisabled,
    canCmUserQuery,
  } = useClientMattersStore()
  const { initSocketAndSendQuery, sendCancelRequest } = useHarveySocket({
    path: SERVER_ROUTE,
    setter: setTask,
    endCallback: () => {
      setClientMatterSelectDisabled(false)
      setloadingEmptyStateText('')
    },
  })

  const clearExistingQuery = () => {
    setTask({ headerText: '', response: '', sources: [] })
    setFeedback(null)
  }

  const onSubmit = async () => {
    if (!activeDocument) {
      return null
    }
    recordQuerySubmitted({
      event_kind: EventKind.REDLINES,
    })
    clearExistingQuery()
    const client_matter_id = userInfo.isClientMattersReadUser
      ? selectedClientMatter?.id
      : null

    const queryToSend = activeTab === RedlinesTabs.OpenEnded ? query : ''
    const topicsToSend =
      activeTab === RedlinesTabs.IssuesList ? { issues: issuesTags } : {}

    setClientMatterSelectDisabled(true)
    initSocketAndSendQuery({
      query: queryToSend,
      additionalAuthParams: { task_type: TASK_TYPE, client_matter_id },
      additionalRequestParams: {
        // adding source_event_id if we have a query id
        // https://www.notion.so/harveyai/Make-sure-we-keep-honoring-event_source_id-04edcaaae0d8440a9b51808aef7af349?pvs=4
        source_event_id: queryId,
        task_type: TASK_TYPE,
        documents: [UploadedFileToJSON(activeDocument)],
        ...topicsToSend,
      },
    })
  }

  const getHrvyInfoMetadata = (annotationId: string) => {
    return {
      onClick: () =>
        onAnnotationClick(annotations, annotationId, pspdfInstanceRef),
    }
  }

  const handleExportInner = async (withInlineCitations: boolean) => {
    await exportWordWithQuery({
      taskType: TaskType.REDLINES,
      includeAnnotation: withInlineCitations,
      titleText: 'Redlines',
      queryId,
      query,
      response,
      sources,
    })
    recordExport(String(queryId), 'word', withInlineCitations)
  }

  // TODO(Adam): fix the numbers appearing in the response text? or are these redline number? like what are these
  // TODO(Adam): Fix the ugly Span rendering in the export (it is happening in prod)
  const handleExport = async (exportValues: ExportOptionValues) => {
    await handleExportInner(!!exportValues.includeAnnotation)
  }

  const userRequiresClientMatter =
    userInfo.isClientMattersReadUser && !canCmUserQuery()

  const showFeedback =
    hasCompletedStreaming(completionStatus) && !feedback?.closed

  const isIssuesListTabEmpty =
    activeTab === RedlinesTabs.IssuesList && _.isEmpty(issuesTags)
  const isOpenEndedTabEmpty =
    activeTab === RedlinesTabs.OpenEnded && query.trim().length === 0

  const [
    isAutoIssuesListClientMatterDialogOpen,
    setIsAutoIssuesListClientMatterDialogOpen,
  ] = useState(false)

  const isAutoIssuesListDisabled =
    !activeDocument || isLoading || !_.isEmpty(issuesTags)

  const isAskHarveyDisabled =
    isLoading || isIssuesListTabEmpty || isOpenEndedTabEmpty || !activeDocument

  const getAutoIssuesListTooltip = () => {
    if (_.isNil(activeDocument)) {
      return UPLOAD_DOCUMENT_HELP_TEXT
    } else if (isLoading) {
      return IS_LOADING_HELP_TEXT
    } else if (!_.isEmpty(issuesTags)) {
      return AUTO_ISSUES_LIST_DISABLED_HELP_TEXT
    } else {
      return AUTO_ISSUES_LIST_ENABLED_HELP_TEXT
    }
  }

  const getAskHarveyTooltip = () => {
    if (_.isNil(activeDocument)) {
      return UPLOAD_DOCUMENT_HELP_TEXT
    } else if (isLoading) {
      return IS_LOADING_HELP_TEXT
    } else if (isIssuesListTabEmpty) {
      return ISSUES_LIST_ADD_TOPIC_HELP_TEXT
    } else if (isOpenEndedTabEmpty) {
      return EMPTY_QUERY_HELP_TEXT
    } else {
      return ''
    }
  }

  const EmptyState = () => {
    return (
      <div className="flex grow flex-col items-center justify-center">
        {loadingEmptyStateText.length > 0 ? (
          <p className="text-muted">{loadingEmptyStateText}</p>
        ) : (
          <>
            <p>Add a topic to get started</p>
            <p className="text-xs text-muted">
              e.g., &quot;Confidentiality&quot;, &quot;Transaction
              structure&quot;, &quot;Financing&quot;
            </p>
          </>
        )}
      </div>
    )
  }

  const redlinesBanner = !hasRedlinesDocument ? (
    <div>
      <Banner
        title="No redlined changes detected!"
        description={
          <div>
            <p>
              The file {activeDocument?.name} you have uploaded does not appear
              to be a redline document.
            </p>
            <p>
              You can use{' '}
              <TextLink
                label="Assistant"
                href="/assistant"
                openInNewTab
                linkClickCallback={() => {
                  setHasRedlinesDocument(true)
                  Services.HoneyComb.Record({
                    metric: ISSUES_LIST_ASSISTANT_REDIRECT_CLICKED_METRIC,
                    user: userInfo.id,
                    workspace: userInfo.workspace.slug,
                  })
                  trackEvent(
                    'Redlines to Assistant Issues List Redirect Clicked'
                  )
                }}
              />{' '}
              to query a document that does not contain redlined changes.
            </p>
          </div>
        }
        leadingIcon={<Info />}
        onClose={() => setHasRedlinesDocument(true)}
      />
    </div>
  ) : (
    <></>
  )

  const textareaRef = React.useRef<HTMLTextAreaElement | null>(null)
  const tagInputRef = React.useRef<HTMLInputElement | null>(null)

  const onQueryChange = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
    setTask({ query: e.target.value })
  }

  const emptyStateText =
    userInfo.GetHelpPanel(WorkflowTypeToTaskType[workflowType])?.content ??
    (activeTab === RedlinesTabs.IssuesList ? ISSUES_LIST_HELP : REDLINES_HELP)

  const onTabChange = (value: string) => {
    setActiveTab(value as RedlinesTabs)
  }

  const onAutoGenerateSubmit = async () => {
    setIssuesTags([])
    setloadingEmptyStateText('Generating issues…')
    Services.HoneyComb.Record({
      metric: AUTO_GENERATE_ISSUES_CLICKED_METRIC,
      user: userInfo.id,
      workspace: userInfo.workspace.slug,
      documentName: activeDocument ? MD5(activeDocument.name).toString() : '',
    })
    await onSubmit()
  }

  const autoGenerateButtonText = 'Auto-generate'

  const TagInputFooter = (
    <>
      <ClientMatterSelectionDialog
        isClientMatterDialogOpen={isAutoIssuesListClientMatterDialogOpen}
        setIsClientMatterDialogOpen={setIsAutoIssuesListClientMatterDialogOpen}
        onContinue={onAutoGenerateSubmit}
        continueButtonText={autoGenerateButtonText}
      />
      <div className="flex flex-row items-center space-x-2">
        <div className="w-full">
          <Button
            className="h-8 w-full"
            tooltip={getAutoIssuesListTooltip()}
            tooltipClassName="w-full"
            disabled={isAutoIssuesListDisabled}
            onClick={() => {
              if (userRequiresClientMatter) {
                setIsAutoIssuesListClientMatterDialogOpen(true)
              } else {
                onAutoGenerateSubmit()
              }
            }}
            variant="outline"
            data-testid="auto-generate"
          >
            <div className="flex w-full items-center justify-center">
              <Sparkles size={14} className="mr-2" />
              <span className="text-sm text-primary opacity-100 transition">
                {autoGenerateButtonText}
              </span>
            </div>
          </Button>
        </div>
        <AskHarveyButton
          handleSubmit={onSubmit}
          tooltip={getAskHarveyTooltip()}
          disabled={isAskHarveyDisabled}
          inputRef={tagInputRef}
        />
      </div>
    </>
  )

  const acceptedFileTypes = [FileType.PDF, FileType.WORD]
  const dropzoneDescription = (
    <DropzoneDescription
      fileTypes={acceptedFileTypes}
      maxSize={mbToReadable(20)}
    >
      Handles redline PDF documents that represent insertions with blue text and
      deletions with red text, and Microsoft Word (.docx) documents with tracked
      changes.
    </DropzoneDescription>
  )

  const userInputs: unknown[] = [query, activeDocument, issuesTags]
  const emptyUserInput = isUserInputEmpty(userInputs)
  const resetDisabled = emptyUserInput || isLoading

  return (
    <WorkflowLayout
      workflowType={workflow.type}
      title={workflow.name}
      appHeaderActions={
        <>
          <AppHeaderActions
            handleReset={handleReset}
            resetDisabled={resetDisabled}
            saveExample={{
              show: userInfo.canSeeSaveExample,
              disabled: !hasCompletedStreaming(completionStatus),
              params: {
                eventId: String(queryId),
              },
            }}
          />
          <ExportDialog
            hasSources
            onExport={handleExport}
            disabled={!hasCompletedStreaming(completionStatus)}
          />
        </>
      }
    >
      <WorkflowsDualPaneLayout
        input={
          <WorkflowFileInput
            eventKind={EventKind.REDLINES}
            handleFile={handleFile}
            store={useRedlinesStore}
            fileTypes={acceptedFileTypes}
            pspdfInstanceRef={pspdfInstanceRef}
            pdfViewerFooter={redlinesBanner}
            dropzoneDescription={dropzoneDescription}
          >
            <div className="min-h-80 space-y-2">
              <Tabs
                defaultValue={RedlinesTabs.OpenEnded}
                onValueChange={onTabChange}
                value={activeTab}
              >
                <TabsList className="w-full">
                  <TabsTrigger
                    value={RedlinesTabs.IssuesList}
                    className="w-full"
                    disabled={isLoading}
                  >
                    Issues list
                  </TabsTrigger>
                  <TabsTrigger
                    value={RedlinesTabs.OpenEnded}
                    className="w-full"
                    disabled={isLoading}
                  >
                    Q&A
                  </TabsTrigger>
                </TabsList>
                <TabsContent value={RedlinesTabs.IssuesList}>
                  <DeprecatedTagInput
                    addButtonText="Add topic"
                    closeableTags
                    emptyState={<EmptyState />}
                    inputPlaceholder="Add a topic"
                    tags={issuesTags}
                    setTags={setIssuesTags}
                    minInputLength={MIN_ISSUE_LENGTH}
                    maxInputLength={MAX_ISSUE_LENGTH}
                    className="min-h-80"
                    footerElement={TagInputFooter}
                    disabled={isLoading}
                    commandEnterRef={tagInputRef}
                  />
                </TabsContent>
                <TabsContent value={RedlinesTabs.OpenEnded}>
                  <div className="space-y-2">
                    <BasicInput
                      textareaRef={textareaRef}
                      placeholder="Enter your prompt here"
                      text={query}
                      onTextChange={onQueryChange}
                      showSubmitButton={false}
                      maxCharacters={MAX_QUERY_LENGTH}
                      className="h-[272px]"
                      disabled={isLoading}
                    />
                    <AskHarveyButton
                      handleSubmit={onSubmit}
                      disabled={isAskHarveyDisabled}
                      tooltip={getAskHarveyTooltip()}
                      className="w-full"
                      size="lg"
                      isLoading={isLoading}
                      inputRef={textareaRef}
                    />
                  </div>
                </TabsContent>
              </Tabs>
            </div>
          </WorkflowFileInput>
        }
        output={
          <Response
            markdown={response}
            isLoading={isLoading}
            headerText={headerText}
            handleCancel={handleCancel}
            emptyStateText={emptyStateText}
            getHrvyInfoMetadata={getHrvyInfoMetadata}
            footerChildren={
              <WorkflowResponseSources
                sources={sources}
                pspdfInstanceRef={pspdfInstanceRef}
              />
            }
            queryId={queryId}
            handleSaveFeedback={showFeedback ? setFeedback : undefined}
          />
        }
      />
    </WorkflowLayout>
  )
}

export default RedlinesWorkflow
