import React from 'react'

import { cn } from 'utils/utils'

interface HarveyWordMarkProps {
  className?: string
}

const HarveyWordMark: React.FC<HarveyWordMarkProps> = ({ className = '' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 3162 961"
      preserveAspectRatio="xMidYMid meet"
      className={cn('fill-primary-inverse', className)}
    >
      <path
        fill="currentColor"
        d="M2821 961h-116l167-255-216-444-73-61v-4h251v4l-77 50 160 336 131-334-80-52v-4h194v4l-69 62-272 698Zm-594-540c2 143 78 234 181 234 59 0 107-21 153-76l26 24c-66 88-126 121-208 121-138 0-238-111-238-267 0-148 112-271 246-271 100 0 196 71 196 219v16h-356ZM862 572c0 50 35 84 84 84 37 0 78-18 121-48V442l-110 26c-63 14-95 54-95 104Zm205-173v-47c0-82-40-125-101-125-60 0-120 45-180 133h-4V222c58-19 131-36 191-36 111 0 188 47 188 154v310l80 60v4h-177v-68c-36 40-92 78-151 78-83 0-153-59-153-142 0-74 54-123 173-151l134-32ZM720 714H436v-4l90-60V368H194v282l90 60v4H0v-4l90-60V64L0 4V0h284v4l-90 60v257h332V64L436 4V0h284v4l-90 60v586l90 60v4Zm1510-337h250v-7c0-95-42-143-111-143-83 0-128 74-139 150Zm-577-85c-29-13-57-19-86-19-44 0-88 17-132 49v328l100 60v4h-274v-4l80-60V277l-80-40v-4l174-42v92c41-43 100-86 178-86h200v4l-74 47 159 331 138-325-80-53v-4h200v4l-73 62-201 465h-18l-211-436Z"
      />
    </svg>
  )
}

export default HarveyWordMark
