import React from 'react'

import { Column } from '@tanstack/react-table'
import { ArrowDown, ArrowUp, ArrowUpDown } from 'lucide-react'

import { Button } from 'components/ui/button'

interface DataTableSortHeaderProps {
  column: Column<any, any>
  header: string
  tooltipText?: string
  tooltipDelay?: number
  tooltipPopoverClassName?: string
  onMouseEnter?: () => void
}

const DataTableSortHeader = ({
  column,
  header,
  tooltipText,
  tooltipDelay,
  tooltipPopoverClassName,
  onMouseEnter,
}: DataTableSortHeaderProps) => {
  const currentSort = column.getIsSorted()

  const onSortClick = () => {
    if (currentSort === false) {
      column.toggleSorting(false) // sort ascending
    } else if (currentSort === 'asc') {
      column.toggleSorting(true) // sort descending
    } else if (currentSort === 'desc') {
      column.clearSorting() // remove sorting
    }
  }

  const sortArrow =
    currentSort === false ? (
      <ArrowUpDown className="h-3 w-3" />
    ) : currentSort === 'asc' ? (
      <ArrowDown className="h-3 w-3" />
    ) : (
      <ArrowUp className="h-3 w-3" />
    )

  return (
    <Button
      variant="ghost"
      onClick={onSortClick}
      tooltip={tooltipText}
      tooltipPopoverClassName={tooltipPopoverClassName}
      tooltipDelay={tooltipDelay}
      onMouseEnter={onMouseEnter}
    >
      <p className="text-nowrap font-semibold">{header}</p>
      <div className="ml-2">{sortArrow}</div>
    </Button>
  )
}

export default DataTableSortHeader
