import { ErrorKind } from 'openapi/models/ErrorKind'
import Services from 'services'

export enum HarveySocketCompletionStatus {
  Loading,
  Completed,
  Cancelled,
  Error,
}

export const hasCompletedStreaming = (
  status: HarveySocketCompletionStatus | null
) => status === HarveySocketCompletionStatus.Completed

export const getErrorMessage = (error: string, statusCode: number) => {
  // TODO: refactor this rest of this file so that error is an actual object and more strongly typed
  let kind = ''
  let parsedErrorMessage = ''
  try {
    const parsedError = JSON.parse(error)
    kind = parsedError.kind
    if (kind === ErrorKind.USER_ERROR_MESSAGE) {
      return parsedError.error !== '' ? parsedError.error : UNCAUGHT_ERROR
    }
    parsedErrorMessage = parsedError.error
  } catch (e) {
    // DO Nothing since error can be a string sometimes
  }

  if (parsedErrorMessage === FORCED_LOGOUT) {
    console.info('forcing user logout')
    Services.Backend.logout()
    return
  }

  if (IsOpenAIerror(error, kind)) {
    return OPEN_AI_ERROR
  }

  if (IsAzureError(error, kind)) {
    return AZURE_ERROR
  }

  if (
    error === OVERFLOW_PROMPT_ERROR ||
    error === EMPTY_PROMPT_ERROR ||
    error === UPLOAD_ERROR ||
    error === NETWORK_ERROR ||
    error === PASSWORD_PROTECTED_FILE_ERROR ||
    error.replace(/(one|\d+) documents?/g, '{documentLimit}') ===
      TOO_MANY_FILES ||
    error.replaceAll(/\d/g, '') ===
      FILE_TOO_LARGE.replaceAll(/\d/g, '')
        .replaceAll('{fileSize}', '')
        .replaceAll('{maxFileSize}', '') ||
    error.startsWith('The file type you have uploaded is unsupported.')
  ) {
    return error
  }

  if (
    statusCode === 429 ||
    kind === ErrorKind.RATE_LIMITED ||
    error.replace(/\s+|_/g, '').toLowerCase().includes('ratelimit')
  ) {
    return RATE_LIMIT
  }

  if (statusCode === 422 && kind === ErrorKind.UNPROCESSABLE_CONTENT) {
    return UNSAFE_ERROR
  }

  return UNCAUGHT_ERROR
}

export const IsOpenAIerror = (err: string, kind: string): boolean => {
  return (
    err === OPEN_AI_ERROR ||
    kind === ErrorKind.OPENAI_ERROR ||
    kind === ErrorKind.EMBEDDING_ERROR
  )
}

export const IsAzureError = (err: string, kind: string): boolean => {
  return (
    err === AZURE_ERROR ||
    kind === ErrorKind.AZURE_OPENAI_ERROR ||
    kind === ErrorKind.AZURE_EMBEDDING_ERROR
  )
}

export const UPLOAD_ERROR = `Sorry, Harvey was unable to upload your document. This may be caused by an unstable internet connection. Please try uploading again and contact support@harvey.ai if this issue persists.`

export const TOO_MANY_FILES = `You can only upload {documentLimit} at a time.`

export const FILE_TOO_LARGE = `The document you uploaded is too large ({fileSize}MB). Harvey currently requires documents to be less than ({maxFileSize}MB).`

export const RATE_LIMIT = `You have submitted more simultaneous requests than permitted by your organization (3 requests / minute). Please wait a couple of minutes before attempting to submit more requests.`

export const EMPTY_PROMPT_ERROR = `It looks like you haven’t given Harvey a task to complete, please describe your task in the shaded area above the “Ask Harvey” button. If you think you received this message in error, please reach out to support@harvey.ai.`

export const OVERFLOW_PROMPT_ERROR = `It looks like your task request is too long, try limiting your request to fewer than 2000 characters for Harvey to be able to provide an effective answer. We hope to get Harvey to respond to longer requests in the near future.`

export const UNCAUGHT_ERROR = `An unexpected error occurred. This error (but no user data) has been provided to the team at Harvey and we are working to fix it. If you have any questions or see this error repeatedly, please reach out to support@harvey.ai.`

export const UNSAFE_ERROR = `The task you have requested cannot be completed because it contains language that potentially requests an inappropriate or offensive response from Harvey.`

export const NETWORK_ERROR = `Sorry, Harvey was unable to process this request due to a network issue. This may be caused by an unstable internet connection. Please check your internet connection and try your request again. Contact support@harvey.ai if this issue persists.`

export const PASSWORD_PROTECTED_FILE_ERROR = `The file you have uploaded is password protected. Harvey is unable to process password protected files.`

export const OPEN_AI_ERROR =
  'Unfortunately OpenAI is experiencing a service outage. We are actively investigating the issue and working with them to resolve it. We hope to restore functionality shortly.'

export const AZURE_ERROR =
  'Unfortunately Microsoft Azure is experiencing a service outage. We are actively investigating the issue and working with them to resolve it. We hope to restore functionality shortly.'

export const FORCED_LOGOUT = 'Forced Logout'
