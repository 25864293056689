import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'

import * as Sentry from '@sentry/react'
import { ConfigProvider } from 'antd'

import { initDatadog } from 'utils/datadog'
import { isSentryLoggingEnabled } from 'utils/env'
import { initSentry, SentryDsns } from 'utils/sentry'

import Auth0ProviderWithRedirect from 'components/auth/auth-provider-with-redirect'

import App from './app'
import './index.css'
import reportWebVitals from './report-web-vitals'
import Services from './services'
import { Env } from './utils/env'
import { environment, site } from './utils/server-data'

// We have to use an env var here so that the library gets tree-shaken
// out of the build. NODE_ENV is set to "development" in dev builds
// and "production" in prod builds.
if (process.env.NODE_ENV !== 'production') {
  import('@axe-core/react')
    .then(async (axe) => {
      await axe.default(React, ReactDOM, 1000)
    })
    .catch((error) => {
      Sentry.captureException(error)
    })
}

if (isSentryLoggingEnabled) {
  initSentry(SentryDsns.FRONTEND)
}

initDatadog()

Services.HoneyComb.HandleUncaughtErrors()

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  (() => {
    const content = (
      <ConfigProvider theme={{ token: { colorPrimary: '#1452F1' } }}>
        <BrowserRouter>
          <Auth0ProviderWithRedirect>
            <App />
          </Auth0ProviderWithRedirect>
        </BrowserRouter>
      </ConfigProvider>
    )

    // with the migration to vite, we need to explicitly disable StrictMode for non-local development environments
    const shouldEnableStrictMode =
      (environment as string) === 'development-local' ||
      environment === Env.LOCAL
    if (shouldEnableStrictMode) {
      return <React.StrictMode>{content}</React.StrictMode>
    }
    return content
  })()
)

console.info(
  `initialized app with service: ${process.env.REACT_APP_SERVICE}, at revision: ${process.env.REACT_APP_REVISION}, in environment: ${environment}, on site: ${site}, with locale: ${navigator.language}`
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
