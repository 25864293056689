import { ContractsDocumentExtractedTermsValue } from 'openapi/models/ContractsDocumentExtractedTermsValue'

import { EXTRACT_NOT_AVAILABLE_VALUE } from 'components/workflows/workflow/contracts/config'

export const termsViewerSort = (
  terms: string[],
  extractedTerms: Record<string, ContractsDocumentExtractedTermsValue>
) => {
  // Down sort terms that are not available
  // Otherwise, sort alphabetically
  return terms.sort((a, b) => {
    const aValue = extractedTerms[a].text.includes(EXTRACT_NOT_AVAILABLE_VALUE)
    const bValue = extractedTerms[b].text.includes(EXTRACT_NOT_AVAILABLE_VALUE)

    if (aValue && !bValue) return 1
    if (!aValue && bValue) return -1
    return a.localeCompare(b)
  })
}
