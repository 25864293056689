import React, { useEffect } from 'react'
import { useMount } from 'react-use'

import _ from 'lodash'

import { TranscriptType } from 'openapi/models/TranscriptType'
import { TranscriptsDocument } from 'openapi/models/TranscriptsDocument'
import { TranscriptsQuestionAnswer } from 'openapi/models/TranscriptsQuestionAnswer'

import { displaySuccessMessage } from 'utils/toast'
import useHarveySocket, {
  HarveySocketSetter,
  InitSocketAndSendQuery,
} from 'utils/use-harvey-socket'

import AskHarveyButton from 'components/common/ask-harvey-button'
import Markdown from 'components/common/markdown/markdown'
import { Button } from 'components/ui/button'
import { Card, CardHeader, CardTitle } from 'components/ui/card'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/ui/select'
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
} from 'components/ui/sheet'
import { Spinner } from 'components/ui/spinner'
import { Textarea } from 'components/ui/text-area'
import * as config from 'components/workflows/workflow/transcripts/config'

type Params = {
  documents: TranscriptsDocument[]
  showSheet: boolean
  setShowSheet: (showSheet: boolean) => void
  currentQuestionAnswer: TranscriptsQuestionAnswer | null
  addCurrentQuestionAnswerToQuestions: () => void
  type?: TranscriptType
  onSourceClick: (source: string) => void
  setStreamQuestionAnswer: (value: boolean) => void
  setter: HarveySocketSetter
  handleQuestion: (
    question: string,
    docType: TranscriptType,
    initSocketAndSendQuery: InitSocketAndSendQuery
  ) => void
  handleQuestionCompleted: () => void
}

export const TranscriptsQuestionSheet: React.FC<Params> = ({
  documents,
  showSheet,
  setShowSheet,
  currentQuestionAnswer,
  addCurrentQuestionAnswerToQuestions,
  type,
  onSourceClick,
  setStreamQuestionAnswer,
  setter,
  handleQuestion,
  handleQuestionCompleted,
}) => {
  const { initSocketAndSendQuery } = useHarveySocket({
    path: config.ROUTE,
    setter,
    endCallback: handleQuestionCompleted,
    closeOnUnmount: false,
  })

  const [question, setQuestion] = React.useState('')
  const [selectedDocumentType, setSelectedDocumentType] =
    React.useState<TranscriptType | null>(
      currentQuestionAnswer?.documentType ?? type ?? null
    )

  const availableTypes = new Set(
    documents
      .filter((doc) => !doc.isLoading)
      .map((doc) => doc.metadata?.transcriptType)
  )

  // Only stream question answer when the sheet is open
  useEffect(() => {
    setStreamQuestionAnswer(showSheet)
  }, [setStreamQuestionAnswer, showSheet])

  const addToQuestionsDisabled =
    currentQuestionAnswer?.isLoading ||
    question === '' ||
    currentQuestionAnswer === null

  useMount(() => {
    if (currentQuestionAnswer) {
      setQuestion(currentQuestionAnswer.question)
    }
  })

  const handleAddToQuestions = () => {
    addCurrentQuestionAnswerToQuestions()
    setQuestion('')
    setShowSheet(false)
    displaySuccessMessage('Question added to topics')
  }

  const getHrvyInfoMetadata = (id: string) => {
    return {
      onClick: () => onSourceClick(id),
    }
  }

  const textareaRef = React.useRef<HTMLTextAreaElement | null>(null)

  return (
    <Sheet
      onOpenChange={(value: boolean) => setShowSheet(value)}
      open={showSheet}
      key="question-sheet"
    >
      <SheetContent className="w-full sm:max-w-2xl">
        <SheetHeader>
          <div>
            <SheetTitle>Harvey Q&A</SheetTitle>
            <SheetDescription>
              Ask a question about the {selectedDocumentType} transcripts
            </SheetDescription>
          </div>
        </SheetHeader>
        <div className="m-4 mb-16 space-y-2">
          <Select
            disabled={currentQuestionAnswer?.isLoading}
            value={selectedDocumentType ?? undefined}
            onValueChange={(value) =>
              setSelectedDocumentType(value as TranscriptType)
            }
          >
            <SelectTrigger className="h-8 w-48 text-nowrap">
              <SelectValue placeholder="Select a document type" />
            </SelectTrigger>
            <SelectContent>
              {Array.from(availableTypes).map((type) => (
                <SelectItem key={type} value={type as TranscriptType}>
                  {`${_.startCase(type)}s`}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
          <div className="relative">
            {currentQuestionAnswer?.isLoading && (
              <Spinner className="absolute right-0 top-3 h-4 w-4" />
            )}
            <Textarea
              disabled={currentQuestionAnswer?.isLoading}
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
              placeholder="Ask a question about the transcripts…"
              className="resize-none"
              ref={textareaRef}
            />
          </div>
          {currentQuestionAnswer?.answer.text && (
            <Card className="bg-secondary px-4 py-3">
              <CardHeader className="p-0">
                <CardTitle>Answer</CardTitle>
              </CardHeader>
              <Markdown
                getHrvyInfoMetadata={getHrvyInfoMetadata}
                content={currentQuestionAnswer.answer.text}
                className="mt-2"
              />
              <div className="mt-4 flex justify-end">
                <Button
                  disabled={addToQuestionsDisabled}
                  variant="outline"
                  onClick={handleAddToQuestions}
                >
                  Save as new Theme
                </Button>
              </div>
            </Card>
          )}
        </div>
        <SheetFooter className="justify-end space-x-2">
          <div>
            <AskHarveyButton
              handleSubmit={() => {
                handleQuestion(
                  question,
                  selectedDocumentType!,
                  initSocketAndSendQuery
                )
              }}
              disabled={
                currentQuestionAnswer?.isLoading ||
                question === '' ||
                !selectedDocumentType
              }
              inputRef={textareaRef}
            />
          </div>
        </SheetFooter>
      </SheetContent>
    </Sheet>
  )
}
