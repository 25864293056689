/* tslint:disable */
/* eslint-disable */
/**
 * PwC Deals API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SocketMessageResponse } from './SocketMessageResponse';
import {
    SocketMessageResponseFromJSON,
    SocketMessageResponseFromJSONTyped,
    SocketMessageResponseToJSON,
    SocketMessageResponseToJSONTyped,
} from './SocketMessageResponse';
import type { DiligenceTask } from './DiligenceTask';
import {
    DiligenceTaskFromJSON,
    DiligenceTaskFromJSONTyped,
    DiligenceTaskToJSON,
    DiligenceTaskToJSONTyped,
} from './DiligenceTask';

/**
 * 
 * @export
 * @interface DiligenceSection
 */
export interface DiligenceSection {
    /**
     * 
     * @type {string}
     * @memberof DiligenceSection
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof DiligenceSection
     */
    description: string;
    /**
     * 
     * @type {Array<DiligenceTask>}
     * @memberof DiligenceSection
     */
    tasks: Array<DiligenceTask>;
    /**
     * 
     * @type {SocketMessageResponse}
     * @memberof DiligenceSection
     */
    executiveSummary?: SocketMessageResponse;
    /**
     * 
     * @type {boolean}
     * @memberof DiligenceSection
     */
    isLoading?: boolean;
}

/**
 * Check if a given object implements the DiligenceSection interface.
 */
export function instanceOfDiligenceSection(value: object): value is DiligenceSection {
    if (!('title' in value) || value['title'] === undefined) return false;
    if (!('description' in value) || value['description'] === undefined) return false;
    if (!('tasks' in value) || value['tasks'] === undefined) return false;
    return true;
}

export function DiligenceSectionFromJSON(json: any): DiligenceSection {
    return DiligenceSectionFromJSONTyped(json, false);
}

export function DiligenceSectionFromJSONTyped(json: any, ignoreDiscriminator: boolean): DiligenceSection {
    if (json == null) {
        return json;
    }
    return {
        
        'title': json['title'],
        'description': json['description'],
        'tasks': ((json['tasks'] as Array<any>).map(DiligenceTaskFromJSON)),
        'executiveSummary': json['executive_summary'] == null ? undefined : SocketMessageResponseFromJSON(json['executive_summary']),
        'isLoading': json['is_loading'] == null ? undefined : json['is_loading'],
    };
}

  export function DiligenceSectionToJSON(json: any): DiligenceSection {
      return DiligenceSectionToJSONTyped(json, false);
  }

  export function DiligenceSectionToJSONTyped(value?: DiligenceSection | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'title': value['title'],
        'description': value['description'],
        'tasks': ((value['tasks'] as Array<any>).map(DiligenceTaskToJSON)),
        'executive_summary': SocketMessageResponseToJSON(value['executiveSummary']),
        'is_loading': value['isLoading'],
    };
}

