import * as Sentry from '@sentry/browser'
import { Query, QueryKey } from '@tanstack/react-query'

import { FetchHistoryItem } from 'models/fetchers/history-fetcher'
import Services from 'services'
import { Maybe } from 'types'
import { HistoryItem } from 'types/history'

import { HarvQueryKeyPrefix } from './all-query-keys'
import { useWrappedQuery } from './lib/use-wrapped-query'

export const historyItemQuery = (
  id: Maybe<string>,
  throwOnError: boolean = false,
  maxRetryCount: number = 3
) => ({
  queryKey: [HarvQueryKeyPrefix.HistoryItemQuery, id],
  queryFn: async () => FetchHistoryItem({ id, throwOnError, maxRetryCount }),
  initialData: null,
})

const vaultHistoryItemQuery = ({
  id,
  vaultFolderId,
  throwOnError = false,
  maxRetryCount = 3,
  skipSources = false,
}: {
  id: Maybe<string>
  vaultFolderId: string
  throwOnError: boolean
  maxRetryCount: number
  skipSources?: boolean
}) => ({
  queryKey: [HarvQueryKeyPrefix.VaultHistoryItemQuery, id, skipSources],
  queryFn: async () => {
    const historyItem = await FetchHistoryItem({
      id,
      throwOnError,
      maxRetryCount,
      useVaultEndpoint: true,
      skipSources,
    })
    if (historyItem && historyItem.vaultFolderId !== vaultFolderId) {
      throw new Error(
        `History item ${historyItem.id} does not belong to the vault project ${vaultFolderId}`
      )
    }
    return historyItem
  },
  initialData: null,
})

export const useHistoryItemQuery = ({
  id,
  isEnabled = true,
  throwOnError = false,
  refetchInterval,
  vaultFolderId,
  skipSources = false,
}: {
  id: Maybe<string>
  isEnabled?: boolean
  throwOnError?: boolean
  refetchInterval?: (
    query: Query<HistoryItem | null, Error, HistoryItem | null, QueryKey>
  ) => number | false | undefined
  vaultFolderId?: string
  skipSources?: boolean
}) => {
  const {
    isPending,
    error,
    data: historyItem,
    isLoading,
    isFetching,
    isFetched,
  } = useWrappedQuery({
    enabled: !!id && isEnabled,
    refetchInterval,
    refetchOnWindowFocus: false,
    // Set maxRetryCount to 0 because we will handle retries in the useQuery hook
    ...(vaultFolderId
      ? vaultHistoryItemQuery({
          id,
          vaultFolderId,
          throwOnError,
          maxRetryCount: 0,
          skipSources,
        })
      : historyItemQuery(id, throwOnError, 0)),
  })

  if (error) {
    Sentry.captureException(error)
    Services.HoneyComb.RecordError(error)
  }

  return { historyItem, isPending, isLoading, isFetching, isFetched, error }
}
