import { useState, useEffect, useCallback } from 'react'

import {
  getWorkspacePermUserCounts,
  PermUserCountByWorkspace,
} from 'models/workspace'

const useWorkspacePermUserCounts = ({
  workspaceId,
}: {
  workspaceId: string | undefined
}) => {
  const [permUserCounts, setPermUserCounts] =
    useState<PermUserCountByWorkspace>({})

  const fetchWorkspacePermUserCounts = useCallback(async () => {
    if (!workspaceId) return
    const permCounts = await getWorkspacePermUserCounts(Number(workspaceId))
    setPermUserCounts(permCounts)
  }, [workspaceId])

  useEffect(() => {
    void fetchWorkspacePermUserCounts()
  }, [fetchWorkspacePermUserCounts])

  return { permUserCounts, fetchWorkspacePermUserCounts }
}

export default useWorkspacePermUserCounts
