import React from 'react'

import { AlertCircle, CheckCircle2, X } from 'lucide-react'
import { toast } from 'sonner'

import { cn } from 'utils/utils'

import { useAnalytics } from 'components/common/analytics/analytics-context'

import { Button } from './button'
import { CTA } from './types'

interface ToastProps {
  toastId: string | number
  message: string | ((toastId: string | number) => React.ReactNode)
  title?: string
  variant?: 'default' | 'destructive' | 'warning' | 'success' // convert this to an enum to be used in other UI components
  cta?: CTA
  dataTestId?: string
}

const VariantToLeadingIcon = {
  default: null,
  warning: (
    <AlertCircle
      size={24}
      className="fill-warning stroke-2 text-primary-inverse"
    />
  ),
  success: (
    <CheckCircle2
      size={24}
      className="fill-success stroke-2 text-primary-inverse"
    />
  ),
  destructive: (
    <AlertCircle
      size={24}
      className="fill-destructive stroke-2 text-primary-inverse"
    />
  ),
}

const Toast = ({
  toastId,
  message,
  variant,
  title,
  cta,
  dataTestId,
}: ToastProps) => {
  const { trackEvent } = useAnalytics()
  const leadingIcon = VariantToLeadingIcon[variant ?? 'default']

  const handleDismiss = (toastId: string | number) => {
    trackEvent('Toast Dismissed', {
      toast_id: toastId,
      variant: variant,
      title: title,
      message: message,
    })
    toast.dismiss(toastId)
  }

  return (
    <div
      className="sonner-toast relative w-[360px] rounded-lg border bg-primary px-2 py-4 shadow-md"
      data-testid={dataTestId}
    >
      <div className="flex w-full flex-row space-x-3">
        <div className="ml-1">{leadingIcon}</div>
        <div className={cn('w-[340px] space-y-1', { 'mt-0.5': !title })}>
          {title && <p className="text-sm font-semibold">{title}</p>}
          <p className="line-clamp-8 text-sm">
            {typeof message === 'function' ? message(toastId) : message}
          </p>
        </div>
        <div className="mt-0.5 pr-4">
          <X
            className="cursor-pointer text-inactive transition hover:text-secondary"
            size={16}
            onClick={() => handleDismiss(toastId)}
          />
        </div>
      </div>
      {cta && cta.onClick && (
        <div className="mr-4 mt-4 flex justify-end">
          <Button variant={cta.variant} onClick={cta.onClick}>
            {cta.label}
          </Button>
        </div>
      )}
    </div>
  )
}

export default Toast
