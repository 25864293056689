import React from 'react'

import { Trash, Plus, SquareX } from 'lucide-react'

import { EvaluationQuestion } from 'openapi/models/EvaluationQuestion'

import { readableFormat } from 'utils/date-utils'
import { parseIsoString } from 'utils/utils'

import ConfirmationDialog from 'components/common/confirmation-dialog/confirmation-dialog'
import { Button } from 'components/ui/button'
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from 'components/ui/card'
import { Dialog, DialogTrigger } from 'components/ui/dialog'
import Icon from 'components/ui/icon/icon'
import { Separator } from 'components/ui/separator'

interface Props {
  evaluationQuestion: EvaluationQuestion
  orderRank?: number
  onDelete?: (id: string) => Promise<void>
  onRemove?: (id: string) => void
  onClick?: (q: EvaluationQuestion) => void
  footerChildren?: React.ReactNode
}

const EvaluationQuestionCard = ({
  evaluationQuestion,
  orderRank,
  onDelete,
  onRemove,
  onClick,
  footerChildren,
}: Props) => {
  return (
    <Card className="mb-5 mt-5 p-2">
      <CardHeader>
        {orderRank && (
          <>
            <CardDescription>
              <p>Question # {orderRank}</p>
            </CardDescription>
            <Separator className="my-1" />
          </>
        )}
        <div className="flex items-center justify-between">
          <CardTitle>{evaluationQuestion.question}</CardTitle>
          <div className="flex items-center gap-2">
            {onDelete && (
              <Dialog>
                <DialogTrigger>
                  <Button variant="outline" size="icon">
                    <Icon
                      icon={Trash}
                      size="default"
                      className="stroke-destructive"
                    />
                  </Button>
                </DialogTrigger>
                <ConfirmationDialog
                  title="Delete evaluation question"
                  description="Are you sure you want to delete this evaluation question? This will prevent it from being used in any future experiments."
                  cta={{
                    label: 'Delete',
                    onClick: async () => {
                      await onDelete(evaluationQuestion.id)
                    },
                  }}
                  secondaryCta={{ label: 'Cancel', onClick: () => {} }}
                  variant="destructive"
                  showCloseIcon={false}
                />
              </Dialog>
            )}
            {onRemove && (
              <Button
                variant="outline"
                size="icon"
                onClick={() => onRemove(evaluationQuestion.id)}
              >
                <Icon icon={SquareX} size="default" />
              </Button>
            )}
            {onClick && (
              <Button
                variant="outline"
                size="icon"
                onClick={() => onClick(evaluationQuestion)}
              >
                <Icon icon={Plus} size="default" />
              </Button>
            )}
          </div>
        </div>
      </CardHeader>
      <CardContent>
        <CardDescription className="pb-4">
          {evaluationQuestion.questionDescription}
        </CardDescription>
        <CardDescription className="pb-4">
          <div className="font-semibold">Question Details</div>
          <div>ID: {evaluationQuestion.id}</div>
          <div>Question Type: {evaluationQuestion.questionType}</div>
          <div>Response Type: {evaluationQuestion.responseType}</div>
          {evaluationQuestion.meta && (
            <div>Metadata: {JSON.stringify(evaluationQuestion.meta)}</div>
          )}
          <div>
            <span className="mr-1">Created At:</span>
            {readableFormat(
              new Date(parseIsoString(evaluationQuestion.createdAt))
            )}
          </div>
          <div>
            <span className="mr-1">Updated At:</span>
            {readableFormat(
              new Date(parseIsoString(evaluationQuestion.updatedAt))
            )}
          </div>
        </CardDescription>
        {footerChildren && (
          <>
            <Separator className="my-4" />
            {footerChildren}
          </>
        )}
      </CardContent>
    </Card>
  )
}

export default EvaluationQuestionCard
