import React, { useEffect, useRef, useState } from 'react'

import _ from 'lodash'

import { EvaluationQuestion } from 'openapi/models/EvaluationQuestion'
import { EvaluationQuestionInput } from 'openapi/models/EvaluationQuestionInput'
import { EvaluationQuestionResponseType } from 'openapi/models/EvaluationQuestionResponseType'
import { EvaluationQuestionType } from 'openapi/models/EvaluationQuestionType'
import { Maybe } from 'types'

import { displayErrorMessage } from 'utils/toast'

import { CreateEvaluationQuestions } from 'components/settings/experiment/utils/experiment-fetcher'
import {
  EvaluationQuestionResponseOptionInput,
  VALID_EVALUATION_QUESTION_RESPONSE_TYPES,
} from 'components/settings/experiment/utils/experiment-utils'
import { Button } from 'components/ui/button'
import { Input } from 'components/ui/input'
import { Label } from 'components/ui/label'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/ui/select'
import { Separator } from 'components/ui/separator'
import { Textarea } from 'components/ui/text-area'

import EvaluationResponseOptionsMultipleSelectInput from './evaluation-response-options-multiple-select-input'
import EvaluationResponseOptionsRatingInput from './evaluation-response-options-rating-input'

const EvaluationQuestionNew = ({
  handleNewSelectedQuestion,
}: {
  handleNewSelectedQuestion: (
    question: EvaluationQuestion,
    shouldClose: boolean
  ) => void
}) => {
  // TODO improvement would be to store the state even if user navigates away from
  // create new tab, in case they want to reference the question bank while creating
  // a new question
  const [question, setQuestion] = useState<string>()
  const [questionDescription, setQuestionDescription] = useState<string>()
  const [questionType, setQuestionType] = useState<EvaluationQuestionType>()
  const questionTypeRef = useRef<EvaluationQuestionType>()
  const [responseType, setResponseType] =
    useState<EvaluationQuestionResponseType>()
  const [isSubmitting, setIsSubmitting] = useState(false)

  // only used for multiple select and rating response options at the moment (July 5, 2024)
  // should be set to undefined when the inputs are invalid by child components
  const [responseOptions, setResponseOptions] =
    useState<Maybe<EvaluationQuestionResponseOptionInput>>(undefined)
  // const responseOptionsRef = useRef<EvaluationQuestionResponseOptionInput>()

  const requiresResponseOptions =
    responseType &&
    [
      EvaluationQuestionResponseType.MULTIPLE_SELECT.valueOf(),
      EvaluationQuestionResponseType.RATING.valueOf(),
    ].includes(responseType.valueOf())

  // TODO clear response type when switching question types, not working well
  useEffect(() => {
    if (questionType && questionTypeRef.current !== questionType) {
      setResponseType(undefined)
      questionTypeRef.current = questionType
    }
  }, [questionType])

  // clear response options ref if question type or response type changes
  useEffect(() => {
    if (_.isNil(responseOptions)) {
      setResponseOptions(undefined)
    }
  }, [questionType, responseType, responseOptions])

  const validateQuestionInputs = () => {
    if (!question || !questionDescription || !questionType || !responseType) {
      return false
    }

    if (requiresResponseOptions && _.isNil(responseOptions)) {
      return false
    }

    return true
  }

  const handleSubmit = async () => {
    if (validateQuestionInputs()) {
      setIsSubmitting(true)
      const questionInput: EvaluationQuestionInput = {
        question: question!,
        questionDescription: questionDescription!,
        questionType: questionType!,
        responseType: responseType!,
      }
      if (!_.isNil(responseOptions)) {
        questionInput.meta = {
          responseOptions: responseOptions,
        }
      }

      try {
        const newQuestion = await CreateEvaluationQuestions([questionInput])
        handleNewSelectedQuestion(newQuestion[0], true)
      } catch (error) {
        displayErrorMessage(`Failed to create new question: ${error}`, 5)
      } finally {
        setIsSubmitting(false)
      }
    }
  }

  return (
    <>
      <div className="my-5">
        <Label className="font-normal">Question</Label>
        <Input
          type="text"
          placeholder="e.g. Please rate the quality of the response"
          value={question}
          onChange={(e) => setQuestion(e.target.value)}
          disabled={isSubmitting}
        />
      </div>
      <div className="my-5">
        <Label className="font-normal">
          Question Description / Instructions
        </Label>
        <Textarea
          placeholder="e.g. Rating scale goes from 1 to 5 (highest)"
          value={questionDescription}
          onChange={(e) => setQuestionDescription(e.target.value)}
          disabled={isSubmitting}
        />
      </div>
      <div className="my-5 flex justify-start">
        <div className="mr-5">
          <Label className="font-normal">Question Type</Label>
          <Select
            value={questionType}
            onValueChange={(value) =>
              setQuestionType(value as EvaluationQuestionType)
            }
          >
            <SelectTrigger className="mt-1 min-w-[200px]">
              <SelectValue placeholder="Select a question type" />
            </SelectTrigger>
            <SelectContent>
              {Object.values(EvaluationQuestionType).map((questionType) => (
                <SelectItem key={questionType} value={questionType}>
                  {questionType}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        <div className="mr-5">
          {questionType && (
            <>
              <Label className="font-normal">Response Type</Label>
              <Select
                onValueChange={(value) =>
                  setResponseType(value as EvaluationQuestionResponseType)
                }
              >
                <SelectTrigger className="mt-1 min-w-[200px]">
                  {/* TODO fix how response type is cleared after switching question type */}
                  <SelectValue placeholder="Select a response type" />
                </SelectTrigger>
                <SelectContent>
                  {VALID_EVALUATION_QUESTION_RESPONSE_TYPES[questionType].map(
                    (responseType) => (
                      <SelectItem key={responseType} value={responseType}>
                        {responseType}
                      </SelectItem>
                    )
                  )}
                </SelectContent>
              </Select>
            </>
          )}
        </div>
      </div>
      {requiresResponseOptions && (
        <>
          <Separator orientation="horizontal" className="w-full" />
          <Label className="font-semibold">Response Options Required</Label>
          {responseType === EvaluationQuestionResponseType.RATING && (
            <EvaluationResponseOptionsRatingInput
              responseOptions={responseOptions}
              setResponseOptions={setResponseOptions}
            />
          )}
          {responseType === EvaluationQuestionResponseType.MULTIPLE_SELECT && (
            <EvaluationResponseOptionsMultipleSelectInput
              responseOptions={responseOptions}
              setResponseOptions={setResponseOptions}
            />
          )}
        </>
      )}
      <Button
        onClick={handleSubmit}
        disabled={!validateQuestionInputs() || isSubmitting}
        variant="secondary"
      >
        Create new question
      </Button>
    </>
  )
}

export default EvaluationQuestionNew
