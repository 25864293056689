// Promise.allSettled is not available in all Browsers
// This is a polyfill for Promise.allSettled
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Promise/allSettled
const PromiseHelperAllSettled = (
  promises: Array<Promise<any>>
): Promise<Array<{ status: string; value?: any; reason?: any }>> => {
  return Promise.all(
    promises.map(function (promise) {
      return promise
        .then(function (value) {
          return { status: 'fulfilled', value: value }
        })
        .catch(function (reason) {
          return { status: 'rejected', reason: reason }
        })
    })
  )
}

export { PromiseHelperAllSettled }
