import * as React from 'react'

import * as CheckboxPrimitive from '@radix-ui/react-checkbox'
import { Check, Minus } from 'lucide-react'

import { cn } from 'utils/utils'

export enum CheckboxVariant {
  DEFAULT = 'DEFAULT',
  SELECT_ALL = 'SELECT_ALL',
}

const Checkbox = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root> & {
    checkboxClassName?: string
    label?: string
    isIndeterminate?: boolean
    labelClassName?: string
    variant?: CheckboxVariant
  }
>(
  (
    {
      checkboxClassName,
      className,
      label,
      isIndeterminate,
      labelClassName,
      variant = CheckboxVariant.DEFAULT,
      ...props
    },
    ref
  ) => (
    <div className={cn('flex items-center space-x-2', className)}>
      <CheckboxPrimitive.Root
        ref={ref}
        className={cn(
          'peer h-4 w-4 shrink-0 rounded-sm border border-input ring-offset-primary hover:bg-secondary-hover focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:border-background-interactive data-[state=checked]:bg-interactive data-[state=checked]:text-primary-inverse data-[state=checked]:hover:bg-interactive-hover',
          // Expand the click area a bit
          'relative before:absolute before:-inset-x-1 before:-inset-y-1 before:content-[""]',
          checkboxClassName
        )}
        aria-checked={isIndeterminate ? 'mixed' : undefined}
        {...props}
      >
        <CheckboxPrimitive.Indicator
          className={cn('text-current flex items-center justify-center')}
        >
          {isIndeterminate ? (
            <Minus className="-mt-[1px] h-4 w-4" />
          ) : (
            <Check className="h-4 w-4" />
          )}
        </CheckboxPrimitive.Indicator>
      </CheckboxPrimitive.Root>

      {label !== undefined && (
        <label
          htmlFor={props.id}
          className={cn(
            'line-clamp-1 cursor-pointer text-sm peer-disabled:cursor-not-allowed peer-disabled:opacity-70',
            labelClassName,
            {
              'font-semibold underline': variant === CheckboxVariant.SELECT_ALL,
            }
          )}
        >
          {label}
        </label>
      )}
    </div>
  )
)
Checkbox.displayName = CheckboxPrimitive.Root.displayName

export { Checkbox }
