import React, { useState } from 'react'
import { useParams } from 'react-router-dom'

import { isEmpty } from 'lodash'
import pluralize from 'pluralize'
import { useShallow } from 'zustand/react/shallow'

import { QueryCapRuleUnitLevel } from 'openapi/models/QueryCapRuleUnitLevel'

import { Button } from 'components/ui/button'
import { ScrollArea } from 'components/ui/scroll-area'
import { DOT_SEPARATOR, QueryQuestion } from 'components/vault/utils/vault'
import { useVaultStore } from 'components/vault/utils/vault-store'
import {
  getQueryUsageStringWithUnit,
  useVaultUsageStore,
} from 'components/vault/utils/vault-usage-store'

import { NewQuestion } from './new-question'
import QuestionItem from './question-item'

interface Props {
  isQuestionsOpen: boolean
  questions: QueryQuestion[]
  setQuestions: (questions: QueryQuestion[]) => void
  onCreateTable: (selectedQuestions: QueryQuestion[]) => void
  questionsLimit: number
  existingQuestions?: QueryQuestion[]
  isFetchingQuestions: boolean
  onEditQuery: () => void
  descriptionLabel: string
  maxQuestionCharacterLength: number
  minQuestionCharacterLength: number
  filesCount: number
  filesLimit: number | null
  reviewQueryLimit: number | null
  reviewQueryUsage: number
  disabled: boolean
}

export const Questions: React.FC<Props> = ({
  isQuestionsOpen,
  questions,
  setQuestions,
  onCreateTable,
  questionsLimit,
  existingQuestions,
  isFetchingQuestions,
  onEditQuery,
  descriptionLabel,
  maxQuestionCharacterLength,
  minQuestionCharacterLength,
  filesCount,
  filesLimit,
  reviewQueryLimit,
  reviewQueryUsage,
  disabled,
}) => {
  const { queryId } = useParams()
  const [isEditingQuestion, setIsEditingQuestion] = useState(false)

  const queryIdToState = useVaultStore(
    useShallow((state) => state.queryIdToState)
  )
  const isTextAreaFocused = useVaultStore((state) => state.isTextAreaFocused)
  const selectedQuestions = useVaultStore(
    useShallow((state) => state.selectedQuestions)
  )
  const totalQuestionLength = useVaultStore(
    (state) => state.totalQuestionLength
  )
  const reviewQueryLimitUnitLevel = useVaultUsageStore(
    (state) => state.reviewQueryLimitUnitLevel
  )
  const reviewQueryUnit = useVaultUsageStore((state) => state.reviewQueryUnit)
  const reviewQueryDenominator = useVaultUsageStore(
    (state) => state.reviewQueryDenominator
  )

  const handleEditClick = () => {
    onEditQuery()
  }

  // Whether we are adding extra columns to an existing query or not
  const isAddingExtraColumnsToExistingQUery =
    !isEmpty(queryId) && (existingQuestions?.length ?? 0) > 0
  const isEmptyState = questions.length === 0 && !isFetchingQuestions
  const currentQueryUsage = (() => {
    if (reviewQueryLimitUnitLevel === QueryCapRuleUnitLevel.CELL) {
      // If we are calculating based on cells, we want to count the review query usage by the number of cells that will be used
      // regardless of whether we are adding columns to an existing query or creating a new query
      return selectedQuestions.length * filesCount
    }
    if (isAddingExtraColumnsToExistingQUery) {
      // If we are adding extra columns to an existing query, we don't want to count the review query usage
      return 0
    }
    if (reviewQueryLimitUnitLevel === QueryCapRuleUnitLevel.FILE) {
      // If we are not adding extra columns to an existing query and we are calculating based on files, we want to
      // count the review query usage by the number of files that will be used
      return filesCount
    }
    // If we are not adding extra columns to an existing query and we are calculating based on query, we want to
    // count the review query usage by the number of queries that will be used (which is 1 here)
    return 1
  })()
  const isSubmittingQuestions =
    queryIdToState[queryId ?? '']?.isLoading && !isFetchingQuestions
  const isDisabled =
    selectedQuestions.length === 0 ||
    isFetchingQuestions ||
    isSubmittingQuestions ||
    isEmptyState ||
    isEditingQuestion ||
    (reviewQueryLimit !== null &&
      currentQueryUsage + reviewQueryUsage > reviewQueryLimit) ||
    (questionsLimit > 0 && totalQuestionLength > questionsLimit) ||
    (!!filesLimit && filesCount > filesLimit)
  const selectedColumnsText =
    totalQuestionLength > 0 && questionsLimit > 0
      ? `Uses ${totalQuestionLength}/${questionsLimit} ${pluralize(
          'column',
          totalQuestionLength
        )}`
      : ''
  const selectedFilesText =
    selectedQuestions.length > 0 &&
    filesCount > 0 &&
    reviewQueryLimitUnitLevel !== QueryCapRuleUnitLevel.FILE &&
    !!filesLimit
      ? `Uses ${filesCount.toLocaleString()}/${filesLimit.toLocaleString()} ${pluralize(
          'document',
          filesCount
        )}`
      : ''
  const currentQueryUsageText =
    selectedQuestions.length > 0 && currentQueryUsage > 0
      ? `Uses ${getQueryUsageStringWithUnit(
          currentQueryUsage,
          reviewQueryUnit,
          reviewQueryDenominator
        )}`
      : ''

  const getDisabledText = () => {
    if (isFetchingQuestions) return 'Loading…'
    if (isSubmittingQuestions) {
      if (isAddingExtraColumnsToExistingQUery) {
        return 'Adding columns…'
      } else {
        return 'Creating table…'
      }
    }
    if (isEmptyState) return 'No questions found'
    if (selectedQuestions.length === 0)
      return 'Please select at least one column'
    if (
      reviewQueryLimit !== null &&
      currentQueryUsage + reviewQueryUsage > reviewQueryLimit
    )
      return 'You will reach the review query limit.'
    if (questionsLimit > 0 && totalQuestionLength > questionsLimit)
      return `You have selected ${totalQuestionLength} questions. Please remove some questions.`
    if (!!filesLimit && filesCount > filesLimit)
      return 'You will reach the review document limit.'
    return ''
  }

  if (!isTextAreaFocused || !isQuestionsOpen) return null
  return (
    <div className="w-full cursor-default rounded-b-lg border-t bg-primary text-left">
      <div>
        <ScrollArea
          className="mb-4"
          maxHeight="max-h-40 min-desktop-size:max-h-64"
        >
          <div className="mb-2 px-4 pt-4">
            <p className="font-semibold">Table preview</p>
            <p className="text-muted">
              {descriptionLabel ||
                'For each file in your project, Harvey will create a column answering the questions below.'}
            </p>
          </div>
          {questions.map((question, idx) => (
            <QuestionItem
              question={question}
              key={question.id}
              questionsLimit={questionsLimit}
              questions={questions}
              setQuestions={setQuestions}
              idx={idx}
              maxQuestionCharacterLength={maxQuestionCharacterLength}
              minQuestionCharacterLength={minQuestionCharacterLength}
              setIsEditingQuestion={setIsEditingQuestion}
              disabled={disabled}
            />
          ))}

          {!disabled && !isFetchingQuestions && (
            <NewQuestion
              setQuestions={setQuestions}
              questions={questions}
              maxQuestionCharacterLength={maxQuestionCharacterLength}
              minQuestionCharacterLength={minQuestionCharacterLength}
              setIsEditingQuestion={setIsEditingQuestion}
            />
          )}

          {isEmptyState && (
            <div className="flex items-center justify-center px-4 py-12">
              <p className="text-muted">No questions found</p>
            </div>
          )}

          {/* Carefully determined these values so they line up with the eventual columns */}
          {isFetchingQuestions && (
            <div className="z-10 w-full space-y-3.5 px-4 pt-1.5">
              <div className="flex w-full items-center space-x-4">
                <div className="h-4 w-4 shrink-0 rounded bg-skeleton" />
                <div className="h-4 w-16 shrink-0 rounded bg-skeleton/80" />
                <div className="h-4 w-full rounded bg-skeleton" />
              </div>
              <div className="flex items-center space-x-4">
                <div className="h-4 w-4 shrink-0 animate-pulse rounded bg-skeleton" />
                <div className="h-4 w-16 shrink-0 animate-pulse rounded bg-skeleton/80" />
                <div className="h-4 w-full animate-pulse rounded bg-skeleton" />
              </div>
              <div className="flex h-4 space-x-4">
                <div className="h-full w-4 shrink-0 animate-pulse rounded bg-skeleton/80" />
                <div className="h-full w-16 animate-pulse rounded bg-skeleton" />
                <div className="h-full w-full animate-pulse rounded bg-skeleton" />
              </div>
            </div>
          )}
        </ScrollArea>
      </div>
      <div className="flex items-center justify-between gap-2 border-t px-4 py-3">
        <div className="flex items-center gap-1">
          <p>
            {[selectedColumnsText, selectedFilesText, currentQueryUsageText]
              .filter(Boolean)
              .join(DOT_SEPARATOR)}
          </p>
        </div>

        <div className="flex space-x-2">
          {!disabled && (
            <Button
              variant="outline"
              onClick={handleEditClick}
              disabled={isFetchingQuestions || isSubmittingQuestions}
            >
              Edit query
            </Button>
          )}
          <Button
            onClick={() => onCreateTable(selectedQuestions)}
            disabled={isDisabled}
            tooltip={getDisabledText()}
            tooltipAlign="end"
            isLoading={isSubmittingQuestions}
          >
            {isAddingExtraColumnsToExistingQUery
              ? 'Add columns'
              : 'Create table'}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default Questions
