import React, { useRef } from 'react'
import { useClickAway } from 'react-use'

import { Table2, FileText, InfoIcon, Check, ChevronsUpDown } from 'lucide-react'
import { useShallow } from 'zustand/react/shallow'

import { QueryCapRuleLevel } from 'openapi/models/QueryCapRuleLevel'
import { QueryCapRuleTimeFrame } from 'openapi/models/QueryCapRuleTimeFrame'

import { cn } from 'utils/utils'

import { useAuthUser } from 'components/common/auth-context'
import { Button } from 'components/ui/button'
import { Card } from 'components/ui/card'
import { Icon } from 'components/ui/icon/icon'
import { Pill } from 'components/ui/pill/pill'
import { Popover, PopoverContent, PopoverTrigger } from 'components/ui/popover'
import useSharingPermissions from 'components/vault/hooks/use-sharing-permissions'
import { QUERY_TYPES, useVaultStore } from 'components/vault/utils/vault-store'
import { useVaultUsageStore } from 'components/vault/utils/vault-usage-store'

interface Props {
  isQueryTypeSelectPopoverOpen: boolean
  setIsQueryTypeSelectPopoverOpen: (value: boolean) => void
  queryType: QUERY_TYPES
  setQueryType: (value: QUERY_TYPES) => void
  disabled?: boolean
}

export const QueryTypeSelect: React.FC<Props> = ({
  isQueryTypeSelectPopoverOpen,
  setIsQueryTypeSelectPopoverOpen,
  queryType,
  setQueryType,
  disabled,
}) => {
  const userInfo = useAuthUser()

  const setOpenVaultAssistantModal = useVaultStore(
    useShallow((s) => s.setIsVaultAssistantModalOpen)
  )
  const exampleProjectIds = useVaultStore(
    useShallow((s) => s.exampleProjectIds)
  )
  const currentProject = useVaultStore(useShallow((s) => s.currentProject))
  const isTextAreaFocused = useVaultStore(
    useShallow((state) => state.isTextAreaFocused)
  )

  const popoverContentRef = useRef<HTMLDivElement>(null)

  const isReviewDisabled = !userInfo.IsVaultReviewUser
  const reviewQueryLimit = useVaultUsageStore((state) => state.reviewQueryLimit)
  const reviewQueryLimitDisplayString = useVaultUsageStore(
    (state) => state.reviewQueryLimitDisplayString
  )
  const reviewQueryLimitUnitDisplayString = useVaultUsageStore(
    (state) => state.reviewQueryLimitUnitDisplayString
  )
  const reviewQueryLimitTimeFrame = useVaultUsageStore(
    (state) => state.reviewQueryLimitTimeFrame
  )
  const reviewQueryLimitLevel = useVaultUsageStore(
    (state) => state.reviewQueryLimitLevel
  )

  const { doesCurrentUserHaveEditPermission } = useSharingPermissions({
    projectId: currentProject?.id,
  })

  const isExampleProject =
    currentProject && exampleProjectIds.has(currentProject.id)
  const shouldNavigateToVaultAssistant =
    userInfo.IsVaultKnowledgeSourceUser && !isExampleProject

  const shouldNavigateToVaultAssistantWithSharing =
    userInfo.IsVaultViewSharesUser
      ? doesCurrentUserHaveEditPermission && shouldNavigateToVaultAssistant
      : shouldNavigateToVaultAssistant

  useClickAway(popoverContentRef, () => {
    setIsQueryTypeSelectPopoverOpen(false)
  })

  if (!isTextAreaFocused) return

  return (
    <Popover open={isQueryTypeSelectPopoverOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          onClick={() => setIsQueryTypeSelectPopoverOpen(true)}
          className={cn('transition', {
            'bg-button-secondary': isQueryTypeSelectPopoverOpen,
          })}
          tooltip={disabled ? 'Query type cannot be changed here' : undefined}
          disabled={disabled}
        >
          {queryType === QUERY_TYPES.NN ? (
            <Icon icon={Table2} className="mr-1" />
          ) : (
            <Icon icon={FileText} className="mr-1" />
          )}

          <span className="truncate text-sm capitalize">{queryType} Query</span>

          <Icon icon={ChevronsUpDown} className="ml-5" />
        </Button>
      </PopoverTrigger>
      <PopoverContent
        align="start"
        className="z-[70] w-fit"
        ref={popoverContentRef}
      >
        <p className="mb-4 font-semibold">Choose query type</p>
        <div className="space-y-2">
          <Card
            className={cn(
              'flex rounded-lg border border-transparent p-4 transition ',
              {
                'bg-accent': queryType === QUERY_TYPES.NN,
                'cursor-not-allowed': isReviewDisabled,
                'hover:border-ring': queryType !== QUERY_TYPES.NN,
              }
            )}
            onClick={() => {
              if (isReviewDisabled) {
                return
              }
              setQueryType(QUERY_TYPES.NN)
              setIsQueryTypeSelectPopoverOpen(false)
            }}
          >
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label
              htmlFor="review"
              className={cn('flex cursor-pointer items-center', {
                'cursor-not-allowed': isReviewDisabled,
              })}
            >
              <div className="flex aspect-video shrink-0 rounded-lg border">
                <div className="w-12 space-y-2 rounded-l-lg bg-accent p-3">
                  <div className="h-2 w-2/3 rounded-xs bg-skeleton-dark" />
                  <div className="h-2 w-2/3 rounded-xs bg-skeleton-dark" />
                  <div className="h-2 w-full rounded-xs bg-skeleton-dark" />
                  <div className="h-2 w-1/3 rounded-xs bg-skeleton-dark" />
                  <div className="h-2 w-full rounded-xs bg-skeleton-dark" />
                </div>
                <div className="w-12 space-y-2 bg-secondary p-3">
                  <div className="h-2 w-2/3 rounded-xs bg-skeleton-dark" />
                  <div className="h-2 w-2/3 rounded-xs bg-skeleton-dark" />
                  <div className="h-2 w-full rounded-xs bg-skeleton-dark" />
                  <div className="h-2 w-1/3 rounded-xs bg-skeleton-dark" />
                  <div className="h-2 w-full rounded-xs bg-skeleton-dark" />
                </div>
                <div className="h-fit w-12 space-y-2 rounded-r-lg bg-secondary p-3">
                  <div className="h-2 w-2/3 rounded-xs bg-skeleton-dark" />
                  <div className="h-2 w-2/3 rounded-xs bg-skeleton-dark" />
                  <div className="h-2 w-full rounded-xs bg-skeleton-dark" />
                  <div className="h-2 w-1/3 rounded-xs bg-skeleton-dark" />
                  <div className="h-2 w-full rounded-xs bg-skeleton-dark" />
                </div>
              </div>
              <div className="ml-4 max-w-32 space-y-1 sm:max-w-[216px]">
                <p className="text-xs font-semibold">Review query</p>
                <p className="text-xs">
                  Get individual answers for each file in a table.
                </p>
                {!isReviewDisabled && reviewQueryLimit !== null && (
                  <p className="text-xs text-muted">
                    {`${reviewQueryLimitDisplayString} ${reviewQueryLimitUnitDisplayString}${
                      reviewQueryLimitTimeFrame ===
                      QueryCapRuleTimeFrame.CALENDAR_MONTH
                        ? ' monthly'
                        : ''
                    }`}
                    {reviewQueryLimitLevel === QueryCapRuleLevel.PER_WORKSPACE
                      ? ' for entire workspace'
                      : ''}
                  </p>
                )}
                {isReviewDisabled && (
                  <Pill variant="secondary">
                    <div className="flex items-center gap-1">
                      <Icon
                        icon={InfoIcon}
                        size="small"
                        className="text-white"
                      />
                      <p className="text-white text-xs">
                        Contact admin to enable
                      </p>
                    </div>
                  </Pill>
                )}
              </div>
              <Icon
                icon={Check}
                className={cn('ml-6', {
                  invisible: queryType === QUERY_TYPES.N1,
                })}
              />
            </label>
          </Card>
          <Card
            className={cn(
              'flex rounded-lg border border-transparent p-4 transition',
              {
                'bg-accent': queryType === QUERY_TYPES.N1,
                'hover:border-ring': queryType !== QUERY_TYPES.N1,
              }
            )}
            onClick={() => {
              if (shouldNavigateToVaultAssistantWithSharing) {
                setOpenVaultAssistantModal(true)
              } else {
                setQueryType(QUERY_TYPES.N1)
              }
              setIsQueryTypeSelectPopoverOpen(false)
            }}
          >
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label htmlFor="ask" className="flex cursor-pointer items-center">
              <div className="flex aspect-video shrink-0 flex-col justify-center space-y-2 rounded-lg border bg-secondary px-4 py-3">
                <div className="h-2 w-24 rounded-xs bg-skeleton-dark" />
                <div className="h-2 w-28 rounded-xs bg-skeleton-dark" />
                <div className="h-2 w-16 rounded-xs bg-skeleton-dark" />
                <div className="h-2 w-12 rounded-xs bg-skeleton-dark" />
                <div className="h-2 w-14 rounded-xs bg-skeleton-dark" />
              </div>
              <div className="ml-4 max-w-32 space-y-1 sm:max-w-[216px]">
                <p className="text-xs font-semibold">Ask query</p>
                <p className="text-xs">
                  Get a single answer on collective information across all
                  files.
                </p>
                {shouldNavigateToVaultAssistantWithSharing && (
                  <Pill variant="secondary">
                    <p className="text-xs text-secondary">Moved to Assistant</p>
                  </Pill>
                )}
              </div>
              <Icon
                icon={Check}
                className={cn('ml-6', {
                  invisible: queryType === QUERY_TYPES.NN,
                })}
              />
            </label>
          </Card>
        </div>
      </PopoverContent>
    </Popover>
  )
}

export default QueryTypeSelect
