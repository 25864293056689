import React from 'react'

import { Row } from '@tanstack/table-core'
import _ from 'lodash'
import { Edit2, Trash, Undo2 } from 'lucide-react'

import { ClientMatterWithStats } from 'models/client-matters'

import { useSettingsState } from 'components/settings/settings-store'
import { Button } from 'components/ui/button'

interface ClientMattersTableActionsProps {
  row: Row<ClientMatterWithStats>
  onAddClientMatter: (
    cmName: string,
    cmDesc: string,
    cmAllowed: boolean
  ) => void
  onEditClientMatter: (
    cmName: string,
    cmDesc: string,
    cmAllowed: boolean
  ) => void
  onDeleteClientMatter: (cmName: string) => void
}

const ClientMattersTableActions = ({
  row,
  onAddClientMatter,
  onEditClientMatter,
  onDeleteClientMatter,
}: ClientMattersTableActionsProps) => {
  const settingsUser = useSettingsState((s) => s.settingsUser)

  if (
    _.isNil(settingsUser) ||
    (!settingsUser.IsTerritoryAdmin &&
      settingsUser.id !== String(row.getValue('userEmail')).trim())
  ) {
    return null
  }

  return row.getValue('deletedAt') ? (
    <div className="flex flex-row justify-end">
      <Button
        className="flex items-center justify-center rounded hover:bg-button-secondary-hover"
        variant="ghost"
        size="xsIcon"
        tooltip={`Readd ${row.getValue('name')}`}
        tooltipSide="left"
        onClick={async () =>
          onAddClientMatter(
            row.getValue('name'),
            row.getValue('description'),
            row.getValue('allowed')
          )
        }
      >
        <Undo2 className="h-4 w-4" />
      </Button>
    </div>
  ) : (
    <div className="flex flex-row justify-end">
      <Button
        className="mr-1 flex items-center justify-center rounded hover:bg-button-secondary-hover"
        variant="ghost"
        size="xsIcon"
        tooltip={`Edit ${row.getValue('name')}`}
        tooltipSide="left"
      >
        <Edit2
          onClick={() => {
            onEditClientMatter(
              row.getValue('name'),
              row.getValue('description'),
              row.getValue('allowed')
            )
          }}
          className="h-4 w-4"
        />
      </Button>
      <Button
        className="flex items-center justify-center rounded hover:bg-button-secondary-hover"
        variant="ghost"
        size="xsIcon"
        tooltip={`Delete ${row.getValue('name')}`}
        tooltipSide="left"
      >
        <Trash
          onClick={() => onDeleteClientMatter(row.getValue('name'))}
          className="h-4 w-4 stroke-destructive"
        />
      </Button>
    </div>
  )
}

export default ClientMattersTableActions
