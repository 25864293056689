import React from 'react'
import { Navigate, useParams } from 'react-router-dom'

const RedirectWithId: React.FC<{ path: string }> = ({ path }) => {
  // Use the useParams hook to access the dynamic parts of the URL.
  const { id } = useParams<{ id: string }>()

  // Redirect to the new path, using the captured id.
  return <Navigate to={`${path}/${id}`} replace />
}

export default RedirectWithId
