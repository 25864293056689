import React from 'react'

import { cn } from 'utils/utils'

import { Button, ButtonProps } from 'components/ui/button'

const ToolbarButton = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ children, className, ...props }, ref) => {
    return (
      <Button
        className={cn(className, 'text-muted hover:text-muted')}
        variant="ghost"
        size="smIcon"
        ref={ref}
        {...props}
      >
        {children}
      </Button>
    )
  }
)
ToolbarButton.displayName = 'ToolbarButton'

export default ToolbarButton
