import pluralize from 'pluralize'
import { create } from 'zustand'

import { QueryCapRuleLevel } from 'openapi/models/QueryCapRuleLevel'
import { QueryCapRuleTimeFrame } from 'openapi/models/QueryCapRuleTimeFrame'
import { QueryCapRuleUnitLevel } from 'openapi/models/QueryCapRuleUnitLevel'

import {
  MAX_REVIEW_FILES_LIMIT_FOR_VAULT_ADD_ON,
  MAX_REVIEW_QUESTIONS_LIMIT_FOR_VAULT_ADD_ON,
} from './vault'
import { pluralizeDocuments } from './vault-text-utils'

interface VaultUsageState {
  reviewFilesPerQueryLimit: number | null
  reviewQuestionsPerQueryLimit: number
  reviewQueryCapExplanation: string
  reviewQueryCapExplanationMore: string
  reviewQueryDenominator: number
  reviewQueryUsage: number
  reviewQueryLimit: number | null
  reviewQueryLimitUnitLevel: QueryCapRuleUnitLevel
  reviewQueryLimitTimeFrame: QueryCapRuleTimeFrame
  reviewQueryLimitLevel: QueryCapRuleLevel
  reviewQueryUnit: QueryUnit
  reviewQueryUsageDisplayString: string
  reviewQueryLimitDisplayString: string
  reviewQueryLimitUnitDisplayString: string
  reviewQueryRemainingDisplayString: string
  setReviewFilesPerQueryLimit: (limit: number | null) => void
  setReviewQuestionsPerQueryLimit: (limit: number) => void
  setReviewQueryUsage: (usage: number) => void
  setReviewQueryLimit: (limit: number | null) => void
  setReviewQueryLimitUnitLevel: (unitLevel: QueryCapRuleUnitLevel) => void
  setReviewQueryLimitTimeFrame: (timeFrame: QueryCapRuleTimeFrame) => void
  setReviewQueryLimitLevel: (level: QueryCapRuleLevel) => void
}

export type QueryUnit = 'review query' | 'review document'

const getQueryUsageNumber = (
  queryUsage: number,
  type: 'usage' | 'remaining' = 'usage',
  denominator: number = 1
) => {
  switch (type) {
    case 'usage':
      if (queryUsage === 0 || denominator === 1) {
        return queryUsage
      }
      return Math.max(1, Math.floor(queryUsage / denominator))
    case 'remaining':
      return Math.ceil(queryUsage / denominator)
  }
}

export const getQueryUsageString = (
  queryUsage: number | null,
  type: 'usage' | 'remaining' = 'usage',
  denominator: number = 1
) => {
  if (queryUsage === null) return 'unlimited'
  return getQueryUsageNumber(queryUsage, type, denominator).toLocaleString()
}

export const getQueryUsageStringWithUnit = (
  totalUsage: number,
  unit: QueryUnit = 'review query',
  denominator: number = 1
) => {
  return `${getQueryUsageNumber(
    totalUsage,
    'usage',
    denominator
  ).toLocaleString()} ${getQueryUnitString(totalUsage, unit, denominator)}`
}

const getQueryUnitString = (
  totalUsage: number | null,
  unit: QueryUnit = 'review query',
  denominator: number = 1
) => {
  return pluralize(
    unit,
    getQueryUsageNumber(totalUsage ?? 0, 'usage', denominator)
  )
}

export const getQueryUnitStringForRemaining = (
  remaining: number,
  unit: QueryUnit = 'review query',
  denominator: number = 1
) => {
  return pluralize(
    unit,
    getQueryUsageNumber(remaining, 'remaining', denominator)
  )
}

export const getDenominator = (
  unitLevel: QueryCapRuleUnitLevel,
  filesPerQueryLimit: number | null,
  questionsPerQueryLimit: number
) => {
  if (unitLevel === QueryCapRuleUnitLevel.CELL) {
    if (
      filesPerQueryLimit !== null &&
      filesPerQueryLimit > 0 &&
      questionsPerQueryLimit > 0
    ) {
      return filesPerQueryLimit * questionsPerQueryLimit
    } else {
      return (
        MAX_REVIEW_FILES_LIMIT_FOR_VAULT_ADD_ON *
        MAX_REVIEW_QUESTIONS_LIMIT_FOR_VAULT_ADD_ON
      )
    }
  }
  return 1
}

const getQueryCapExplanation = (
  unitLevel: QueryCapRuleUnitLevel,
  filesPerQueryLimit: number | null,
  questionsPerQueryLimit: number
) => {
  if (unitLevel === QueryCapRuleUnitLevel.FILE) {
    return 'You’ve been granted documents from your admin.'
  }
  if (filesPerQueryLimit === null) {
    return `A review query can have up to ${questionsPerQueryLimit} ${pluralize(
      'column',
      questionsPerQueryLimit
    )}.`
  }
  return `A review query can have up to ${pluralizeDocuments(
    filesPerQueryLimit
  )} and ${questionsPerQueryLimit} ${pluralize(
    'column',
    questionsPerQueryLimit
  )}.`
}

const getQueryCapExplanationMore = (
  unitLevel: QueryCapRuleUnitLevel,
  timeFrame: QueryCapRuleTimeFrame
) => {
  if (unitLevel === QueryCapRuleUnitLevel.CELL) {
    if (timeFrame === QueryCapRuleTimeFrame.CALENDAR_MONTH) {
      return 'Leftover queries do not rollover month over month.'
    }
  }
  if (unitLevel === QueryCapRuleUnitLevel.QUERY) {
    if (timeFrame === QueryCapRuleTimeFrame.CALENDAR_MONTH) {
      return 'Leftover queries do not rollover month over month.'
    }
  }
  if (unitLevel === QueryCapRuleUnitLevel.FILE) {
    if (timeFrame === QueryCapRuleTimeFrame.CALENDAR_MONTH) {
      return 'Leftover documents do not rollover month over month.'
    } else {
      return 'Run a review query in a project to start a Review.'
    }
  }
  return ''
}

export const useVaultUsageStore = create<VaultUsageState>((set) => ({
  reviewFilesPerQueryLimit: null,
  reviewQuestionsPerQueryLimit: 0,
  reviewQueryCapExplanation: '',
  reviewQueryCapExplanationMore: '',
  reviewQueryDenominator: 1,
  reviewQueryUsage: 0,
  reviewQueryLimit: null,
  reviewQueryLimitUnitLevel: QueryCapRuleUnitLevel.CELL,
  reviewQueryLimitTimeFrame: QueryCapRuleTimeFrame.CALENDAR_MONTH,
  reviewQueryLimitLevel: QueryCapRuleLevel.PER_USER,
  reviewQueryUsageDisplayString: '0',
  reviewQueryLimitDisplayString: '0',
  reviewQueryUnit: 'review query',
  reviewQueryLimitUnitDisplayString: '',
  reviewQueryRemainingDisplayString: '0',
  setReviewFilesPerQueryLimit: (limit) =>
    set((state) => ({
      reviewFilesPerQueryLimit: limit,
      reviewQueryCapExplanation: getQueryCapExplanation(
        state.reviewQueryLimitUnitLevel,
        limit,
        state.reviewQuestionsPerQueryLimit
      ),
      reviewQueryDenominator: getDenominator(
        state.reviewQueryLimitUnitLevel,
        limit,
        state.reviewQuestionsPerQueryLimit
      ),
      reviewQueryUsageDisplayString: getQueryUsageString(
        Math.min(
          state.reviewQueryUsage,
          state.reviewQueryLimit ?? state.reviewQueryUsage
        ),
        'usage',
        getDenominator(
          state.reviewQueryLimitUnitLevel,
          limit,
          state.reviewQuestionsPerQueryLimit
        )
      ),
      reviewQueryLimitDisplayString: getQueryUsageString(
        state.reviewQueryLimit,
        'usage',
        getDenominator(
          state.reviewQueryLimitUnitLevel,
          limit,
          state.reviewQuestionsPerQueryLimit
        )
      ),
      reviewQueryLimitUnitDisplayString: getQueryUnitString(
        state.reviewQueryLimit,
        state.reviewQueryUnit,
        getDenominator(
          state.reviewQueryLimitUnitLevel,
          limit,
          state.reviewQuestionsPerQueryLimit
        )
      ),
      reviewQueryRemainingDisplayString: getQueryUsageString(
        state.reviewQueryLimit
          ? Math.max(0, state.reviewQueryLimit - state.reviewQueryUsage)
          : null,
        'remaining',
        getDenominator(
          state.reviewQueryLimitUnitLevel,
          limit,
          state.reviewQuestionsPerQueryLimit
        )
      ),
    })),
  setReviewQuestionsPerQueryLimit: (limit) =>
    set((state) => ({
      reviewQuestionsPerQueryLimit: limit,
      reviewQueryCapExplanation: getQueryCapExplanation(
        state.reviewQueryLimitUnitLevel,
        state.reviewFilesPerQueryLimit,
        limit
      ),
      reviewQueryDenominator: getDenominator(
        state.reviewQueryLimitUnitLevel,
        state.reviewFilesPerQueryLimit,
        limit
      ),
      reviewQueryUsageDisplayString: getQueryUsageString(
        Math.min(
          state.reviewQueryUsage,
          state.reviewQueryLimit ?? state.reviewQueryUsage
        ),
        'usage',
        getDenominator(
          state.reviewQueryLimitUnitLevel,
          state.reviewFilesPerQueryLimit,
          limit
        )
      ),
      reviewQueryLimitDisplayString: getQueryUsageString(
        state.reviewQueryLimit,
        'usage',
        getDenominator(
          state.reviewQueryLimitUnitLevel,
          state.reviewFilesPerQueryLimit,
          limit
        )
      ),
      reviewQueryLimitUnitDisplayString: getQueryUnitString(
        state.reviewQueryLimit,
        state.reviewQueryUnit,
        getDenominator(
          state.reviewQueryLimitUnitLevel,
          state.reviewFilesPerQueryLimit,
          limit
        )
      ),
      reviewQueryRemainingDisplayString: getQueryUsageString(
        state.reviewQueryLimit
          ? Math.max(0, state.reviewQueryLimit - state.reviewQueryUsage)
          : null,
        'remaining',
        getDenominator(
          state.reviewQueryLimitUnitLevel,
          state.reviewFilesPerQueryLimit,
          limit
        )
      ),
    })),
  setReviewQueryUsage: (usage) =>
    set((state) => ({
      reviewQueryUsage: usage,
      reviewQueryUsageDisplayString: getQueryUsageString(
        Math.min(usage, state.reviewQueryLimit ?? usage),
        'usage',
        getDenominator(
          state.reviewQueryLimitUnitLevel,
          state.reviewFilesPerQueryLimit,
          state.reviewQuestionsPerQueryLimit
        )
      ),
      reviewQueryRemainingDisplayString: getQueryUsageString(
        state.reviewQueryLimit
          ? Math.max(0, state.reviewQueryLimit - usage)
          : null,
        'remaining',
        getDenominator(
          state.reviewQueryLimitUnitLevel,
          state.reviewFilesPerQueryLimit,
          state.reviewQuestionsPerQueryLimit
        )
      ),
    })),
  setReviewQueryLimit: (limit) =>
    set((state) => ({
      reviewQueryLimit: limit,
      reviewQueryUsageDisplayString: getQueryUsageString(
        Math.min(state.reviewQueryUsage, limit ?? state.reviewQueryUsage),
        'usage',
        getDenominator(
          state.reviewQueryLimitUnitLevel,
          state.reviewFilesPerQueryLimit,
          state.reviewQuestionsPerQueryLimit
        )
      ),
      reviewQueryLimitDisplayString: getQueryUsageString(
        limit,
        'usage',
        getDenominator(
          state.reviewQueryLimitUnitLevel,
          state.reviewFilesPerQueryLimit,
          state.reviewQuestionsPerQueryLimit
        )
      ),
      reviewQueryLimitUnitDisplayString: getQueryUnitString(
        limit,
        state.reviewQueryUnit,
        getDenominator(
          state.reviewQueryLimitUnitLevel,
          state.reviewFilesPerQueryLimit,
          state.reviewQuestionsPerQueryLimit
        )
      ),
      reviewQueryRemainingDisplayString: getQueryUsageString(
        limit ? Math.max(0, limit - state.reviewQueryUsage) : null,
        'remaining',
        getDenominator(
          state.reviewQueryLimitUnitLevel,
          state.reviewFilesPerQueryLimit,
          state.reviewQuestionsPerQueryLimit
        )
      ),
    })),
  setReviewQueryLimitUnitLevel: (unitLevel) =>
    set((state) => ({
      reviewQueryCapExplanation: getQueryCapExplanation(
        unitLevel,
        state.reviewFilesPerQueryLimit,
        state.reviewQuestionsPerQueryLimit
      ),
      reviewQueryCapExplanationMore: getQueryCapExplanationMore(
        unitLevel,
        state.reviewQueryLimitTimeFrame
      ),
      reviewQueryDenominator: getDenominator(
        unitLevel,
        state.reviewFilesPerQueryLimit,
        state.reviewQuestionsPerQueryLimit
      ),
      reviewQueryLimitUnitLevel: unitLevel,
      reviewQueryUnit:
        unitLevel === QueryCapRuleUnitLevel.FILE
          ? 'review document'
          : 'review query',
      reviewQueryUsageDisplayString: getQueryUsageString(
        Math.min(
          state.reviewQueryUsage,
          state.reviewQueryLimit ?? state.reviewQueryUsage
        ),
        'usage',
        getDenominator(
          unitLevel,
          state.reviewFilesPerQueryLimit,
          state.reviewQuestionsPerQueryLimit
        )
      ),
      reviewQueryLimitDisplayString: getQueryUsageString(
        state.reviewQueryLimit,
        'usage',
        getDenominator(
          unitLevel,
          state.reviewFilesPerQueryLimit,
          state.reviewQuestionsPerQueryLimit
        )
      ),
      reviewQueryLimitUnitDisplayString: getQueryUnitString(
        state.reviewQueryLimit,
        unitLevel === QueryCapRuleUnitLevel.FILE
          ? 'review document'
          : 'review query',
        getDenominator(
          unitLevel,
          state.reviewFilesPerQueryLimit,
          state.reviewQuestionsPerQueryLimit
        )
      ),
      reviewQueryRemainingDisplayString: getQueryUsageString(
        state.reviewQueryLimit
          ? Math.max(0, state.reviewQueryLimit - state.reviewQueryUsage)
          : null,
        'remaining',
        getDenominator(
          unitLevel,
          state.reviewFilesPerQueryLimit,
          state.reviewQuestionsPerQueryLimit
        )
      ),
    })),
  setReviewQueryLimitTimeFrame: (timeFrame) =>
    set((state) => ({
      reviewQueryCapExplanationMore: getQueryCapExplanationMore(
        state.reviewQueryLimitUnitLevel,
        timeFrame
      ),
      reviewQueryLimitTimeFrame: timeFrame,
    })),
  setReviewQueryLimitLevel: (level) =>
    set(() => ({
      reviewQueryLimitLevel: level,
    })),
}))
