/* tslint:disable */
/* eslint-disable */
/**
 * Harvey Web App - User Permissions
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Enum for specifying the workspace features.
 * @export
 */
export const WorkspaceFeature = {
    VAULT_ADD_ON: 'vault_add_on',
    VAULT_REVIEW_PACK: 'vault_review_pack'
} as const;
export type WorkspaceFeature = typeof WorkspaceFeature[keyof typeof WorkspaceFeature];


export function instanceOfWorkspaceFeature(value: any): boolean {
    for (const key in WorkspaceFeature) {
        if (Object.prototype.hasOwnProperty.call(WorkspaceFeature, key)) {
            if (WorkspaceFeature[key as keyof typeof WorkspaceFeature] === value) {
                return true;
            }
        }
    }
    return false;
}

export function WorkspaceFeatureFromJSON(json: any): WorkspaceFeature {
    return WorkspaceFeatureFromJSONTyped(json, false);
}

export function WorkspaceFeatureFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkspaceFeature {
    return json as WorkspaceFeature;
}

export function WorkspaceFeatureToJSON(value?: WorkspaceFeature | null): any {
    return value as any;
}

