import { useState, useEffect, useCallback } from 'react'

import { FetchWorkspace, type Workspace } from 'models/workspace'

const useWorkspaceDetail = ({
  workspaceId,
}: {
  workspaceId: string | undefined
}) => {
  const [workspace, setWorkspace] = useState<Workspace>({} as Workspace)
  const [isLoadingWorkspace, setIsLoadingWorkspace] = useState(true)

  const fetchWorkspace = useCallback(async () => {
    if (!workspaceId) return
    const workspace = await FetchWorkspace(Number(workspaceId))
    setWorkspace(workspace)
    setIsLoadingWorkspace(false)
  }, [workspaceId])

  useEffect(() => {
    void fetchWorkspace()
  }, [fetchWorkspace])

  return { workspace, isLoadingWorkspace }
}

export default useWorkspaceDetail
