import { ReviewCellResponse } from 'components/vault/utils/vault'

import { CellComparator, CompareConfig } from './cell-comparator-types'

export class CurrencyCellComparator extends CellComparator {
  compareCells({ answerA, answerB }: CompareConfig) {
    // We index at 0 because we know the response is always a single currency value
    const responseA = answerA.rawResponse?.[0] as ReviewCellResponse
    const responseB = answerB.rawResponse?.[0] as ReviewCellResponse

    return compareCurrencies({
      currencyCodeA: responseA.currencyCode || '',
      currencyCodeB: responseB.currencyCode || '',
      currencyAString: responseA.value || '',
      currencyBString: responseB.value || '',
    })
  }
}

export const compareCurrencies = ({
  currencyCodeA,
  currencyCodeB,
  currencyAString,
  currencyBString,
}: {
  currencyCodeA: string
  currencyCodeB: string
  currencyAString: string
  currencyBString: string
}) => {
  // Group by currency code
  if (currencyCodeA !== currencyCodeB) {
    return currencyCodeA > currencyCodeB ? 1 : -1
  }

  // Sort by numeric value
  const numberA = parseFloat(currencyAString)
  const numberB = parseFloat(currencyBString)

  return numberA > numberB ? 1 : -1
}
