import { removeWhitespace } from './string'

/**
 * This calculates the non-whitespace offset relative to the start of the range.
 * @param parentNode The parent node of the selection.
 * @param range The range of the selection.
 * @returns The start offset of the selection.
 */
export const calculateNoWhitespaceOffset = (
  parentNode: HTMLDivElement,
  range: Range
): number => {
  // Get the text of the start container up to the start offset
  const startingText = range.startContainer.textContent?.slice(
    0,
    range.startOffset
  )
  // remove it's whitespace
  let startOffset = removeWhitespace(startingText || '').length
  let container: Node | null = range.startContainer

  // Walk up the DOM from the startContainer to the parentNode element, accumulating offsets
  while (container && container !== parentNode) {
    if (container.previousSibling) {
      let sibling: ChildNode | null = container.previousSibling
      while (sibling) {
        if (sibling.nodeType === Node.TEXT_NODE) {
          startOffset += removeWhitespace((sibling as Text).data).length
        } else if (sibling.nodeName !== 'STYLE') {
          startOffset += removeWhitespace(sibling.textContent || '').length
        }
        sibling = sibling.previousSibling
      }
    }
    container = container.parentNode
  }
  return startOffset
}
