import React, { useMemo } from 'react'
import { useState } from 'react'
import { useInterval } from 'react-use'

import { MoreHorizontal, Users } from 'lucide-react'
import { useShallow } from 'zustand/react/shallow'

import { Maybe } from 'types'

import { useNavigateWithQueryParams } from 'hooks/use-navigate-with-query-params'
import { TodayOption, readableFormat } from 'utils/date-utils'
import { TaskType } from 'utils/task'
import { displayInfoMessage } from 'utils/toast'
import { cn } from 'utils/utils'

import { BaseAppPath } from 'components/base-app-path'
import { useAuthUser } from 'components/common/auth-context'
import { Badge } from 'components/ui/badge'
import { Button } from 'components/ui/button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'components/ui/dropdown-menu'
import Icon from 'components/ui/icon/icon'
import AlertIcon from 'components/ui/icons/alert-icon'
import { Spinner } from 'components/ui/spinner'
import { TableCell, TableRow } from 'components/ui/table'
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/ui/tooltip'
import useSharingPermissions from 'components/vault/hooks/use-sharing-permissions'
import useVaultQueryDetailStore from 'components/vault/query-detail/vault-query-detail-store'
import {
  REMOVE_PARAMS,
  projectsPath,
  queriesPath,
} from 'components/vault/utils/vault'
import { getEtaDisplayString } from 'components/vault/utils/vault-helpers'
import { isProjectShared } from 'components/vault/utils/vault-sharing-helpers'
import { useVaultSharingStore } from 'components/vault/utils/vault-sharing-store'
import { useVaultStore } from 'components/vault/utils/vault-store'

enum VaultQueryRowStatus {
  Processing = 'Processing',
  Completed = 'Completed',
  Cancelled = 'Cancelled',
  Failed = 'Failed',
}

export type VaultQueryRowProps = {
  isFromHistory: boolean
  projectId: string
  queryId: string
  title: string
  originalQuery?: string
  taskType: TaskType
  status: VaultQueryRowStatus
  updatedAt: Date
  eta?: Maybe<Date>
  userId?: string
  showUserIconAndTooltip?: boolean
  onCopyQueryHandler: () => void
  isDeleteEnabled: boolean
  onDeleteHandler: () => void
}

const VaultQueryRow = ({
  isFromHistory,
  projectId,
  queryId,
  title,
  originalQuery,
  taskType,
  status,
  updatedAt,
  eta,
  userId,
  showUserIconAndTooltip = false,
  onCopyQueryHandler,
  isDeleteEnabled,
  onDeleteHandler,
}: VaultQueryRowProps) => {
  const navigate = useNavigateWithQueryParams()
  const userInfo = useAuthUser()

  const { setPendingQueryFileIds, setPendingQueryQuestions } =
    useVaultQueryDetailStore(
      useShallow((s) => ({
        setPendingQueryFileIds: s.setPendingQueryFileIds,
        setPendingQueryQuestions: s.setPendingQueryQuestions,
      }))
    )

  const exampleProjectIds = useVaultStore(
    useShallow((s) => s.exampleProjectIds)
  )
  const sharedProjectIds = useVaultStore(useShallow((s) => s.sharedProjectIds))
  const isExampleProject = useMemo(
    () => projectId && exampleProjectIds.has(projectId),
    [projectId, exampleProjectIds]
  )
  const permissionsByProjectId = useVaultSharingStore(
    useShallow((s) => s.permissionsByProjectId)
  )
  const isSharedProject = isProjectShared(
    sharedProjectIds,
    permissionsByProjectId,
    projectId
  )
  const { doesCurrentUserHaveEditPermission } = useSharingPermissions({
    projectId,
  })

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [minuteCounter, setMinuteCounter] = useState(0)
  useInterval(
    () => {
      setMinuteCounter((prev) => prev + 1)
    },
    eta ? 60000 : null
  ) // Every 1 minute update the component to refresh the eta time

  const statusAttachment =
    status === VaultQueryRowStatus.Processing ? (
      eta ? (
        <Badge
          variant="secondary"
          className="rounded-full border-0 pl-1 pr-2.5 text-muted"
        >
          <Spinner className="mx-0 mr-2 h-3 w-3 shrink-0" />
          <p className="text-xs">{getEtaDisplayString(eta, true, null)}</p>
        </Badge>
      ) : (
        <Spinner className="mx-0 h-4 w-4 shrink-0" />
      )
    ) : status === VaultQueryRowStatus.Cancelled ? (
      <Badge variant="secondary" className="rounded-full border-0 text-muted">
        <p className="text-xs">Cancelled</p>
      </Badge>
    ) : status === VaultQueryRowStatus.Failed ? (
      <Tooltip>
        <TooltipTrigger>
          <AlertIcon className="h-4 w-4 shrink-0" />
        </TooltipTrigger>
        <TooltipContent className="max-w-96">
          {taskType === TaskType.VAULT_REVIEW
            ? 'This query resulted in errors. You can view the errors on the result page.'
            : 'This query resulted in errors.'}
        </TooltipContent>
      </Tooltip>
    ) : null

  const taskTypeTitle =
    taskType === TaskType.VAULT_REVIEW
      ? userInfo.IsVaultV2User
        ? 'Review table'
        : 'Review query'
      : taskType === TaskType.ASSISTANT_CHAT
      ? userInfo.IsVaultV2User
        ? 'Assist'
        : 'Assist query'
      : taskType === TaskType.ASSISTANT_DRAFT
      ? userInfo.IsVaultV2User
        ? 'Draft'
        : 'Draft query'
      : 'Ask query'

  // TODO(@Nami) - Remove assistant chat task type pending product requirements
  const vaultProjectHref = `${BaseAppPath.Vault}${projectsPath}${projectId}${queriesPath}${queryId}`
  const assistantChatHref = `${BaseAppPath.Assistant}/assist/${queryId}`
  const assistantDraftHref = `${BaseAppPath.Assistant}/draft/${queryId}`

  const href =
    taskType === TaskType.ASSISTANT_CHAT
      ? assistantChatHref
      : taskType === TaskType.ASSISTANT_DRAFT
      ? assistantDraftHref
      : vaultProjectHref

  return (
    <TableRow
      key={queryId}
      className="cursor-pointer"
      onClick={async (event) => {
        if (
          isFromHistory &&
          taskType === TaskType.VAULT &&
          status === VaultQueryRowStatus.Processing
        ) {
          displayInfoMessage(
            'The query is still processing, please wait for a moment.'
          )
          return
        }

        const openInNewTab = event.metaKey || event.ctrlKey
        if (openInNewTab) {
          window.open(href, '_blank')
        } else {
          setPendingQueryFileIds(null)
          setPendingQueryQuestions(null)
          navigate(href, {}, REMOVE_PARAMS)
        }
      }}
    >
      <TableCell className="px-1 py-2.5">
        <div
          className={cn('flex items-center', {
            'text-muted':
              status === VaultQueryRowStatus.Cancelled ||
              status === VaultQueryRowStatus.Processing,
          })}
        >
          {userInfo.IsVaultV2User ? (
            <p className="line-clamp-1 break-all text-left">{title}</p>
          ) : (
            <Tooltip>
              <TooltipTrigger>
                <p className="line-clamp-1 break-all text-left">{title}</p>
              </TooltipTrigger>
              <TooltipContent className="max-w-96" align="start">
                {originalQuery && originalQuery !== title ? (
                  originalQuery.includes('\n') ? (
                    <>
                      Original query:
                      <br />
                      {originalQuery.split('\n').map((line, index) => (
                        <React.Fragment key={index}>
                          {line}
                          <br />
                        </React.Fragment>
                      ))}
                    </>
                  ) : (
                    <>Original query: {originalQuery}</>
                  )
                ) : (
                  title
                )}
              </TooltipContent>
            </Tooltip>
          )}
          {statusAttachment && (
            <div className="ml-1 mr-2 flex shrink-0 items-center">
              {statusAttachment}
            </div>
          )}
        </div>
      </TableCell>
      {isSharedProject && (
        <TableCell className="px-1 py-2.5 ">
          {showUserIconAndTooltip ? (
            <Tooltip>
              <TooltipTrigger>
                <div className="flex items-center space-x-1">
                  <Icon icon={Users} size="small" className="text-muted" />
                  <p className="truncate text-muted">{userId}</p>
                </div>
              </TooltipTrigger>
              <TooltipContent className="max-w-96">
                Created by {userId}
              </TooltipContent>
            </Tooltip>
          ) : (
            <p className="truncate text-muted">{userId}</p>
          )}
        </TableCell>
      )}
      <TableCell className="px-1 py-2.5 ">
        <p className="truncate text-muted">{taskTypeTitle}</p>
      </TableCell>
      <TableCell className="px-1 py-2.5 text-right">
        <Tooltip>
          <TooltipTrigger asChild>
            <p className="truncate text-right text-muted">
              {readableFormat(updatedAt, TodayOption.showTime, 'short')}
            </p>
          </TooltipTrigger>
          <TooltipContent className="max-w-96">
            {updatedAt.toLocaleString()}
          </TooltipContent>
        </Tooltip>
      </TableCell>
      {!isExampleProject && (
        <TableCell className="px-0 py-1 text-right">
          <VaultQueryRowMoreMenu
            href={href}
            onCopyQueryHandler={onCopyQueryHandler}
            isDeleteEnabled={
              doesCurrentUserHaveEditPermission && isDeleteEnabled
            }
            onDeleteHandler={onDeleteHandler}
          />
        </TableCell>
      )}
    </TableRow>
  )
}

const VaultQueryRowMoreMenu = ({
  href,
  onCopyQueryHandler,
  isDeleteEnabled,
  onDeleteHandler,
}: {
  href: string
  onCopyQueryHandler: () => void
  isDeleteEnabled: boolean
  onDeleteHandler: () => void
}) => {
  const userInfo = useAuthUser()
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const onOpenChangeHandler = (open: boolean) => {
    setIsDropdownOpen(open)
  }

  return (
    <DropdownMenu onOpenChange={onOpenChangeHandler}>
      <DropdownMenuTrigger asChild>
        <Button
          variant="ghost"
          size="smIcon"
          className={cn({
            'bg-button-secondary text-primary': isDropdownOpen,
          })}
        >
          <Icon icon={MoreHorizontal} />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        {!userInfo.IsVaultV2User && (
          <DropdownMenuItem
            onClick={(event) => {
              event.stopPropagation()
              onCopyQueryHandler()
            }}
          >
            Copy query
          </DropdownMenuItem>
        )}
        <DropdownMenuItem
          onClick={(event) => {
            event.stopPropagation()
            window.open(href, '_blank')
          }}
        >
          Open in new tab
        </DropdownMenuItem>
        {isDeleteEnabled && (
          <DropdownMenuItem
            onClick={(event) => {
              event.stopPropagation()
              onDeleteHandler()
            }}
            className="text-destructive"
          >
            Delete
          </DropdownMenuItem>
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

export { VaultQueryRow, VaultQueryRowStatus }
