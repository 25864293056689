import React, { useEffect, useRef, useState } from 'react'

import { ChevronDown, ChevronUp, Copy, Edit, ListPlusIcon } from 'lucide-react'

import { EventKind } from 'openapi/models/EventKind'

import { displaySuccessMessage } from 'utils/toast'
import { cn } from 'utils/utils'

import { useAssistantAnalytics } from 'components/assistant/hooks/use-assistant-analytics'
import { useAssistantStore } from 'components/assistant/stores/assistant-store'
import { AssistantMessage } from 'components/assistant/types'
import { getMessageQuery } from 'components/assistant/utils/assistant-helpers'
import { useAuthUser } from 'components/common/auth-context'
import { SavePromptButton } from 'components/library/save-prompt-button'
import { Button, ButtonProps } from 'components/ui/button'
import Icon from 'components/ui/icon/icon'
import { Tooltip, TooltipTrigger, TooltipContent } from 'components/ui/tooltip'

interface Props {
  message?: AssistantMessage
  onEditMessage?: (message: AssistantMessage | null) => void
  size?: 'sm' | 'default'
}

const AssistantQuestion = ({
  message,
  onEditMessage,
  size = 'default',
}: Props) => {
  const userInfo = useAuthUser()
  const trackEvent = useAssistantAnalytics()
  const query = getMessageQuery(message)

  const [isTruncated, setIsTruncated] = useState(false)
  const [isExpanded, setIsExpanded] = useState(false)
  const handleToggleExpand = () => {
    setIsExpanded((prevExpanded) => !prevExpanded)
  }
  const isEventOwner = useAssistantStore((state) => state.isEventOwner)

  const questionRef = useRef<HTMLDivElement | null>(null)

  const handleSetTruncated = () => {
    if (!questionRef.current) return
    setIsTruncated(
      questionRef.current.scrollHeight > questionRef.current.clientHeight
    )
  }

  useEffect(() => {
    handleSetTruncated()
    window.addEventListener('resize', handleSetTruncated)

    return () => {
      window.removeEventListener('resize', handleSetTruncated)
    }
  }, [])

  const handleCopy = async () => {
    await navigator.clipboard.writeText(query)
    trackEvent('Message Copied')
    displaySuccessMessage('Copied query to clipboard')
  }

  const handleEdit = () => {
    if (!onEditMessage || !message) return
    onEditMessage(message)
    trackEvent('Edit Message Clicked')
  }

  return (
    <>
      <div
        className={cn('mb-4 whitespace-pre-wrap text-sm', {
          'line-clamp-4': !isExpanded,
          'mb-2 text-xs': size === 'sm',
        })}
        ref={questionRef}
      >
        {query}
      </div>
      <div className="flex items-center justify-between">
        {message && (
          <div
            className={cn('flex items-center', {
              'space-x-0.5': size === 'sm',
            })}
          >
            <ActionIcon onClick={handleCopy} size={size} tooltip="Copy query">
              <Icon icon={Copy} />
            </ActionIcon>
            {userInfo.IsLibraryPrivatePromptUser && (
              <SavePromptButton
                query={query}
                triggerComponent={(
                  disabled,
                  buttonTooltipText,
                  onMouseEnter
                ) => (
                  <ActionIcon
                    disabled={disabled}
                    onMouseEnter={onMouseEnter}
                    size={size}
                    tooltip={buttonTooltipText}
                    tooltipClassName={cn({
                      'cursor-default': disabled,
                    })}
                  >
                    <Icon icon={ListPlusIcon} />
                  </ActionIcon>
                )}
                eventKind={EventKind.ASSISTANT_CHAT}
              />
            )}
            {isEventOwner && onEditMessage && (
              <ActionIcon
                onClick={handleEdit}
                size={size}
                tooltip="Edit query"
                data-testid="edit-button"
              >
                <Icon icon={Edit} />
              </ActionIcon>
            )}
          </div>
        )}
        {isTruncated && (
          <div className="ml-auto shrink-0">
            <Button
              className="inline-flex text-xs font-medium text-muted hover:bg-button-secondary-hover hover:text-muted"
              onClick={handleToggleExpand}
              variant="ghost"
              size="sm"
            >
              {isExpanded ? 'Show less' : 'Show more'}{' '}
              <Icon
                className="ml-1"
                icon={isExpanded ? ChevronUp : ChevronDown}
                size="small"
              />
            </Button>
          </div>
        )}
      </div>
    </>
  )
}

export default AssistantQuestion

const ActionIcon = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ children, size, tooltip, ...props }, ref) => {
    return (
      <Tooltip disableHoverableContent>
        <TooltipTrigger asChild>
          <Button
            className="text-muted hover:bg-button-secondary-hover hover:text-muted"
            variant="ghost"
            ref={ref}
            size={size === 'sm' ? 'xsIcon' : 'smIcon'}
            {...props}
          >
            {children}
          </Button>
        </TooltipTrigger>
        <TooltipContent>{tooltip}</TooltipContent>
      </Tooltip>
    )
  }
)
ActionIcon.displayName = 'ActionIcon'
