import React, { useState, useMemo, useRef, useEffect, useCallback } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'

import { max } from 'date-fns'
import _, { isEmpty, isNil } from 'lodash'
import { useShallow } from 'zustand/react/shallow'

import { FetchHistoryItem } from 'models/fetchers/history-fetcher'
import { EventKind } from 'openapi/models/EventKind'
import { SourceType } from 'openapi/models/SourceType'
import { VaultFile } from 'openapi/models/VaultFile'
import { usePDFViewerStore } from 'stores/pdf-viewer-store'

import { useAnnotationCaches } from 'hooks/use-annotation-caches'
import { useNavigateWithQueryParams } from 'hooks/use-navigate-with-query-params'
import {
  applyAnnotations,
  removeAnnotations,
  applyFrontendAnnotations,
} from 'utils/pspdfkit-helpers'
import { SafeRecord } from 'utils/safe-types'
import { Source, TaskStatus } from 'utils/task'
import { displayErrorMessage } from 'utils/toast'

import { BaseAppPath } from 'components/base-app-path'
import { useAuthUser } from 'components/common/auth-context'
import { Button } from 'components/ui/button'
import Combobox from 'components/ui/combobox/combobox'
import { ScrollArea } from 'components/ui/scroll-area'
import Skeleton from 'components/ui/skeleton'
import { useFileReviewRows } from 'components/vault/hooks/use-file-review-rows'
import useRecentQueries from 'components/vault/hooks/use-recent-queries'
import {
  REMOVE_PARAMS,
  NUM_ALL_QUERIES_TO_FETCH,
  projectsPath,
  queriesPath,
  queryIdSearchParamKey,
  sourceIdSearchParamKey,
  ReviewAnswer,
  ReviewCellStatus,
  ColumnDataType,
  QueryQuestion,
} from 'components/vault/utils/vault'
import {
  columnToQueryQuestion,
  updateQueryStateForHistoryItem,
} from 'components/vault/utils/vault-helpers'
import {
  VaultSocketTask,
  useVaultStore,
} from 'components/vault/utils/vault-store'

import AskQueryResponse from './ask-query-response'
import ReviewQueryResponse from './review-query-response'

const EMPTY_COPY = 'You haven’t created any queries with this file yet'

export type HistoricQuery = {
  queryId: string
  taskType: EventKind | null
  title: string
  createdAt: Date | null
  completedAt: Date | null
  cancelledAt: Date | null
  failedAt: Date | null
  fileIdToSources: SafeRecord<string, Source[]>
  sources: Source[]
  response: string
  answers: SafeRecord<string, ReviewAnswer[]>
  numQuestions: number
  columnHeaders: { id: string; text: string; columnDataType?: ColumnDataType }[]
  questions: QueryQuestion[]
}

const HistoricQueriesHeader = () => {
  const currentProject = useVaultStore(useShallow((s) => s.currentProject))
  return (
    <div className="mb-4">
      <p className="font-semibold">Queries</p>
      <p className="text-muted">
        Your recent queries in {currentProject?.name}
      </p>
    </div>
  )
}

const HistoricQueries = () => {
  const userInfo = useAuthUser()
  const { projectId, fileId } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const navigateWithQueryParams = useNavigateWithQueryParams()

  const searchParamQueryId = searchParams.get(queryIdSearchParamKey)
  const searchParamSourceId = searchParams.get(sourceIdSearchParamKey)

  // TODO: Revisit this query to not requery all queries because we might have it in zustand already
  const { isLoadingHistory } = useRecentQueries({
    projectId: projectId!,
    maxQueries: NUM_ALL_QUERIES_TO_FETCH,
    hasInProgressHistoryEvents: false,
  })
  const { reviewRows, isLoading: isLoadingReviewRows } = useFileReviewRows(
    fileId!
  )
  const isLoading = userInfo.IsVaultV2User
    ? isLoadingReviewRows
    : isLoadingHistory
  const activeDocument = useVaultStore(useShallow((s) => s.activeDocument))
  const queryIdToState = useVaultStore(useShallow((s) => s.queryIdToState))
  const queryIdToReviewState = useVaultStore(
    useShallow((s) => s.queryIdToReviewState)
  )
  const setTask = useVaultStore(useShallow((s) => s.setTask))
  const setReviewTask = useVaultStore(useShallow((s) => s.setReviewTask))

  const instance = usePDFViewerStore(useShallow((s) => s.instance))
  const isPdfLoading = usePDFViewerStore((s) => s.isPdfLoading)

  const queryIdRef = useRef<string | null>(null)
  const fileIdRef = useRef<string | null>(null)

  const [isLoadingHistoryItem, setIsLoadingHistoryItem] = useState(false)
  const [selectedQueryId, setSelectedQueryId] = useState<string | null>(null)

  const clearDocumentAnnotation = usePDFViewerStore(
    (s) => s.clearDocumentAnnotation
  )
  const setIsAnnotating = usePDFViewerStore((s) => s.setIsAnnotating)

  // Only want to clear document-specific annotation cache when switching to a different document
  useEffect(() => {
    clearDocumentAnnotation()
  }, [activeDocument, clearDocumentAnnotation])

  const {
    sourceAnnotationsRef,
    updateSourceAnnotationsRef,
    documentAnnotationsRef,
    updateDocumentAnnotationsRef,
  } = useAnnotationCaches()

  const fileQueries: HistoricQuery[] = useMemo(() => {
    if (!fileId || isLoading) return []

    const queryData = Object.values(queryIdToState)
      .map((query) => {
        if (!query) return undefined
        return {
          ...query,
          ...queryIdToReviewState[query.queryId],
        }
      })
      .filter(Boolean) as VaultSocketTask[]
    const queriesWithCurrentFileId = queryData.filter(
      (query: VaultSocketTask) => {
        const isQueryLoading = query.isLoading

        const queryFileIds =
          query.taskType === EventKind.VAULT_REVIEW
            ? query.processedFileIds
            : query.sourcedFileIds
        const queryErroredFileIds =
          query.taskType === EventKind.VAULT_REVIEW
            ? Object.keys(query.errors)
            : []

        return (
          !isQueryLoading &&
          !isNil(queryFileIds) &&
          queryFileIds.includes(fileId) &&
          !queryErroredFileIds.includes(fileId)
        )
      }
    )

    if (userInfo.IsVaultV2User) {
      const reviewQueries = reviewRows
        .map((reviewRow) => {
          const sources = reviewRow.sources.map(
            (source): Source => ({
              ...source,
              documentId: fileId,
              sourceType: SourceType.PDF_KIT,
              annotations: [],
              page: source.pageNumber,
              documentUrl: '', // We don't have the document URL for event v2 sources
            })
          )
          const columnIdToQuestionId = new Map(
            reviewRow.columns.map((column) => [
              column.id,
              String(column.displayId),
            ])
          )
          const answers = reviewRow.cells
            .filter((cell) => cell.status === ReviewCellStatus.COMPLETED)
            .reduce((acc: SafeRecord<string, ReviewAnswer[]>, cell) => {
              acc[fileId] ??= []
              acc[fileId]!.push({
                columnId: columnIdToQuestionId.get(cell.reviewColumnId) ?? '',
                long: false,
                text:
                  cell.columnDataType === ColumnDataType.date
                    ? cell.rawShortResponse?.[0].value ??
                      cell.shortResponse ??
                      ''
                    : cell.shortResponse ?? '',
                columnDataType: cell.columnDataType,
                rawResponse: cell.rawShortResponse,
              })
              acc[fileId]!.push({
                columnId: columnIdToQuestionId.get(cell.reviewColumnId) ?? '',
                long: true,
                text: cell.response,
                columnDataType: cell.longColumnDataType,
                rawResponse: cell.rawResponse,
              })
              return acc
            }, {})
          if (Object.keys(answers).length === 0) {
            return undefined
          }
          return {
            ...reviewRow,
            queryId: reviewRow.eventId,
            taskType: EventKind.VAULT_REVIEW,
            title: isEmpty(reviewRow.eventTitle)
              ? 'Untitled'
              : reviewRow.eventTitle,
            createdAt: new Date(reviewRow.eventCreatedAt),
            completedAt:
              reviewRow.eventStatus === TaskStatus.COMPLETED
                ? new Date(reviewRow.eventUpdatedAt)
                : null,
            cancelledAt:
              reviewRow.eventStatus === TaskStatus.CANCELLED
                ? new Date(reviewRow.eventUpdatedAt)
                : null,
            failedAt:
              reviewRow.eventStatus === TaskStatus.ERRORED
                ? new Date(reviewRow.eventUpdatedAt)
                : null,
            fileIdToSources: { [fileId]: sources },
            sources: sources,
            response: '',
            answers: answers,
            numQuestions: reviewRow.columns.length,
            columnHeaders: reviewRow.columns
              .sort((a, b) => a.displayId - b.displayId)
              .map((column) => ({
                id: String(column.displayId),
                text: column.header,
                columnDataType: column.dataType,
              })),
            questions: reviewRow.columns
              .sort((a, b) => a.displayId - b.displayId)
              .map(columnToQueryQuestion),
          }
        })
        .filter(Boolean) as HistoricQuery[]
      // TODO: Add assistant chat queries to the list
      return reviewQueries.sort((a, b) => {
        return (
          new Date(
            max(
              [a.completedAt, a.failedAt, a.cancelledAt, a.createdAt].filter(
                Boolean
              ) as Date[]
            )
          ).getTime() -
          new Date(
            max(
              [b.completedAt, b.failedAt, b.cancelledAt, b.createdAt].filter(
                Boolean
              ) as Date[]
            )
          ).getTime()
        )
      })
    }

    return queriesWithCurrentFileId
  }, [
    queryIdToState,
    queryIdToReviewState,
    fileId,
    isLoading,
    userInfo.IsVaultV2User,
    reviewRows,
  ])
  const selectedQuery = useMemo(() => {
    return fileQueries.find((query) => query.queryId === selectedQueryId)
  }, [fileQueries, selectedQueryId])
  const fileSources = useMemo(() => {
    if (!selectedQuery || !fileId) return []
    return selectedQuery.taskType === EventKind.VAULT_REVIEW
      ? selectedQuery.fileIdToSources[fileId] ?? []
      : selectedQuery.sources
  }, [selectedQuery, fileId])

  const comboboxOptions = useMemo(() => {
    if (!fileId) return []
    return fileQueries.map((query) => {
      return {
        value: query.queryId,
        label: query.title,
        extras: [
          query.cancelledAt ? 'Cancelled' : '',
          query.failedAt ? 'Failed' : '',
        ].filter(Boolean),
      }
    })
  }, [fileId, fileQueries])

  const resetHandler = useCallback(() => {
    setSelectedQueryId(null)
    queryIdRef.current = null
    fileIdRef.current = null
    setSearchParams((prev) => {
      const newParams = new URLSearchParams(prev)
      newParams.delete(queryIdSearchParamKey)
      return newParams
    })
  }, [setSelectedQueryId, setSearchParams])

  const annotationsHandler = useCallback(async () => {
    if (isPdfLoading) return
    const activeDocumentAsVaultFile = activeDocument as VaultFile
    const shouldApplyAnnotations =
      selectedQuery &&
      queryIdRef.current === selectedQuery.queryId &&
      instance &&
      activeDocument &&
      fileSources.length > 0 &&
      fileId === activeDocumentAsVaultFile.id

    const sourceToApply = fileSources.find(
      (source) => source.id === searchParamSourceId
    )
    if (!shouldApplyAnnotations) return
    const shouldUseBackendProvidedHighlights =
      searchParamSourceId &&
      sourceToApply &&
      sourceToApply.annotations.length > 0
    if (shouldUseBackendProvidedHighlights) {
      await applyAnnotations(
        activeDocument,
        fileSources,
        instance,
        sourceToApply
      )
    } else {
      setIsAnnotating(true)
      await applyFrontendAnnotations({
        sources: fileSources.filter(
          (source) =>
            source.documentId === activeDocument.id && !_.isNil(source.page)
        ),
        pdfkitInstance: instance,
        selectedSource: sourceToApply,
        cachedSourceAnnotations: sourceAnnotationsRef.current,
        updateSourceAnnotationStore: updateSourceAnnotationsRef.current,
        cachedDocumentAnnotations: documentAnnotationsRef.current,
        updateDocumentAnnotationStore: updateDocumentAnnotationsRef.current,
      })
      setIsAnnotating(false)
    }
  }, [
    isPdfLoading,
    fileId,
    searchParamSourceId,
    selectedQuery,
    instance,
    activeDocument,
    fileSources,
    documentAnnotationsRef,
    sourceAnnotationsRef,
    updateDocumentAnnotationsRef,
    updateSourceAnnotationsRef,
    setIsAnnotating,
  ])

  const onSelectQueryHandler = useCallback(
    async (queryId: string | null, updateQueryParams = true) => {
      if (!fileId) return
      if (!queryId) {
        resetHandler()
        return
      }
      const query = fileQueries.find((query: HistoricQuery) => {
        return query.queryId === queryId
      })

      if (!query) {
        displayErrorMessage('Could not find query. Please try again.')
        resetHandler()
        return
      }
      queryIdRef.current = queryId
      fileIdRef.current = fileId

      if (fileSources.length > 0 && instance) {
        await removeAnnotations(instance)
      }
      if (updateQueryParams) {
        setSearchParams((prev) => {
          const newParams = new URLSearchParams(prev)
          newParams.set(queryIdSearchParamKey, queryId)
          newParams.delete(sourceIdSearchParamKey)
          return newParams
        })
      }
      setSelectedQueryId(queryId)

      const sources =
        query.taskType === EventKind.VAULT_REVIEW
          ? query.fileIdToSources[fileId] ?? []
          : query.sources
      if (isEmpty(sources) && !userInfo.IsVaultV2User) {
        // fetch the query to get sources/annotations
        setIsLoadingHistoryItem(true)
        const fetchHistoryItem = await FetchHistoryItem({
          id: queryId,
          useVaultEndpoint: true,
        })
        setIsLoadingHistoryItem(false)

        if (fetchHistoryItem) {
          updateQueryStateForHistoryItem(
            fetchHistoryItem,
            setTask,
            setReviewTask
          )
        }
      }
    },
    [
      fileId,
      fileQueries,
      fileSources,
      instance,
      resetHandler,
      setSelectedQueryId,
      setSearchParams,
      setTask,
      setReviewTask,
      setIsLoadingHistoryItem,
      userInfo.IsVaultV2User,
    ]
  )

  useEffect(() => {
    void annotationsHandler()
  }, [annotationsHandler])

  const navigateToQueryHandler = () => {
    const newPath = `${BaseAppPath.Vault}${projectsPath}${projectId}${queriesPath}${queryIdRef.current}`
    navigateWithQueryParams(newPath, {}, REMOVE_PARAMS)
  }

  useEffect(() => {
    if (fileQueries.length === 0) return
    if (searchParamQueryId && searchParamQueryId !== queryIdRef.current) {
      void onSelectQueryHandler(searchParamQueryId, false)
    } else if (searchParamQueryId && fileId !== fileIdRef.current) {
      void onSelectQueryHandler(searchParamQueryId, false)
    } else if (!searchParamQueryId && !queryIdRef.current) {
      void onSelectQueryHandler(
        // Select the most recent query
        fileQueries[fileQueries.length - 1].queryId,
        true
      )
    }
  }, [
    fileId,
    fileQueries,
    searchParamQueryId,
    onSelectQueryHandler,
    resetHandler,
  ])

  return (
    <ScrollArea isFullHeight className="h-full w-full">
      <div className="p-6">
        <div>
          <HistoricQueriesHeader />

          {(isLoading || fileQueries.length === 0) && (
            <div className="mb-2 flex h-full w-full items-center justify-center">
              <p className="text-sm text-muted">
                {isLoading ? 'Loading…' : EMPTY_COPY}
              </p>
            </div>
          )}

          <Combobox
            align="start"
            className="w-full"
            hasCreateNewCommand={false}
            disabled={fileQueries.length === 0 || isLoadingHistoryItem}
            defaultText="Select query"
            value={selectedQuery?.queryId ?? ''}
            setValue={onSelectQueryHandler}
            options={comboboxOptions.reverse()}
          />
          {selectedQuery && (
            <div className="flex justify-end">
              <Button
                onClick={navigateToQueryHandler}
                variant="link"
                className="pr-0"
              >
                {selectedQuery.taskType === EventKind.VAULT_REVIEW
                  ? 'Open query in table'
                  : 'Open query'}
              </Button>
            </div>
          )}
        </div>
        {selectedQuery &&
          selectedQuery.taskType === EventKind.VAULT &&
          isEmpty(selectedQuery.response) && (
            <Skeleton rowHeight="h-8" rows={5} />
          )}
        {selectedQuery &&
          selectedQuery.taskType === EventKind.VAULT_REVIEW &&
          isEmpty(selectedQuery.answers) && (
            <Skeleton rowHeight="h-8" rows={selectedQuery.numQuestions} />
          )}
        {selectedQuery && selectedQuery.taskType === EventKind.VAULT_REVIEW && (
          <div className="space-y-2 divide-y">
            <ReviewQueryResponse
              selectedQuery={selectedQuery}
              sources={fileSources}
              fileId={fileId}
              projectId={projectId}
            />
          </div>
        )}
        {selectedQuery && selectedQuery.taskType === EventKind.VAULT && (
          <AskQueryResponse
            fileId={fileId}
            projectId={projectId}
            selectedQuery={selectedQuery as VaultSocketTask}
          />
        )}
      </div>
    </ScrollArea>
  )
}

export default HistoricQueries
