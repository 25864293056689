import { NavigateFunction } from 'react-router-dom'

import { driver, DriveStep } from 'driver.js'
import { create } from 'zustand'

import { UserInfo } from 'models/user-info'

import { waitForElement } from 'utils/utils'

type ProductTourState = {
  isTourActive: boolean
  startTour: () => void
  endTour: () => void
}

const useClientMatterAdminTourStore = create<ProductTourState>((set) => ({
  isTourActive: false,
  startTour: () => set({ isTourActive: true }),
  endTour: () => set({ isTourActive: false }),
}))

export const runClientMatterAdminTour = async (
  navigate: NavigateFunction,
  userInfo: UserInfo | undefined
) => {
  const startRoute = window.location.pathname
  navigate('/assistant')
  await waitForElement('#assistant-container')

  if (!userInfo) {
    return
  }

  const closeProductTour = () => {
    useClientMatterAdminTourStore.getState().endTour()
    driverObj.destroy()
    navigate(startRoute)
  }

  const goToPage = {
    settings: async () => {
      navigate('/settings/client-matters')
      await waitForElement('#settings-client-matters')
    },
    assistant: async () => {
      navigate('/assistant')
      await waitForElement('#assistant-container')
    },
    history: async () => {
      navigate('/history')
      await waitForElement('#table-head-query')
    },
  }

  const steps: DriveStep[] = [
    {
      // 1
      element: '#client-matter-select',
      popover: {
        title: 'Select client matter',
        description:
          'This is where users can select the client matter to be associated with queries they run',
      },
    },

    {
      // 2
      element: '#sidebar-item--Settings',
      popover: {
        title: 'Manage client matters',
        description: 'Go to Settings to manage client matters in Harvey',
        onNextClick: async () => {
          await goToPage.settings()
          driverObj.moveNext()
        },
      },
    },
    {
      // 3
      element: '#settings-client-matters-table',
      popover: {
        title: 'View and manage client matters',
        description:
          'This is where you can see information about existing client matters',
        onPrevClick: async () => {
          await goToPage.assistant()
          driverObj.movePrevious()
        },
      },
    },
    {
      // 4
      element: '#client-matter-add-button',
      popover: {
        title: 'Add new client matters',
        description: 'Input new client matters, or bulk upload a CSV',
      },
    },
    {
      // 5
      element: '#client-matter-admin-settings',
      popover: {
        title: 'Set client matter settings',
        description:
          'Choose whether to restrict users from submitting queries without a client matter, or restrict users from adding their own client matters. This will impact all users.',
      },
    },
  ]

  if (userInfo.IsHistoryUser) {
    steps.push({
      // 6
      element: '#admin-settings-nav-item-Workspace-history',
      popover: {
        title: 'View historical client matters',
        description:
          'See which client matters have been associated with your users’ queries',
      },
    })
  }

  const driverObj = driver({
    showProgress: true,
    smoothScroll: false,
    steps,
    disableActiveInteraction: true,
    onCloseClick: closeProductTour,
    onDestroyStarted: async () => {
      if (!driverObj.hasNextStep()) {
        closeProductTour()
      }
    },
    popoverClass: 'general-ui-tour-popover',
    nextBtnText: 'Next',
    prevBtnText: 'Previous',
  })

  driverObj.drive()
  useClientMatterAdminTourStore.getState().startTour()
}
