import React, { useState, useRef, useEffect, useCallback } from 'react'
import { useClickAway } from 'react-use'

import { Plus } from 'lucide-react'
import { v4 as uuidv4 } from 'uuid'
import { useShallow } from 'zustand/react/shallow'

import { displayWarningMessage } from 'utils/toast'

import { useAnalytics } from 'components/common/analytics/analytics-context'
import { Button } from 'components/ui/button'
import { Input } from 'components/ui/input'
import { QueryQuestion } from 'components/vault/utils/vault'
import { useVaultStore } from 'components/vault/utils/vault-store'
import { useVaultUsageStore } from 'components/vault/utils/vault-usage-store'

interface Props {
  setQuestions: (questions: QueryQuestion[]) => void
  questions: QueryQuestion[]
  maxQuestionCharacterLength: number
  minQuestionCharacterLength: number
  setIsEditingQuestion: (isEditingQuestion: boolean) => void
}

export const NewQuestion: React.FC<Props> = ({
  setQuestions,
  questions,
  maxQuestionCharacterLength,
  minQuestionCharacterLength,
  setIsEditingQuestion,
}) => {
  const { trackEvent } = useAnalytics()
  const [isOpen, setIsOpen] = useState(false)
  const [newQuestionText, setNewQuestionText] = useState<string>('')
  const ref = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)

  const selectedQuestions = useVaultStore(
    useShallow((s) => s.selectedQuestions)
  )
  const setSelectedQuestions = useVaultStore((s) => s.setSelectedQuestions)
  const questionsLimit = useVaultUsageStore(
    (s) => s.reviewQuestionsPerQueryLimit
  )

  const handleAddQuestionClick = () => {
    trackEvent('Vault Query Box Add Column Button Clicked')
    setIsOpen(true)
    setIsEditingQuestion(true)
    setTimeout(() => inputRef.current?.focus(), 0)
  }

  const handleCancelClick = () => {
    setIsOpen(false)
    setIsEditingQuestion(false)
  }

  const handleConfirmAddClick = useCallback(() => {
    // Validation
    const isDuplicate = questions.some(
      (question) =>
        question.text.toLocaleLowerCase() ===
        newQuestionText.toLocaleLowerCase()
    )

    if (newQuestionText.trim() === '') {
      return displayWarningMessage('Your question cannot be empty')
    }

    if (newQuestionText.trim().length < minQuestionCharacterLength) {
      return displayWarningMessage(
        `Your question must be at least ${minQuestionCharacterLength} characters long`
      )
    }
    if (newQuestionText.trim().length > maxQuestionCharacterLength) {
      return displayWarningMessage(
        `Your question cannot exceed ${maxQuestionCharacterLength} characters`
      )
    }
    if (isDuplicate) {
      return displayWarningMessage('Your question is a duplicate')
    }

    const newQuestion = {
      id: uuidv4(),
      text: newQuestionText,
    }

    setIsOpen(false)
    setIsEditingQuestion(false)
    const newQuestions = [...questions, newQuestion]
    setQuestions(newQuestions)
    setSelectedQuestions(
      [...selectedQuestions, newQuestion].slice(0, questionsLimit),
      newQuestions
    )
    setNewQuestionText('')
  }, [
    newQuestionText,
    minQuestionCharacterLength,
    maxQuestionCharacterLength,
    questions,
    questionsLimit,
    setIsEditingQuestion,
    setQuestions,
    selectedQuestions,
    setSelectedQuestions,
  ])

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        handleConfirmAddClick()
      }
    }

    const inputElement = inputRef.current
    if (inputElement) {
      inputElement.addEventListener('keydown', handleKeyDown)
    }

    return () => {
      if (inputElement) {
        inputElement.removeEventListener('keydown', handleKeyDown)
      }
    }
  }, [inputRef, handleConfirmAddClick])

  useClickAway(ref, () => {
    if (isOpen) {
      handleCancelClick()
    }
  })

  return (
    <div className="px-2" ref={ref}>
      {isOpen ? (
        <div className="flex items-center justify-between rounded border px-2 py-0.5 shadow-sm">
          <div className="flex w-full items-center space-x-4 pl-8">
            <p className="w-20 shrink-0 text-muted">
              Column {questions.length + 1}
            </p>
            <Input
              ref={inputRef}
              className="h-6 w-full border-none p-0 focus-within:ring-transparent focus:ring-transparent focus-visible:ring-transparent"
              value={newQuestionText}
              onChange={(e) => setNewQuestionText(e.target.value)}
              placeholder={`Type a question (${minQuestionCharacterLength} - ${maxQuestionCharacterLength} characters)`}
            />
          </div>
          <div className="flex items-center">
            <Button variant="ghost" size="sm" onClick={handleCancelClick}>
              Cancel
            </Button>
            <Button
              variant="ghost"
              size="sm"
              onClick={handleConfirmAddClick}
              className="font-semibold"
            >
              Add
            </Button>
          </div>
        </div>
      ) : (
        <button
          className="flex w-full items-center space-x-4 rounded border border-transparent px-2 py-0.5 transition hover:bg-secondary"
          onClick={handleAddQuestionClick}
        >
          <Plus size={16} className="text-muted" />
          <span className="flex h-6 items-center">
            <p className="text-sm text-muted">Add a new column</p>
          </span>
        </button>
      )}
    </div>
  )
}
