import React, { useState, useRef, FormEvent } from 'react'

import { displayErrorMessage, displaySuccessMessage } from 'utils/toast'

import {
  UserComparison,
  FetchExperimentSummary,
  FetchExperimentCSV,
} from 'components/compare/compare-fetcher'
import BasicTransition from 'components/ui/basic-transition'
import { Button } from 'components/ui/button'
import { Card, CardContent } from 'components/ui/card'

import ExperimentUserManagementCard from './settings-experiment-user-management-card'

const SettingsExperimentInspectorCard = () => {
  const [loading, setLoading] = useState(false)
  const [inspectingExperimentId, setInspectingExperimentId] =
    useState<string>('')
  const [userComparisons, setUserComparisons] = useState<UserComparison[]>([])
  const totalComparisons = userComparisons.length
  const aCount = userComparisons.filter((c) => c.preference === 'A').length
  const bCount = userComparisons.filter((c) => c.preference === 'B').length
  const tieCount = userComparisons.filter((c) => c.preference === 'Tie').length
  const naCount = userComparisons.filter((c) =>
    ['NA', null, ''].includes(c.preference)
  ).length

  const aPercentage = (aCount / totalComparisons) * 100
  const bPercentage = (bCount / totalComparisons) * 100
  const tiePercentage = (tieCount / totalComparisons) * 100
  const naPercentage = (naCount / totalComparisons) * 100

  const aPercentDisplay =
    aPercentage > 0 ? `${aCount} (${aPercentage.toFixed(2)}%)` : ''
  const bPercentDisplay =
    bPercentage > 0 ? `${bCount} (${bPercentage.toFixed(2)}%)` : ''
  const tiePercentDisplay =
    tiePercentage > 0 ? `${tieCount} (${tiePercentage.toFixed(2)}%)` : ''
  const naPercentDisplay =
    naPercentage > 0 ? `${naCount} (${naPercentage.toFixed(2)}%)` : ''

  const handleSubmitExperimentId = async (
    experimentId: string
  ): Promise<void> => {
    setLoading(true)
    const fetchedUserComparisons = await FetchExperimentSummary(experimentId)
    if (fetchedUserComparisons.length == 0) {
      displayErrorMessage('Please enter a valid experiment ID')
      return
    }
    setUserComparisons(fetchedUserComparisons)
    setInspectingExperimentId(experimentId)
    setLoading(false)
  }

  const experimentIdRef = useRef<HTMLInputElement>(null)

  const handleFormSubmit = (event: FormEvent) => {
    event.preventDefault()
    if (experimentIdRef.current) {
      void handleSubmitExperimentId(experimentIdRef.current.value)
    }
  }

  const handleDownloadExperiment = async (experimentId: string) => {
    if (!experimentId) {
      displayErrorMessage('Please enter a valid experiment ID')
      return
    }
    await FetchExperimentCSV(experimentId)
    displaySuccessMessage('Experiment CSV downloaded successfully')
  }

  return (
    <Card>
      <div className="ml-4 mt-4">
        <h2 className="mb-4 text-lg  font-semibold">Experiment inspector</h2>
      </div>
      <CardContent>
        <form className="ml-1 w-full">
          <label>
            Experiment ID <span className="text-destructive">*</span>
            <input
              ref={experimentIdRef}
              className="mb-4 w-full rounded border p-2"
              required
            />
          </label>
          <Button variant="default" type="submit" onClick={handleFormSubmit}>
            Inspect
          </Button>
        </form>
      </CardContent>
      <BasicTransition show={!loading && userComparisons.length > 0}>
        <div className="ml-6">
          <h3 className="mb-4 text-lg font-semibold">Overview</h3>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              borderRadius: '10px',
              backgroundColor: 'rgba(0, 0, 0, 0.1)',
              margin: '4px',
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                margin: '4px',
              }}
            >
              <div
                style={{
                  width: `${aPercentage}%`,
                  backgroundColor: 'rgba(0, 0, 255, 0.8)',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '10px',
                }}
              >
                {aCount > 0 && <span style={{ color: 'white' }}>A</span>}
              </div>
              <span style={{ marginLeft: '10px' }}>{aPercentDisplay}</span>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                margin: '4px',
              }}
            >
              <div
                style={{
                  width: `${bPercentage}%`,
                  backgroundColor: 'rgba(128, 128, 128, 0.8)',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '10px',
                }}
              >
                {bCount > 0 && <span style={{ color: 'white' }}>B</span>}
              </div>
              <span style={{ marginLeft: '10px' }}>{bPercentDisplay}</span>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                margin: '4px',
              }}
            >
              <div
                style={{
                  width: `${naPercentage}%`,
                  backgroundColor: 'rgba(255, 0, 0, 0.8)',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '10px',
                }}
              >
                {naCount > 0 && <span style={{ color: 'white' }}>NA</span>}
              </div>
              <span style={{ marginLeft: '10px' }}>{naPercentDisplay}</span>
            </div>

            <div className="m-1 flex items-center">
              <div
                style={{ width: `${tiePercentage}%` }} // Tailwind CSS doesn't support percentage widths, so must use inline style
                className="flex items-center justify-center rounded-lg bg-interactive-disabled"
              >
                {tieCount > 0 && <span className="text-white">TIE</span>}
              </div>
              <span className="ml-2">{tiePercentDisplay}</span>
            </div>
            <div className="m-2">
              Answered: {aCount + bCount}/{aCount + bCount + naCount + tieCount}
            </div>
          </div>
        </div>
        <Button
          className="m-8 mb-6"
          onClick={() =>
            handleDownloadExperiment(userComparisons[0]?.experimentId)
          }
        >
          Download results
        </Button>
        {inspectingExperimentId && (
          <ExperimentUserManagementCard experimentId={inspectingExperimentId} />
        )}
      </BasicTransition>
    </Card>
  )
}

export default SettingsExperimentInspectorCard
