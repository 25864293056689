/* tslint:disable */
/* eslint-disable */
/**
 * Transcripts API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { TranscriptType } from './TranscriptType';
import {
    TranscriptTypeFromJSON,
    TranscriptTypeFromJSONTyped,
    TranscriptTypeToJSON,
} from './TranscriptType';
import type { TranscriptsWitness } from './TranscriptsWitness';
import {
    TranscriptsWitnessFromJSON,
    TranscriptsWitnessFromJSONTyped,
    TranscriptsWitnessToJSON,
} from './TranscriptsWitness';

/**
 * 
 * @export
 * @interface TranscriptsDocumentMetadata
 */
export interface TranscriptsDocumentMetadata {
    /**
     * 
     * @type {string}
     * @memberof TranscriptsDocumentMetadata
     */
    documentName: string;
    /**
     * 
     * @type {TranscriptType}
     * @memberof TranscriptsDocumentMetadata
     */
    transcriptType: TranscriptType;
    /**
     * 
     * @type {Array<TranscriptsWitness>}
     * @memberof TranscriptsDocumentMetadata
     */
    witnesses: Array<TranscriptsWitness>;
    /**
     * 
     * @type {string}
     * @memberof TranscriptsDocumentMetadata
     */
    headerStr: string;
}



/**
 * Check if a given object implements the TranscriptsDocumentMetadata interface.
 */
export function instanceOfTranscriptsDocumentMetadata(value: object): value is TranscriptsDocumentMetadata {
    if (!('documentName' in value) || value['documentName'] === undefined) return false;
    if (!('transcriptType' in value) || value['transcriptType'] === undefined) return false;
    if (!('witnesses' in value) || value['witnesses'] === undefined) return false;
    if (!('headerStr' in value) || value['headerStr'] === undefined) return false;
    return true;
}

export function TranscriptsDocumentMetadataFromJSON(json: any): TranscriptsDocumentMetadata {
    return TranscriptsDocumentMetadataFromJSONTyped(json, false);
}

export function TranscriptsDocumentMetadataFromJSONTyped(json: any, ignoreDiscriminator: boolean): TranscriptsDocumentMetadata {
    if (json == null) {
        return json;
    }
    return {
        
        'documentName': json['document_name'],
        'transcriptType': TranscriptTypeFromJSON(json['transcript_type']),
        'witnesses': ((json['witnesses'] as Array<any>).map(TranscriptsWitnessFromJSON)),
        'headerStr': json['header_str'],
    };
}

export function TranscriptsDocumentMetadataToJSON(value?: TranscriptsDocumentMetadata | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'document_name': value['documentName'],
        'transcript_type': TranscriptTypeToJSON(value['transcriptType']),
        'witnesses': ((value['witnesses'] as Array<any>).map(TranscriptsWitnessToJSON)),
        'header_str': value['headerStr'],
    };
}

