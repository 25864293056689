import React, { useEffect, useState } from 'react'

import { useShallow } from 'zustand/react/shallow'

import { IntegrationType } from 'openapi/models/IntegrationType'
import { useIntegrationsStore } from 'stores/integrations-store'

import { displayErrorMessage, displaySuccessMessage } from 'utils/toast'

import { Button } from 'components/ui/button'
import { Card } from 'components/ui/card'

import { useOauthConnect } from './use-oauth-connect'
import { disconnectIntegration } from './utils'

interface IntegrationConnectionCardProps {
  integrationType: IntegrationType
  Logo: React.ComponentType<{ className?: string }>
  title: string
  description: string
  connected: boolean
}

const IntegrationConnectionCard: React.FC<IntegrationConnectionCardProps> = ({
  integrationType,
  Logo,
  title,
  description,
  connected,
}) => {
  const [isConnected, setIsConnected] = useState(connected)

  useEffect(() => {
    setIsConnected(connected)
  }, [connected])

  const {
    isConnecting,
    isConnected: isOauthConnected,
    handleConnect,
  } = useOauthConnect({ integrationType })

  const [clearIntegrationToken] = useIntegrationsStore(
    useShallow((state) => [state.clearIntegrationToken])
  )

  const handleDisconnect = async () => {
    try {
      await disconnectIntegration(integrationType)
      clearIntegrationToken(integrationType)
    } catch (e) {
      console.error(e)
      displayErrorMessage(`Failed to disconnect from ${title}`)
      return
    }
    displaySuccessMessage(`Disconnected from ${title}`)
    setIsConnected(false)
  }

  return (
    <Card className="mt-3 flex items-center justify-between p-3">
      <div className="ml-4 flex items-center space-x-4">
        <Logo className="size-8" />
        <div>
          <p className="font-semibold">{title}</p>
          <p>{description}</p>
        </div>
      </div>
      <div>
        {isConnecting ? (
          <Button variant="outline" className="mr-3" disabled>
            Loading...
          </Button>
        ) : isConnected || isOauthConnected ? (
          <Button
            variant="destructive"
            className="mr-3"
            onClick={handleDisconnect}
          >
            Disconnect
          </Button>
        ) : (
          <Button variant="outline" className="mr-3" onClick={handleConnect}>
            Connect
          </Button>
        )}
      </div>
    </Card>
  )
}

export default IntegrationConnectionCard
