import _ from 'lodash'

import Services from 'services'
import { Maybe } from 'types'

import { ClientMatterAdd } from 'components/client-matters/client-matter-utils'
import { ClientMatter } from 'components/client-matters/client-matters-store'

import { UserInfo } from './user-info'
import { FetchUserWorkspaces, Workspace } from './workspace'

export interface WorkspaceClientMattersStore {
  clientMatters: ClientMatter[]
}

export interface ClientMatterWithStats extends ClientMatter {
  deletedAt: Maybe<string>
  totalEvents: number
  userCount?: number
}

interface ClientMatterResponse {
  successClientMatters: ClientMatterWithStats[]
  erroredClientMatters: string[]
}

export const fetchWorkspaceClientMatters =
  async (): Promise<WorkspaceClientMattersStore> => {
    const clientMatters = await Services.Backend.Get<ClientMatter[]>(
      'settings/client_matters'
    )
    return { clientMatters }
  }

export const addClientMatters = async (
  clientMatters: ClientMatterAdd[],
  workspaceId: number
): Promise<ClientMatterResponse> => {
  if (_.isEmpty(clientMatters)) {
    return { successClientMatters: [], erroredClientMatters: [] }
  }

  const resp = await Services.Backend.Post<ClientMatterResponse>(
    'settings/add_client_matters',
    { clientMatters, workspaceId }
  )
  return resp
}

export const editClientMatters = async (
  editCmId: string,
  cmName: string,
  cmDesc: Maybe<string>,
  cmAllowed: boolean,
  workspaceId: number
  // eslint-disable-next-line max-params
): Promise<Maybe<ClientMatterWithStats>> => {
  if (_.isEmpty(cmName)) {
    return null
  }
  const resp = await Services.Backend.Post<ClientMatterWithStats>(
    'settings/edit_client_matter',
    { editCmId, cmName, cmDesc, cmAllowed, workspaceId }
  )
  return resp
}

export const deleteClientMatters = async (
  clientMatters: string[],
  workspaceId: number
): Promise<ClientMatterResponse> => {
  if (_.isEmpty(clientMatters)) {
    return { successClientMatters: [], erroredClientMatters: [] }
  }
  const resp = await Services.Backend.Post<ClientMatterResponse>(
    'settings/delete_client_matters',
    { clientMatters, workspaceId }
  )
  return resp
}

export const getClientMattersWithStats = async (
  workspaceId: number
): Promise<ClientMatterWithStats[]> => {
  const clientMatters = await Services.Backend.Get<ClientMatterWithStats[]>(
    `settings/client_matters_with_stats/${workspaceId}`
  )
  return clientMatters
}

export type ClientMattersSettingsStore = {
  clientMattersWithStats: ClientMatterWithStats[]
  workspaces: Workspace[]
}

export const settingsClientMattersLoader = async (
  userInfo: Maybe<UserInfo>
): Promise<ClientMattersSettingsStore> => {
  let workspaces: Workspace[] = []
  if (_.isNil(userInfo)) {
    return { clientMattersWithStats: [], workspaces: [] }
  }

  if (userInfo.IsTerritoryAdmin) {
    workspaces = await FetchUserWorkspaces()
  } else {
    workspaces = [userInfo.workspace]
  }

  const clientMattersWithStats = await getClientMattersWithStats(
    userInfo.workspace.id
  )

  return { clientMattersWithStats, workspaces }
}

export const setClientMatterWorkspaceSettings = async (
  workspaceId: number,
  shouldCmLockQueries: boolean,
  canUsersManageCm: boolean
) => {
  return Services.Backend.Post<{ updated: boolean }>(
    `settings/admin/client_matters/manage`,
    {
      workspaceId,
      shouldCmLockQueries,
      canUsersManageCm,
    }
  )
}

export const getDisallowedClientMatters = async (workspaceId: number) => {
  return Services.Backend.Get<ClientMatter[]>(
    `settings/disallowed_client_matters/${workspaceId}`,
    {
      throwOnError: true,
    }
  )
}
