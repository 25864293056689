/* tslint:disable */
/* eslint-disable */
/**
 * Transcripts API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration of transcripts tasks
 * @export
 */
export const TranscriptsTask = {
    ANALYZE: 'analyze',
    QUESTION: 'question'
} as const;
export type TranscriptsTask = typeof TranscriptsTask[keyof typeof TranscriptsTask];


export function instanceOfTranscriptsTask(value: any): boolean {
    for (const key in TranscriptsTask) {
        if (Object.prototype.hasOwnProperty.call(TranscriptsTask, key)) {
            if (TranscriptsTask[key as keyof typeof TranscriptsTask] === value) {
                return true;
            }
        }
    }
    return false;
}

export function TranscriptsTaskFromJSON(json: any): TranscriptsTask {
    return TranscriptsTaskFromJSONTyped(json, false);
}

export function TranscriptsTaskFromJSONTyped(json: any, ignoreDiscriminator: boolean): TranscriptsTask {
    return json as TranscriptsTask;
}

export function TranscriptsTaskToJSON(value?: TranscriptsTask | null): any {
    return value as any;
}

