import React, { useEffect, useState } from 'react'

import { Alert, Input, Modal, Table, Typography } from 'antd'
import { Checkbox } from 'antd/lib'
import _ from 'lodash'

import {
  BulkDeleteUsersResult,
  InternalAdminBulkDeleteUser,
  RawUser,
} from 'models/users'
import { Workspace } from 'models/workspace'
import { AuditLogType } from 'openapi/models/AuditLogType'

import { useNavigateWithQueryParams } from 'hooks/use-navigate-with-query-params'
import { usePostAuditLog } from 'utils/audit-log'
import { displayErrorMessage, displaySuccessMessage } from 'utils/toast'

import { useAuthUser } from 'components/common/auth-context'
import { Badge } from 'components/ui/badge'
import { Button } from 'components/ui/button'
import CsvDownload from 'components/ui/csv-download'

interface UserInfoTableProps {
  users: RawUser[]
  workspace: Workspace
  fetchData: () => void
}

const WorkspaceUsersInternal: React.FC<UserInfoTableProps> = ({
  users,
  fetchData,
  workspace,
}) => {
  const navigate = useNavigateWithQueryParams()
  const userInfo = useAuthUser()
  const [filteredUsers, setFilteredUsers] = useState<RawUser[]>(users)
  const [userFilter, setUserFilter] = useState<string>('')

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false)
  const [deleteAcknowledge, setDeleteAcknowledge] = useState<boolean>(false)
  const [deleteErrorUsers, setDeleteErrorUsers] = useState<string[]>([])

  const { postAuditLog } = usePostAuditLog()

  useEffect(() => {
    setFilteredUsers(users)
  }, [users])

  if (_.isNil(userInfo) || !userInfo.IsInternalAdminReader) return <></>

  const userManagementPerm = userInfo.isUserManagement

  const handleSearch = (value: string) => {
    value = value.trim()
    setUserFilter(value)
    if (value.length === 0) {
      setFilteredUsers(users)
    } else {
      setFilteredUsers(
        users.filter((user: RawUser) =>
          user.email.includes(value.toLowerCase())
        )
      )
    }
  }

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys)
  }

  const rowSelection = userManagementPerm
    ? {
        selectedRowKeys,
        onChange: onSelectChange,
      }
    : undefined

  const bulkDeleteUsers = async () => {
    if (_.isNil(userInfo) || !userManagementPerm) {
      displayErrorMessage('You do not have permission to bulk delete users')
      return
    }
    const emails = selectedRowKeys as string[]
    if (_.isEmpty(emails)) {
      displayErrorMessage('No users selected')
      return
    }
    const result: BulkDeleteUsersResult =
      await InternalAdminBulkDeleteUser(emails)
    if (_.isNil(result)) {
      displayErrorMessage('Failed to delete users')
      return
    } else {
      const { usersDeleted, usersFailed } = result
      if (!_.isEmpty(usersDeleted)) {
        displaySuccessMessage(`${usersDeleted.length} users deleted`)
      }
      setDeleteErrorUsers(usersFailed)
    }
    setDeleteAcknowledge(false)
    setSelectedRowKeys([])
    setUserFilter('')
    fetchData()
    setDeleteModalOpen(false)
  }

  return (
    <>
      {deleteErrorUsers.length > 0 && (
        <div className="mb-4">
          <Alert
            message={`Failed to delete ${deleteErrorUsers.length} users`}
            description={
              <Typography.Paragraph
                ellipsis={{ rows: 2, expandable: true, symbol: 'more' }}
              >
                {deleteErrorUsers.join(', ')}
              </Typography.Paragraph>
            }
            type="error"
            showIcon
            closable
            onClose={() => setDeleteErrorUsers([])}
          />
        </div>
      )}
      <div className="flex justify-between">
        <div className="flex justify-between space-x-2">
          {!_.isEmpty(selectedRowKeys) && userManagementPerm && (
            <>
              <Button
                type="submit"
                variant="destructive"
                onClick={() => setDeleteModalOpen(true)}
              >
                {`Bulk delete ${selectedRowKeys.length} users`}
              </Button>
              <Button className="ml-2" onClick={() => setSelectedRowKeys([])}>
                Clear all
              </Button>
            </>
          )}
        </div>
        <div className="flex justify-between space-x-2">
          {filteredUsers.length > 0 && (
            <CsvDownload
              buttonText="Export users"
              filename={`${workspace.clientName}_users_internal_admin.csv`}
              data={filteredUsers.map((user) => ({
                id: user.id,
                email: user.email,
                pseudonymizedEmail: user.pseudonymizedEmail,
                // https://stackoverflow.com/questions/69268535/export-string-to-csv-keeping-inside-commas
                // eslint-disable-next-line harvey/no-straight-quotes
                settings: JSON.stringify(user.settings).replace(/"/g, '""'),
                createdAt: user.createdAt,
                updatedAt: user.updatedAt,
                deletedAt: user.deletedAt,
              }))}
              headers={[
                { label: 'Id', key: 'id' },
                { label: 'Email', key: 'email' },
                { label: 'Pseudonymized Email', key: 'pseudonymizedEmail' },
                { label: 'Settings', key: 'settings' },
                { label: 'Created At', key: 'createdAt' },
                { label: 'Updated At', key: 'updatedAt' },
                { label: 'Deleted At', key: 'deletedAt' },
              ]}
              onClickCallback={() => {
                postAuditLog(
                  AuditLogType.INTERNAL_ADMINCLIENT_EXPORT_WORKSPACE_USERS,
                  workspace.id,
                  {
                    numUsers: filteredUsers.length,
                  }
                )
              }}
            />
          )}
          <Input.Search
            placeholder="User email"
            value={userFilter}
            onSearch={handleSearch}
            style={{ width: '250px', float: 'right', marginBottom: '16px' }}
            size="middle"
            onChange={(e) => handleSearch(e.target.value)}
            allowClear
          />
        </div>
      </div>
      <Table
        dataSource={_.orderBy(
          filteredUsers,
          [(user) => Boolean(user.deletedAt), 'email'],
          ['asc', 'asc']
        )}
        rowKey={(user: RawUser) => user.email}
        bordered
        onRow={(user: RawUser) => ({
          onClick: () => {
            if (user.deletedAt) {
              return
            }
            navigate(`/settings/internal_admin/user-inspector`, {
              state: { email: user.email },
            })
          },
        })}
        className="min-h-[500px] cursor-pointer"
        columns={[
          {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
          },
          {
            title: 'Settings',
            dataIndex: 'settings',
            key: 'settings',
            render: (settings: any) =>
              !_.isEmpty(settings) && (
                <Typography>{JSON.stringify(settings, null, 2)}</Typography>
              ),
          },
          {
            title: 'Created at',
            dataIndex: 'createdAt',
            key: 'createdAt',
            width: '20%',
          },
          {
            title: 'Deleted at',
            dataIndex: 'deletedAt',
            key: 'deletedAt',
            width: '20%',
          },
        ]}
        rowSelection={rowSelection}
        footer={() => (
          <div className="flex justify-end">
            <p>{filteredUsers.length} users</p>
          </div>
        )}
        pagination={{
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '50', '100'],
        }}
      />
      {userManagementPerm && (
        <Modal
          title={
            <div>
              <div className="text-destructive">Delete users</div>
            </div>
          }
          open={deleteModalOpen}
          onOk={bulkDeleteUsers}
          onCancel={() => {
            setDeleteAcknowledge(false)
            setDeleteModalOpen(false)
          }}
          okText={`Delete ${selectedRowKeys.length} Users`}
          cancelText="Cancel"
          width={600}
          okButtonProps={{
            disabled: !deleteAcknowledge,
            danger: true,
          }}
        >
          <div>
            <Typography>
              This is non-reversible. It will delete the users from Auth0 and
              remove their access from Harvey.
              <div className="mt-2">
                <Typography.Text>
                  <b>Users to be deleted:</b>
                  <Typography.Paragraph
                    ellipsis={{ rows: 2, expandable: true, symbol: 'more' }}
                  >
                    <div className="mt-1">
                      {(selectedRowKeys as string[]).map((email) => (
                        <span key={email}>
                          <Badge variant="outline">{email}</Badge>
                        </span>
                      ))}
                    </div>
                  </Typography.Paragraph>
                </Typography.Text>
              </div>
              <div className="mt-4 pb-2">
                <Alert
                  message={
                    <div className="flex justify-between">
                      <div>{`Please acknowledge you want to delete ${selectedRowKeys.length} users`}</div>
                      <Checkbox
                        checked={deleteAcknowledge}
                        onChange={() =>
                          setDeleteAcknowledge(!deleteAcknowledge)
                        }
                      />
                    </div>
                  }
                  type="warning"
                  showIcon
                />
              </div>
            </Typography>
          </div>
        </Modal>
      )}
    </>
  )
}

export default WorkspaceUsersInternal
