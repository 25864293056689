import React from 'react'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useMount } from 'react-use'

import { useShallow } from 'zustand/react/shallow'

import { EventKind } from 'openapi/models/EventKind'

import { cn } from 'utils/utils'

import { useAssistantAnalytics } from 'components/assistant/hooks/use-assistant-analytics'
import { useAssistantStore } from 'components/assistant/stores/assistant-store'
import { AssistantChatMessage } from 'components/assistant/types'
import {
  isQueryValid,
  getQueryLimit,
} from 'components/assistant/utils/assistant-helpers'
import AskHarveyButton from 'components/common/ask-harvey-button'
import { Button } from 'components/ui/button'
import { Textarea } from 'components/ui/text-area'

import { AssistantLoadPromptButton } from './assistant-load-prompt-button'
import { AssistantMode } from './assistant-mode-select'

export type EditMessageQueryInputProps = {
  editingMessage: AssistantChatMessage | null
  setEditingMessage: (message: AssistantChatMessage | null) => void
  handleEditMessageQuery: (query: string | undefined) => void
  // The position of this message in the messages thread shown to the user
  threadNumber: number
  // The position of this message in the linked list of backend-stored messages
  messageNumber: number
}

export const AssistantEditMessageQueryInput = ({
  handleEditMessageQuery,
  editingMessage,
  setEditingMessage,
  messageNumber,
  threadNumber,
}: EditMessageQueryInputProps) => {
  const { mode } = useParams()
  const [query, setQuery] = useState<string>(editingMessage?.query || '')
  const [documentsUploading, documents] = useAssistantStore(
    useShallow((s) => [s.documentsUploading, s.documents])
  )
  const trackEvent = useAssistantAnalytics()
  const hasDocuments = !!(documents.length || documentsUploading.length)
  const queryLimit = getQueryLimit(
    hasDocuments,
    !!editingMessage?.prevMessageId
  )
  const [queryPreview, setQueryPreview] = useState<string | null>(null)
  const isAskHarveyDisabled = !isQueryValid(query, queryLimit)
  const handleEditMessageQueryWithAnalytics = (query: string) => {
    trackEvent('Edit Message Confirmed', {
      query_length: query.length,
      thread_number: threadNumber,
      message_number: messageNumber,
    })
    handleEditMessageQuery(query)
  }
  const handleEditMessageQueryCancel = () => {
    trackEvent('Edit Message Cancelled', {
      query_length: query.length,
      message_number: messageNumber,
    })
    setEditingMessage(null)
  }

  const inputTextareaRef = React.useRef<HTMLTextAreaElement | null>(null)

  const handleAskClick = () => {
    if (!isAskHarveyDisabled) {
      handleEditMessageQueryWithAnalytics(query)
    }
  }

  useMount(() => {
    inputTextareaRef.current?.focus()
  })

  const handlePromptSelect = (prompt: string) => {
    setQuery(prompt)
    setTimeout(() => inputTextareaRef.current?.focus(), 0)
  }

  return (
    <div className="relative">
      <Textarea
        className={cn(
          'min-h-0 resize-none rounded-[3px] border-0 p-6 pb-20 ring-inset',
          {
            'disabled:cursor-default': queryPreview,
          }
        )}
        isFluid
        onChange={(e) => setQuery(e.target.value)}
        value={queryPreview ? '' : query}
        placeholder={queryPreview || ''}
        ref={inputTextareaRef}
      />

      <div
        className={cn(
          'pointer-events-none absolute bottom-0 left-0 flex w-full justify-between px-6 pb-4 pt-2'
        )}
      >
        {mode === AssistantMode.ASSIST && (
          <AssistantLoadPromptButton
            setQuery={handlePromptSelect}
            setQueryPreview={setQueryPreview}
            eventKind={
              mode === AssistantMode.ASSIST
                ? EventKind.ASSISTANT_CHAT
                : EventKind.ASSISTANT_DRAFT
            }
          />
        )}
        <div className="flex space-x-2">
          <Button
            className={cn(
              'pointer-events-auto shrink-0 whitespace-nowrap font-semibold'
            )}
            onClick={handleEditMessageQueryCancel}
            disabled={isAskHarveyDisabled}
            variant="outline"
            size="sm"
          >
            Cancel
          </Button>
          <div className="flex">
            <AskHarveyButton
              handleSubmit={handleAskClick}
              disabled={isAskHarveyDisabled}
              inputRef={inputTextareaRef}
              // no need to enforce client matter dialog here as normal follow up messages should not change
              // original event's client matter number
              shouldEnforceClientMatterSelection={false}
              className="pointer-events-auto shrink-0 whitespace-nowrap font-semibold"
              size="sm"
            />
          </div>
        </div>
      </div>
    </div>
  )
}
