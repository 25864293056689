import * as Sentry from '@sentry/browser'
import { Query, QueryKey } from '@tanstack/react-query'
import { HTTPError } from 'ky'

import { FetchHistoryItem } from 'models/fetchers/history-fetcher'
import { HarvQueryKeyPrefix } from 'models/queries/all-query-keys'
import { useWrappedQuery } from 'models/queries/lib/use-wrapped-query'
import { ReviewEventRunType } from 'openapi/models/ReviewEventRunType'
import Services from 'services'
import { Maybe } from 'types'
import { HistoryItem } from 'types/history'

import { parseIsoString } from 'utils/utils'

import {
  TrackEventFunction,
  useAnalytics,
} from 'components/common/analytics/analytics-context'
import { ReviewEvent } from 'components/vault/utils/vault'
import { mapReviewEventToEventV1Metadata } from 'components/vault/utils/vault-helpers'

const runV1AndV2History = async ({
  id,
  isV1,
  isDualWriteUser,
  throwOnError = false,
  maxRetryCount = 3,
  skipSources = false,
  trackEvent,
}: {
  id: Maybe<string>
  isV1: boolean
  isDualWriteUser: boolean
  throwOnError?: boolean
  maxRetryCount?: number
  skipSources?: boolean
  trackEvent?: TrackEventFunction
}) => {
  const runV1 = async () => {
    const startTime = performance.now()
    const result = await FetchHistoryItem({
      id,
      throwOnError,
      maxRetryCount,
      useVaultEndpoint: true,
      skipSources,
    })
    const endTime = performance.now()
    trackEvent?.('Vault History Item V1 Queried', {
      duration: endTime - startTime,
    })
    return result
  }
  const runV2 = async () => {
    const startTime = performance.now()
    const result = await FetchVaultHistoryItemV2({
      id,
      throwOnError,
      maxRetryCount,
      skipSources,
    })
    const endTime = performance.now()
    trackEvent?.('Vault History Item V2 Queried', {
      duration: endTime - startTime,
    })
    return result
  }

  if (isV1) {
    const v1Promise = runV1()
    if (isDualWriteUser) {
      // Only run V2 if the user is a dual write user while user is not V2 user.
      void runV2()
    }
    return await v1Promise
  } else {
    const v2Promise = runV2()
    void runV1()
    return await v2Promise
  }
}

const FetchVaultHistoryItemV2 = async ({
  id,
  throwOnError = false,
  maxRetryCount = 3,
  skipSources = false,
}: {
  id: Maybe<string>
  throwOnError?: boolean
  maxRetryCount?: number
  skipSources?: boolean
}): Promise<ReviewEvent | null> => {
  if (!id) {
    return null
  }

  const searchParams = new URLSearchParams()
  if (skipSources) {
    searchParams.append('skip_sources', 'true')
  }

  const searchParamsString = searchParams.toString()

  return Services.Backend.Get<ReviewEvent>(
    `vault/v2/history/${id}${
      searchParamsString ? `?${searchParamsString}` : ''
    }`,
    {
      throwOnError: true,
      maxRetryCount: maxRetryCount,
    }
  )
    .then((reviewEvent) => {
      const metadata = mapReviewEventToEventV1Metadata(reviewEvent)
      return {
        ...reviewEvent,
        id: reviewEvent.eventId.toString(), // This is a number in the backend
        userId: reviewEvent.eventCreatorEmail, // userId of the event v1 is the creator's email
        status: reviewEvent.eventStatus,
        query:
          reviewEvent.runs.find((run) => run.runType === ReviewEventRunType.NEW)
            ?.query ?? '',
        response: '',
        kind: reviewEvent.eventKind,
        created: parseIsoString(reviewEvent.eventCreatedAt),
        updatedAt: parseIsoString(reviewEvent.eventUpdatedAt),
        metadata: metadata,
        ...metadata,
      } as ReviewEvent
    })
    .catch((e) => {
      if (throwOnError) {
        throw e
      } else {
        console.warn('Failed to fetch history item', { e, id })
        return null
      }
    })
}

const vaultHistoryItemQuery = ({
  id,
  vaultFolderId,
  isV2 = false,
  isDualWriteUser = false,
  throwOnError = false,
  maxRetryCount = 3,
  skipSources = false,
  trackEvent,
}: {
  id: Maybe<string>
  vaultFolderId: string
  isV2?: boolean
  isDualWriteUser?: boolean
  throwOnError: boolean
  maxRetryCount?: number
  skipSources?: boolean
  trackEvent?: TrackEventFunction
}) => ({
  queryKey: [HarvQueryKeyPrefix.VaultHistoryItemQuery, id, skipSources],
  queryFn: async () => {
    let historyItem: HistoryItem | ReviewEvent | null
    const fetchHistoryItemUsingOldEndpoint = async () => {
      return await runV1AndV2History({
        id,
        isV1: true,
        isDualWriteUser,
        throwOnError,
        maxRetryCount,
        skipSources,
        trackEvent,
      })
    }
    if (isV2) {
      try {
        historyItem = await runV1AndV2History({
          id,
          isV1: false,
          isDualWriteUser,
          throwOnError,
          maxRetryCount,
          skipSources,
          trackEvent,
        })
      } catch (e) {
        if (e instanceof HTTPError && e.response.status === 400) {
          // Bad request means that we should use the old endpoint
          historyItem = await fetchHistoryItemUsingOldEndpoint()
        } else {
          throw e
        }
      }
    } else {
      historyItem = await fetchHistoryItemUsingOldEndpoint()
    }
    if (historyItem && historyItem.vaultFolderId !== vaultFolderId) {
      throw new Error(
        `History item ${historyItem.id} does not belong to the vault project ${vaultFolderId}`
      )
    }
    return historyItem
  },
  initialData: null,
})

export const useVaultHistoryItemQuery = ({
  id,
  vaultFolderId,
  isV2 = false,
  isDualWriteUser = false,
  isEnabled = true,
  throwOnError = false,
  refetchInterval,
  skipSources = false,
}: {
  id: Maybe<string>
  vaultFolderId: string
  isV2?: boolean
  isDualWriteUser?: boolean
  isEnabled?: boolean
  throwOnError?: boolean
  refetchInterval?: (
    query: Query<
      ReviewEvent | HistoryItem | null,
      Error,
      ReviewEvent | HistoryItem | null,
      QueryKey
    >
  ) => number | false | undefined
  skipSources?: boolean
}) => {
  const { trackEvent } = useAnalytics()
  const {
    isPending,
    error,
    data: historyItem,
    isLoading,
    isFetching,
    isFetched,
  } = useWrappedQuery({
    enabled: !!id && isEnabled,
    refetchInterval,
    refetchOnWindowFocus: false,
    // Set maxRetryCount to 0 because we will handle retries in the useQuery hook
    ...vaultHistoryItemQuery({
      id,
      isV2,
      isDualWriteUser,
      vaultFolderId,
      throwOnError,
      maxRetryCount: 0,
      skipSources,
      trackEvent,
    }),
  })

  if (error) {
    Sentry.captureException(error)
    Services.HoneyComb.RecordError(error)
  }

  return { historyItem, isPending, isLoading, isFetching, isFetched, error }
}
