import _ from 'lodash'
import PSPDFKit, { Annotation, Instance } from 'pspdfkit'

import { UploadedFile } from 'openapi/models/UploadedFile'

import { Source } from './task'

export const HIGHLIGHT_SELECTED_OPACITY = 1
export const HIGHLIGHT_DEFAULT_OPACITY = 0.75

const sourceToPspdfkitAnnotation = (
  docSources: Source[] | undefined
): Annotation[] => {
  if (_.isNil(docSources)) {
    return []
  }

  return docSources
    .flatMap((source) => source.annotations as any[])
    .filter((annotation) => annotation)
    .map(
      (raw) =>
        PSPDFKit.Annotations.fromSerializableObject({
          ...raw,
          color: '#BFDBFE',
        }) as Annotation
    )
}

export const findSourcePage = async (
  pspdfInstance: Instance,
  source: Source
): Promise<number | null> => {
  const sourceText = source.text.replace(/…$/, '').substring(0, 30) // remove trailing ellipsis and truncate before searching

  try {
    const pageIndex =
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      source.page ??
      // @ts-expect-error todo
      (await pspdfInstance.search(sourceText)).first()?.pageIndex

    // XXX: Guarding against over-indexing, not clear how this happens
    if (pageIndex >= pspdfInstance.totalPageCount) {
      console.warn(
        `Page index out of bounds: ${pageIndex} of ${pspdfInstance.totalPageCount}` +
          source
      )
      return null
    }

    return pageIndex
  } catch (e) {
    return null
  }
}

export const applyAnnotations = async (
  pspdfInstance: Instance | null,
  document: UploadedFile,
  sources: Source[]
): Promise<void> => {
  if (_.isNil(document) || pspdfInstance == null) {
    return
  }
  const annotations = sourceToPspdfkitAnnotation(sources)

  // create annotation in pspdfkit document
  await Promise.all(
    // eslint-disable-next-line
    annotations.map((annotation) => {
      return pspdfInstance.create(annotation)
    })
  )
}

export const scrollToSourceHighlight = async (
  pspdfInstance: Instance | null,
  source: Source
): Promise<void> => {
  const group = source.id
  if (_.isNil(group) || _.isNil(pspdfInstance)) {
    return
  }

  // get current document annotations
  const pagesAnnotations = await Promise.all(
    Array.from({
      length: pspdfInstance.totalPageCount,
    }).map(
      // eslint-disable-next-line
      (_, pageIndex) => pspdfInstance?.getAnnotations(pageIndex)
    )
  )

  // Reset opacity of all annotations
  await Promise.all(
    pagesAnnotations.map((annotations) => {
      if (_.isNil(annotations)) {
        return null
      }
      return Promise.all(
        annotations.map((annotation) => {
          if (annotation.get('opacity') !== HIGHLIGHT_DEFAULT_OPACITY) {
            return pspdfInstance
              .update(annotation.set('opacity', HIGHLIGHT_DEFAULT_OPACITY))
              .catch((e) => console.warn('Failed to reset opacity', e))
          }
          return null
        })
      )
    })
  )

  if (source.annotations.length === 0) {
    // if no annotations, jump to page
    try {
      const sourcePage = await findSourcePage(pspdfInstance, source)

      if (sourcePage === null) return
      const newViewState = pspdfInstance.viewState.set(
        'currentPageIndex',
        sourcePage
      )
      pspdfInstance.setViewState(newViewState)
    } catch (e) {
      console.error('Failed to scroll', e)
    }
    return
  }

  // highlight selected annotation
  pagesAnnotations.forEach((annotations) => {
    if (_.isNil(annotations)) return null

    annotations.forEach(async (annotation) => {
      if (
        annotation.get('name') === group &&
        annotation.get('opacity') !== HIGHLIGHT_SELECTED_OPACITY &&
        !_.isNil(pspdfInstance)
      ) {
        try {
          await pspdfInstance.update(
            annotation.set('opacity', HIGHLIGHT_SELECTED_OPACITY)
          )
        } catch (e) {
          console.warn('Failed to update highlighted opacity', e)
        }
      }
    })

    const matchingAnnotations = annotations.filter(
      (annotation) => annotation.get('name') === group
    )
    if (matchingAnnotations.size > 0) {
      const lastMatchingAnnotation = matchingAnnotations.last() as Annotation
      const pageIndex = lastMatchingAnnotation.get('pageIndex') ?? -1
      pspdfInstance.jumpToRect(pageIndex, lastMatchingAnnotation.boundingBox)
    }
  })
}
