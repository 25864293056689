import React from 'react'
import { CSVLink } from 'react-csv'
import { Headers } from 'react-csv/lib/core'

import { DownloadIcon } from 'lucide-react'

import { cn } from 'utils/utils'

import Icon from 'components/ui/icon/icon'

// import { Maybe } from 'types'

interface CsvDownloadProps {
  data: any
  buttonText: string
  filename: string
  headers?: Headers
  onClickCallback?: () => void
  className?: string
  hasIcon?: boolean
}
const CsvDownload: React.FC<CsvDownloadProps> = ({
  data,
  buttonText,
  filename,
  headers,
  onClickCallback,
  className,
  hasIcon,
}) => {
  return (
    <CSVLink
      data={data}
      target="_blank"
      headers={headers || undefined}
      className={cn(
        'flex h-8 items-center truncate rounded-md border bg-primary px-2.5 text-sm hover:bg-button-secondary',
        className
      )}
      filename={filename}
      onClick={onClickCallback}
    >
      {hasIcon && (
        <Icon
          icon={DownloadIcon}
          size="default"
          className="mr-1.5 hidden lg:block"
        />
      )}
      <span className="truncate text-sm">{buttonText}</span>
    </CSVLink>
  )
}

export default CsvDownload
