/* tslint:disable */
/* eslint-disable */
/**
 * Contracts Processing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ContractsCustomTerm } from './ContractsCustomTerm';
import {
    ContractsCustomTermFromJSON,
    ContractsCustomTermFromJSONTyped,
    ContractsCustomTermToJSON,
} from './ContractsCustomTerm';
import type { ContractsDocument } from './ContractsDocument';
import {
    ContractsDocumentFromJSON,
    ContractsDocumentFromJSONTyped,
    ContractsDocumentToJSON,
} from './ContractsDocument';

/**
 * 
 * @export
 * @interface ContractsExtractionRequest
 */
export interface ContractsExtractionRequest {
    /**
     * 
     * @type {Array<ContractsDocument>}
     * @memberof ContractsExtractionRequest
     */
    documents: Array<ContractsDocument>;
    /**
     * All existing custom terms.
     * @type {Array<ContractsCustomTerm>}
     * @memberof ContractsExtractionRequest
     */
    customTerms?: Array<ContractsCustomTerm>;
    /**
     * Map of terms requested for extraction, keys are contract types.
     * @type {{ [key: string]: Array<string>; }}
     * @memberof ContractsExtractionRequest
     */
    requestedTerms?: { [key: string]: Array<string>; };
}

/**
 * Check if a given object implements the ContractsExtractionRequest interface.
 */
export function instanceOfContractsExtractionRequest(value: object): value is ContractsExtractionRequest {
    if (!('documents' in value) || value['documents'] === undefined) return false;
    return true;
}

export function ContractsExtractionRequestFromJSON(json: any): ContractsExtractionRequest {
    return ContractsExtractionRequestFromJSONTyped(json, false);
}

export function ContractsExtractionRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContractsExtractionRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'documents': ((json['documents'] as Array<any>).map(ContractsDocumentFromJSON)),
        'customTerms': json['custom_terms'] == null ? undefined : ((json['custom_terms'] as Array<any>).map(ContractsCustomTermFromJSON)),
        'requestedTerms': json['requested_terms'] == null ? undefined : json['requested_terms'],
    };
}

export function ContractsExtractionRequestToJSON(value?: ContractsExtractionRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'documents': ((value['documents'] as Array<any>).map(ContractsDocumentToJSON)),
        'custom_terms': value['customTerms'] == null ? undefined : ((value['customTerms'] as Array<any>).map(ContractsCustomTermToJSON)),
        'requested_terms': value['requestedTerms'],
    };
}

