// Local storage utilities
// NOTE: Local storage is public, do not store anything sensitive (such as access tokens)

const isLocalStorageAvailable = (): boolean => {
  try {
    const testKey = '__testKey__'
    localStorage.setItem(testKey, testKey)
    localStorage.removeItem(testKey)
    return true
  } catch (e) {
    console.error('localStorage is not available')
    return false
  }
}

export const setItem = <T>(key: string, value: T) => {
  if (isLocalStorageAvailable()) {
    localStorage.setItem(key, JSON.stringify(value))
  }
}

export const getItem = <T>(key: string): T | null => {
  if (isLocalStorageAvailable()) {
    const item = localStorage.getItem(key)
    if (item) {
      return JSON.parse(item) as T
    }
  }
  return null
}

export const removeItem = (key: string) => {
  if (isLocalStorageAvailable()) {
    localStorage.removeItem(key)
  }
}
