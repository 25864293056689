import React from 'react'

import {
  IS_LOADING_HELP_TEXT,
  UPLOAD_DOCUMENT_HELP_TEXT,
} from 'utils/tooltip-texts'

import AskHarveyButton from 'components/common/ask-harvey-button'

import { useOgcStore } from './ogc-store'

type Props = {
  onSubmit: () => void
}

const OgcPromptInput: React.FC<Props> = ({ onSubmit }) => {
  const { isLoading, activeDocument } = useOgcStore()

  const askHarveyDisabled = !(activeDocument instanceof File) || isLoading

  const getButtonTooltip = () => {
    if (!(activeDocument instanceof File)) {
      return UPLOAD_DOCUMENT_HELP_TEXT
    } else if (isLoading) {
      return IS_LOADING_HELP_TEXT
    } else {
      return ''
    }
  }
  return (
    <AskHarveyButton
      handleSubmit={onSubmit}
      disabled={askHarveyDisabled}
      size="lg"
      tooltip={getButtonTooltip()}
      isLoading={isLoading}
    />
  )
}

export default OgcPromptInput
