import { useState, useEffect, useCallback } from 'react'

import { FetchUserWorkspaces, type Workspace } from 'models/workspace'

const useWorkspacesForUser = () => {
  const [workspaces, setWorkspaces] = useState<Workspace[]>([])
  const [isLoadingWorkspaces, setIsLoadingWorkspaces] = useState(true)

  const fetchWorkspaces = useCallback(async () => {
    const workspaces = (await FetchUserWorkspaces()).filter((w) => !w.deletedAt)
    setWorkspaces(workspaces)
    setIsLoadingWorkspaces(false)
  }, [])

  useEffect(() => {
    void fetchWorkspaces()
  }, [fetchWorkspaces])

  return { workspaces, isLoadingWorkspaces }
}

export default useWorkspacesForUser
