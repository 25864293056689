import React from 'react'

import { CirclePlay } from 'lucide-react'
import { useShallow } from 'zustand/react/shallow'

import { useAuthUser } from 'components/common/auth-context'
import { Button } from 'components/ui/button'
import { Icon } from 'components/ui/icon/icon'
import useVaultQueryDetailStore from 'components/vault/query-detail/vault-query-detail-store'

import { useRunReview } from './use-run-review'

const VaultRunButton = () => {
  const userInfo = useAuthUser()
  const {
    isRunDisabled,
    shouldCheckIsQueryLoadingForRunButton,
    hasEmptyCells,
    handleRun,
  } = useRunReview()
  const [isQueryLoading, isRunButtonLoading] = useVaultQueryDetailStore(
    useShallow((state) => [state.isQueryLoading, state.isRunButtonLoading])
  )

  if (!userInfo.IsVaultV2User) {
    return null
  }

  if (isQueryLoading || !hasEmptyCells) {
    // If the query is loading or there are no empty cells, don't show the run button
    return null
  }

  return (
    <Button
      className="flex items-center gap-1"
      onClick={() => handleRun({})}
      isLoading={
        (shouldCheckIsQueryLoadingForRunButton && isQueryLoading) ||
        isRunButtonLoading
      }
      disabled={isRunDisabled}
    >
      <Icon icon={CirclePlay} />
      Resume
    </Button>
  )
}

export default VaultRunButton
