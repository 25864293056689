import React, { useMemo, useState } from 'react'
import { DateRange } from 'react-day-picker'

import {
  Column,
  ColumnDef,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  PaginationState,
  Row,
  SortingState,
  useReactTable,
} from '@tanstack/react-table'
import { format } from 'date-fns'
import _ from 'lodash'
import { InfoIcon } from 'lucide-react'
import pluralize from 'pluralize'

import { UsageData } from 'models/usage-data'
import { Maybe } from 'types'

import { cn, EM_DASH } from 'utils/utils'

import { Card, CardContent } from 'components/ui/card'
import CsvDownload from 'components/ui/csv-download'
import { DataTable } from 'components/ui/data-table/data-table'
import DataTableFooter from 'components/ui/data-table/data-table-footer'
import DataTableHeader from 'components/ui/data-table/data-table-header'
import DataTableSortHeader from 'components/ui/data-table/data-table-sort-header'
import Icon from 'components/ui/icon/icon'
import { Separator } from 'components/ui/separator'
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/ui/tooltip'

import { TOTAL_KEY } from './dashboard-page'

interface UserStatsTableProps {
  usageData: UsageData | undefined
  isLoading: boolean
  emptyStateText: string
  selectedDateRange: Maybe<DateRange>
}

type UserStatsRow = { userEmail: string; [key: string]: number | string }

const UserStatsTable = ({
  usageData,
  isLoading,
  emptyStateText,
  selectedDateRange,
}: UserStatsTableProps) => {
  const processedData = useMemo(() => {
    if (_.isEmpty(usageData)) return []

    return usageData.userStats.map((userStats) => {
      return {
        ...userStats,
        userEmail: userStats.userEmail,
        [TOTAL_KEY]: Object.values(userStats).reduce(
          (acc: number, curr) => (typeof curr === 'number' ? acc + curr : acc),
          0
        ),
      }
    })
  }, [usageData])

  const columns: ColumnDef<UserStatsRow>[] = [
    {
      accessorKey: 'userEmail',
      header: ({ column }) => (
        <DataTableSortHeader column={column} header="User" />
      ),
      cell: ({ getValue }) => (
        <Tooltip delayDuration={250}>
          <TooltipTrigger asChild className="w-60">
            <div className="ml-3 truncate text-sm">{String(getValue())}</div>
          </TooltipTrigger>
          <TooltipContent align="start">{String(getValue())}</TooltipContent>
        </Tooltip>
      ),
      enableGlobalFilter: true,
    },
    {
      accessorKey: TOTAL_KEY,
      header: ({ column }) => (
        <DataTableSortHeader column={column} header={TOTAL_KEY} />
      ),
      cell: ({ row }) => (
        <Tooltip delayDuration={250}>
          <TooltipTrigger asChild>
            <div className="ml-3 w-1/2 text-sm">{row.getValue(TOTAL_KEY)}</div>
          </TooltipTrigger>
          <TooltipContent>
            {`${row.getValue(TOTAL_KEY)} ${pluralize(
              'query',
              row.getValue(TOTAL_KEY)
            )} submitted`}
          </TooltipContent>
        </Tooltip>
      ),
      enableGlobalFilter: false,
    },
    ...(usageData?.distinctTypes || []).map((type) => ({
      accessorFn: (row: UserStatsRow) => {
        if (_.isNil(row[type])) return -1
        return row[type]
      },
      accessorKey: type,
      header: ({ column }: { column: Column<UserStatsRow, unknown> }) => (
        <DataTableSortHeader column={column} header={type} />
      ),
      cell: ({ row }: { row: Row<UserStatsRow> }) => (
        <Tooltip delayDuration={250}>
          <TooltipTrigger asChild>
            <div className="ml-3 w-1/2 text-sm">
              {row.getValue(type) === -1 ? EM_DASH : row.getValue(type)}
            </div>
          </TooltipTrigger>
          <TooltipContent>
            {row.getValue(type) === -1
              ? 'User does not have access to this product'
              : `${row.getValue(type)} ${pluralize(
                  'query',
                  row.getValue(type)
                )} submitted`}
          </TooltipContent>
        </Tooltip>
      ),
      enableGlobalFilter: false,
    })),
  ]

  const [filter, setFilter] = useState<string>('')

  const [sorting, setSorting] = useState<SortingState>([
    {
      id: TOTAL_KEY,
      desc: true,
    },
  ])

  const [tablePaginationState, setTablePaginationState] =
    useState<PaginationState>({
      pageIndex: 0,
      pageSize: 10,
    })

  const table = useReactTable({
    data: processedData,
    columns,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setTablePaginationState,
    manualPagination: false,
    onGlobalFilterChange: setFilter,
    enableSorting: true,
    state: {
      sorting: sorting,
      globalFilter: filter,
      pagination: tablePaginationState,
    },
  })

  const rowCountCopy = `${table.getFilteredRowModel().rows.length} ${pluralize(
    'user',
    table.getFilteredRowModel().rows.length
  )}`

  const exportFilename =
    selectedDateRange?.from && selectedDateRange?.to
      ? `user_stats_${format(selectedDateRange.from, 'P')}-${format(
          selectedDateRange.to,
          'P'
        )}.csv`
      : 'user_stats.csv'

  return (
    <Card className="rounded-lg border">
      <CardContent className="p-0 pb-2 pt-2">
        <DataTableHeader
          searchable={{ searchQuery: filter, setSearchQuery: setFilter }}
          className="text-neutral-950 mb-1 pl-4 pr-4 text-sm font-semibold"
        >
          <div className="flex w-full items-center justify-between">
            <div className="mr-4 flex items-center gap-1 md:gap-2">
              <p className="truncate">Number of queries per user</p>
              <Tooltip delayDuration={200}>
                <TooltipTrigger>
                  <Icon icon={InfoIcon} size="small" />
                </TooltipTrigger>
                <TooltipContent className="max-w-sm text-start">
                  This table includes everyone with current permissions to use
                  the products, even if they have not used them yet.
                </TooltipContent>
              </Tooltip>
            </div>
            <CsvDownload
              data={processedData}
              buttonText="Export"
              filename={exportFilename}
              className="mr-2"
              hasIcon={true}
            />
          </div>
        </DataTableHeader>
        <Separator className="mt-2 w-full" />
        <DataTable
          table={table}
          // can't set max height, it'll break scrolling
          className={cn({ 'h-[580px]': !isLoading })}
          isLoading={isLoading}
          emptyStateText={emptyStateText}
          tableCellClassName="max-w-80"
          hideTableBorder
        />
        <DataTableFooter table={table} isLoading={isLoading}>
          <p>{rowCountCopy}</p>
        </DataTableFooter>
      </CardContent>
    </Card>
  )
}

export default UserStatsTable
