import React from 'react'

import _ from 'lodash'

import { Textarea } from 'components/ui/text-area'

interface MultiEmailTextareaProps {
  value: string
  onChange: (emails: string) => void
  maxNumUsers: number
  emailsValidCallback: (valid: boolean) => void
  placeholder?: string
  setNumEmails?: (numEmails: number) => void
}

export const getCleanEmails = (emails: string) => {
  return emails
    .split('\n')
    .map((email: string) => email.trim())
    .filter((email: string) => !_.isEmpty(email))
}

const MultiEmailTextarea = ({
  value,
  onChange,
  maxNumUsers,
  emailsValidCallback,
  placeholder,
  setNumEmails,
}: MultiEmailTextareaProps) => {
  const setValue = (textAreaValue: string) => {
    const values = getCleanEmails(textAreaValue)
    const textAreaLines = textAreaValue.trim().length > 0 ? values.length : 0
    if (setNumEmails) setNumEmails(textAreaLines)
    onChange(textAreaValue) // test if we can auto split lines
    if (textAreaLines > maxNumUsers) {
      emailsValidCallback(false)
    } else {
      emailsValidCallback(true)
    }
  }

  return (
    <Textarea
      value={value}
      onChange={(e) => setValue(e.target.value)}
      placeholder={placeholder}
      className="max-h-60"
    />
  )
}

export default MultiEmailTextarea
