import * as React from 'react'
import { useLocation } from 'react-router-dom'

import { FLOWS } from 'components/common/flows'
import { Dialog, DialogContent } from 'components/ui/dialog'

export type StackValue = {
  flow: string
  step: string
  stepInput: Record<string, unknown>
  stepIndex: number
}

type ModalFlowStepContextType = {
  step: string
  stepInput: Record<string, unknown>
  stepIndex: number
}

type ModalFlowNavigatorContextType = {
  stack: StackValue[]
  setStack: React.Dispatch<React.SetStateAction<StackValue[]>>
}

// The context for each step in the flow. This holds everything the current step needs to know
const ModalFlowStepContext = React.createContext<
  ModalFlowStepContextType | undefined
>(undefined)

// This just holds a stack for downstream controls
export const ModalFlowNavigatorContext = React.createContext<
  ModalFlowNavigatorContextType | undefined
>(undefined)

export const useModalFlowNavigatorContext = () => {
  const value = React.useContext(ModalFlowNavigatorContext)

  if (!value) {
    throw new Error(
      'Tried to consume ModalFlowNavigatorContext without a provider'
    )
  }

  return value
}

export const useModalFlowStepContext = () => {
  const value = React.useContext(ModalFlowStepContext)

  if (!value) {
    throw new Error('Tried to consume ModalFlowStepContext without a provider')
  }

  return value
}

interface ModalFlowProps<T extends typeof FLOWS> {
  flows: T
  children: React.ReactNode
}

export const ModalFlowNavigator = <T extends typeof FLOWS>({
  flows,
  children,
}: ModalFlowProps<T>) => {
  const [stack, setStack] = React.useState<StackValue[]>([])
  const contextValue = React.useMemo(
    () => ({ stack, setStack }),
    [stack, setStack]
  )

  // Clear out any open modals upon user navigation. Otherwise the user could navigate to a different page
  // but the flow will still be mounted.
  const location = useLocation()
  React.useEffect(() => {
    setStack([])
  }, [location])

  if (stack.length === 0) {
    return (
      <ModalFlowNavigatorContext.Provider value={contextValue}>
        {children}
      </ModalFlowNavigatorContext.Provider>
    )
  }

  const currentStep = stack[stack.length - 1]
  const FlowComponent = flows[currentStep.flow as keyof typeof FLOWS]

  if (!FlowComponent) {
    throw new Error(
      `Flow ${currentStep.flow} not found, only [${Object.keys(flows)
        .map((flow) => `'${flow}'`)
        .join(', ')}] are supported`
    )
  }

  return (
    <ModalFlowNavigatorContext.Provider value={contextValue}>
      <ModalFlowStepContext.Provider value={currentStep}>
        <Dialog modal open>
          <DialogContent
            preventClose
            showCloseIcon={false}
            className="h-[80vh] max-h-[500px] w-[90vw] max-w-[700px]"
            innerClassName="p-0 flex flex-col space-y-0"
          >
            <FlowComponent />
          </DialogContent>
        </Dialog>
      </ModalFlowStepContext.Provider>
      {children}
    </ModalFlowNavigatorContext.Provider>
  )
}
