import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'

import { fetchWorkspaceUsersClientAdmin, RawUser } from 'models/users'

interface ClientAdminState {
  users: RawUser[]
}

interface ClientAdminAction {
  fetchUsers: (workspaceId: number) => Promise<void>
}

export const useClientAdminStore = create(
  devtools(
    immer<ClientAdminState & ClientAdminAction>((set) => ({
      users: [],
      fetchUsers: async (workspaceId: number) => {
        const users = await fetchWorkspaceUsersClientAdmin(workspaceId)
        set({ users })
      },
    }))
  )
)
