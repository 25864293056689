import { FileType } from 'types/file'

export const FILE_TYPES = [
  FileType.PDF,
  FileType.WORD,
  FileType.WORD_LEGACY,
  FileType.ZIP,
]

export const MAX_FILES = 200
export const MAX_FILE_SIZE_MB = 20
export const MAX_TOTAL_FILE_SIZE_MB = 100

export const EXTRACT_NOT_AVAILABLE_VALUE = 'N/A'
export const ROUTE = 'contracts'
