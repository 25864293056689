import React, { useEffect, useState } from 'react'

import { setClientMatterWorkspaceSettings } from 'models/client-matters'

import { displayErrorMessage, displaySuccessMessage } from 'utils/toast'

import { useClientMattersStore } from 'components/client-matters/client-matters-store'
import { useSettingsState } from 'components/settings/settings-store'
import { Card, CardContent } from 'components/ui/card'
import { Label } from 'components/ui/label'
import { Switch } from 'components/ui/switch'

interface SettingsClientMattersAdminProps {
  workspaceId: number
}

const SettingsClientMattersAdmin = ({
  workspaceId,
}: SettingsClientMattersAdminProps) => {
  const settingsUser = useSettingsState((s) => s.settingsUser)

  const {
    shouldCmLockQueries,
    setShouldCmLockQueries,
    canUsersManageCm,
    setCanUsersManageCm,
  } = useClientMattersStore()

  const [shouldBlockNonCmQueries, setShouldBlockNonCmQueries] =
    useState(shouldCmLockQueries)
  const [canUsersManageCmToggle, setCanUsersManageCmToggle] =
    useState(canUsersManageCm)

  useEffect(() => {
    setShouldBlockNonCmQueries(shouldCmLockQueries)
    setCanUsersManageCmToggle(canUsersManageCm)
  }, [shouldCmLockQueries, canUsersManageCm])

  const onToggleBlockCmQueries = async (
    queryLock: boolean,
    canUserManage: boolean
  ) => {
    const response = await setClientMatterWorkspaceSettings(
      workspaceId,
      queryLock,
      canUserManage
    )
    if (!response.updated) {
      displayErrorMessage('Failed to update client matter setting', 10)
    } else {
      setShouldBlockNonCmQueries(queryLock)
      setShouldCmLockQueries(queryLock)
      setCanUsersManageCmToggle(canUserManage)
      setCanUsersManageCm(canUserManage)
      displaySuccessMessage('Client matter setting updated successfully', 5)
    }
  }

  if (!settingsUser?.isClientMattersManagementUser) return null

  return (
    <Card>
      <CardContent className="mb-3 ml-4 p-2">
        <div className="ml-0.5 mt-3 flex w-[500px] flex-col space-y-2">
          <div className="flex items-center justify-between">
            <Label>
              Do not allow users to submit a query without a client matter
              selected
            </Label>
            <Switch
              checked={shouldBlockNonCmQueries}
              onCheckedChange={() =>
                onToggleBlockCmQueries(
                  !shouldBlockNonCmQueries,
                  canUsersManageCmToggle
                )
              }
            />
          </div>
          <div className="flex items-center justify-between">
            <Label>
              Do not allow users to add, edit or delete client matters
            </Label>
            <Switch
              checked={!canUsersManageCmToggle}
              onCheckedChange={() =>
                onToggleBlockCmQueries(
                  shouldBlockNonCmQueries,
                  !canUsersManageCmToggle
                )
              }
            />
          </div>
        </div>
      </CardContent>
    </Card>
  )
}

export default SettingsClientMattersAdmin
