import React, { useMemo, useRef, useEffect } from 'react'
import { useState } from 'react'

import { ArrowLeft } from 'lucide-react'
import { useShallow } from 'zustand/react/shallow'

import { QUERY_MUST_HAVE_X_LEN_HELP_TEXT } from 'utils/tooltip-texts'

import { AssistantMode } from 'components/assistant/components/assistant-mode-select'
import {
  getQueryLimit,
  isQueryValid,
} from 'components/assistant/utils/assistant-helpers'
import { MIN_QUERY_LENGTH } from 'components/assistant/utils/constants'
import { useAuthUser } from 'components/common/auth-context'
import { Button } from 'components/ui/button'
import { DialogHeader, DialogTitle } from 'components/ui/dialog'
import Icon from 'components/ui/icon/icon'
import { Tabs, TabsList, TabsTrigger } from 'components/ui/tabs'
import { Textarea } from 'components/ui/text-area'
import { useVaultStore } from 'components/vault/utils/vault-store'
import useVaultWorkflowStore, {
  WorkflowModalState,
} from 'components/vault/workflows/vault-workflow-store'

const VaultAssistantThreadInput = ({
  assistantMode,
  setAssistantMode,
  onBackButtonClicked,
  handleOpenAddFiles,
}: {
  assistantMode: AssistantMode
  setAssistantMode: (mode: AssistantMode) => void
  onBackButtonClicked: () => void
  handleOpenAddFiles: () => void
}) => {
  const userInfo = useAuthUser()
  const [setWorkflowModalState] = useVaultWorkflowStore(
    useShallow((state) => [state.setWorkflowModalState])
  )
  const setPendingQuery = useVaultStore(useShallow((s) => s.setPendingQuery))

  const [queryValue, setQueryValue] = useState('')

  const handleValueChange = (value: string) => {
    const newMode = value as AssistantMode
    setAssistantMode(newMode)
  }

  const placeholder =
    assistantMode === AssistantMode.DRAFT
      ? 'Ask Harvey to write a draft…'
      : 'Ask Harvey anything…'

  const queryLimit = getQueryLimit(true)
  const isCurrentQueryValid = useMemo(
    () => isQueryValid(queryValue, queryLimit),
    [queryValue, queryLimit]
  )

  const getDisabledText = () => {
    if (queryValue.trim().length < MIN_QUERY_LENGTH) {
      return QUERY_MUST_HAVE_X_LEN_HELP_TEXT(`at least ${MIN_QUERY_LENGTH}`)
    } else if (queryValue.trim().length > queryLimit) {
      return QUERY_MUST_HAVE_X_LEN_HELP_TEXT(`fewer than ${queryLimit}`)
    } else {
      return ''
    }
  }

  const textareaRef = useRef<HTMLTextAreaElement>(null)

  useEffect(() => {
    textareaRef.current?.focus()
  }, [])

  return (
    <>
      <DialogHeader className="gap-4">
        <Button
          variant="ghost"
          onClick={onBackButtonClicked}
          className="absolute left-2 top-2"
          size="smIcon"
        >
          <Icon icon={ArrowLeft} />
        </Button>
        <DialogTitle className="w-full text-center">
          Ask Harvey anything...
        </DialogTitle>
        <div className="-mx-6 border-b" />
      </DialogHeader>
      <div className="flex flex-col gap-2">
        <Tabs value={assistantMode} onValueChange={handleValueChange}>
          <TabsList variant="minimal" className="gap-1">
            <TabsTrigger
              id="assistant-mode-assist"
              variant="minimal"
              value={AssistantMode.ASSIST}
              className="py-1.5"
            >
              <span className="text-sm font-medium">Assist</span>
            </TabsTrigger>
            {userInfo.IsAssistantDraftUser && (
              <TabsTrigger
                id="assistant-mode-draft"
                variant="minimal"
                value={AssistantMode.DRAFT}
                className="py-1.5"
              >
                <span className="text-sm font-medium">Draft</span>
              </TabsTrigger>
            )}
          </TabsList>
        </Tabs>
        <Textarea
          ref={textareaRef}
          value={queryValue}
          onChange={(e) => setQueryValue(e.target.value)}
          placeholder={placeholder}
        />
      </div>

      <div className="flex items-center justify-end gap-2">
        <Button
          onClick={() => {
            setPendingQuery(queryValue)
            handleOpenAddFiles()
            setWorkflowModalState(WorkflowModalState.AddFilesAssistantThread)
          }}
          disabled={!isCurrentQueryValid}
          tooltip={getDisabledText()}
        >
          Continue
        </Button>
      </div>
    </>
  )
}

export default VaultAssistantThreadInput
