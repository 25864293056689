import { useState } from 'react'

function useWorkflowTaxonomySelector() {
  const [openTaxonomies, setOpenTaxonomies] = useState<string[]>([])

  const handleTaxonomyChange = (value: string[]) => {
    setOpenTaxonomies(value)
  }

  const handleToggleTaxonomy = (value: string, isExpanded: boolean) => {
    setOpenTaxonomies((prevTaxonomies) => {
      const taxonomies = [...prevTaxonomies]
      const valueIndex = taxonomies.indexOf(value)
      if (isExpanded) {
        if (valueIndex === -1) taxonomies.push(value)
      } else if (valueIndex !== -1) {
        taxonomies.splice(valueIndex, 1)
      }
      return taxonomies
    })
  }

  return {
    openTaxonomies,
    handleTaxonomyChange,
    handleToggleTaxonomy,
  }
}

export default useWorkflowTaxonomySelector
