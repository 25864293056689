export const ASSISTANT_V2_MODE_HELP = `<h3>Choosing a mode</h3>
<p>Assistant has two modes, Assist and Draft. These modes feature different ways of interacting with Assistant and different styles of output.</p>

<p><strong>Assist mode is built for quick Q&A-style iteration.</strong> Assist mode is helpful for quickly learning about something, getting a high-level overview, or finding specific information within a document. After the initial response, you can ask follow-up questions to go deeper.</p>

<p><strong>Draft mode is best for generating content like emails, contract clauses, and sections of briefs.</strong> Draft mode is helpful for gaining in-depth understanding, drafting professional communication, or subjective tasks such as suggesting counterarguments. After the initial draft, you can request revisions and view the changes.</p>

Generally, Assist mode is more concise and provides clear, organized overviews. Draft mode is more verbose and helps you write sophisticated content.`

export const ASSISTANT_V2_OPEN_ENDED_HELP_INTRO = `<p class="ql-align-justify">In Assistant, you can query Harvey with or without uploading documents. When one or more documents are uploaded, Harvey will generate a response to your query based on your document(s) and include linked references to the document(s).</p>`

export const ASSISTANT_V2_OPEN_ENDED_HELP_REMINDERS = `<h3>Important reminders</h3>
<ul>
  <li><strong>Always double-check outputs for accuracy and completeness.</strong> Responses may contain hallucinations. Be particularly careful when asking about specific cases, statutes, company filings, or other sources that may expose gaps in Harvey’s training data.</li>
  <li>You can customize how Harvey responds to you by specifying additional information in your query, like a specific format, tone, or intended audience (e.g., “a professional email for a client with little acquisition experience”).</li>
  <li>Queries should have a specific request and include context on the documents.</li>
  <li>When documents are uploaded, text is interpreted left to right, all the way across the page. This means Assistant is unable to recognize multi-column pages, vertical text, or images.</li>
</ul>`

export const ASSISTANT_V2_WITHOUT_OPEN_ENDED_HELP_INTRO = `<p class="ql-align-justify">In Assistant, you can query Harvey and generate a response on any topic.</p>`

export const ASSISTANT_V2_WITHOUT_OPEN_ENDED_HELP_REMINDERS = `<h3>Important reminders</h3>
<ul>
  <li><strong>Always double-check outputs for accuracy and completeness.</strong> Responses may contain hallucinations. Be particularly careful when asking about specific cases, statutes, company filings, or other sources that may expose gaps in Harvey’s training data.</li>
  <li>You can customize how Harvey responds to you by specifying additional information in your query, like a specific format, tone, or intended audience (e.g., “a professional email for a client with little acquisition experience”).</li>
</ul>`

export const ASSISTANT_KNOWLEDGE_SOURCES_HEADER = `<h3>Knowledge Sources</h3>`
export const ASSISTANT_KNOWLEDGE_SOURCES_INTRO = `<p>Knowledge is designed to facilitate research across various domains, enabling users to search through extensive proprietary and public data sources. Explore trusted information sources by selecting a source from the Knowledge section.</p>`

export const ASSISTANT_SAVE_PROMPT_TOOLTIP_HELP = `Save a prompt to Library`
