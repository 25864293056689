/* tslint:disable */
/* eslint-disable */
/**
 * Assistant API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GenerateTitleRequest
 */
export interface GenerateTitleRequest {
    /**
     * 
     * @type {number}
     * @memberof GenerateTitleRequest
     */
    eventId: number;
    /**
     * 
     * @type {string}
     * @memberof GenerateTitleRequest
     */
    query: string;
}

/**
 * Check if a given object implements the GenerateTitleRequest interface.
 */
export function instanceOfGenerateTitleRequest(value: object): value is GenerateTitleRequest {
    if (!('eventId' in value) || value['eventId'] === undefined) return false;
    if (!('query' in value) || value['query'] === undefined) return false;
    return true;
}

export function GenerateTitleRequestFromJSON(json: any): GenerateTitleRequest {
    return GenerateTitleRequestFromJSONTyped(json, false);
}

export function GenerateTitleRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): GenerateTitleRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'eventId': json['event_id'],
        'query': json['query'],
    };
}

export function GenerateTitleRequestToJSON(value?: GenerateTitleRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'event_id': value['eventId'],
        'query': value['query'],
    };
}

