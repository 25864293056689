import { UploadedFile } from 'openapi/models/UploadedFile'

export enum BroadcastMessageTypes {
  READY = 'READY',
  DATA = 'DATA',
}

export interface BroadcastMessage<T> {
  type: BroadcastMessageTypes
  payload?: T
}

export const BroadcastChannelMessageEvent = 'message' // this is part of the broadcast channel API

export type BroadcastChannelDetails = {
  name: string
  key: string // this is uuid, used to add extra uniqueness to the channel name. Use this website: https://www.uuidgenerator.net/version7
}

export enum BroadcastChannelType {
  ASSISTANT_ISSUES_LIST_REDIRECT = 'assistant-issues-list-redirect',
  ISSUES_LIST_ASSISTANT_REDIRECT = 'issues-list-assistant-redirect',
}

export const RegisteredHarveyBroadcastChannels: Record<
  BroadcastChannelType,
  BroadcastChannelDetails
> = {
  [BroadcastChannelType.ASSISTANT_ISSUES_LIST_REDIRECT]: {
    name: 'assistant-issues-list-redirect',
    key: 'f5a5c3c0-1d3e-11ec-9621-0242ac130002',
  },
  [BroadcastChannelType.ISSUES_LIST_ASSISTANT_REDIRECT]: {
    name: 'issues-list-assistant-redirect',
    key: '018dd24f-8dbf-7630-87da-19c6c92ee21d',
  },
}

// add new data types used by broadcast channels here
export type IssuesListRedirectData = {
  file: File
  uploadedDoc: UploadedFile
}
