/* tslint:disable */
/* eslint-disable */
/**
 * Harvey Web App - User Permissions
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const InternalOnlyPermission = {
    READALL_WORKSPACES: 'read:all_workspaces',
    WRITEALL_WORKSPACES: 'write:all_workspaces',
    MANAGEUSERS: 'manage:users',
    READALL_SENSITIVE: 'read:all_sensitive',
    READSOME_SENSITIVE_DATA: 'read:some_sensitive_data',
    USESKIP_RATE_LIMIT: 'use:skip_rate_limit',
    CREATEVAULT_INTERNAL_ONLY: 'create:vault_internal_only',
    CREATEVAULT_SANDBOX_PROJECT: 'create:vault_sandbox_project',
    CREATEHARVEY_LIBRARY_ITEMS: 'create:harvey_library_items',
    CREATERESPONSE_COMPARISON: 'create:response_comparison',
    READENG_INTERNAL: 'read:eng_internal',
    WRITEENG_INTERNAL: 'write:eng_internal'
} as const;
export type InternalOnlyPermission = typeof InternalOnlyPermission[keyof typeof InternalOnlyPermission];


export function instanceOfInternalOnlyPermission(value: any): boolean {
    for (const key in InternalOnlyPermission) {
        if (Object.prototype.hasOwnProperty.call(InternalOnlyPermission, key)) {
            if (InternalOnlyPermission[key as keyof typeof InternalOnlyPermission] === value) {
                return true;
            }
        }
    }
    return false;
}

export function InternalOnlyPermissionFromJSON(json: any): InternalOnlyPermission {
    return InternalOnlyPermissionFromJSONTyped(json, false);
}

export function InternalOnlyPermissionFromJSONTyped(json: any, ignoreDiscriminator: boolean): InternalOnlyPermission {
    return json as InternalOnlyPermission;
}

export function InternalOnlyPermissionToJSON(value?: InternalOnlyPermission | null): any {
    return value as any;
}

