import React, { useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'

import _ from 'lodash'
import {
  PencilLine,
  Plus,
  Search,
  Send,
  SquareAsterisk,
  Trash2,
  Undo,
  Waypoints,
} from 'lucide-react'
import { useShallow } from 'zustand/react/shallow'

import { ReviewWorkflowVisibilityKind } from 'openapi/models/ReviewWorkflowVisibilityKind'

import { cn } from 'utils/utils'

import { useAuthUser } from 'components/common/auth-context'
import { Button } from 'components/ui/button'
import { Card } from 'components/ui/card'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from 'components/ui/dialog'
import { Icon } from 'components/ui/icon/icon'
import { Input } from 'components/ui/input'
import { Tabs, TabsContent, TabsList, TabsTrigger } from 'components/ui/tabs'
import { ReviewWorkflow } from 'components/vault/utils/vault-fetcher'
import useVaultWorkflowStore, {
  WorkflowModalState,
} from 'components/vault/workflows/vault-workflow-store'

const VaultWorkflowSelector: React.FC = () => {
  const userInfo = useAuthUser()
  const [searchTerm, setSearchTerm] = useState('')
  const [selectedTab, setSelectedTab] = useState('All')
  const [
    workflows,
    workflowModalState,
    setWorkflowModalState,
    setSelectedWorkflow,
  ] = useVaultWorkflowStore(
    useShallow((state) => [
      Object.values(state.workflows) as ReviewWorkflow[],
      state.workflowModalState,
      state.setWorkflowModalState,
      state.setSelectedWorkflow,
    ])
  )

  const [isShowingDraftWorkflows, setIsShowingDraftWorkflows] = useState(false)

  useHotkeys(
    'Control+h',
    () => {
      if (userInfo.IsVaultWorkflowAdminUser) {
        // Only admins can see draft workflows
        setIsShowingDraftWorkflows(!isShowingDraftWorkflows)
        if (isShowingDraftWorkflows && selectedTab === 'Draft') {
          setSelectedTab('All')
        }
      }
    },
    [isShowingDraftWorkflows, selectedTab]
  )

  // Filter workflows based on user role, search term, and selected tab
  const filteredWorkflows = workflows
    .filter((workflow) => {
      if (isShowingDraftWorkflows) {
        return true // When showing draft workflows, show all workflows
      } else {
        return workflow.visibility !== ReviewWorkflowVisibilityKind.DRAFT // Non-admins can only see published workflows
      }
    })
    .filter(
      (workflow) =>
        // Search by name
        workflow.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
        // Filter by matching the selected tab
        (selectedTab === 'All' ||
          workflow.kind.toLowerCase() === selectedTab.toLowerCase() ||
          (selectedTab === 'Draft' &&
            workflow.visibility === ReviewWorkflowVisibilityKind.DRAFT))
    )

  // Component to render individual workflow card
  const WorkflowCard: React.FC<{ workflow: ReviewWorkflow }> = ({
    workflow,
  }) => {
    const isDraft =
      isShowingDraftWorkflows &&
      workflow.visibility === ReviewWorkflowVisibilityKind.DRAFT
    const showUnPublishButton =
      isShowingDraftWorkflows &&
      workflow.visibility !== ReviewWorkflowVisibilityKind.DRAFT
    return (
      <Card
        className={cn(
          'flex h-28 w-full items-center justify-center rounded border border-primary p-4 shadow-sm hover:cursor-pointer hover:border-input-focused',
          {
            'border-dashed': isDraft,
          }
        )}
        onClick={() => {
          setSelectedWorkflow(workflow)
          setWorkflowModalState(WorkflowModalState.Builder)
        }}
      >
        <div className="flex w-full flex-col items-start gap-2">
          <div className="flex items-center justify-center gap-2">
            <Icon icon={isDraft ? PencilLine : SquareAsterisk} />
            <p className="line-clamp-1 text-sm font-medium">{workflow.name}</p>
          </div>
          <p
            className={cn('line-clamp-3 text-xs text-muted', {
              'line-clamp-1': isShowingDraftWorkflows,
            })}
          >
            {workflow.description}
          </p>
          {isDraft && (
            <div className="flex items-center gap-2">
              <Button
                variant="outline"
                size="sm"
                onClick={(e) => {
                  e.stopPropagation()
                  setSelectedWorkflow(workflow)
                  setWorkflowModalState(WorkflowModalState.Publish)
                }}
              >
                <Icon icon={Send} size="small" className="mr-1" />
                <p className="text-xs">Publish</p>
              </Button>
              <Button
                variant="outline"
                size="sm"
                onClick={(e) => {
                  e.stopPropagation()
                  setSelectedWorkflow(workflow)
                  setWorkflowModalState(WorkflowModalState.Delete)
                }}
              >
                <Icon icon={Trash2} size="small" className="mr-1" />
                <p className="text-xs">Delete</p>
              </Button>
            </div>
          )}
          {showUnPublishButton && (
            <Button
              variant="outline"
              size="sm"
              onClick={(e) => {
                e.stopPropagation()
                setSelectedWorkflow(workflow)
                setWorkflowModalState(WorkflowModalState.Unpublish)
              }}
            >
              <Icon icon={Undo} size="small" className="mr-1" />
              <p className="text-xs">Un-publish</p>
            </Button>
          )}
        </div>
      </Card>
    )
  }

  // Component to render the "New Workflow" card
  const NewWorkflowCard: React.FC = () => {
    return (
      <Card
        onClick={() => {
          setWorkflowModalState(WorkflowModalState.New)
        }}
        className="flex h-28 w-full items-center justify-center rounded border border-dashed p-4 shadow-sm hover:cursor-pointer hover:border-input-focused"
      >
        <div className="flex flex-col items-center gap-2">
          <Icon icon={Plus} size="large" className="text-muted" />
          <p className="text-sm font-medium">New draft workflow</p>
        </div>
      </Card>
    )
  }

  // Generate unique workflow kinds for tabs
  const workflowKinds = [
    'All',
    ...Array.from(
      new Set(workflows.map((workflow) => _.capitalize(workflow.kind)))
    ),
    isShowingDraftWorkflows ? 'Draft' : undefined,
  ].filter(Boolean) as string[]

  return (
    <Dialog
      open={workflowModalState === WorkflowModalState.Selector}
      onOpenChange={(open) => {
        if (!open) {
          setWorkflowModalState(WorkflowModalState.None)
        }
      }}
    >
      <DialogContent className="bg-secondary sm:max-w-[800px]">
        <DialogHeader>
          <DialogTitle>
            <div className="flex items-center gap-2">
              <Icon icon={Waypoints} /> Select a workflow
            </div>
          </DialogTitle>
          <DialogDescription className="text-left">
            Select a workflow to use in your vault
          </DialogDescription>
        </DialogHeader>
        <div className="mb-4 flex flex-col items-center justify-between gap-4 sm:flex-row">
          <Tabs
            value={selectedTab}
            onValueChange={setSelectedTab}
            className="flex-1"
          >
            <TabsList variant="minimal" className="w-full border-b-0">
              {workflowKinds.map((kind) => (
                <TabsTrigger
                  key={kind}
                  value={kind}
                  variant="minimal"
                  className="border-b-0 font-medium"
                >
                  <p className="text-lg font-medium">{kind}</p>
                </TabsTrigger>
              ))}
            </TabsList>
          </Tabs>
          <div className="relative">
            <Input
              placeholder="Search workflows"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="h-8 pl-10"
            />
            <Icon
              icon={Search}
              className="text-muted-foreground absolute left-3 top-1/2 -translate-y-1/2 transform"
            />
          </div>
        </div>
        <Tabs value={selectedTab} onValueChange={setSelectedTab}>
          {workflowKinds.map((kind) => (
            <TabsContent key={kind} value={kind}>
              {kind === 'Draft' && (
                <div className="mb-4">
                  <p className="text-muted">
                    Draft workflows are not visible to regular users
                  </p>
                </div>
              )}
              {(filteredWorkflows.length > 0 || kind === 'Draft') && (
                <div className="grid min-h-40 grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3">
                  {kind === 'Draft' && <NewWorkflowCard />}
                  {filteredWorkflows.map((workflow) => (
                    <WorkflowCard key={workflow.id} workflow={workflow} />
                  ))}
                </div>
              )}
              {filteredWorkflows.length === 0 && kind !== 'Draft' && (
                <div className="flex min-h-40 items-center justify-center">
                  <p className="text-center text-muted">No workflows found</p>
                </div>
              )}
            </TabsContent>
          ))}
        </Tabs>
      </DialogContent>
    </Dialog>
  )
}

export default VaultWorkflowSelector
