import { EvaluationQuestionResponseOptionMultipleSelect } from 'openapi/models/EvaluationQuestionResponseOptionMultipleSelect'
import { EvaluationQuestionResponseOptionRating } from 'openapi/models/EvaluationQuestionResponseOptionRating'
import { EvaluationQuestionResponseType } from 'openapi/models/EvaluationQuestionResponseType'
import { EvaluationQuestionType } from 'openapi/models/EvaluationQuestionType'
import { UserEvaluationResponse } from 'openapi/models/UserEvaluationResponse'
import { FileType } from 'types/file'

import { createAcceptObject } from 'utils/file-utils'

export const MAX_FILES = 1
export const ALLOWED_FILE_TYPES = [FileType.CSV]
export const MAX_FILE_SIZE_MB = 20
export const ExperimentAllowedFileTypes = createAcceptObject(ALLOWED_FILE_TYPES)
export const EXPERIMENT_CSV_COLUMNS = [
  'query',
  'response_*',
  'model_*',
  'metadata',
]

// mapping of question type to allowed question response types
export const VALID_EVALUATION_QUESTION_RESPONSE_TYPES = {
  [EvaluationQuestionType.RESPONSE_LEVEL]: [
    EvaluationQuestionResponseType.RATING,
    // EvaluationQuestionResponseType.FLAG,  // not fully implemented, de-prioritized as of 7/11/24
    EvaluationQuestionResponseType.FREE_FORM,
    // EvaluationQuestionResponseType.MULTIPLE_SELECT, // not fully implemented, de-prioritized as of 7/11/24
  ],
  [EvaluationQuestionType.EVALUATION_LEVEL]: [
    EvaluationQuestionResponseType.PREFERENCE,
    EvaluationQuestionResponseType.FREE_FORM,
  ],
}

export type EvaluationQuestionResponseOptionInput =
  | EvaluationQuestionResponseOptionMultipleSelect
  | EvaluationQuestionResponseOptionRating

export type EvaluationQuestionMeta = {
  responseOptions:
    | EvaluationQuestionResponseOptionRating
    | EvaluationQuestionResponseOptionMultipleSelect
}

// TODO add to openapi
export type UserEvaluationResponseInput = Omit<
  UserEvaluationResponse,
  'createdAt' | 'updatedAt' | 'id' | 'userEvaluationId'
> &
  Partial<Pick<UserEvaluationResponse, 'id'>>

export const EVAL_ID_SEARCH_PARAM_KEY = 'evalId'

export const REMOVE_PARAMS = [EVAL_ID_SEARCH_PARAM_KEY]

export const USER_PREFERENCE_RESPONSE_VALUE_TIE = 'TIE'
