import React, { useCallback, useEffect } from 'react'

import { Select } from 'antd'
import _ from 'lodash'
import pluralize from 'pluralize'

import { FetchAllPerms, Perm } from 'models/perms'
import { addPermsToRole, WorkspaceRole } from 'models/roles'
import {
  isInternalOnlyPerm,
  isRestrictedPerm,
  isSensitiveUserPerm,
  Permission,
} from 'models/user-info'

import { displayErrorMessage, displaySuccessMessage } from 'utils/toast'

import { useAuthUser } from 'components/common/auth-context'
import { Alert } from 'components/ui/alert'
import { Badge } from 'components/ui/badge'
import { Button } from 'components/ui/button'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogTitle,
} from 'components/ui/dialog'
import { ScrollArea } from 'components/ui/scroll-area'
import { Spinner } from 'components/ui/spinner'

interface RoleBulkAddPermsProps {
  role: WorkspaceRole
  currentPerms: Perm[]
  fetchRolePermsCallback: () => void
  roleUserCount: number
}

const RoleBulkAddPerms = ({
  role,
  currentPerms,
  fetchRolePermsCallback,
  roleUserCount,
}: RoleBulkAddPermsProps) => {
  const userInfo = useAuthUser()
  const [availablePerms, setAvailablePerms] = React.useState<Perm[]>([])
  const [selectedPerms, setSelectedPerms] = React.useState<string[]>([])
  const [showConfirmationDialog, setShowConfirmationDialog] =
    React.useState<boolean>(false)
  const [loading, setLoading] = React.useState<boolean>(false)
  const [numAddedPerms, setNumAddedPerms] = React.useState<number>(0)

  const fetchAllPerms = useCallback(async () => {
    const perms = await FetchAllPerms()
    const existingPermSet = new Set(currentPerms.map((perm) => perm.permId))
    const filteredPerms = perms.filter(
      (perm) => !existingPermSet.has(perm.permId)
    )
    if (!userInfo.IsInternalAdminWriter) {
      // test this
      _.remove(filteredPerms, (d) =>
        isSensitiveUserPerm(d.permId as Permission)
      )
    }

    // internal only perms to be added using migrations only
    _.remove(filteredPerms, (d) => isInternalOnlyPerm(d.permId as Permission))
    _.remove(filteredPerms, (d) => isRestrictedPerm(d.permId as Permission))

    setAvailablePerms(filteredPerms)
  }, [currentPerms, userInfo])

  useEffect(() => {
    fetchAllPerms()
  }, [fetchAllPerms])

  const permOptions = availablePerms.map((perm) => {
    return {
      label: `${perm.name} - ${perm.permId}`,
      value: perm.permId,
    }
  })

  const handlePermsSubmit = useCallback(async () => {
    if (selectedPerms.length === 0) {
      return
    }
    setLoading(true)
    const numAdded = await addPermsToRole(role.rolePk, selectedPerms)
    if (numAdded > 0) {
      fetchRolePermsCallback()
      displaySuccessMessage(
        `Added ${numAdded} ${pluralize('permissions', numAdded)} to role ${
          role.roleId
        }`,
        10
      )
      setNumAddedPerms(numAdded)
    } else {
      displayErrorMessage(
        `Failed to add permissions to role ${role.roleId}`,
        10
      )
    }
    setLoading(false)
  }, [selectedPerms, role, fetchRolePermsCallback])

  const onClear = () => {
    setSelectedPerms([])
    setNumAddedPerms(0)
    setLoading(false)
  }

  const numPermsToAdd = selectedPerms.length

  return (
    <div className="space-y-4 p-1">
      <div className="mt-2">
        <p>
          Select permissions from dropdown to add them to role {role.roleId}
        </p>
      </div>
      <div>
        <Select
          mode="multiple"
          placeholder={`Add permissions to ${role.roleId}`}
          virtual={false}
          style={{ width: '100%' }}
          options={_.sortBy(permOptions, 'label')}
          loading={_.isEmpty(permOptions)}
          optionFilterProp="label"
          onChange={(value) => setSelectedPerms(value)}
          value={selectedPerms}
        />
      </div>
      {numAddedPerms === 0 ? (
        <div className="flex justify-end">
          {loading ? (
            <Spinner />
          ) : (
            <Button
              onClick={() => setShowConfirmationDialog(true)}
              disabled={numPermsToAdd === 0}
            >
              {numPermsToAdd === 0
                ? 'Add permissions'
                : `Add ${numPermsToAdd} ${pluralize(
                    'permissions',
                    numPermsToAdd
                  )} to ${role.roleId}`}
            </Button>
          )}
        </div>
      ) : (
        <div className="flex justify-between">
          <span>
            <p>
              {numAddedPerms} permissions have been added to role {role.roleId}
            </p>
          </span>
          <Button variant="secondary" onClick={onClear}>
            Clear
          </Button>
        </div>
      )}
      <Dialog
        open={showConfirmationDialog}
        onOpenChange={() => setShowConfirmationDialog(!showConfirmationDialog)}
      >
        <DialogContent>
          <DialogTitle>
            {`Add ${numPermsToAdd} ${pluralize(
              'permissions',
              numPermsToAdd
            )} to role ${role.roleId}`}
          </DialogTitle>
          <DialogDescription>
            <div>
              {`This will grant ${numPermsToAdd} new ${pluralize(
                'permissions',
                numPermsToAdd
              )} to ${roleUserCount} ${pluralize('users', roleUserCount)}`}
            </div>
            <Alert className="mt-4 p-3" variant="success">
              {numPermsToAdd > 0 && (
                <div>
                  <p>Permissions to add:</p>
                  <ScrollArea className="max-h-64">
                    <div className="max-h-64">
                      {selectedPerms.map((permId, idx) => {
                        const perm = availablePerms.find(
                          (p) => p.permId === permId
                        )
                        return (
                          <Badge
                            key={idx}
                            variant="outline"
                            className="mr-1 mt-1"
                          >
                            {perm?.name} - {perm?.permId}
                          </Badge>
                        )
                      })}
                    </div>
                  </ScrollArea>
                </div>
              )}
            </Alert>
          </DialogDescription>
          <DialogFooter>
            <DialogClose asChild>
              <Button
                variant="outline"
                onClick={() => setShowConfirmationDialog(false)}
              >
                Cancel
              </Button>
            </DialogClose>
            <DialogClose asChild>
              <Button onClick={handlePermsSubmit}>
                {`Add ${numPermsToAdd} ${pluralize(
                  'permissions',
                  numPermsToAdd
                )}`}
              </Button>
            </DialogClose>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default RoleBulkAddPerms
