import React from 'react'

import { WorkflowType } from 'types/workflows'

import { cn } from 'utils/utils'

import { AppHeader } from 'components/common/app-header'
import { AppMain } from 'components/common/app-main'
import { ScrollArea } from 'components/ui/scroll-area'
import { getWorkflowSubtitle } from 'components/workflows/constants'

interface WorkflowLayoutProps {
  workflowType: WorkflowType
  childClassName?: string
  children: React.ReactNode
  title: string
  className?: string
  appHeaderActions?: React.ReactNode
  breadcrumb?: React.ReactNode
  renderChildrenDirectly?: boolean
}

const WorkflowLayout: React.FC<WorkflowLayoutProps> = ({
  workflowType,
  children,
  childClassName,
  className,
  appHeaderActions,
  title,
  renderChildrenDirectly,
}) => {
  return (
    <AppMain>
      <AppHeader
        title={title}
        subtitle={getWorkflowSubtitle(workflowType)}
        actions={
          <div className="flex grow justify-end space-x-2">
            {appHeaderActions}
          </div>
        }
      />
      {renderChildrenDirectly ? (
        children
      ) : (
        <ScrollArea className={cn('h-full', className)} isFullHeight>
          <div
            className={cn(
              childClassName,
              'container mx-auto flex h-full flex-col py-4'
            )}
          >
            {children}
          </div>
        </ScrollArea>
      )}
    </AppMain>
  )
}

export default WorkflowLayout
