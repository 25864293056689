import { useCallback, useEffect, useMemo, useState } from 'react'
import { useMount } from 'react-use'

import {
  useQueryClient,
  useInfiniteQuery,
  type QueryFunctionContext,
  InfiniteData,
  type QueryKey,
  GetPreviousPageParamFunction,
  QueryFunction,
} from '@tanstack/react-query'
import { GetNextPageParamFunction, QueryObserver } from '@tanstack/react-query'
import _ from 'lodash'

import { AdditionalQueryOptions } from 'models/queries/lib/core.types'
import Services from 'services'

import {
  deduplicateByLastModified,
  getLeftmostNonEmptySubarrayAndIndex,
  getRightmostNonEmptySubarrayAndIndex,
  evictOldData,
} from 'utils/array-utils'
import type { ArrayElement, DedupeFuncParams } from 'utils/array-utils'
import {
  findOldestDate,
  findOldestDateString, // findYoungestDate,
  findYoungestDateString,
  ISODateStringWithMicroseconds,
} from 'utils/date-utils'
import { awaitDelay, exponentialBackoffSeconds } from 'utils/delay'
import { addQueryParamsToRequestPath, combineSearchParams } from 'utils/routing'

import { defaultRetry } from './use-wrapped-query'

enum TimeDirectionEnum {
  NEWER = 'newer',
  OLDER = 'older',
}

type MyPageParam = null | {
  queryStringParams: URLSearchParams
  timeDirection: TimeDirectionEnum
  timestamp: Date
}

interface UsePollingAndOlderQueryParams<T extends ArrayElement> {
  cacheKeyPrefix: string
  url: string
  getArrayFromResults: (results: any) => T[]
  oldestDateRequested: Date
  modifiedKey?: string
  modifiedAfterKey?: string
  createdBeforeKey?: string
  idFunc?: (item: T) => any
  createdKey?: string
  deletedKey?: string
  dedupeFunc?: (params: DedupeFuncParams<T>) => T[]
  queryOptions?: AdditionalQueryOptions<{ pages: T[][] }>
  pollTimeInSeconds?: number
  batchSize?: number
  retentionPolicyInSeconds: number
  compactInternalDataStructure?: boolean
  additionalQueryStringParams?: URLSearchParams
  updateLastUpdatedTime?: () => void
}

interface GetOldestDateInCacheParams<T> {
  data: InfiniteData<T[], MyPageParam> | undefined
  getArrayFromResults?: (results: any) => T[]
  createdKey: string
}

/**
 * This function is used to operate on the pages, pageParams data structure
 * @returns the oldest date in the cache, or null if the cache is empty
 */
const getOldestDateInCache = <T>({
  data,
  createdKey,
}: GetOldestDateInCacheParams<T>) => {
  if (_.isNil(data) || _.isEmpty(data.pages)) {
    return null
  } else {
    const [lastPage] = getRightmostNonEmptySubarrayAndIndex(data.pages)
    const oldestDateInCache = _.isNil(lastPage)
      ? null
      : findOldestDate(lastPage, createdKey)
    return oldestDateInCache
  }
}

/**
 * This function fetches two-sided updates to a data source by using useInfinteQuery
 * So the data has a different shape in the query cache (be careful!)
 * It continually polls for new data, and also fetches older data up to the `oldestDate` parameter
 */
const usePollingAndOlderQuery = <T extends object>({
  cacheKeyPrefix,
  url,
  getArrayFromResults,
  oldestDateRequested,
  modifiedKey = 'modifiedAt',
  modifiedAfterKey = 'modified_after_exclusive',
  createdKey = 'createdAt',
  createdBeforeKey = 'created_before_exclusive',
  deletedKey = 'deletedAt',
  idFunc = (item: any) => item.id,
  dedupeFunc = deduplicateByLastModified,
  pollTimeInSeconds = 60 * 5, // 5 minutes default
  queryOptions = {},
  batchSize = 10,
  retentionPolicyInSeconds,
  compactInternalDataStructure = true,
  additionalQueryStringParams = new URLSearchParams(),
  updateLastUpdatedTime,
}: UsePollingAndOlderQueryParams<T>) => {
  // TODO(Adam): Deal with gaps: What if we get a recently modified event that is not currently in the cache? We need to _not_ add it to the overall data. But if we end up fetching that older event later, then we need to overwrite with the recently modified one.
  // .......can solve this by always knowing the oldest date in the cache that was fetched by null or 'older' direction?
  const retentionPolicy = retentionPolicyInSeconds * 1000 // get retention policy in milliseconds
  const searchParamsToAdd = combineSearchParams(
    additionalQueryStringParams,
    new URLSearchParams({ page_size: batchSize.toString() })
  )
  const requestPath = addQueryParamsToRequestPath(url, searchParamsToAdd)
  const queryClient = useQueryClient()
  const queryKey = [cacheKeyPrefix, requestPath]

  // Next page fetch is for OLDER data
  // eslint-disable-next-line max-params
  /* eslint-disable */
  const getOlderPageParam: GetNextPageParamFunction<MyPageParam, T[]> = (
    lastPage,
    pages,
    lastPageParam,
    allPageParams
  ) => {
    // Check if there are any more pages
    if (
      !_.isNil(lastPageParam) &&
      !_.isNil(lastPage) &&
      _.isEmpty(lastPage) &&
      !_.isEmpty(pages) &&
      lastPageParam.timeDirection === TimeDirectionEnum.OLDER
    ) {
      return undefined
    }

    const [_lastPage, idx] = getRightmostNonEmptySubarrayAndIndex(pages)
    const dataArray = _lastPage
    const params = allPageParams[idx]
    const isNewer =
      !_.isNil(params) && params.timeDirection === TimeDirectionEnum.NEWER

    const oldestDateString: ISODateStringWithMicroseconds | null = _.isNil(
      dataArray
    )
      ? ''
      : isNewer
      ? findOldestDateString(dataArray, modifiedKey)
      : findOldestDateString(dataArray, createdKey)

    const queryStringParams = !_.isNil(oldestDateString)
      ? new URLSearchParams({ [createdBeforeKey]: oldestDateString })
      : new URLSearchParams()

    return {
      queryStringParams,
      timeDirection: TimeDirectionEnum.OLDER,
      timestamp: new Date(),
    }
  }

  // Previous page fetch is for NEWEST data
  // eslint-disable-next-line max-params
  const getNewerPageParam: GetPreviousPageParamFunction<MyPageParam, T[]> = (
    firstPage,
    pages,
    firstPageParam,
    allPageParams
  ) => {
    const [_firstPage, idx] = getLeftmostNonEmptySubarrayAndIndex(pages)
    const dataArray = _firstPage
    const params = allPageParams[idx]
    const isNewer =
      !_.isNil(params) && params.timeDirection === TimeDirectionEnum.NEWER

    const newestDateString: ISODateStringWithMicroseconds | null = _.isNil(
      dataArray
    )
      ? ''
      : isNewer
      ? findYoungestDateString(dataArray, modifiedKey)
      : findYoungestDateString(dataArray, createdKey)

    const queryStringParams = !_.isNil(newestDateString)
      ? new URLSearchParams({
          [modifiedAfterKey]: newestDateString,
          include_deleted: 'true',
        })
      : new URLSearchParams()

    return {
      queryStringParams,
      timeDirection: TimeDirectionEnum.NEWER,
      timestamp: new Date(),
    }
  }

  // main function used to fetch the data
  const queryFn: QueryFunction<T[], QueryKey, MyPageParam> = async ({
    pageParam,
  }: QueryFunctionContext<QueryKey, MyPageParam>): Promise<T[]> => {
    const queryParams = _.get(pageParam, 'queryStringParams', null)
    const newRelativeURL = !_.isNil(queryParams)
      ? addQueryParamsToRequestPath(requestPath, queryParams)
      : requestPath
    const response = await Services.Backend.Get<T[]>(newRelativeURL, {
      throwOnError: true,
    })
    const dataArray = getArrayFromResults(response)

    updateLastUpdatedTime?.()

    return dataArray
  }

  // actual query being called
  const queryResult = useInfiniteQuery<
    T[],
    Error,
    InfiniteData<T[], unknown>,
    QueryKey,
    MyPageParam
  >({
    queryKey: queryKey,
    // @ts-ignore
    queryFn: queryFn,
    getNextPageParam: getOlderPageParam,
    getPreviousPageParam: getNewerPageParam,
    initialPageParam: null,
    staleTime: Infinity, // infinite stale time because we are not getting rid of the old data, just fetching new data
    retry: defaultRetry, // TODO: Create useWrappedInfiniteQuery
    ...queryOptions,
  })

  // Fetch the *new* data on mount
  useMount(() => {
    queryResult.fetchPreviousPage()
  })

  // use Effect to fetch new, fresh modified data
  const [paused, setPaused] = useState(false)
  useEffect(() => {
    if (paused) {
      return
    }

    // set up the interval to keep calling fetch previous page
    const intervalId = setInterval(() => {
      queryResult.fetchPreviousPage()
      if (compactInternalDataStructure) {
        updateQueryDataToCleanData()
      }
    }, pollTimeInSeconds * 1000)

    return () => {
      clearInterval(intervalId)
    }
  }, [queryResult.fetchPreviousPage, paused])

  const handleVisibilityChange = useCallback(() => {
    setPaused(document.hidden)
  }, [setPaused])

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange)
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [handleVisibilityChange])

  const { pause, resume } = useMemo(
    () => ({
      pause: () => setPaused(true),
      resume: () => setPaused(false),
    }),
    []
  )

  const [failures, setFailures] = useState(0)

  // use Effect to fetch all of the older pages we need to fetch
  useEffect(() => {
    // Check if we are not currently fetching the next page, and that there are still older pages to fetch

    const innerFunc = async () => {
      const timeDelay = exponentialBackoffSeconds(failures)

      if (failures >= 5) {
        // After 5 failures, stop trying to fetch older data
        return
      }

      // Delay some amount of time before trying again
      if (timeDelay > 0) {
        await awaitDelay(timeDelay)
      }

      if (!queryResult.isFetchingNextPage && queryResult.hasNextPage) {
        const data: InfiniteData<T[], MyPageParam> | undefined =
          queryClient.getQueryData(queryKey)
        const oldestDateSeen = getOldestDateInCache({
          data,
          getArrayFromResults,
          createdKey: createdKey,
        })
        if (_.isNil(oldestDateSeen)) {
          return // happens if there are no events in cache
        }
        const seenDate = new Date(oldestDateSeen) // Convert dates to comparable format, assuming they are in a compatible format or ISO strings
        const requestedDate = new Date(oldestDateRequested)
        if (seenDate > requestedDate) {
          const fetchResult = await queryResult.fetchNextPage() // fetch more pages until we get to the oldest date requested
          if (fetchResult.isError) {
            setFailures((prev) => prev + 1)
          } else {
            setFailures(0)
          }
        }
      }
    }

    innerFunc()
  }, [
    queryResult.data,
    oldestDateRequested,
    queryResult.isFetchingNextPage,
    failures,
  ])

  // workaround to clean up the query cache entry without interfering with an ongoing fetch
  const updateQueryDataToCleanData = async () => {
    /* workaround begins */
    const isSomePageFetching =
      queryClient.getQueryState(queryKey)?.fetchStatus === 'fetching'
    if (isSomePageFetching) {
      await new Promise<void>((resolve) => {
        const observer = new QueryObserver(queryClient, { queryKey })
        const unsubscribe = observer.subscribe(({ isFetching }) => {
          // this is going to run when there's any update to the query
          if (!isFetching) {
            unsubscribe()
            resolve()
          }
        })
      })
    }
    /* workaround ends */

    const queryData: InfiniteData<T[]> | null =
      queryClient.getQueryData(queryKey) || null
    const flatData = !_.isNil(queryData) ? (queryData.pages.flat() as T[]) : []

    const dedupeData = dedupeFunc({
      elements: flatData,
      modifiedKey: modifiedKey,
      getId: idFunc,
      createdKey: createdKey,
    })

    const now = new Date()
    const filteredData: T[] = evictOldData({
      data: dedupeData,
      retentionPolicy,
      currentTime: now,
      createdKey,
      deletedKey: deletedKey,
    })

    const result: { pages: T[][]; pageParams: MyPageParam[] } = {
      pages: [filteredData],
      pageParams: [null],
    }

    // If our last page is empty and an oldest page fetch, add it back to result
    const lastPage = !_.isNil(queryData) ? queryData.pages[-1] : null
    const lastPageParams: MyPageParam = !_.isNil(queryData)
      ? (queryData.pageParams[-1] as MyPageParam)
      : null
    const lastPageTimeDirection =
      !_.isNil(lastPageParams) && 'timeDirection' in lastPageParams
        ? lastPageParams.timeDirection
        : null
    if (
      !_.isNil(lastPage) &&
      !_.isNil(lastPageParams) &&
      _.isEmpty(lastPage) &&
      lastPageTimeDirection === TimeDirectionEnum.OLDER
    ) {
      result.pages.push(lastPage)
      result.pageParams.push(lastPageParams)
    }

    queryClient.setQueryData(queryKey, result)
  }

  // Custom logic to merge and sort pages into a single array, using mergingFunc
  const mergedData = useMemo(() => {
    const flatData = queryResult.data?.pages.flat()
    const dedupeData = dedupeFunc({
      elements: flatData || [],
      modifiedKey: modifiedKey,
      getId: idFunc,
      createdKey: createdKey,
    })
    const now = new Date()
    const filteredData = evictOldData({
      data: dedupeData,
      retentionPolicy,
      currentTime: now,
      createdKey,
      deletedKey: deletedKey,
    })
    return filteredData
  }, [queryResult.data?.pages, dedupeFunc, idFunc, createdKey])

  const fetchOnePoll = useCallback(queryResult.fetchPreviousPage, [
    queryResult.fetchPreviousPage,
  ])

  return {
    ...queryResult,
    fetchOnePoll: fetchOnePoll,
    data: mergedData,
    pausePolling: pause,
    resumePolling: resume,
    isFetchingOlder: queryResult.isFetchingNextPage,
    isFetchingNewer: queryResult.isFetchingPreviousPage,
  }
}

// exports
export { usePollingAndOlderQuery }
