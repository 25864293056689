import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { usePrevious } from 'react-use'

import {
  ColumnDef,
  Row,
  SortingState,
  Table,
  getCoreRowModel,
  getExpandedRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { HTTPError } from 'ky'
import _ from 'lodash'
import { File, Folder, FolderPlus } from 'lucide-react'
import pluralize from 'pluralize'
import { useShallow } from 'zustand/react/shallow'

import { VaultFolder } from 'openapi/models/VaultFolder'
import Services from 'services'
import { RequestError } from 'services/backend/backend'

import { displayErrorMessage } from 'utils/toast'

import { useAnalytics } from 'components/common/analytics/analytics-context'
import { useAuthUser } from 'components/common/auth-context'
import { Badge } from 'components/ui/badge'
import { Button } from 'components/ui/button'
import { DataTable } from 'components/ui/data-table/data-table'
import DataTableSortHeader from 'components/ui/data-table/data-table-sort-header'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogDescription,
  DialogTitle,
} from 'components/ui/dialog'
import Icon from 'components/ui/icon/icon'
import { Input } from 'components/ui/input'
import { ScrollArea } from 'components/ui/scroll-area'
import { Spinner } from 'components/ui/spinner'
import { VaultNameCell } from 'components/vault/components/file-explorer/vault-cells'
import { VaultItem, VaultItemType } from 'components/vault/utils/vault'
import {
  CreateVaultFolder,
  PatchFile,
  PatchFolder,
} from 'components/vault/utils/vault-fetcher'
import { useVaultFileExplorerStore } from 'components/vault/utils/vault-file-explorer-store'
import { folderAsItem } from 'components/vault/utils/vault-helpers'
import { useVaultStore } from 'components/vault/utils/vault-store'

const generateOptimisticId = (
  parentFolderId: string,
  folderName: string,
  editing: boolean
) => {
  if (editing) {
    return `optimistic-id-editing-${parentFolderId}-${folderName}`
  }
  return `optimistic-id-${parentFolderId}-${folderName}`
}

const VaultMoveDialog: React.FC = () => {
  const folderIdToVaultFolder = useVaultStore(
    useShallow((s) => s.folderIdToVaultFolder)
  )
  const parentIdToVaultFolderIds = useVaultStore(
    useShallow((s) => s.parentIdToVaultFolderIds)
  )
  const currentProject = useVaultStore(useShallow((s) => s.currentProject))
  const projectsMetadata = useVaultStore(useShallow((s) => s.projectsMetadata))
  const moveRecords = useVaultStore(useShallow((s) => s.moveRecords))
  const isMoveDialogOpen = useVaultStore((s) => s.isMoveDialogOpen)
  const setIsMoveDialogOpen = useVaultStore((s) => s.setIsMoveDialogOpen)
  const upsertVaultFolders = useVaultStore((s) => s.upsertVaultFolders)
  const upsertVaultFiles = useVaultStore((s) => s.upsertVaultFiles)
  const setRequiresProjectDataRefetch = useVaultStore(
    (s) => s.setRequiresProjectDataRefetch
  )
  const setSelectedRows = useVaultFileExplorerStore((s) => s.setSelectedRows)
  const { trackEvent } = useAnalytics()
  const userInfo = useAuthUser()

  const [isMoving, setIsMoving] = useState<boolean>(false)
  const [targetFolder, setTargetFolder] = useState<VaultFolder | null>(
    currentProject
  )
  const [isCreatingNewFolder, setIsCreatingNewFolder] = useState<boolean>(false)
  const [pendingNewFolder, setPendingNewFolder] = useState<VaultFolder | null>(
    null
  )
  const [createdFolders, setCreatedFolders] = useState<VaultFolder[]>([])

  const firstRecordType = moveRecords.length >= 1 ? moveRecords[0].type : 'item'
  const recordsType = moveRecords.every((r) => r.type === firstRecordType)
    ? firstRecordType
    : 'item'
  const recordsDisplayString = `${moveRecords.length.toLocaleString()} ${pluralize(
    recordsType,
    moveRecords.length
  )}`
  const dialogTitle = `Move ${pluralize(recordsType, moveRecords.length)}`
  const inputDescription = `Select a folder to move ${recordsDisplayString}`

  const onNewFolderClick = () => {
    if (!targetFolder || pendingNewFolder) return
    // Create a pending new folder with an empty name
    setPendingNewFolder({
      id: generateOptimisticId(targetFolder.id, '', true),
      name: '',
      parentId: targetFolder.id,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
    } as VaultFolder)
    // Focus on the input (with a zero delay to allow the pending new folder input to be created)
    setTimeout(() => {
      inputRef.current?.focus()
    }, 0)
  }

  const onCancel = () => {
    Services.HoneyComb.Record({
      metric: 'ui.vault_move_dialog_cancelled',
      record_ids: moveRecords.map((r) => r.id),
    })
    trackEvent('Vault Move Dialog Cancelled', {
      record_ids: moveRecords.map((r) => r.id),
    })
    // Flush the created folders to the store
    upsertVaultFolders(createdFolders, userInfo.dbId)
    setCreatedFolders([])
    // Reset the pending new folder state
    setPendingNewFolder(null)
    // Close the move dialog
    setIsMoveDialogOpen(false)
  }

  const onMoveSubmit = useCallback(async () => {
    if (!targetFolder) {
      return
    }

    setIsMoving(true)

    trackEvent('Vault Move Dialog Submitted', {
      record_ids: moveRecords.map((r) => r.id),
    })

    try {
      await Promise.all(
        moveRecords
          // No need to move if the target folder is already the parent folder
          .filter((record) => record.parentId !== targetFolder.id)
          .map((record) =>
            record.type === 'file'
              ? // Move the file to the target folder
                PatchFile(record.id, {
                  new_vault_folder_id: targetFolder.id,
                }).then((updatedFile) => upsertVaultFiles([updatedFile]))
              : // Move the folder to the target folder
                PatchFolder(record.id, { new_parent_id: targetFolder.id }).then(
                  (updatedFolder) =>
                    upsertVaultFolders([updatedFolder], userInfo.dbId)
                )
          )
      )
      // Refetch the project data because the move operation may have changed the project data
      setRequiresProjectDataRefetch(true)
      // Flush the created folders to the store
      upsertVaultFolders(createdFolders, userInfo.dbId)
      setCreatedFolders([])
      // Reset the pending new folder state
      setPendingNewFolder(null)
      // Close the move dialog
      setIsMoveDialogOpen(false)
      // Reset the selected rows in case the move operation is targeted for those rows
      setSelectedRows([])
    } catch (e) {
      if (e instanceof HTTPError && e.response.status === 409) {
        displayErrorMessage(
          `Failed to move ${recordsDisplayString} to folder “${
            targetFolder.name
          }”. ${_.upperFirst(
            recordsType
          )} with the same name already exists in the target folder`
        )
      } else if (e instanceof RequestError || e instanceof HTTPError) {
        displayErrorMessage(
          `Failed to move ${recordsDisplayString} to folder “${targetFolder.name}”`
        )
      }
    }
    setIsMoving(false)
  }, [
    targetFolder,
    trackEvent,
    moveRecords,
    setRequiresProjectDataRefetch,
    setIsMoveDialogOpen,
    setSelectedRows,
    upsertVaultFiles,
    upsertVaultFolders,
    createdFolders,
    recordsDisplayString,
    recordsType,
    userInfo.dbId,
  ])

  const inputRef = useRef<HTMLInputElement>(null)
  const handleCancelNewFolder = () => {
    // Reset the pending new folder state
    setPendingNewFolder(null)
  }
  const handleSaveNewFolder = useCallback(
    async (table: Table<VaultItem>) => {
      if (!targetFolder || !inputRef.current?.value.trim()) return

      const previouslySelectedTargetFolder = targetFolder
      const previouslySelectedRow = table.getSelectedRowModel().flatRows[0]

      setIsCreatingNewFolder(true)
      const newPendingFolderName = inputRef.current.value.trim()
      // Create a pending new folder with the new name that will display as a regular row in the table
      const pendingNewFolder = {
        id: generateOptimisticId(targetFolder.id, newPendingFolderName, false),
        name: newPendingFolderName,
        parentId: targetFolder.id,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
      } as VaultFolder
      setPendingNewFolder(pendingNewFolder)
      setTargetFolder(pendingNewFolder)

      const pendingNewFolderRow = table.getRowModel().flatRows.find((row) =>
        // check for optimistic folder id that is being edited
        // only one folder can be edited at a time so it is guaranteed to be unique
        row.original.id.startsWith('optimistic-id-editing')
      )
      if (pendingNewFolderRow) {
        table.setRowSelection({
          [pendingNewFolderRow.id]: true,
        })
      }

      try {
        const newFolder = await CreateVaultFolder(
          newPendingFolderName,
          targetFolder.id
        )
        // Reset the pending new folder state
        setPendingNewFolder(null)
        setTargetFolder(newFolder)
        // Add the new folder to the created folders
        setCreatedFolders((prev) => [...prev, newFolder])
      } catch (e) {
        // Reset the editing state if the folder creation fails, so it reverts back to an input
        setPendingNewFolder({
          id: generateOptimisticId(targetFolder.id, newPendingFolderName, true),
          name: newPendingFolderName,
          parentId: targetFolder.id,
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
        } as VaultFolder)
        setTargetFolder(previouslySelectedTargetFolder)
        if (previouslySelectedRow) {
          table.setRowSelection({
            [previouslySelectedRow.id]: true,
          })
        }
        displayErrorMessage('Failed to create new folder')
      }
      setIsCreatingNewFolder(false)
    },
    [targetFolder]
  )

  const columns: ColumnDef<VaultItem>[] = [
    {
      header: ({ column }) => (
        <DataTableSortHeader column={column} header="Name" />
      ),
      id: 'name',
      accessorKey: 'name',
      cell: ({ row }) =>
        // If the row is a pending new folder in editing state, display an input
        row.original.id.startsWith('optimistic-id-editing-')
          ? NewFolderNameInputCell({
              row,
              inputRef,
              handleCancelNewFolder,
              handleSaveNewFolder: () => handleSaveNewFolder(table),
            })
          : VaultNameCell({ row }),
      enableSorting: true,
      size: 280,
    },
  ]

  const data = useMemo(() => {
    if (!currentProject) {
      return []
    }
    const newFolders = pendingNewFolder
      ? [...createdFolders, pendingNewFolder]
      : createdFolders
    const updatedFolderIdToVaultFolder = { ...folderIdToVaultFolder }
    newFolders.forEach((folder) => {
      updatedFolderIdToVaultFolder[folder.id] = folder
    })
    const updatedParentIdToVaultFolderIds = { ...parentIdToVaultFolderIds }
    newFolders.forEach((folder) => {
      if (!folder.parentId) return
      updatedParentIdToVaultFolderIds[folder.parentId] ??= []
      if (
        updatedParentIdToVaultFolderIds[folder.parentId]!.includes(folder.id)
      ) {
        return
      }
      updatedParentIdToVaultFolderIds[folder.parentId] = [
        folder.id,
        ...updatedParentIdToVaultFolderIds[folder.parentId]!,
      ]
    })
    const allRows = [
      folderAsItem({
        folder: currentProject,
        projectsMetadata,
        shouldExpand: true,
        folderIdToVaultFolder: updatedFolderIdToVaultFolder,
        parentIdToVaultFolderIds: updatedParentIdToVaultFolderIds,
      }),
    ]
    // Update each row to be disabled if it is invalid
    const updateDisabledStatus = (
      items: VaultItem[],
      moveRecords: VaultItem[]
    ) => {
      const isInvalidTarget = (item: VaultItem): boolean => {
        if (item.type === VaultItemType.file) {
          return false
        }
        const isSelf = moveRecords.some(
          (record) => record.type === item.type && record.id === item.id
        )
        const isAncestor = (
          item: VaultFolder,
          records: VaultItem[]
        ): boolean => {
          if (!item.parentId) return false
          if (records.some((record) => record.id === item.parentId)) return true
          const parentItem = folderIdToVaultFolder[item.parentId]
          return parentItem ? isAncestor(parentItem, records) : false
        }
        // If any record to be moved is the target folder itself or an ancestor of the target folder, disable it.
        // Otherwise we might have circular dependencies after moving.
        return isSelf || isAncestor(item.data, moveRecords)
      }

      items.forEach((item) => {
        if (isInvalidTarget(item)) {
          item.disabled = true
        }
        // Recursively update the disabled status for each child
        if (item.type !== VaultItemType.file && item.children) {
          updateDisabledStatus(item.children, moveRecords)
        }
      })
    }

    updateDisabledStatus(allRows, moveRecords)
    return allRows
  }, [
    currentProject,
    projectsMetadata,
    folderIdToVaultFolder,
    parentIdToVaultFolderIds,
    moveRecords,
    pendingNewFolder,
    createdFolders,
  ])

  const [sorting, setSorting] = useState<SortingState>([])
  const table = useReactTable({
    data,
    columns,
    getSubRows: (row) =>
      row.type !== VaultItemType.file ? row.children : undefined,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    onSortingChange: setSorting,
    state: {
      sorting,
    },
    enableSorting: true,
    enableSortingRemoval: true,
    enableMultiRowSelection: false,
  })

  const onRowClick = (row: Row<VaultItem>) => {
    if (row.original.type === VaultItemType.file) {
      // This should not happen
      return
    }
    // If the row is a pending new folder in editing state, focus on the input
    if (row.original.id.startsWith('optimistic-id-editing-')) {
      inputRef.current?.focus()
      return
    }
    // Toggle the row selection
    const isRowSelected = row.getIsSelected()
    table.setRowSelection({
      [row.id]: !isRowSelected,
    })
    // If deselecting a row, set the target folder to the current project so we can
    // create new folder from the current project
    setTargetFolder(isRowSelected ? currentProject : row.original.data)
  }

  const prevIsMoveDialogOpen = usePrevious(isMoveDialogOpen)
  useEffect(() => {
    if (isMoveDialogOpen && !prevIsMoveDialogOpen) {
      // Expand all rows when the move dialog opens
      table.toggleAllRowsExpanded(true)
    } else if (!isMoveDialogOpen && prevIsMoveDialogOpen) {
      // Deselect all rows when the move dialog closes
      table.toggleAllRowsSelected(false)
      // Reset the target folder to the current project
      setTargetFolder(currentProject)
    }
  }, [table, isMoveDialogOpen, prevIsMoveDialogOpen, currentProject])

  const isTargetFolderDisabled = useMemo(() => {
    if (!targetFolder) return null
    // Find the vault item that matches the target folder
    const findVaultItem = (
      id: string,
      vaultItems: VaultItem[]
    ): VaultItem | null => {
      for (const vaultItem of vaultItems) {
        if (vaultItem.id === id) {
          return vaultItem
        } else if (
          vaultItem.type !== VaultItemType.file &&
          vaultItem.children
        ) {
          const item = findVaultItem(id, vaultItem.children)
          if (item) {
            return item
          }
        }
      }
      return null
    }
    const targetVaultItem = findVaultItem(targetFolder.id, data)
    return targetVaultItem?.disabled
  }, [targetFolder, data])

  const shouldDisableMoveButton = useMemo(() => {
    if (isMoving) {
      // We want to disable the move button while moving
      return true
    }
    if (
      !targetFolder ||
      (targetFolder === currentProject &&
        table
          .getSelectedRowModel()
          .rows.every((row) => row.original.id !== targetFolder.id))
    ) {
      // If the target folder is the current project and no rows are selected, disable the move button
      return true
    }
    if (isTargetFolderDisabled) {
      // If the target vault item is disabled, disable the move button
      return true
    }
    // If there is a pending new folder, disable the move button
    return !!pendingNewFolder
  }, [
    isMoving,
    targetFolder,
    isTargetFolderDisabled,
    pendingNewFolder,
    table,
    currentProject,
  ])

  return (
    <Dialog open={isMoveDialogOpen}>
      <DialogContent showCloseIcon={false}>
        <DialogHeader>
          <DialogTitle>{dialogTitle}</DialogTitle>
          <DialogDescription>
            <div className="flex items-center">
              <span className="mr-1 shrink-0">{inputDescription}</span>
              {moveRecords.length === 1 && (
                <Badge variant="secondary" className="shrink space-x-1 px-2">
                  <Icon icon={moveRecords[0].type === 'file' ? File : Folder} />
                  <span className="truncate">{moveRecords[0].data.name}</span>
                </Badge>
              )}
            </div>
          </DialogDescription>
        </DialogHeader>
        <ScrollArea maxHeight="max-h-96">
          <DataTable
            table={table}
            className="rounded-none"
            onRowClick={onRowClick}
            hideTableBorder
            tableCellClassName="px-1 py-2.5 font-normal"
          />
        </ScrollArea>
        <div className="mt-6 flex justify-between gap-2">
          <Button
            variant="secondary"
            disabled={
              isCreatingNewFolder || !targetFolder || !!pendingNewFolder
            }
            onClick={onNewFolderClick}
            data-testid="vault-move-dialog--create-new-folder-button"
          >
            {isCreatingNewFolder ? (
              <div className="flex items-center">
                <Spinner className="top-3 mr-2 h-3 w-3" />
                <p>Creating new folder…</p>
              </div>
            ) : (
              <>
                <Icon icon={FolderPlus} className="mr-1" />
                <p>New folder</p>
              </>
            )}
          </Button>
          <div className="flex items-center space-x-2">
            <Button variant="ghost" onClick={onCancel}>
              Cancel
            </Button>
            <Button
              disabled={shouldDisableMoveButton}
              onClick={onMoveSubmit}
              data-testid="vault-move-dialog--move-button"
            >
              {isMoving ? (
                <div className="flex items-center">
                  <Spinner className="top-3 mr-2 h-3 w-3" />
                  <p>Moving…</p>
                </div>
              ) : (
                <p>Move {recordsDisplayString}</p>
              )}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}

const NewFolderNameInputCell: React.FC<{
  row: Row<VaultItem>
  inputRef: React.RefObject<HTMLInputElement>
  handleCancelNewFolder: () => void
  handleSaveNewFolder: () => void
}> = ({ row, inputRef, handleCancelNewFolder, handleSaveNewFolder }) => {
  return (
    <div
      className="flex items-center rounded border bg-primary px-2 py-0.5 shadow-sm transition hover:bg-primary"
      style={{ marginLeft: `${row.depth * 16 + 10}px` }}
    >
      <FolderNameInput
        inputRef={inputRef}
        initialValue={row.original.data.name}
      />
      <div className="flex shrink-0">
        <Button size="sm" variant="ghost" onClick={handleCancelNewFolder}>
          Cancel
        </Button>
        <Button
          size="sm"
          variant="ghost"
          onClick={handleSaveNewFolder}
          className="font-semibold"
          data-testid="vault-move-dialog--save-new-folder-button"
        >
          Save
        </Button>
      </div>
    </div>
  )
}

const FolderNameInput: React.FC<{
  inputRef: React.RefObject<HTMLInputElement>
  initialValue: string
}> = ({ inputRef, initialValue }) => {
  const [pendingNewFolderName, setPendingNewFolderName] =
    useState<string>(initialValue)
  return (
    <Input
      ref={inputRef}
      placeholder="Enter new folder name"
      value={pendingNewFolderName}
      onChange={(e) => {
        setPendingNewFolderName(e.target.value)
      }}
      className="h-8 border-none p-2 focus-within:ring-transparent focus:ring-transparent focus-visible:ring-transparent"
      data-testid="vault-move-dialog--new-folder-name-input"
    />
  )
}

export default VaultMoveDialog
