import React, { useEffect, useRef, useState } from 'react'

import { useAuth0 } from '@auth0/auth0-react'

import { getDisallowedClientMatters } from 'models/client-matters'
import { WelcomeScreenAction } from 'models/user-settings'
import { WelcomeScreen } from 'models/workspace'
import Services from 'services'
import type TelemtryEvent from 'services/honey-comb/telemetry-event'
import { Maybe } from 'types'

import { ClientMatter } from 'components/client-matters/client-matters-store'
import { useAuthUser } from 'components/common/auth-context'
import { Button } from 'components/ui/button'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from 'components/ui/dialog'
import SearchInput from 'components/ui/search-input'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from 'components/ui/table'

const ClientMattersDisallowedInterstitial = () => {
  const { user } = useAuth0()
  const telEventRef = useRef<TelemtryEvent | null>(null)

  const userInfo = useAuthUser()
  const [interstitial, setInterstitial] = useState<WelcomeScreen | undefined>(
    userInfo.GetDisallowedClientMatterInterstitial()
  )
  const [disallowedClientMatters, setDisallowedClientMatters] =
    useState<Maybe<ClientMatter[]>>(undefined)
  const [filteredDisallowedClientMatters, setFilteredDisallowedClientMatters] =
    useState<Maybe<ClientMatter[]>>(undefined)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (!interstitial) return

    telEventRef.current = Services.HoneyComb.Start({
      metric: 'ui.show_welcome_screen',
      welcome_screen_id: interstitial.id,
      welcome_screen_type: interstitial.type,
      task_type: interstitial.taskType,
    })
  }, [interstitial])

  useEffect(() => {
    if (!userInfo.isDisallowClientMattersUser) {
      setInterstitial(undefined)
      return
    }
    const fetchDisallowedClientMatters = async () => {
      setIsLoading(true)
      try {
        const beginTime = Date.now()
        const disallowedClientMatters = await getDisallowedClientMatters(
          userInfo.workspace.id
        )
        setDisallowedClientMatters(disallowedClientMatters)
        setFilteredDisallowedClientMatters(disallowedClientMatters)
        const endTime = Date.now()
        Services.HoneyComb.Record({
          metric: 'ui.fetch_disallowed_client_matters',
          disallowed_client_matters: disallowedClientMatters,
          duration: (endTime - beginTime) * 1000,
          workspace_id: userInfo.workspace.id,
        })
      } catch (error) {
        console.error('Error fetching disallowed client matters', error)
      } finally {
        setIsLoading(false)
      }
    }
    fetchDisallowedClientMatters()
  }, [userInfo.isDisallowClientMattersUser, userInfo.workspace.id])

  const [searchValue, setSearchValue] = useState('')

  if (!userInfo.isDisallowClientMattersUser || !interstitial) return

  const onOk = async (): Promise<void> => {
    if (!user || !interstitial?.id) {
      return
    }

    telEventRef.current?.Finish({
      action: WelcomeScreenAction.ACCEPTED,
      type: interstitial?.type,
    })
    telEventRef.current = null
    setInterstitial(undefined)
  }
  const onSearchValueChange = (value: string) => {
    value = value.trim()
    setSearchValue(value)
    if (value === '') {
      setFilteredDisallowedClientMatters(disallowedClientMatters)
      return
    }
    value = value.toLowerCase()
    const filteredDisallowedClientMatters = disallowedClientMatters?.filter(
      (clientMatter) =>
        clientMatter.name.toLowerCase().includes(value) ||
        clientMatter.description?.toLowerCase().includes(value)
    )

    setFilteredDisallowedClientMatters(filteredDisallowedClientMatters)
  }

  return (
    <Dialog open>
      <DialogContent showCloseIcon={false}>
        <DialogHeader>
          <DialogTitle>
            {interstitial.title || 'Some client matters are not allowed'}
          </DialogTitle>
        </DialogHeader>
        <div
          className="flex flex-col gap-4"
          dangerouslySetInnerHTML={{ __html: interstitial.content }}
        />

        <div>
          <div className="mt-4">
            <div className="mb-2 flex flex-row items-center justify-between">
              <p className="font-semibold">List of disallowed client matters</p>
              <SearchInput
                value={searchValue}
                setValue={onSearchValueChange}
                handleClear={() => {
                  onSearchValueChange('')
                }}
              />
            </div>
            <div className="max-h-80 min-h-80 overflow-hidden overflow-y-auto rounded-lg border">
              <Table className="w-full ">
                <TableHeader>
                  <TableRow>
                    <TableHead className="px-4 py-2 text-left font-semibold">
                      Client Matter #
                    </TableHead>
                    <TableHead className="px-4 py-2 text-left font-semibold">
                      Description
                    </TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {isLoading && (
                    <TableRow>
                      <TableCell colSpan={2} className="py-4 text-center">
                        Loading...
                      </TableCell>
                    </TableRow>
                  )}
                  {filteredDisallowedClientMatters?.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={2} className="py-4 text-center">
                        No client matters found
                      </TableCell>
                    </TableRow>
                  ) : (
                    filteredDisallowedClientMatters?.map(
                      (clientMatter, index) => (
                        <TableRow key={index}>
                          <TableCell className="border-t px-4 py-2">
                            {clientMatter.name}
                          </TableCell>
                          <TableCell className="border-t px-4 py-2">
                            {clientMatter.description}
                          </TableCell>
                        </TableRow>
                      )
                    )
                  )}
                </TableBody>
              </Table>
            </div>
          </div>
        </div>

        <DialogFooter>
          <DialogClose asChild>
            <Button onClick={onOk} disabled={isLoading}>
              Accept
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default ClientMattersDisallowedInterstitial
