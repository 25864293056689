import React from 'react'

import HarveyLogo from './harvey-logo'

const LoadingScreen: React.FC = () => {
  return (
    <div className="fixed flex h-screen w-screen items-center justify-center bg-secondary">
      <div className="relative h-24 w-24 overflow-hidden rounded-md">
        <div className="absolute inset-0 flex h-32 w-32 animate-spin items-end justify-end">
          {/* params to play with to change spin effect are width and negative margin of the below element */}
          <div className="-m-8 h-24 w-24 rounded-full bg-interactive blur-lg" />
        </div>
        <div className="absolute inset-0 h-24 w-24 bg-button-secondary-hover/50 p-0.5">
          <div className="flex h-full w-full items-center justify-center rounded bg-button-secondary text-[56px]">
            <HarveyLogo className="w-12" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default LoadingScreen
