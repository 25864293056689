import saveAs from 'file-saver'
import JSZip from 'jszip'

import { createFileName, sanitizeFileName } from './file-utils'

export const downloadUploadedFiles = async ({
  uploadedFiles,
  zippedFileName,
  shouldZipSingleFile = false,
}: {
  uploadedFiles: { id: string; name: string; url: string }[]
  zippedFileName?: string
  shouldZipSingleFile?: boolean
}) => {
  if (uploadedFiles.length === 1 && !shouldZipSingleFile) {
    const file = uploadedFiles[0]
    const response = await fetch(file.url)
    const blob = await response.blob()
    saveAs(blob, file.name)
  } else if (uploadedFiles.length > 0) {
    const zip = new JSZip()
    const namesForExistingFiles: string[] = []
    const documentIdToBlob: Record<string, Blob> = {}
    await Promise.all(
      uploadedFiles.map(async (file) => {
        const response = await fetch(file.url)
        const blob = await response.blob()
        documentIdToBlob[file.id] = blob
      })
    )
    const existingNamesSet = new Set(namesForExistingFiles)
    uploadedFiles.forEach((document) => {
      const fileName = createFileName(document.name, existingNamesSet)
      namesForExistingFiles.push(fileName)
      zip.file(fileName, documentIdToBlob[document.id])
    })
    const zipName = zippedFileName
      ? `${sanitizeFileName(zippedFileName)}.zip`
      : 'downloaded_files.zip'
    const generatedZip = await zip.generateAsync({ type: 'blob' })
    saveAs(generatedZip, zipName)
  }
}
