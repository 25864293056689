import { UploadedFile } from 'openapi/models/UploadedFile'
import Services from 'services'

interface requestProps {
  redlinesCheck?: boolean
  notRedlinesCheck?: boolean
  languageNotSupportedCheck?: boolean
  eventId?: string
  signal?: AbortSignal
}

export const uploadFile = async (
  file: File,
  throwOnError = false,
  requestProps?: requestProps
): Promise<UploadedFile> => {
  const formData = new FormData()
  formData.append('file', file)

  const redlinesCheck = requestProps?.redlinesCheck ?? false
  const notRedlinesCheck = requestProps?.notRedlinesCheck ?? false

  if (redlinesCheck) {
    formData.append('redlines_check', 'true')
  }

  if (notRedlinesCheck) {
    formData.append('not_redlines_check', 'true')
  }

  if (requestProps?.languageNotSupportedCheck) {
    formData.append('language_not_supported_check', 'true')
  }

  if (requestProps?.eventId) {
    formData.append('event_id', requestProps.eventId)
  }

  const result = await Services.Backend.Post<UploadedFile>(
    'upload_file',
    formData,
    {
      signal: requestProps?.signal,
      throwOnError,
    }
  )

  if (Object.keys(result).length === 0 && !requestProps?.signal?.aborted) {
    throw new Error('Empty response from upload file')
  }

  return result
}
