import React, { useState } from 'react'

import { useShallow } from 'zustand/react/shallow'

import { displayErrorMessage, displaySuccessMessage } from 'utils/toast'

import { Button } from 'components/ui/button'
import {
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogContent,
  Dialog,
  DialogFooter,
} from 'components/ui/dialog'
import { UnpublishWorkflow } from 'components/vault/utils/vault-fetcher'
import useVaultWorkflowStore, {
  WorkflowModalState,
} from 'components/vault/workflows/vault-workflow-store'

const VaultUnpublishWorkflow: React.FC = () => {
  const [isUnpublishing, setIsUnpublishing] = useState(false)
  const [
    workflowModalState,
    setWorkflowModalState,
    selectedWorkflow,
    setWorkflow,
  ] = useVaultWorkflowStore(
    useShallow((state) => [
      state.workflowModalState,
      state.setWorkflowModalState,
      state.selectedWorkflow,
      state.setWorkflow,
    ])
  )

  const handleUnpublish = async () => {
    if (!selectedWorkflow) return

    setIsUnpublishing(true)
    try {
      const response = await UnpublishWorkflow(selectedWorkflow.id)
      displaySuccessMessage('Workflow unpublished successfully')
      setWorkflow(response.workflow)
      setWorkflowModalState(WorkflowModalState.Selector)
    } catch (error) {
      displayErrorMessage('Failed to unpublish workflow')
    }
    setIsUnpublishing(false)
  }

  const handleOpenChange = (open: boolean) => {
    if (!open) {
      setWorkflowModalState(WorkflowModalState.Selector)
    }
  }

  const isUnpublishWorkflowOpen =
    workflowModalState === WorkflowModalState.Unpublish

  return (
    <Dialog open={isUnpublishWorkflowOpen} onOpenChange={handleOpenChange}>
      <DialogContent className="bg-secondary sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Unpublish Workflow</DialogTitle>
          <DialogDescription>
            Are you sure you want to unpublish this workflow? It will no longer
            be available to other users.
          </DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <Button
            variant="outline"
            onClick={() => setWorkflowModalState(WorkflowModalState.Selector)}
          >
            Cancel
          </Button>
          <Button onClick={handleUnpublish} isLoading={isUnpublishing}>
            Unpublish
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default VaultUnpublishWorkflow
