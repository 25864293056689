import { Node as ASTNode, Text } from 'mdast'

import * as Types from './types'

export const extractText = (node: ASTNode): string => {
  if (node.type === 'text') return (node as Text).value

  if (Types.isParent(node)) {
    return node.children.map((child: ASTNode) => extractText(child)).join('')
  }
  return ''
}
