import React from 'react'
import { Navigate } from 'react-router-dom'

import { UserInfo } from 'models/user-info'

import { createRouteComponent } from 'utils/routing'

import ContractsFileExplorer from './file-explorer'
import ContractsFileViewer from './file-viewer'

export enum ContractsPages {
  FILE_EXPLORER = '/workflows/contracts',
  // XXX: ":filename" is replaced with the actual filename, don't change it
  // without updating that replace. Same for ":witnessname"
  FILE_VIEW = '/workflows/contracts/documents/:filename',
  DOCUMENTS = '/workflows/contracts/documents',
}

export const getContractsPageRoutes = (userInfo: UserInfo) => {
  return [
    createRouteComponent({
      path: ContractsPages.FILE_EXPLORER,
      hasPerms: userInfo.isContractsUser,
      component: ContractsFileExplorer,
    }),
    createRouteComponent({
      path: ContractsPages.FILE_VIEW,
      hasPerms: userInfo.isContractsUser,
      component: ContractsFileViewer,
    }),

    // Unimplemented (redirect) routes:
    createRouteComponent({
      path: ContractsPages.DOCUMENTS,
      hasPerms: userInfo.isContractsUser,
      component: () => <Navigate to={ContractsPages.FILE_EXPLORER} replace />,
    }),
  ]
}
