import React, { ChangeEvent, useState } from 'react'

import emailAddresses from 'email-addresses'

import { useNavigateWithQueryParams } from 'hooks/use-navigate-with-query-params'

import { Button } from 'components/ui/button'
import { Card, CardContent, CardHeader } from 'components/ui/card'
import { Input } from 'components/ui/input'

const SettingsUserPermissionsCard = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [inputValue, setInputValue] = useState('')
  const navigate = useNavigateWithQueryParams()

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value)
    setError('')
  }

  const handleSubmitEmail = async (): Promise<void> => {
    setLoading(true)
    const email = inputValue
    const emailValid = emailAddresses.parseOneAddress(email)
    if (!emailValid) {
      setError('Please enter a valid email address')
      setLoading(false)
      return
    }
    navigate(`/settings/internal_admin/user-inspector`, {
      state: { email },
    })
    setLoading(false)
  }

  return (
    <Card>
      <CardHeader>
        <h2 className="text-lg font-semibold">User inspector</h2>
      </CardHeader>
      <CardContent>
        <form className="flex space-x-2.5" onSubmit={handleSubmitEmail}>
          <p className="inline-flex h-8 items-center">User email:</p>
          <div>
            <Input
              className="h-8 w-48"
              value={inputValue}
              onChange={handleChange}
            />
            {error && <span className="text-xs">{error}</span>}
          </div>
          <Button
            className="h-8"
            type="submit"
            disabled={loading || inputValue.trim().length === 0}
          >
            Inspect
          </Button>
        </form>
      </CardContent>
    </Card>
  )
}

export default SettingsUserPermissionsCard
