import React, { useEffect, useState } from 'react'

import { Loader2, Trash2 } from 'lucide-react'

import { Experiment } from 'openapi/models/Experiment'

import { getTableDateString } from 'utils/date-utils'
import { displayErrorMessage } from 'utils/toast'

import { SettingsPath } from 'components/base-app-path'
import ConfirmationDialog from 'components/common/confirmation-dialog/confirmation-dialog'
import BasicTransition from 'components/ui/basic-transition'
import { Button } from 'components/ui/button'
import { Card, CardContent, CardHeader, CardTitle } from 'components/ui/card'
import { Dialog, DialogTrigger } from 'components/ui/dialog'
import Icon from 'components/ui/icon/icon'
import { ScrollArea } from 'components/ui/scroll-area'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from 'components/ui/table'

import {
  DeleteExperiment,
  FetchAllExperiments,
} from './utils/experiment-fetcher'

const ExperimentsOverviewCard = () => {
  const [isLoading, setIsLoading] = useState(true)

  const [isLoadingExperiments, setIsLoadingExperiments] = useState<string[]>([])

  const [experiments, setExperiments] = useState<Experiment[]>([])

  const fetchAllExperiments = async () => {
    setIsLoading(true)
    try {
      const experiments = await FetchAllExperiments()
      setExperiments(experiments)
    } catch (error) {
      displayErrorMessage(`Failed to fetch experiments: ${error}`)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    void fetchAllExperiments() // NOTE can move this into hook in future
  }, [])

  const onDeleteExperiment = async (experiment: Experiment) => {
    setIsLoadingExperiments([...isLoadingExperiments, experiment.slug])
    try {
      await DeleteExperiment(experiment.id)
      setExperiments(experiments.filter((e) => e.slug !== experiment.slug))
    } catch (error) {
      displayErrorMessage(`Failed to delete experiment: ${error}`)
    } finally {
      setIsLoadingExperiments(
        isLoadingExperiments.filter((e) => e !== experiment.slug)
      )
    }
  }

  return (
    <Card className="mt-6">
      <CardHeader>
        <CardTitle>Experiments overview table</CardTitle>
      </CardHeader>
      <CardContent>
        <BasicTransition show={!isLoading}>
          <ScrollArea hasHorizontalScroll className="h-[500px] w-full">
            {/* NOTE: can be replaced with data table */}
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Name</TableHead>
                  <TableHead>Slug</TableHead>
                  <TableHead>Created At</TableHead>
                  <TableHead>Actions</TableHead>
                  {/* NOTE can add other columns like # of users, created_by, etc */}
                </TableRow>
              </TableHeader>
              <TableBody>
                {experiments.map((experiment) => (
                  <TableRow
                    key={experiment.id}
                    className="cursor-pointer"
                    onClick={() => {
                      window.open(
                        `${SettingsPath.InternalAdminExperimentManagement}/${experiment.slug}`,
                        '_blank'
                      )
                    }}
                  >
                    <TableCell>{experiment.name}</TableCell>
                    <TableCell>{experiment.slug}</TableCell>
                    <TableCell>
                      {getTableDateString(experiment.createdAt)}
                    </TableCell>
                    <TableCell
                      onClick={(e) => e.stopPropagation()}
                      className="cursor-default"
                    >
                      <Dialog>
                        <DialogTrigger
                          disabled={isLoadingExperiments.includes(
                            experiment.slug
                          )}
                        >
                          <Button
                            variant="outline"
                            size="icon"
                            disabled={isLoadingExperiments.includes(
                              experiment.slug
                            )}
                          >
                            {isLoadingExperiments.includes(experiment.slug) ? (
                              <Icon
                                icon={Loader2}
                                size="small"
                                className="stroke-destructive"
                              />
                            ) : (
                              <Icon
                                icon={Trash2}
                                size="small"
                                className="stroke-destructive"
                              />
                            )}
                          </Button>
                        </DialogTrigger>
                        <ConfirmationDialog
                          title={`Delete experiment: ${experiment.name}`}
                          description="Are you sure you want to delete this experiment?"
                          cta={{
                            label: 'Delete',
                            onClick: () => onDeleteExperiment(experiment),
                          }}
                          secondaryCta={{ label: 'Cancel', onClick: () => {} }}
                          variant="destructive"
                          showCloseIcon={false}
                        />
                      </Dialog>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </ScrollArea>
        </BasicTransition>
      </CardContent>
    </Card>
  )
}

export default ExperimentsOverviewCard
