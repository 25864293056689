import { GridApi } from 'ag-grid-community'
import { formatDuration } from 'date-fns'

import { parseIsoDurationString, durationToSeconds } from 'utils/utils'

import {
  ColumnDataType,
  ReviewAnswer,
  ReviewCellResponse,
} from 'components/vault/utils/vault'
import { FilterType } from 'components/vault/utils/vault-data-grid-filters-store'

import { ColumnFilter } from './column-filter-types'
import { TextColumnFilter } from './text-column-filter'

export class DurationColumnFilter extends ColumnFilter {
  getUniqueColumnValues(
    gridApi: GridApi,
    currentFilterQuestionId: string
  ): string[] {
    const uniqueDurationsSet = new Set<string>()
    let hasDataTypeMismatch = false
    gridApi.forEachNode((node) => {
      if (node.group) return
      const answer = (node.data.answers as ReviewAnswer[]).find(
        (answer) => answer.columnId === currentFilterQuestionId
      )

      if (answer != null) {
        if (answer.columnDataType === ColumnDataType.duration) {
          // We index at 0 because we know the response is always a single duration value
          const response = answer.rawResponse?.[0] as ReviewCellResponse
          const duration = response.value || ''
          const durationIsoString = parseIsoDurationString(duration)
          if (duration === '' || Object.keys(durationIsoString).length === 0) {
            // If the duration is invalid, skip it
            return
          }
          uniqueDurationsSet.add(duration)
        } else {
          hasDataTypeMismatch = true
        }
      }
    })

    // if any answers are non-duration column type, use text column filter instead
    if (hasDataTypeMismatch) {
      return new TextColumnFilter().getUniqueColumnValues(
        gridApi,
        currentFilterQuestionId
      )
    }

    return Array.from(uniqueDurationsSet)
      .sort((a, b) => {
        const durationA = parseIsoDurationString(a)
        const durationB = parseIsoDurationString(b)
        return durationToSeconds(durationA) - durationToSeconds(durationB)
      })
      .map((duration) => {
        const durationObject = parseIsoDurationString(duration)

        let durationFormat
        try {
          // @ts-expect-error -- Intl.DurationFormat is not supported in all browsers
          durationFormat = new Intl.DurationFormat(navigator.language, {
            style: 'long',
          }).format(durationObject)
        } catch (error) {
          console.error(error)
          durationFormat = formatDuration(durationObject)
        }
        return durationFormat
      })
  }

  getFilterType(): FilterType {
    return FilterType.DURATION
  }
}
