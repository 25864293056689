import React, { useState } from 'react'

import { Button } from 'components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogFooter,
  DialogClose,
} from 'components/ui/dialog'
import { Input } from 'components/ui/input'
import { RadioGroup, RadioGroupItem } from 'components/ui/radio-group'

import { ApiKey } from './workspace-details-api'

interface ApiEditDialogProps {
  apiKey: ApiKey | undefined
  close: () => void
  setExpiryFromNow: (id: string, hours: number) => Promise<void>
}

const ApiEditDialog: React.FC<ApiEditDialogProps> = ({
  apiKey,
  close,
  setExpiryFromNow,
}) => {
  const [numValue, setNumValue] = useState<number>(1)
  const [hoursPer, setHoursPer] = useState<number>(24)
  const [loading, setLoading] = useState(false)

  const onSave = () => {
    setLoading(true)
    setExpiryFromNow(apiKey ? apiKey.id : '', numValue * hoursPer)
      .then(() => setLoading(false))
      .then(() => close())
  }

  return (
    <Dialog open={!!apiKey} onOpenChange={() => close()}>
      <DialogContent showCloseIcon={true}>
        <DialogHeader>
          <DialogTitle>Set API Key Expiry</DialogTitle>
          <DialogDescription>
            Select how long you want the key to be valid for from the options
            below. Expired keys remain expired and cannot be changed.
          </DialogDescription>
        </DialogHeader>

        <div className="mt-4 flex flex-row justify-around">
          <div className="align-center flex flex-col justify-center">
            <Input
              id="single-client-matter-id"
              type="number"
              className="w-24"
              value={numValue}
              min="1"
              max="30"
              onChange={(e) => setNumValue(parseInt(e.target.value))}
            />
          </div>
          <div className="align-center flex flex-col justify-center">
            <RadioGroup>
              <RadioGroupItem
                id="radio-days"
                label="Days"
                value="24"
                checked={hoursPer === 24}
                onClick={() => setHoursPer(24)}
              />
              <RadioGroupItem
                id="radio-months"
                label="Months"
                value="730"
                checked={hoursPer === 730}
                onClick={() => setHoursPer(730)}
              />
              <RadioGroupItem
                id="radio-years"
                label="Years"
                value="8760"
                checked={hoursPer === 8760}
                onClick={() => setHoursPer(8760)}
              />
            </RadioGroup>
          </div>
          <div className="align-center flex flex-col justify-center">
            from now
          </div>
        </div>

        <DialogFooter>
          <DialogClose asChild>
            <Button
              variant="outline"
              onClick={() => close()}
              disabled={loading}
            >
              Cancel
            </Button>
          </DialogClose>
          <Button
            variant="destructive"
            onClick={() => onSave()}
            disabled={loading}
          >
            Save
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default ApiEditDialog
