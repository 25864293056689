import React, { useMemo } from 'react'

import { useAbortController } from 'providers/abort-controller-provider'
import { useShallow } from 'zustand/react/shallow'

import { WorkflowType } from 'types/workflows'

import { displayErrorMessage } from 'utils/toast'

import { AppMain } from 'components/common/app-main'
import AskHarveyButton from 'components/common/ask-harvey-button'
import { Button } from 'components/ui/button'
import { Input } from 'components/ui/input'
import { Label } from 'components/ui/label'
import { ScrollArea } from 'components/ui/scroll-area'
import { Textarea } from 'components/ui/text-area'
import { fetchCompetitorExcel } from 'components/workflows/workflow/competitive-analysis/fetchers'
import { useCompetitiveAnalysisStore } from 'components/workflows/workflow/competitive-analysis/stores/competitive-analysis-store'

const Form = () => {
  const companyName = useCompetitiveAnalysisStore(
    useShallow((s) => s.companyName)
  )
  const companyDescription = useCompetitiveAnalysisStore(
    useShallow((s) => s.companyDescription)
  )
  const isFetching = useCompetitiveAnalysisStore(
    useShallow((s) => s.isFetching)
  )

  const setCompanyName = useCompetitiveAnalysisStore(
    useShallow((s) => s.setCompanyName)
  )
  const setCompanyDescription = useCompetitiveAnalysisStore(
    useShallow((s) => s.setCompanyDescription)
  )

  return (
    <div className="container max-w-[622px] pb-4 pt-10">
      <div className="w-full space-y-10 px-2">
        <div className="space-y-2">
          <Label htmlFor="company-name">Company name</Label>
          <Input
            required
            maxLength={500}
            data-testid="company-name"
            placeholder="AllSaints"
            value={companyName}
            disabled={isFetching}
            onChange={(e) => setCompanyName(e.target.value)}
          />
        </div>
        <div className="space-y-2">
          <Label htmlFor="company-description">Company description</Label>
          <Textarea
            required
            disabled={isFetching}
            placeholder="AllSaints is a British fashion retailer headquartered in London, England."
            value={companyDescription}
            onChange={(e) => setCompanyDescription(e.target.value)}
          />
        </div>
      </div>
    </div>
  )
}

const CreateCompetitiveAnalysisFooter = () => {
  const { getAbortController, addAbortController, removeAbortController } =
    useAbortController()

  const companyName = useCompetitiveAnalysisStore(
    useShallow((s) => s.companyName)
  )
  const companyDescription = useCompetitiveAnalysisStore(
    useShallow((s) => s.companyDescription)
  )
  const isFetching = useCompetitiveAnalysisStore(
    useShallow((s) => s.isFetching)
  )
  const setIsFetching = useCompetitiveAnalysisStore(
    useShallow((s) => s.setIsFetching)
  )
  const setExcelUrl = useCompetitiveAnalysisStore(
    useShallow((s) => s.setExcelUrl)
  )
  const setRawData = useCompetitiveAnalysisStore(
    useShallow((s) => s.setRawData)
  )

  const isDisabled = useMemo(() => {
    return !companyName || !companyDescription || isFetching
  }, [companyName, companyDescription, isFetching])

  const submitHandler = async () => {
    try {
      setIsFetching(true)
      const abortController = new AbortController()
      addAbortController(WorkflowType.COMPETITIVE_ANALYSIS, abortController)
      const requestData = {
        companyName,
        companyDescription,
      }
      const res = await fetchCompetitorExcel(requestData, abortController)
      const excelUrl = res?.excelUrl
      const raw = res?.raw
      if (excelUrl && raw) {
        setExcelUrl(excelUrl)
        setRawData(raw)
      }
    } catch (error) {
      if ((error as Error).name !== 'AbortError') {
        displayErrorMessage('Failed to fetch competitors')
      }
    } finally {
      setIsFetching(false)
    }
  }

  const cancelHandler = async () => {
    const abortController = getAbortController(
      WorkflowType.COMPETITIVE_ANALYSIS
    )
    if (abortController) {
      abortController.abort()
      removeAbortController(WorkflowType.COMPETITIVE_ANALYSIS)
      setIsFetching(false)
      setExcelUrl('')
      setRawData(null)
    }
  }

  return (
    <div className="flex h-auto min-h-[72px] w-full items-center justify-end gap-2 border-t px-6 py-2">
      <div className="flex basis-1/2 flex-row-reverse gap-2 sm:basis-1/3">
        <div>
          <AskHarveyButton
            handleSubmit={submitHandler}
            disabled={isDisabled}
            isLoading={isFetching}
          />
        </div>
        <Button
          variant="outline"
          disabled={!isFetching}
          onClick={cancelHandler}
        >
          Cancel
        </Button>
      </div>
    </div>
  )
}

const Landing = () => {
  return (
    <AppMain className="flex flex-col">
      <ScrollArea className="grow">
        <Form />
      </ScrollArea>
      <CreateCompetitiveAnalysisFooter />
    </AppMain>
  )
}

export default Landing
