/* tslint:disable */
/* eslint-disable */
/**
 * File Upload Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const FileContainerType = {
    VAULT: 'VAULT',
    EVENT: 'EVENT'
} as const;
export type FileContainerType = typeof FileContainerType[keyof typeof FileContainerType];


export function instanceOfFileContainerType(value: any): boolean {
    for (const key in FileContainerType) {
        if (Object.prototype.hasOwnProperty.call(FileContainerType, key)) {
            if (FileContainerType[key as keyof typeof FileContainerType] === value) {
                return true;
            }
        }
    }
    return false;
}

export function FileContainerTypeFromJSON(json: any): FileContainerType {
    return FileContainerTypeFromJSONTyped(json, false);
}

export function FileContainerTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): FileContainerType {
    return json as FileContainerType;
}

export function FileContainerTypeToJSON(value?: FileContainerType | null): any {
    return value as any;
}

