import { Permission } from 'models/user-info'
import { Permission as OpenAPIPermission } from 'openapi/models/Permission'
import { WorkflowConfig, WorkflowType } from 'types/workflows'

import { TaskType } from 'utils/task'

export const WorkflowTypeToWorkflowConfig: Record<
  WorkflowType,
  WorkflowConfig
> = {
  [WorkflowType.COMPANY_PROFILE]: {
    name: 'Company profile',
    path: `/workflows/${WorkflowType.COMPANY_PROFILE}`,
    permissions: [Permission.COMPANY_PROFILE],
    type: WorkflowType.COMPANY_PROFILE,
  },
  [WorkflowType.REDLINE_ANALYSIS]: {
    name: 'Redline analysis',
    path: `/workflows/${WorkflowType.REDLINE_ANALYSIS}`,
    permissions: [Permission.REDLINES],
    type: WorkflowType.REDLINE_ANALYSIS,
  },
  [WorkflowType.TRANSLATION]: {
    name: 'Translation',
    path: `/workflows/${WorkflowType.TRANSLATION}`,
    permissions: [Permission.TRANSLATION],
    type: WorkflowType.TRANSLATION,
  },
  [WorkflowType.OGC]: {
    name: 'OGC',
    path: `/workflows/${WorkflowType.OGC}`,
    permissions: [Permission.OGC_REVIEW],
    type: WorkflowType.OGC,
  },
  [WorkflowType.LEGACY_DRAFTING]: {
    name: 'Drafting',
    path: `/workflows/${WorkflowType.LEGACY_DRAFTING}`,
    permissions: [Permission.DRAFTING],
    type: WorkflowType.LEGACY_DRAFTING,
  },
  [WorkflowType.DOCUMENT_COMPARISON]: {
    name: 'Document comparison',
    path: `/workflows/${WorkflowType.DOCUMENT_COMPARISON}`,
    permissions: [Permission.DOC_COMPARE],
    type: WorkflowType.DOCUMENT_COMPARISON,
  },
  [WorkflowType.CONTRACTS]: {
    name: 'Contracts',
    path: `/workflows/${WorkflowType.CONTRACTS}`,
    permissions: [Permission.CONTRACTS],
    type: WorkflowType.CONTRACTS,
  },
  [WorkflowType.TRANSCRIPTS]: {
    name: 'Transcripts',
    path: `/workflows/${WorkflowType.TRANSCRIPTS}`,
    permissions: [Permission.TRANSCRIPTS],
    type: WorkflowType.TRANSCRIPTS,
  },
  [WorkflowType.DILIGENCE]: {
    name: 'Discovery',
    path: `/workflows/${WorkflowType.DILIGENCE}`,
    // TODO: Refactor all to use OpenAPIPermission
    permissions: [OpenAPIPermission.PWC_DEALS as Permission],
    type: WorkflowType.DILIGENCE,
  },
  [WorkflowType.COMPETITIVE_ANALYSIS]: {
    name: 'Competitive Analysis',
    path: `/workflows/${WorkflowType.COMPETITIVE_ANALYSIS}`,
    permissions: [Permission.USE_COMPETITIVE_ANALYSIS],
    type: WorkflowType.COMPETITIVE_ANALYSIS,
  },
  [WorkflowType.DILIGENCE_TRANSCRIPTS]: {
    name: 'Diligence Transcripts',
    path: `/workflows/${WorkflowType.DILIGENCE_TRANSCRIPTS}`,
    permissions: [Permission.DILIGENCE_TRANSCRIPTS],
    type: WorkflowType.DILIGENCE_TRANSCRIPTS,
  },
}

export const KindToWorkflowType: Partial<Record<TaskType, WorkflowType>> = {
  [TaskType.COMPANY_PROFILE]: WorkflowType.COMPANY_PROFILE,
  [TaskType.REDLINES]: WorkflowType.REDLINE_ANALYSIS,
  [TaskType.TRANSLATION]: WorkflowType.TRANSLATION,
  [TaskType.OGC_REVIEW]: WorkflowType.OGC,
  [TaskType.DRAFTING]: WorkflowType.LEGACY_DRAFTING,
  [TaskType.DOCUMENT_COMPARISON]: WorkflowType.DOCUMENT_COMPARISON,
  [TaskType.ISSUES_LIST]: WorkflowType.REDLINE_ANALYSIS,
  [TaskType.TRANSCRIPTS]: WorkflowType.TRANSCRIPTS,
}
