import React from 'react'
import { useLocation } from 'react-router-dom'

import { WorkflowType } from 'types/workflows'

import { useAuthUser } from 'components/common/auth-context'
import { Card, CardHeader, CardTitle, CardContent } from 'components/ui/card'
import Icon from 'components/ui/icon/icon'
import Link from 'components/ui/link/link'
import { userHasPermissionForWorkflow } from 'components/workflows/workflows-utils'

import { getWorkflowDescriptions } from './constants'
import { WorkflowTypeToDetails } from './workflow-definitions'

const AvailableWorkflows: React.FC = () => {
  const userInfo = useAuthUser()
  const location = useLocation()

  const workflows = Object.values(WorkflowType)
    .filter((workflow) => userHasPermissionForWorkflow(userInfo, workflow))
    .map((workflow) => WorkflowTypeToDetails[workflow])

  if (!workflows) {
    return <div className="text-center">No workflows available</div>
  }

  const workflowDescriptions = getWorkflowDescriptions()

  return (
    <div
      id="workflows-available-workflows"
      data-testid="workflows-available-workflows"
      className="grid grid-cols-1 gap-3 lg:grid-cols-2 xl:grid-cols-3"
    >
      {workflows.map((workflow) => (
        <Link
          className="workflows-workflow-link group rounded-lg"
          key={workflow.name}
          to={`${workflow.path}${location.search}`}
        >
          <Card
            data-testid={`workflows-${workflow.type}-card`}
            className="flex h-56 flex-col justify-between transition hover:border-input-focused xl:h-48"
          >
            <CardHeader>
              <CardTitle className="flex items-start justify-between text-lg">
                <Icon icon={workflow.icon} size="largeWithBackground" />
                {workflow.logoPath && (
                  <img
                    className="w-16 opacity-50 transition-opacity group-hover:opacity-90"
                    src={`/img/logos/${workflow.logoPath}`}
                    alt="Workflow logo"
                  />
                )}
              </CardTitle>
            </CardHeader>
            <CardContent>
              <p className="text-base">{workflow.name}</p>
              <p className="mt-1 text-xs">
                {workflowDescriptions[workflow.type].body}
              </p>
            </CardContent>
          </Card>
        </Link>
      ))}
    </div>
  )
}

export default AvailableWorkflows
