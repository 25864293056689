import { convertMillimetersToTwip } from 'docx'
import _ from 'lodash'

import { DiligenceDocument } from 'openapi/models/DiligenceDocument'
import { DiligenceSection } from 'openapi/models/DiligenceSection'
import { DiligenceTask } from 'openapi/models/DiligenceTask'

import { WordSection } from 'utils/docx'
import { exportWordWithSections } from 'utils/markdown'
import { TaskType } from 'utils/task'

import { AAG_SUMMARY_TITLE } from 'components/workflows/workflow/discovery/config'

import { FollowUpQAPair } from './diligence-store'

interface ExportParams {
  documents: DiligenceDocument[]
  sections: DiligenceSection[]
  followUpQAPairs?: FollowUpQAPair[]
}

export const handleExport = async ({
  sections,
  followUpQAPairs,
}: ExportParams) => {
  const sectionHasAnsweredTasks = (section: DiligenceSection) =>
    section.tasks.some((task) => task.answer?.response.length)

  const taskContent = (task: DiligenceTask, isAAG: boolean): WordSection[] => {
    const content: WordSection[] = []

    if (!isAAG) {
      content.push({ content: `## ${task.title}`, type: 'markdown' })
    }

    content.push({ content: task.answer?.response || '', type: 'html' })

    return content
  }

  const exportSections: WordSection[] = sections
    .filter(sectionHasAnsweredTasks)
    .flatMap((section): WordSection[] => {
      const content: WordSection[] = []

      const isAAG = section.title === AAG_SUMMARY_TITLE
      if (!isAAG) {
        content.push({ content: `# ${section.title}`, type: 'markdown' })
      }

      content.push(
        ...section.tasks
          .filter((t) => t.answer?.response.length)
          .map((t) => taskContent(t, isAAG))
          .flat()
      )

      content.push({
        content: '<hr>',
        type: 'html',
        options: {
          spacing: {
            before: convertMillimetersToTwip(8),
          },
        },
      })

      return content
    })

  const followUpContent: WordSection[] = []

  if (!_.isNil(followUpQAPairs) && !_.isEmpty(followUpQAPairs)) {
    followUpQAPairs.forEach((qaPair, index) => {
      followUpContent.push(
        {
          content: `## Follow-up Question ${index + 1}:`,
          type: 'markdown',
        },
        { content: `### Question`, type: 'markdown' },
        { content: qaPair.question, type: 'markdown' },
        { content: `### Answer`, type: 'markdown' },
        { content: qaPair.answer.response || '', type: 'markdown' }
      )
    })
  }

  const allContent = [...exportSections, ...followUpContent]

  await exportWordWithSections({
    title: 'Discovery Report',
    taskType: TaskType.PWC_DEALS,
    includeAnnotation: false,
    queryId: '0',
    sections: allContent,
    filePrefixOverride: 'DISCOVERY',
    defaultStyleOverrides: {
      heading1: {
        paragraph: {
          spacing: {
            before: convertMillimetersToTwip(12),
          },
        },
      },
      heading2: {
        paragraph: {
          spacing: {
            before: convertMillimetersToTwip(5),
          },
        },
      },
      heading3: {
        paragraph: {
          spacing: {
            before: convertMillimetersToTwip(4),
          },
        },
      },
    },
  })
}
