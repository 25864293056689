// This is a markdown file, exported as a single javascript string

const markdownContent = `
## Client matter numbers

You can now attach a client matter number to each query you run in Harvey. If you have questions about which client matters can be used within Harvey, please contact your admin.


`

export default markdownContent
