import { Text, Html, Node, Parent } from 'mdast'
import { remark } from 'remark'
import { remarkExtendedTable } from 'remark-extended-table'
import remarkGfm from 'remark-gfm'
import remarkParse from 'remark-parse'
import { visit } from 'unist-util-visit'

import { findOriginalSubstring, removeWhitespace } from 'utils/string'

export const harveyCitationRegex =
  / <span data-hrvy-id="[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}">.*?<\/span>/gs

export const getMarkdownText = (mdText: string) => {
  const processor = remark()
    .use(remarkParse)
    .use(remarkGfm)
    .use(remarkExtendedTable)
  const tree = processor.parse(mdText)
  let text = ''
  visit(tree, 'text', (node) => {
    text += node.value
  })
  return text
}

export const getMarkdownTextOffset = (markdown: string, offset: number) => {
  const cleanedMarkdown = getMarkdownText(markdown)
  // Function to strip whitespace and create a mapping
  function stripAndMap(str: string) {
    return str.split('').reduce(
      (acc, char, index) => {
        if (char.trim() !== '') {
          acc.mapping[acc.counter] = index
          acc.counter++
        }
        return acc
      },
      {
        mapping: {} as Record<number, number>,
        counter: 0,
      }
    )
  }

  // Strip whitespace from key and haystack
  const { mapping } = stripAndMap(cleanedMarkdown)
  return mapping[offset]
}

const findExactSubString = (
  strippedText: string,
  highlight: string,
  highightStartOffset: number
) => {
  const startIndex = strippedText.indexOf(highlight, highightStartOffset)
  if (startIndex === -1) {
    const match = findOriginalSubstring(highlight, strippedText)
    if (match === null) {
      return { startIndex: -1, endIndex: -1, selection: '' }
    }
    const index = strippedText.indexOf(match)
    return {
      startIndex: index,
      endIndex: index + match.length,
      selection: match,
    }
  }

  const endIndex = startIndex + highlight.length
  return {
    startIndex,
    endIndex,
    selection: strippedText.substring(startIndex, endIndex),
  }
}

export const mapIndicesToOriginal = ({
  markdown,
  strippedMarkdown,
  startIndex,
  endIndex,
}: {
  markdown: string
  strippedMarkdown: string
  startIndex: number
  endIndex: number
}) => {
  let origIndex = 0
  let strippedIndex = 0
  let resultStart = 0
  let resultEnd = 0

  while (origIndex < markdown.length) {
    if (strippedIndex === startIndex) resultStart = origIndex
    if (strippedIndex >= endIndex) {
      resultEnd = origIndex
      break
    }

    if (markdown.slice(origIndex).match(harveyCitationRegex)) {
      const match = markdown.slice(origIndex).match(harveyCitationRegex)
      if (match && match.index === 0) {
        origIndex += match[0].length // Skip the entire tag
        continue
      }
    }

    // Skip over HTML tags to avoid characters within the tag matching
    if (markdown[origIndex] === '<') {
      const nextChar = markdown[origIndex + 1]
      // Check if the next character suggests this might be a tag
      if (/[\w!?/]/.test(nextChar)) {
        while (origIndex < markdown.length && markdown[origIndex] !== '>') {
          origIndex++
        }
        origIndex++ // Skip '>'
        continue
      }
    }

    // Match characters from original and stripped, incrementing indices
    if (markdown[origIndex] === strippedMarkdown[strippedIndex]) {
      strippedIndex++
    } else if (
      /\s/.test(markdown[origIndex]) ||
      /\s/.test(strippedMarkdown[strippedIndex])
    ) {
      // Sync whitespace skipping in both markdown and strippedMarkdown
      while (/\s/.test(markdown[origIndex])) origIndex++
      while (/\s/.test(strippedMarkdown[strippedIndex])) strippedIndex++
      continue
    }

    origIndex++
  }

  // Check for opening tags
  if (resultStart > 0) {
    const precedingText = markdown.slice(resultStart - 2, resultStart)
    if (precedingText === '**' || precedingText === '__') {
      resultStart -= 2
    } else if (markdown[resultStart - 1] === '>') {
      // Look for opening tag
      let openTagIndex = resultStart - 1
      while (openTagIndex > 0 && markdown[openTagIndex] !== '<') {
        openTagIndex--
      }
      resultStart = openTagIndex
    }
  }
  // Check for closing tags or markdown syntax after the match
  if (resultEnd > 0) {
    const remainingText = markdown.slice(resultEnd)
    if (markdown[resultEnd] === '<') {
      const nextChar = markdown[resultEnd + 1]
      // Check if the next character suggests this might be a tag
      if (/[\w!?/]/.test(nextChar)) {
        while (resultEnd < markdown.length && markdown[resultEnd] !== '>') {
          resultEnd++
        }
        resultEnd++ // Skip '>'
      }
    } else if (remainingText.startsWith('**')) {
      resultEnd += 2 // Length of '**'
    } else if (remainingText.startsWith('__')) {
      resultEnd += 2 // Length of '__'
    }
  }

  // Handle last character highlighted
  if (!resultEnd && strippedIndex >= endIndex) resultEnd = origIndex

  return { startIndex: resultStart, endIndex: resultEnd }
}

/**
 * Find the start and ending indices in the stripped markdown text
 * @param markdown The original markdown text.
 * @param highlight The highlighted text.
 * @param highlightStartOffset The start offset of the highlighted text in the original markdown.
 * @returns The start and end indices of the highlighted text in the markdown ast string. Also returns the selection string in the markdown ast.
 */
export const findMarkdownHighlightOffsets = (
  markdown: string,
  highlight: string,
  noWhitespaceOffset: number
) => {
  const strippedMarkdown = getMarkdownText(markdown)
  return findExactSubString(strippedMarkdown, highlight, noWhitespaceOffset)
}

/**
 * Finds the start and end indices of the highlighted text in the original markdown.
 * @param markdown The original markdown text.
 * @param highlight The highlighted text.
 * @param highlightStartOffset The start offset of the highlighted text in the original markdown.
 * @returns The start and end indices of the highlighted text in the original markdown.
 */
export const findMarkdownHighlightIndices = (
  markdown: string,
  highlight: string,
  highlightStartOffset: number
) => {
  const { startIndex, endIndex } = findMarkdownHighlightOffsets(
    markdown,
    highlight,
    highlightStartOffset
  )

  if (startIndex === -1) return { startIndex: -1, endIndex: -1 }
  const strippedMarkdown = getMarkdownText(markdown)

  return mapIndicesToOriginal({
    markdown,
    strippedMarkdown,
    startIndex,
    endIndex,
  })
}

export const getZeroIndexOffset = ({
  diffMarkdown,
  selection,
  startIndex,
}: {
  diffMarkdown: string
  selection: string
  startIndex: number
}): { startIndex: number; endIndex: number } => {
  const processor = remark()
    .use(remarkParse)
    .use(remarkGfm)
    .use(remarkExtendedTable)

  const originalAst = processor.parse(diffMarkdown)

  let zeroIndexOffset = 0
  let offset = startIndex

  const traverse = (
    node: Node,
    isDeletion: boolean
  ): { isDeletion: boolean; shouldContinue: boolean } => {
    if (node.type === 'html') {
      const nodeAsHtml = node as Html
      if (nodeAsHtml.value === '<del>') {
        return { isDeletion: true, shouldContinue: true }
      }
      if (nodeAsHtml.value === '</del>') {
        return { isDeletion: false, shouldContinue: true }
      }
    } else if (node.type === 'text') {
      const value = (node as Text).value

      const nodeValueLength = value.length
      if (nodeValueLength) {
        offset -= nodeValueLength
        if (!isDeletion) {
          if (offset > 0) {
            zeroIndexOffset += removeWhitespace(value).length
            return { isDeletion, shouldContinue: true }
          } else {
            const substring = value.slice(0, value.length + offset)
            zeroIndexOffset += removeWhitespace(substring).length
            return { isDeletion, shouldContinue: false }
          }
        }
      }
    }
    if ('children' in node) {
      let localIsDeletion = isDeletion
      let localShouldContinue = true
      ;(node as Parent).children.flatMap((child) => {
        if (!localShouldContinue) {
          return
        }
        const { isDeletion, shouldContinue } = traverse(child, localIsDeletion)
        localIsDeletion = isDeletion
        localShouldContinue = shouldContinue
      })
      return {
        isDeletion: localIsDeletion,
        shouldContinue: localShouldContinue,
      }
    }
    return { isDeletion, shouldContinue: true }
  }

  traverse(originalAst, false)

  const endIndex = zeroIndexOffset + removeWhitespace(selection).length
  return { startIndex: zeroIndexOffset, endIndex }
}

export const getHasDeletions = (
  markdown: string,
  startIndex: number,
  endIndex: number
) => {
  const processor = remark()
    .use(remarkParse)
    .use(remarkGfm)
    .use(remarkExtendedTable)

  const originalAst = processor.parse(markdown)
  let offset = 0

  let hasDiff = false
  let inDiff = false
  const traverse = (node: Node): boolean => {
    if (node.type === 'html') {
      const nodeAsHtml = node as Html
      const value = nodeAsHtml.value
      if (value === '<del>') {
        inDiff = true
      } else if (value === '</del>') {
        inDiff = false
      }
    } else if (node.type === 'text') {
      const value = (node as Text).value
      const nodeValueLength = value.length
      if (nodeValueLength) {
        offset += nodeValueLength
        if (inDiff && startIndex < offset) {
          hasDiff = true
        }
        return offset < endIndex
      }
    }
    if ('children' in node) {
      let shouldContinue = true
      ;(node as Parent).children.flatMap((child) => {
        if (shouldContinue) {
          shouldContinue = traverse(child)
        }
      })
      return shouldContinue
    }
    return true
  }

  traverse(originalAst)

  return hasDiff
}
