import React, { useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { Instance } from 'pspdfkit'

import { TranscriptType } from 'openapi/models/TranscriptType'
import { TranscriptsDocumentTopic } from 'openapi/models/TranscriptsDocumentTopic'

import ErrorPage from 'components/common/error/error'
import Markdown from 'components/common/markdown/markdown'
import PdfViewer from 'components/common/pdf-viewer/pdf-viewer'
import { ScrollArea } from 'components/ui/scroll-area'
import { Separator } from 'components/ui/separator'
import { TranscriptsSubheader } from 'components/workflows/workflow/transcripts/common/subheader'
import { TranscriptsTopicList } from 'components/workflows/workflow/transcripts/common/topic-list'
import { TranscriptsTopicView } from 'components/workflows/workflow/transcripts/common/topic-view'
import { TranscriptsLayout } from 'components/workflows/workflow/transcripts/layout'
import { useTranscriptsStore } from 'components/workflows/workflow/transcripts/transcripts-store'

export const TranscriptsTranscriptView: React.FC = () => {
  const { documents } = useTranscriptsStore()
  const pspdfInstanceRef = useRef<Instance | null>(null)
  const navigate = useNavigate()
  const { filename } = useParams<{
    filename: string
    type: TranscriptType
  }>()
  const document = documents.find((doc) => doc.file.name === filename)
  const [viewingTopic, setViewingTopic] =
    React.useState<TranscriptsDocumentTopic | null>(null)

  if (!document?.file.url) {
    return (
      <ErrorPage
        primaryCTA={{
          text: 'Back to Transcripts',
          onClick: () => navigate(-1),
        }}
      />
    )
  }

  const close = () => {
    navigate(-1)
  }

  const topicsGroupedByWitness: Record<string, TranscriptsDocumentTopic[]> =
    document.topics.reduce(
      (acc, topic) => {
        if (!acc[topic.witness]) {
          acc[topic.witness] = []
        }
        acc[topic.witness].push(topic)
        return acc
      },
      {} as Record<string, TranscriptsDocumentTopic[]>
    )

  const onSourceClick = (id: string) => {
    const source = document.topics
      .flatMap((t) => t.sources)
      .find((s) => s?.id === id)

    if (!source || !pspdfInstanceRef.current) return

    const newViewState = pspdfInstanceRef.current.viewState.set(
      'currentPageIndex',
      source.page
    )
    pspdfInstanceRef.current.setViewState(newViewState)
  }

  return (
    <TranscriptsLayout>
      <TranscriptsSubheader documents={documents} />

      <div className="flex h-[calc(100vh-148px)] space-x-4">
        <div className="w-full ">
          <PdfViewer
            onClear={close}
            canClear
            document={document.file}
            pspdfInstanceRef={pspdfInstanceRef}
          />
        </div>
        <ScrollArea className="w-full rounded-lg border p-8">
          {!viewingTopic ? (
            <div className="space-y-4">
              {document.metadata?.headerStr && (
                <div>
                  <p className="mb-2 text-xl">Summary</p>
                  <Separator className="my-0.5 mb-4" />
                  <Markdown content={document.metadata.headerStr || ''} />
                </div>
              )}

              <div>
                <p className="mb-2 text-xl">Themes by witness</p>
                <Separator className="my-0.5" />
                <div className="mt-4 space-y-2">
                  {Object.entries(topicsGroupedByWitness).map(
                    ([witness, topics], i) => (
                      <div key={i}>
                        <p className="font-semibold">{witness}</p>
                        <TranscriptsTopicList
                          useSimpleUI
                          titles={topics.map((t) => t.topic)}
                          onClick={(index) =>
                            setViewingTopic(
                              topicsGroupedByWitness[witness][index]
                            )
                          }
                        />
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div className="px-4 py-3">
              <TranscriptsTopicView
                close={() => setViewingTopic(null)}
                onSourceClick={onSourceClick}
                title={viewingTopic.topic}
                markdownContent={viewingTopic.summary}
              />
            </div>
          )}
        </ScrollArea>
      </div>
    </TranscriptsLayout>
  )
}

export default TranscriptsTranscriptView
