import React from 'react'

import { HTTPError } from 'ky'

import { isUnauthorizedError } from 'services/errors/errors'

import { useNavigateWithQueryParams } from 'hooks/use-navigate-with-query-params'

import ErrorPage, { ErrorPageTitle } from 'components/common/error/error'

export const AssistantErrorPage = ({ error }: { error: Error }) => {
  const navigate = useNavigateWithQueryParams()
  const isUnauthorized =
    error instanceof HTTPError ? isUnauthorizedError(error) : false

  return (
    <ErrorPage
      title={
        isUnauthorized
          ? ErrorPageTitle.NOT_AUTHORIZED_TO_CONTENT
          : ErrorPageTitle.SOMETHING_WENT_WRONG
      }
      description={
        isUnauthorized
          ? 'Request access, or sign in with an account that has the necessary permissions.'
          : undefined
      }
      primaryCTA={{
        text: 'Back to Assistant',
        onClick: () => navigate('/assistant'),
      }}
    />
  )
}
