import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'

export interface CompetitiveAnalysisState {
  companyName: string
  companyDescription: string
  isFetching: boolean
  excelUrl: string
  // @eslint-disable-next-line
  rawData: Record<string, any> | null
}

export interface CompetitiveAnalysisActions {
  setCompanyName: (companyName: string) => void
  setCompanyDescription: (companyDescription: string) => void
  setIsFetching: (isFetching: boolean) => void
  setExcelUrl: (excelUrl: string) => void
  // eslint-disable-next-line
  setRawData: (rawData: Record<string, any> | null) => void
}

export const useCompetitiveAnalysisStore = create(
  devtools(
    immer<CompetitiveAnalysisState & CompetitiveAnalysisActions>((set) => ({
      companyName: '',
      companyDescription: '',
      isFetching: false,
      excelUrl: '',
      rawData: {},
      gridApi: null,
      setCompanyName: (companyName: string) => set({ companyName }),
      setCompanyDescription: (companyDescription: string) =>
        set({ companyDescription }),
      setIsFetching: (isFetching: boolean) => set({ isFetching }),
      setExcelUrl: (excelUrl: string) => set({ excelUrl }),
      // eslint-disable-next-line
      setRawData: (rawData: Record<string, any> | null) => set({ rawData }),
    }))
  )
)
