import React, { useState } from 'react'

import pluralize from 'pluralize'

import { Alert, AlertTitle } from 'components/ui/alert'
import { Badge } from 'components/ui/badge'
import { Button } from 'components/ui/button'
import { Checkbox } from 'components/ui/checkbox'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogTitle,
} from 'components/ui/dialog'
import { ScrollArea } from 'components/ui/scroll-area'

interface BulkDeleteUserModalProps {
  selectedUsers: string[]
  open: boolean
  onOpenChange: (open: boolean) => void
  onDelete: () => void
}

const BulkDeleteUserModal = ({
  selectedUsers,
  open,
  onOpenChange,
  onDelete,
}: BulkDeleteUserModalProps) => {
  const [deleteAcknowledge, setDeleteAcknowledge] = useState(false)
  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="max-w-[600px]">
        <DialogTitle>
          <div className="text-destructive">Delete users</div>
        </DialogTitle>
        <DialogDescription>
          <p>
            This is non-reversible. It will delete the users from Auth0 and
            remove their access from Harvey.
          </p>
          <br />
          <b>Users to be deleted:</b>
          <ScrollArea className="max-h-96">
            <div className="mt-1 max-h-96">
              {selectedUsers.map((email, idx) => (
                <Badge key={idx} variant="outline" className="mr-1 mt-1">
                  {email}
                </Badge>
              ))}
            </div>
          </ScrollArea>
          <br />
          <Alert className="mt-4 h-10" variant="destructive">
            <AlertTitle className="-mt-2 flex justify-between">
              {`Please acknowledge you want to delete ${
                selectedUsers.length
              } ${pluralize('users', selectedUsers.length)}`}
              <Checkbox
                checked={deleteAcknowledge}
                onCheckedChange={() => setDeleteAcknowledge(!deleteAcknowledge)}
                checkboxClassName="border border-destructive"
              />
            </AlertTitle>
          </Alert>
        </DialogDescription>
        <DialogFooter>
          <Button
            className="btn btn-secondary"
            onClick={() => {
              setDeleteAcknowledge(false)
              onOpenChange(false)
            }}
          >
            Cancel
          </Button>
          <Button
            className="btn btn-primary"
            onClick={onDelete}
            disabled={!deleteAcknowledge}
            variant="destructive"
          >
            {`Delete ${selectedUsers.length} ${pluralize(
              'users',
              selectedUsers.length
            )}`}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default BulkDeleteUserModal
