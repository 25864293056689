import React from 'react'
import { NavLink as ReactRouterNavLink } from 'react-router-dom'
import { useLocation } from 'react-router-dom'

import queryString from 'query-string'

import { Maybe } from 'types'

import { cn } from 'utils/utils'

export interface SettingsNavItemProps {
  label: string
  href: string
  search?: string
  icon?: Maybe<React.ReactElement>
  available?: Maybe<boolean>
  comingSoon?: Maybe<boolean>
  id?: string
}

const SettingsNavItem: React.FC<SettingsNavItemProps> = ({
  label,
  icon,
  href,
  search,
  comingSoon,
  id,
}) => {
  const location = useLocation()
  const existingParams = queryString.parse(location.search)
  const newParams = queryString.parse(search || '')
  const combinedParams = { ...existingParams, ...newParams }
  const combinedSearch = queryString.stringify(combinedParams)
  const destination = {
    pathname: href,
    search: combinedSearch ? `?${combinedSearch}` : '',
  }

  const isActiveNavItem = (path: string, href: string) => {
    // Normalize both paths before comparison
    const normalizedCurrentPath = path.endsWith('/') ? path : `${path}/`
    const normalizedHref = href.endsWith('/') ? href : `${href}/`

    return normalizedCurrentPath.startsWith(normalizedHref)
  }

  const active = isActiveNavItem(location.pathname, href)

  return (
    <ReactRouterNavLink
      id={id}
      to={destination}
      className={cn(
        '-mx-2.5 flex w-full cursor-pointer items-center justify-between rounded-md px-2.5 py-1 transition hover:bg-secondary-hover',
        {
          'bg-secondary-hover ': active,
          'pointer-events-none text-muted': comingSoon,
        }
      )}
    >
      <div className="flex items-center space-x-4">
        {icon &&
          React.cloneElement(icon, { className: cn('w-5 h-5  shrink-0') })}
        <span
          className={cn('text-sm ', {
            'text-muted': comingSoon,
          })}
        >
          {label}
        </span>
      </div>
    </ReactRouterNavLink>
  )
}

export default SettingsNavItem
