import React, { useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { useMount, useUnmount } from 'react-use'

import * as Sentry from '@sentry/browser'
import _ from 'lodash'
import { Instance } from 'pspdfkit'

import { uploadFile } from 'api'
import { useHistoryItemQuery } from 'models/queries/use-history-item-query'
import { EventKind } from 'openapi/models/EventKind'
import { UploadedFileToJSON } from 'openapi/models/UploadedFile'
import { FileType } from 'types/file'
import { WorkflowType, WorkflowTypeToTaskType } from 'types/workflows'

import { useNavigateWithQueryParams } from 'hooks/use-navigate-with-query-params'
import { exportTaskDocumentFromBackend } from 'utils/export'
import { feedbackClosedState } from 'utils/feedback'
import { exportWordWithQuery } from 'utils/markdown'
import { TaskType } from 'utils/task'
import { displayErrorMessage } from 'utils/toast'
import useHarveySocket from 'utils/use-harvey-socket'
import {
  HarveySocketCompletionStatus,
  hasCompletedStreaming,
} from 'utils/use-harvey-socket-utils'
import { isUserInputEmpty } from 'utils/utils'

import { useClientMattersStore } from 'components/client-matters/client-matters-store'
import useQueryAnalytics from 'components/common/analytics/use-query-analytics'
import { AppHeaderActions } from 'components/common/app-header-actions'
import { useAuthUser } from 'components/common/auth-context'
import ExportDialog from 'components/common/export/export-dialog'
import Response from 'components/common/response/response'
import { TRANSLATION_HELP } from 'components/workflows/constants'
import { WorkflowTypeToDetails } from 'components/workflows/workflow-definitions'
import WorkflowFileInput from 'components/workflows/workflow/components/workflow-file-input'
import { WorkflowsDualPaneLayout } from 'components/workflows/workflow/layouts/dual-pane'
import WorkflowLayout from 'components/workflows/workflow/workflow-layout'

import PromptInput from './translation-prompt-input'
import { useTranslationStore } from './translation-store'
import { UnsupportedLanguageBanner } from './unsupported-language-banner'

const ROUTE = 'translation'
const TASK_TYPE = 'TRANSLATION'

const TranslationWorkflow: React.FC = () => {
  const userInfo = useAuthUser()
  const workflowType = WorkflowType.TRANSLATION
  const workflow = WorkflowTypeToDetails[workflowType]
  const navigate = useNavigateWithQueryParams()
  const pspdfInstanceRef = useRef<Instance | null>(null)
  const [showUnsupportedLanguageBanner, setShowUnsupportedLanguageBanner] =
    React.useState(false)
  const { id: historyId } = useParams<{ id: string }>()
  const { historyItem } = useHistoryItemQuery({ id: historyId ?? null })
  const {
    query,
    response,
    setTask,
    activeDocument,
    setActiveDocument,
    queryId,
    feedback,
    setFeedback,
    headerText,
    isLoading,
    reset,
    completionStatus,
    metadata,
  } = useTranslationStore()

  const {
    setQueryViewed,
    recordExport,
    recordQueryCancel,
    recordQuerySubmitted,
    recordQueryCompletion,
    recordReset,
  } = useQueryAnalytics(EventKind.TRANSLATION)

  const handleReset = () => {
    navigate(workflow.path, { replace: true })
    reset()
    recordReset()
  }

  useMount(() => {
    if (!_.isNil(historyItem)) {
      setTask({
        queryId: historyItem.id,
        query: historyItem.query,
        response: historyItem.response,
        completionStatus: HarveySocketCompletionStatus.Completed,
        metadata: historyItem.metadata,
      })
      setFeedback(feedbackClosedState)
      const document = historyItem.documents?.find((doc) => {
        return doc.kind === undefined || doc.kind === 'doc' || doc.kind === ''
      })
      if (!document) {
        displayErrorMessage('Couldn’t find document in history')
        Sentry.captureException('Couldn’t find document in history', {
          extra: { documents: historyItem.documents },
        })
        return
      }
      setActiveDocument(document)
      setQueryViewed()

      // Add the signed url to the task metadata. It gets resigned and
      // passed to client as a document with kind 'export'. Use that
      // to download the export document.
      const exportDocument = historyItem.documents?.find((doc) => {
        return doc.kind === 'export'
      })
      if (exportDocument) {
        setTask({
          metadata: {
            translatedDocUrl: exportDocument.url,
          },
        })
      }
    }
  })

  useEffect(() => {
    if (
      userInfo.IsHistoryUser &&
      queryId &&
      hasCompletedStreaming(completionStatus)
    ) {
      navigate(`${workflow.path}/${queryId}`, {
        replace: true,
        state: { fetchHistoryItem: false },
      })
      recordQueryCompletion()
    }
  }, [
    queryId,
    completionStatus,
    navigate,
    recordQueryCompletion,
    userInfo,
    workflow.path,
  ])

  const handleFile = async (file: File) => {
    const response = await uploadFile(file, false, {
      languageNotSupportedCheck: true,
    })

    if (response.languageNotSupported) {
      setShowUnsupportedLanguageBanner(true)
    }
    setActiveDocument(response)
  }

  const { selectedClientMatter, setClientMatterSelectDisabled } =
    useClientMattersStore()
  const { initSocketAndSendQuery, sendCancelRequest } = useHarveySocket({
    path: ROUTE,
    setter: setTask,
    endCallback: () => {
      setClientMatterSelectDisabled(false)
    },
  })

  const clearExistingQuery = () => {
    setTask({ headerText: '', response: '', sources: [] })
    setFeedback(null)
  }

  const onSubmit = async () => {
    if (!activeDocument) {
      return null
    }
    recordQuerySubmitted({
      event_kind: EventKind.TRANSLATION,
    })
    clearExistingQuery()

    const client_matter_id = userInfo.isClientMattersReadUser
      ? selectedClientMatter?.id
      : null

    initSocketAndSendQuery({
      query,
      additionalAuthParams: { task_type: TASK_TYPE, client_matter_id },
      additionalRequestParams: {
        // adding source_event_id if we have a query id
        // https://www.notion.so/harveyai/Make-sure-we-keep-honoring-event_source_id-04edcaaae0d8440a9b51808aef7af349?pvs=4
        source_event_id: queryId,
        task_type: TASK_TYPE,
        documents: [UploadedFileToJSON(activeDocument)],
      },
    })
  }

  const handleCancel = () => {
    recordQueryCancel()
    sendCancelRequest()
  }

  useUnmount(() => {
    handleCancel()
    reset()
  })

  const handleExportInner = async () => {
    if (metadata?.translatedDocUrl) {
      try {
        await exportTaskDocumentFromBackend(
          metadata.translatedDocUrl,
          WorkflowTypeToTaskType[WorkflowType.TRANSLATION],
          queryId
        )
        return
        // eslint-disable-next-line no-empty
      } catch (e) {}
    }

    await exportWordWithQuery({
      query,
      files: activeDocument ? [activeDocument.name] : undefined,
      response,
      taskType: TaskType.TRANSLATION,
      includeAnnotation: false,
      queryId,
    })
    recordExport(String(queryId), 'word', false)
  }

  const closeUnsupportedLanguageBanner = () => {
    setShowUnsupportedLanguageBanner(false)
  }

  const showFeedback =
    hasCompletedStreaming(completionStatus) && !feedback?.closed

  const emptyUserInput = isUserInputEmpty([query, activeDocument])
  const resetDisabled = emptyUserInput || isLoading
  // TODO(Adam): Move the language to a higher state so we can reset and control it
  // TODO(Adam): Also change how the reset button clears the query, but then we still have a language selected. This disables ask Harvey button in confusing way.

  return (
    <WorkflowLayout
      workflowType={workflow.type}
      title="Translation"
      appHeaderActions={
        <>
          <AppHeaderActions
            handleReset={handleReset}
            resetDisabled={resetDisabled}
            saveExample={{
              show: userInfo.canSeeSaveExample,
              disabled: !hasCompletedStreaming(completionStatus),
              params: {
                eventId: String(queryId),
              },
            }}
          />
          <ExportDialog
            onExport={handleExportInner}
            disabled={!hasCompletedStreaming(completionStatus)}
          />
        </>
      }
    >
      <WorkflowsDualPaneLayout
        input={
          <WorkflowFileInput
            eventKind={EventKind.TRANSLATION}
            handleFile={handleFile}
            pdfViewerFooter={
              showUnsupportedLanguageBanner && (
                <UnsupportedLanguageBanner
                  onClose={closeUnsupportedLanguageBanner}
                />
              )
            }
            store={useTranslationStore}
            pspdfInstanceRef={pspdfInstanceRef}
            fileTypes={[
              FileType.PDF,
              FileType.WORD,
              FileType.EMAIL,
              FileType.OUTLOOK,
            ]}
            onClear={() => setShowUnsupportedLanguageBanner(false)}
          >
            <PromptInput onSubmit={onSubmit} />
          </WorkflowFileInput>
        }
        output={
          <Response
            markdown={response}
            language={query}
            isLoading={isLoading}
            headerText={headerText}
            handleCancel={handleCancel}
            emptyStateText={
              userInfo.GetHelpPanel(WorkflowTypeToTaskType[workflowType])
                ?.content ?? TRANSLATION_HELP
            }
            queryId={queryId}
            handleSaveFeedback={showFeedback ? setFeedback : undefined}
          />
        }
      />
    </WorkflowLayout>
  )
}

export default TranslationWorkflow
