import React from 'react'

import { Card, Tabs, TabsProps } from 'antd'
import _ from 'lodash'

import { Workspace } from 'models/workspace'

import { EM_DASH, intervalToReadable } from 'utils/utils'

import { useAuthUser } from 'components/common/auth-context'

import WorkspaceDetailsQueryCaps from './vault-management/query-cap/workspace-details-query-caps'
import VaultManagement from './vault-management/vault-management'
import WorkspaceDetailsApi from './workspace-details-api'
import WorkspaceDetailsStatusBar from './workspace-details-status-bar'
import WorkspaceOffboard from './workspace-offboard/workspace-offboard'
import WorkspaceStateManagement from './workspace-state-management'

interface WorkspaceDetailsImplementationProps {
  workspace: Workspace
  fetchData: () => void
}

const WorkspaceDetailsImplementation = ({
  workspace,
  fetchData,
}: WorkspaceDetailsImplementationProps) => {
  const userInfo = useAuthUser()
  const WorkspaceDataPrivacy = (
    <Card>
      <div className="space-y-2">
        <div className="*:text-sm">
          <p className="text-muted">Azure Storage account name</p>
          <p>
            {_.isNil(workspace.storageAccount)
              ? EM_DASH
              : workspace.storageAccount}
          </p>
        </div>
        <div className="*:text-sm">
          <p className="text-muted">Bucket name</p>
          <p className="font-semibold ">
            {_.isNil(workspace.bucket) ? EM_DASH : workspace.bucket}
          </p>
        </div>
        <div>
          <p className="text-sm text-muted">Retention period</p>
          <div className="mt-1 flex max-w-md flex-wrap gap-x-1 gap-y-2">
            <p className="text-sm">
              {!_.isNil(workspace.settings.retentionPeriod)
                ? intervalToReadable(workspace.settings.retentionPeriod, true)
                : 'Unlimited'}
            </p>
          </div>
        </div>
      </div>
    </Card>
  )

  const tabItems: TabsProps['items'] = [
    {
      key: '1',
      label: 'API Tokens',
      children: <WorkspaceDetailsApi workspace={workspace} />,
      disabled:
        (!userInfo.IsInternalAdminReader || workspace.deletedAt != null) ??
        true,
    },
    {
      key: '2',
      label: 'Data Privacy',
      children: WorkspaceDataPrivacy,
    },
    {
      key: '3',
      label: 'Query caps',
      children: <WorkspaceDetailsQueryCaps workspace={workspace} />,
      disabled:
        (!userInfo.isReadEngInternal || workspace.deletedAt != null) ?? true,
    },
    {
      key: '4',
      label: 'Vault Mgmt',
      children: <VaultManagement workspace={workspace} />,
      disabled:
        (!userInfo.IsInternalAdminReader || workspace.deletedAt != null) ??
        true,
    },
    {
      key: '5',
      label: 'State Mgmt',
      children: (
        <WorkspaceStateManagement workspace={workspace} fetchData={fetchData} />
      ),
      disabled:
        (!userInfo.isUserManagement || workspace.deletedAt != null) ?? true,
    },
    {
      key: '6',
      label: 'Offboard',
      children: <WorkspaceOffboard workspace={workspace} />,
      disabled: !userInfo.IsInternalAdminReader,
    },
    {
      key: '7',
      label: 'Status Bar',
      children: <WorkspaceDetailsStatusBar workspace={workspace} />,
      disabled: !userInfo.IsInternalAdminWriter,
    },
  ]

  return (
    <Tabs
      defaultActiveKey={userInfo.isUserManagement ? '1' : '2'}
      items={tabItems.filter((tab) => !tab.disabled)}
      type="card"
    />
  )
}

export default WorkspaceDetailsImplementation
