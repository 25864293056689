import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'

import { FileToUpload } from './vault'

export interface VaultCreateProjectState {
  newFolderName: string
  droppedFiles: File[]
  filesToUpload: FileToUpload[]
  isDropzoneLoading: boolean
  isSubmitting: boolean
  totalFileSizeInBytes: number
  filesGroupedByVaultFolderId: Record<string, FileToUpload[]>
  vaultFolderIdToPrefix: Record<string, string>
  clientMatterName: string | null
  duplicateFiles: FileToUpload[]
}

export interface VaultCreateProjectActions {
  setNewFolderName: (name: string) => void
  setDroppedFiles: (files: File[]) => void
  setIsDropzoneLoading: (isLoading: boolean) => void
  setIsSubmitting: (isSubmitting: boolean) => void
  setTotalFileSizeInBytes: (size: number) => void
  setFilesToUpload: (files: FileToUpload[]) => void
  setFileUploadMetadata: (
    filesGroupedByVaultFolderId: Record<string, FileToUpload[]>,
    vaultFolderIdToPrefix: Record<string, string>
  ) => void
  setDuplicateFiles: (files: FileToUpload[]) => void
  clearFilesToUpload: () => void
  setClientMatterName: (clientMatterName: string | null) => void
}

export const useVaultCreateProjectStore = create(
  devtools(
    immer<VaultCreateProjectState & VaultCreateProjectActions>((set) => ({
      newFolderName: '',
      droppedFiles: [],
      filesToUpload: [],
      isSubmitting: false,
      isDropzoneLoading: false,
      totalFileSizeInBytes: 0,
      filesGroupedByVaultFolderId: {},
      vaultFolderIdToPrefix: {},
      clientMatterName: null,
      duplicateFiles: [],
      setNewFolderName: (name: string) => set({ newFolderName: name }),
      setDroppedFiles: (files: File[]) => set({ droppedFiles: files }),
      setIsSubmitting: (isSubmitting: boolean) => set({ isSubmitting }),
      setIsDropzoneLoading: (isLoading: boolean) =>
        set({ isDropzoneLoading: isLoading }),
      setFilesToUpload: (files: FileToUpload[]) =>
        set({ filesToUpload: files }),
      setTotalFileSizeInBytes: (size: number) =>
        set({ totalFileSizeInBytes: size }),
      setFileUploadMetadata: (
        filesGroupedByVaultFolderId: Record<string, FileToUpload[]>,
        vaultFolderIdToPrefix: Record<string, string>
      ) => set({ filesGroupedByVaultFolderId, vaultFolderIdToPrefix }),
      clearFilesToUpload: () =>
        set({
          filesToUpload: [],
          filesGroupedByVaultFolderId: {},
          vaultFolderIdToPrefix: {},
        }),
      setClientMatterName: (clientMatterName: string | null) =>
        set({ clientMatterName }),
      setDuplicateFiles: (files: FileToUpload[]) =>
        set({ duplicateFiles: files }),
    }))
  )
)
