import React, { useEffect } from 'react'
import { Routes, Route, useParams, useNavigate } from 'react-router-dom'
import { useMount, useUnmount } from 'react-use'

import _ from 'lodash'
import { useShallow } from 'zustand/react/shallow'

import { useSharingStore } from 'stores/sharing-store'

import { BaseAppPath } from 'components/base-app-path'
import { CLIENT_MATTER_URL_PARAM } from 'components/client-matters/client-matter-utils'
import { useClientMattersStore } from 'components/client-matters/client-matters-store'
import { AppMain } from 'components/common/app-main'
import { useAuthUser } from 'components/common/auth-context'
import FullscreenLoading from 'components/common/fullscreen-loading'
import RedirectWithQuery from 'components/common/redirects/redirect-with-query'
import GeneralStoreListener from 'components/general-store-listener'

import useVaultUploadFiles from './hooks/use-vault-upload-files'
import VaultQueryDetailWrapper from './query-detail/vault-query-detail'
import useVaultQueryDetailStore from './query-detail/vault-query-detail-store'
import { useVaultFolderUpdatesSubscription } from './utils/use-vault-folder-updates-subscription'
import {
  GenerateN1ResponseProps,
  GenerateNNResponseProps,
  GenerateQuestionsProps,
  QueryQuestionsResponseData,
  projectsPath,
  queriesPath,
  filesPath,
} from './utils/vault'
import { useVaultCreateProjectStore } from './utils/vault-create-project-store'
import {
  FetchVaultFolderShareStatus,
  SetVaultFolderLastOpened,
} from './utils/vault-fetcher'
import { useVaultFileExplorerStore } from './utils/vault-file-explorer-store'
import { useVaultSharingStore } from './utils/vault-sharing-store'
import { useVaultStore } from './utils/vault-store'
import VaultFilePreviewer from './vault-file-previewer'
import VaultProjectDetail from './vault-project-detail'
import VaultProjectQueries from './vault-project-queries'
import VaultQueryDetail from './vault-query-detail'

interface VaultV1V2QueryDetailWrapperProps {
  sendCancelRequestN1: () => void
  generateQuestions: (
    props: GenerateQuestionsProps
  ) => Promise<QueryQuestionsResponseData>
  generateNNResponse: (props: GenerateNNResponseProps) => Promise<void>
  generateN1Response: (props: GenerateN1ResponseProps) => Promise<void>
}

const VaultV1V2QueryDetailWrapper = ({
  sendCancelRequestN1,
  generateQuestions,
  generateNNResponse,
  generateN1Response,
}: VaultV1V2QueryDetailWrapperProps) => {
  const userInfo = useAuthUser()

  const [useV1QueryDetail, setUseV1QueryDetail] = useVaultQueryDetailStore(
    useShallow((s) => [s.useV1QueryDetail, s.setUseV1QueryDetail])
  )

  useUnmount(() => {
    setUseV1QueryDetail(false)
  })

  return (
    <>
      {userInfo.IsVaultV2User && !useV1QueryDetail ? (
        <VaultQueryDetailWrapper />
      ) : (
        <VaultQueryDetail
          sendCancelRequestN1={sendCancelRequestN1}
          generateQuestions={generateQuestions}
          generateNNResponse={generateNNResponse}
          generateN1Response={generateN1Response}
        />
      )}
    </>
  )
}

const VaultProjectLayout = ({
  sendCancelRequestN1,
  generateQuestions,
  generateNNResponse,
  generateN1Response,
}: {
  generateQuestions: (
    props: GenerateQuestionsProps
  ) => Promise<QueryQuestionsResponseData>
  generateNNResponse: (props: GenerateNNResponseProps) => Promise<void>
  generateN1Response: (props: GenerateN1ResponseProps) => Promise<void>
  sendCancelRequestN1: () => void
}) => {
  useVaultUploadFiles()
  const { projectId } = useParams()
  const userInfo = useAuthUser()
  const navigate = useNavigate()
  const [
    projectsMetadata,
    folderIdToVaultFolder,
    exampleProjectIds,
    areExampleProjectsLoaded,
    setIsProjectLayoutLoading,
    setCurrentProject,
    setAreUploadButtonsDisabled,
    upsertVaultFolders,
  ] = useVaultStore(
    useShallow((s) => [
      s.projectsMetadata,
      s.folderIdToVaultFolder,
      s.exampleProjectIds,
      s.areExampleProjectsLoaded,
      s.setIsProjectLayoutLoading,
      s.setCurrentProject,
      s.setAreUploadButtonsDisabled,
      s.upsertVaultFolders,
    ])
  )

  const clearFilesToUpload = useVaultCreateProjectStore(
    (s) => s.clearFilesToUpload
  )

  const setPermissionsForProjectId = useVaultSharingStore(
    (s) => s.setPermissionsForProjectId
  )
  const setIsFetchingFolderShareStatus = useVaultSharingStore(
    (s) => s.setIsFetchingFolderShareStatus
  )
  const setDidFetchFolderShareStatusFail = useSharingStore(
    (s) => s.setDidFetchSharingStatusFail
  )
  const clearSearchHandler = useVaultFileExplorerStore(
    (s) => s.clearSearchHandler
  )

  const [
    clientMatters,
    setSelectedClientMatter,
    setClientMatterSelectDisabled,
  ] = useClientMattersStore(
    useShallow((s) => [
      s.clientMatters,
      s.setSelectedClientMatter,
      s.setClientMatterSelectDisabled,
    ])
  )

  // we need to add this useMount to update the current project
  // because the current project is cleared when the component is unmounted
  // this will ensure that the header and other components have the correct project
  useMount(async () => {
    if (!projectId) return
    setIsProjectLayoutLoading(true)

    const project = folderIdToVaultFolder[projectId]
    if (project) {
      setCurrentProject(project)
    }
    setIsProjectLayoutLoading(false)

    if (userInfo.IsVaultProjectClientMatterUser) {
      // set current client matter if exists on project
      const clientMatter = clientMatters.find(
        (cm) => cm.id === project?.clientMatterId
      )
      setSelectedClientMatter(clientMatter)
      if (clientMatter && !_.isEmpty(clientMatter.name)) {
        // Update the URL with the selected client matter only if there are client matters
        const searchParams = new URLSearchParams(location.search)
        searchParams.set(CLIENT_MATTER_URL_PARAM, clientMatter.name)
        navigate(`${location.pathname}?${searchParams.toString()}`, {
          replace: true,
        })
        setClientMatterSelectDisabled(true)
      }
    }

    // update last opened time for the project
    try {
      void SetVaultFolderLastOpened(projectId)
      if (project) {
        upsertVaultFolders(
          [{ ...project, lastOpenedAt: new Date().toISOString() }],
          userInfo.dbId,
          false
        )
      }
    } catch (error) {
      console.error('Error updating last opened time for the project')
    }
  })

  useVaultFolderUpdatesSubscription(projectId!)

  useUnmount(() => {
    setAreUploadButtonsDisabled(true)
    clearFilesToUpload()
    setCurrentProject(null)
    setIsProjectLayoutLoading(false)
    clearSearchHandler()
    if (userInfo.IsVaultProjectClientMatterUser) {
      setClientMatterSelectDisabled(false)
    }
  })

  useEffect(() => {
    if (!projectId) return

    async function fetchVaultFolderShareStatus() {
      if (!projectId || !areExampleProjectsLoaded) return

      try {
        setIsFetchingFolderShareStatus(true)
        const response = await FetchVaultFolderShareStatus(projectId)
        setDidFetchFolderShareStatusFail(false)
        setPermissionsForProjectId({
          projectId,
          userId: userInfo.dbId,
          workspaceId: userInfo.workspace.id,
          permissions: response.shareStatus,
        })
      } catch (error) {
        setDidFetchFolderShareStatusFail(true)
        console.error('Error fetching vault folder share status')
      }
      setIsFetchingFolderShareStatus(false)
    }

    const isExampleProject = exampleProjectIds.has(projectId)
    if (userInfo.IsVaultViewSharesUser && !isExampleProject) {
      void fetchVaultFolderShareStatus()
    }
  }, [
    exampleProjectIds,
    areExampleProjectsLoaded,
    projectId,
    userInfo.IsVaultViewSharesUser,
    userInfo.dbId,
    userInfo.workspace.id,
    setDidFetchFolderShareStatusFail,
    setIsFetchingFolderShareStatus,
    setPermissionsForProjectId,
  ])

  if (!projectId) return null

  if (!projectsMetadata[projectId]) {
    return (
      <AppMain className="flex w-full">
        <FullscreenLoading isLoading zIndex="z-50" />
      </AppMain>
    )
  }

  // if the user navigates to /files, let's redirect them to the project page
  return (
    <>
      <GeneralStoreListener />
      <Routes>
        <Route
          path="/"
          element={
            <VaultProjectDetail
              generateQuestions={generateQuestions}
              generateNNResponse={generateNNResponse}
              generateN1Response={generateN1Response}
            />
          }
        />
        <Route
          path={filesPath}
          element={
            <RedirectWithQuery
              to={`${BaseAppPath.Vault}${projectsPath}${projectId}`}
            />
          }
        />
        <Route path={`${filesPath}:fileId`} element={<VaultFilePreviewer />} />
        <Route path={queriesPath} element={<VaultProjectQueries />} />
        <Route
          path={`${queriesPath}:queryId`}
          element={
            <VaultV1V2QueryDetailWrapper
              sendCancelRequestN1={sendCancelRequestN1}
              generateQuestions={generateQuestions}
              generateNNResponse={generateNNResponse}
              generateN1Response={generateN1Response}
            />
          }
        />
      </Routes>
    </>
  )
}

export default VaultProjectLayout
