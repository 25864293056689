import React from 'react'

import { HrvyInfoElement } from 'utils/source'
import { cn } from 'utils/utils'

export interface CitationProps {
  className?: string
  children: React.ReactNode
  href?: string
  isSelected?: boolean
  onClick?: (e: React.MouseEvent) => void
  citationRef?: (el: HrvyInfoElement) => void
}

const Citation = React.forwardRef<HrvyInfoElement, CitationProps>(
  (
    { className, children, href, isSelected, onClick, citationRef, ...props },
    ref
  ) => {
    const mergedRefs = (el: HrvyInfoElement) => {
      if (typeof ref === 'function') {
        ref(el)
      } else if (ref) {
        ref.current = el
      }
      if (citationRef) citationRef(el)
    }

    const cx = cn(
      'source inline-flex min-w-3.5 h-3.5 box-border px-0.5 shrink-0 items-center justify-center rounded border-[0.5px] bg-button-secondary text-2xs text-muted font-medium focus:outline-none focus-visible:ring-1 focus-visible:ring-ring',
      className,
      {
        'cursor-pointer transition hover:bg-button-secondary-hover':
          href || onClick,
        'not-italic no-underline': href,
        'border-background-interactive bg-interactive hover:bg-interactive text-primary-inverse':
          isSelected,
      },
      '[del_&]:bg-destructive [del_&]:border-destructive/50 [del_&]:text-destructive/50 [del_&]:hover:bg-destructive'
    )

    if (href) {
      return (
        <a
          href={href}
          className={cx}
          onClick={onClick}
          target="_blank"
          rel="noreferrer"
          ref={mergedRefs}
          {...props}
        >
          {children}
        </a>
      )
    }
    if (onClick) {
      return (
        <button className={cx} onClick={onClick} ref={mergedRefs} {...props}>
          {children}
        </button>
      )
    }

    return (
      <span className={cx} ref={mergedRefs} {...props}>
        {children}
      </span>
    )
  }
)

Citation.displayName = 'Citation'

export default Citation
