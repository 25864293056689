import React from 'react'

import { Accordion } from 'components/ui/accordion'
import { ScrollArea } from 'components/ui/scroll-area'
import useWorkflowTaxonomySelector from 'components/workflows/workflow/utils/use-workflow-taxonomy-selector'
import { WorkflowTaxonomyGroup } from 'components/workflows/workflow/utils/use-workflow-taxonomy-selector-group'

import { WorkflowTaxonomySelectorGroup } from './workflow-taxonomy-selector-group'

interface Props<T> {
  availableTaxonomies: T[]
  selectedTaxonomies: T[]
  setSelectedTaxonomies: (taxonomies: T[]) => void
}

export const WorkflowTaxonomySelector = <T extends WorkflowTaxonomyGroup>({
  availableTaxonomies,
  selectedTaxonomies,
  setSelectedTaxonomies,
}: Props<T>) => {
  const { openTaxonomies, handleTaxonomyChange, handleToggleTaxonomy } =
    useWorkflowTaxonomySelector()

  return (
    <ScrollArea
      className="h-full rounded-lg border"
      data-testid="company-profile-input-form"
      overflowHintSide="both"
    >
      <Accordion
        type="multiple"
        value={openTaxonomies}
        onValueChange={handleTaxonomyChange}
      >
        {availableTaxonomies.map((taxonomy) => {
          return (
            <WorkflowTaxonomySelectorGroup
              key={taxonomy.title}
              group={taxonomy}
              onToggleTaxonomy={handleToggleTaxonomy}
              selectedTaxonomies={selectedTaxonomies}
              setSelectedTaxonomies={setSelectedTaxonomies}
            />
          )
        })}
      </Accordion>
    </ScrollArea>
  )
}
