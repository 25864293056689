import React, { useEffect, useState } from 'react'

import {
  ColumnDef,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  PaginationState,
  SortingState,
  useReactTable,
} from '@tanstack/react-table'
import { Trash } from 'lucide-react'
import pluralize from 'pluralize'

import { removeUsersFromRole, WorkspaceRole } from 'models/roles'
import { Workspace } from 'models/workspace'

import { useNavigateWithQueryParams } from 'hooks/use-navigate-with-query-params'
import { getTableDateString } from 'utils/date-utils'
import { displayErrorMessage, displaySuccessMessage } from 'utils/toast'
import { cn } from 'utils/utils'

import { useAuthUser } from 'components/common/auth-context'
import { Button } from 'components/ui/button'
import { DataTable } from 'components/ui/data-table/data-table'
import DataTableFooter from 'components/ui/data-table/data-table-footer'
import DataTableHeader from 'components/ui/data-table/data-table-header'
import DataTableSortHeader from 'components/ui/data-table/data-table-sort-header'

interface RoleUsersTableProps {
  users: RoleUser[]
  isLoading: boolean
  workspaceRole: WorkspaceRole
  workspace: Workspace
  fetchRoleUsersCallback: () => void
}

export interface RoleUser {
  rolePk: string
  userEmail: string
  createdAt: string
  deletedAt: string | null
}

const RolesUsersTable = ({
  users,
  isLoading,
  workspaceRole,
  workspace,
  fetchRoleUsersCallback,
}: RoleUsersTableProps) => {
  const userInfo = useAuthUser()
  const [tableUsers, setTableUsers] = useState<RoleUser[]>(users)
  const navigate = useNavigateWithQueryParams()

  useEffect(() => {
    setTableUsers(users)
  }, [users])

  const userInspectorNavigate = (email: string) => {
    navigate(`/settings/internal_admin/user-inspector`, {
      state: { email },
    })
  }

  const columns: ColumnDef<RoleUser>[] = [
    {
      accessorKey: 'rolePk',
    },
    {
      accessorKey: 'userEmail',
      header: ({ column }) => {
        return <DataTableSortHeader column={column} header="Email" />
      },
      cell: ({ getValue, row }) => (
        <div
          className="ml-3 w-1/2 cursor-pointer text-sm"
          onClick={() => userInspectorNavigate(row.getValue('userEmail'))}
          onKeyDown={() => row.getValue('userEmail')}
          tabIndex={0}
          role="button"
        >
          {String(getValue())}
        </div>
      ),
      enableGlobalFilter: true,
    },
    {
      accessorKey: 'createdAt',
      header: ({ column }) => {
        return <DataTableSortHeader column={column} header="Date added" />
      },
      cell: ({ getValue, row }) => {
        const value = getValue()
        return (
          <div
            className="ml-3 w-1/2 cursor-pointer text-sm"
            onClick={() => userInspectorNavigate(row.getValue('userEmail'))}
            onKeyDown={() => row.getValue('userEmail')}
            tabIndex={0}
            role="button"
          >
            {value ? getTableDateString(value) : ''}
          </div>
        )
      },
      enableGlobalFilter: true,
    },
    {
      accessorKey: 'deletedAt',
      header: ({ column }) => {
        return <DataTableSortHeader column={column} header="Deleted At" />
      },
      cell: ({ getValue }) => {
        const value = getValue()
        return (
          <div className="ml-3 w-1/2 text-sm">
            {value ? getTableDateString(value) : ''}
          </div>
        )
      },
    },
    {
      accessorKey: 'actions',
      header: '',
      size: 1,
      cell: ({ row }) => {
        const deletedAt = row.getValue('deletedAt')
        if (deletedAt || workspace.deletedAt) {
          return <></>
        }
        return (
          <div className="float-right flex justify-end">
            <Button
              className="flex items-center justify-center rounded transition hover:bg-button-secondary-hover"
              variant="ghost"
              size="xsIcon"
              tooltip={`Remove ${row.getValue('userEmail')}`}
              tooltipSide="left"
              onClick={() =>
                onDeleteRoleUser(
                  row.getValue('rolePk'),
                  row.getValue('userEmail')
                )
              }
            >
              <Trash className="h-4 w-4 stroke-destructive" />
            </Button>
          </div>
        )
      },
      enableGlobalFilter: false,
    },
  ]

  const [filter, setFilter] = useState<string>('')

  const [tablePaginationState, setTablePaginationState] =
    useState<PaginationState>({
      pageIndex: 0,
      pageSize: 10,
    })
  const [sorting, setSorting] = useState<SortingState>([
    { id: 'deletedAt', desc: false },
  ])

  const table = useReactTable({
    data: tableUsers,
    columns,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setTablePaginationState,
    manualPagination: false,
    state: {
      sorting,
      pagination: tablePaginationState,
      globalFilter: filter,
      columnVisibility: {
        rolePk: false,
        actions: userInfo.isUserManagement ?? false,
      },
    },
    onGlobalFilterChange: setFilter,
    enableSorting: true,
    enableSortingRemoval: true,
  })

  const rowCountCopy = `${table.getFilteredRowModel().rows.length} ${pluralize(
    'users',
    table.getFilteredRowModel().rows.length
  )}`

  const onDeleteRoleUser = async (rolePk: string, userEmail: string) => {
    const removed = await removeUsersFromRole(rolePk, [userEmail])
    if (removed > 0) {
      displaySuccessMessage(
        `${userEmail} removed from ${workspaceRole.roleId}`,
        5
      )
      fetchRoleUsersCallback()
    } else {
      displayErrorMessage(
        `Failed to remove ${userEmail} from ${workspaceRole.roleId}`,
        5
      )
    }
  }

  if (!userInfo || !userInfo.IsInternalAdminReader) return <></>

  return (
    <div className="-mt-10">
      <DataTableHeader
        searchable={{ searchQuery: filter, setSearchQuery: setFilter }}
      />
      <div className="mt-2">
        <DataTable
          table={table}
          className={cn({ 'h-[620px]': !isLoading })}
          useVirtual={users.length > 100}
          isLoading={isLoading}
          emptyStateText="No users found"
          isRowDisabled={(row) => Boolean(row.getValue('deletedAt'))}
        />
      </div>
      <DataTableFooter table={table} isLoading={isLoading}>
        <p>{rowCountCopy}</p>
      </DataTableFooter>
    </div>
  )
}

export default RolesUsersTable
