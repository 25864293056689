import React, { useEffect, useMemo, useState } from 'react'
import { useMount, useUnmount } from 'react-use'

import { Folder, Users } from 'lucide-react'
import { useShallow } from 'zustand/react/shallow'

import { UploadedFile } from 'openapi/models/UploadedFile'
import { VaultFile } from 'openapi/models/VaultFile'
import { VaultFolder } from 'openapi/models/VaultFolder'

import { bytesToPreciseReadable } from 'utils/file-utils'
import { backendToReadable, cn } from 'utils/utils'

import AssistantFiles from 'components/assistant/components/assistant-files'
import AssistantInputSourceHeader from 'components/assistant/components/assistant-input-source-header'
import { FileUploadingState } from 'components/assistant/stores/assistant-store'
import {
  FileSource,
  isVaultKnowledgeSource,
  VAULT_SOURCE_SELECT_FILES_DESCRIPTION,
} from 'components/assistant/utils/assistant-knowledge-sources'
import { KnowledgeSourceItem } from 'components/assistant/utils/assistant-knowledge-sources'
import { BaseAppPath } from 'components/base-app-path'
import { useAnalytics } from 'components/common/analytics/analytics-context'
import { Button } from 'components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from 'components/ui/dialog'
import Icon from 'components/ui/icon/icon'
import FolderShieldIcon from 'components/ui/icons/folder-shield-icon'
import { ScrollArea } from 'components/ui/scroll-area'
import { SkeletonBlock } from 'components/ui/skeleton'
import { Spinner } from 'components/ui/spinner'
import VaultFileExplorer from 'components/vault/components/file-explorer/vault-file-explorer'
import { VaultCreateProjectCard } from 'components/vault/components/new-project/vault-create-project-card'
import useSharingPermissions from 'components/vault/hooks/use-sharing-permissions'
import { useVaultProjects } from 'components/vault/hooks/use-vault-projects'
import { newProjectPath } from 'components/vault/utils/vault'
import { useVaultFileExplorerStore } from 'components/vault/utils/vault-file-explorer-store'
import {
  getSelectedFiles,
  getSortedFilesBasedOnReviewQueryOrder,
} from 'components/vault/utils/vault-helpers'
import { useVaultStore } from 'components/vault/utils/vault-store'
import { pluralizeFiles } from 'components/vault/utils/vault-text-utils'

interface Props {
  knowledgeSource: KnowledgeSourceItem | null
  setKnowledgeSource: (knowledgeSource: KnowledgeSourceItem | null) => void
  onClose: () => void
}

const VaultKnowledgeSourcePicker = ({
  knowledgeSource,
  setKnowledgeSource,
  onClose,
}: Props) => {
  const [fileIdToVaultFile, folderIdToVaultFolder, setCurrentProject] =
    useVaultStore(
      useShallow((s) => [
        s.fileIdToVaultFile,
        s.folderIdToVaultFolder,
        s.setCurrentProject,
      ])
    )

  const isVaultSource = isVaultKnowledgeSource(knowledgeSource)

  const activeFileIds = useMemo(
    () => new Set(isVaultSource ? knowledgeSource.fileIds : []),
    [knowledgeSource, isVaultSource]
  )
  const activeFiles = Array.from(activeFileIds)
    .map((fileId) => fileIdToVaultFile[fileId])
    .filter(Boolean) as VaultFile[]

  const [showDialog, setShowDialog] = useState(!activeFileIds.size)

  const {
    projects: allVaultProjects,
    isFetching,
    areProjectsLoaded,
  } = useVaultProjects(undefined, {
    includeExamples: false,
    loadAllMetadata: true,
    // activeFileIds.size !== activeFiles.length if we 'Reuse query' in new tab
    isEnabled: showDialog || activeFileIds.size !== activeFiles.length,
  })

  const activeProject = isVaultSource
    ? allVaultProjects.find(
        (project) => project.id === knowledgeSource.folderId
      )
    : null

  const [selectedRows, setSelectedRows] = useVaultFileExplorerStore(
    useShallow((s) => [s.selectedRows, s.setSelectedRows])
  )
  const selectedFiles = getSelectedFiles(selectedRows)

  const handleClose = () => {
    setKnowledgeSource(null)
    setCurrentProject(null)
    onClose()
  }

  useUnmount(() => {
    setCurrentProject(null)
  })

  const handleOpenChange = (isOpen: boolean) => {
    setShowDialog(isOpen)
    if (!isOpen && !activeFiles.length) handleClose()
  }
  const handleSelectMore = () => {
    setShowDialog(true)
  }

  const handleSelectProject = (project: VaultFolder) => {
    setKnowledgeSource({
      type: FileSource.VAULT,
      folderId: project.id,
      fileIds: [],
    })
    setCurrentProject(project)
  }
  const handleResetFolder = () => {
    setKnowledgeSource({
      type: FileSource.VAULT,
      folderId: '',
      fileIds: [],
    })
    setCurrentProject(null)
  }

  const handleAddFiles = () => {
    if (!isVaultSource) return
    const sortedFiles = getSortedFilesBasedOnReviewQueryOrder(
      selectedFiles,
      folderIdToVaultFolder
    )
    setKnowledgeSource({
      ...knowledgeSource,
      fileIds: sortedFiles.map((file) => file.id),
    })
    setShowDialog(false)
  }

  const handleRemoveFile = (file: UploadedFile | FileUploadingState) => {
    if (!isVaultSource) return
    const newFiles = activeFiles.filter((f) => f && f.name !== file.name)
    setKnowledgeSource({
      ...knowledgeSource,
      fileIds: newFiles.map((file) => file.id),
    })
  }

  if (!showDialog) {
    const separator = <span className="mx-1.5 text-xs text-muted/50">•</span>
    return (
      <div className="flex h-full grow flex-col">
        <AssistantInputSourceHeader
          actions={
            <>
              <Button size="sm" variant="ghost" onClick={handleClose}>
                Cancel
              </Button>
              <Button size="sm" variant="outline" onClick={handleSelectMore}>
                Select more files
              </Button>
            </>
          }
        >
          Vault
          {separator}
          {activeProject?.name ?? 'Project'}
          {separator}
          {pluralizeFiles(activeFiles.length)}
        </AssistantInputSourceHeader>
        <ScrollArea className="min-h-0 grow">
          <AssistantFiles
            files={activeFiles as UploadedFile[]}
            handleRemoveFile={handleRemoveFile}
          />
        </ScrollArea>
      </div>
    )
  }

  const isEmpty = areProjectsLoaded && allVaultProjects.length === 0
  const dialogTitle = activeProject ? 'Add files' : 'Choose project'
  const dialogDescription = activeProject
    ? VAULT_SOURCE_SELECT_FILES_DESCRIPTION
    : 'Select a Vault project to browse files'

  return (
    <div className="flex grow items-center justify-center">
      <p className="text-muted">Selecting files from Vault…</p>
      <Dialog open={showDialog} onOpenChange={handleOpenChange}>
        <DialogContent
          className={cn('max-w-[800px]', {
            'h-[65vh]': !isEmpty,
          })}
          hasContainer={!activeProject}
          innerClassName="flex flex-col p-0"
        >
          {!isEmpty && (
            <DialogHeader className="px-6 pt-6">
              <DialogTitle className="flex items-center">
                {dialogTitle}{' '}
                {isFetching && (
                  <FetchingSpinner
                    delayMs={allVaultProjects.length > 0 ? 1000 : 0}
                  />
                )}
              </DialogTitle>
              <DialogDescription>{dialogDescription}</DialogDescription>
            </DialogHeader>
          )}
          {activeProject ? (
            <div className="flex min-h-0 grow flex-col space-y-4">
              <VaultFileExplorer
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                className="flex min-h-0 grow flex-col"
                isAddingFilesToQuery
                preselectFileIds={activeFileIds}
                projectId={activeProject.id}
              />
              <div className="flex items-center justify-between space-x-2 px-6 pb-6">
                {selectedFiles.length > 0 ? (
                  <p>{pluralizeFiles(selectedFiles.length)}</p>
                ) : (
                  <div />
                )}
                <div className="flex items-center space-x-2">
                  {!activeFiles.length && (
                    <Button variant="outline" onClick={handleResetFolder}>
                      Back
                    </Button>
                  )}
                  <Button
                    disabled={!selectedFiles.length}
                    onClick={handleAddFiles}
                  >
                    Add
                  </Button>
                </div>
              </div>
            </div>
          ) : isEmpty ? (
            <EmptyProjects />
          ) : (
            <ProjectChooser
              isLoaded={areProjectsLoaded}
              onSelectProject={handleSelectProject}
              projects={allVaultProjects}
            />
          )}
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default VaultKnowledgeSourcePicker

const ProjectChooser = ({
  isLoaded,
  onSelectProject,
  projects,
}: {
  isLoaded: boolean
  onSelectProject: (project: VaultFolder) => void
  projects: VaultFolder[]
}) => {
  const sharedProjectIds = useVaultStore((s) => s.sharedProjectIds)
  const isLoadingProjects = !isLoaded && !projects.length

  return (
    <div className="grid w-full grid-cols-1 gap-3 p-6 pt-1 sm:grid-cols-3">
      {projects.map((project) => (
        <ProjectCard
          key={project.id}
          project={project}
          onClick={() => onSelectProject(project)}
          isShared={sharedProjectIds.has(project.id)}
        />
      ))}
      {!isLoadingProjects && (
        <VaultCreateProjectCard size="small" className="h-40" />
      )}
      {isLoadingProjects &&
        [...new Array(4)].map((_, i) => (
          <ProjectCard
            key={i}
            project={{ id: `vault-placeholder-${i}` } as VaultFolder}
            isLoading
          />
        ))}
    </div>
  )
}

const EmptyProjects = () => {
  const { trackEvent } = useAnalytics()

  useMount(() => {
    trackEvent('No Vault Projects Dialog Shown')
  })

  const handleNewClick = () => {
    trackEvent('Vault New Project Button Clicked', {
      source: 'use-vault-in-assistant',
    })
  }

  return (
    <div className="flex flex-col items-center px-6 py-12 text-sm">
      <FolderShieldIcon className="h-6 w-6 shrink-0" />
      <div className="my-2 text-lg font-semibold">
        Create a project to get started
      </div>
      <p className="mb-4 max-w-md text-center text-muted">
        Vault allows you to upload and store files and perform sophisticated
        analysis and search across them.
      </p>
      <Button
        onClick={handleNewClick}
        to={`${BaseAppPath.Vault}${newProjectPath}`}
      >
        New project
      </Button>
    </div>
  )
}

const ProjectCard = ({
  project,
  onClick,
  isLoading,
  isShared,
}: {
  project: VaultFolder
  onClick?: () => void
  isLoading?: boolean
  isShared?: boolean
}) => {
  const projectsMetadata = useVaultStore((s) => s.projectsMetadata)
  const projectData = projectsMetadata[project.id]
  const numProjectFiles = projectData?.totalFiles || 0
  const projectSize = bytesToPreciseReadable(
    projectData?.folderSize || 0,
    2,
    true
  )

  const { doesCurrentUserHaveEditPermission } = useSharingPermissions({
    projectId: project.id,
  })

  return (
    <Button
      className="flex h-40 w-full flex-col items-start justify-start p-4"
      disabled={isLoading || !doesCurrentUserHaveEditPermission}
      onClick={onClick}
      tooltip={
        !doesCurrentUserHaveEditPermission
          ? 'You do not have permission to run queries on this project'
          : undefined
      }
      variant="outline"
    >
      <div className="text-xs text-muted">
        {isLoading ? (
          <SkeletonBlock className="h-4 w-24" hasDarkBackground />
        ) : (
          `Updated ${backendToReadable(project.updatedAt)}`
        )}
      </div>
      {isLoading ? (
        <SkeletonBlock className="mt-1 h-4 w-48" hasDarkBackground />
      ) : (
        <p className="line-clamp-3 w-full text-left">{project.name}</p>
      )}
      <div className="mt-auto w-full space-y-1">
        {isShared && (
          <div className="flex items-center space-x-1">
            <Icon icon={Users} size="small" className="text-muted" />
            <p className="truncate text-xs text-muted">
              Shared{project.userEmail && ` by ${project.userEmail}`}
            </p>
          </div>
        )}
        <div className="flex items-center space-x-1">
          <Icon icon={Folder} size="small" />
          {!projectData ? (
            <SkeletonBlock className="h-4 w-32" hasDarkBackground />
          ) : (
            <p className="text-xs">
              {numProjectFiles === 0
                ? 'No files uploaded'
                : `${pluralizeFiles(numProjectFiles)} (${projectSize})`}
            </p>
          )}
        </div>
      </div>
    </Button>
  )
}

const FetchingSpinner = ({ delayMs }: { delayMs: number }) => {
  const [isVisible, setIsVisible] = useState(!delayMs)

  useEffect(() => {
    if (!delayMs) return

    // Show spinner if request > 1 second
    const timer = setTimeout(() => {
      setIsVisible(true)
    }, delayMs)

    return () => clearTimeout(timer)
  })

  if (!isVisible) return null

  return <Spinner className="ml-3 h-3 w-3" />
}
