import React from 'react'
import { useEffect, useState } from 'react'

import _ from 'lodash'

import { EvaluationQuestionResponseOptionRating } from 'openapi/models/EvaluationQuestionResponseOptionRating'
import { Maybe } from 'types'

import { EvaluationQuestionResponseOptionInput } from 'components/settings/experiment/utils/experiment-utils'
import { Alert, AlertTitle } from 'components/ui/alert'
import { Input } from 'components/ui/input'
import { Label } from 'components/ui/label'

// TODO is this safe?
const EvaluationResponseOptionsRatingInput = ({
  responseOptions,
  setResponseOptions,
}: {
  responseOptions: Maybe<EvaluationQuestionResponseOptionInput>
  setResponseOptions: (
    responseOptions: Maybe<EvaluationQuestionResponseOptionInput>
  ) => void
}) => {
  const [minRating, setMinRating] = useState<number>()
  const [maxRating, setMaxRating] = useState<number>()
  const [stepSize, setStepSize] = useState<number>()
  const [errorMsg, setErrorMsg] = useState<string>()

  useEffect(() => {
    if (_.isNil(minRating) || _.isNil(maxRating) || _.isNil(stepSize)) {
      if (!_.isNil(responseOptions)) {
        setResponseOptions(undefined)
      }
      return
    }

    // conduct validation
    if (
      minRating >= maxRating ||
      (maxRating - minRating) % stepSize != 0 ||
      stepSize <= 0
    ) {
      setErrorMsg('Invalid input')
      if (!_.isNil(responseOptions)) {
        setResponseOptions(undefined)
      }
      return
    } else {
      setErrorMsg(undefined)
    }

    const newResponseOptions: EvaluationQuestionResponseOptionRating = {
      min: minRating,
      max: maxRating,
      step: stepSize,
    }
    setResponseOptions(newResponseOptions)
  }, [minRating, maxRating, stepSize, responseOptions, setResponseOptions])

  return (
    <>
      <div className="my-5 flex justify-between">
        <div>
          <Label className="font-normal">Minimum Rating</Label>
          <Input
            type="number"
            placeholder="Minimum Rating"
            value={minRating}
            onChange={(e) => setMinRating(Number(e.target.value))}
          />
        </div>
        <div>
          <Label className="font-normal">Maximum Rating</Label>
          <Input
            type="number"
            placeholder="Maximum Rating"
            value={maxRating}
            onChange={(e) => setMaxRating(Number(e.target.value))}
          />
        </div>
        <div>
          <Label className="font-normal">Step Size</Label>
          <Input
            type="number"
            placeholder="Step Size"
            value={stepSize}
            onChange={(e) => setStepSize(Number(e.target.value))}
          />
        </div>
      </div>
      {errorMsg && (
        <Alert variant="destructive" className="mb-2 mt-2">
          <AlertTitle>{errorMsg}</AlertTitle>
        </Alert>
      )}
    </>
  )
}

export default EvaluationResponseOptionsRatingInput
