import {
  GridApi,
  IRowNode,
  ProcessCellForExportParams,
  ProcessHeaderForExportParams,
  ProcessRowGroupForExportParams,
} from 'ag-grid-community'
import { HeadingLevel, convertMillimetersToTwip } from 'docx'
import saveAs from 'file-saver'
import { isEmpty } from 'lodash'
import * as XLSX from 'xlsx'

import { VaultFile } from 'openapi/models/VaultFile'

import { WordSection } from 'utils/docx'
import { downloadUploadedFiles } from 'utils/download'
import { exportWordWithSections, logExport } from 'utils/markdown'
import { SafeRecord } from 'utils/safe-types'
import { removeControlCharacters } from 'utils/string'
import { Source, TaskType } from 'utils/task'
import {
  displayErrorMessage,
  displayInfoMessage,
  displayWarningMessage,
} from 'utils/toast'
import { backendFormat, EM_DASH, s2ab } from 'utils/utils'

import {
  EXCLUDED_HEADER_NAMES,
  ExtendedColumnDef,
  HIDDEN_COLUMN_ID_SUFFIX,
} from 'components/vault/components/data-grid/vault-query-result-table'
import { EXCLUDED_HEADER_NAMES_FROM_EXPORT } from 'components/vault/query-detail/vault-query-detail'
import { ReviewHistoryItem } from 'components/vault/query-detail/vault-query-detail-store'
import { EXPIRATION_URL_KEY } from 'components/vault/utils/vault'
import { FetchVaultFiles } from 'components/vault/utils/vault-fetcher'
import {
  getDisplayAnswer,
  isUrlExpired,
} from 'components/vault/utils/vault-helpers'
import {
  VaultCurrentStreamingState,
  VaultReviewSocketState,
} from 'components/vault/utils/vault-store'

export enum ExportAnswerType {
  SHORT = 'short',
  LONG = 'long',
  BOTH = 'both',
}

const computeHeaderName = (
  isShowingLongResponses: boolean,
  field: string,
  headerName: string
): string => {
  if (isShowingLongResponses && field.endsWith(HIDDEN_COLUMN_ID_SUFFIX)) {
    return `${headerName} (Short)`
  } else if (
    isShowingLongResponses &&
    !field.endsWith(HIDDEN_COLUMN_ID_SUFFIX)
  ) {
    return `${headerName} (Long)`
  }

  if (!isShowingLongResponses && field.endsWith(HIDDEN_COLUMN_ID_SUFFIX)) {
    return `${headerName} (Long)`
  }

  return `${headerName} (Short)`
}

export const exportWordWithReviewState = async ({
  taskType,
  gridApi,
  queryId,
  onlySelected,
  exportAll,
  answerType = ExportAnswerType.BOTH,
  reviewState,
  fileIdToVaultFile,
}: {
  taskType: TaskType
  gridApi: GridApi | null
  queryId: string
  onlySelected: boolean
  exportAll: boolean
  answerType?: ExportAnswerType
  reviewState: VaultReviewSocketState
  fileIdToVaultFile: SafeRecord<string, VaultFile>
}) => {
  if (!gridApi) {
    displayErrorMessage(
      'Could not export word file at this time. Please try again later.'
    )
    return
  }

  let visibleRows: IRowNode[] = []
  if (exportAll) {
    gridApi.forEachNode((node) => {
      visibleRows.push(node)
    })
  } else {
    gridApi.forEachNodeAfterFilterAndSort((node) => {
      visibleRows.push(node)
    })
  }

  if (onlySelected) {
    visibleRows = gridApi.getSelectedNodes()
  }

  const reviewStateWithTitle = reviewState as VaultReviewSocketState &
    VaultCurrentStreamingState
  const title = reviewStateWithTitle.title || ''
  const columnHeaders = reviewStateWithTitle.columnHeaders

  const fileSections: WordSection[] = []
  visibleRows.forEach((row) => {
    let file
    if (row.data && row.data.file) {
      file = row.data.file
    } else if (row.id) {
      file = fileIdToVaultFile[row.id]
    }

    if (!file) {
      return
    }

    const fileId = file.id
    const fileName = file.name
    const fileNameWithoutExtension = fileName.split('.')[0]
    const fileSources = reviewStateWithTitle.fileIdToSources[fileId] ?? []

    // 1. Create the table
    let headerRow = `| Header |`
    let separatorRow = `| :--- |`

    if (answerType === ExportAnswerType.BOTH) {
      headerRow += ` Short Answer | Long Answer |`
      separatorRow += ` :--- | :--- |`
    } else if (answerType === ExportAnswerType.LONG) {
      headerRow += ` Long Answer |`
      separatorRow += ` :--- |`
    } else {
      headerRow += ` Short Answer |`
      separatorRow += ` | :--- |`
    }

    fileSections.push({
      type: `markdown`,
      options: {
        pageBreakBefore: true,
      },
      content: `## ${
        row.data.file.name
      }\n${headerRow}\n${separatorRow}\n${columnHeaders
        .map((header) => {
          const columnId = header.id

          const sourcesForAnswerText = fileSources
            .filter((s) => s.questionId === columnId)
            .map((s) => `[${s.footnote}]`)
            .join('')

          const longAnswer = getDisplayAnswer(
            reviewState.answers[fileId]?.find(
              (a) => a.columnId === columnId && a.long
            )
          ).replace(/\n/g, ' ')
          const shortAnswer = getDisplayAnswer(
            reviewState.answers[fileId]?.find(
              (a) => a.columnId === columnId && !a.long
            )
          ).replace(/\n/g, ' ')

          const outputText = `| ${header.text} ${sourcesForAnswerText} |`
          if (answerType === ExportAnswerType.LONG) {
            return outputText + ` ${longAnswer} |`
          } else if (answerType === ExportAnswerType.SHORT) {
            return outputText + ` ${shortAnswer} |`
          } else {
            return outputText + ` ${shortAnswer} | ${longAnswer} |`
          }
        })
        .join('\n')}`,
    })

    // 2. Create the sources
    const markedFileSources = fileSources.map((source) => {
      return {
        ...source,
        text: `${fileNameWithoutExtension} <mark>${source.text}</mark>`,
      }
    })
    fileSections.push({
      type: 'sources',
      content: markedFileSources as Source[],
      options: {
        pageBreakBefore: true,
        heading: HeadingLevel.HEADING_3,
        spacing: {
          before: convertMillimetersToTwip(1),
          after: convertMillimetersToTwip(1),
        },
      },
    })
  })

  await exportWordWithSections({
    title,
    taskType: taskType,
    queryId: queryId,
    includeAnnotation: true,
    useRemark: true,
    addTitleToSections: false,
    sections: fileSections,
  })
}

export const exportExcelWithReviewState = async ({
  taskType,
  sheetName,
  queryId,
  reviewState,
  fileIdToVaultFile,
  gridApi,
  exportAll,
  isShowingLongResponses,
  answerType = ExportAnswerType.BOTH,
  onlySelected,
  isCSV,
}: {
  taskType: TaskType
  sheetName: string
  queryId: string
  reviewState: VaultReviewSocketState
  fileIdToVaultFile: SafeRecord<string, VaultFile>
  gridApi: GridApi | null
  exportAll: boolean
  isShowingLongResponses: boolean
  answerType?: ExportAnswerType
  onlySelected?: boolean
  isCSV?: boolean
}): Promise<void> => {
  const dateTime = backendFormat(new Date())
  const fileName = `${taskType}_${dateTime}.${isCSV ? 'csv' : 'xlsx'}`
  const wb = XLSX.utils.book_new()
  const standardWidth = 20

  const shouldHideColumn = (field: string, headerName: string): boolean => {
    // do not hide excluded header names unless they are also explicitly excluded from export
    if (
      EXCLUDED_HEADER_NAMES.includes(headerName) &&
      !EXCLUDED_HEADER_NAMES_FROM_EXPORT.includes(headerName)
    ) {
      return false
    }

    if (answerType === ExportAnswerType.BOTH) {
      return false
    }

    if (answerType === ExportAnswerType.SHORT) {
      return isShowingLongResponses !== field.endsWith(HIDDEN_COLUMN_ID_SUFFIX)
    }

    if (answerType === ExportAnswerType.LONG) {
      return isShowingLongResponses === field.endsWith(HIDDEN_COLUMN_ID_SUFFIX)
    }

    return false
  }

  const getColumnKeys = () => {
    const columns = gridApi?.getColumns()
    return columns?.filter(
      (c) =>
        !(
          EXCLUDED_HEADER_NAMES_FROM_EXPORT.includes(c.getColId()) ||
          shouldHideColumn(
            c.getColId(),
            (c.getColDef() as ExtendedColumnDef).headerName
          )
        )
    )
  }

  const processHeaderCallback = (props: ProcessHeaderForExportParams) => {
    const column = props.column
    const colDef = column.getColDef() as ExtendedColumnDef
    const field = colDef.field
    const columnHeaderName = colDef.headerName
    if (
      !(
        EXCLUDED_HEADER_NAMES.includes(columnHeaderName) ||
        shouldHideColumn(field, columnHeaderName)
      )
    ) {
      return computeHeaderName(isShowingLongResponses, field, columnHeaderName)
    }
    return columnHeaderName
  }

  const processRowGroupCallback = (props: ProcessRowGroupForExportParams) => {
    return props.node.allLeafChildren.length > 0
      ? props.node.allLeafChildren[0].data.folderPath
      : '' // this should never happen
  }

  const processCellCallback = (props: ProcessCellForExportParams) => {
    if (!props.value) return ''
    const cellValue = props.value.toString()
    return removeControlCharacters(cellValue)
  }

  if (gridApi && isCSV) {
    gridApi.exportDataAsCsv({
      fileName,
      columnKeys: getColumnKeys(),
      exportedRows: exportAll ? 'all' : 'filteredAndSorted',
      onlySelected: onlySelected,
      processHeaderCallback,
      processRowGroupCallback,
      processCellCallback,
    })
    await logExport('csv', taskType, queryId)
    return
  } else if (gridApi && !isCSV) {
    gridApi.exportDataAsExcel({
      fileName,
      sheetName,
      columnKeys: getColumnKeys(),
      exportedRows: exportAll ? 'all' : 'filteredAndSorted',
      onlySelected: onlySelected,
      processHeaderCallback,
      processRowGroupCallback,
      processCellCallback,
    })
    await logExport('excel', taskType, queryId)
    return
  }
  console.error('No GridApi found, exporting manually')

  const data = Object.entries(reviewState.answers)
    .map(([fileId, answers]) => {
      const row: any = {
        Name: fileIdToVaultFile[fileId]?.name || 'N/A',
      }

      reviewState.columnHeaders.forEach((header) => {
        const longAnswer =
          answers?.find((a) => a.columnId === header.id && a.long)?.text || ''
        const shortAnswer =
          answers?.find((a) => a.columnId === header.id && !a.long)?.text || ''

        if (answerType !== ExportAnswerType.SHORT) {
          row[`${header.text} (Long)`] = longAnswer
        }
        if (answerType !== ExportAnswerType.LONG) {
          row[`${header.text} (Short)`] = shortAnswer
        }
      })

      return row
    })
    .sort((a, b) => a.Name.localeCompare(b.Name))

  const ws = XLSX.utils.json_to_sheet(data)

  ws['!cols'] = Array.from(
    { length: reviewState.columnHeaders.length * 2 + 1 },
    () => ({
      wch: standardWidth,
    })
  )

  const maxSheetNameLength = 31
  XLSX.utils.book_append_sheet(wb, ws, sheetName.slice(0, maxSheetNameLength))

  const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' })
  saveAs(
    new Blob([s2ab(wbout)], { type: 'application/octet-stream' }),
    fileName
  )

  await logExport('excel', taskType, queryId)
}

export const exportWordWithReviewStateV2 = async ({
  visibleRows,
  taskType,
  reviewState,
  shouldExportAdditionalContext,
  shouldIncludeQuestion,
  fileIdToVaultFile,
}: {
  visibleRows: IRowNode[]
  taskType: TaskType
  reviewState: ReviewHistoryItem
  shouldExportAdditionalContext: boolean
  shouldIncludeQuestion: boolean
  fileIdToVaultFile: SafeRecord<string, VaultFile>
}) => {
  const queryId = reviewState.queryId
  const title = reviewState.title
  const columns = reviewState.columns

  const fileSections: WordSection[] = []
  visibleRows.forEach((row) => {
    let file
    if (row.data && row.data.file) {
      file = row.data.file
    } else if (row.id) {
      file = fileIdToVaultFile[row.id]
    }
    if (!file) return

    const fileId = file.id
    const fileName = file.name
    const fileNameWithoutExtension = fileName.split('.')[0]
    const fileSources = reviewState.fileIdToSources[fileId] ?? []

    // 1. Create the table
    let headerRow = `| Header |`
    let separatorRow = `| :--- |`

    if (shouldIncludeQuestion && shouldExportAdditionalContext) {
      headerRow += ` Question | Summary | Additional Context |`
      separatorRow += ` :--- | :--- | :--- |`
    } else if (shouldIncludeQuestion) {
      headerRow += ` Question | Summary |`
      separatorRow += ` :--- | :--- |`
    } else if (shouldExportAdditionalContext) {
      headerRow += ` Summary | Additional Context |`
      separatorRow += ` :--- | :--- |`
    } else {
      headerRow += ` Summary |`
      separatorRow += ` :--- |`
    }

    fileSections.push({
      type: `markdown`,
      options: {
        pageBreakBefore: true,
      },
      content: `## ${
        row.data.file.name
      }\n${headerRow}\n${separatorRow}\n${columns
        .filter((c) => !c.isHidden)
        .map((column) => {
          const columnId = String(column.displayId)
          const header = column.header
          const question = column.fullText

          const sourcesForAnswerText = fileSources
            .filter((s) => s.questionId === columnId)
            .map((s) => `[${s.footnote}]`)
            .join('')

          const summaryAnswer = reviewState.answers[fileId]?.find(
            (a) => a.columnId === columnId && !a.long
          )
          const additionalContextAnswer = reviewState.answers[fileId]?.find(
            (a) => a.columnId === columnId && a.long
          )

          const summaryDisplayAnswer = getDisplayAnswer(summaryAnswer).replace(
            /\n/g,
            ' '
          )
          const additionalContextDisplayAnswer =
            additionalContextAnswer?.text === summaryAnswer?.text
              ? EM_DASH
              : getDisplayAnswer(additionalContextAnswer).replace(/\n/g, ' ')

          const outputText = `| ${header} ${sourcesForAnswerText} |`
          if (shouldIncludeQuestion && shouldExportAdditionalContext) {
            return (
              outputText +
              ` ${question} | ${summaryDisplayAnswer} | ${additionalContextDisplayAnswer} |`
            )
          } else if (shouldIncludeQuestion) {
            return outputText + ` ${question} | ${summaryDisplayAnswer} |`
          } else if (shouldExportAdditionalContext) {
            return (
              outputText +
              ` ${summaryDisplayAnswer} | ${additionalContextDisplayAnswer} |`
            )
          } else {
            return outputText + ` ${summaryDisplayAnswer} |`
          }
        })
        .join('\n')}`,
    })

    // 2. Create the sources
    const markedFileSources = fileSources.map((source) => {
      return {
        ...source,
        text: `${fileNameWithoutExtension} <mark>${source.text}</mark>`,
      }
    })
    fileSections.push({
      type: 'sources',
      content: markedFileSources as Source[],
      options: {
        pageBreakBefore: true,
        heading: HeadingLevel.HEADING_3,
        spacing: {
          before: convertMillimetersToTwip(1),
          after: convertMillimetersToTwip(1),
        },
      },
    })
  })

  await exportWordWithSections({
    title,
    taskType: taskType,
    queryId: queryId,
    includeAnnotation: true,
    useRemark: true,
    addTitleToSections: false,
    sections: fileSections,
  })
}

export const exportExcelWithReviewStateV2 = async ({
  visibleRowIds,
  taskType,
  sheetName,
  reviewState,
  fileIdToVaultFile,
  shouldExportAdditionalContext,
  shouldIncludeQuestion,
  isCSV,
}: {
  visibleRowIds: Set<string>
  taskType: TaskType
  sheetName: string
  reviewState: ReviewHistoryItem
  fileIdToVaultFile: SafeRecord<string, VaultFile>
  shouldExportAdditionalContext: boolean
  shouldIncludeQuestion: boolean
  isCSV: boolean
}) => {
  const standardWidth = 20
  const dateTime = backendFormat(new Date())
  const fileName = `${taskType}_${dateTime}.${isCSV ? 'csv' : 'xlsx'}`
  const wb = XLSX.utils.book_new()

  // unfortunately we cannot use the gridApi to export excel/csv because
  // the long answer is not in the grid anymore
  // instead we have to manually build the data
  const data = Object.entries(reviewState.answers)
    .filter(([fileId]) => visibleRowIds.has(fileId))
    .map(([fileId, answers]) => {
      const row: any = {
        Name: fileIdToVaultFile[fileId]?.name || 'N/A',
      }

      reviewState.columns
        .filter((c) => !c.isHidden)
        .forEach((column) => {
          const header = column.header
          const question = column.fullText
          const columnId = String(column.displayId)
          let columnHeader = header

          if (shouldIncludeQuestion) {
            columnHeader = `${header} (${question})`
          }

          const summaryAnswer = answers?.find(
            (a) => a.columnId === columnId && !a.long
          )
          const additionalContextAnswer = answers?.find(
            (a) => a.columnId === columnId && a.long
          )
          const summaryDisplayAnswer = getDisplayAnswer(summaryAnswer).replace(
            /\n/g,
            ' '
          )
          const additionalContextDisplayAnswer =
            additionalContextAnswer?.text === summaryAnswer?.text
              ? EM_DASH
              : getDisplayAnswer(additionalContextAnswer).replace(/\n/g, ' ')

          row[columnHeader] = summaryDisplayAnswer

          if (shouldExportAdditionalContext) {
            row[`${header} (Additional Context)`] =
              additionalContextDisplayAnswer
          }
          return row
        })

      return row
    })
    .sort((a, b) => a.Name.localeCompare(b.Name))

  const ws = XLSX.utils.json_to_sheet(data)
  ws['!cols'] = Array.from(
    { length: reviewState.columnHeaders.length * 2 + 1 },
    () => ({
      wch: standardWidth,
    })
  )

  const maxSheetNameLength = 31
  XLSX.utils.book_append_sheet(wb, ws, sheetName.slice(0, maxSheetNameLength))

  const wbout = XLSX.write(wb, {
    bookType: isCSV ? 'csv' : 'xlsx',
    type: 'binary',
  })
  saveAs(
    new Blob([s2ab(wbout)], { type: 'application/octet-stream' }),
    fileName
  )

  await logExport(isCSV ? 'csv' : 'excel', taskType, reviewState.queryId)
}

export const downloadFiles = async ({
  fileIdsToDownload,
  fileIdToVaultFile,
  downloadFileName,
  upsertVaultFiles,
}: {
  fileIdsToDownload: string[]
  fileIdToVaultFile: SafeRecord<string, VaultFile>
  downloadFileName: string
  upsertVaultFiles: (files: VaultFile[]) => void
}) => {
  displayInfoMessage('Your download will start shortly.', 5)
  const filesToDownload: { id: string; name: string; url: string }[] = []
  const filesNotReadyToDownload: string[] = []
  const fileUrlsNeeded: VaultFile[] = []
  fileIdsToDownload.forEach((fileId) => {
    const file = fileIdToVaultFile[fileId]
    if (!file) return

    // if the file is a temporary file, we need to wait for it to finish uploading to the system
    const localFileId = `${file.vaultFolderId}-${file.name}`
    if (localFileId === file.id) {
      filesNotReadyToDownload.push(fileId)
    } else if (
      file.docAsPdfUrl &&
      !isUrlExpired(file.docAsPdfUrl, EXPIRATION_URL_KEY)
    ) {
      filesToDownload.push({
        id: file.id,
        name: file.name,
        url: file.docAsPdfUrl,
      })
    } else if (file.url && !isUrlExpired(file.url, EXPIRATION_URL_KEY)) {
      filesToDownload.push({ id: file.id, name: file.name, url: file.url })
    } else {
      fileUrlsNeeded.push(file)
    }
  })
  if (fileUrlsNeeded.length > 0) {
    const newFileData = await FetchVaultFiles(fileUrlsNeeded.map((f) => f.id))
    if (isEmpty(newFileData)) return
    const newFileDataFiles = newFileData.files
    upsertVaultFiles(newFileDataFiles)
    newFileDataFiles.forEach((f) => {
      const fileUrl = f.docAsPdfUrl || f.url
      if (fileUrl) {
        filesToDownload.push({ id: f.id, name: f.name, url: fileUrl })
      }
    })
  }
  if (filesNotReadyToDownload.length > 0) {
    displayWarningMessage(
      `There are currently ${filesNotReadyToDownload.length} files that are not ready to download. Skipping these files. Please try again later.`
    )
  }
  await downloadUploadedFiles({
    uploadedFiles: filesToDownload,
    zippedFileName: downloadFileName,
    shouldZipSingleFile: true,
  })
}
