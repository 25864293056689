import React from 'react'

interface SharePointIconProps {
  className?: string
}

const SharePointIcon: React.FC<SharePointIconProps> = ({ className = '' }) => {
  return (
    <img
      className={className}
      src="/img/logos/sharepoint.svg"
      alt="SharePoint"
    />
  )
}

export default SharePointIcon
