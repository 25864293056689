// Import error handling and service abstraction as necessary
// Assuming a similar setup to your history fetcher
import _ from 'lodash'

import Services from 'services'

export interface UserComparison {
  userId: string
  comparisonId: string
  experimentId: string
  preference: string
  reason: string
  taxNetwork: string
}

export interface ComparisonDetail {
  comparisonId: string
  query: string
  responseA: string
  responseB: string
  modelA: string
  modelB: string
  taxNetwork: string
}

const ListUserExperiments = async (userEmail: string): Promise<string[]> => {
  const requestPath = 'experiments'
  const response = await Services.Backend.Post<string[]>(requestPath, {
    userEmail,
  })
  return response
}

const FetchUserComparisons = async (
  experimentId?: string
): Promise<UserComparison[]> => {
  const requestPath = experimentId ? `experiment/${experimentId}` : 'experiment'
  const response = await Services.Backend.Get<UserComparison[]>(requestPath)
  return response
}

const FetchExperimentSummary = async (
  experimentId?: string
): Promise<UserComparison[]> => {
  const requestPath = experimentId
    ? `experiment/${experimentId}/summary`
    : 'experiment'
  const response = await Services.Backend.Get<UserComparison[]>(requestPath)
  return response
}

const FetchExperimentCSV = async (experimentId: string) => {
  const requestPath = `experiment/${experimentId}/export`
  const csvData: string = await Services.Backend.Get(requestPath)
  const blob = new Blob([csvData], { type: 'text/csv' })
  const url = window.URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', `experiment_${experimentId}_results.csv`)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

const FetchComparisonDetails = async (
  comparisonId?: string
): Promise<ComparisonDetail> => {
  if (!comparisonId) {
    return {} as ComparisonDetail
  }
  const requestPath = `comparison/${comparisonId}`
  const result: ComparisonDetail = await Services.Backend.Get(requestPath)
  return result
}

interface DecideOnComparisonParams {
  comparisonId: string
  experimentId: string
  preference: string
  reason?: string
  userId: string
}

const DecideOnComparison = async (
  params: DecideOnComparisonParams
): Promise<boolean> => {
  const requestPath = `comparison/${params.comparisonId}`
  const result = await Services.Backend.Post(requestPath, {
    preference: params.preference,
    experimentId: params.experimentId,
    reason: params.reason || '',
    userId: params.userId,
  })
  return !_.isEmpty(result)
}

const AddUsersToExperiment = async (
  experimentId: string,
  userEmails: string[]
): Promise<UserComparison[]> => {
  const requestPath = `experiment/${experimentId}/users/add`
  const formData = new FormData()
  formData.append('user_emails', userEmails.join(', '))
  const result = await Services.Backend.Post(requestPath, formData)
  return result as UserComparison[]
}

const RemoveUsersFromExperiment = async (
  experimentId: string,
  userEmails: string[]
): Promise<UserComparison[]> => {
  const requestPath = `experiment/${experimentId}/users/remove`
  const formData = new FormData()
  formData.append('user_emails', userEmails.join(', '))
  const result = await Services.Backend.Post(requestPath, formData)
  return result as UserComparison[]
}

const CreateExperiment = async (
  userEmails: string[],
  experimentId: string,
  csvFile: File
) => {
  const formData = new FormData()
  formData.append('user_emails', userEmails.join(','))
  formData.append('experiment_id', experimentId)
  formData.append('csv_file', csvFile)

  const requestPath = `experiment`
  const result = await Services.Backend.Post(requestPath, formData)
  return !_.isEmpty(result)
}

const DeleteExperiment = async (experimentId: string): Promise<boolean> => {
  const requestPath = `experiment/${experimentId}`
  await Services.Backend.Delete(requestPath)
  return true
}

export {
  AddUsersToExperiment,
  RemoveUsersFromExperiment,
  FetchUserComparisons,
  FetchComparisonDetails,
  FetchExperimentSummary,
  FetchExperimentCSV,
  DecideOnComparison,
  ListUserExperiments,
  CreateExperiment,
  DeleteExperiment,
}
