import { ReviewCellResponse } from 'components/vault/utils/vault'

import { StrictCellRenderer } from './cell-renderer-types'

export class ClassifyCellRenderer extends StrictCellRenderer {
  renderCell(response: ReviewCellResponse) {
    // Split comma-separated values, clean whitespace, sort alphabetically, and rejoin with proper formatting
    return response.value
      .split(',')
      .map((item) => item.trim())
      .sort()
      .join(', ')
  }
  extractLabels(text: string) {
    return text.split(',').map((item) => item.trim())
  }
}
