import React, { useCallback, useMemo } from 'react'

import queryString from 'query-string'
import { useShallow } from 'zustand/react/shallow'

import { VaultFolder } from 'openapi/models/VaultFolder'
import { Maybe } from 'types'

import RouterBreadcrumbs from 'components/common/router-breadcrumbs'
import { Spinner } from 'components/ui/spinner'
import { folderIdSearchParamKey } from 'components/vault/utils/vault'
import { useVaultFileExplorerStore } from 'components/vault/utils/vault-file-explorer-store'
import { useVaultStore } from 'components/vault/utils/vault-store'

const VaultFileExplorerBreadcrumbs = () => {
  const currentFolderId = useVaultStore((s) => s.currentFolderId)
  const folderIdToVaultFolder = useVaultStore((s) => s.folderIdToVaultFolder)

  const isSearching = useVaultFileExplorerStore((state) => state.isSearching)
  const isSemanticSearching = useVaultFileExplorerStore(
    (state) => state.isSemanticSearching
  )
  const numRowsFound = useVaultFileExplorerStore(
    useShallow((state) => state.numRowsFound)
  )

  // Get the folders on the path to the current folder, if we already have
  // cached folders in the store, we can get valid foldersByPathIndex immediately.
  const foldersByPathIndex = useMemo(() => {
    const foldersOnPath: VaultFolder[] = []
    let folderId: Maybe<string> = currentFolderId
    while (folderId) {
      const folder: VaultFolder | undefined = folderIdToVaultFolder[folderId]
      if (!folder) {
        return []
      }
      foldersOnPath.push(folder)
      folderId = folder.parentId
    }
    return foldersOnPath.reverse()
  }, [folderIdToVaultFolder, currentFolderId])

  const folderPath = [
    'Project files',
    ...foldersByPathIndex.slice(1).map((f) => f.name),
  ].join('/')

  const pathForIndexFunc = useCallback(
    (index: number, pathname: string, search: string) => {
      const queryParams = queryString.parse(search)
      queryParams[folderIdSearchParamKey] = foldersByPathIndex[index].id
      return `${pathname}?${queryString.stringify(queryParams)}`
    },
    [foldersByPathIndex]
  )

  if (!folderPath) {
    return <p>Loading…</p>
  }

  if (isSearching) {
    return (
      <div className="flex items-center gap-2">
        {numRowsFound > 0 && (
          <p className="font-semibold">{numRowsFound} results</p>
        )}
        {isSemanticSearching && <Spinner className="h-4 w-4" />}
      </div>
    )
  }

  return (
    <RouterBreadcrumbs
      overridePath={folderPath}
      breadcrumbItemClassName="text-sm"
      keepCaseStartingFromIndex={0}
      checkHistoryIdInPath={false}
      pathForIndexFunc={pathForIndexFunc}
      collapseMargin={300}
      keepLastItem
    />
  )
}

export default VaultFileExplorerBreadcrumbs
