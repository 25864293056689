import React from 'react'
import { useState } from 'react'

import { Divider } from 'antd/lib'
import { format } from 'date-fns'
import _ from 'lodash'

import { FetchAuth0LoginLogsExport } from 'models/fetchers/history-fetcher'
import {
  Workspace,
  resetWorkspaceInterstitial,
  resetWorkspaceProductTour,
} from 'models/workspace'

import { displayErrorMessage, displaySuccessMessage } from 'utils/toast'
import { download } from 'utils/utils'

import { useAuthUser } from 'components/common/auth-context'
import ConfirmationDialog from 'components/common/confirmation-dialog/confirmation-dialog'
import { Button } from 'components/ui/button'
import { Card } from 'components/ui/card'
import { Dialog, DialogTrigger } from 'components/ui/dialog'
import { Label } from 'components/ui/label'
import { Spinner } from 'components/ui/spinner'

import WorkspaceDetailsHistoryManagement from './workspace-details-history-management'

interface WorkspaceStateManagementProps {
  workspace: Workspace
  fetchData: () => void
}

const WorkspaceStateManagement = ({
  workspace,
  fetchData,
}: WorkspaceStateManagementProps) => {
  const userInfo = useAuthUser()

  const resetUserInterstitial = async () => {
    if (_.isNil(userInfo) || !userInfo.isUserManagement) {
      displayErrorMessage(
        'You do not have permissions to reset the user interstitial'
      )
      return
    }
    const result = await resetWorkspaceInterstitial(workspace.id)
    fetchData()
    displaySuccessMessage(`${result.updatedUsers} users interstitial reset`)
  }

  const resetProductTour = async () => {
    if (_.isNil(userInfo) || !userInfo.IsInternalAdminWriter) {
      displayErrorMessage(
        'You do not have permissions to reset the product tour'
      )
      return
    }
    const result = await resetWorkspaceProductTour(workspace.id)
    fetchData()
    displaySuccessMessage(`Product tour reset for ${result.updatedUsers} users`)
  }

  const [isExportingLoginLogs, setIsExportingLoginLogs] = useState(false)

  const exportLoginLogs = async () => {
    if (_.isNil(userInfo) || !userInfo.IsInternalAdminWriter) {
      displayErrorMessage(
        'You do not have permissions to export the login logs'
      )
      return
    }
    setIsExportingLoginLogs(true)
    const csvData = await FetchAuth0LoginLogsExport(workspace.id)
    const blob = new Blob([csvData], { type: 'text/csv' })
    const url = window.URL.createObjectURL(blob)
    download(url, true, {
      download: `auth0_login_logs_${workspace.clientName}_${format(
        new Date(),
        'yyyyMMdd'
      )}.csv`,
    })
    setIsExportingLoginLogs(false)
    displaySuccessMessage(`Login logs exported`)
  }

  // user management is minimum permission
  if (_.isNil(userInfo) || !userInfo.isUserManagement) return <></>

  return (
    <Card className="p-6">
      <div className="flex flex-col items-start space-y-8">
        {userInfo.isUserManagement && (
          <div className="flex flex-col items-start space-y-2">
            <Label>User interstitial</Label>
            <Button>
              <Dialog>
                <DialogTrigger>Reset user interstitial</DialogTrigger>
                <ConfirmationDialog
                  title="Reset user interstitial"
                  description="This is non-reversible. If a user previously accepted the interstitial this will reset it and force the user to re-accept it."
                  cta={{
                    label: 'Reset',
                    onClick: () => {
                      resetUserInterstitial()
                    },
                  }}
                  secondaryCta={{
                    label: 'Cancel',
                    onClick: () => {
                      ;() => {}
                    },
                  }}
                  variant="destructive"
                  showCloseIcon={false}
                />
              </Dialog>
            </Button>
          </div>
        )}
        {userInfo.IsInternalAdminWriter && (
          <div className="flex flex-col items-start space-y-2">
            <Label>Product tour</Label>
            <Button>
              <Dialog>
                <DialogTrigger>Reset product tour</DialogTrigger>
                <ConfirmationDialog
                  title="Reset product tour"
                  description="This will reset the product tour for ALL users in this workspace."
                  cta={{
                    label: 'Reset',
                    onClick: () => {
                      resetProductTour()
                    },
                  }}
                  secondaryCta={{
                    label: 'Cancel',
                    onClick: () => {
                      ;() => {}
                    },
                  }}
                  variant="destructive"
                  showCloseIcon={false}
                />
              </Dialog>
            </Button>
          </div>
        )}
        {userInfo.IsInternalAdminWriter && !_.isEmpty(workspace.domains) && (
          <div className="flex flex-col items-start space-y-2">
            <Label>Auth0 login logs (max of 1000 entries)</Label>
            <Button disabled={isExportingLoginLogs} onClick={exportLoginLogs}>
              {isExportingLoginLogs ? 'Exporting…' : 'Export (last week)'}
              {isExportingLoginLogs && <Spinner className="ml-2 h-3 w-3" />}
            </Button>
          </div>
        )}
      </div>
      {userInfo.IsReadSensitiveDataUser && _.isNil(workspace.deletedAt) && (
        <>
          <Divider>History Management</Divider>
          <div className="mt-4">
            <WorkspaceDetailsHistoryManagement workspace={workspace} />
          </div>
        </>
      )}
    </Card>
  )
}

export default WorkspaceStateManagement
