import React from 'react'

import _ from 'lodash'
import { useShallow } from 'zustand/react/shallow'

import { SocketMessageResponse } from 'openapi/models/SocketMessageResponse'

import Markdown from 'components/common/markdown/markdown'
import SourcePopover from 'components/common/source-popover'
import Skeleton from 'components/ui/skeleton'
import { useSelectedCitation } from 'components/workflows/workflow/discovery/common/use-selected-citation'
import { useDiligenceStore } from 'components/workflows/workflow/discovery/diligence-store'
import { FollowUpQAPair } from 'components/workflows/workflow/discovery/diligence-store'
import { findSource } from 'components/workflows/workflow/discovery/util'

const OneFollowUpQuestionAnswer = ({
  followUpQAPair,
  id,
}: {
  followUpQAPair: FollowUpQAPair
  id: string
}) => {
  const { selectedSourceFileId, selectedSourceId, setSelectedCitation } =
    useSelectedCitation()

  const getHrvyInfoMetadataFactory = (
    answer: Partial<SocketMessageResponse>
  ) => {
    return (identifier: string) => {
      const source = findSource(identifier, answer)
      const onClick = () => setSelectedCitation(source)

      const hoverContent = <SourcePopover source={source} onClick={onClick} />

      return {
        onClick,
        hoverContent,
        isSelected:
          source.documentId === selectedSourceFileId &&
          source.id === selectedSourceId,
      }
    }
  }

  return (
    <div id={`follow-up-query-${id}`}>
      <h2 className="grow">{`Question ${id}`}</h2>
      <Markdown content={followUpQAPair.question} />
      <h2 className="grow">Answer</h2>
      <div className="mt-4">
        {followUpQAPair.answer.response || !followUpQAPair.isStreaming ? (
          <Markdown
            content={followUpQAPair.answer.response ?? ''}
            getHrvyInfoMetadata={(identifier: string) => {
              const fn = getHrvyInfoMetadataFactory(followUpQAPair.answer)
              return fn(identifier)
            }}
          />
        ) : (
          <Skeleton className="py-4" rows={3} rowHeight="h-4" />
        )}
      </div>
    </div>
  )
}

type Props = {
  id: string
}

export const FollowUpAnswersSection = React.forwardRef<HTMLDivElement, Props>(
  ({ id }, ref) => {
    const [followUpQAPairs] = useDiligenceStore(
      useShallow((s) => [s.followUpQAPairs])
    )

    const answerComponent = (
      <div
        id={id}
        ref={ref}
        className="border-t py-12 first-of-type:border-0 first-of-type:pt-8 last-of-type:pb-16"
      >
        <div className="mx-auto flex w-full max-w-[936px] px-8">
          <div
            id="follow-up-div"
            className="prose prose-sm prose-harvey max-w-full flex-1"
          >
            <h1 id="follow-up" className="grow">
              Follow-up Questions and Answers
            </h1>

            {followUpQAPairs.map((followUpQAPair, index) => (
              <OneFollowUpQuestionAnswer
                key={index + 1}
                followUpQAPair={followUpQAPair}
                id={(index + 1).toString()}
              />
            ))}
          </div>
        </div>
      </div>
    )

    return !_.isEmpty(followUpQAPairs) ? answerComponent : null
  }
)

FollowUpAnswersSection.displayName = 'FollowUpAnswersSection'
