import React from 'react'

import { useShallow } from 'zustand/react/shallow'

import { TranscriptType } from 'openapi/models/TranscriptType'
import { WorkflowType } from 'types/workflows'

import ExportDialog from 'components/common/export/export-dialog'
import { HeaderActions } from 'components/workflows/workflow/transcripts/common/header-actions'
import WorkflowLayout from 'components/workflows/workflow/workflow-layout'

import { useTranscriptsStore } from './transcripts-store'
import { handleExport } from './util/util'

type Props = {
  children: React.ReactNode
  type?: TranscriptType
}

export const TranscriptsLayout: React.FC<Props> = ({ children, type }) => {
  const [documents, questions] = useTranscriptsStore(
    useShallow((s) => [s.documents, s.questions])
  )

  return (
    <WorkflowLayout
      workflowType={WorkflowType.TRANSCRIPTS}
      title="Transcripts"
      appHeaderActions={
        <>
          <HeaderActions type={type} />
          <ExportDialog
            onExport={() => handleExport(documents, questions)}
            disabled={
              documents.some((doc) => doc.isLoading) || !documents.length
            }
          />
        </>
      }
    >
      {children}
    </WorkflowLayout>
  )
}
