import { GridApi } from 'ag-grid-community'

import { FilterType } from 'components/vault/utils/vault-data-grid-filters-store'

export abstract class ColumnFilter {
  abstract getUniqueColumnValues(
    gridApi: GridApi,
    currentFilterQuestionId: string
  ): string[]
  abstract getFilterType(): FilterType
}
