/* tslint:disable */
/* eslint-disable */
/**
 * Transcripts API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TranscriptsDocumentQaFile
 */
export interface TranscriptsDocumentQaFile {
    /**
     * 
     * @type {string}
     * @memberof TranscriptsDocumentQaFile
     */
    path?: string;
    /**
     * 
     * @type {string}
     * @memberof TranscriptsDocumentQaFile
     */
    signedUrl?: string;
}

/**
 * Check if a given object implements the TranscriptsDocumentQaFile interface.
 */
export function instanceOfTranscriptsDocumentQaFile(value: object): value is TranscriptsDocumentQaFile {
    return true;
}

export function TranscriptsDocumentQaFileFromJSON(json: any): TranscriptsDocumentQaFile {
    return TranscriptsDocumentQaFileFromJSONTyped(json, false);
}

export function TranscriptsDocumentQaFileFromJSONTyped(json: any, ignoreDiscriminator: boolean): TranscriptsDocumentQaFile {
    if (json == null) {
        return json;
    }
    return {
        
        'path': json['path'] == null ? undefined : json['path'],
        'signedUrl': json['signed_url'] == null ? undefined : json['signed_url'],
    };
}

export function TranscriptsDocumentQaFileToJSON(value?: TranscriptsDocumentQaFile | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'path': value['path'],
        'signed_url': value['signedUrl'],
    };
}

