import { Feedback } from 'openapi/models/Feedback'
import { MessageFeedback } from 'openapi/models/MessageFeedback'
import Services from 'services'
import { RequestError } from 'services/backend/backend'

export async function fetchEventFeedbackSentiments(
  eventId: number
): Promise<Feedback[]> {
  const url = `event/${eventId}/feedback_sentiments`
  return await Services.Backend.Get<Feedback[]>(url, { throwOnError: true })
}

export async function submitEventFeedback(
  eventId: number,
  feedbackData: Partial<Feedback>
): Promise<Feedback> {
  const url = `event/${eventId}/feedback`
  return await Services.Backend.Post<Feedback>(url, feedbackData, {
    throwOnError: true,
  })
}

export async function updateEventFeedback(
  eventId: number,
  feedbackId: string,
  feedbackData: MessageFeedback
): Promise<Feedback> {
  const url = `event/${eventId}/feedback/${feedbackId}`
  const response = await Services.Backend.Patch<Feedback>(url, feedbackData, {
    throwOnError: true,
  })
  if (response instanceof RequestError) {
    throw response
  }
  return response
}
