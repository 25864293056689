export interface WorkflowTaxonomyGroup {
  title: string
  rows: string[]
  description?: string
}

export interface Props<T> {
  group: T
  onToggleTaxonomy: (value: string, isExpanded: boolean) => void
  selectedTaxonomies: T[]
  setSelectedTaxonomies: (taxonomies: T[]) => void
}

function useWorkflowTaxonomyGroupSelection<T extends WorkflowTaxonomyGroup>({
  group,
  onToggleTaxonomy,
  selectedTaxonomies,
  setSelectedTaxonomies,
}: Props<T>) {
  const areAllRowsSelected = group.rows.every((row) =>
    selectedTaxonomies.some(
      (groups) => groups.title === group.title && groups.rows.includes(row)
    )
  )

  const onTaxonomyRowCheckedChange = (title: string, term: string) => {
    if (!selectedTaxonomies.some((groups) => groups.title === title)) {
      setSelectedTaxonomies([
        ...selectedTaxonomies,
        { title, rows: [term] } as T,
      ])
      return
    }
    const newSelectedTaxonomies = selectedTaxonomies.map((groups) => {
      if (groups.title === title) {
        if (groups.rows.includes(term)) {
          return {
            ...groups,
            rows: groups.rows.filter((row) => row !== term),
          }
        } else {
          return {
            ...groups,
            rows: [...groups.rows, term],
          }
        }
      }
      return groups
    })
    setSelectedTaxonomies(newSelectedTaxonomies)
  }

  const toggleSelectAll = () => {
    if (!selectedTaxonomies.some((groups) => groups.title === group.title)) {
      setSelectedTaxonomies([
        ...selectedTaxonomies,
        { title: group.title, rows: group.rows } as T,
      ])
      return
    }
    const newSelectedTaxonomies = selectedTaxonomies.map((groups) => {
      if (groups.title === group.title) {
        if (areAllRowsSelected) {
          return {
            ...groups,
            rows: groups.rows.filter((row) => !group.rows.includes(row)),
          }
        } else {
          return {
            ...groups,
            rows: Array.from(new Set([...groups.rows, ...group.rows])),
          }
        }
      }
      return groups
    })
    setSelectedTaxonomies(newSelectedTaxonomies)
  }

  const toggleSelectAllTrigger = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    onToggleTaxonomy(group.title, !areAllRowsSelected)
    toggleSelectAll()
  }

  const numberSelectedGroup = selectedTaxonomies.filter(
    (groups) => groups.title === group.title
  )[0]

  const numberSelected = numberSelectedGroup
    ? numberSelectedGroup.rows.length
    : 0

  return {
    numberSelected,
    areAllRowsSelected,
    onTaxonomyRowCheckedChange,
    toggleSelectAllTrigger,
  }
}

export default useWorkflowTaxonomyGroupSelection
