import React, { useState } from 'react'

import { useQueryClient } from '@tanstack/react-query'

import { HarvQueryKeyPrefix } from 'models/queries/all-query-keys'
import { useWrappedQuery } from 'models/queries/lib/use-wrapped-query'
import { SharingSettings } from 'openapi/models/SharingSettings'

import { displayErrorMessage } from 'utils/toast'
import { cn } from 'utils/utils'

import { useAuthUser } from 'components/common/auth-context'
import ConfirmationDialog from 'components/common/confirmation-dialog/confirmation-dialog'
import SettingsAppHeader from 'components/settings/settings-app-header'
import SettingsLayout from 'components/settings/settings-layout'
import { useSettingsState } from 'components/settings/settings-store'
import { Card, CardContent, CardHeader } from 'components/ui/card'
import { Dialog } from 'components/ui/dialog'
import { Switch } from 'components/ui/switch'

import {
  fetchSharingSettingsForWorkspace,
  updateSharingSettingsForWorkspace,
} from './settings-sharing-fetcher'

export const SettingsSharing: React.FC = () => {
  const queryClient = useQueryClient()
  const userInfo = useAuthUser()
  const settingsUser = useSettingsState((state) => state.settingsUser)

  const [showConfirmationDialog, setShowConfirmationDialog] =
    useState<boolean>(false)
  const [settingToDisable, setSettingToDisable] = useState<
    | 'assistantSharing'
    | 'assistantWorkspaceLevel'
    | 'vaultSharing'
    | 'vaultWorkspaceLevel'
    | null
  >(null)

  const isAssistantSharingEnabled = userInfo.IsAssistantSharingManagementUser
  const isVaultSharingEnabled =
    userInfo.IsVaultSharingManagementUser &&
    settingsUser?.workspace.hasVaultAddOn
  const { data: sharingSettings, isPending } = useWrappedQuery({
    queryKey: [HarvQueryKeyPrefix.SharingSettings, settingsUser?.workspace.id],
    queryFn: () => fetchSharingSettingsForWorkspace(settingsUser!.workspace.id),
    enabled:
      !!settingsUser && (isAssistantSharingEnabled || isVaultSharingEnabled),
  })

  if (!settingsUser?.IsSharingManagementUser) {
    return null
  }

  const onUpdateSharingSettings = async (sharingSettings: SharingSettings) => {
    try {
      await updateSharingSettingsForWorkspace(
        settingsUser!.workspace.id,
        sharingSettings
      )
      await queryClient.invalidateQueries({
        queryKey: [
          HarvQueryKeyPrefix.SharingSettings,
          settingsUser.workspace.id,
        ],
      })
    } catch (error) {
      console.error(error)
      displayErrorMessage('Failed to update sharing settings')
    }
  }

  const onDisableSharingSettings = async () => {
    const updatedSharingSettings = {
      assistant: {
        enabled:
          settingToDisable === 'assistantSharing'
            ? false
            : sharingSettings?.assistant.enabled ?? false,
        workspaceLevel:
          settingToDisable === 'assistantWorkspaceLevel' ||
          settingToDisable === 'assistantSharing'
            ? false
            : sharingSettings?.assistant.workspaceLevel ?? false,
      },
      vault: {
        enabled:
          settingToDisable === 'vaultSharing'
            ? false
            : sharingSettings?.vault.enabled ?? false,
        workspaceLevel:
          settingToDisable === 'vaultWorkspaceLevel' ||
          settingToDisable === 'vaultSharing'
            ? false
            : sharingSettings?.vault.workspaceLevel ?? false,
      },
    }
    await onUpdateSharingSettings(updatedSharingSettings)
  }

  const confirmationDialogTitle = (() => {
    switch (settingToDisable) {
      case 'vaultWorkspaceLevel':
        return 'Disable Vault workspace-level sharing'
      case 'vaultSharing':
        return 'Disable Vault sharing'
      case 'assistantWorkspaceLevel':
        return 'Disable Assistant workspace-level sharing'
      case 'assistantSharing':
        return 'Disable Assistant sharing'
      default:
        return ''
    }
  })()

  const confirmationDialogDescription = (() => {
    switch (settingToDisable) {
      case 'vaultWorkspaceLevel':
        return 'Are you sure? This will permanently unshare all Vault projects shared with the workspace. This cannot be undone.'
      case 'vaultSharing':
        return 'Are you sure? This will permanently unshare all Vault projects shared with individual users and the workspace. This cannot be undone.'
      case 'assistantWorkspaceLevel':
        return 'Are you sure? This will permanently unshare all Assistant queries shared with the workspace. This cannot be undone.'
      case 'assistantSharing':
        return 'Are you sure? This will permanently unshare all Assistant queries shared with individual users and the workspace. This cannot be undone.'
      default:
        return ''
    }
  })()

  return (
    <>
      <SettingsAppHeader />
      <SettingsLayout>
        <div className="space-y-6">
          {!isAssistantSharingEnabled && !isVaultSharingEnabled && (
            <Card>
              <CardHeader>
                <h2 className="text-lg font-semibold">
                  There are no sharing settings to be configured
                </h2>
              </CardHeader>
              <CardContent>
                <p className="text-sm text-muted">
                  Come back later when your workspace can enable sharing
                  settings for Assistant and/or Vault.
                </p>
              </CardContent>
            </Card>
          )}
          <Card className={cn({ hidden: !isAssistantSharingEnabled })}>
            <CardHeader>
              <h2 className="text-lg font-semibold">Assistant</h2>
            </CardHeader>
            <CardContent>
              <div className="flex max-w-[600px] items-center justify-between gap-2">
                <p className="text-sm font-medium">
                  Allow users to share Assistant queries
                </p>
                <Switch
                  checked={sharingSettings?.assistant.enabled}
                  onCheckedChange={async (checked) => {
                    if (!checked) {
                      setShowConfirmationDialog(true)
                      setSettingToDisable('assistantSharing')
                    } else {
                      await onUpdateSharingSettings({
                        assistant: {
                          enabled: checked,
                          workspaceLevel:
                            sharingSettings?.assistant.workspaceLevel ?? false,
                        },
                        vault: sharingSettings?.vault ?? {
                          enabled: sharingSettings?.vault.enabled ?? false,
                          workspaceLevel:
                            sharingSettings?.vault.workspaceLevel ?? false,
                        },
                      })
                    }
                  }}
                  disabled={isPending}
                />
              </div>
              <div
                className={cn(
                  'ml-4 mt-4 flex max-w-[584px] items-center justify-between gap-2',
                  {
                    'cursor-not-allowed opacity-50':
                      !sharingSettings?.assistant.enabled,
                  }
                )}
              >
                <p className="text-sm font-medium">
                  Allow workspace-level sharing
                </p>
                <Switch
                  checked={sharingSettings?.assistant.workspaceLevel}
                  onCheckedChange={async (checked) => {
                    if (!checked) {
                      setShowConfirmationDialog(true)
                      setSettingToDisable('assistantWorkspaceLevel')
                    } else {
                      await onUpdateSharingSettings({
                        assistant: {
                          enabled: sharingSettings?.assistant.enabled ?? false,
                          workspaceLevel: checked,
                        },
                        vault: sharingSettings?.vault ?? {
                          enabled: sharingSettings?.vault.enabled ?? false,
                          workspaceLevel:
                            sharingSettings?.vault.workspaceLevel ?? false,
                        },
                      })
                    }
                  }}
                  disabled={isPending || !sharingSettings?.assistant.enabled}
                />
              </div>
              <p
                className={cn('ml-4 mt-2 max-w-[440px] text-sm text-muted', {
                  'cursor-not-allowed opacity-50':
                    !sharingSettings?.assistant.enabled,
                })}
              >
                When enabled, query creators will be able to share their queries
                with the entire workspace.
              </p>
            </CardContent>
          </Card>
          <Card className={cn({ hidden: !isVaultSharingEnabled })}>
            <CardHeader>
              <h2 className="text-lg font-semibold">Vault</h2>
            </CardHeader>
            <CardContent>
              <div className="flex max-w-[600px] items-center justify-between gap-2">
                <p className="text-sm font-medium">
                  Allow users to share Vault projects
                </p>
                <Switch
                  checked={sharingSettings?.vault.enabled}
                  onCheckedChange={async (checked) => {
                    if (!checked) {
                      setShowConfirmationDialog(true)
                      setSettingToDisable('vaultSharing')
                    } else {
                      await onUpdateSharingSettings({
                        assistant: sharingSettings?.assistant ?? {
                          enabled: false,
                          workspaceLevel: false,
                        },
                        vault: {
                          enabled: checked,
                          workspaceLevel:
                            sharingSettings?.vault.workspaceLevel ?? false,
                        },
                      })
                    }
                  }}
                  disabled={isPending}
                  data-testid="vault-sharing-settings-toggle"
                />
              </div>
              <div
                className={cn(
                  'ml-4 mt-4 flex max-w-[584px] items-center justify-between gap-2',
                  {
                    'cursor-not-allowed opacity-50':
                      !sharingSettings?.vault.enabled,
                  }
                )}
              >
                <p className="text-sm font-medium">
                  Allow workspace-level sharing
                </p>
                <Switch
                  checked={sharingSettings?.vault.workspaceLevel}
                  onCheckedChange={async (checked) => {
                    if (!checked) {
                      setShowConfirmationDialog(true)
                      setSettingToDisable('vaultWorkspaceLevel')
                    } else {
                      await onUpdateSharingSettings({
                        assistant: sharingSettings?.assistant ?? {
                          enabled: sharingSettings?.assistant.enabled ?? false,
                          workspaceLevel:
                            sharingSettings?.assistant.workspaceLevel ?? false,
                        },
                        vault: {
                          enabled: sharingSettings?.vault.enabled ?? false,
                          workspaceLevel: checked,
                        },
                      })
                    }
                  }}
                  disabled={isPending || !sharingSettings?.vault.enabled}
                  data-testid="vault-workspace-level-sharing-settings-toggle"
                />
              </div>
              <p
                className={cn('ml-4 mt-2 max-w-[440px] text-sm text-muted', {
                  'cursor-not-allowed opacity-50':
                    !sharingSettings?.vault.enabled,
                })}
              >
                When enabled, project owners and users with full access project
                permissions will be able to share projects with the entire
                workspace.
              </p>
            </CardContent>
          </Card>
        </div>
      </SettingsLayout>
      <Dialog
        open={showConfirmationDialog}
        onOpenChange={setShowConfirmationDialog}
      >
        <ConfirmationDialog
          title={confirmationDialogTitle}
          description={confirmationDialogDescription}
          cta={{
            label: 'Disable',
            onClick: () => {
              void onDisableSharingSettings()
              setShowConfirmationDialog(false)
              setSettingToDisable(null)
            },
          }}
          secondaryCta={{
            label: 'Cancel',
            onClick: () => {
              setShowConfirmationDialog(false)
              setSettingToDisable(null)
            },
          }}
          variant="destructive"
          showCloseIcon={false}
        />
      </Dialog>
    </>
  )
}

export default SettingsSharing
