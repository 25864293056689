import { useEffect, useRef, useState, useCallback } from 'react'
import { useSearchParams } from 'react-router-dom'

import { LibraryVisbilityScope } from 'openapi/models/LibraryVisbilityScope'

import { SafeRecord } from 'utils/safe-types'

import { useAnalytics } from 'components/common/analytics/analytics-context'

import { LibraryItem } from './library-types'
import {
  getDefaultItems,
  getHarveyItems,
  getPrivateItems,
  getTeamItems,
} from './library.helpers'

interface VisibilityScopeProps {
  initialScope: LibraryVisbilityScope
  items: Record<string, LibraryItem>
  queryKey?: string
  filterValues?: SafeRecord<string, string>
  setFilterValue?: (key: string, value: string) => void
}

const VisibilityScopeToParam: Record<LibraryVisbilityScope, string> = {
  [LibraryVisbilityScope.HARVEY]: 'harvey',
  [LibraryVisbilityScope.PRIVATE]: 'private',
  [LibraryVisbilityScope.WORKSPACE]: 'team',
}

export const useVisibilityScope = ({
  initialScope,
  items,
  queryKey,
  filterValues,
  setFilterValue,
}: VisibilityScopeProps): [
  LibraryVisbilityScope,
  (scope: LibraryVisbilityScope) => void,
] => {
  const [searchParams] = useSearchParams()
  const paramValue = queryKey
    ? filterValues?.[queryKey] || searchParams.get(queryKey)
    : ''
  const { trackEvent } = useAnalytics()

  const [currentScope, setCurrentScope] =
    useState<LibraryVisbilityScope>(initialScope)
  const defaultScope = useRef<LibraryVisbilityScope | undefined>(undefined)

  const handleSetCurrentScope = useCallback(
    (scope: LibraryVisbilityScope, skipTrack?: boolean) => {
      if (queryKey && setFilterValue) {
        setFilterValue(queryKey, VisibilityScopeToParam[scope])
      }
      setCurrentScope(scope)
      if (skipTrack) return
      trackEvent('Library Visibility Scope Changed', {
        visibility_scope: scope,
      })
    },
    [queryKey, setFilterValue, trackEvent]
  )

  useEffect(() => {
    if (defaultScope.current) return
    let visibilityScope: LibraryVisbilityScope | undefined

    if (paramValue) {
      visibilityScope = paramToVisibilityScope(paramValue)
    } else {
      const privatePrompts = getPrivateItems(items)
      const teamPrompts = getTeamItems(items)
      const harveyPrompts = getHarveyItems(items)
      const defaultItems = getDefaultItems([
        privatePrompts,
        teamPrompts,
        harveyPrompts,
      ])
      if (defaultItems.length > 0) {
        visibilityScope = defaultItems[0].visibilityScope
      }
    }

    if (visibilityScope) {
      defaultScope.current = visibilityScope
      handleSetCurrentScope(defaultScope.current, true)
    }
  }, [handleSetCurrentScope, items, paramValue])

  return [currentScope, handleSetCurrentScope]
}

const paramToVisibilityScope = (
  param: string | null
): LibraryVisbilityScope | undefined => {
  return (Object.keys(LibraryVisbilityScope) as LibraryVisbilityScope[]).find(
    (scope) => VisibilityScopeToParam[scope] === param
  )
}
