import React, { useState } from 'react'

import { Card } from 'antd'

import { Workspace } from 'models/workspace'

import { displayErrorMessage } from 'utils/toast'

import { useAuthUser } from 'components/common/auth-context'
import useWorkspacesOffboardStatus from 'components/settings/hooks/use-workspaces-offboard-status'
import { Badge } from 'components/ui/badge'
import { Button } from 'components/ui/button'
import { Input } from 'components/ui/input'
import { Label } from 'components/ui/label'

import WorkspaceOffboardCancelModal from './workspace-offboard-cancel-modal'
import WorkspaceOffboardModal from './workspace-offboard-modal'
import WorkspaceOffboardRescheduleModal from './workspace-offboard-reschedule-modal'

interface WorkspaceOffboardProps {
  workspace: Workspace
}

export const MIN_GRACE_PERIOD = 1
export const MAX_GRACE_PERIOD = 60

const WorkspaceOffboard = ({ workspace }: WorkspaceOffboardProps) => {
  const userInfo = useAuthUser()
  const [gracePeriod, setGracePeriod] = useState(30)
  const {
    workspacesOffboardStatus,
    isLoadingWorkspacesOffboardStatus,
    refetchWorkspaceOffboardStatus,
  } = useWorkspacesOffboardStatus(workspace.id)

  const validGracePeriod = gracePeriod >= 0 && gracePeriod <= 60

  const allowToTriggerOffboard =
    userInfo.IsInternalAdminWriter && !isLoadingWorkspacesOffboardStatus
  const triggerButtonDisabled = !allowToTriggerOffboard || !validGracePeriod
  const [offboardModalOpen, setOffboardModalOpen] = useState(false)
  const [offboardCancelModalOpen, setOffboardCancelModalOpen] = useState(false)
  const [offboardRescheduleModalOpen, setOffboardRescheduleModalOpen] =
    useState(false)

  const handleGracePeriodChange = (value: string) => {
    const parsedValue = parseInt(value, 10)
    if (value === '') {
      setGracePeriod(0)
    } else if (isNaN(parsedValue)) {
      displayErrorMessage('Grace period must be a number')
      setGracePeriod(30)
    } else if (
      parsedValue < MIN_GRACE_PERIOD ||
      parsedValue > MAX_GRACE_PERIOD
    ) {
      displayErrorMessage(
        `Grace period must be between ${MIN_GRACE_PERIOD} and ${MAX_GRACE_PERIOD}`
      )
      setGracePeriod(30)
    } else {
      setGracePeriod(parsedValue)
    }
  }

  const offboardStatus = () => {
    if (!workspacesOffboardStatus?.jobStatus) {
      return null
    }

    if (
      workspacesOffboardStatus.jobStatus == 'COMPLETED' ||
      workspacesOffboardStatus.jobStatus == 'IN_PROGRESS'
    ) {
      return (
        <div>
          <Label>
            <b>Offboard is {workspacesOffboardStatus.jobStatus}</b>
          </Label>
          <p>
            <b>Attempted at: </b>
            <Badge variant="secondary" className="shrink space-x-1 px-2">
              <span className="truncate">
                {workspacesOffboardStatus.jobAttemptNext}
              </span>
            </Badge>
          </p>
        </div>
      )
    }

    return (
      <>
        <WorkspaceOffboardCancelModal
          open={offboardCancelModalOpen}
          workspaceId={workspace.id}
          onOpenChange={(open) => {
            setOffboardCancelModalOpen(open)
          }}
          onCancelSuccess={refetchWorkspaceOffboardStatus}
        />
        <WorkspaceOffboardRescheduleModal
          open={offboardRescheduleModalOpen}
          workspaceId={workspace.id}
          onOpenChange={(open) => {
            setOffboardRescheduleModalOpen(open)
          }}
          onRescheduleSuccess={refetchWorkspaceOffboardStatus}
        />

        <div className="flex-col space-y-3">
          <div>
            <Label>
              <b>Offboard status</b>: {workspacesOffboardStatus.jobStatus}
            </Label>
          </div>
          <div>
            <Label>
              <b>Initiated at</b>: {workspacesOffboardStatus.jobCreatedAt}
            </Label>
          </div>
          <div>
            <Label>
              <b>Scheduled at</b>: {workspacesOffboardStatus.jobAttemptNext}
            </Label>
          </div>
          <div className="mt-4 flex items-center space-x-3">
            <Button
              variant="secondary"
              onClick={() => setOffboardRescheduleModalOpen(true)}
              disabled={!allowToTriggerOffboard}
            >
              Reschedule
            </Button>
            <Button
              variant="destructive"
              onClick={() => setOffboardCancelModalOpen(true)}
              disabled={!allowToTriggerOffboard}
            >
              Cancel
            </Button>
          </div>
        </div>
      </>
    )
  }

  const onOffboardClick = () => {
    if (gracePeriod < MIN_GRACE_PERIOD || gracePeriod > MAX_GRACE_PERIOD) {
      displayErrorMessage(
        `Grace period must be between ${MIN_GRACE_PERIOD} and ${MAX_GRACE_PERIOD}`
      )
      return
    }
    setOffboardModalOpen(true)
  }

  return (
    <>
      <WorkspaceOffboardModal
        open={offboardModalOpen}
        workspaceId={workspace.id}
        gracePeriod={gracePeriod}
        onOpenChange={(open) => {
          setOffboardModalOpen(open)
        }}
        onOffboardSuccess={refetchWorkspaceOffboardStatus}
      />
      <Card>
        {!isLoadingWorkspacesOffboardStatus &&
        workspacesOffboardStatus?.jobStatus ? (
          offboardStatus()
        ) : (
          <div>
            <div>
              <p>
                Queue a job to delete workspace users, permissions and data.
                Schedule a job for upto {MIN_GRACE_PERIOD} to {MAX_GRACE_PERIOD}{' '}
                grace period days from today. Setting this job will block user
                and API access immediately for all users of{' '}
                {workspace.clientName}{' '}
              </p>
            </div>
            <div className="mb-4 mt-4 flex items-center space-x-3 p-2 pl-1">
              <p className="text-stone-500">Grace period days:</p>
              <Input
                className="h-8 w-48"
                value={gracePeriod}
                onChange={(e) => handleGracePeriodChange(e.target.value)}
              />
              <Button
                onClick={onOffboardClick}
                disabled={triggerButtonDisabled}
              >
                Schedule Offboard
              </Button>
            </div>
          </div>
        )}
      </Card>
    </>
  )
}

export default WorkspaceOffboard
