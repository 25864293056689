import { useCallback, useEffect, useState } from 'react'

import {
  FetchWorkspaceOffboardStatus,
  WorkspaceOffboardStatus,
} from 'models/workspace'

const useWorkspacesOffboardStatus = (workspace_id: number) => {
  const [workspacesOffboardStatus, setWorkspacesOffboardStatus] =
    useState<WorkspaceOffboardStatus | null>(null)
  const [
    isLoadingWorkspacesOffboardStatus,
    setIsLoadingWorkspacesOffboardStatus,
  ] = useState(true)

  const fetchWorkspaceOffboardStatus = useCallback(async () => {
    const res = await FetchWorkspaceOffboardStatus(workspace_id)
    if (res) {
      setWorkspacesOffboardStatus(res)
    } else {
      setWorkspacesOffboardStatus({
        jobStatus: null,
        jobAttemptNext: null,
        jobId: null,
      } as WorkspaceOffboardStatus)
    }
    setIsLoadingWorkspacesOffboardStatus(false)
  }, [workspace_id])

  useEffect(() => {
    void fetchWorkspaceOffboardStatus()
  }, [fetchWorkspaceOffboardStatus])

  const refetchWorkspaceOffboardStatus = useCallback(() => {
    setIsLoadingWorkspacesOffboardStatus(true)
    fetchWorkspaceOffboardStatus()
  }, [fetchWorkspaceOffboardStatus])

  return {
    workspacesOffboardStatus,
    isLoadingWorkspacesOffboardStatus,
    refetchWorkspaceOffboardStatus,
  }
}

export default useWorkspacesOffboardStatus
