import React, { useCallback, useEffect } from 'react'
import { useSearchParams, useParams } from 'react-router-dom'

import { isEmpty } from 'lodash'
import { AlignLeft } from 'lucide-react'

import { useAnalytics } from 'components/common/analytics/analytics-context'
import Combobox from 'components/ui/combobox/combobox'
import {
  fileIdSearchParamKey,
  viewSearchParamKey,
  filtersSearchParamKey,
} from 'components/vault/utils/vault'
import { useVaultDataGridFilterStore } from 'components/vault/utils/vault-data-grid-filters-store'
import { useVaultStore } from 'components/vault/utils/vault-store'

const DisplayDropdown = ({ clearFilters }: { clearFilters: () => void }) => {
  const { queryId } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const { trackEvent } = useAnalytics()
  const fileId = searchParams.get(fileIdSearchParamKey)
  const viewParam = searchParams.get(viewSearchParamKey)

  const gridApi = useVaultStore((state) => state.gridApi)
  const queryIdToState = useVaultStore((state) => state.queryIdToState)
  const queryIdToReviewState = useVaultStore(
    (state) => state.queryIdToReviewState
  )
  const isWorkflowRepsWarranties =
    queryIdToReviewState[queryId!]?.isWorkflowRepsWarranties
  const isShowingLongResponses = useVaultDataGridFilterStore(
    (state) => state.isShowingLongResponses
  )
  const setIsShowingLongResponses = useVaultDataGridFilterStore(
    (state) => state.setIsShowingLongResponses
  )
  const onLongResponseChangeHandler = useCallback(() => {
    // we are just going to update the state value here and let the data-grid
    // setupGridRowColumnData handle the rest
    const newIsShowingLongResponses = !isShowingLongResponses
    trackEvent('Vault Review Query Answer Length Changed', {
      answer_length: newIsShowingLongResponses ? 'long' : 'short',
    })
    setIsShowingLongResponses(newIsShowingLongResponses)
    clearFilters()
    setSearchParams((prev) => {
      const newParams = new URLSearchParams(prev)
      newParams.delete(filtersSearchParamKey)
      newParams.set(
        viewSearchParamKey,
        newIsShowingLongResponses ? 'long' : 'short'
      )
      return newParams
    })
  }, [
    isShowingLongResponses,
    setIsShowingLongResponses,
    clearFilters,
    setSearchParams,
    trackEvent,
  ])

  // adding useEffect to watch the view param and update the isShowingLongResponses state
  // only if the viewParam is different from the isShowingLongResponses state
  useEffect(() => {
    const isViewParamLong = viewParam === 'long'
    if (isWorkflowRepsWarranties) {
      setIsShowingLongResponses(true)
    } else if (isViewParamLong !== isShowingLongResponses) {
      setIsShowingLongResponses(isViewParamLong)
    }
  }, [
    viewParam,
    isWorkflowRepsWarranties,
    isShowingLongResponses,
    setIsShowingLongResponses,
  ])

  const isDropdownDisabled =
    !gridApi ||
    !queryId ||
    isEmpty(queryIdToState[queryId]) ||
    !isEmpty(fileId) ||
    isWorkflowRepsWarranties

  return (
    <Combobox
      hideSearchInput
      value={isShowingLongResponses ? 'long' : 'short'}
      setValue={onLongResponseChangeHandler}
      prefix={<AlignLeft size={12} />}
      defaultText="Short answers"
      options={[
        { value: 'short', label: 'Short answers' },
        { value: 'long', label: 'Long answers' },
      ]}
      className="w-auto"
      buttonSize="sm"
      popoverClassName="w-40"
      popoverMenuItemTextClassName="text-xs"
      align="start"
      disabled={isDropdownDisabled}
    />
  )
}

export default DisplayDropdown
