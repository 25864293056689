import { ReviewAnswer } from 'components/vault/utils/vault'

export interface CompareConfig {
  valueA: string
  valueB: string
  answerA: ReviewAnswer
  answerB: ReviewAnswer
  isDescending: boolean
}

export abstract class CellComparator {
  abstract compareCells(config: CompareConfig): number
}

export enum SortOrder {
  STRICT = 0,
  FREE_RESPONSE = 1,
  EMPTY = 2,
}
