import React, { useEffect, useRef, useState } from 'react'

import { debounce } from 'lodash'

import { cn } from 'utils/utils'

export type OverflowHintSide = 'start' | 'end' | 'both'

interface Props {
  children: (
    ref: React.MutableRefObject<HTMLElement | null>,
    onScroll?: (e: React.UIEvent<HTMLElement>) => void
  ) => React.ReactNode
  className?: string
  direction?: 'horizontal' | 'vertical'
  side?: OverflowHintSide
}

type OverflowState = {
  startHidden?: boolean
  endHidden?: boolean
}

const OverflowHint = ({
  children,
  className,
  direction = 'vertical',
  side = 'both',
}: Props) => {
  const [overflowState, setOverflowState] = useState<OverflowState>({})
  const { startHidden, endHidden } = overflowState
  const innerRef = useRef<HTMLElement | null>(
    null
  ) as React.MutableRefObject<HTMLElement | null>

  const handleTestOverflow = () => {
    if (innerRef.current && innerRef.current.parentElement) {
      const parentEl = innerRef.current.parentElement

      let scrollSize = 0
      let containerSize = 0
      let scrollStart = 0

      if (direction === 'vertical') {
        scrollSize = innerRef.current.scrollHeight
        containerSize = parentEl.offsetHeight
        scrollStart = innerRef.current.scrollTop
      } else {
        scrollSize = innerRef.current.scrollWidth
        containerSize = parentEl.offsetWidth
        scrollStart = innerRef.current.scrollLeft
      }

      if (scrollSize > containerSize) {
        let start = false
        let end = true
        if (scrollStart > 0) start = true
        if (scrollSize - scrollStart <= containerSize) end = false
        if (side === 'start') end = false
        if (side === 'end') start = false

        if (start !== startHidden || end !== endHidden) {
          setOverflowState({ startHidden: start, endHidden: end })
        }
      } else if (endHidden || startHidden) {
        setOverflowState({})
      }
    }
  }

  const handleResize = debounce(handleTestOverflow, 50)

  useEffect(() => {
    handleTestOverflow()
    window.addEventListener('resize', handleResize)

    return () => {
      handleResize.cancel()
      window.removeEventListener('resize', handleResize)
    }
  })

  return (
    <div className={cn('relative overflow-hidden', className)}>
      <div
        className={cn(
          'pointer-events-none absolute left-0 top-0 z-10 from-background to-transparent opacity-0 transition-opacity ease-in',
          {
            'right-0 h-8 bg-gradient-to-b': direction === 'vertical',
            'bottom-0 w-8 bg-gradient-to-r': direction === 'horizontal',
            'opacity-100': startHidden,
          }
        )}
      />
      {children(innerRef, handleResize)}
      <div
        className={cn(
          'pointer-events-none absolute bottom-0 right-0 z-10 from-background to-transparent opacity-0 transition-opacity ease-in',
          {
            'left-0 h-8 bg-gradient-to-t': direction === 'vertical',
            'top-0 w-8 bg-gradient-to-l': direction === 'horizontal',
            'opacity-100': endHidden,
          }
        )}
      />
    </div>
  )
}

export default OverflowHint
