import * as Sentry from '@sentry/browser'
import { Query, QueryKey } from '@tanstack/react-query'

import { useWrappedQuery } from 'models/queries/lib/use-wrapped-query'
import { historyItemQuery } from 'models/queries/use-history-item-query'
import Services from 'services'
import { Maybe } from 'types'
import { HistoryItem } from 'types/history'

export const useDiligenceHistoryItemQuery = ({
  id,
  isEnabled = true,
  maxRetryCount = 3,
  refetchInterval,
}: {
  id: Maybe<string>
  isEnabled?: boolean
  refetchInterval?: (
    query: Query<HistoryItem | null, Error, HistoryItem | null, QueryKey>
  ) => number | false | undefined
  maxRetryCount?: number
}) => {
  const {
    isPending,
    error,
    data: historyItem,
    isLoading,
    isFetching,
    isFetched,
  } = useWrappedQuery({
    enabled: !!id && isEnabled,
    refetchInterval,
    refetchOnWindowFocus: false,
    ...historyItemQuery(id, false, maxRetryCount),
  })

  if (error) {
    Sentry.captureException(error)
    Services.HoneyComb.RecordError(error)
  }

  return { historyItem, isPending, isLoading, isFetching, isFetched, error }
}
