import { useEffect } from 'react'

import { useShallow } from 'zustand/react/shallow'

import { useGeneralStore } from 'stores/general-store'
import { Theme } from 'types/ui-constants'

const DARK_MODE_MEDIA_QUERY = '(prefers-color-scheme: dark)'

export const useThemeSwitcher = () => {
  const [theme, setTheme] = useGeneralStore(
    useShallow((s) => [s.theme, s.setTheme])
  )

  useEffect(() => {
    if (theme !== Theme.SYSTEM) return

    const mediaQuery = window.matchMedia(DARK_MODE_MEDIA_QUERY)
    const systemTheme = mediaQuery.matches ? Theme.DARK : Theme.LIGHT
    setTheme(systemTheme)

    const handleThemeChange = (e: MediaQueryListEvent) => {
      setTheme(e.matches ? Theme.DARK : Theme.LIGHT)
    }

    mediaQuery.addEventListener('change', handleThemeChange)

    return () => mediaQuery.removeEventListener('change', handleThemeChange)
  }, [theme, setTheme])

  useEffect(() => {
    if (theme === Theme.DARK) {
      document.body.classList.add('dark')
    } else {
      document.body.classList.remove('dark')
    }
  }, [theme])
}
