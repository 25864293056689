import React, { useRef } from 'react'

import { CustomCellRendererProps } from 'ag-grid-react'
import { useShallow } from 'zustand/react/shallow'

import { cn } from 'utils/utils'

import { useAnalytics } from 'components/common/analytics/analytics-context'
import useSharingPermissions from 'components/vault/hooks/use-sharing-permissions'
import {
  addNewColumn,
  ADD_COLUMN_WIDTH_WITH_ICON,
  handleMouseLeave,
  handleMouseEnter,
} from 'components/vault/query-detail/data-grid-helpers'
import useVaultQueryDetailStore, {
  ReviewHistoryItem,
} from 'components/vault/query-detail/vault-query-detail-store'
import { useVaultStore } from 'components/vault/utils/vault-store'

export const AddColumnCell = (props: CustomCellRendererProps) => {
  const { trackEvent } = useAnalytics()
  const ref = useRef<HTMLButtonElement>(null)
  const [
    gridApi,
    queryId,
    historyItem,
    currentPendingColumnId,
    pendingQueryQuestions,
    setIsHoveringAddColumn,
    addToPendingQueryQuestions,
  ] = useVaultQueryDetailStore(
    useShallow((state) => [
      state.gridApi,
      state.queryId,
      state.historyItem,
      state.currentPendingColumnId,
      state.pendingQueryQuestions,
      state.setIsHoveringAddColumn,
      state.addToPendingQueryQuestions,
    ])
  )

  const [currentProject, exampleProjectIds] = useVaultStore(
    useShallow((state) => [state.currentProject, state.exampleProjectIds])
  )

  const { doesCurrentUserHaveEditPermission } = useSharingPermissions({
    projectId: currentProject?.id,
  })
  const isExampleProject =
    currentProject && exampleProjectIds.has(currentProject.id)
  const canCurrentUserEditProject =
    !isExampleProject && doesCurrentUserHaveEditPermission

  const { colDef } = props
  const colWidth = colDef?.width
  const isPinned = colDef?.pinned === 'right'

  const isIconOnly = colWidth === ADD_COLUMN_WIDTH_WITH_ICON
  const hasCurrentPendingColumnId = !!currentPendingColumnId

  const onAddColumn = () => {
    if (!gridApi || !canCurrentUserEditProject) return
    trackEvent('Vault Review Query Add Column Button Clicked', {
      query_id: queryId,
    })
    const reviewEvent = historyItem as ReviewHistoryItem
    const pendingQuestions = pendingQueryQuestions ?? []
    const allColumns = [...pendingQuestions, ...(reviewEvent?.columns ?? [])]
    addNewColumn(gridApi, allColumns, addToPendingQueryQuestions)
  }

  const onMouseEnter = () => {
    if (!gridApi || hasCurrentPendingColumnId || !canCurrentUserEditProject)
      return
    setIsHoveringAddColumn(true)
    handleMouseEnter(gridApi, isIconOnly)
  }

  const onMouseLeave = (e: React.MouseEvent) => {
    if (!gridApi) return
    setIsHoveringAddColumn(false)
    handleMouseLeave(e, gridApi, isPinned)
  }

  return (
    <button
      ref={ref}
      onClick={onAddColumn}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={cn('h-full w-full cursor-pointer bg-button-secondary', {
        'border-l-0': !isPinned,
        'border-l border-l-primary': isPinned,
      })}
    />
  )
}

export default AddColumnCell
