import { Env } from './env'

export enum Site {
  EU = 'eu',
  US = 'us',
}

export const getSiteName = (site: Site): string => {
  return site.toUpperCase()
}

export const getOtherSite = (site: Site): Site => {
  return site === Site.EU ? Site.US : Site.EU
}

export const getSiteUrl = (site: Site, env: Env): string => {
  if (env === Env.STAGING) {
    if (site === Site.EU) {
      return 'https://eu-staging.harvey.engineering'
    }
    return 'https://staging.harvey.engineering'
  } else if (env === Env.PRODUCTION) {
    if (site === Site.EU) {
      return 'https://eu.app.harvey.ai'
    }
    return 'https://app.harvey.ai'
  } else {
    return 'http://localhost:3000'
  }
}

export const getSiteColor = (site: Site): string => {
  if (site === Site.EU) {
    return '#003399'
  }
  return '#BF0A30'
}
