import { Row } from '@tanstack/react-table'
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'

import { Event } from 'models/event'
import { UserInfo } from 'models/user-info'
import { Maybe } from 'types'

interface SettingsState {
  settingsUser: UserInfo | null
  selectedHistoryRows: Row<Event>[]
  isSettingsSidebarCollapsed: boolean
  setSettingsUser: (user: Maybe<UserInfo>) => void
  setSelectedHistoryRows: (selectedHistoryRows: Row<Event>) => void
  setIsSettingsSidebarCollapsed: (isCollapsed: boolean) => void
}

export const useSettingsState = create<SettingsState>()(
  devtools(
    immer((set) => ({
      settingsUser: null,
      selectedHistoryRows: [],
      isSettingsSidebarCollapsed: false,
      setSettingsUser: (user) => {
        set(() => ({ settingsUser: user }))
      },
      setSelectedHistoryRows: (selectedHistoryRows: any) =>
        set({ selectedHistoryRows }),
      setIsSettingsSidebarCollapsed: (isCollapsed: boolean) =>
        set({ isSettingsSidebarCollapsed: isCollapsed }),
    }))
  )
)
