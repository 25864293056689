import React from 'react'

import { Info } from 'lucide-react'

import Banner from 'components/ui/banner'

type Props = {
  onClose: () => void
}

export const UnsupportedLanguageBanner: React.FC<Props> = ({ onClose }) => {
  return (
    <div>
      <Banner
        title="Language not supported"
        description={
          <div>
            <p>
              Please note: This language is currently unsupported. Ensure all
              responses generated by Harvey in this language are carefully
              reviewed.
            </p>
          </div>
        }
        leadingIcon={<Info />}
        onClose={onClose}
      />
    </div>
  )
}
