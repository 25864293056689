import React from 'react'

import pluralize from 'pluralize'

import { TranscriptType } from 'openapi/models/TranscriptType'
import { TranscriptsDocument } from 'openapi/models/TranscriptsDocument'

import { Badge } from 'components/ui/badge'
import {
  Card,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from 'components/ui/card'
import Link from 'components/ui/link/link'
import { getTranscriptsTypePage } from 'components/workflows/workflow/transcripts/pages'

type Props = {
  documents: TranscriptsDocument[]
}

export const CaseTypeCards: React.FC<Props> = ({ documents }) => {
  const [trials, depositions] = React.useMemo(() => {
    const trials = documents.filter(
      (doc) => doc.metadata?.transcriptType === TranscriptType.TRIAL
    )
    const depositions = documents.filter(
      (doc) => doc.metadata?.transcriptType === TranscriptType.DEPOSITION
    )

    return [trials, depositions]
  }, [documents])

  const cardData = [
    {
      title: 'Trial',
      count: trials.length,
      link: getTranscriptsTypePage(TranscriptType.TRIAL),
      description:
        'Review topical summaries of your trial transcripts and ask additional questions.',
    },
    {
      title: 'Depositions',
      count: depositions.length,
      link: getTranscriptsTypePage(TranscriptType.DEPOSITION),
      description:
        'Review topical summaries of your deposition transcripts and ask additional questions.',
    },
  ]

  return (
    <div className="rounded-lg bg-secondary p-4">
      <p className="mb-2 pl-1 text-xl">Explore by Area</p>
      <div className="grid grid-cols-1 gap-3 lg:grid-cols-2 xl:grid-cols-3">
        {cardData.map(({ title, count, link, description }) => (
          <Link key={title} className="rounded-lg" to={count ? link : ''}>
            <Card className="flex h-44 flex-col justify-between transition hover:border-input-focused">
              <CardHeader>
                <CardTitle>{title}</CardTitle>
                <CardDescription>{description}</CardDescription>
              </CardHeader>

              <CardFooter>
                <div className="flex w-full justify-between">
                  <Badge variant="secondary">
                    {count} {pluralize('Document', count)}
                  </Badge>
                  <p className="text-primary">Open</p>
                </div>
              </CardFooter>
            </Card>
          </Link>
        ))}
      </div>
    </div>
  )
}
