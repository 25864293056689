import React from 'react'
import { useParams } from 'react-router-dom'

import { RotateCw, Trash } from 'lucide-react'

import { Button } from 'components/ui/button'
import { Icon } from 'components/ui/icon/icon'
import AlertIcon from 'components/ui/icons/alert-icon'
import { Popover, PopoverContent, PopoverTrigger } from 'components/ui/popover'
import { GenerateNNResponseProps } from 'components/vault/utils/vault'
import {
  clearReviewErrorsV1,
  retryReview,
} from 'components/vault/utils/vault-helpers'
import { useVaultStore } from 'components/vault/utils/vault-store'

const ErrorPopover = ({
  fileId,
  generateNNResponse,
}: {
  fileId: string
  generateNNResponse: (props: GenerateNNResponseProps) => Promise<void>
}) => {
  const { projectId, queryId } = useParams()
  const queryIdToState = useVaultStore((s) => s.queryIdToState)
  const isLoading = queryIdToState[queryId!]?.isLoading
  const queryIdToReviewState = useVaultStore((s) => s.queryIdToReviewState)
  const setReviewTask = useVaultStore((s) => s.setReviewTask)
  const markHistoryTaskAsFromStreaming = useVaultStore(
    (s) => s.markHistoryTaskAsFromStreaming
  )
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          size="xsIcon"
          variant="ghost"
          onClick={(e) => {
            e.stopPropagation()
          }}
          className="flex shrink-0 items-center hover:bg-transparent"
        >
          <AlertIcon className="h-3 w-3 shrink-0" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-96" align="start">
        <div>
          <p className="font-semibold">Something went wrong</p>
          <p className="mt-1">
            There was an error generating an answer for one of your questions on
            this file. You can choose to try again or clear this error.
          </p>
          <div className="mt-3 flex items-center justify-end gap-2">
            <Button
              variant="ghost"
              size="sm"
              className="text-xs"
              disabled={isLoading}
              tooltip={
                isLoading
                  ? 'You can clear the error after the query is finished processing'
                  : undefined
              }
              onClick={async (e) => {
                e.stopPropagation()
                await clearReviewErrorsV1({
                  setReviewTask,
                  queryId: queryId!,
                  fileIds: [fileId],
                  queryIdToReviewState,
                })
              }}
            >
              <Icon icon={Trash} size="small" className="mr-1" />
              Clear error
            </Button>
            <Button
              variant="ghost"
              size="sm"
              className="text-xs"
              disabled={isLoading}
              tooltip={
                isLoading
                  ? 'You can retry after the query is finished processing'
                  : undefined
              }
              onClick={async (e) => {
                e.stopPropagation()
                await retryReview({
                  generateNNResponse,
                  setReviewTask,
                  markHistoryTaskAsFromStreaming,
                  projectId: projectId!,
                  queryId: queryId!,
                  fileIds: [fileId],
                  queryIdToState,
                  queryIdToReviewState,
                })
              }}
            >
              <Icon icon={RotateCw} size="small" className="mr-1" />
              Retry
            </Button>
          </div>
        </div>
      </PopoverContent>
    </Popover>
  )
}

export default ErrorPopover
