import React from 'react'

import { ArrowRight, Shapes } from 'lucide-react'

import { cn } from 'utils/utils'

import { Button } from 'components/ui/button'
import Icon from 'components/ui/icon/icon'

type Props = {
  titles: string[]
  onClick: (index: number) => void
  useSimpleUI?: boolean
}

export const TranscriptsTopicList: React.FC<Props> = ({
  titles,
  onClick,
  useSimpleUI,
}) => {
  return (
    <div
      className={cn('max-w-xl space-y-2 rounded-lg', {
        'border p-4': !useSimpleUI,
      })}
    >
      {!useSimpleUI && (
        <div className="flex items-center space-x-2 pl-2">
          <div className="h-6 w-6 rounded bg-accent p-1">
            <Shapes size={16} className="mr-1.5 text-primary" />
          </div>
          <p className="text-lg font-semibold">Themes</p>
        </div>
      )}
      <div>
        {titles.map((title, i) => (
          <Button
            className={cn(
              'group flex w-full items-center justify-between rounded-md py-0.5',
              {
                'px-2 hover:bg-secondary-hover': !useSimpleUI,
              }
            )}
            onClick={() => onClick(i)}
            variant="unstyled"
            key={i}
          >
            <p className="mr-4 line-clamp-1 text-left transition group-hover:underline">
              {title}
            </p>

            <p className="flex shrink-0 items-center gap-1 text-muted transition group-hover:text-secondary">
              Read <Icon icon={ArrowRight} />
            </p>
          </Button>
        ))}
      </div>
    </div>
  )
}
