import { getErrorFields } from 'error/handler'
import _ from 'lodash'
import UAParser from 'ua-parser-js'

import type { UserInfo } from 'models/user-info'
import { SensitiveFields, SensitiveFileFields } from 'models/workspace'

import { environment, site } from 'utils/server-data'
import { retry } from 'utils/utils'

import { SetErrorCatcher } from './error-catcher'
import TelemtryEvent from './telemetry-event'

const HONEYCOMB_API_URL_BATCH = 'https://api.honeycomb.io/1/batch/'
const ANALYTICS_FLUSH_TIMEOUT = 10_000
const ANALYTICS_QUEUE_BATCH_SIZE = 100
const HONEYCOMB_MAX_EVENTS_FLUSH_SIZE = 10
const MAX_FIELD_SIZE_BYTES = 4_000

export default class HoneyComb {
  writeKey: string
  dataset: string
  queue: TelemtryEvent[] = []
  timer: any
  userId: string | undefined
  workspaceId: number | undefined
  workspaceSlug: string | undefined
  userAgent: object = {}
  windowHasDroppedEvents: boolean

  constructor(WriteKey: string, Dataset: string) {
    this.writeKey = WriteKey
    this.dataset = Dataset
    this.timer = setInterval(() => {
      void this.flush()
    }, ANALYTICS_FLUSH_TIMEOUT)
    this.windowHasDroppedEvents = false

    const parser = new UAParser()
    this.userAgent = parser.getResult()
  }

  HandleUncaughtErrors(): void {
    SetErrorCatcher(this)
  }

  Record(fields: Record<string, any>): void {
    this.Start(fields).Record({})
  }

  Start(fields: Record<string, any>): TelemtryEvent {
    const event = new TelemtryEvent(this.onFinish.bind(this))
    event.Add(fields)
    return event
  }

  Finish(event: TelemtryEvent): void {
    event.Finish({})
  }

  onFinish(e: TelemtryEvent): void {
    e.Add({
      user_id: this.userId,
      workspace_id: this.workspaceId,
      workspace_slug: this.workspaceSlug,
      service: process.env.REACT_APP_SERVICE,
      revision: process.env.REACT_APP_REVISION,
      environment: environment,
      location: window.location.href,
      site,
    })
    e.Add(this.userAgent)
    if (this.queue.length < ANALYTICS_QUEUE_BATCH_SIZE) {
      this.queue.push(e)
    } else {
      this.windowHasDroppedEvents = true
    }
  }

  AttachUser(userInfo: UserInfo): void {
    this.userId = userInfo.nonDbId
    this.workspaceId = userInfo.workspace?.id
    this.workspaceSlug = userInfo.workspace?.slug
  }

  RecordError(error: any): string {
    const fields = getErrorFields(error)
    this.Record({ metric: 'ui.uncaught_error', ...fields })
    return fields.error
  }

  private removeLargeTopLevelFields(obj: Record<string, any>) {
    for (const key in obj) {
      if (JSON.stringify(obj[key])?.length > MAX_FIELD_SIZE_BYTES) {
        delete obj[key]
      }
    }
  }

  async flush(): Promise<void> {
    if (this.queue.length === 0) {
      return
    }

    const body = this.queue.map((event) => {
      // Always scrub sensitive fields
      Object.values(SensitiveFields).forEach((f) => delete event.fields[f])
      Object.values(SensitiveFileFields).forEach((f) => delete event.fields[f])

      this.removeLargeTopLevelFields(event.fields)
      return event.ToRaw()
    })

    this.queue = []

    // Break the API requests into chunks of HONEYCOMB_MAX_EVENTS_FLUSH_SIZE events
    for (const chunk of _.chunk(body, HONEYCOMB_MAX_EVENTS_FLUSH_SIZE)) {
      void retry(
        async () => {
          const response = await fetch(
            HONEYCOMB_API_URL_BATCH + encodeURIComponent(this.dataset),
            {
              method: 'POST',
              headers: {
                'X-Honeycomb-Team': this.writeKey,
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(chunk),
            }
          )

          const responseJson = await response.json()
          const erroredList = responseJson.filter(
            (r: any) => r.status !== 202 || r.status !== 202
          )
          if (erroredList.length > 0) {
            console.warn(
              `Failed to send some events to Honeycomb (${erroredList.length}/${body.length})`,
              erroredList
            )
          }
        },
        (e) => {
          console.warn(
            `Failed to send all (${chunk.length}) events to Honeycomb: `,
            e
          )
        },
        { shouldNotUseSentry: true }
      )
    }

    if (this.windowHasDroppedEvents) {
      console.warn('Dropping events due to queue size overflow')
      this.windowHasDroppedEvents = false
    }
  }
}
