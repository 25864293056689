import React from 'react'

import _ from 'lodash'

import { ResearchArea } from 'openapi/models/ResearchArea'
import { ResearchFilter } from 'openapi/models/ResearchFilter'

import ExplorerColumn from 'components/research/explorer-column'
import { ScrollArea } from 'components/ui/scroll-area'

interface ExplorerProps {
  isOpen: boolean
  taxonomy: ResearchFilter[]
  selectedFilters: ResearchFilter[]
  setSelectedFilters: (filters: ResearchFilter[]) => void
  isLoading: boolean
  researchArea: ResearchArea
}

const Explorer: React.FC<ExplorerProps> = ({
  isOpen,
  taxonomy,
  selectedFilters,
  setSelectedFilters,
  isLoading,
  researchArea,
}) => {
  const [openFilters, setOpenFilters] = React.useState<ResearchFilter[]>([])

  if (!isOpen) return

  return (
    <ScrollArea className="h-full" hasHorizontalScroll isFullHeight>
      <div className="research-explorer flex h-full overflow-clip">
        <ExplorerColumn
          openFilters={openFilters}
          setOpenFilters={setOpenFilters}
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
          isLoading={isLoading}
          taxonomy={taxonomy}
          filters={taxonomy}
          depth={0}
          researchArea={researchArea}
        />
        {!_.isNil(openFilters[0]) && (
          <ExplorerColumn
            openFilters={openFilters}
            setOpenFilters={setOpenFilters}
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
            isLoading={isLoading}
            taxonomy={taxonomy}
            filters={openFilters[0].children}
            depth={1}
            researchArea={researchArea}
          />
        )}
        {!_.isNil(openFilters[1]) &&
          openFilters[1].parentId === openFilters[0].id &&
          openFilters[1].children.length > 0 && (
            <ExplorerColumn
              openFilters={openFilters}
              setOpenFilters={setOpenFilters}
              selectedFilters={selectedFilters}
              setSelectedFilters={setSelectedFilters}
              isLoading={isLoading}
              taxonomy={taxonomy}
              filters={openFilters[1].children}
              depth={2}
              researchArea={researchArea}
            />
          )}
      </div>
    </ScrollArea>
  )
}

export default Explorer
