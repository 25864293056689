import { useState, useEffect, useCallback } from 'react'

import { RawUser, fetchWorkspaceUsersClientAdmin } from 'models/users'

const useWorkspaceActiveUsers = ({
  workspaceId,
}: {
  workspaceId: number | null
}) => {
  const [activeUsers, setActiveUsers] = useState<RawUser[]>([])
  const [isLoadingActiveUsers, setIsLoadingActiveUsers] = useState(true)

  const fetchActiveUsers = useCallback(async () => {
    if (!workspaceId) return
    const users = await fetchWorkspaceUsersClientAdmin(workspaceId)
    setActiveUsers(users)
    setIsLoadingActiveUsers(false)
  }, [workspaceId])

  useEffect(() => {
    void fetchActiveUsers()
  }, [fetchActiveUsers])

  return { activeUsers, isLoadingActiveUsers }
}

export default useWorkspaceActiveUsers
