/* tslint:disable */
/* eslint-disable */
/**
 * PwC Deals API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration of Diligence tasks that can be requested
 * @export
 */
export const DiligenceTaskType = {
    PROCESS_SECTIONS: 'process_sections',
    FOLLOW_UP_QUESTION: 'follow_up_question'
} as const;
export type DiligenceTaskType = typeof DiligenceTaskType[keyof typeof DiligenceTaskType];


export function instanceOfDiligenceTaskType(value: any): boolean {
    for (const key in DiligenceTaskType) {
        if (Object.prototype.hasOwnProperty.call(DiligenceTaskType, key)) {
            if (DiligenceTaskType[key as keyof typeof DiligenceTaskType] === value) {
                return true;
            }
        }
    }
    return false;
}

export function DiligenceTaskTypeFromJSON(json: any): DiligenceTaskType {
    return DiligenceTaskTypeFromJSONTyped(json, false);
}

export function DiligenceTaskTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): DiligenceTaskType {
    return json as DiligenceTaskType;
}

export function DiligenceTaskTypeToJSON(value?: DiligenceTaskType | null): any {
    return value as any;
}

export function DiligenceTaskTypeToJSONTyped(value: any, ignoreDiscriminator: boolean): DiligenceTaskType {
    return value as DiligenceTaskType;
}

