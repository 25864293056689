export const EMPTY_QUERY_HELP_TEXT = `Please enter a query`
export const QUERY_MUST_HAVE_X_LEN_HELP_TEXT = (x: string) =>
  `Query must have ${x} characters`

export const IS_LOADING_HELP_TEXT = `Harvey is answering your previous query`

export const CLIENT_MATTER_QUERY_LOCK_HELP_TEXT = `Please select a client matter # before asking Harvey`

export const UPLOAD_DOCUMENT_HELP_TEXT =
  'Please upload a document before asking Harvey'
export const GET_UPLOAD_X_DOCUMENT_HELP_TEXT = (x: string) =>
  `Please upload ${x} document`

export const SELECT_RESEARCH_DATABASE_HELP_TEXT = 'Select a research database'

export const COMPANY_PROFILE_EMPTY_HELP_TEXT = 'Please enter a stock ticker'
export const COMPANY_PROFILE_SELECT_FIELD_HELP_TEXT =
  'Please select at least one field'

export const ISSUES_LIST_ADD_TOPIC_HELP_TEXT = 'Please add at least one topic'
export const AUTO_ISSUES_LIST_ENABLED_HELP_TEXT =
  'Harvey will choose the most relevant topics based on changes in the document.'
export const AUTO_ISSUES_LIST_DISABLED_HELP_TEXT =
  'Remove topics and choose Auto Generate to have Harvey choose the most relevant topics.'

export const SELECT_CONTRACT_DOCUMENT_TYPE_HELP_TEXT =
  'Please select a document type'

export const DOCUMENTS_UPLOADING_HELP_TEXT = 'Documents are uploading'
