import React from 'react'

import { useQueryClient } from '@tanstack/react-query'

import { HarvQueryKeyPrefix } from 'models/queries/all-query-keys'
import { FetchWorkspace, Workspace } from 'models/workspace'

import { Card, CardContent } from 'components/ui/card'
import { Separator } from 'components/ui/separator'

import VaultUsers from './users/vault-users'
import VaultEnablement from './vault-enablement'

interface VaultManagementProps {
  workspace: Workspace
}

const VaultManagement = ({ workspace }: VaultManagementProps) => {
  const queryClient = useQueryClient()
  const [vaultWorkspace, setVaultWorkspace] =
    React.useState<Workspace>(workspace)

  const fetchVaultWorkspace = async () => {
    const ws = await FetchWorkspace(workspace.id)
    setVaultWorkspace(ws)
    // Also invalidate query cap rules for this workspace
    await queryClient.invalidateQueries({
      queryKey: [HarvQueryKeyPrefix.QueryCapRulesQuery, workspace.id],
    })
  }

  return (
    <div>
      <Card>
        <CardContent className="p-2">
          <div className="mb-2">
            <VaultEnablement
              workspace={vaultWorkspace}
              fetchWorkspace={fetchVaultWorkspace}
            />
          </div>
          {(vaultWorkspace.hasVaultAddOn ||
            vaultWorkspace.hasVaultReviewPack) && (
            <div>
              <Separator />
              <div className="mt-6">
                <VaultUsers workspace={vaultWorkspace} />
              </div>
            </div>
          )}
        </CardContent>
      </Card>
    </div>
  )
}

export default VaultManagement
