import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'

import { VaultUser } from 'models/vault'

interface State {
  vaultAddOnUsers: VaultUser[]
  vaultReviewPackUsers: VaultUser[]
  totalSeats: number
  remainingReviewPackFiles: number
}

interface Action {
  setVaultAddOnUsers: (addOnUsers: VaultUser[]) => void
  setVaultReviewPackUsers: (reviewPackUsers: VaultUser[]) => void
  setRemainingReviewPackFiles: (files: number) => void
}

export const useVaultManagementStore = create(
  devtools(
    immer<State & Action>((set) => ({
      vaultAddOnUsers: [],
      vaultReviewPackUsers: [],
      totalSeats: 0,
      remainingReviewPackFiles: 0,
      setVaultAddOnUsers: (addOnUsers) =>
        set((state) => {
          state.vaultAddOnUsers = addOnUsers
          state.totalSeats =
            addOnUsers.length + state.vaultReviewPackUsers.length
        }),
      setVaultReviewPackUsers: (reviewPackUsers) =>
        set((state) => {
          state.vaultReviewPackUsers = reviewPackUsers
          state.totalSeats =
            state.vaultAddOnUsers.length + reviewPackUsers.length
        }),
      setRemainingReviewPackFiles: (files) =>
        set((state) => {
          state.remainingReviewPackFiles = files
        }),
    }))
  )
)
