import React from 'react'

import { cn } from 'utils/utils'

import { Badge } from 'components/ui/badge'

import { ClassifyCellRenderer } from './classify-cell-renderer'

interface VaultClassifyRenderProps {
  text: string
  shouldWrap?: boolean
}

const VaultClassifyCellRender: React.FC<VaultClassifyRenderProps> = ({
  text,
  shouldWrap = false,
}) => {
  const labels = new ClassifyCellRenderer().extractLabels(text)
  const containerClassName = cn('flex', {
    'flex-wrap': shouldWrap,
  })
  const badgeClassName = cn('mr-1', {
    'mb-1': shouldWrap,
  })
  return (
    <div className={containerClassName}>
      {labels.map((label) => {
        return (
          <Badge key={label} variant="skeleton" className={badgeClassName}>
            {label}
          </Badge>
        )
      })}
    </div>
  )
}

export default VaultClassifyCellRender
