import pluralize from 'pluralize'

export const pluralizeFiles = (count: number) => {
  return `${count.toLocaleString()} ${pluralize('file', count)}`
}

export const pluralizeDocuments = (count: number) => {
  return `${count.toLocaleString()} ${pluralize('document', count)}`
}

export const pluralizePages = (count: number) => {
  return `${count.toLocaleString()} ${pluralize('page', count)}`
}
