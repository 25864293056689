import React, { useRef } from 'react'
import { useNavigate } from 'react-router-dom'

import { ArrowLeftIcon, ArrowRightIcon, Folders } from 'lucide-react'
import { Instance } from 'pspdfkit'

import { WorkflowType } from 'types/workflows'

import * as PdfViewerUtils from 'utils/pdf-viewer'
import { Source } from 'utils/task'
import { snakeToStart } from 'utils/utils'

import ErrorPage from 'components/common/error/error'
import ExportDialog from 'components/common/export/export-dialog'
import PdfViewer from 'components/common/pdf-viewer/pdf-viewer'
import { Button } from 'components/ui/button'
import { ScrollArea } from 'components/ui/scroll-area'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/ui/select'
import { EXTRACT_NOT_AVAILABLE_VALUE } from 'components/workflows/workflow/contracts//config'
import { useContractsStore } from 'components/workflows/workflow/contracts/contracts-store'
import { ContractsPages } from 'components/workflows/workflow/contracts/pages'
import { exportToExcel } from 'components/workflows/workflow/contracts/utils/export-to-excel'
import {
  ALL_FILTER,
  getFilteredDocuments,
} from 'components/workflows/workflow/contracts/utils/utils'
import WorkflowLayout from 'components/workflows/workflow/workflow-layout'

import TermsViewer from './terms-viewer'

export const ContractsFileViewer: React.FC = () => {
  const { documents, filters } = useContractsStore()
  // XXX: useParams double decodes the filename, so get it manually instead
  const filename = window.location.pathname.split('/').pop()
  const document = documents.find(
    (doc) => doc.file.name === decodeURIComponent(filename ?? '')
  )
  const navigate = useNavigate()
  const [selectedFilter, setSelectedFilter] = React.useState<string>(
    ALL_FILTER.value
  )
  const pspdfInstanceRef = useRef<Instance | null>(null)

  if (!document?.file.url) {
    return (
      <ErrorPage
        primaryCTA={{
          text: 'Back to Contracts',
          onClick: () => navigate(ContractsPages.FILE_EXPLORER),
        }}
      />
    )
  }

  const close = () => {
    navigate(ContractsPages.FILE_EXPLORER)
  }

  const switchDocument = (val: 'next' | 'prev') => {
    const documentOptions = getFilteredDocuments(
      documents ?? [],
      filters
    ).filter((doc) => !doc.isLoading)
    const currentDocumentIdx = documentOptions.findIndex(
      (doc) => doc.file === document.file
    )
    if (currentDocumentIdx === -1) return

    const increment = val === 'next' ? 1 : -1
    const adjustedIndex =
      (currentDocumentIdx + increment + documentOptions.length) %
      documentOptions.length
    const nextDocument = documentOptions[adjustedIndex]
    navigate(
      ContractsPages.FILE_VIEW.replace(
        ':filename',
        encodeURIComponent(nextDocument.file.name)
      )
    )
  }

  const allSources: { term: string; source: Source }[] = Object.entries(
    document.extractedTerms ?? {}
  )
    .filter(
      ([, value]) =>
        !value.text.includes(EXTRACT_NOT_AVAILABLE_VALUE) && value.source
    )
    .flatMap(([term, value]) => ({ term, source: value.source }))

  const onSourceClick = async (term: string): Promise<void> => {
    const source = allSources.find((s) => s.term === term)?.source

    if (!source) {
      return
    }

    void PdfViewerUtils.scrollToSourceHighlight(
      pspdfInstanceRef.current,
      source
    )
  }

  const sortedOptions = Object.keys(document.extractedTerms ?? {})
    .filter(
      (key) =>
        !document.extractedTerms[key].text.includes(EXTRACT_NOT_AVAILABLE_VALUE)
    )
    .sort((a, b) => snakeToStart(a).localeCompare(snakeToStart(b)))
    .map((key) => ({
      label: snakeToStart(key),
      value: key,
    }))

  const options = [ALL_FILTER, ...sortedOptions]

  const applyAnnotations = () =>
    PdfViewerUtils.applyAnnotations(
      pspdfInstanceRef.current,
      document.file,
      allSources.map((s) => s.source)
    )

  const handleExport = () => exportToExcel(documents)

  return (
    <WorkflowLayout
      workflowType={WorkflowType.CONTRACTS}
      title="Contracts"
      appHeaderActions={
        <ExportDialog onExport={handleExport} disabled={!documents.length} />
      }
    >
      <div className="mb-4 flex w-full items-end justify-end space-x-2">
        <Button onClick={close} variant="outline">
          <Folders size={16} className="mr-1.5 shrink-0" />
          Back to Files
        </Button>
        <Button variant="outline" onClick={() => switchDocument('prev')}>
          <ArrowLeftIcon size={16} className="mr-1.5 shrink-0" />
          Previous
        </Button>
        <Button variant="outline" onClick={() => switchDocument('next')}>
          Next
          <ArrowRightIcon size={16} className="mr-1.5 shrink-0" />
        </Button>
      </div>
      <div className="flex h-[calc(100vh-104px)] space-x-4">
        <div className="w-1/2">
          <PdfViewer
            onClear={close}
            canClear
            document={document.file}
            pspdfInstanceRef={pspdfInstanceRef}
            closeIcon={null}
            applyAnnotations={applyAnnotations}
          />
        </div>
        <ScrollArea className="w-1/2 rounded-lg border">
          {/* Blur bottom to indicate scroll */}
          <div className="pointer-events-none absolute bottom-0 left-0 right-0 z-40 h-16 bg-gradient-to-t from-white to-transparent" />

          <div className="relative">
            <div className="sticky top-0 mb-8 flex h-12 w-full items-center justify-between border-b  bg-secondary/70 px-4 backdrop-blur">
              <p className="grow text-lg font-semibold">Extracted terms</p>

              <p className="mr-2">Jump to</p>
              <Select onValueChange={setSelectedFilter}>
                <SelectTrigger className="h-8 w-48">
                  <SelectValue>{selectedFilter}</SelectValue>
                </SelectTrigger>
                <SelectContent>
                  {options.map((option) => (
                    <SelectItem key={option.value} value={option.value}>
                      {option.label}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
            <TermsViewer
              terms={Object.keys(document.extractedTerms)}
              selectedFilter={selectedFilter}
              extractedTerms={document.extractedTerms}
              onSourceClick={onSourceClick}
            />
          </div>
        </ScrollArea>
      </div>
    </WorkflowLayout>
  )
}

export default ContractsFileViewer
