import React from 'react'

import {
  ColumnFiltersState,
  VisibilityState,
  SortingState,
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  getFilteredRowModel,
} from '@tanstack/react-table'

import { ContractsDocument } from 'openapi/models/ContractsDocument'
import { ContractsSmartFilter } from 'openapi/models/ContractsSmartFilter'

import * as config from 'components/workflows/workflow/contracts/config'
import {
  DocumentTable,
  useColumns,
} from 'components/workflows/workflow/contracts/file-explorer/file-explorer-columns'
import { getFilteredDocuments } from 'components/workflows/workflow/contracts/utils/utils'

type Props = {
  documents: ContractsDocument[]
  filters: ContractsSmartFilter[]
  removeDocument: (documentName: string) => void
}

export const useContractsTable = ({
  documents,
  filters,
  removeDocument,
}: Props) => {
  const [sorting, setSorting] = React.useState<SortingState>([])
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  )
  const [columnVisibility, setColumnVisibility] =
    React.useState<VisibilityState>({})
  const [rowSelection, setRowSelection] = React.useState({})

  const handleRemoveDocument = (e: any, document: ContractsDocument) => {
    e.stopPropagation()
    removeDocument(document.file.name)
  }

  const columns = useColumns(() => handleRemoveDocument)

  const data: DocumentTable[] = React.useMemo(() => {
    return getFilteredDocuments(documents ?? [], filters).map((doc) => ({
      ...doc,
      extractedTermsCount: Object.keys(doc.extractedTerms).filter(
        (key) =>
          !doc.extractedTerms[key].text.includes(
            config.EXTRACT_NOT_AVAILABLE_VALUE
          )
      ).length,
    }))
  }, [documents, filters])

  return useReactTable({
    data,
    columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    manualPagination: false,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
    },
    defaultColumn: {
      minSize: 0,
      size: 2,
      maxSize: 20,
    },
  })
}
