import React, { ChangeEvent } from 'react'

import { debounce } from 'lodash'
import { LucideIcon } from 'lucide-react'

import { Icon } from './icon/icon'
import { Input, InputProps } from './input'

interface DebouncedInputProps extends InputProps {
  value: string
  onChange: (value: ChangeEvent<HTMLInputElement>) => void
  debounceDelay?: number
  icon?: LucideIcon
}

const DEFAULT_DELAY_MS = 250

const DebouncedInput = React.forwardRef<HTMLInputElement, DebouncedInputProps>(
  (
    { value, onChange, icon, debounceDelay = DEFAULT_DELAY_MS, ...props },
    ref
  ) => {
    // Local state to handle input value in real-time
    const [localValue, setLocalValue] = React.useState(value)

    // Create a debounced function that calls the passed onChange prop
    const debouncedOnChange = React.useMemo(
      () =>
        debounce(
          (value: string) =>
            onChange({ target: { value } } as ChangeEvent<HTMLInputElement>),
          debounceDelay
        ),
      [onChange, debounceDelay]
    )

    // Effect to sync local state with prop value, in case it changes externally
    React.useEffect(() => {
      setLocalValue(value)
    }, [value])

    // Effect for debouncing onChange calls
    React.useEffect(() => {
      // Call the debounced onChange with the current localValue
      debouncedOnChange(localValue)
      // Cleanup function to cancel the debounced call if the component unmounts or if the value changes
      return () => debouncedOnChange.cancel()
    }, [localValue, debouncedOnChange])

    return (
      <div className="flex items-center gap-1">
        {icon && <Icon icon={icon} className="h-4 w-4 text-muted" />}
        <Input
          value={localValue}
          onChange={(e) => setLocalValue(e.target.value)}
          ref={ref}
          {...props}
        />
      </div>
    )
  }
)

DebouncedInput.displayName = 'DebouncedInput'

export { DebouncedInput }
