import React, { useState } from 'react'

import { isNil } from 'lodash'

import { useNavigateWithQueryParams } from 'hooks/use-navigate-with-query-params'

import * as API from 'components/assistant/utils/assistant-api'
import { useAuthUser } from 'components/common/auth-context'
import { Button } from 'components/ui/button'

import { AssistantMode } from './assistant-mode-select'

export const AssistantCreateCopy = (params: {
  eventId: string | null
  mode: AssistantMode
}) => {
  const [isCreatingCopy, setIsCreatingCopy] = useState(false)
  const navigate = useNavigateWithQueryParams()
  const { eventId, mode } = params
  const isDraft = mode === AssistantMode.DRAFT
  const userInfo = useAuthUser()

  // Creating a copy involves creating a new thread and loading
  // the thread from history. This is only allowed for history users.
  // TODO: `createOwnCopy` could return the entire new thread instead
  // to support creating a copy for non-history users.
  if (!userInfo.IsHistoryUser) return null

  const createCopy = async () => {
    if (isNil(eventId)) return
    setIsCreatingCopy(true)
    const copy = await API.createOwnCopy(eventId)
    if (copy?.id)
      navigate(`/assistant/${mode}/${copy.id}`, { state: { isCopied: true } })
    setIsCreatingCopy(false)
  }

  const component = isDraft ? DraftComponent : AssistComponent

  return component({ eventId, isCreatingCopy, createCopy })
}

type ComponentProps = {
  eventId: string | null
  isCreatingCopy: boolean
  createCopy: () => void
}

const AssistComponent = ({
  eventId,
  isCreatingCopy,
  createCopy,
}: ComponentProps) => (
  <div className="w-full space-y-4 rounded-md border px-4 py-6 text-center">
    <p>You can ask follow-ups by creating your own copy of this thread</p>
    <div>
      <Button disabled={isNil(eventId) || isCreatingCopy} onClick={createCopy}>
        Create a copy
      </Button>
    </div>
  </div>
)

const DraftComponent = ({
  eventId,
  isCreatingCopy,
  createCopy,
}: ComponentProps) => (
  <div className="w-full space-y-2 rounded text-left">
    <p className="text-xs">Create a copy of this draft to add revisions</p>
    <div>
      <Button
        size="sm"
        variant="outline"
        disabled={isNil(eventId) || isCreatingCopy}
        onClick={createCopy}
      >
        Create a copy
      </Button>
    </div>
  </div>
)
