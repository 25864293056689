import { UserInfo } from 'models/user-info'

import { createRouteComponent } from 'utils/routing'

import { DiligenceFlow } from './flow'

enum DiligencePages {
  DILIGENCE_DOCUMENTS = '/workflows/discovery/documents',
  DILIGENCE_HOME = '/workflows/discovery',
  DILIGENCE_HOME_OLD = '/workflows/diligence_old',

  // XXX: :section? is matched in string ops, don't change it
  DILIGENCE_REPORTS = '/workflows/discovery/reports/:section?',
}

export const getDiligencePageRoutes = (userInfo: UserInfo) => {
  return [
    createRouteComponent({
      path: DiligencePages.DILIGENCE_HOME,
      hasPerms: userInfo.isDiligenceUser,
      component: DiligenceFlow,
    }),
    createRouteComponent({
      path: `${DiligencePages.DILIGENCE_HOME}/:id?`,
      hasPerms: userInfo.isDiligenceUser,
      component: DiligenceFlow,
    }),
  ]
}
