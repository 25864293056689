import React, { useEffect, useState } from 'react'

import {
  ColumnDef,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  PaginationState,
  Row,
  SortingState,
  useReactTable,
} from '@tanstack/react-table'
import _ from 'lodash'
import pluralize from 'pluralize'

import { FetchAllPerms, Perm } from 'models/perms'
import { PermUserCountByWorkspace, Workspace } from 'models/workspace'

import { useNavigateWithQueryParams } from 'hooks/use-navigate-with-query-params'
import { cn } from 'utils/utils'

import WorkspaceDropdown from 'components/dashboard/workspace-dropdown'
import CsvDownload from 'components/ui/csv-download'
import { DataTable } from 'components/ui/data-table/data-table'
import DataTableFooter from 'components/ui/data-table/data-table-footer'
import DataTableHeader from 'components/ui/data-table/data-table-header'
import DataTableSortHeader from 'components/ui/data-table/data-table-sort-header'

interface WorkspacePermUserCountProps {
  permUserCountByWorkspace: PermUserCountByWorkspace
  workspace: Workspace
  workspaces: Workspace[]
}

interface PermUserCount {
  permName: string
  permId: string
  count: number
}

const WorkspacePermUserCount = ({
  permUserCountByWorkspace,
  workspace,
  workspaces,
}: WorkspacePermUserCountProps) => {
  const navigate = useNavigateWithQueryParams()
  const [filter, setFilter] = useState<string>('')
  const [permUserCounts, setPermUserCounts] = useState<PermUserCount[]>([])
  const [selectedWorkspace, setSelectedWorkspace] =
    useState<Workspace>(workspace)
  const [availableWorkspaces, setAvailableWorkspaces] = useState<Workspace[]>(
    []
  )
  const [allPerms, setAllPerms] = useState<Perm[]>([])

  useEffect(() => {
    const fetchData = async () => {
      const allPerms = await FetchAllPerms()
      setAllPerms(allPerms)
    }
    fetchData()
  }, [])

  useEffect(() => {
    if (!_.isNil(permUserCountByWorkspace[workspace.id])) {
      const puCounts = permUserCountByWorkspace[workspace.id]
      const permUserCounts = Object.keys(puCounts).map((permId) => ({
        permId,
        count: puCounts[permId],
        permName: allPerms.find((p) => p.permId === permId)?.name ?? permId,
      }))
      setPermUserCounts(permUserCounts)
    }
  }, [allPerms, permUserCountByWorkspace, workspace])

  useEffect(() => {
    const puWorkspaces = Object.keys(permUserCountByWorkspace).map((w) =>
      parseInt(w)
    )
    const availableWorkspaces = workspaces.filter((w) =>
      puWorkspaces.includes(w.id)
    )
    setAvailableWorkspaces(availableWorkspaces)
  }, [permUserCountByWorkspace, workspaces])

  const onWorkspaceChange = (workspace: Workspace) => {
    setSelectedWorkspace(workspace)
    setPermUserCounts(
      Object.keys(permUserCountByWorkspace[workspace.id]).map((permId) => ({
        permId,
        count: permUserCountByWorkspace[workspace.id][permId],
        permName: allPerms.find((p) => p.permId === permId)?.name ?? permId,
      }))
    )
  }

  const columns: ColumnDef<PermUserCount>[] = [
    {
      accessorKey: 'permName',
      header: ({ column }) => {
        return <DataTableSortHeader column={column} header="Perm Name" />
      },
      cell: ({ getValue }) => (
        <div className="ml-3 text-sm">{String(getValue())}</div>
      ),
      enableColumnFilter: true,
    },
    {
      accessorKey: 'permId',
      header: ({ column }) => {
        return <DataTableSortHeader column={column} header="Perm ID" />
      },
      cell: ({ getValue }) => (
        <div className="ml-3 text-sm">{String(getValue())}</div>
      ),
      enableGlobalFilter: true,
    },
    {
      accessorKey: 'count',
      header: ({ column }) => {
        return <DataTableSortHeader column={column} header="Count" />
      },
      cell: ({ getValue }) => (
        <div className="ml-3 text-sm">{String(getValue())}</div>
      ),
      enableGlobalFilter: true,
    },
  ]

  const [tablePaginationState, setTablePaginationState] =
    useState<PaginationState>({
      pageIndex: 0,
      pageSize: 10,
    })
  const [sorting, setSorting] = useState<SortingState>([])

  const table = useReactTable({
    data: permUserCounts,
    columns,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setTablePaginationState,
    manualPagination: false,
    state: {
      sorting,
      pagination: tablePaginationState,
      globalFilter: filter,
      columnVisibility: {
        rolePk: false,
      },
    },
    onGlobalFilterChange: setFilter,
    enableSorting: true,
    enableSortingRemoval: true,
  })

  const rowCountCopy = `${table.getFilteredRowModel().rows.length} ${pluralize(
    'perms',
    table.getFilteredRowModel().rows.length
  )}`

  const handleRowClick = (row: Row<PermUserCount>) => {
    navigate(
      `/settings/internal_admin/permissions/users?permId=${row.original.permId}&permName=${row.original.permName}&workspace=${selectedWorkspace.slug}`
    )
  }

  return (
    <>
      <DataTableHeader
        searchable={{ searchQuery: filter, setSearchQuery: setFilter }}
        className="mb-2"
      >
        <div className="mr-2 mt-1 flex items-center space-x-2">
          <p>
            View number of users perm permission for{' '}
            <b>{selectedWorkspace.clientName}</b>. Permissions counts include
            user perms, workspace perms and workspace role perms.
          </p>

          <CsvDownload
            data={permUserCounts}
            buttonText="Export"
            filename={`${selectedWorkspace.clientName}_perm_user_counts.csv`}
            className="w-[84px]"
          />
          <WorkspaceDropdown
            workspaces={availableWorkspaces.filter((w) => !w.deletedAt)}
            selected={selectedWorkspace}
            setSelected={onWorkspaceChange}
            className="w-40"
          />
        </div>
      </DataTableHeader>
      <DataTable
        table={table}
        className={cn('min-h-[420px]')}
        useVirtual={permUserCounts.length > 100}
        emptyStateText="No perms found"
        onRowClick={handleRowClick}
      />
      <DataTableFooter table={table}>
        <p>{rowCountCopy}</p>
      </DataTableFooter>
    </>
  )
}

export default WorkspacePermUserCount
