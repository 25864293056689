import React from 'react'

import {
  EMPTY_QUERY_HELP_TEXT,
  IS_LOADING_HELP_TEXT,
} from 'utils/tooltip-texts'
import { cn } from 'utils/utils'

import AskHarveyButton from 'components/common/ask-harvey-button'
import { Label } from 'components/ui/label'
import { Textarea } from 'components/ui/text-area'
import { TooltipTrigger, Tooltip, TooltipContent } from 'components/ui/tooltip'

interface Props {
  label?: string
  placeholder?: string
  text?: string
  onTextChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
  onAskHarvey?: (e: React.BaseSyntheticEvent) => void
  showSubmitButton?: boolean
  isLoading?: boolean
  className?: string
  disabled?: boolean
  maxCharacters?: number
  textareaRef?: React.RefObject<HTMLTextAreaElement>
}

const BasicInput = ({
  label,
  placeholder,
  text,
  onTextChange,
  onAskHarvey,
  showSubmitButton = true,
  isLoading,
  className,
  disabled,
  maxCharacters,
  textareaRef,
}: Props) => {
  const handleSubmit = (e: React.BaseSyntheticEvent) => {
    if (!onAskHarvey) return
    e.preventDefault()
    onAskHarvey(e)
  }

  const isAskHarveyDisabled = !text?.trim().length || isLoading

  const getButtonTooltip = () => {
    if (!text?.trim().length) {
      return EMPTY_QUERY_HELP_TEXT
    } else if (isLoading) {
      return IS_LOADING_HELP_TEXT
    } else {
      return ''
    }
  }

  return (
    <form className={cn('relative', className)}>
      {label && <Label>{label}</Label>}
      <Textarea
        value={text}
        onChange={onTextChange}
        placeholder={placeholder}
        className="h-full resize-none"
        maxLength={maxCharacters}
        ref={textareaRef}
        data-testid="basic-input-textarea"
        disabled={disabled}
      />
      {showSubmitButton && (
        <AskHarveyButton
          disabled={disabled || isAskHarveyDisabled}
          handleSubmit={handleSubmit}
          className={cn('absolute right-[9px] top-[34px] w-full', {
            'top-[13px]': label === undefined,
          })}
          tooltip={getButtonTooltip()}
        />
      )}
      {maxCharacters && (
        <div className="absolute bottom-2 right-2">
          <Tooltip>
            <TooltipTrigger
              onClick={(e: React.FormEvent) => {
                e.preventDefault()
              }}
            >
              <p className="text-muted">
                {text?.trim().length} / {maxCharacters}
              </p>
            </TooltipTrigger>
            <TooltipContent>Maximum query length</TooltipContent>
          </Tooltip>
        </div>
      )}
    </form>
  )
}

export { BasicInput }
