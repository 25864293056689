/* tslint:disable */
/* eslint-disable */
/**
 * Harvey Web App - User Permissions
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const TagScope = {
    WORKSPACE: 'WORKSPACE',
    DOCUMENT_CLASSIFICATION: 'DOCUMENT_CLASSIFICATION',
    VAULT_PROJECT: 'VAULT_PROJECT'
} as const;
export type TagScope = typeof TagScope[keyof typeof TagScope];


export function instanceOfTagScope(value: any): boolean {
    for (const key in TagScope) {
        if (Object.prototype.hasOwnProperty.call(TagScope, key)) {
            if (TagScope[key as keyof typeof TagScope] === value) {
                return true;
            }
        }
    }
    return false;
}

export function TagScopeFromJSON(json: any): TagScope {
    return TagScopeFromJSONTyped(json, false);
}

export function TagScopeFromJSONTyped(json: any, ignoreDiscriminator: boolean): TagScope {
    return json as TagScope;
}

export function TagScopeToJSON(value?: TagScope | null): any {
    return value as any;
}

