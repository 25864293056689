import React from 'react'

import _ from 'lodash'

import Services from 'services'

import { displayErrorMessage, displaySuccessMessage } from 'utils/toast'
import { cn } from 'utils/utils'

import ClientMatterSelect from 'components/client-matters/client-matter-select'
import { useClientMattersStore } from 'components/client-matters/client-matters-store'
import { useAnalytics } from 'components/common/analytics/analytics-context'
import { Button } from 'components/ui/button'
import { Dialog, DialogContent } from 'components/ui/dialog'
import { PatchFolder } from 'components/vault/utils/vault-fetcher'
import { useVaultStore } from 'components/vault/utils/vault-store'

interface VaultProjectEditClientMatterDialogProps {
  modalOpen: boolean
  setModalOpen: (open: boolean) => void
  projectId: string
}

const VaultProjectEditClientMatterDialog: React.FC<
  VaultProjectEditClientMatterDialogProps
> = ({ modalOpen, setModalOpen, projectId }) => {
  const { trackEvent } = useAnalytics()

  const projectsMetadata = useVaultStore((s) => s.projectsMetadata)
  const clientMatters = useClientMattersStore((s) => s.clientMatters)

  const updateProjectMetadataClientMatterId = useVaultStore(
    (s) => s.updateProjectMetadataClientMatterId
  )

  const projectMetadata = projectsMetadata[projectId]
  const currentClientMatterName = clientMatters.find(
    (cm) => cm.id === projectMetadata?.clientMatterId
  )?.name

  const [selected, setSelected] = React.useState<string>(
    currentClientMatterName ?? ''
  )
  const containerRef = React.useRef<HTMLDivElement>(null)

  const isSubmitButtonDisabled = selected === currentClientMatterName

  const onSubmit = React.useCallback(async () => {
    const clientMatterId =
      clientMatters.find((cm) => cm.name === selected)?.id ?? null
    if (clientMatterId) {
      try {
        updateProjectMetadataClientMatterId(clientMatterId)
        await PatchFolder(projectId, {
          newClientMatterId: clientMatterId,
        })
        displaySuccessMessage('Client matter updated successfully')
      } catch (error) {
        displayErrorMessage('Failed to update client matter')
      }
    }
    setModalOpen(false)
  }, [
    clientMatters,
    projectId,
    setModalOpen,
    selected,
    updateProjectMetadataClientMatterId,
  ])

  return (
    <Dialog
      open={modalOpen}
      onOpenChange={(open) => {
        setModalOpen(open)
        Services.HoneyComb.Record({
          metric: `ui.vault_project_edit_client_matter_dialog_${
            open ? 'opened' : 'dismissed'
          }`,
          project_id: projectId,
        })
        trackEvent(
          `Vault Project Edit Client Matter Dialog ${
            open ? 'Opened' : 'Dismissed'
          }`,
          {
            project_id: projectId,
          }
        )
      }}
    >
      <DialogContent showCloseIcon={false}>
        <div>
          <p className="text-xl">Edit Client Matter</p>
          <div className="mt-6" ref={containerRef}>
            <ClientMatterSelect
              className={cn({
                'text-muted': _.isEmpty(selected),
              })}
              selectedValue={selected}
              setSelectedValue={setSelected}
              allowAddNewItem={false}
              containerRef={containerRef}
              showClearOption={false}
            />
          </div>
          <div className="mt-6 flex flex-row justify-end space-x-2">
            <Button
              variant="ghost"
              onClick={() => {
                setModalOpen(false)
                Services.HoneyComb.Record({
                  metric: `ui.vault_project_edit_client_matter_dialog_cancelled`,
                  project_id: projectId,
                })
                trackEvent(
                  `Vault Project Edit Client Matter Dialog Cancelled`,
                  {
                    project_id: projectId,
                  }
                )
              }}
            >
              Cancel
            </Button>
            <Button disabled={isSubmitButtonDisabled} onClick={onSubmit}>
              Submit
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default VaultProjectEditClientMatterDialog
