export const harveyForWordAnnouncement = () => {
  return `
  ## Use Harvey for Word

  <div style="text-align: center; width: 100%;">
    <img src="./img/announcements/harvey-for-word.png" alt="Harvey for Word">
  </div>

  #### Install the Add-In
  Reach out to your IT admin for access. Once they set it up, you'll be ready to go.

  #### How it works
  After installation, Harvey is ready to assist with drafting, reviewing, and editing -- all directly within your document.

  If you're experiencing issues, see our [Troubleshooting Guide](https://help.harvey.ai/en/articles/10072892-troubleshooting-word-add-in-issues).
  `
}
