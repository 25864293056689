import _ from 'lodash'

import { UserInfo } from 'models/user-info'
import { EventKind } from 'openapi/models/EventKind'
import { ResearchArea } from 'openapi/models/ResearchArea'
import { ResearchFilter } from 'openapi/models/ResearchFilter'

import { ResearchAreaToDetail } from './research-definitions'

export const RESEARCH_PATH = '/research'
export const RESEARCH_ARTIFACT_PATH = RESEARCH_PATH + '/artifact'

// Get a single filter by ID
const getFilterById = (
  id: string,
  taxonomy: ResearchFilter[]
): ResearchFilter | undefined => {
  for (const filter of taxonomy) {
    if (filter.id === id) {
      return filter
    }
    if (filter.children.length > 0) {
      const foundFilter = getFilterById(id, filter.children)
      if (foundFilter) {
        return foundFilter
      }
    }
  }
  return undefined
}

// Same as getFilterById but bulk
export const getFiltersById = (
  ids: string[],
  taxonomy: ResearchFilter[]
): ResearchFilter[] => {
  // handle error if filters not found
  return ids.map((id) => {
    const filter = getFilterById(id, taxonomy)
    return filter as ResearchFilter
  })
}

export const getAllChildrenDeep = (
  filterNode: ResearchFilter,
  depth: number = 0
): ResearchFilter[] => {
  if (depth > 10) {
    return []
  }
  let children = filterNode.children
  if (filterNode.children.length > 0) {
    filterNode.children.forEach((child) => {
      children = [...children, ...getAllChildrenDeep(child, depth + 1)]
    })
  }
  return children
}

export const getAllParentsDeep = (
  filterNodes: ResearchFilter[],
  allFilterNodes: ResearchFilter[],
  height: number = 0
): ResearchFilter[] => {
  // recursively traverse up the tree to get the all parents and ancestors
  if (height > 10) {
    return []
  }
  const seenParentIds: { [parentId: string]: boolean } = {}
  const parents: ResearchFilter[] = []

  for (const filterNode of filterNodes) {
    const parent = allFilterNodes.find(
      (currNode) => currNode.id === filterNode.parentId
    )
    if (!_.isNil(parent) && !seenParentIds[parent.id]) {
      parents.push(parent)
      seenParentIds[parent.id] = true
      const grandParents = getAllParentsDeep(
        [parent],
        allFilterNodes,
        height + 1
      )
      grandParents.forEach((grandParent) => {
        if (!seenParentIds[grandParent.id]) {
          parents.push(grandParent)
          seenParentIds[grandParent.id] = true
        }
      })
    }
  }

  return parents
}

export const getFilterDepth = (
  filterNode: ResearchFilter,
  allFilterIdsToFilterMap: { [key: string]: ResearchFilter }
): number => {
  // Root node has depth 0, children of root node have depth 1, etc.
  const parentId = filterNode.parentId
  if (_.isNil(parentId)) {
    return 0
  }
  const parentNode = allFilterIdsToFilterMap[parentId]
  if (_.isNil(parentNode)) {
    return 0
  }
  return 1 + getFilterDepth(parentNode, allFilterIdsToFilterMap)
}

export const hasSelectedChild = (
  filter: ResearchFilter,
  selectedFilters: ResearchFilter[]
): boolean => {
  if (selectedFilters.some((n) => n.id === filter.id)) {
    return true
  }
  return filter.children.some((filter) =>
    hasSelectedChild(filter, selectedFilters)
  )
}

export const getNumSelectedChildren = (
  filter: ResearchFilter,
  selectedFilters: ResearchFilter[]
): number => {
  return (
    selectedFilters.filter((n) => filter.children.some((c) => c.id === n.id))
      .length ?? 0
  )
}

export const anyNodeHasL0Ancestor = (
  l0ParentId: string,
  selectedFilters: ResearchFilter[],
  allFilterNodes: ResearchFilter[]
): boolean => {
  // Check if any node in the selectedFilters list either is l0ParentId or contains it as its topmost ancestor
  // Cheaper checks, can exit early
  const isFilterMatchedOrImmediateParent = selectedFilters.some(
    (filter) => filter.id === l0ParentId || filter.parentId === l0ParentId
  )
  if (isFilterMatchedOrImmediateParent) {
    return true
  }
  // More expensive recursive check
  const allParentsDeep = getAllParentsDeep(selectedFilters, allFilterNodes)
  return allParentsDeep.some((filter) => filter.id === l0ParentId)
}

export const hasAllSiblingsSelected = (
  filter: ResearchFilter,
  selectedFilters: ResearchFilter[],
  parentFilter: ResearchFilter
): boolean => {
  return parentFilter?.children
    .filter((c) => c.id !== filter.id) // Exclude current filter
    .every((c) => selectedFilters.some((n) => c.id === n.id))
}

export const isEmptyOrNil = (response: string): boolean => {
  return response === '' || _.isNil(response)
}

export const isEmptyState = (response: string, headerText: string): boolean => {
  return isEmptyOrNil(response) && headerText === ''
}

export const isResponseStarted = (headerText: string): boolean => {
  return headerText !== ''
}

export const isDoneStreaming = (
  response: string,
  headerText: string
): boolean => {
  return (
    !isEmptyOrNil(response) &&
    (_.isEmpty(headerText) || headerText === 'Done!' || headerText === 'Done')
  )
}

export const computeIsDisabled = ({
  filter,
  selectedFilters,
  openFilters,
  taxonomy,
}: {
  filter: ResearchFilter
  selectedFilters: ResearchFilter[]
  openFilters: ResearchFilter[]
  taxonomy: ResearchFilter[]
}): boolean => {
  // if no filters opened or selected yet, everything is enabled
  if (selectedFilters.length === 0) return false
  if (openFilters.length === 0) return false

  const flattenedTaxonomy = taxonomy.concat(
    taxonomy.flatMap((researchFilter) => getAllChildrenDeep(researchFilter))
  )

  const selectedFiltersAndParents = [
    ...selectedFilters,
    ...getAllParentsDeep(selectedFilters, flattenedTaxonomy),
  ]
  for (const { id, exclusionTag } of selectedFiltersAndParents) {
    if (
      exclusionTag &&
      id !== filter.id &&
      exclusionTag === filter.exclusionTag
    ) {
      return true
    }
  }

  return false
}

export const isResearchArea = (
  area: string | undefined
): area is ResearchArea => {
  return Object.values(ResearchArea).includes(area as ResearchArea)
}

export const getTitle = (area: ResearchArea) => {
  const titleMap: { [K in ResearchArea]?: string } = {
    [ResearchArea.TAX]: 'Tax AI Assistant',
    [ResearchArea.EDGAR]: 'EDGAR',
    [ResearchArea.MEMOS]: 'Memos',
    [ResearchArea.EURLEX]: 'EUR-Lex',
    [ResearchArea.USACASELAW]: 'US Case Law',
    [ResearchArea.USCASELAW]: 'US Case Law',
    [ResearchArea.FRANCECASELAW]: 'French Case Law',
    [ResearchArea.AUSBREACHREPORTING]: 'Australia Breach Reporting',
    [ResearchArea.CUATRECASAS]: 'Cuatrecasas',
    [ResearchArea.FROMCOUNSEL]: 'From Counsel',
  }

  return titleMap[area]!
}

export const getSubtitle = (area: ResearchArea) => {
  const subtitleMap: { [K in ResearchArea]?: string } = {
    [ResearchArea.TAX]: 'Understand tax laws and guidance',
    [ResearchArea.EDGAR]: 'Understand public company filings',
    [ResearchArea.MEMOS]: 'Understand law firm memos',
    [ResearchArea.EURLEX]: 'Understand cases from the EU',
    [ResearchArea.USACASELAW]: 'Understand cases from the US',
    [ResearchArea.USCASELAW]: 'Understand cases from the US',
    [ResearchArea.FRANCECASELAW]: 'Understand cases from France',
    [ResearchArea.AUSBREACHREPORTING]:
      'Understand Australia breach reporting requirements',
    [ResearchArea.CUATRECASAS]: 'Access the Cuatrecasas knowledge base',
  }

  return subtitleMap[area]!
}

export const getEventForResearchArea = (researchArea?: string): EventKind => {
  if (!researchArea) return EventKind.OPEN_ENDED
  const mapping: Record<string, EventKind> = {
    [ResearchArea.TAX]: EventKind.JAPAN_TAX_QA, // db-storable value // should this return correct event kind for each region?
    [ResearchArea.EDGAR]: EventKind.SEC_EDGAR_QA,
    [ResearchArea.EURLEX]: EventKind.EURLEX_QA,
    [ResearchArea.USACASELAW]: EventKind.USA_CASELAW,
    [ResearchArea.FRANCECASELAW]: EventKind.FRANCE_CASELAW,
    [ResearchArea.MEMOS]: EventKind.MEMOS_QA,
    [ResearchArea.CUATRECASAS]: EventKind.CUATRECASAS,
    [ResearchArea.FROMCOUNSEL]: EventKind.FROM_COUNSEL,
  } as const
  return researchArea in mapping ? mapping[researchArea] : EventKind.OPEN_ENDED
}

export const userHasResearchPermission = (
  userInfo: UserInfo,
  researchArea: ResearchArea
) => {
  const { permCheck } = ResearchAreaToDetail[researchArea]

  return userInfo.IsInternalUser && permCheck(userInfo)
}

export const hasResearchAreaPermission = (
  userInfo: UserInfo,
  researchArea: string
) => {
  if (!isResearchArea(researchArea)) return false
  const { permCheck } = ResearchAreaToDetail[researchArea]
  return permCheck(userInfo)
}

export const getCaseDateYear = (date: string | undefined) => {
  if (!date) return ''
  const matches = date.match(/[A-Z][a-z]+ \d{1,2}, (\d{4})/)
  if (!matches || matches.length !== 2) return ''
  return matches[1]
}
