import React from 'react'

import BasicTransition from 'components/ui/basic-transition'
import { Button } from 'components/ui/button'
import * as Card from 'components/ui/card'
import { RadioGroup, RadioGroupItem } from 'components/ui/radio-group'

interface ComparePreferencePickerProps {
  selectedPreference: string
  onSelectPreference: (preference: string) => void
  onSubmit: () => void
  loading?: boolean
}

const instructionMessage = `Which of the two responses below is more helpful?`
const criteriaMessage = `In determining helpfulness, you can consider answer quality, sources, or any other criteria that would facilitate your research.\n\n
You can either click on a response directly or check one of the boxes below. Click submit once you’ve made your selection.`

const ComparePreferencePicker: React.FC<ComparePreferencePickerProps> = ({
  selectedPreference,
  onSelectPreference,
  onSubmit,
  loading,
}) => {
  const options = [
    {
      text: 'Response 1',
      value: '1',
    },
    {
      text: 'Response 2',
      value: '2',
    },
    {
      text: 'Tie',
      value: '3',
    },
  ]

  return (
    <BasicTransition show={!loading}>
      <Card.Card className="relative z-50 mt-4 px-4 py-2">
        <div className="mt-2 py-2 pl-8 pr-8">
          <div className="mb-8 w-full cursor-pointer items-center justify-between">
            <p className="mb-4 font-semibold">{instructionMessage}</p>
            <p>{criteriaMessage}</p>
          </div>
          {options.map((option, index) => (
            <div key={index}>
              <div className="mb-4 flex h-5 items-center space-x-2">
                <RadioGroup>
                  <RadioGroupItem
                    id={`radio-${index}`}
                    label={option.text}
                    value={option.value}
                    checked={selectedPreference === option.value}
                    onClick={() => onSelectPreference(option.value)}
                  />
                </RadioGroup>
              </div>
            </div>
          ))}
        </div>
        <div className="flex w-full justify-end">
          <Button size="sm" onClick={onSubmit}>
            Submit
          </Button>
        </div>
      </Card.Card>
    </BasicTransition>
  )
}

export default ComparePreferencePicker
