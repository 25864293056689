import React from 'react'

import _ from 'lodash'
import { SquareArrowOutUpRight } from 'lucide-react'

import { useGeneralStore } from 'stores/general-store'
import { Maybe } from 'types'

import { cn } from 'utils/utils'

import { Button } from 'components/ui/button'
import Icon from 'components/ui/icon/icon'
import Link from 'components/ui/link/link'

export enum StatusVariant {
  info = 'info',
  warning = 'warning',
  success = 'success',
  destructive = 'destructive',
}

export const STATUS_VARIANT_TO_TEXT_COLOR: Record<StatusVariant, string> = {
  info: '',
  warning: 'text-warning',
  success: 'text-success',
  destructive: 'text-destructive',
}
export const STATUS_VARIANT_TO_BG_COLOR: Record<StatusVariant, string> = {
  info: '',
  warning: 'bg-warning',
  success: 'bg-success',
  destructive: 'bg-destructive',
}

interface CTA {
  text: string
  link: string
}

export interface StatusBarProps {
  variant: StatusVariant
  header?: Maybe<string>
  description: string
  cta?: Maybe<CTA>
}

const StatusBar: React.FC = () => {
  const statusBar = useGeneralStore((s) => s.statusBar)

  if (_.isNil(statusBar)) {
    return null
  }

  const hasHeader = !_.isEmpty(statusBar.header)
  const hasCTA = !_.isNil(statusBar.cta)

  return (
    <div
      className={cn(
        'bottom-0 left-0 top-0 z-50 flex min-h-10 w-full items-center border-b px-6 pr-6',
        STATUS_VARIANT_TO_BG_COLOR[statusBar.variant],
        {
          'justify-between': !hasCTA,
        }
      )}
      data-testid="status-bar"
    >
      {hasHeader && (
        <div className="shrink-0">
          <p
            className={cn(
              'font-medium',
              STATUS_VARIANT_TO_TEXT_COLOR[statusBar.variant]
            )}
          >
            {statusBar.header}
          </p>
        </div>
      )}
      <div className={cn('flex flex-grow justify-center', { 'mx-4': !hasCTA })}>
        <p
          className={cn(
            'text-center',
            STATUS_VARIANT_TO_TEXT_COLOR[statusBar.variant]
          )}
        >
          {statusBar.description}
        </p>
      </div>
      {!_.isNil(statusBar.cta) && !_.isEmpty(statusBar.cta.text) && (
        <Link to={statusBar.cta.link} target="_blank">
          <Button size="sm" variant="link" className="min-w-36">
            {statusBar.cta.text}
            <Icon icon={SquareArrowOutUpRight} className="ml-1" size="small" />
          </Button>
        </Link>
      )}
    </div>
  )
}

export default StatusBar
