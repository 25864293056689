import React, { useCallback, useEffect, useRef, useState } from 'react'
import { FileRejection, useDropzone } from 'react-dropzone'

import _ from 'lodash'
import { ChevronsUpDown, UploadCloudIcon } from 'lucide-react'
import pluralize from 'pluralize'
import { useShallow } from 'zustand/react/shallow'

import { IntegrationType } from 'openapi/models/IntegrationType'
import { UploadedFile } from 'openapi/models/UploadedFile'
import { useIntegrationsStore } from 'stores/integrations-store'
import { FileType, FileTypeToExtension } from 'types/file'

import { onDrop } from 'utils/dropzone'
import { mbToBytes } from 'utils/file-utils'
import { cn } from 'utils/utils'

import { useAssistantAnalytics } from 'components/assistant/hooks/use-assistant-analytics'
import { useAssistantFileUpload } from 'components/assistant/hooks/use-assistant-file-upload'
import {
  FileUploadingState,
  useAssistantStore,
} from 'components/assistant/stores/assistant-store'
import {
  ACCEPTED_FILE_TYPES,
  MAX_FILE_SIZE,
  MAX_TOTAL_FILE_SIZE,
  MAX_ZIP_FILE_SIZE,
  NUM_MAX_FILES,
} from 'components/assistant/utils/constants'
import { useAuthUser } from 'components/common/auth-context'
import { Dropzone } from 'components/common/dropzone/dropzone'
import DropzoneDescription from 'components/common/dropzone/dropzone-description'
import SharepointButton from 'components/common/sharepoint-button'
import SharepointOneDriveLogo from 'components/common/sharepoint-onedrive-logo'
import { IntegrationDefinitions } from 'components/settings/integrations/integration-definitions'
import { Button } from 'components/ui/button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from 'components/ui/dropdown-menu'
import Icon from 'components/ui/icon/icon'
import { ScrollArea } from 'components/ui/scroll-area'

import AssistantFiles from './assistant-files'
import AssistantInputSourceHeader from './assistant-input-source-header'
import { AssistantMode } from './assistant-mode-select'

type Props = {
  dropzoneSources?: (JSX.Element | null)[]
  hasFilesHeader: boolean
  mode: AssistantMode
  onUpload: () => void
  onCancel: () => void
  setAskHarveyDisabled: (disabled: boolean) => void
}

const AssistantFilesInput: React.FC<Props> = ({
  dropzoneSources,
  hasFilesHeader,
  mode,
  onUpload,
  onCancel,
  setAskHarveyDisabled,
}) => {
  const authUser = useAuthUser()
  const { handleFileUpload, handleRemoveFile, handleRemoveAllFiles } =
    useAssistantFileUpload(mode)
  const trackEvent = useAssistantAnalytics()

  const documents = useAssistantStore((s) => s.documents)
  const documentsUploading = useAssistantStore((s) => s.documentsUploading)

  const [zipFiles, setZipFiles] = useState<File[]>([])
  const [pstFiles, setPstFiles] = useState<File[]>([])
  const hasUploadedFiles =
    documents.length > 0 ||
    documentsUploading.length > 0 ||
    zipFiles.length > 0 ||
    pstFiles.length > 0
  const filesAttachedCount = documents.length + documentsUploading.length
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  const sharepointEnabled =
    IntegrationDefinitions[IntegrationType.SHAREPOINT].available(authUser)

  const [localDropzoneSources, setLocalDropzoneSources] = useState<
    (JSX.Element | null)[]
  >([])

  useEffect(() => {
    if (dropzoneSources) {
      setLocalDropzoneSources(dropzoneSources)
    }
  }, [dropzoneSources])

  const handleFileUploadWithTracking = useCallback(
    async (files: File[]) => {
      setZipFiles([])
      setPstFiles([])
      await handleFileUpload(files)
    },
    [handleFileUpload]
  )

  const handleRemoveFileWithTracking = (
    file: UploadedFile | FileUploadingState
  ) => {
    handleRemoveFile(file)
  }

  const prevUploadedFiles = useRef(!!hasUploadedFiles)
  useEffect(() => {
    if (prevUploadedFiles.current && !hasUploadedFiles) {
      onCancel()
    } else if (!prevUploadedFiles.current && hasUploadedFiles) {
      onUpload()
    }
    prevUploadedFiles.current = hasUploadedFiles
  }, [hasUploadedFiles, onCancel, onUpload])

  const onAssistantDrop = useCallback(
    async (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      setAskHarveyDisabled(true)
      setZipFiles(acceptedFiles.filter((file) => file.type === FileType.ZIP))
      setPstFiles(
        acceptedFiles.filter(
          (file) =>
            file.type === FileType.OUTLOOK &&
            file.name.toLowerCase().endsWith('.pst')
        )
      )

      return onDrop({
        acceptedFiles,
        fileRejections,
        currentFileCount: documents.length + documentsUploading.length,
        maxFiles: NUM_MAX_FILES,
        acceptedFileTypes: ACCEPTED_FILE_TYPES,
        maxFileSize: mbToBytes(MAX_FILE_SIZE),
        maxZipFileSize: mbToBytes(MAX_ZIP_FILE_SIZE),
        maxTotalFileSizeProps: {
          maxTotalFileSize: mbToBytes(MAX_TOTAL_FILE_SIZE),
          currentTotalFileSize: documents.reduce(
            (total, file) => total + (file.size ?? 0),
            0
          ),
        },
        handleAcceptedFiles: handleFileUploadWithTracking,
      }).finally(() => {
        setAskHarveyDisabled(false)
        setZipFiles([])
        setPstFiles([])
      })
    },
    [
      handleFileUploadWithTracking,
      documents,
      documentsUploading,
      setAskHarveyDisabled,
    ]
  )

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop: onAssistantDrop,
    maxFiles: NUM_MAX_FILES,
    noClick: hasUploadedFiles,
  })

  const getRootPropsWithTracking = () => {
    const rootProps = getRootProps()
    return {
      ...rootProps,
      onClick: (e: React.MouseEvent<HTMLElement>) => {
        rootProps.onClick?.(e)
        trackUploadFromComputer()
      },
    }
  }

  const onUploadFromSharePoint = useCallback(
    async (files: File[]) => {
      await onAssistantDrop(files, [])
    },
    [onAssistantDrop]
  )

  const trackUploadFromComputer = () => trackEvent('Upload Files Clicked')

  const onUploadFromComputer = () => {
    open()
    trackUploadFromComputer()
  }

  const [setSharepointPickerOpenState] = useIntegrationsStore(
    useShallow((state) => [state.setSharepointPickerOpenState])
  )

  const handleSharePointClick = async () => {
    setSharepointPickerOpenState({
      acceptedFileTypes: _.flatMap(
        ACCEPTED_FILE_TYPES,
        (type) => FileTypeToExtension[type] ?? []
      ),
      onUploadFromSharepoint: onUploadFromSharePoint,
    })
  }

  useEffect(() => {
    if (
      sharepointEnabled &&
      !localDropzoneSources.some(
        (source) =>
          React.isValidElement(source) && source.key === 'sharepoint-button'
      )
    ) {
      setLocalDropzoneSources((prev) => [
        ...prev,
        <div
          className="flex space-x-2"
          onClick={(e) => e.stopPropagation()}
          onKeyDown={(e) => e.stopPropagation()}
          role="button"
          tabIndex={0}
          key="sharepoint-button"
        >
          <SharepointButton
            onUploadFromSharepoint={onUploadFromSharePoint}
            acceptedFileTypes={ACCEPTED_FILE_TYPES}
          />
        </div>,
      ])
    }
  }, [localDropzoneSources, onUploadFromSharePoint, sharepointEnabled])

  return (
    <div
      {...(hasUploadedFiles ? getRootProps() : {})}
      className="relative flex h-full flex-col"
    >
      {hasFilesHeader && (
        <p className="pb-2 text-xs font-medium text-secondary">Files</p>
      )}
      {hasUploadedFiles && (
        <AssistantInputSourceHeader
          actions={
            <>
              <Button size="sm" variant="ghost" onClick={handleRemoveAllFiles}>
                Cancel
              </Button>
              {!sharepointEnabled ? (
                <Button
                  size="sm"
                  variant="outline"
                  onClick={onUploadFromComputer}
                >
                  Add more files
                </Button>
              ) : (
                <DropdownMenu
                  onOpenChange={setIsDropdownOpen}
                  open={isDropdownOpen}
                >
                  <DropdownMenuTrigger asChild>
                    <Button
                      className="pointer-events-auto"
                      size="sm"
                      variant="outline"
                    >
                      Add more files
                      <Icon icon={ChevronsUpDown} className="ml-1 h-3 w-3" />
                    </Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuSeparator />
                  <DropdownMenuContent align="end">
                    <DropdownMenuItem onClick={onUploadFromComputer}>
                      From your computer
                    </DropdownMenuItem>
                    <DropdownMenuItem onClick={handleSharePointClick}>
                      <SharepointOneDriveLogo />
                    </DropdownMenuItem>
                  </DropdownMenuContent>
                </DropdownMenu>
              )}
            </>
          }
        >
          {`${filesAttachedCount} ${pluralize(
            'file',
            filesAttachedCount
          )} attached`}
        </AssistantInputSourceHeader>
      )}
      {hasUploadedFiles && (
        <input className="hidden" {...getRootPropsWithTracking()} />
      )}
      {hasUploadedFiles && isDragActive && (
        <div className="absolute z-10 size-full bg-primary p-4">
          <div className="flex size-full flex-col items-center justify-center rounded border border-dashed">
            <Icon icon={UploadCloudIcon} className="mb-2" />
            <p className="font-semibold">Drop files here</p>
            <p className="text-sm">or click to upload</p>
          </div>
        </div>
      )}
      {hasUploadedFiles ? (
        <ScrollArea className="h-full">
          <AssistantFiles
            files={documents}
            uploadingFiles={documentsUploading}
            handleRemoveFile={handleRemoveFileWithTracking}
            zipFiles={zipFiles}
            pstFiles={pstFiles}
          />
        </ScrollArea>
      ) : (
        <Dropzone
          className={cn('h-full py-4', {
            'border-ring bg-secondary-hover': isDragActive,
          })}
          isLoading={false}
          dropzone={{ getRootProps: getRootPropsWithTracking, getInputProps }}
          description={
            <DropzoneDescription
              fileTypes={ACCEPTED_FILE_TYPES}
              maxFiles={50}
              maxSize="20MB"
              totalSize="100MB"
              descriptionClassName="max-w-full"
            >
              {!!localDropzoneSources.length && (
                <span>
                  or
                  <div className="mt-2 flex items-center space-x-2">
                    {localDropzoneSources}
                  </div>
                </span>
              )}
            </DropzoneDescription>
          }
        />
      )}
    </div>
  )
}

export default AssistantFilesInput
