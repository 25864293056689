import React, { useState } from 'react'

import _ from 'lodash'

import { type Workspace } from 'models/workspace'
import { Maybe } from 'types'

import { cn } from 'utils/utils'

import Combobox from 'components/ui/combobox/combobox'

interface WorkspaceDropdownProps {
  selected: Maybe<Workspace>
  setSelected: (workspace: Workspace) => void
  workspaces: Workspace[]
  className?: string
  showClearOption?: boolean
}

const WorkspaceDropdown: React.FC<WorkspaceDropdownProps> = ({
  selected,
  setSelected,
  workspaces,
  className,
  showClearOption = false,
}) => {
  const [workspaceDropdown, setWorkspaceDropdown] = useState<string>(
    selected?.slug ?? ''
  )

  React.useEffect(() => {
    setWorkspaceDropdown(selected?.slug ?? '')
  }, [selected?.slug])

  const onDropdownChange = (value: string): void => {
    setWorkspaceDropdown(value)
    const workspace = workspaces.find((workspace) => workspace.slug === value)
    if (!_.isNil(workspace)) {
      setSelected(workspace)
    }
  }

  if (workspaces.length <= 1) {
    return null
  }

  return (
    <Combobox
      className={cn('h-8 w-40', className)}
      data-testid="workspace-dropdown"
      value={workspaceDropdown}
      setValue={onDropdownChange}
      options={_.sortBy(workspaces, [
        (workspace) =>
          workspace.clientName ?? workspace.friendlyName ?? workspace.slug,
      ]).map((workspace) => ({
        value: workspace.slug,
        label: workspace.clientName ?? workspace.friendlyName ?? workspace.slug,
      }))}
      defaultText={
        selected?.clientName ??
        selected?.friendlyName ??
        selected?.slug ??
        'Select workspace'
      }
      virtual={workspaces.length > 500}
      showClearOption={showClearOption}
    />
  )
}

export default WorkspaceDropdown
