import React from 'react'

import VaultRecentQueries from './components/vault-recent-queries'
import { AppMain } from 'components/common/app-main'

const VaultProjectQueries = () => {
  return (
    <AppMain hasContainer>
      <VaultRecentQueries showAll />
    </AppMain>
  )
}

export default VaultProjectQueries
