import React, { useState, useRef, FormEvent } from 'react'

import emailAddresses from 'email-addresses'

import { displayErrorMessage } from 'utils/toast'

import { ListUserExperiments } from 'components/compare/compare-fetcher'
import { Button } from 'components/ui/button'
import { Card, CardContent } from 'components/ui/card'

const ParticipantInspectorCard = () => {
  const [loading, setLoading] = useState(false)
  const [userExperiments, setUserExperiments] = useState<string[]>([])
  const userEmailRef = useRef<HTMLInputElement>(null)

  const handleSubmitUserEmail = async (userEmail: string): Promise<void> => {
    setLoading(true)
    const fetchedUserExperiments = await ListUserExperiments(userEmail)
    if (fetchedUserExperiments?.length == 0) {
      displayErrorMessage('No experiments found for user email')
      return
    }
    setUserExperiments(fetchedUserExperiments)
    setLoading(false)
  }

  const handleFormSubmit = (event: FormEvent) => {
    event.preventDefault()
    if (userEmailRef.current) {
      const email = userEmailRef.current.value.trim()
      if (!emailAddresses.parseOneAddress(email)) {
        displayErrorMessage('Please enter a valid email')
        return
      }
      void handleSubmitUserEmail(email)
    }
  }

  return (
    <Card>
      <div className="ml-4 mt-4">
        <h2 className="mb-4 text-lg font-semibold">Participant inspector</h2>
      </div>
      <CardContent>
        <form className="ml-1 w-full">
          <label>
            User email <span className="text-destructive">*</span>
            <input
              ref={userEmailRef}
              className="mb-4 w-full rounded border p-2"
              required
            />
          </label>
          <Button variant="default" type="submit" onClick={handleFormSubmit}>
            Inspect
          </Button>
        </form>
      </CardContent>
      {!loading && userExperiments?.length > 0 && (
        <div className="mb-6 ml-6">
          <h3 className="mb-2 font-semibold">Experiments</h3>
          {userExperiments.map((experiment, index) => (
            <p className="font-semibold" key={index}>
              - {experiment}
            </p>
          ))}
        </div>
      )}
    </Card>
  )
}

export default ParticipantInspectorCard
