import React from 'react'

import _ from 'lodash'

import {
  setVaultEnablement,
  setVaultSettings,
  Workspace,
} from 'models/workspace'
import { WorkspaceFeature } from 'openapi/models/WorkspaceFeature'

import { displayErrorMessage, displaySuccessMessage } from 'utils/toast'

import { Button } from 'components/ui/button'
import { Dialog, DialogContent, DialogFooter } from 'components/ui/dialog'

interface VaultDisableDialogProps {
  open: boolean
  onOpenChange: (open: boolean) => void
  workspace: Workspace
  type: WorkspaceFeature
  onDisable: () => Promise<void>
}

const VaultDisableDialog = ({
  open,
  onOpenChange,
  workspace,
  type,
  onDisable,
}: VaultDisableDialogProps) => {
  const onDisableClick = async () => {
    try {
      // remove all vault settings
      await setVaultSettings(workspace.id, {}, type)
      const disabled = await setVaultEnablement(type, workspace.id, false)
      if (disabled) {
        await onDisable()
        onOpenChange(false)
        displaySuccessMessage(
          `${_.startCase(type).toLowerCase()} disabled successfully`
        )
      } else {
        displayErrorMessage(
          `Failed to disable ${_.startCase(type).toLowerCase()}`
        )
      }
    } catch (e) {
      displayErrorMessage(
        `Failed to disable ${_.startCase(type).toLowerCase()}: ${
          e instanceof Error ? e.message : 'Unknown error'
        }`
      )
    }
  }
  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent showCloseIcon={false}>
        <p className="text-xl">
          Disable {_.startCase(type).toLowerCase()} for {workspace.friendlyName}
        </p>
        <p>
          This will remove all perms associated with{' '}
          {_.startCase(type).toLowerCase()}
        </p>
        <DialogFooter>
          <Button onClick={() => onOpenChange(false)} variant="secondary">
            Cancel
          </Button>
          <Button onClick={() => onDisableClick()} variant="destructive">
            Disable
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default VaultDisableDialog
