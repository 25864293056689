import {
  ColumnDataType,
  ReviewCellResponse,
} from 'components/vault/utils/vault'

import { ClassifyCellRenderer } from './classify-cell-renderer'
import { CurrencyCellRenderer } from './currency-cell-renderer'
import { DateCellRenderer } from './date-cell-renderer'
import { DurationCellRenderer } from './duration-cell-renderer'
import { NumericCellRenderer } from './numeric-cell-renderer'
import { TextCellRenderer } from './text-cell-renderer'

export class CompoundCellRenderer {
  renderCell(response: ReviewCellResponse[]) {
    let renderedValue = ''
    // TODO: handle separating multiple values based on type
    for (const r of response) {
      switch (r.type) {
        case ColumnDataType.currency:
          renderedValue += currencyRenderer.renderCell(r)
          break
        case ColumnDataType.date:
          renderedValue += dateRenderer.renderCell(r)
          break
        case ColumnDataType.duration:
          renderedValue += durationRenderer.renderCell(r)
          break
        case ColumnDataType.classify:
          renderedValue += classifyRenderer.renderCell(r)
          break
        case ColumnDataType.numeric:
          renderedValue += numericRenderer.renderCell(r)
          break
        case ColumnDataType.freeResponse:
        default:
          renderedValue += textRenderer.renderCell(r)
          break
      }
    }
    return renderedValue
  }
}

// Create single instances of each renderer type
const textRenderer = new TextCellRenderer()
const dateRenderer = new DateCellRenderer()
const durationRenderer = new DurationCellRenderer()
const classifyRenderer = new ClassifyCellRenderer()
const currencyRenderer = new CurrencyCellRenderer()
const numericRenderer = new NumericCellRenderer()
const compoundRenderer = new CompoundCellRenderer()

export const cellRendererMap = {
  [ColumnDataType.freeResponse]: textRenderer,
  [ColumnDataType.date]: dateRenderer,
  [ColumnDataType.duration]: durationRenderer,
  [ColumnDataType.classify]: classifyRenderer,
  [ColumnDataType.currency]: currencyRenderer,
  [ColumnDataType.numeric]: numericRenderer,
  [ColumnDataType.extraction]: textRenderer,
  [ColumnDataType.compoundResponse]: compoundRenderer,

  // Default all others to text
  [ColumnDataType.list]: textRenderer,
  [ColumnDataType.empty]: textRenderer,
  [ColumnDataType.noFormat]: textRenderer,
  [ColumnDataType.string]: textRenderer,
  [ColumnDataType.binary]: textRenderer,
}
