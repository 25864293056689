import React from 'react'

import { Card, CardContent, CardHeader, CardTitle } from 'components/ui/card'

import { PWC_DISCLAIMER_TEXT } from './constants'

const Disclaimer = () => {
  return (
    <Card className="relative border-0 bg-secondary">
      <CardHeader>
        <CardTitle>Disclaimer</CardTitle>
      </CardHeader>
      <CardContent>
        <p className="text-sm">{PWC_DISCLAIMER_TEXT}</p>
      </CardContent>
    </Card>
  )
}

export default Disclaimer
