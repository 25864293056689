import { EM_DASH } from 'utils/utils'
import { parseIsoString } from 'utils/utils'

import { ReviewCellResponse } from 'components/vault/utils/vault'

import { StrictCellRenderer } from './cell-renderer-types'

export class DateCellRenderer extends StrictCellRenderer {
  renderCell(response: ReviewCellResponse) {
    const utcDate = parseIsoString(response.value)
    if (isNaN(utcDate.getTime()) || utcDate.getTime() === 0) {
      // TODO: we should return the original text if the date is invalid
      // change this once we have a better date parsing solution
      return response.value ? response.value : EM_DASH
    }
    const dateFormat = new Intl.DateTimeFormat(navigator.language, {
      year: 'numeric',
      month: 'long',
      day: '2-digit',
      timeZone: 'UTC',
    }).format(utcDate)
    return dateFormat
  }
}
