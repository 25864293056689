/* tslint:disable */
/* eslint-disable */
/**
 * Translation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * The languages that we currently support translating documents into. We don't currently impose limits on the source language.
 * @export
 */
export const DestinationLanguageCode = {
    DA: 'da',
    NL: 'nl',
    EN: 'en',
    FR: 'fr',
    DE: 'de',
    IT: 'it',
    PT_BR: 'pt-br',
    PT_PT: 'pt-pt',
    ZH_CN: 'zh-cn',
    ES: 'es',
    MS: 'ms',
    TA: 'ta',
    JA: 'ja',
    KO: 'ko',
    AR: 'ar'
} as const;
export type DestinationLanguageCode = typeof DestinationLanguageCode[keyof typeof DestinationLanguageCode];


export function instanceOfDestinationLanguageCode(value: any): boolean {
    for (const key in DestinationLanguageCode) {
        if (Object.prototype.hasOwnProperty.call(DestinationLanguageCode, key)) {
            if (DestinationLanguageCode[key as keyof typeof DestinationLanguageCode] === value) {
                return true;
            }
        }
    }
    return false;
}

export function DestinationLanguageCodeFromJSON(json: any): DestinationLanguageCode {
    return DestinationLanguageCodeFromJSONTyped(json, false);
}

export function DestinationLanguageCodeFromJSONTyped(json: any, ignoreDiscriminator: boolean): DestinationLanguageCode {
    return json as DestinationLanguageCode;
}

export function DestinationLanguageCodeToJSON(value?: DestinationLanguageCode | null): any {
    return value as any;
}

