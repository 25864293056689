import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { NAV_STATE_PARAM } from 'utils/routing'

import { FORK_EVENT_STATE_KEY } from 'components/assistant/utils/assistant-helpers'
import { KnowledgeSourceItem } from 'components/assistant/utils/assistant-knowledge-sources'

export type StateValues = {
  mode?: string
  query?: string
  caption?: string
  [FORK_EVENT_STATE_KEY]?: string
  knowledge?: KnowledgeSourceItem
}

// This hook manages setting a value based on search params state.
// This is useful for opening new tabs, where historyState is lost, so we instead
// store the navigation state from query params and clear them afterward.
export const useNavigationQueryState = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [storedState, setStoredState] = useState<StateValues | undefined>()
  const searchState = searchParams.get(NAV_STATE_PARAM)

  useEffect(() => {
    if (searchState) {
      try {
        const parsedState = JSON.parse(searchState)
        setStoredState(parsedState)
      } catch {
        console.error('Failed to parse navigation state')
      } finally {
        setSearchParams(
          (prevParams) => {
            const newParams = new URLSearchParams(prevParams)
            newParams.delete(NAV_STATE_PARAM)
            return newParams
          },
          { replace: true }
        )
      }
    }
  }, [searchState, setSearchParams])

  return storedState
}
