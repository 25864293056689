import Services from 'services'
import { Maybe } from 'types'

import { RolePerm } from 'components/settings/roles/role-perms-table'
import { RoleRawUser } from 'components/settings/roles/roles-users-card'

export interface WorkspaceRole {
  rolePk: string
  roleId: string
  name: string
  desc: string
  workspaceId: number
  createdAt: string
  updatedAt: string
  deletedAt: string
}

export const getWorkspaceRoles = async (
  workspaceId: number
): Promise<WorkspaceRole[]> => {
  const searchParams = new URLSearchParams()
  searchParams.append('workspace_id', workspaceId.toString())
  return Services.Backend.Get<WorkspaceRole[]>(
    `internal_admin/workspace_roles?${searchParams.toString()}`
  )
}

export const getRoleUsers = async (rolePk: string): Promise<RoleRawUser[]> => {
  const searchParams = new URLSearchParams()
  searchParams.append('role_pk', rolePk)
  return Services.Backend.Get<RoleRawUser[]>(
    `internal_admin/workspace_role/users?${searchParams.toString()}`
  )
}

export const getRolePerms = async (rolePk: string): Promise<RolePerm[]> => {
  const searchParams = new URLSearchParams()
  searchParams.append('role_pk', rolePk)
  return Services.Backend.Get<RolePerm[]>(
    `internal_admin/workspace_role/perms?${searchParams.toString()}`
  )
}

export const addUsersToRole = async (
  rolePk: string,
  userEmails: string[]
): Promise<number> => {
  return Services.Backend.Post<number>(`internal_admin/workspace_role/users`, {
    rolePk,
    userEmails,
  })
}

export const removeUsersFromRole = async (
  rolePk: string,
  userEmails: string[]
): Promise<number> => {
  return Services.Backend.Post<number>(
    `internal_admin/workspace_role/users/delete`,
    {
      rolePk,
      userEmails,
    }
  )
}

export const addPermsToRole = async (
  rolePk: string,
  permIds: string[]
): Promise<number> => {
  return Services.Backend.Post<number>(`internal_admin/workspace_role/perms`, {
    rolePk,
    permIds,
  })
}

export const removePermsFromRole = async (
  rolePk: string,
  permIds: string[]
): Promise<number> => {
  return Services.Backend.Post<number>(
    `internal_admin/workspace_role/perms/delete`,
    {
      rolePk,
      permIds,
    }
  )
}

export const fetchRoleByIdOrPk = async (
  roleId: string
): Promise<Maybe<WorkspaceRole>> => {
  const searchParams = new URLSearchParams()
  searchParams.append('role_id', roleId)
  return Services.Backend.Get<Maybe<WorkspaceRole>>(
    `internal_admin/workspace_role?${searchParams.toString()}`
  )
}
