import React, { useCallback } from 'react'

import { isEmpty } from 'lodash'
import { useShallow } from 'zustand/react/shallow'

import { EventKind } from 'openapi/models/EventKind'
import { ResearchArea } from 'openapi/models/ResearchArea'

import { useNavigateWithQueryParams } from 'hooks/use-navigate-with-query-params'
import { exportWordWithQuery, replaceSpanWithLink } from 'utils/markdown'
import { TaskType } from 'utils/task'
import { hasCompletedStreaming } from 'utils/use-harvey-socket-utils'
import { isUserInputEmpty } from 'utils/utils'

import useQueryAnalytics from 'components/common/analytics/use-query-analytics'
import { AppHeader } from 'components/common/app-header'
import { AppHeaderActions } from 'components/common/app-header-actions'
import { useAuthUser } from 'components/common/auth-context'
import ExportDialog from 'components/common/export/export-dialog'
import { ExportOptionValues } from 'components/common/export/types'
import { PWC_DISCLAIMER_TEXT } from 'components/research/constants'
import { getTitle, getSubtitle } from 'components/research/research-helpers'
import { useResearchStore } from 'components/research/research-store'

interface ResearchHeaderProps {
  isTax: boolean
  researchArea: ResearchArea
  eventKind: EventKind
  historyIdRef: React.MutableRefObject<string | undefined>
  setDefaultFilters: () => void
}

const ResearchHeader = ({
  isTax,
  researchArea,
  eventKind,
  historyIdRef,
  setDefaultFilters,
}: ResearchHeaderProps) => {
  const userInfo = useAuthUser()
  const navigate = useNavigateWithQueryParams()

  const selectedFilters = useResearchStore(useShallow((s) => s.selectedFilters))
  const queryId = useResearchStore(useShallow((s) => s.queryId))
  const response = useResearchStore(useShallow((s) => s.response))
  const annotations = useResearchStore(useShallow((s) => s.annotations))
  const sources = useResearchStore(useShallow((s) => s.sources))
  const query = useResearchStore(useShallow((s) => s.query))
  const isLoading = useResearchStore(useShallow((s) => s.isLoading))
  const completionStatus = useResearchStore(
    useShallow((s) => s.completionStatus)
  )

  const resetState = useResearchStore(useShallow((s) => s.resetState))

  const { recordExport, recordReset } = useQueryAnalytics(eventKind)

  const hasResponse = !isEmpty(response)
  const userInputEmpty = isUserInputEmpty([query, selectedFilters])
  const isQueryStreaming = isLoading && !hasCompletedStreaming(completionStatus)
  const isResetDisabled = isQueryStreaming || userInputEmpty

  const handleExport = useCallback(
    async (exportValues: ExportOptionValues) => {
      if (!queryId) return
      const titleText = `Research – ${getTitle(researchArea)}`
      const cleanedResponse = replaceSpanWithLink(response, annotations)
      const additionalSections: {
        content: string
        type: 'html' | 'markdown'
      }[] = isTax
        ? [
            { content: `<br/>`, type: 'html' },
            {
              content: `##Disclaimer\n\n${PWC_DISCLAIMER_TEXT}`,
              type: 'markdown',
            },
          ]
        : []
      const includeAnnotation = !!exportValues.includeAnnotation
      await exportWordWithQuery({
        taskType: TaskType.RESEARCH,
        includeAnnotation: includeAnnotation,
        titleText,
        queryId,
        query,
        response: cleanedResponse,
        sources: sources,
        additionalSections,
      })
      recordExport(String(queryId), 'word', includeAnnotation)
    },
    [
      isTax,
      researchArea,
      queryId,
      response,
      annotations,
      sources,
      query,
      recordExport,
    ]
  )

  const handleResetPage = () => {
    historyIdRef.current = undefined
    resetState()
    recordReset()
    // using ../ to navigate because we need to reset the dynamic route parameter (in this case researchArea)
    // and we don't want to keep the query params
    // more information here: https://stackoverflow.com/questions/77913681/usenavigate-does-not-replace-my-dynamic-parameter
    navigate(`../research/${researchArea}`, { replace: true })
    setDefaultFilters()
  }

  const actionDisabled = !hasResponse || isQueryStreaming || userInputEmpty

  return (
    <AppHeader
      title={getTitle(researchArea as ResearchArea)}
      subtitle={getSubtitle(researchArea as ResearchArea)}
      actions={
        <div className="inline-flex space-x-2">
          <AppHeaderActions
            handleReset={handleResetPage}
            resetDisabled={isResetDisabled}
            saveExample={{
              show: userInfo.canSeeSaveExample ?? false,
              disabled: actionDisabled,
              params: {
                eventId: String(queryId),
              },
            }}
          />
          <ExportDialog
            hasSources
            onExport={handleExport}
            disabled={actionDisabled}
            variant="outline"
          />
        </div>
      }
    />
  )
}

export default ResearchHeader
