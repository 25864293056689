import {
  captureError,
  getFileMetadata,
  handlePotentialRedirect,
} from 'error/handler'
import ky from 'ky'

import Services from 'services'

import { ToBackendKeys, ToFrontendKeys } from 'utils/utils'

export class RequestError extends Error {
  constructor(message: string) {
    super(message)
    this.name = 'RequestError'
  }
}

const MAX_RETRY_COUNT = 3

export type ClientOptions = {
  maxRetryCount?: number
  signal?: AbortSignal
  // caller will handle error
  throwOnError?: boolean
  // don't log any analytics generated by this request
  noAnalytics?: boolean
}

export default class Backend {
  restURL: string
  streamingURL: string
  getToken: any
  logout: () => void

  constructor(restURL?: string, streamingURL?: string) {
    this.restURL = restURL ?? 'http://localhost:8000'
    this.streamingURL = streamingURL ?? 'ws://localhost:8000'
    this.logout = () => {}
  }

  AttachGetToken(getToken: any): void {
    this.getToken = getToken
  }

  AttachLogout(logout: () => void): void {
    this.logout = logout
  }

  private async Client(maxRetryCount: number) {
    let retries = 0

    const client = ky.create({
      timeout: 10 * 60 * 1000, // 10 minutes
      hooks: {
        beforeRetry: [
          async () => {
            retries++
          },
        ],
      },
      prefixUrl: this.restURL + '/',
      retry: {
        limit: maxRetryCount,
        backoffLimit: 60 * 1000, // 1 minute
        statusCodes: [408, 413, 429, 500, 502, 503, 504],
      },
      headers: {
        Authorization: this.getToken
          ? `Bearer ${await this.getToken()}`
          : undefined,
      },
      // Send credentials (cookies). The backend only requires authentication in dev/staging, but we
      // set this unconditionally for consistency. As of 2024-04-25, prod domains do not have cookies.
      credentials: 'include',
    })
    return { client, getRetryCount: () => retries }
  }

  async Get<T>(path: string, options?: ClientOptions): Promise<T> {
    const { maxRetryCount = MAX_RETRY_COUNT, throwOnError } = options ?? {}
    const telEvent = Services.HoneyComb.Start({
      metric: 'frontend.request.get',
      host: this.restURL,
      path,
      method: 'GET',
      endpoint_name: path,
    })

    const { client, getRetryCount } = await this.Client(maxRetryCount)
    return client
      .get(path)
      .json()
      .then((r: any) => ToFrontendKeys(r.data))
      .catch(async (e) => {
        await handlePotentialRedirect(e)
        await captureError({ e, telEvent, throwOnError, logout: this.logout })
        return {} as unknown as T
      })
      .finally(() => {
        telEvent.Finish({ retry_count: getRetryCount() })
      })
  }

  async Post<T>(
    path: string,
    body: FormData | Record<string, any>,
    options?: ClientOptions
  ): Promise<T> {
    const {
      signal,
      maxRetryCount = MAX_RETRY_COUNT,
      throwOnError,
    } = options ?? {}

    const telEvent = Services.HoneyComb.Start({
      metric: 'frontend.request.post',
      host: this.restURL,
      path,
      method: 'POST',
      endpoint_name: path,
    })

    const { client, getRetryCount } = await this.Client(maxRetryCount)
    return client
      .post(path, {
        ...(body instanceof FormData
          ? { body }
          : { json: ToBackendKeys(body) }),
        signal,
      })
      .json()
      .then((r: any) => ToFrontendKeys(r.data))
      .catch(async (e) => {
        await handlePotentialRedirect(e)

        let err = {}
        try {
          err = {
            message: e instanceof Error ? e.message : e,
            is_online: navigator.onLine,
          }
          if (body instanceof FormData) {
            Array.from(body.keys()).forEach((key) => {
              const value = body.get(key)
              err = {
                ...err,
                ...getFileMetadata(value),
              }
            })
          }
        } finally {
          await captureError({
            e,
            telEvent,
            throwOnError,
            additionalErrorFields: err,
            logout: this.logout,
          })
        }
        return {} as unknown as T
      })
      .finally(() => {
        if (!options?.noAnalytics) {
          telEvent.Finish({ retry_count: getRetryCount() })
        }
      })
  }

  async Patch<T>(
    path: string,
    body: FormData | Record<string, any>,
    options?: ClientOptions
  ): Promise<T | RequestError> {
    const {
      signal,
      maxRetryCount = MAX_RETRY_COUNT,
      throwOnError,
    } = options ?? {}
    const telEvent = Services.HoneyComb.Start({
      metric: 'frontend.request.patch',
      host: this.restURL,
      path,
      method: 'PATCH',
      endpoint_name: path,
    })

    const { client, getRetryCount } = await this.Client(maxRetryCount)
    return client
      .patch(path, {
        json: ToBackendKeys(body),
        signal,
      })
      .json()
      .then((r: any) => ToFrontendKeys(r.data))
      .catch(async (e) => {
        await handlePotentialRedirect(e)
        await captureError({ e, telEvent, throwOnError, logout: this.logout })
        return {} as unknown as T
      })
      .finally(() => {
        telEvent.Finish({ retry_count: getRetryCount() })
      })
  }

  async Put<T>(
    path: string,
    body: FormData | Record<string, any>,
    options?: ClientOptions
  ): Promise<T | RequestError> {
    const {
      signal,
      maxRetryCount = MAX_RETRY_COUNT,
      throwOnError,
    } = options ?? {}
    const telEvent = Services.HoneyComb.Start({
      metric: 'frontend.request.put',
      host: this.restURL,
      path,
      method: 'PUT',
      endpoint_name: path,
    })

    const { client, getRetryCount } = await this.Client(maxRetryCount)
    return client
      .put(path, {
        json: ToBackendKeys(body),
        signal,
      })
      .json()
      .then((r: any) => ToFrontendKeys(r.data))
      .catch(async (e) => {
        await handlePotentialRedirect(e)
        await captureError({ e, telEvent, throwOnError, logout: this.logout })
        return {} as unknown as T
      })
      .finally(() => {
        telEvent.Finish({ retry_count: getRetryCount() })
      })
  }

  async Delete<T>(
    path: string,
    body: Record<string, any> = {},
    options?: ClientOptions
  ): Promise<T> {
    const { maxRetryCount = MAX_RETRY_COUNT, throwOnError } = options ?? {}
    const telEvent = Services.HoneyComb.Start({
      metric: 'frontend.request.delete',
      host: this.restURL,
      path,
      method: 'DELETE',
      endpoint_name: path,
    })

    const { client, getRetryCount } = await this.Client(maxRetryCount)

    return client
      .delete(path, {
        json: ToBackendKeys(body),
      })
      .json()
      .then((r: any) => ToFrontendKeys(r.data))
      .catch(async (e) => {
        await handlePotentialRedirect(e)
        await captureError({ e, telEvent, throwOnError, logout: this.logout })
        return {} as unknown as T
      })
      .finally(() => {
        telEvent.Finish({ retry_count: getRetryCount() })
      })
  }
}
