import React, { useEffect, useState } from 'react'

import { Card, Form, List, Space, Typography } from 'antd'
import emailAddresses from 'email-addresses'
import _ from 'lodash'
import pluralize from 'pluralize'

import {
  InternalAdminBulkDeleteUser,
  BulkDeleteUsersResult,
  CreateUsersResult,
  ExistingUserResponse,
  UserAddedSuccess,
  createNewUsersCustomerFacing,
  createNewUsersInternalAdmin,
} from 'models/users'
import { getOrgDomains, Workspace } from 'models/workspace'
import { Maybe } from 'types'

import { useNavigateWithQueryParams } from 'hooks/use-navigate-with-query-params'
import { displayErrorMessage, displaySuccessMessage } from 'utils/toast'

import BulkUpsertUserModal from 'components/settings/user-management/bulk-upsert-user-modal'
import BulkDeleteUserModal from 'components/settings/workspace/workspace-details/bulk-delete-user-modal'
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from 'components/ui/accordion'
import { Badge } from 'components/ui/badge'
import { Button as TailwindButton } from 'components/ui/button'
import CsvDownload from 'components/ui/csv-download'
import { Spinner } from 'components/ui/spinner'
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
} from 'components/ui/table'

import MultiEmailTextarea, { getCleanEmails } from './multi-email-textarea'
import { onUpsertUsers } from './user-management-utils'

interface MultiUserAddProps {
  type: 'add' | 'remove'
  displayDownloadButton: boolean
  workspace?: Workspace
  workspaces?: Workspace[]
  helpMessage?: React.ReactNode
  customerFacing?: boolean
  maxNumUsers?: number
  collapsible?: boolean
  accordionTitle?: string
  onAdd?: () => Promise<void>
  orgDomains?: string[]
}

const MultiUserAddRemove: React.FC<MultiUserAddProps> = ({
  workspace,
  workspaces,
  helpMessage,
  customerFacing = false,
  maxNumUsers = 100,
  type,
  collapsible = false,
  accordionTitle,
  displayDownloadButton,
  onAdd,
}) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [successManagedUsers, setSuccessManagedUsers] = useState<
    UserAddedSuccess[]
  >([])
  const [failedUsers, setFailedUsers] = useState<string[]>([])
  const [existingUsers, setExistingUsers] = useState<ExistingUserResponse[]>([])
  const [totalUsersRequested, setTotalUsersRequested] =
    useState<Maybe<number>>(null)
  const [textAreaValue, setTextAreaValue] = useState<string>('')
  const [userLimitExceeded, setUserLimitExceeded] = useState<boolean>(false)
  const [textAreaLines, setTextAreaLines] = useState<number>(0)
  const workspacesMap = _.keyBy(workspaces, 'id')

  const navigate = useNavigateWithQueryParams()

  const cleanResponseState = () => {
    setSuccessManagedUsers([])
    setFailedUsers([])
    setExistingUsers([])
    setTotalUsersRequested(null)
  }

  const getAddSuccessMessage = (response: CreateUsersResult) => {
    if (!customerFacing) {
      return response.message || `Users added successfully`
    }

    const totalAdded = response.usersAdded.length
    const totalAlreadyExist = response.usersAlreadyExist.length

    if (totalAlreadyExist > 0) {
      return `${totalAdded} new ${pluralize(
        'user',
        totalAdded
      )} added (${totalAlreadyExist} already existed)`
    } else if (totalAdded > 0) {
      return `${totalAdded} new ${pluralize('user', totalAdded)} added`
    } else {
      return
    }
  }

  const handleAddRemoveUsers = async (): Promise<void> => {
    setLoading(true)
    try {
      const values = await form.validateFields()
      cleanResponseState()
      // parse emails from textarea
      const emails = getCleanEmails(values.emails)

      if (emails.length > maxNumUsers) {
        displayErrorMessage(
          `Exceeded maximum number of users (${maxNumUsers}) allowed`
        )
        setLoading(false)
        return
      }
      const [validEmails, invalidEmails] = _.partition(emails, (email) => {
        const parsed = emailAddresses.parseOneAddress(email)
        return parsed && /^[^@]+@[^@]+\.[^@]+$/.test(email) // Regex to ensure domain part has a dot
      })

      setDeleteModalOpen(false)
      setAddModalOpen(false)
      if (!_.isEmpty(validEmails)) {
        if (type === 'remove') {
          const response: BulkDeleteUsersResult =
            await InternalAdminBulkDeleteUser(emails)
          if (
            _.isEmpty(response.usersDeleted) &&
            !_.isEmpty(response.usersFailed)
          ) {
            displayErrorMessage('Error removing users', 10)
          } else {
            displaySuccessMessage('Users removed successfully', 5)
          }
          setSuccessManagedUsers(
            (response.usersDeleted ?? []).map((email) => ({
              email,
            }))
          )
          invalidEmails.push(...(response.usersFailed || []))
        } else {
          const createNewUsersFxn = customerFacing
            ? createNewUsersCustomerFacing
            : createNewUsersInternalAdmin
          const response = await createNewUsersFxn(validEmails, workspace?.id)
          if (
            _.isEmpty(response.usersAdded) &&
            _.isEmpty(response.usersAlreadyExist) &&
            _.isEmpty(response.usersFailed)
          ) {
            displayErrorMessage(
              response.message ||
                `Error ${type === 'add' ? 'adding' : 'removing'} users`,
              10
            )
          } else {
            const message = getAddSuccessMessage(response)
            if (message) {
              displaySuccessMessage(message, 5)
            }
            // only show errors if customer facing
            if (customerFacing) {
              if (response.usersFailed.length > 0) {
                const totalFailed =
                  response.usersFailed.length + invalidEmails.length
                displayErrorMessage(
                  `${totalFailed} ${pluralize(
                    'user',
                    totalFailed
                  )} could not be added. Please try again.`,
                  10
                )
              } else {
                // reset if success && customer facing
                form.resetFields()
                setTextAreaValue('')
              }
            }
          }

          setTotalUsersRequested(response?.totalUsersRequested || null)
          setExistingUsers(response.usersAlreadyExist ?? [])
          setSuccessManagedUsers(response.usersAdded ?? [])
          invalidEmails.push(...(response.usersFailed || []))
        }
      }
      setFailedUsers(invalidEmails)
    } catch (errorInfo) {
      displayErrorMessage(
        `Error ${type === 'add' ? 'adding' : 'removing'} users`,
        10
      )
    }
    onAdd?.()
    setLoading(false)
  }

  const submitButtonDisabled =
    loading || _.isEmpty(textAreaValue) || userLimitExceeded

  const getTooltipText = () => {
    if (_.isEmpty(textAreaValue)) {
      return 'Please enter at least one email'
    } else if (loading) {
      return type === 'add' ? 'Adding users…' : 'Removing users…'
    } else if (userLimitExceeded) {
      return `Maximum ${maxNumUsers} users allowed`
    } else {
      return ''
    }
  }

  const userResponsePopulated =
    !_.isEmpty(successManagedUsers) ||
    !_.isEmpty(failedUsers) ||
    !_.isEmpty(existingUsers)

  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [addModalOpen, setAddModalOpen] = useState(false)
  const [mismatchDomainUsers, setMismatchDomainUsers] = useState<string[]>([])
  const [orgDomains, setOrgDomains] = useState<string[]>([])

  useEffect(() => {
    const fetchOrgDomains = async () => {
      let domains: string[] = []
      try {
        if (workspace) {
          domains = (await getOrgDomains(workspace.id)).domains ?? []
        }
        setOrgDomains(domains)
      } catch (e) {
        displayErrorMessage('Error fetching organization domains')
      }
    }
    fetchOrgDomains()
  }, [workspace])

  const onAddUsers = () => {
    onUpsertUsers(
      setLoading,
      setAddModalOpen,
      setMismatchDomainUsers,
      textAreaValue,
      orgDomains
    )
  }

  const [existingUsersInCurrentWorkspace, existingUsersInOtherWorkspaces] =
    _.partition(existingUsers, (user) => user.workspaceId === workspace?.id)

  const handleRowClick = (email: string) => {
    navigate(`/settings/internal_admin/user-inspector`, {
      state: { email },
    })
  }

  const AddRemoveForm = (
    <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
      <Typography>
        {_.isNil(helpMessage) ? (
          <Typography>
            Enter user emails to{' '}
            {type === 'add' ? 'add new users to' : 'remove users from'} Harvey
            and Auth0.
            {type === 'add' && (
              <>
                Download a csv of new users after submit.
                <br />
                <p>
                  User workspaces are inferred from domains, a workspace is
                  created if it doesnt exist for the domain
                </p>
              </>
            )}
          </Typography>
        ) : (
          helpMessage
        )}
      </Typography>
      <Form disabled={loading} form={form}>
        <Form.Item name="emails">
          <MultiEmailTextarea
            value={textAreaValue}
            onChange={(emails: string) => setTextAreaValue(emails)}
            maxNumUsers={maxNumUsers}
            emailsValidCallback={(valid: boolean) =>
              setUserLimitExceeded(!valid)
            }
            placeholder={`Enter new user emails to ${type}, one email per line`}
            setNumEmails={(numEmails: number) => setTextAreaLines(numEmails)}
          />
        </Form.Item>
        <Form.Item className="flex flex-row justify-end">
          {loading ? (
            <Spinner className="h-6 w-6" />
          ) : (
            <div>
              {userResponsePopulated && (
                <TailwindButton
                  className="mr-2"
                  variant="secondary"
                  onClick={() => {
                    cleanResponseState()
                    setTextAreaValue('')
                    setTextAreaLines(0)
                    form.resetFields()
                  }}
                >
                  Clear
                </TailwindButton>
              )}
              <TailwindButton
                onClick={() => {
                  if (type === 'remove') setDeleteModalOpen(true)
                  else onAddUsers()
                }}
                disabled={submitButtonDisabled}
                tooltip={getTooltipText()}
                tooltipSide="left"
                variant={type === 'add' ? 'default' : 'destructive'}
              >
                {_.startCase(type)} {textAreaLines > 0 ? textAreaLines : ''}{' '}
                {textAreaLines > 0 ? pluralize('user', textAreaLines) : 'users'}{' '}
                {customerFacing || _.isNil(workspace)
                  ? ''
                  : `to ${_.startCase(workspace.clientName ?? workspace.slug)}`}
              </TailwindButton>
            </div>
          )}
        </Form.Item>
      </Form>

      <BulkDeleteUserModal
        open={deleteModalOpen}
        selectedUsers={getCleanEmails(textAreaValue)}
        onOpenChange={(open) => {
          setDeleteModalOpen(open)
        }}
        onDelete={handleAddRemoveUsers}
      />
      <BulkUpsertUserModal
        open={addModalOpen}
        onOpenChange={(open) => {
          setAddModalOpen(open)
          setLoading(false)
        }}
        onUpsert={handleAddRemoveUsers}
        mismatchDomainUsers={mismatchDomainUsers}
        selectedUsers={getCleanEmails(textAreaValue)}
        workspace={workspace}
        customerFacing={customerFacing}
        type="add"
        orgDomains={orgDomains}
      />
      {!customerFacing && userResponsePopulated && (
        <Card
          type="inner"
          title="Results"
          className="mb-4"
          extra={
            !_.isEmpty(successManagedUsers) &&
            type === 'add' &&
            displayDownloadButton && (
              <div className="flex h-full items-center">
                <CsvDownload
                  data={successManagedUsers}
                  buttonText="Download added users"
                  filename="new_users.csv"
                />
              </div>
            )
          }
        >
          <Typography>
            <List>
              {!_.isNil(totalUsersRequested) && (
                <List.Item>
                  Total requested users count: {totalUsersRequested}
                </List.Item>
              )}
              <List.Item>
                Successfully {type === 'add' ? 'added' : 'removed'} users count:{' '}
                {successManagedUsers.length}
              </List.Item>

              {failedUsers.length > 0 && (
                <List.Item>
                  Failed users ({failedUsers.length}):
                  {_.sortBy(failedUsers).map((user, index) => (
                    <Badge key={index} variant="ghost">
                      {user}
                    </Badge>
                  ))}
                </List.Item>
              )}

              {existingUsersInCurrentWorkspace.length > 0 && (
                <List.Item>
                  Existing users {!customerFacing && <>in current workspace</>}{' '}
                  ({existingUsersInCurrentWorkspace.length}):
                  {_.sortBy(existingUsersInCurrentWorkspace).map(
                    (user, index) => (
                      <Badge key={index} variant="ghost">
                        {user.email}
                      </Badge>
                    )
                  )}
                </List.Item>
              )}

              {existingUsersInOtherWorkspaces.length > 0 && !customerFacing && (
                <List.Item>
                  <div className="w-full">
                    <div className="flex justify-between">
                      <div className="flex-grow">
                        Existing users in other workspaces (
                        {existingUsersInOtherWorkspaces.length}): These users
                        are active in another workspace and will not be moved{' '}
                        {!_.isNil(workspace)
                          ? `to ${workspace?.clientName ?? workspace?.slug}`
                          : 'from their current workspace'}
                        . To move these users, copy them using the button to the
                        right and move them individually via user inspector.
                      </div>
                      <TailwindButton
                        onClick={() => {
                          navigator.clipboard.writeText(
                            existingUsersInOtherWorkspaces
                              .map((user) => user.email)
                              .join('\n')
                          )
                          displaySuccessMessage('Copied users to clipboard', 5)
                        }}
                        className="ml-4 w-32"
                      >
                        Copy users
                      </TailwindButton>
                    </div>
                    <div className="max-h-96 overflow-y-scroll">
                      <Table className="mt-3 rounded-lg border">
                        <TableHeader className="rounded border font-semibold">
                          <TableCell>Email</TableCell>
                          <TableCell>Current workspace ID</TableCell>
                          <TableCell>Current workspace slug</TableCell>
                        </TableHeader>
                        <TableBody>
                          {_.sortBy(existingUsersInOtherWorkspaces).map(
                            (user, index) => (
                              <TableRow key={index}>
                                <TableCell>{user.email}</TableCell>
                                <TableCell
                                  onClick={() => handleRowClick(user.email)}
                                  className="cursor-pointer"
                                >
                                  {workspacesMap[user.workspaceId]?.id}
                                </TableCell>
                                <TableCell
                                  onClick={() => handleRowClick(user.email)}
                                  className="cursor-pointer"
                                >
                                  {workspacesMap[user.workspaceId]?.slug}
                                </TableCell>
                                <TableCell>
                                  <TailwindButton
                                    onClick={() => handleRowClick(user.email)}
                                    variant="outline"
                                    className="float-right"
                                  >
                                    View user
                                  </TailwindButton>
                                </TableCell>
                              </TableRow>
                            )
                          )}
                        </TableBody>
                      </Table>
                    </div>
                  </div>
                </List.Item>
              )}
            </List>
          </Typography>
        </Card>
      )}
      {customerFacing && failedUsers.length > 0 && (
        <div>
          <p>
            {successManagedUsers.length}{' '}
            {successManagedUsers.length > 1 ? 'users were' : 'user was'}{' '}
            successfully added
            {existingUsers.length > 0 &&
              `(${existingUsers.length} already existed).`}
            <br />
            {failedUsers.length} {pluralize('user', failedUsers.length)} failed;
            please try adding these users again. If this issue persists, reach
            out to support@harvey.ai.
          </p>
          <Table className="mt-3 rounded-lg border">
            <TableHeader className="rounded border font-semibold">
              <TableCell>Email</TableCell>
              <TableCell>Status</TableCell>
            </TableHeader>
            <TableBody>
              {failedUsers.map((user, index) => (
                <TableRow key={index}>
                  <TableCell>{user}</TableCell>
                  <TableCell>Failed</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      )}
    </Space>
  )

  if (collapsible) {
    return (
      <Accordion type="single" collapsible className="rounded-md border">
        <AccordionItem value={`user-${type}`}>
          <AccordionTrigger className="p-4">
            <div className="flex justify-between">
              <h2 className="text-lg font-semibold">{accordionTitle}</h2>
            </div>
          </AccordionTrigger>
          <AccordionContent className="p-5 pb-0">
            {AddRemoveForm}
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    )
  } else {
    return AddRemoveForm
  }
}

export default MultiUserAddRemove
