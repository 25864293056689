import React from 'react'

import { useShallow } from 'zustand/react/shallow'

import { WorkflowType } from 'types/workflows'

import { AppHeader } from 'components/common/app-header'
import { AppHeaderActions } from 'components/common/app-header-actions'
import ExportDialog from 'components/common/export/export-dialog'
import { getWorkflowSubtitle } from 'components/workflows/constants'
import { useCompetitiveAnalysisStore } from 'components/workflows/workflow/competitive-analysis/stores/competitive-analysis-store'

const CompetativeAnalysisHeader = () => {
  const companyName = useCompetitiveAnalysisStore(
    useShallow((state) => state.companyName)
  )
  const excelUrl = useCompetitiveAnalysisStore(
    useShallow((state) => state.excelUrl)
  )
  const setExcelUrl = useCompetitiveAnalysisStore(
    useShallow((state) => state.setExcelUrl)
  )
  const setRawData = useCompetitiveAnalysisStore(
    useShallow((state) => state.setRawData)
  )

  const handleReset = () => {
    setExcelUrl('')
    setRawData(null)
  }

  const downloadHandler = () => {
    const a = document.createElement('a')
    a.href = excelUrl
    a.download = `${companyName}-competitive-analysis.xlsx`
    a.click()
    a.remove()
  }

  return (
    <AppHeader
      title="Competitive Analysis"
      subtitle={getWorkflowSubtitle(WorkflowType.COMPETITIVE_ANALYSIS)}
      actions={
        <div className="inline-flex space-x-2">
          <AppHeaderActions
            handleReset={handleReset}
            resetDisabled={!excelUrl}
            showReset={!!excelUrl}
          />
          {!!excelUrl && (
            <ExportDialog onExport={downloadHandler} disabled={false} />
          )}
        </div>
      }
    />
  )
}

export default CompetativeAnalysisHeader
