import React from 'react'

import { ListEndIcon, ListPlusIcon } from 'lucide-react'

import { EventKind } from 'openapi/models/EventKind'

import { cn } from 'utils/utils'

import { useAuthUser } from 'components/common/auth-context'
import { Button } from 'components/ui/button'

import LoadPromptButton from './load-prompt-button'
import { SavePromptButton } from './save-prompt-button'

interface PromptButtonGroupProps {
  query: string
  setQuery: (query: string) => void
  setQueryPreview?: (query: string) => void
  queryLimit?: number
  enabledTooltipText?: string
  loadDisabled?: boolean
  saveDisabled?: boolean
  eventKind: EventKind
  showMoreOptions?: boolean
}

const PromptButtonGroup: React.FC<PromptButtonGroupProps> = ({
  query,
  setQuery,
  setQueryPreview,
  queryLimit,
  enabledTooltipText,
  saveDisabled,
  loadDisabled,
  eventKind,
}) => {
  const userInfo = useAuthUser()
  return (
    <div className="flex items-center">
      <LoadPromptButton
        setQuery={setQuery}
        setQueryPreview={setQueryPreview}
        triggerComponent={(disabled, buttonTooltipText) => (
          <Button
            id="load-prompt"
            size="sm"
            className="align-items-center mr-1 flex"
            variant="ghost"
            disabled={disabled}
            tooltip={buttonTooltipText}
            tooltipClassName={cn({
              'cursor-default': disabled,
            })}
          >
            <ListEndIcon size={16} className="mr-1" />
            Load prompt
          </Button>
        )}
        eventKind={eventKind}
        disabled={loadDisabled}
      />
      {userInfo.IsLibraryPrivatePromptUser && (
        <SavePromptButton
          query={query}
          queryLimit={queryLimit}
          enabledTooltipText={enabledTooltipText}
          triggerComponent={(disabled, tooltipText, onMouseEnter) => (
            <Button
              id="save-prompt"
              size="sm"
              variant="ghost"
              onMouseEnter={onMouseEnter}
              tooltip={tooltipText}
              tooltipClassName={cn({
                'cursor-default': disabled,
              })}
            >
              <ListPlusIcon size={16} className="mr-1" />
              Save prompt
            </Button>
          )}
          disabled={saveDisabled}
          eventKind={eventKind}
        />
      )}
    </div>
  )
}

export default PromptButtonGroup
