import React from 'react'

import { cn } from 'utils/utils'

interface FolderShieldIconProps {
  className?: string
}

const FolderShieldIcon: React.FC<FolderShieldIconProps> = ({
  className = '',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      className={cn('h-auto w-auto', className)}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M10 20H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h3.9a2 2 0 0 1 1.69.9l.81 1.2a2 2 0 0 0 1.67.9H20a2 2 0 0 1 2 2v2.5"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M21.333 18.417c0 2.083-1.458 3.125-3.191 3.729-.091.03-.19.03-.28-.004-1.737-.6-3.195-1.642-3.195-3.725V15.5a.417.417 0 0 1 .416-.417c.834 0 1.875-.5 2.6-1.133a.488.488 0 0 1 .634 0c.729.638 1.766 1.133 2.6 1.133a.416.416 0 0 1 .416.417v2.917Z"
      />
    </svg>
  )
}

export default FolderShieldIcon
