import { useEffect, useMemo } from 'react'

import { useInfiniteQuery } from '@tanstack/react-query'
import { isEmpty } from 'lodash'

import { findOldestDateString } from 'utils/date-utils'

import { defaultRetry } from './use-wrapped-query'

export interface PaginationOptions {
  pageNumber?: number
  pageSize?: number
  createdBefore?: string | null
}

export interface PaginatedResponse<T> {
  items: T[]
  total: number
}

interface BatchPaginationParams<T> {
  queryKey: unknown[]
  queryFn: (params: PaginationOptions) => Promise<{ items: T[]; total: number }>
  createdKey?: string
  initialBatchSize?: number
  batchSize?: number
  isEnabled?: boolean
}

/**
 * useBatchPaginatedQuery fetches an initial set of items and then automatically fetches
 * the remaining items in batches until all data is loaded.
 */
export const useBatchPaginatedQuery = <T extends object>({
  queryKey,
  queryFn,
  createdKey = 'createdAt',
  initialBatchSize = 25,
  batchSize = 10,
  isEnabled = true,
}: BatchPaginationParams<T>) => {
  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
    refetch,
  } = useInfiniteQuery({
    queryKey: queryKey,
    queryFn: ({ pageParam = {} }: { pageParam: PaginationOptions }) =>
      queryFn(pageParam),
    initialPageParam: { pageNumber: 1, pageSize: initialBatchSize },
    getNextPageParam: (
      lastPage: PaginatedResponse<T>,
      _: PaginatedResponse<T>[],
      lastPageParam: PaginationOptions
    ): PaginationOptions | undefined => {
      if (
        isEmpty(lastPage) ||
        lastPage.total === 0 ||
        (lastPageParam.pageSize && lastPage.total <= lastPageParam.pageSize)
      )
        return undefined
      const oldestDate = findOldestDateString(lastPage.items, createdKey)
      return oldestDate
        ? { createdBefore: oldestDate, pageSize: batchSize }
        : undefined
    },
    retry: defaultRetry, // TODO: Create useWrappedInfiniteQuery
    enabled: isEnabled,
  })

  useEffect(() => {
    if (hasNextPage && !isFetchingNextPage) {
      void fetchNextPage()
    }
  }, [hasNextPage, isFetchingNextPage, fetchNextPage])

  const allItems = useMemo(
    () => data?.pages.flatMap((page) => page.items) || [],
    [data?.pages]
  )

  return {
    items: allItems,
    isFetchingNextPage,
    isLoading,
    refetch,
  }
}
