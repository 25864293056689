import React, { useState } from 'react'

import {
  ColumnDef,
  Row,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
  getPaginationRowModel,
} from '@tanstack/react-table'
import _ from 'lodash'

import { TranscriptsDocument } from 'openapi/models/TranscriptsDocument'

import { DataTable } from 'components/ui/data-table/data-table'
import { IsLoadingCell } from 'components/workflows/workflow/contracts/file-explorer/cells'
import PaginationButtons from 'components/workflows/workflow/transcripts/common/pagination-buttons'

type Props = {
  documents: TranscriptsDocument[]
  onRowClick: (row: Row<TranscriptsDocument>) => void
  className?: string
  pageSize?: number
}

export const TranscriptsFilesTable: React.FC<Props> = ({
  documents,
  onRowClick,
  className,
  pageSize = 5,
}) => {
  const columns: ColumnDef<TranscriptsDocument>[] = [
    {
      header: 'File name',
      accessorKey: 'file.name',
    },
    {
      header: 'Witnesses',
      cell: ({ row }) =>
        row.original.isLoading ? (
          <IsLoadingCell />
        ) : (
          row.original.metadata?.witnesses
            .map((w) => _.startCase(_.toLower(w.name)))
            .join(', ')
        ),
    },
    {
      header: 'Type',
      accessorKey: 'metadata.transcriptType',
      cell: ({ row }) =>
        row.original.isLoading ? (
          <IsLoadingCell />
        ) : (
          _.startCase(row.original.metadata?.transcriptType)
        ),
    },
  ]
  const [tablePaginationState, setTablePaginationState] = useState({
    pageIndex: 0,
    pageSize,
  })

  const data = React.useMemo(() => {
    return documents
  }, [documents])

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    manualPagination: false,
    state: {
      pagination: tablePaginationState,
    },
  })

  const handlePageChange = (newPageIndex: number) => {
    setTablePaginationState((prev) => ({
      ...prev,
      pageIndex: newPageIndex,
    }))
  }

  return (
    <div>
      <p className="mb-2 pl-1 text-xl">Transcripts</p>
      <DataTable className={className} table={table} onRowClick={onRowClick} />
      <PaginationButtons
        pageIndex={tablePaginationState.pageIndex}
        onPageChange={handlePageChange}
        canNextPage={table.getCanNextPage()}
      />
    </div>
  )
}
