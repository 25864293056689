/* tslint:disable */
/* eslint-disable */
/**
 * Harvey Web App - User Permissions
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const RestrictedPermission = {
    CREATEVAULT: 'create:vault',
    CREATEVAULT_REVIEW: 'create:vault_review'
} as const;
export type RestrictedPermission = typeof RestrictedPermission[keyof typeof RestrictedPermission];


export function instanceOfRestrictedPermission(value: any): boolean {
    for (const key in RestrictedPermission) {
        if (Object.prototype.hasOwnProperty.call(RestrictedPermission, key)) {
            if (RestrictedPermission[key as keyof typeof RestrictedPermission] === value) {
                return true;
            }
        }
    }
    return false;
}

export function RestrictedPermissionFromJSON(json: any): RestrictedPermission {
    return RestrictedPermissionFromJSONTyped(json, false);
}

export function RestrictedPermissionFromJSONTyped(json: any, ignoreDiscriminator: boolean): RestrictedPermission {
    return json as RestrictedPermission;
}

export function RestrictedPermissionToJSON(value?: RestrictedPermission | null): any {
    return value as any;
}

