import React, { useEffect, useRef, useState } from 'react'

import { PlusCircle } from 'lucide-react'

import { cn } from 'utils/utils'

import { Button } from 'components/ui/button'
import { Card } from 'components/ui/card'
import { Input } from 'components/ui/input'
import { ScrollArea } from 'components/ui/scroll-area'
import Tag from 'components/ui/tag'

/**
 * @deprecated
 * This component is being deprecated and will be replaced by a new TagInput component.
 */

interface DeprecatedTagInputProps {
  tags: string[]
  setTags: (tags: string[]) => void
  addButtonText?: string
  closeableTags?: boolean // can existing tags be removed
  emptyState?: React.ReactNode
  inputPlaceholder?: string
  className?: string
  minInputLength?: number
  maxInputLength?: number
  footerElement?: React.ReactNode
  disabled?: boolean
  allowDuplicates?: boolean
  allowReset?: boolean
  commandEnterRef?: React.MutableRefObject<HTMLInputElement | null>
}

const DeprecatedTagInput = ({
  tags, // should include all tags, including non-removable tags
  setTags,
  addButtonText,
  closeableTags = true,
  emptyState,
  inputPlaceholder,
  className,
  minInputLength,
  maxInputLength,
  footerElement,
  disabled = false,
  allowDuplicates = false,
  allowReset = true,
  commandEnterRef,
}: DeprecatedTagInputProps) => {
  const [input, setInput] = useState('')
  const [showReset, setShowReset] = useState(false)
  const [initialTags] = useState<string[]>(tags) // should only be set once upon component mount

  useEffect(() => {
    if (!allowReset) {
      if (showReset) setShowReset(false)
      return
    }
    if (tags.length === 0) {
      setShowReset(false)
    } else {
      setShowReset(true)
    }
  }, [tags, allowReset, showReset])

  const handleAdd = () => {
    const trimmedInput = input.trim()
    if (trimmedInput.length < 1) return
    setTags([...tags, trimmedInput])
    setInput('')
  }

  const removeTag = (index: number) => {
    const newTags = tags.filter((_, i) => i !== index)
    setTags(newTags)
  }

  const removeAllTags = () => {
    if (closeableTags) {
      setTags([])
    } else {
      setTags(initialTags)
    }
  }

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && !isSubmitDisabled()) {
      handleAdd()
    }
  }

  const isSubmitDisabled = () => {
    const trimmedInput = input.trim()
    const cleanTags = tags.map((tag) => tag.trim().toLowerCase())
    const isDuplicate =
      cleanTags.includes(trimmedInput.toLowerCase()) && !allowDuplicates
    return (
      !trimmedInput ||
      disabled ||
      trimmedInput.length < (minInputLength || 1) ||
      trimmedInput.length > (maxInputLength || 100) ||
      isDuplicate
    )
  }

  const inputRef = useRef<HTMLInputElement | null>(null)

  const mergedRefs = (ref: HTMLInputElement) => {
    inputRef.current = ref
    if (commandEnterRef) commandEnterRef.current = ref
  }

  const handleEmptyStateKeyDown = (
    event: React.KeyboardEvent<HTMLDivElement>
  ) => {
    if (event.key === 'Enter') {
      inputRef.current?.focus()
    }
  }

  return (
    <Card className={cn('flex flex-col', className)}>
      <div className="relative flex items-center">
        <Input
          id="tag-input"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyDown={handleKeyDown}
          placeholder={inputPlaceholder || 'Add a tag'}
          min={minInputLength}
          max={maxInputLength}
          data-testid="tag-text-input"
          className="border-0"
          ref={mergedRefs}
          disabled={disabled}
        />
        <div className=" absolute right-2 flex space-x-1">
          {showReset && (
            <Button
              size="sm"
              variant="ghost"
              onClick={removeAllTags}
              data-testid="tag-clear-button"
              disabled={disabled}
            >
              Reset
            </Button>
          )}
          <Button
            size="sm"
            variant="secondary"
            onClick={handleAdd}
            disabled={isSubmitDisabled()}
            data-testid="tag-add-button"
          >
            <PlusCircle className="mr-1 size-3" />
            {addButtonText || 'Add'}
          </Button>
        </div>
      </div>

      <div className="flex grow flex-col justify-between border-t p-2 pr-1">
        {tags.length === 0 ? (
          // eslint-disable-next-line jsx-a11y/interactive-supports-focus
          <div
            className="flex grow"
            onClick={() => inputRef.current && inputRef.current.focus()}
            onKeyDown={handleEmptyStateKeyDown}
            role="button"
          >
            {emptyState}
          </div>
        ) : (
          <ScrollArea className="pr-3">
            <div className="flex max-h-64 flex-wrap gap-1">
              {tags.map((tag, index) => (
                <Tag
                  key={index}
                  text={tag}
                  onDismiss={
                    !closeableTags && initialTags.includes(tag) // matches by value / name
                      ? undefined
                      : () => removeTag(index)
                  }
                  disabled={disabled}
                />
              ))}
            </div>
          </ScrollArea>
        )}
        <div className="mt-4 w-full shrink-0 pr-1">{footerElement}</div>
      </div>
    </Card>
  )
}

export default DeprecatedTagInput
