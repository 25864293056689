import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'

import { ClientMatterWithStats } from 'models/client-matters'
import { FetchWorkspaceHistoryMetadata } from 'models/fetchers/history-fetcher'
import { Workspace } from 'models/workspace'
import { Maybe } from 'types'
import { HistoryItemFolder } from 'types/history'

import { addQueryParamsToRequestPath } from 'utils/routing'
import { SafeRecord } from 'utils/safe-types'
import { TaskType } from 'utils/task'

import { CLIENT_MATTER_URL_PARAM } from 'components/client-matters/client-matter-utils'
import { ClientMatterRecord } from 'components/filter/types/client-matter-record'

interface WorkspaceHistoryMetadataState {
  historyTaskTypes: SafeRecord<string, TaskType[]>
  historyItemFolders: SafeRecord<string, HistoryItemFolder[]>
  historyClientMatters: SafeRecord<string, ClientMatterWithStats[]>
  clientMatterIdToName: SafeRecord<string, SafeRecord<string, string>>
}

interface WorkspaceHistoryMetadataAction {
  getClientMatterName: (
    workspaceSlug: string,
    record: ClientMatterRecord
  ) => Maybe<string>
  getRouteWithClientMatter: (
    workspaceSlug: string,
    record: ClientMatterRecord,
    path: string
  ) => string
  /**
   * Fetches history metadata from the backend and updates the store.
   * This includes event task types, item folders, and client matters.
   */
  fetchData: (workspace: Workspace) => Promise<void>
}

export const useWorkspaceHistoryMetadataStore = create<
  WorkspaceHistoryMetadataState & WorkspaceHistoryMetadataAction
>()(
  devtools(
    immer((set, get) => ({
      historyTaskTypes: {},
      historyItemFolders: {},
      historyClientMatters: {},
      clientMatterIdToName: {},
      getClientMatterName: (
        workspaceSlug: string,
        record: ClientMatterRecord
      ) => {
        const { clientMatterIdToName } = get()
        return record.clientMatterId
          ? clientMatterIdToName[workspaceSlug]?.[record.clientMatterId]
          : undefined
      },
      getRouteWithClientMatter: (
        workspaceSlug: string,
        record: ClientMatterRecord,
        path: string
      ) => {
        const clientMatterId = record.clientMatterId

        const clientMatter = get().historyClientMatters[workspaceSlug]?.find(
          (cm) => cm.id === clientMatterId
        )
        if (!clientMatter || clientMatter.deletedAt) {
          // We don't want to append the client matter to the path if it doesn't
          // exist in the history client matters list or the client matter was deleted.
          return path
        }

        const searchParams = new URLSearchParams({
          [CLIENT_MATTER_URL_PARAM]: clientMatter.name,
        })
        return addQueryParamsToRequestPath(path, searchParams)
      },
      fetchData: async (workspace: Workspace) => {
        const historyMetadata = (await FetchWorkspaceHistoryMetadata(
          workspace.id
        )) || {
          eventTaskTypes: [],
          eventFolders: [],
          eventClientMatters: [],
        }
        set((state) => {
          state.historyTaskTypes[workspace.slug] =
            historyMetadata.eventTaskTypes
          state.historyClientMatters[workspace.slug] =
            historyMetadata.eventClientMatters

          const newClientMatterIdToName: SafeRecord<string, string> = {}
          historyMetadata.eventClientMatters.forEach((clientMatter) => {
            if (clientMatter.id) {
              newClientMatterIdToName[clientMatter.id] = clientMatter.name
            }
          })
          state.clientMatterIdToName[workspace.slug] = newClientMatterIdToName
        })
      },
    }))
  )
)
