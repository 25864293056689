import React from 'react'

import _ from 'lodash'

import { ResearchArea } from 'openapi/models/ResearchArea'

import { Source } from 'utils/task'
import { isAzureBlobPdf } from 'utils/utils'

import { SourceCard } from 'components/common/source-card/source-card'
import { SourceCardWithSnippets } from 'components/common/source-card/source-card-with-snippets'
import { useResearchStore } from 'components/research/research-store'
import BasicTransition from 'components/ui/basic-transition'
import { Card, CardContent, CardHeader, CardTitle } from 'components/ui/card'

import { onDocumentSourceClick } from './research-sheet'
import { addFootnoteToUrl } from './util'

const References = ({
  show,
  children,
}: {
  show: boolean
  children: React.ReactNode
}) => {
  return (
    <BasicTransition show={show}>
      <Card className="shadow-none" data-testid="source-card">
        <CardHeader className="mb-4 space-y-2">
          <CardTitle>References</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="space-y-4">{children}</div>
        </CardContent>
      </Card>
    </BasicTransition>
  )
}

export const ResearchSnippet = ({
  area,
  sources,
}: {
  area: ResearchArea
  sources: Source[]
}) => {
  const setActiveDocument = useResearchStore((s) => s.setActiveDocument)
  const sourceName = sources[0].documentName || ''
  const sourcesToRender =
    area !== ResearchArea.USACASELAW && area !== ResearchArea.USCASELAW
      ? sources
      : sources.map((source) => ({
          ...source,
          documentUrl: addFootnoteToUrl(source),
        }))
  // is a pdf and has page number
  if (sources[0]?.documentUrl && isAzureBlobPdf(sources[0].documentUrl)) {
    return (
      <SourceCardWithSnippets
        area={area}
        badges={sources[0].badges?.map((badge) => ({
          text: badge.text,
          variant: 'secondary',
        }))}
        title={sourceName}
        snippets={sources.map((source) => ({
          text: source.text || 'Click to view',
          citationNumber: source.footnote,
          onClick: () => onDocumentSourceClick(source, setActiveDocument),
        }))}
        metadata={sources[0].metadata}
      />
    )
  }

  return (
    <SourceCardWithSnippets
      area={area}
      badges={sources[0].badges?.map((badge) => ({
        text: badge.text,
        variant: 'secondary',
      }))}
      title={sourceName}
      snippets={sourcesToRender.map((source) => ({
        text: source.text || 'Click to view',
        citationNumber: source.footnote,
        href: source.documentUrl,
      }))}
      metadata={sources[0].metadata}
    />
  )
}

export const Sources = ({ area }: { area: ResearchArea }) => {
  const sources = useResearchStore((s) => s.sources)
  const sourcesToRender = sources || []

  const showSources = !_.isEmpty(sourcesToRender)
  // newer entries should have footnote but otherwise we should backfill based on order
  const sourceGroups = _.groupBy(
    sourcesToRender.map((s, idx) => ({
      ...s,
      footnote: s.footnote ?? idx + 1,
    })),
    'documentName'
  )

  if (area === ResearchArea.EDGAR) {
    return (
      <References show={showSources}>
        {sourcesToRender.map((source, idx) => (
          <SourceCard
            key={idx}
            title={`${source.documentName}`}
            text={source.text || 'Click to view'}
            badges={source.badges?.map((badge) => ({
              text: badge.text,
              variant: 'secondary',
            }))}
            href={source.documentUrl}
            number={String(idx + 1)}
            metadata={source.metadata}
            useLongSnippets
          />
        ))}
      </References>
    )
  }

  return (
    <References show={showSources}>
      {Object.values(sourceGroups).map((value, idx) => (
        <ResearchSnippet sources={value} area={area} key={idx} />
      ))}
    </References>
  )
}
