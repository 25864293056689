import { GridApi } from 'ag-grid-community'

import { parseIsoString } from 'utils/utils'

import { ColumnDataType, ReviewAnswer } from 'components/vault/utils/vault'
import { FilterType } from 'components/vault/utils/vault-data-grid-filters-store'

import { ColumnFilter } from './column-filter-types'
import { TextColumnFilter } from './text-column-filter'

export class DateColumnFilter extends ColumnFilter {
  getUniqueColumnValues(
    gridApi: GridApi,
    currentFilterQuestionId: string
  ): string[] {
    const uniqueDatesSet = new Set<number>()
    let hasDataTypeMismatch = false

    gridApi.forEachNode((node) => {
      // when grouping by folder, we want to ignore the rows that are the group rows
      // because they do not have any unique value
      if (node.group) return
      const answer = (node.data.answers as ReviewAnswer[]).find(
        (answer) => answer.columnId === currentFilterQuestionId
      )

      if (answer != null) {
        if (answer.columnDataType === ColumnDataType.date) {
          const utcDate = parseIsoString(answer.text)
          if (isNaN(utcDate.getTime()) || utcDate.getTime() === 0) {
            // If the date is invalid, skip it
            return
          }
          uniqueDatesSet.add(utcDate.getTime())
        } else {
          hasDataTypeMismatch = true
        }
      }
    })
    // if any answers are non-date column type, use text column filter instead
    if (hasDataTypeMismatch) {
      return new TextColumnFilter().getUniqueColumnValues(
        gridApi,
        currentFilterQuestionId
      )
    }

    return Array.from(uniqueDatesSet)
      .sort((a, b) => a - b)
      .map((date) =>
        new Intl.DateTimeFormat(navigator.language, {
          year: 'numeric',
          month: 'long',
          day: '2-digit',
          timeZone: 'UTC',
        }).format(new Date(date))
      )
  }

  getFilterType(): FilterType {
    return FilterType.DATE
  }
}
