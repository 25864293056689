import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'

import { UploadedFile } from 'openapi/models/UploadedFile'
import { AnnotationById } from 'types/task'

import { HarveySocketSetter, HarveySocketTask } from 'utils/use-harvey-socket'
import { HarveySocketCompletionStatus } from 'utils/use-harvey-socket-utils'

import { FeedbackResult } from 'components/common/feedback/feedback-with-comments'

interface State {
  headerText: string
  response: string
  annotations: AnnotationById
  isLoading: boolean
  queryId: string
  documentA: UploadedFile | null
  documentB: UploadedFile | null
  progress: number
  feedback: FeedbackResult | null
  completionStatus: HarveySocketCompletionStatus | null
}

interface Action {
  setTask: HarveySocketSetter
  setDocumentA: (document: UploadedFile | null) => void
  setDocumentB: (document: UploadedFile | null) => void
  setFeedback: (feedback: FeedbackResult | null) => void
  reset: () => void
}

const initialState: State = {
  response: '',
  annotations: {},
  isLoading: false,
  queryId: '',
  documentA: null,
  documentB: null,
  headerText: '',
  progress: 0,
  feedback: null,
  completionStatus: null,
}

export const useDocCompareStore = create(
  devtools(
    immer<State & Action>((set) => ({
      ...initialState,
      setTask: (socketState: Partial<HarveySocketTask>) => {
        set(() => ({
          ...socketState,
        }))
      },
      setDocumentA: (document: UploadedFile | null) => {
        set(() => ({
          documentA: document,
        }))
      },
      setDocumentB: (document: UploadedFile | null) => {
        set(() => ({
          documentB: document,
        }))
      },
      setFeedback: (feedback: FeedbackResult | null) => {
        set(() => ({
          feedback,
        }))
      },
      reset: () => {
        set(() => ({
          ...initialState,
        }))
      },
    }))
  )
)
