import React from 'react'

import { Maybe } from 'types'

import { Button } from 'components/ui/button'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from 'components/ui/dialog'

interface IntegrationDisableConfirmationDialogProps {
  title: string
  onConfirm: (enable: boolean, resourceUrl?: Maybe<string>) => void
  open: boolean
  onOpenChange: (open: boolean) => void
}

const IntegrationDisableConfirmationDialog: React.FC<
  IntegrationDisableConfirmationDialogProps
> = ({ open, onOpenChange, title, onConfirm }) => {
  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Disable {title}</DialogTitle>
        </DialogHeader>
        <p>
          This action will delete connected integration tokens for everyone in
          your workspace. If you re-enable the integration later, users will
          need to go through authentication again.
        </p>
        <DialogFooter>
          <DialogClose asChild>
            <Button onClick={() => onOpenChange(false)} variant="outline">
              Cancel
            </Button>
          </DialogClose>
          <DialogClose asChild>
            <Button onClick={() => onConfirm(false)} variant="destructive">
              Disable
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default IntegrationDisableConfirmationDialog
