import _ from 'lodash'
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'

import {
  FetchWorkspaces,
  getAllStatusMessages,
  RawStatusMessage,
  Workspace,
} from 'models/workspace'

import { displayErrorMessage } from 'utils/toast'

import { StatusVariant } from 'components/common/status-bar'

export interface ExistingStatusMessagesByName
  extends Omit<RawStatusMessage, 'workspaceId'> {
  workspaces: number[]
}

interface State {
  name: string
  header: string
  description: string
  ctaText: string
  ctaUrl: string
  workspacesLoading: boolean
  variant: StatusVariant
  workspaces: Workspace[]
  workspacesById: Record<number, Workspace>
  excludedWorkspaces: Set<number>
  includedWorkspaces: Set<number>
  applyToAllWorkspaces: boolean
  existingStatusMessages: RawStatusMessage[]
  existingStatusMessagesByName: ExistingStatusMessagesByName[]
}

interface Action {
  setName: (name: string) => void
  setHeader: (header: string) => void
  setDescription: (description: string) => void
  setCtaText: (ctaText: string) => void
  setCtaUrl: (ctaUrl: string) => void
  setWorkspacesLoading: (loading: boolean) => void
  setVariant: (variant: StatusVariant) => void
  setWorkspaces: (workspaces: Workspace[]) => void
  setExcludedWorkspaces: (excludedWorkspaces: Set<number>) => void
  setIncludedWorkspaces: (includedWorkspaces: Set<number>) => void
  setApplyToAllWorkspaces: (applyToAllWorkspaces: boolean) => void
  fetchAllStatusMessages: () => Promise<void>
  fetchWorkspaces: () => Promise<void>
}

// store for settings/incident-management tab
export const useIncidentManagementStore = create(
  devtools(
    immer<State & Action>((set) => ({
      name: '',
      header: '',
      description: '',
      ctaText: '',
      ctaUrl: '',
      workspacesLoading: false,
      variant: StatusVariant.destructive,
      workspaces: [],
      excludedWorkspaces: new Set(),
      includedWorkspaces: new Set(),
      applyToAllWorkspaces: true,
      existingStatusMessages: [],
      existingStatusMessagesByName: [],
      workspacesById: {},

      setName: (name) =>
        set((state) => {
          state.name = name
        }),
      setHeader: (header) =>
        set((state) => {
          state.header = header
        }),
      setDescription: (description) =>
        set((state) => {
          state.description = description
        }),
      setCtaText: (ctaText) =>
        set((state) => {
          state.ctaText = ctaText
        }),
      setCtaUrl: (ctaUrl) =>
        set((state) => {
          state.ctaUrl = ctaUrl
        }),
      setWorkspacesLoading: (loading) =>
        set((state) => {
          state.workspacesLoading = loading
        }),
      setVariant: (variant) =>
        set((state) => {
          state.variant = variant
        }),
      setWorkspaces: (workspaces) =>
        set((state) => {
          state.workspaces = workspaces
        }),
      setExcludedWorkspaces: (excludedWorkspaces) =>
        set((state) => {
          state.excludedWorkspaces = excludedWorkspaces
        }),
      setIncludedWorkspaces: (includedWorkspaces) =>
        set((state) => {
          state.includedWorkspaces = includedWorkspaces
        }),
      setApplyToAllWorkspaces: (applyToAllWorkspaces) =>
        set((state) => {
          state.applyToAllWorkspaces = applyToAllWorkspaces
        }),
      fetchAllStatusMessages: async () => {
        try {
          const resp = await getAllStatusMessages()
          set((state) => {
            state.existingStatusMessages = resp

            const messagesByName = resp.reduce(
              (acc, message) => {
                if (!acc[message.name]) {
                  const { workspaceId, ...messageWithoutWorkspaceId } = message
                  acc[message.name] = {
                    ...messageWithoutWorkspaceId,
                    workspaces: [workspaceId],
                  }
                } else {
                  if (
                    !acc[message.name].workspaces.includes(message.workspaceId)
                  ) {
                    acc[message.name].workspaces.push(message.workspaceId)
                  }
                }
                return acc
              },
              {} as Record<string, ExistingStatusMessagesByName>
            )

            state.existingStatusMessagesByName = Object.values(messagesByName)
          })
        } catch (error) {
          displayErrorMessage('Error fetching status messages')
          console.error('error fetching status messages', error)
        }
      },
      fetchWorkspaces: async () => {
        set((state) => {
          state.workspacesLoading = true
        })
        const data = await FetchWorkspaces()
        const filteredData = data.filter(
          (workspace) =>
            !workspace.deletedAt &&
            !(workspace.settings.onlyCustomStatusMessage ?? false)
        )
        const sortedData = _.orderBy(
          filteredData,
          [(workspace) => workspace.id],
          ['asc']
        )

        set((state) => {
          state.workspaces = sortedData
          // get mapping of all workspaces by ID
          state.workspacesById = data.reduce(
            (acc, workspace) => {
              acc[workspace.id] = workspace
              return acc
            },
            {} as Record<number, Workspace>
          )
          state.workspacesLoading = false
        })
      },
    }))
  )
)
