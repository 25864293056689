import React from 'react'

const VaultVerbatimCellRender: React.FC<{ text: string }> = ({ text }) => {
  return (
    <div className="border-l-medium virtualized-scrollbar whitespace-pre-line border-l-primary pl-2 text-xs">
      {text}
    </div>
  )
}

export default VaultVerbatimCellRender
