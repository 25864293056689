import React, { ReactNode } from 'react'

import { X } from 'lucide-react'

import { Alert, AlertDescription, AlertTitle } from './alert'
import { Button } from './button'
import { TextLink } from './text-link'
import { CTA } from './types'

interface BannerProps {
  title: string
  description: ReactNode
  leadingIcon?: React.ReactElement
  variant?: 'default' | 'destructive'
  className?: string
  onClose?: () => void
  cta?: CTA | undefined
}

const Banner = ({
  title,
  description,
  leadingIcon,
  variant,
  className,
  onClose,
  cta,
}: BannerProps) => {
  return (
    <Alert className={className} variant={variant}>
      {leadingIcon &&
        React.cloneElement(leadingIcon, {
          size: 12,
          className: 'mr-1 h-4 w-4',
        })}
      <div className="flex flex-row items-center justify-between">
        {onClose && (
          <X
            className="absolute right-0 top-0 h-4 w-4 cursor-pointer"
            onClick={onClose}
          />
        )}
        <AlertTitle>{title}</AlertTitle>
      </div>
      <AlertDescription className="mt-1">{description}</AlertDescription>
      {cta && (
        <div className="mt-2 flex justify-end">
          {cta.onClick ? (
            <Button variant={cta.variant} onClick={cta.onClick}>
              {cta.label}
            </Button>
          ) : (
            <TextLink href={cta.href ?? ''} label={cta.label} />
          )}
        </div>
      )}
    </Alert>
  )
}

export default Banner
