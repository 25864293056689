import React, { useMemo } from 'react'

import _ from 'lodash'
import { Group } from 'lucide-react'

import Services from 'services'

import { cn } from 'utils/utils'

import { useAnalytics } from 'components/common/analytics/analytics-context'
import { useAuthUser } from 'components/common/auth-context'
import { useGroupingStore } from 'components/grouping/grouping-store'
import {
  HistoryGrouping,
  HistoryGroupingDefsAvailable,
} from 'components/grouping/history-grouping-def'
import Combobox from 'components/ui/combobox/combobox'

const HistoryGroupingSelect: React.FC = () => {
  const userInfo = useAuthUser()
  const { trackEvent } = useAnalytics()
  const availableGroupings = useMemo(
    () => HistoryGroupingDefsAvailable(userInfo),
    [userInfo]
  )
  const grouping = useGroupingStore((s) => s.grouping)
  const setGrouping = useGroupingStore((s) => s.setGrouping)

  const value = grouping ?? ''
  const noGrouping = _.isNil(grouping)
  return (
    <Combobox
      value={value}
      options={availableGroupings}
      setValue={(value) => {
        setGrouping(_.isEmpty(value) ? undefined : (value as HistoryGrouping))
        Services.HoneyComb.Record({
          metric: 'ui.history_grouping_select',
          grouping: value,
        })
        trackEvent('History Grouping Selected', {
          grouping: value,
        })
      }}
      inputPlaceholder="Group by"
      defaultText="Group by"
      className={cn('w-auto', { 'text-muted': noGrouping })}
      prefix={
        <Group
          className={cn('size-4', {
            'text-muted': noGrouping,
          })}
        />
      }
      align="end"
    />
  )
}

export default HistoryGroupingSelect
