import { ReviewCellResponse } from 'components/vault/utils/vault'

import { CellComparator, CompareConfig } from './cell-comparator-types'

export class NumericCellComparator extends CellComparator {
  compareCells({ answerA, answerB }: CompareConfig) {
    // We index at 0 because we know the response is always a single numeric value
    const responseA = answerA.rawResponse?.[0] as ReviewCellResponse
    const responseB = answerB.rawResponse?.[0] as ReviewCellResponse

    return compareNumbers(responseA.value || '', responseB.value || '')
  }
}

export const compareNumbers = (
  numberAString: string,
  numberBString: string
) => {
  const numberA = parseFloat(numberAString)
  const numberB = parseFloat(numberBString)
  return numberA > numberB ? 1 : -1
}
