import { NavigateFunction } from 'react-router-dom'

import { driver, DriveStep } from 'driver.js'

import { waitForElement } from 'utils/utils'

export const runKnowledgeSourceTour = async (navigate: NavigateFunction) => {
  const startRoute = window.location.pathname

  navigate('/assistant')
  await waitForElement('#knowledge')

  let firstSourceType = ''
  const steps: DriveStep[] = [
    {
      element: '#knowledge',
      popover: {
        title: 'Knowledge (formerly Research modules)',
        description:
          'Click on a module to start researching in that area. Data availability and response quality will be the same as they were previously in the Research tab.',
        onNextClick: async () => {
          // Prefer EDGAR source since we'll be using that as an example later.
          // Otherwise, click the first source available.
          const firstSource =
            document.querySelector('#edgar') ??
            document.querySelector('#knowledge-sources button')
          if (firstSource) {
            firstSourceType = firstSource.id
            ;(firstSource as HTMLElement).click()
            await waitForElement('.research-explorer')
          }
          driverObj.moveNext()
        },
      },
    },
    {
      element: '.research-explorer',
      popover: {
        title: 'Filters',
        description:
          'Select one or more filters to narrow down the sources that will be used to respond to your query. These filters are the same as what was previously available in the Research modules.',
        onPrevClick: async () => {
          const cancelButton = document.querySelector(
            '#knowledge-source-cancel'
          )
          if (cancelButton) {
            ;(cancelButton as HTMLElement).click()
            await waitForElement('#knowledge')
          }
          driverObj.movePrevious()
        },
        onNextClick: async () => {
          navigate('/assistant/assist/example/edgar')
          await waitForElement(`#assistant-sources`)
          driverObj.moveNext()
        },
      },
    },
    {
      element: `#assistant-sources`,
      popover: {
        title: 'Sources',
        description:
          'Citations will be provided for all answers, and an aggregate list of cited sources will be available in the Sources panel.',
        onPrevClick: async () => {
          navigate('/assistant')
          const firstSource = await waitForElement(
            `#knowledge #${firstSourceType}`
          )
          if (firstSource) {
            firstSourceType = firstSource.id
            ;(firstSource as HTMLElement).click()
            await waitForElement('.research-explorer')
          }
          driverObj.movePrevious()
        },
        onNextClick: async () => {
          window.scrollTo(0, document.body.scrollHeight)
          driverObj.moveNext()
        },
      },
    },
    {
      element: `#assistant-followups`,
      popover: {
        title: 'Follow-ups',
        description:
          'Follow-ups are now available for queries that use Knowledge sources. Similar to Assistant, click on a suggested follow-up or submit your own for further Research.',
        onPrevClick: async () => {
          driverObj.movePrevious()
        },
        onNextClick: async () => {
          closeKnowledgeSourceTour()
        },
      },
    },
  ]

  const closeKnowledgeSourceTour = () => {
    driverObj.destroy()
    navigate(startRoute)
  }

  const driverObj = driver({
    showProgress: true,
    steps,
    disableActiveInteraction: true,
    onCloseClick: closeKnowledgeSourceTour,
    onDestroyStarted: async () => {
      if (!driverObj.hasNextStep()) {
        closeKnowledgeSourceTour()
      }
    },
    popoverClass: 'assistant-tour',
  })

  driverObj.drive()
}
