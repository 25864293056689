import _ from 'lodash'
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'

import { UserInfo } from 'models/user-info'
import { Maybe } from 'types'

export interface ClientMatter {
  id: string
  name: string
  description: Maybe<string>
  userId: string
  workspaceId: number
  userEmail: Maybe<string>
  createdAt: string
  allowed: boolean
}

interface ClientMatterState {
  clientMatters: ClientMatter[]
  selectedClientMatter: Maybe<ClientMatter>
  clientMatterSelectDisabled: boolean
  shouldCmLockQueries: boolean
  canUsersManageCm: boolean
}

interface ClientMatterAction {
  setClientMatters: (clientMatterIds: ClientMatter[]) => void
  setSelectedClientMatter: (clientMatterId: Maybe<ClientMatter>) => void
  setClientMatterSelectDisabled: (disabled: boolean) => void
  setShouldCmLockQueries: (shouldCmLockQueries: boolean) => void
  canCmUserQuery: () => boolean
  setCanUsersManageCm: (shouldUsersManageCm: boolean) => void
  canCmUserManage: (userInfo: UserInfo) => boolean
}

export const useClientMattersStore = create(
  devtools(
    immer<ClientMatterState & ClientMatterAction>((set, get) => ({
      clientMatters: [],
      selectedClientMatter: null,
      clientMatterSelectDisabled: false,
      shouldCmLockQueries: false,
      canUsersManageCm: true,
      setClientMatters: (clientMatters) =>
        set((state) => {
          state.clientMatters = clientMatters
        }),
      setSelectedClientMatter: (clientMatter) =>
        set((state) => {
          state.selectedClientMatter = clientMatter
        }),
      setClientMatterSelectDisabled: (disabled) =>
        set((state) => {
          state.clientMatterSelectDisabled = disabled
        }),
      setShouldCmLockQueries: (shouldCmLockQueries) =>
        set((state) => {
          state.shouldCmLockQueries = shouldCmLockQueries
        }),
      setCanUsersManageCm: (shouldUsersManageCm) =>
        set((state) => {
          state.canUsersManageCm = shouldUsersManageCm
        }),
      canCmUserQuery: () => {
        const state = get()
        return !(
          state.shouldCmLockQueries && _.isNil(state.selectedClientMatter)
        )
      },
      canCmUserManage: (userInfo: UserInfo) => {
        const state = get()
        if (_.isNil(userInfo) || !userInfo.isClientMattersReadUser) return false
        return state.canUsersManageCm || userInfo.isClientMattersManagementUser
      },
    }))
  )
)
