/* tslint:disable */
/* eslint-disable */
/**
 * PwC Deals API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration of diligence tasks, each generates some user-facing output that gets added to the report.
 * @export
 */
export const DiligenceTaskIdentifier = {
    BUSINESS_MODEL_AND_STRATEGY: 'business_model_and_strategy',
    ESG: 'esg',
    MANAGEMENT_GOVERNANCE: 'management_governance',
    OPERATION_AND_RISK: 'operation_and_risk',
    OPERATING_MODEL: 'operating_model',
    OPERATIONAL_EFFICIENCY: 'operational_efficiency',
    OPERATIONAL_RESILIENCE: 'operational_resilience',
    BUSINESS_PLAN: 'business_plan',
    TECHNOLOGY: 'technology',
    MARKET_TREND_ANALYSIS: 'market_trend_analysis',
    COMPETITOR_ASSESSMENT: 'competitor_assessment',
    CUSTOMER_BASE: 'customer_base',
    SHARE_PRICE_ANALYSIS: 'share_price_analysis',
    GENERAL_FINANCIAL_KPIS: 'general_financial_kpis',
    DEBT_PROFILE_FINANCING: 'debt_profile_financing',
    EBITDA_CONSIDERATIONS: 'ebitda_considerations',
    NET_DEBT: 'net_debt',
    NWC: 'nwc',
    CASH_FLOW_AND_CAPEX: 'cash_flow_and_capex',
    KEY_RISK_AREAS: 'key_risk_areas',
    KEY_DEAL_HYPOTHESES: 'key_deal_hypotheses',
    EXECUTIVE_SUMMARY_OF_ALL_TAX_RISKS: 'executive_summary_of_all_tax_risks',
    TAX_RED_FLAGS: 'tax_red_flags',
    SUMMARY_OF_TAX_FUNCTION_AND_TAX_PROFILE: 'summary_of_tax_function_and_tax_profile',
    CORPORATION_TAX_RISKS: 'corporation_tax_risks',
    TRANSFER_PRICING_TP_RISKS: 'transfer_pricing_tp_risks',
    VAT_SALES_AND_USE_TAX_RISKS: 'vat_sales_and_use_tax_risks',
    PAYROLL_TAX_RISKS: 'payroll_tax_risks',
    EMPLOYMENT_RELATED_SECURITIES_TAX_RISKS: 'employment_related_securities_tax_risks',
    REPORT_SUMMARY: 'report_summary'
} as const;
export type DiligenceTaskIdentifier = typeof DiligenceTaskIdentifier[keyof typeof DiligenceTaskIdentifier];


export function instanceOfDiligenceTaskIdentifier(value: any): boolean {
    for (const key in DiligenceTaskIdentifier) {
        if (Object.prototype.hasOwnProperty.call(DiligenceTaskIdentifier, key)) {
            if (DiligenceTaskIdentifier[key as keyof typeof DiligenceTaskIdentifier] === value) {
                return true;
            }
        }
    }
    return false;
}

export function DiligenceTaskIdentifierFromJSON(json: any): DiligenceTaskIdentifier {
    return DiligenceTaskIdentifierFromJSONTyped(json, false);
}

export function DiligenceTaskIdentifierFromJSONTyped(json: any, ignoreDiscriminator: boolean): DiligenceTaskIdentifier {
    return json as DiligenceTaskIdentifier;
}

export function DiligenceTaskIdentifierToJSON(value?: DiligenceTaskIdentifier | null): any {
    return value as any;
}

export function DiligenceTaskIdentifierToJSONTyped(value: any, ignoreDiscriminator: boolean): DiligenceTaskIdentifier {
    return value as DiligenceTaskIdentifier;
}

