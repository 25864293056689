import React, { useMemo } from 'react'

import { debounce, isEmpty } from 'lodash'
import { FolderPlus, Upload } from 'lucide-react'
import { useShallow } from 'zustand/react/shallow'

import { useAnalytics } from 'components/common/analytics/analytics-context'
import { Button } from 'components/ui/button'
import { Icon } from 'components/ui/icon/icon'
import SearchInput from 'components/ui/search-input'
import useSharingPermissions from 'components/vault/hooks/use-sharing-permissions'
import { useVaultFileExplorerStore } from 'components/vault/utils/vault-file-explorer-store'
import { useVaultStore } from 'components/vault/utils/vault-store'

const UploadButton = () => {
  const { trackEvent } = useAnalytics()

  const areUploadButtonsDisabled = useVaultStore(
    useShallow((state) => state.areUploadButtonsDisabled)
  )
  const exampleProjectIds = useVaultStore(
    useShallow((s) => s.exampleProjectIds)
  )
  const currentProject = useVaultStore(useShallow((s) => s.currentProject))
  const isExampleProject =
    useMemo(
      () => currentProject && exampleProjectIds.has(currentProject.id),
      [currentProject, exampleProjectIds]
    ) ?? false
  const currentFolderId = useVaultStore(
    useShallow((state) => state.currentFolderId)
  )
  const setCurrentUploadFilesFolderId = useVaultStore(
    (state) => state.setCurrentUploadFilesFolderId
  )
  const setIsUploadFilesDialogOpen = useVaultStore(
    (state) => state.setIsUploadFilesDialogOpen
  )

  const { doesCurrentUserHaveEditPermission } = useSharingPermissions({
    projectId: currentProject?.id,
  })

  return (
    <Button
      disabled={
        areUploadButtonsDisabled ||
        isExampleProject ||
        !doesCurrentUserHaveEditPermission
      }
      variant="outline"
      onClick={() => {
        trackEvent('Vault Upload Files Button Clicked')
        setCurrentUploadFilesFolderId(currentFolderId)
        setIsUploadFilesDialogOpen(true)
      }}
      tooltip={
        isExampleProject
          ? 'Cannot upload files to an example project'
          : undefined
      }
      tooltipAlign="end"
    >
      <Icon icon={Upload} className="mr-1" />
      <p className="truncate">Upload files</p>
    </Button>
  )
}

const CreateFolderButton = () => {
  const { trackEvent } = useAnalytics()

  const areUploadButtonsDisabled = useVaultStore(
    useShallow((state) => state.areUploadButtonsDisabled)
  )
  const exampleProjectIds = useVaultStore(
    useShallow((s) => s.exampleProjectIds)
  )
  const currentProject = useVaultStore(useShallow((s) => s.currentProject))
  const isExampleProject =
    useMemo(
      () => currentProject && exampleProjectIds.has(currentProject.id),
      [currentProject, exampleProjectIds]
    ) ?? false
  const currentFolderId = useVaultStore(
    useShallow((state) => state.currentFolderId)
  )
  const setCurrentCreateFolderFolderId = useVaultStore(
    (state) => state.setCurrentCreateFolderFolderId
  )
  const setIsCreateFolderDialogOpen = useVaultStore(
    (state) => state.setIsCreateFolderDialogOpen
  )

  const { doesCurrentUserHaveEditPermission } = useSharingPermissions({
    projectId: currentProject?.id,
  })

  return (
    <Button
      disabled={
        areUploadButtonsDisabled ||
        isExampleProject ||
        !doesCurrentUserHaveEditPermission
      }
      variant="outline"
      onClick={() => {
        trackEvent('Vault Create Folder Button Clicked')
        setCurrentCreateFolderFolderId(currentFolderId)
        setIsCreateFolderDialogOpen(true)
      }}
      tooltip={
        isExampleProject
          ? 'Cannot create folders in an example project'
          : undefined
      }
      tooltipAlign="end"
    >
      <Icon icon={FolderPlus} className="mr-1" />
      <p className="truncate">Create folder</p>
    </Button>
  )
}

const SearchBox = () => {
  const { trackEvent } = useAnalytics()

  const setAreUploadButtonsDisabled = useVaultStore(
    (state) => state.setAreUploadButtonsDisabled
  )

  const searchValue = useVaultFileExplorerStore(
    useShallow((state) => state.searchValue)
  )

  const clearSearchHandler = useVaultFileExplorerStore(
    (state) => state.clearSearchHandler
  )
  const setSearchValue = useVaultFileExplorerStore(
    (state) => state.setSearchValue
  )
  const setIsSearching = useVaultFileExplorerStore(
    (state) => state.setIsSearching
  )
  const setIsRequestingSemanticSearch = useVaultFileExplorerStore(
    (state) => state.setIsRequestingSemanticSearch
  )

  const handleClear = () => {
    clearSearchHandler()
    setAreUploadButtonsDisabled(false)
  }

  const debouncedSearchValueChange = debounce((value: string) => {
    trackEvent('Vault File Search Input Changed', {
      value: value,
    })
  }, 500)

  const handleSearch = (newSearchValue: string) => {
    if (isEmpty(newSearchValue)) {
      handleClear()
      return
    }
    debouncedSearchValueChange(newSearchValue)
    setAreUploadButtonsDisabled(true)
    setIsSearching(true)
    setIsRequestingSemanticSearch(false)
    setSearchValue(newSearchValue)
  }

  return (
    <SearchInput
      withIcon
      value={searchValue}
      setValue={handleSearch}
      handleClear={handleClear}
    />
  )
}

const VaultFileExplorerActions = () => {
  return (
    <div className="ml-4 flex items-center space-x-2">
      <SearchBox />
      <CreateFolderButton />
      <UploadButton />
    </div>
  )
}

export default VaultFileExplorerActions
