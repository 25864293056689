import { isEmpty } from 'lodash'

import { UploadedFile } from 'openapi/models/UploadedFile'

export const getIsLoadingUrl = (document: UploadedFile | null) => {
  if (!document) return false
  const { url, path, docAsPdfUrl, docAsPdfPath } = document
  return (
    (isEmpty(url) && !isEmpty(path)) ||
    (isEmpty(docAsPdfUrl) && !isEmpty(docAsPdfPath))
  )
}
