import React, { useMemo, useRef, useEffect } from 'react'

import { throttle } from 'lodash'
import _ from 'lodash'
import { useShallow } from 'zustand/react/shallow'

import { DiligenceSection } from 'openapi/models/DiligenceSection'

import { useNavigation, NavigationMessage } from 'hooks/use-navigation'

import { ScrollArea } from 'components/ui/scroll-area'
import { SCROLL_TO_BOTTOM_OF_DISCOVERY_REPORT } from 'components/workflows/workflow/discovery/common/scroll-to-report-bottom-event'
import { useDiligenceStore } from 'components/workflows/workflow/discovery/diligence-store'
import { generateSectionId } from 'components/workflows/workflow/discovery/util'

import { FollowUpAnswersSection } from './follow-up-answers-section'
import { OneSection } from './one-section'

type Props = {
  sections: DiligenceSection[]
}

export const ManySections: React.FC<Props> = ({ sections }) => {
  const [followUpQAPairs] = useDiligenceStore(
    useShallow((s) => [s.followUpQAPairs])
  )

  const FOLLOW_UP_ELEMENT_ID = 'Follow-ups'

  const navMessages = sections
    .map((section) => {
      const message: NavigationMessage = {
        id: section.title,
        title: section.title,
      }
      return message
    })
    .concat(
      _.isEmpty(followUpQAPairs)
        ? []
        : [{ id: FOLLOW_UP_ELEMENT_ID, title: 'Follow-ups' }]
    )

  const { navigationEl, messageRefs, scrollRef, unthrottledScroll } =
    useNavigation(navMessages)

  const onScroll = throttle(unthrottledScroll, 250)

  const taskRefs = useRef<Record<string, HTMLDivElement | null>>({})

  useEffect(() => {
    const scrollToBottom = () => {
      if (scrollRef.current) {
        scrollRef.current.scrollTop = scrollRef.current.scrollHeight
      }
    }

    const handleScrollToBottom = () => {
      // Delay the scroll operation by 500ms to accomodate the QAPair state update, so the user has something to see
      setTimeout(scrollToBottom, 500)
    }

    window.addEventListener(
      SCROLL_TO_BOTTOM_OF_DISCOVERY_REPORT,
      handleScrollToBottom
    )

    return () => {
      window.removeEventListener(
        SCROLL_TO_BOTTOM_OF_DISCOVERY_REPORT,
        handleScrollToBottom
      )
    }
  }, [scrollRef])

  // Prevent re-render of existing sections when unrelated state changes occur, i.e. when the page is scrolled.
  const memoizedOneSections = useMemo(() => {
    return sections.map((section, i) => (
      <OneSection
        id={generateSectionId(section)}
        key={i}
        ref={(el) => (messageRefs.current[i] = el)}
        selectedSection={section}
        taskRefs={taskRefs}
      />
    ))
  }, [messageRefs, sections])

  return (
    <>
      {navigationEl}
      <ScrollArea
        className="h-full grow"
        hasHorizontalScroll
        onScroll={onScroll}
        ref={scrollRef}
        id="scroll-area-report-sections"
      >
        {memoizedOneSections}

        {!_.isEmpty(followUpQAPairs) && (
          <FollowUpAnswersSection
            id={FOLLOW_UP_ELEMENT_ID}
            ref={(el) => {
              messageRefs.current[navMessages.length - 1] = el
            }}
          />
        )}
      </ScrollArea>
    </>
  )
}
