import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useLocation, useParams, useSearchParams } from 'react-router-dom'

import _ from 'lodash'
import { Check, CheckCircle } from 'lucide-react'

import { EvaluationQuestion } from 'openapi/models/EvaluationQuestion'
import { EvaluationQuestionResponseOptionRating } from 'openapi/models/EvaluationQuestionResponseOptionRating'
import { EvaluationQuestionResponsePayloadFreeForm } from 'openapi/models/EvaluationQuestionResponsePayloadFreeForm'
import { EvaluationQuestionResponsePayloadPreference } from 'openapi/models/EvaluationQuestionResponsePayloadPreference'
import { EvaluationQuestionResponsePayloadRating } from 'openapi/models/EvaluationQuestionResponsePayloadRating'
import { EvaluationQuestionResponseType } from 'openapi/models/EvaluationQuestionResponseType'
import { EvaluationQuestionType } from 'openapi/models/EvaluationQuestionType'
import { Experiment } from 'openapi/models/Experiment'
import { ExperimentConfig } from 'openapi/models/ExperimentConfig'
import { ExperimentMetadata } from 'openapi/models/ExperimentMetadata'
import { ModelResponse } from 'openapi/models/ModelResponse'
import { UserEvaluationEnriched } from 'openapi/models/UserEvaluationEnriched'

import { useNavigateWithQueryParams } from 'hooks/use-navigate-with-query-params'
import { displayErrorMessage, displaySuccessMessage } from 'utils/toast'
import { useScrollToTop } from 'utils/utils'

import { AppHeader } from 'components/common/app-header'
import { useAuthUser } from 'components/common/auth-context'
import Markdown from 'components/common/markdown/markdown'
import {
  FetchEvaluationQuestions,
  FetchExperimentBySlug,
  FetchModelResponses,
  FetchUserEvaluationResponses,
  FetchUserEvaluations,
  RecordUserEvaluationResponses,
} from 'components/settings/experiment/utils/experiment-fetcher'
import {
  EvaluationQuestionMeta,
  UserEvaluationResponseInput,
  EVAL_ID_SEARCH_PARAM_KEY,
  USER_PREFERENCE_RESPONSE_VALUE_TIE,
} from 'components/settings/experiment/utils/experiment-utils'
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from 'components/ui/accordion'
import BasicTransition from 'components/ui/basic-transition'
import { Button } from 'components/ui/button'
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from 'components/ui/card'
import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationNext,
  PaginationPageInput,
  PaginationPrevious,
} from 'components/ui/pagination/pagination'
import { RadioGroup, RadioGroupItem } from 'components/ui/radio-group'
import { ScrollArea } from 'components/ui/scroll-area'
import { Separator } from 'components/ui/separator'
import { Textarea } from 'components/ui/text-area'

import RatingButtons from './rating-buttons'

// each one should track all repsonses for a single question
interface UserEvaluationQuestionResponseTemplate {
  evaluationQuestion: EvaluationQuestion
  userEvaluationResponseInputs: UserEvaluationResponseInput[]
  isRequired: boolean
}

const UserEvaluation = () => {
  const userInfo = useAuthUser()
  const scrollToTop = useScrollToTop()

  // this section remains constant throughout entire set of user evaluations
  const experimentRef = useRef<Experiment>()
  const experimentConfigRef = useRef<ExperimentConfig>()
  const experimentEvaluationQuestionsRef = useRef<EvaluationQuestion[]>([])
  const [userEvaluationsEnriched, setUserEvaluationsEnriched] = useState<
    UserEvaluationEnriched[]
  >([])

  // this section updates for each user evaluation / page
  const [currentUserEvaluationIdx, setCurrentUserEvaluationIdx] =
    useState<number>(0)
  const [currentModelResponses, setCurrentModelResponses] = useState<
    ModelResponse[]
  >([])
  const preferenceOptions: string[] = [
    ...currentModelResponses.map((_, idx) => `Response ${idx + 1}`),
    'Tie',
  ]

  const [
    expectedUserEvaluationResponseTemplates,
    setExpectedUserEvaluationResponseTemplates,
  ] = useState<UserEvaluationQuestionResponseTemplate[]>([])

  const [isLoading, setIsLoading] = useState<boolean>(true)

  const { slug: experimentSlug = '' } = useParams<{ slug: string }>()
  const [searchParams] = useSearchParams()
  const evalIdParam = searchParams.get(EVAL_ID_SEARCH_PARAM_KEY)
  const navigateWithQueryParams = useNavigateWithQueryParams()
  const location = useLocation()

  const evalIdRef = useRef<number | null>(null)
  const [paginationInput, setPaginationInput] = useState(1)

  const topOfScrollAreaRef = useRef<HTMLDivElement>(null)

  // TODO do i need useCallback here? Yes - liknter error if nto
  const fetchExperimentData = useCallback(async () => {
    // TODO ensure this doesn't keep running
    if (experimentRef.current && experimentConfigRef.current) {
      return
    }
    const [experiment, evaluationQuestionBank] = await Promise.all([
      FetchExperimentBySlug(experimentSlug),
      FetchEvaluationQuestions(true), // TODO fetch by question ids once we have too many questions in question bank in the future
    ])
    experimentRef.current = experiment
    const experimentMetadata = experimentRef.current!.meta as ExperimentMetadata
    experimentConfigRef.current = experimentMetadata.config
    const evaluationQuestionsInner =
      experimentConfigRef.current!.evaluationQuestions.sort(
        (a, b) => a.orderRank - b.orderRank
      )
    // assert evaluationQuestions doesn't contain any undefined values, indicating all questions were found
    const evaluationQuestions = evaluationQuestionsInner.map(
      (inner) =>
        evaluationQuestionBank.find(
          (bankQuestion) => bankQuestion.id === inner.questionId
        )!
    )
    experimentEvaluationQuestionsRef.current = evaluationQuestions
  }, [experimentSlug])

  // comparison = user evalution with model responses
  // essentially 1) sets new user evaluation 2) pull relevant data to populate UI
  const fetchComparisonDetails = useCallback(
    async (
      userEvaluationsEnrichedParam: UserEvaluationEnriched[],
      currentUserEvaluationIdxParam: number
    ) => {
      setIsLoading(true)
      setCurrentUserEvaluationIdx(currentUserEvaluationIdxParam)

      const newEvalId = currentUserEvaluationIdxParam + 1 // params are 1-indexed
      setPaginationInput(newEvalId)
      evalIdRef.current = newEvalId
      const searchParams = new URLSearchParams()
      searchParams.set(EVAL_ID_SEARCH_PARAM_KEY, newEvalId.toString())
      const searchParamString = `?${searchParams.toString()}`
      navigateWithQueryParams(location.pathname + searchParamString)

      try {
        const currentUserEvaluation =
          userEvaluationsEnrichedParam[currentUserEvaluationIdxParam]

        const [modelResponses, userEvaluationResponses] = await Promise.all([
          FetchModelResponses(currentUserEvaluation.evaluation.id),
          FetchUserEvaluationResponses(currentUserEvaluation.userEvaluation.id),
        ])

        // randomization of model responses is done in backend since it's easier to control
        // rng seed in Python; order of responses should be preserved across network call
        setCurrentModelResponses(modelResponses)

        // for each evaluation question that needs to be asked, there will be an object that tracks all responses / metadata
        const templates: UserEvaluationQuestionResponseTemplate[] = []

        experimentEvaluationQuestionsRef.current.map((question) => {
          const isRequired =
            experimentConfigRef.current!.evaluationQuestions.find(
              (q) => q.questionId === question.id
            )!.isRequired
          if (
            question.questionType === EvaluationQuestionType.EVALUATION_LEVEL
          ) {
            const existingResponse = userEvaluationResponses.find(
              (response) => response.questionId === question.id
            )
            const input: UserEvaluationResponseInput = {
              id: existingResponse?.id ?? undefined,
              questionId: question.id,
              response: existingResponse?.response ?? {},
            }
            const template: UserEvaluationQuestionResponseTemplate = {
              evaluationQuestion: question,
              userEvaluationResponseInputs: [input],
              isRequired: isRequired,
            }
            templates.push(template)
          } else if (
            question.questionType === EvaluationQuestionType.RESPONSE_LEVEL
          ) {
            // create template response input for each model response
            const inputs: UserEvaluationResponseInput[] = []
            modelResponses.map((modelResponse) => {
              const existingResponse = userEvaluationResponses.find(
                (response) =>
                  response.questionId === question.id &&
                  response.modelResponseId === modelResponse.id
              )
              const input: UserEvaluationResponseInput = {
                id: existingResponse?.id ?? undefined,
                modelResponseId: modelResponse.id,
                questionId: question.id,
                response: existingResponse?.response ?? {},
              }
              inputs.push(input)
            })
            const template: UserEvaluationQuestionResponseTemplate = {
              evaluationQuestion: question,
              userEvaluationResponseInputs: inputs,
              isRequired: isRequired,
            }
            templates.push(template)
          } else {
            displayErrorMessage(
              `Invalid question type, ${question.questionType}`,
              5
            )
            throw new Error(`Invalid question type, ${question.questionType}`)
          }
        })

        setExpectedUserEvaluationResponseTemplates(templates)

        // handleNavigation and useEffect both end up calling this fxn
        scrollToTop(topOfScrollAreaRef)
      } finally {
        setTimeout(() => {
          setIsLoading(false)
        }, 200)
      }
    },
    [navigateWithQueryParams, location, scrollToTop]
  )

  const fetchUserEvaluationData = useCallback(async () => {
    // TODO should i fetch experiment data in layout wrapper instead?
    await fetchExperimentData() // ensure the experiment data is populated
    setIsLoading(true)
    // TODO add error handling
    const newUserEvaluationsEnriched = await FetchUserEvaluations(
      experimentRef.current!.id
    ) // TODO is this safe?
    newUserEvaluationsEnriched.length > 0 &&
      newUserEvaluationsEnriched.sort(
        (a: UserEvaluationEnriched, b: UserEvaluationEnriched) =>
          a.evaluation.orderRank - b.evaluation.orderRank
      )
    setUserEvaluationsEnriched(newUserEvaluationsEnriched)

    setIsLoading(false)
    return newUserEvaluationsEnriched
  }, [setUserEvaluationsEnriched, fetchExperimentData])

  const goToNextIncomplete = useCallback(
    (
      userEvaluationsEnrichedParam: UserEvaluationEnriched[],
      startIdx: number = 0
    ) => {
      setIsLoading(true)
      const nextIncompleteIndexUnshifted =
        userEvaluationsEnrichedParam.length > 0
          ? [
              ...userEvaluationsEnrichedParam.slice(startIdx),
              ...userEvaluationsEnrichedParam.slice(0, startIdx),
            ].findIndex((c) => !c.userEvaluation.isComplete)
          : 0
      const nextIncompleteIndex =
        nextIncompleteIndexUnshifted > -1
          ? (nextIncompleteIndexUnshifted + startIdx) %
            userEvaluationsEnrichedParam.length
          : -1
      if (
        nextIncompleteIndex > -1 &&
        (nextIncompleteIndex != currentUserEvaluationIdx ||
          // edge case when we first load first evaluation, need to enter this if statement
          expectedUserEvaluationResponseTemplates.length == 0)
      ) {
        fetchComparisonDetails(
          userEvaluationsEnrichedParam,
          nextIncompleteIndex
        )
      }
      setIsLoading(false)
    },
    [
      currentUserEvaluationIdx,
      fetchComparisonDetails,
      expectedUserEvaluationResponseTemplates,
    ]
  )

  const handleNavigation = useCallback(
    async (evalId: number | null = null) => {
      const newUserEvaluationsEnriched = await fetchUserEvaluationData()
      if (
        !_.isNil(evalId) &&
        evalId > 0 &&
        evalId <= newUserEvaluationsEnriched.length
      ) {
        fetchComparisonDetails(newUserEvaluationsEnriched, evalId - 1)
      } else {
        goToNextIncomplete(newUserEvaluationsEnriched)
      }
    },
    [fetchUserEvaluationData, fetchComparisonDetails, goToNextIncomplete]
  )

  useEffect(() => {
    let evalId: number | null = null
    if (evalIdParam) {
      const parsed = parseInt(evalIdParam)
      if (!isNaN(parsed)) {
        evalId = parsed
      }
    }
    if (!_.isNil(evalId) && evalId != evalIdRef.current) {
      void handleNavigation(evalId)
    } else if (_.isNil(evalId)) {
      void handleNavigation()
    }
  }, [handleNavigation, evalIdParam])

  const handleSubmitUserEvaluationResponses = async () => {
    setIsLoading(true)
    // TODO prevent submission withotu changes from last time and also check for empty strings - that gets past previous check
    const allUserEvaluationResponseInputs =
      expectedUserEvaluationResponseTemplates
        .map((template) => template.userEvaluationResponseInputs)
        .flat()
    const allNonEmptyUserEvaluationResponseInputs =
      allUserEvaluationResponseInputs.filter(
        (input) => !_.isEmpty(input.response)
      )
    if (allNonEmptyUserEvaluationResponseInputs.length == 0) {
      displayErrorMessage('Please answer at least one question', 5)
      setIsLoading(false)
      return
    }

    try {
      await RecordUserEvaluationResponses(
        userEvaluationsEnriched[currentUserEvaluationIdx].userEvaluation.id,
        allNonEmptyUserEvaluationResponseInputs
      )
      const newUserEvaluationsEnriched = await fetchUserEvaluationData() // can update without re-fetching if needed
      displaySuccessMessage('Responses recorded successfully', 5)
      goToNextIncomplete(newUserEvaluationsEnriched, currentUserEvaluationIdx)
    } catch (error) {
      displayErrorMessage(`Error submitting responses: ${error}`, 5)
    } finally {
      setIsLoading(false)
    }
  }

  // Increment and decrement functions
  const incrementComparisonIndex = () => {
    // TODO implement safeguards so that user doesn't lose progress accidentally
    if (currentUserEvaluationIdx < userEvaluationsEnriched.length - 1) {
      fetchComparisonDetails(
        userEvaluationsEnriched,
        currentUserEvaluationIdx + 1
      )
    }
  }

  const decrementComparisonIndex = () => {
    if (currentUserEvaluationIdx > 0) {
      fetchComparisonDetails(
        userEvaluationsEnriched,
        currentUserEvaluationIdx - 1
      )
    }
  }

  interface ResponseAndSources {
    response: string
    sources?: string
  }

  const splitResponseAndSources = (response: string): ResponseAndSources => {
    const regex = /#* Sources/is
    const parts = response.split(regex)
    const matchIdx = response.search(regex)
    if (parts.length == 2) {
      return {
        response: parts[0],
        sources: response.slice(matchIdx),
      }
    }

    return {
      response: response,
    }
  }

  const totalUserEvaluations = userEvaluationsEnriched.length
  const completedUserEvaluations = userEvaluationsEnriched.filter(
    (evaluation) => evaluation.userEvaluation.isComplete
  ).length
  // TODO do we want to enable users to go back and answer optional questions after completing all required ones?
  const isExperimentCompleteForUser =
    completedUserEvaluations === totalUserEvaluations

  if (!isLoading && totalUserEvaluations == 0) {
    return (
      <AppHeader
        title="Evaluations"
        subtitle="Evaluate & compare model responses"
        breadcrumbs={<div></div>}
        actions={<div>No evaluations available</div>}
      />
    )
  }

  if (_.isEmpty(userInfo) || !userInfo.IsResponseComparisonUser) return <></>

  // TODO, optimization, wrap user-evaluation component in experiment component /context
  // this way do we don't have to re-fetch experiment config or expected user response scaffolding
  return (
    <>
      <AppHeader
        title="Evaluations"
        subtitle="Evaluate & compare model responses"
        breadcrumbs={<div></div>}
        actions={
          <div className="flex justify-end">
            {/* Progress Bar */}
            <div className="mr-2 text-center">
              {`${completedUserEvaluations}/${totalUserEvaluations} Completed`}
              {completedUserEvaluations > 0 ? (
                <div
                  style={{
                    width: `${Math.round(
                      (completedUserEvaluations / totalUserEvaluations) * 100
                    )}%`,
                  }}
                  className="mt-1 h-0.5 rounded-t bg-interactive"
                />
              ) : (
                <div className="w-100 mt-1 h-0.5 rounded-t bg-skeleton" />
              )}
            </div>
          </div>
        }
      />
      <ScrollArea className="h-full" ref={topOfScrollAreaRef}>
        <div
          className="container mx-auto flex h-full flex-col space-y-4 py-4"
          data-testid="user-evaluation-container"
        >
          <BasicTransition show={!isLoading}>
            {isExperimentCompleteForUser ? (
              <CardTitle className="flex justify-start">
                All evaluations completed
                <Check size={24} className="ml-2 text-primary" />
              </CardTitle>
            ) : (
              <>
                <Card>
                  <CardHeader>
                    <CardTitle>Query</CardTitle>
                    <CardContent>
                      <Markdown
                        content={
                          userEvaluationsEnriched[currentUserEvaluationIdx]
                            .evaluation.query
                        }
                      />
                    </CardContent>
                  </CardHeader>
                  <div className="mx-2 my-2 flex justify-end">
                    <Pagination className="flex items-center justify-end space-x-2">
                      {userEvaluationsEnriched[currentUserEvaluationIdx]
                        .userEvaluation.isComplete && (
                        <CheckCircle size={18} className="mr-2 text-success" />
                      )}
                      <Button
                        className="mx-1"
                        variant="outline"
                        onClick={() =>
                          goToNextIncomplete(
                            userEvaluationsEnriched,
                            currentUserEvaluationIdx <
                              userEvaluationsEnriched.length - 1
                              ? currentUserEvaluationIdx + 1
                              : 0
                          )
                        }
                      >
                        Next incomplete
                      </Button>
                      <PaginationContent>
                        <PaginationItem>
                          <PaginationPrevious
                            onClick={decrementComparisonIndex}
                          />
                        </PaginationItem>
                        <PaginationItem>
                          <PaginationPageInput
                            page={paginationInput}
                            setPage={setPaginationInput}
                            onSubmit={(page) => handleNavigation(page)}
                          />{' '}
                          of {totalUserEvaluations}
                        </PaginationItem>
                        <PaginationItem>
                          <PaginationNext onClick={incrementComparisonIndex} />
                        </PaginationItem>
                      </PaginationContent>
                    </Pagination>
                  </div>
                </Card>

                {/* // TODO break up to new component */}
                <Card className="mt-4">
                  <CardHeader>
                    <CardTitle>Model Responses</CardTitle>
                  </CardHeader>
                  <ScrollArea hasHorizontalScroll className="w-full">
                    <div className="flex justify-between">
                      {currentModelResponses.map((modelResponse, idx) => {
                        const { response, sources } = splitResponseAndSources(
                          modelResponse.responseOutput
                        )
                        return (
                          <Card className="m-4 w-1/2 min-w-[500px]" key={idx}>
                            <CardHeader>
                              <CardTitle>Response {idx + 1}</CardTitle>
                            </CardHeader>
                            <CardContent>
                              <CardDescription>
                                <Markdown content={response} width="100%" />
                                {sources && (
                                  <>
                                    <Accordion
                                      type="single"
                                      collapsible
                                      className="mt-5 w-full"
                                    >
                                      <AccordionItem value="sources">
                                        <AccordionTrigger>
                                          Sources (click to expand){' '}
                                        </AccordionTrigger>
                                        <AccordionContent>
                                          <Markdown
                                            content={sources}
                                            width="100%"
                                          />
                                        </AccordionContent>
                                      </AccordionItem>
                                    </Accordion>
                                  </>
                                )}
                              </CardDescription>
                            </CardContent>
                          </Card>
                        )
                      })}
                    </div>
                  </ScrollArea>
                </Card>

                {expectedUserEvaluationResponseTemplates.map(
                  (template, idx) => {
                    return (
                      <Card className="mt-4" key={idx}>
                        <CardHeader>
                          <CardDescription>
                            <p>
                              Question #{idx + 1}{' '}
                              {template.isRequired ? '' : '(optional)'}
                            </p>
                          </CardDescription>
                          <Separator className="my-1" />
                          <CardTitle>
                            {template.evaluationQuestion.question}
                          </CardTitle>
                          <CardDescription>
                            {template.evaluationQuestion.questionDescription}
                          </CardDescription>
                        </CardHeader>
                        <CardContent>
                          <QuestionResponseInputFields
                            questionType={
                              template.evaluationQuestion.questionType
                            }
                            question={template.evaluationQuestion}
                            userEvaluationResponseInputs={
                              template.userEvaluationResponseInputs
                            }
                            expectedUserEvaluationResponseTemplatesIdx={idx}
                            expectedUserEvaluationResponseTemplates={
                              expectedUserEvaluationResponseTemplates
                            }
                            setExpectedUserEvaluationResponseTemplates={
                              setExpectedUserEvaluationResponseTemplates
                            }
                            preferenceOptions={preferenceOptions}
                            currentModelResponses={currentModelResponses}
                            isLoading={isLoading}
                          />
                        </CardContent>
                      </Card>
                    )
                  }
                )}
                <div className="m-4 flex justify-center">
                  <Button
                    variant="default"
                    onClick={handleSubmitUserEvaluationResponses}
                  >
                    Save Responses
                  </Button>
                </div>
              </>
            )}
          </BasicTransition>
        </div>
      </ScrollArea>
    </>
  )
}

interface QuestionResponseInputFieldsProps {
  questionType: EvaluationQuestionType
  question: EvaluationQuestion
  userEvaluationResponseInputs: UserEvaluationResponseInput[]
  expectedUserEvaluationResponseTemplatesIdx: number
  expectedUserEvaluationResponseTemplates: UserEvaluationQuestionResponseTemplate[]
  setExpectedUserEvaluationResponseTemplates: (
    expectedUserEvaluationResponseTemplates: UserEvaluationQuestionResponseTemplate[]
  ) => void
  preferenceOptions: string[]
  currentModelResponses: ModelResponse[]
  isLoading: boolean
}

// TODO should this just be a component inside the UserEvaluation one? would need to memo
const QuestionResponseInputFields = ({
  questionType,
  question,
  userEvaluationResponseInputs,
  expectedUserEvaluationResponseTemplatesIdx,
  expectedUserEvaluationResponseTemplates,
  setExpectedUserEvaluationResponseTemplates,
  preferenceOptions,
  currentModelResponses,
  isLoading,
}: QuestionResponseInputFieldsProps) => {
  if (questionType === EvaluationQuestionType.EVALUATION_LEVEL) {
    if (question.responseType === EvaluationQuestionResponseType.PREFERENCE) {
      return (
        <RadioGroup>
          {/* TODO this is kinda jank */}
          {preferenceOptions.map((option, index) => {
            // this preference value is what gets stored in DB, not `option`
            const preference =
              index < currentModelResponses.length
                ? currentModelResponses[index].id
                : USER_PREFERENCE_RESPONSE_VALUE_TIE
            let existingResponseValue
            try {
              existingResponseValue = (
                userEvaluationResponseInputs[0]
                  .response as EvaluationQuestionResponsePayloadPreference
              ).responseValue
            } catch (e) {
              existingResponseValue = null
            }
            return (
              <RadioGroupItem
                key={index}
                value={preference}
                label={option}
                checked={preference === existingResponseValue}
                onClick={() => {
                  const newResponsePayload: EvaluationQuestionResponsePayloadPreference =
                    {
                      responseType: EvaluationQuestionResponseType.PREFERENCE,
                      responseValue: preference,
                    }
                  const input: UserEvaluationResponseInput = {
                    id: userEvaluationResponseInputs[0].id,
                    questionId: question.id,
                    response: newResponsePayload,
                  }
                  const newExpectedUserEvaluationResponseTemplates = [
                    ...expectedUserEvaluationResponseTemplates,
                  ]
                  newExpectedUserEvaluationResponseTemplates[
                    expectedUserEvaluationResponseTemplatesIdx
                  ] = {
                    ...newExpectedUserEvaluationResponseTemplates[
                      expectedUserEvaluationResponseTemplatesIdx
                    ],
                    userEvaluationResponseInputs: [input],
                  }
                  setExpectedUserEvaluationResponseTemplates(
                    newExpectedUserEvaluationResponseTemplates
                  )
                }}
                disabled={isLoading}
              />
            )
          })}
        </RadioGroup>
      )
    } else if (
      question.responseType === EvaluationQuestionResponseType.FREE_FORM
    ) {
      let existingResponseValue
      try {
        existingResponseValue = (
          userEvaluationResponseInputs[0]
            .response as EvaluationQuestionResponsePayloadFreeForm
        ).responseValue
      } catch (e) {
        existingResponseValue = null
      }
      return (
        <Textarea
          placeholder="Enter your response here"
          // can't set value to undefined otherwise it won't update from previous non-nil value
          value={existingResponseValue ?? ''}
          onChange={(e) => {
            const newResponseValue = e.target.value
            const newResponsePayload: EvaluationQuestionResponsePayloadFreeForm =
              {
                responseType: EvaluationQuestionResponseType.FREE_FORM,
                responseValue: newResponseValue,
              }
            const input: UserEvaluationResponseInput = {
              id: userEvaluationResponseInputs[0].id,
              questionId: question.id,
              response: newResponsePayload,
            }
            const newExpectedUserEvaluationResponseTemplates = [
              ...expectedUserEvaluationResponseTemplates,
            ]
            newExpectedUserEvaluationResponseTemplates[
              expectedUserEvaluationResponseTemplatesIdx
            ] = {
              ...newExpectedUserEvaluationResponseTemplates[
                expectedUserEvaluationResponseTemplatesIdx
              ],
              userEvaluationResponseInputs: [input],
            }
            setExpectedUserEvaluationResponseTemplates(
              newExpectedUserEvaluationResponseTemplates
            )
          }}
          disabled={isLoading}
        />
      )
    }
  } else if (questionType === EvaluationQuestionType.RESPONSE_LEVEL) {
    return (
      <ScrollArea hasHorizontalScroll className="w-full">
        <div className="flex justify-start">
          {currentModelResponses.map((modelResponse, idx) => {
            const userEvaluationResponseInputForModelResponse =
              userEvaluationResponseInputs.find(
                (input) =>
                  input.modelResponseId === modelResponse.id &&
                  input.questionId === question.id
              )
            const userEvaluationResponseInputForModelResponseIdx =
              userEvaluationResponseInputs.findIndex(
                (input) =>
                  input.modelResponseId === modelResponse.id &&
                  input.questionId === question.id
              )
            // TODO sanity check, remove later
            if (
              _.isNil(userEvaluationResponseInputForModelResponse) ||
              userEvaluationResponseInputForModelResponseIdx !== idx ||
              userEvaluationResponseInputs[idx].id !==
                userEvaluationResponseInputForModelResponse.id ||
              userEvaluationResponseInputs[idx].questionId !==
                userEvaluationResponseInputForModelResponse.questionId ||
              userEvaluationResponseInputs[idx].modelResponseId !==
                userEvaluationResponseInputForModelResponse.modelResponseId
            ) {
              throw new Error(
                'Mismatch detected between model response and response template objects!'
              )
            }

            if (
              question.responseType === EvaluationQuestionResponseType.RATING
            ) {
              let existingResponseValue
              try {
                existingResponseValue = (
                  userEvaluationResponseInputForModelResponse.response as EvaluationQuestionResponsePayloadRating
                ).responseValue
              } catch (e) {
                existingResponseValue = null
              }

              // TODO handle error
              const meta = question.meta as EvaluationQuestionMeta
              const responseOption =
                meta.responseOptions as EvaluationQuestionResponseOptionRating
              const min = responseOption.min
              const max = responseOption.max
              const step = responseOption.step

              return (
                <Card
                  className={`m-2 min-w-[${50 * (max - min + 1)}px]`}
                  key={idx}
                >
                  <CardHeader>
                    <CardTitle>Response {idx + 1}</CardTitle>
                  </CardHeader>
                  <CardContent>
                    <div className="flex items-center justify-center gap-4">
                      <p>Lowest</p>
                      <RatingButtons
                        min={min}
                        max={max}
                        step={step}
                        rating={existingResponseValue}
                        disabled={isLoading}
                        onRatingChange={(rating) => {
                          const newResponsePayload: EvaluationQuestionResponsePayloadRating =
                            {
                              responseType:
                                EvaluationQuestionResponseType.RATING,
                              responseValue: rating,
                            }
                          const input: UserEvaluationResponseInput = {
                            id: userEvaluationResponseInputForModelResponse.id,
                            questionId: question.id,
                            modelResponseId: modelResponse.id,
                            response: newResponsePayload,
                          }
                          const newExpectedUserEvaluationResponseTemplates = [
                            ...expectedUserEvaluationResponseTemplates,
                          ]
                          const newEvaluationResponseInputs = [
                            ...userEvaluationResponseInputs,
                          ]
                          newEvaluationResponseInputs[
                            userEvaluationResponseInputForModelResponseIdx
                          ] = input
                          newExpectedUserEvaluationResponseTemplates[
                            expectedUserEvaluationResponseTemplatesIdx
                          ] = {
                            ...newExpectedUserEvaluationResponseTemplates[
                              expectedUserEvaluationResponseTemplatesIdx
                            ],
                            userEvaluationResponseInputs:
                              newEvaluationResponseInputs,
                          }
                          setExpectedUserEvaluationResponseTemplates(
                            newExpectedUserEvaluationResponseTemplates
                          )
                        }}
                      />
                      <p>Highest</p>
                    </div>
                  </CardContent>
                </Card>
              )
            } else if (
              question.responseType === EvaluationQuestionResponseType.FREE_FORM
            ) {
              // TODO abstract this to re-usable component
              let existingResponseValue
              try {
                existingResponseValue = (
                  userEvaluationResponseInputForModelResponse.response as EvaluationQuestionResponsePayloadFreeForm
                ).responseValue
              } catch (e) {
                existingResponseValue = null
              }
              return (
                <Card className="m-2 min-w-[500px]" key={idx}>
                  <CardHeader>
                    <CardTitle>Response {idx + 1}</CardTitle>
                  </CardHeader>
                  <CardContent>
                    <Textarea
                      className="w-full" // TODO [non-urgent] why can i not use m-4 here without overflow
                      placeholder="Enter your response here"
                      value={existingResponseValue ?? ''}
                      onChange={(e) => {
                        const newResponseValue = e.target.value
                        const newResponsePayload: EvaluationQuestionResponsePayloadFreeForm =
                          {
                            responseType:
                              EvaluationQuestionResponseType.FREE_FORM,
                            responseValue: newResponseValue,
                          }
                        const input: UserEvaluationResponseInput = {
                          id: userEvaluationResponseInputForModelResponse.id,
                          questionId: question.id,
                          modelResponseId: modelResponse.id,
                          response: newResponsePayload,
                        }
                        const newExpectedUserEvaluationResponseTemplates = [
                          ...expectedUserEvaluationResponseTemplates,
                        ]
                        const newEvaluationResponseInputs = [
                          ...userEvaluationResponseInputs,
                        ]
                        newEvaluationResponseInputs[
                          userEvaluationResponseInputForModelResponseIdx
                        ] = input
                        newExpectedUserEvaluationResponseTemplates[
                          expectedUserEvaluationResponseTemplatesIdx
                        ] = {
                          ...newExpectedUserEvaluationResponseTemplates[
                            expectedUserEvaluationResponseTemplatesIdx
                          ],
                          userEvaluationResponseInputs:
                            newEvaluationResponseInputs,
                        }
                        setExpectedUserEvaluationResponseTemplates(
                          newExpectedUserEvaluationResponseTemplates
                        )
                      }}
                      disabled={isLoading}
                    />
                  </CardContent>
                </Card>
              )
            }
          })}
        </div>
      </ScrollArea>
    )
  }
}

export default UserEvaluation
