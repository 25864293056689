import React, { useState, useEffect } from 'react'
import { useRef } from 'react'

import { useVirtualizer } from '@tanstack/react-virtual'
import _, { isEmpty } from 'lodash'
import { Check, ChevronsUpDown, PlusCircle } from 'lucide-react'

import { cn } from 'utils/utils'

import { Badge } from 'components/ui/badge'
import { Button } from 'components/ui/button'
import Icon from 'components/ui/icon/icon'
import {
  Popover,
  PopoverContent,
  PopoverMenuItem,
  PopoverTrigger,
} from 'components/ui/popover'
import { ScrollArea } from 'components/ui/scroll-area'
import SearchInput from 'components/ui/search-input'
import { Spinner } from 'components/ui/spinner'

export interface Props {
  value: string
  setValue: (value: string) => void
  defaultText: string
  options: { value: string; label: string; extras?: string[] }[]
  popoverClassName?: string
  buttonClassName?: string
  buttonSize?: 'default' | 'sm' | 'lg'
  searchClassName?: string
  popoverMenuItemClassName?: string
  popoverMenuItemTextClassName?: string
  containerRef?: React.RefObject<HTMLDivElement>
  className?: string
  inputPlaceholder?: string
  emptyStateText?: string
  hasCreateNewCommand?: boolean
  onCreateNew?: (value: string) => void
  createNewCommandLabel?: string
  prefix?: React.ReactNode
  maxLength?: number
  disabled?: boolean
  align?: 'center' | 'start' | 'end'
  showClearOption?: boolean
  virtual?: boolean
  virtualEstimateSize?: number
  'data-testid'?: string
  id?: string
  shouldFocusOption?: boolean
  isLoading?: boolean
  hideSearchInput?: boolean
  maxHeight?: string
}

const CREATE_NEW_COMMAND_OPTION = {
  value: 'create-new',
  label: 'Create new',
}

const CLEAR_COMMAND_OPTION = {
  value: 'none',
  label: 'Clear selection',
}

const Combobox = ({
  className,
  popoverClassName,
  buttonClassName,
  buttonSize = 'default',
  searchClassName,
  popoverMenuItemClassName,
  popoverMenuItemTextClassName,
  containerRef,
  options,
  setValue,
  value,
  defaultText,
  inputPlaceholder = 'Search',
  emptyStateText = 'Not found',
  hasCreateNewCommand = false,
  createNewCommandLabel,
  prefix,
  onCreateNew = () => {},
  maxLength,
  disabled,
  align = 'center',
  showClearOption = false,
  virtual = false,
  virtualEstimateSize = 32,
  'data-testid': dataTestId,
  id,
  shouldFocusOption = true,
  isLoading,
  hideSearchInput = false,
  maxHeight,
}: Props) => {
  const [open, setOpen] = useState(false)
  const [search, setSearch] = useState('')
  const [filteredOptions, setFilteredOptions] = useState(options)

  useEffect(() => {
    setFilteredOptions(options)
  }, [options])

  const popoverTriggerButtonTitle = React.useMemo(
    () =>
      value && options.some((option) => option.value === value)
        ? options.find((option) => option.value === value)?.label ?? defaultText
        : defaultText,
    [value, defaultText, options]
  )

  const handlePopoverOpenChange = (isOpen: boolean) => {
    setOpen(isOpen)
    if (!isOpen) {
      handleSearch('')
    }
  }

  const handlePopoverClose = () => {
    handlePopoverOpenChange(false)
  }

  const handleTriggerClick = (e: React.MouseEvent) => {
    e.stopPropagation()
    handlePopoverOpenChange(!open)
  }

  const handleTriggerKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' || e.key === 'ArrowDown') {
      handlePopoverOpenChange(!open)
    } else if (e.code === `Key${e.key.toUpperCase()}`) {
      setSearch((prevSearch) => prevSearch + e.key)
      setOpen(true)
    }
  }

  const handleSearch = (search: string) => {
    setSearch(search)
    const searchString = search.trim().toLowerCase()
    if (searchString === '') {
      setFilteredOptions(options)
      return
    }
    setFilteredOptions(
      options.filter((option) =>
        option.label.toLowerCase().includes(searchString)
      )
    )
  }

  return (
    <Popover open={open}>
      <PopoverTrigger
        asChild
        className={cn('w-[200px] min-w-0', className, {
          'pointer-events-none': disabled,
        })}
        data-testid={dataTestId}
        id={id}
      >
        <Button
          variant="outline"
          role="combobox"
          size={buttonSize}
          aria-expanded={open}
          className={cn(
            `flex`,
            {
              'pb-0 pl-0 pt-0': prefix,
              'text-muted hover:text-muted': !value,
            },
            buttonClassName
          )}
          disabled={disabled}
          onClick={handleTriggerClick}
          onKeyDown={handleTriggerKeyDown}
        >
          {prefix && (
            <div
              className={cn(
                'mr-2.5 flex h-full shrink-0 items-center rounded-l-md border-r bg-button-secondary font-semibold text-muted',
                {
                  'opacity-50': disabled,
                  'px-1': buttonSize === 'sm',
                  'px-2': buttonSize === 'default',
                  'px-3': buttonSize === 'lg',
                }
              )}
            >
              {prefix}
            </div>
          )}
          <span
            className={cn('min-w-0 grow truncate text-left', {
              'text-xs': buttonSize === 'sm',
              'text-sm': buttonSize === 'default',
              'text-lg': buttonSize === 'lg',
            })}
          >
            {popoverTriggerButtonTitle}
          </span>

          <Icon
            icon={ChevronsUpDown}
            size={
              buttonSize === 'lg'
                ? 'large'
                : buttonSize === 'sm'
                ? 'small'
                : 'default'
            }
            className={cn('opacity-50', {
              'ml-1': buttonSize === 'sm',
              'ml-2': buttonSize === 'default',
              'ml-3': buttonSize === 'lg',
            })}
          />
        </Button>
      </PopoverTrigger>
      <PopoverContent
        className={cn(
          'max-h-[--radix-popover-content-available-height] w-[--radix-popover-trigger-width] min-w-[200px] p-0',
          popoverClassName
        )}
        align={align}
        container={containerRef?.current}
        onEscapeKeyDown={handlePopoverClose}
        onPointerDownOutside={handlePopoverClose}
      >
        <ComboboxPopoverContent
          filteredOptions={filteredOptions}
          search={search}
          setSearch={handleSearch}
          value={value}
          setValue={setValue}
          inputPlaceholder={inputPlaceholder}
          emptyStateText={emptyStateText}
          setOpen={setOpen}
          onCreateNew={onCreateNew}
          hasCreateNewCommand={hasCreateNewCommand}
          createNewCommandLabel={createNewCommandLabel}
          maxLength={maxLength}
          showClearOption={showClearOption}
          virtual={virtual}
          virtualEstimateSize={virtualEstimateSize}
          shouldFocusOption={shouldFocusOption}
          searchClassName={searchClassName}
          popoverMenuItemClassName={popoverMenuItemClassName}
          popoverMenuItemTextClassName={popoverMenuItemTextClassName}
          isLoading={isLoading}
          hideSearchInput={hideSearchInput}
          maxHeight={maxHeight}
        />
      </PopoverContent>
    </Popover>
  )
}

export default Combobox

interface ComboboxPopoverContentProps {
  filteredOptions: { value: string; label: string; extras?: string[] }[]
  search: string
  setSearch: (value: string) => void
  value: string
  setValue: (value: string) => void
  inputPlaceholder?: string
  emptyStateText?: string
  setOpen: (value: boolean) => void
  onCreateNew?: (value: string) => void
  hasCreateNewCommand?: boolean
  createNewCommandLabel?: string
  maxLength?: number
  showClearOption?: boolean
  virtual?: boolean
  virtualEstimateSize?: number
  shouldFocusOption?: boolean
  searchClassName?: string
  popoverMenuItemClassName?: string
  popoverMenuItemTextClassName?: string
  isLoading?: boolean
  hideSearchInput?: boolean
  maxHeight?: string
}

const ComboboxPopoverContent = ({
  filteredOptions,
  search,
  setSearch,
  value,
  setValue,
  inputPlaceholder,
  emptyStateText,
  setOpen,
  onCreateNew,
  hasCreateNewCommand,
  createNewCommandLabel,
  maxLength,
  showClearOption,
  virtual,
  virtualEstimateSize = 32,
  shouldFocusOption = true,
  searchClassName,
  popoverMenuItemClassName,
  popoverMenuItemTextClassName,
  isLoading,
  hideSearchInput,
  maxHeight,
}: ComboboxPopoverContentProps) => {
  const [focusedValue, setFocusedValue] = useState('')

  const searchTrimmed = search.trim()
  const shouldShowCreateNewCommand = hasCreateNewCommand && searchTrimmed !== ''
  if (!createNewCommandLabel) {
    createNewCommandLabel = searchTrimmed
      ? `Create “${searchTrimmed}”`
      : CREATE_NEW_COMMAND_OPTION.label
  }
  // Block hovering over option when navigating with keyboard.
  // This way, if the mouse is still over the menu but the user starts
  // navigating up/down with arrows, there aren't two highlighted items.
  const allowOptionHover = useRef(true)

  const parentRef = useRef<HTMLDivElement>(null)
  const searchRef = useRef<HTMLInputElement>(null)

  const virtualizer = useVirtualizer({
    count: filteredOptions.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => virtualEstimateSize,
    overscan: 5,
  })
  const virtualOptions = virtualizer.getVirtualItems()
  // If we're using virtualized list, we must always set a max height.
  // Otherwise, the virtualizer will think we have infinite space (until the
  // radix popover height variables are calculated) and render all items.
  if (virtual && !maxHeight) maxHeight = 'max-h-screen'

  useEffect(() => {
    if (!shouldFocusOption) return
    setFocusedValue((prevValue) => {
      if (
        !prevValue ||
        !filteredOptions.find((option) => option.value === prevValue)
      ) {
        return filteredOptions.length
          ? filteredOptions[0].value
          : showClearOption
          ? CLEAR_COMMAND_OPTION.value
          : shouldShowCreateNewCommand
          ? CREATE_NEW_COMMAND_OPTION.value
          : ''
      }
      return prevValue
    })
  }, [
    shouldFocusOption,
    filteredOptions,
    shouldShowCreateNewCommand,
    showClearOption,
  ])

  const scrollItemIntoView = (value: string) => {
    const focusItem = parentRef.current?.querySelector(
      `[value="${stripQuotes(value)}"]`
    )
    if (focusItem) focusItem.scrollIntoView({ block: 'nearest' })
  }

  const handleCreateNew = () => {
    if (onCreateNew) {
      onCreateNew(search)
      setValue(search)
    }
    setOpen(false)
  }

  const handleSearchKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    setFocusedValue('')
    allowOptionHover.current = false
    if (e.key === 'Enter') {
      const searchValue = e.currentTarget.value.trim()
      const existingOption = filteredOptions.find(
        (option) => option.value.toLowerCase() === searchValue.toLowerCase()
      )
      if (existingOption) {
        setValue(existingOption.value)
      } else if (
        focusedValue &&
        focusedValue !== CREATE_NEW_COMMAND_OPTION.value
      ) {
        setValue(focusedValue)
      } else if (
        searchValue.length > 0 &&
        onCreateNew &&
        (focusedValue === CREATE_NEW_COMMAND_OPTION.value ||
          hasCreateNewCommand)
      ) {
        setValue(searchValue)
        onCreateNew(searchValue)
      }
      setOpen(false)
    } else if (e.key === 'Escape') {
      setOpen(false)
    } else if (e.key === 'Tab' && focusedValue && parentRef.current) {
      const focusButton = parentRef.current.querySelector(
        `[value="${stripQuotes(focusedValue)}"]`
      )
      if (focusButton) {
        ;(focusButton as HTMLElement).focus()
        e.preventDefault()
      }
    } else if (e.key === 'ArrowDown' && filteredOptions.length > 0) {
      const focusedIndex = filteredOptions.findIndex(
        (option) => option.value === focusedValue
      )
      let nextValue = focusedValue
      if (focusedIndex === filteredOptions.length - 1) {
        if (shouldShowCreateNewCommand) {
          nextValue = CREATE_NEW_COMMAND_OPTION.value
        } else if (showClearOption) {
          nextValue = CLEAR_COMMAND_OPTION.value
        } else {
          nextValue = filteredOptions[0].value
        }
      } else {
        nextValue = filteredOptions[focusedIndex + 1].value
      }
      setFocusedValue(nextValue)
      scrollItemIntoView(nextValue)
      e.preventDefault()
    } else if (e.key === 'ArrowUp' && filteredOptions.length > 0) {
      const focusedIndex = filteredOptions.findIndex(
        (option) => option.value === focusedValue
      )
      let prevValue = focusedValue
      if (focusedIndex === 0) {
        if (showClearOption) {
          prevValue = CLEAR_COMMAND_OPTION.value
        } else if (shouldShowCreateNewCommand) {
          prevValue = CREATE_NEW_COMMAND_OPTION.value
        } else {
          prevValue = filteredOptions[filteredOptions.length - 1].value
        }
      } else if (focusedIndex === -1) {
        if (
          focusedValue === CLEAR_COMMAND_OPTION.value ||
          focusedValue === CREATE_NEW_COMMAND_OPTION.value
        ) {
          prevValue = filteredOptions[filteredOptions.length - 1].value
        } else {
          prevValue = filteredOptions[0].value
        }
      } else {
        prevValue = filteredOptions[focusedIndex - 1].value
      }
      setFocusedValue(prevValue)
      scrollItemIntoView(prevValue)
      e.preventDefault()
    }
  }

  const handleOptionKeyDown = (e: React.KeyboardEvent<HTMLButtonElement>) => {
    if (e.key === 'ArrowUp' && searchRef.current) {
      const previousSibling = e.currentTarget.previousElementSibling
      if (
        !previousSibling ||
        previousSibling.nodeName !== e.currentTarget.nodeName
      ) {
        searchRef.current.focus()
        e.preventDefault()
      }
    }
  }

  const handleOptionClick = (newValue: string) => {
    setValue(value === newValue ? '' : newValue)
    setOpen(false)
  }

  const handleOptionFocus = (value: string) => {
    if (!shouldFocusOption) return
    setFocusedValue(value)
  }

  const handleOptionMouseEnter = (value: string) => {
    if (allowOptionHover.current) {
      handleOptionFocus(value)
    }
  }

  const handleMenuMouseMove = () => {
    allowOptionHover.current = true
  }

  const hasOptions =
    showClearOption ||
    shouldShowCreateNewCommand ||
    (virtual && virtualOptions.length > 0) ||
    filteredOptions.length > 0

  const showMenu = hasOptions || isLoading
  const optionsWithStyles = virtual
    ? virtualOptions.map((option, index) => ({
        option: filteredOptions[option.index],
        style: {
          height: `${option.size}px`,
          transform: `translateY(${option.start - index * option.size}px)`,
        },
      }))
    : filteredOptions.map((option) => ({ option: option, style: undefined }))

  return (
    <div
      className="flex min-h-0 flex-col rounded-md"
      onMouseMove={handleMenuMouseMove}
    >
      {!hideSearchInput && (
        <SearchInput
          ref={searchRef}
          value={search}
          setValue={setSearch}
          placeholder={inputPlaceholder}
          maxLength={maxLength}
          onKeyDown={handleSearchKeyDown}
          className={cn({ 'border-b': showMenu })}
          inputClassName={cn(
            'h-9 rounded-b-none border-0 pr-6 outline-none focus-visible:ring-0 focus-visible:ring-offset-0 ',
            searchClassName
          )}
          data-testid="combobox-search-input"
        />
      )}
      <ScrollArea
        className={cn('flex flex-col', {
          'p-1': showMenu,
        })}
        maxHeight={maxHeight}
        ref={parentRef}
      >
        {showClearOption && (
          <ComboboxPopoverMenuItem
            focusedValue={focusedValue}
            handleOptionClick={handleOptionClick}
            handleOptionFocus={handleOptionFocus}
            handleOptionKeyDown={handleOptionKeyDown}
            handleOptionMouseEnter={handleOptionMouseEnter}
            optionValue={CLEAR_COMMAND_OPTION.value}
          >
            <ComboboxItem
              option={CLEAR_COMMAND_OPTION}
              value={value}
              focusedValue={focusedValue}
              popoverMenuItemTextClassName={popoverMenuItemTextClassName}
            />
          </ComboboxPopoverMenuItem>
        )}
        {isLoading && !hasOptions && (
          <div className="p-1.5">
            <Spinner className="m-auto h-3 w-3" />
          </div>
        )}
        <div
          style={{
            height:
              virtual && virtualizer.getTotalSize()
                ? `${virtualizer.getTotalSize()}px`
                : undefined,
          }}
        >
          {optionsWithStyles.map((item) => (
            <ComboboxPopoverMenuItem
              key={item.option.value}
              focusedValue={focusedValue}
              handleOptionClick={handleOptionClick}
              handleOptionFocus={handleOptionFocus}
              handleOptionKeyDown={handleOptionKeyDown}
              handleOptionMouseEnter={handleOptionMouseEnter}
              optionValue={item.option.value}
              optionExtras={item.option.extras}
              popoverMenuItemClassName={popoverMenuItemClassName}
              // eslint-disable-next-line react/forbid-component-props
              style={item.style}
            >
              <ComboboxItem
                option={item.option}
                value={value}
                focusedValue={focusedValue}
                popoverMenuItemTextClassName={popoverMenuItemTextClassName}
              />
            </ComboboxPopoverMenuItem>
          ))}
        </div>

        {/* Empty State */}
        {_.isEmpty(filteredOptions) &&
          searchTrimmed &&
          !hasCreateNewCommand && (
            <div className="mb-2 px-2 py-1 text-xs text-muted">
              {emptyStateText}
            </div>
          )}

        {/* Actions */}
        {shouldShowCreateNewCommand && (
          <ComboboxPopoverMenuItem
            focusedValue={focusedValue}
            handleOptionClick={handleCreateNew}
            handleOptionFocus={handleOptionFocus}
            handleOptionKeyDown={handleOptionKeyDown}
            handleOptionMouseEnter={handleOptionMouseEnter}
            optionValue={CREATE_NEW_COMMAND_OPTION.value}
            disabled={!searchTrimmed}
          >
            <Icon icon={PlusCircle} className="mr-2 shrink-0" />
            {createNewCommandLabel}
          </ComboboxPopoverMenuItem>
        )}
      </ScrollArea>
    </div>
  )
}

const ComboboxPopoverMenuItem = ({
  children,
  focusedValue,
  handleOptionClick,
  handleOptionFocus,
  handleOptionKeyDown,
  handleOptionMouseEnter,
  optionValue,
  optionExtras,
  popoverMenuItemClassName,
  style,
  disabled,
}: {
  children: React.ReactNode
  focusedValue: string
  handleOptionClick: (newValue: string) => void
  handleOptionFocus: (value: string) => void
  handleOptionKeyDown: (e: React.KeyboardEvent<HTMLButtonElement>) => void
  handleOptionMouseEnter: (value: string) => void
  popoverMenuItemClassName?: string
  optionValue: string
  optionExtras?: string[]
  style?: React.CSSProperties
  disabled?: boolean
}) => {
  return (
    <PopoverMenuItem
      className={cn(
        'hover:bg-inherit',
        {
          'bg-secondary-hover hover:bg-secondary-hover':
            focusedValue === optionValue,
          'group-[.dark]:bg-interactive-hover': focusedValue === optionValue,
          'justify-between': !isEmpty(optionExtras),
        },
        popoverMenuItemClassName
      )}
      // eslint-disable-next-line react/forbid-component-props
      style={style}
      onClick={() => handleOptionClick(optionValue)}
      onFocus={() => handleOptionFocus(optionValue)}
      onKeyDown={handleOptionKeyDown}
      onMouseEnter={() => handleOptionMouseEnter(optionValue)}
      role="option"
      disabled={disabled}
      value={stripQuotes(optionValue)}
    >
      {children}
    </PopoverMenuItem>
  )
}

const ComboboxItem = ({
  option,
  value,
  focusedValue,
  popoverMenuItemTextClassName,
}: {
  option: { value: string; label: string; extras?: string[] }
  value: string
  focusedValue: string
  popoverMenuItemTextClassName?: string
}) => {
  return (
    <div className="flex w-full items-center space-x-2">
      <Icon
        icon={Check}
        className={cn('opacity-0', {
          'opacity-100': value === option.value,
        })}
      />
      <p className={cn('grow', popoverMenuItemTextClassName)}>{option.label}</p>
      {option.extras && option.extras.length > 0 && (
        <div className="flex shrink-0 space-x-2">
          {option.extras
            .map((extraValue: string) => extraValue.trim())
            .filter(Boolean)
            .map((extraValue: string) => (
              <Badge
                key={extraValue}
                variant="secondary"
                className={cn('h-6 rounded-full border-0 text-muted', {
                  'bg-button-secondary-hover hover:bg-button-secondary-hover':
                    focusedValue === option.value,
                })}
              >
                <p className="text-xs">{extraValue}</p>
              </Badge>
            ))}
        </div>
      )}
    </div>
  )
}

// Replace double quotes " that interfere with querySelector
const stripQuotes = (value: string) => {
  // eslint-disable-next-line harvey/no-straight-quotes
  return value.replaceAll('"', '\\"')
}
