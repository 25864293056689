import React, { useCallback, useMemo, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { convertMillimetersToTwip } from 'docx'
import _ from 'lodash'
import { InfoIcon } from 'lucide-react'
import { Instance } from 'pspdfkit'
import { useShallow } from 'zustand/react/shallow'

import { uploadFile } from 'api'
import { EventKind } from 'openapi/models/EventKind'
import { UploadedFile } from 'openapi/models/UploadedFile'
import { UploadedFileToJSON } from 'openapi/models/UploadedFile'
import { WorkflowType } from 'types/workflows'

import { useNavigateWithQueryParams } from 'hooks/use-navigate-with-query-params'
import { mbToReadable } from 'utils/file-utils'
import { exportWordWithSections } from 'utils/markdown'
import { TaskType } from 'utils/task'
import { displayErrorMessage } from 'utils/toast'
import { IS_LOADING_HELP_TEXT } from 'utils/tooltip-texts'
import useHarveySocket from 'utils/use-harvey-socket'
import { isUserInputEmpty } from 'utils/utils'
import { cn } from 'utils/utils'

import {
  FILE_ID_PARAM,
  SOURCE_ID_PARAM,
} from 'components/assistant/utils/assistant-helpers'
import useQueryAnalytics from 'components/common/analytics/use-query-analytics'
import { AppHeaderActions } from 'components/common/app-header-actions'
import AskHarveyButton from 'components/common/ask-harvey-button'
import DeprecatedTagInput from 'components/common/deprecated-tag-input/deprecated-tag-input'
import DropzoneDescription from 'components/common/dropzone/dropzone-description'
import ExportDialog from 'components/common/export/export-dialog'
import { ExportOptionValues } from 'components/common/export/types'
import PdfPushSheet from 'components/common/pdf-viewer/pdf-push-sheet'
import Response from 'components/common/response/response'
import SourcePopover from 'components/common/source-popover'
import { Button } from 'components/ui/button'
import { Checkbox } from 'components/ui/checkbox'
import { Collapsible } from 'components/ui/collapsible'
import Icon from 'components/ui/icon/icon'
import { Label } from 'components/ui/label'
import {
  ImperativeResizablePanelGroupHandle,
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from 'components/ui/resizable'
import { ScrollArea } from 'components/ui/scroll-area'
import { Textarea } from 'components/ui/text-area'
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/ui/tooltip'
import { DILIGENCE_TRANSCRIPTS_HELP } from 'components/workflows/constants'
import { WorkflowTypeToDetails } from 'components/workflows/workflow-definitions'
import WorkflowFileInput from 'components/workflows/workflow/components/workflow-file-input'
import { DiligenceDocumentsTable } from 'components/workflows/workflow/discovery/common/documents-table'
import { WorkflowsDualPaneLayout } from 'components/workflows/workflow/layouts/dual-pane'
import WorkflowLayout from 'components/workflows/workflow/workflow-layout'

import * as config from './config'
import useDiligenceTranscriptsStore from './diligence-transcripts-store'

const SERVER_ROUTE = 'diligence/transcripts'

const DiligenceTranscriptsWorkflow: React.FC = () => {
  const [
    activeDocument,
    isLoading,
    metadata,
    progress,
    reset,
    response,
    sources,
    themes,
    userProvidedContext,
    userProvidedKeywords,
    userProvidedStyle,
    setActiveDocument,
    setTask,
    setThemes,
    setUserProvidedContext,
    setUserProvidedKeywords,
    setUserProvidedStyle,
  ] = useDiligenceTranscriptsStore(
    useShallow((s) => [
      s.activeDocument,
      s.isLoading,
      s.metadata,
      s.progress,
      s.reset,
      s.response,
      s.sources,
      s.themes,
      s.userProvidedContext,
      s.userProvidedKeywords,
      s.userProvidedStyle,
      s.setActiveDocument,
      s.setTask,
      s.setThemes,
      s.setUserProvidedContext,
      s.setUserProvidedKeywords,
      s.setUserProvidedStyle,
    ])
  )

  const { initSocketAndSendQuery, sendCancelRequest } = useHarveySocket({
    path: SERVER_ROUTE,
    setter: setTask,
  })

  const [searchParams, setSearchParams] = useSearchParams()
  const selectedSourceFileId = searchParams.get(FILE_ID_PARAM)
  const selectedSourceId = searchParams.get(SOURCE_ID_PARAM)

  const getDocument = useCallback(
    async (
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      eventId: string | null,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      fileId: string
    ): Promise<UploadedFile | undefined> => {
      if (metadata && metadata.transcriptPdf) {
        return metadata.transcriptPdf
      }

      if (!activeDocument) {
        return undefined
      }

      return activeDocument
    },
    [activeDocument, metadata]
  )

  const pushSheet = useMemo(
    () =>
      selectedSourceFileId && (
        <PdfPushSheet
          fileId={selectedSourceFileId}
          getDocument={getDocument}
          sources={sources}
        />
      ),
    [getDocument, selectedSourceFileId, sources]
  )

  const navigate = useNavigateWithQueryParams()

  const { recordQueryCancel, recordQuerySubmitted, recordReset } =
    useQueryAnalytics(EventKind.DILIGENCE_TRANSCRIPTS)

  const pspdfInstanceRef = useRef<Instance | null>(null)

  const resizablePanelGroupRef =
    useRef<ImperativeResizablePanelGroupHandle | null>(null)

  const [isAutoGenerateThemes, setIsAutoGenerateThemes] = useState(true)

  const askHarveyButtonTooltip = () => {
    if (_.isNil(activeDocument)) {
      return 'Please upload a file before asking Harvey'
    } else if (isLoading) {
      return IS_LOADING_HELP_TEXT
    } else if (isAudioFile && !userProvidedKeywords) {
      return 'Please enter relevant keywords when uploading an audio file'
    } else {
      return ''
    }
  }

  const defaultResizablePanelSizes = selectedSourceFileId ? [50, 50] : [100, 0]

  // The Response component's display of a loading skeleton depends on its headerText prop being set. The diligence transcripts endpoint currently does not set
  // headerText, so we set it manually here based on query loading state.
  const headerText = isLoading ? 'Loading' : ''

  const [fileType, setFileType] = useState<string | null>(null)

  const isAudioFile =
    (activeDocument && fileType?.startsWith('audio/')) || false

  const isAskHarveyButtonDisabled =
    isLoading || !activeDocument || (isAudioFile && !userProvidedKeywords)

  const userInputs: unknown[] = [
    activeDocument,
    userProvidedContext,
    userProvidedKeywords,
    userProvidedStyle,
    themes,
  ]
  const emptyUserInput = isUserInputEmpty(userInputs)
  const resetDisabled = emptyUserInput || isLoading

  const workflowType = WorkflowType.DILIGENCE_TRANSCRIPTS
  const workflow = WorkflowTypeToDetails[workflowType]

  const getHrvyInfoMetadata = (identifier: string) => {
    if (!activeDocument) {
      return
    }

    const source = sources.find((s) => s.id === identifier)
    if (!source) {
      console.warn('Could not find source for citation')
      displayErrorMessage('Could not find source for citation')
      return
    }

    const onClick = () => {
      setSearchParams((prevParams) => {
        const newParams = new URLSearchParams(prevParams)
        if (metadata && metadata.transcriptPdf) {
          newParams.set(FILE_ID_PARAM, metadata.transcriptPdf.id)
        } else {
          newParams.set(FILE_ID_PARAM, activeDocument.id)
        }
        newParams.set(SOURCE_ID_PARAM, source.id)
        return newParams
      })
    }

    const hoverContent = <SourcePopover source={source} onClick={onClick} />

    return {
      hoverContent,
      isSelected:
        source.documentId === selectedSourceFileId &&
        source.id === selectedSourceId,
      onClick,
    }
  }

  const handleCancel = () => {
    recordQueryCancel()
    sendCancelRequest()
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleExport = async (values: ExportOptionValues) => {
    await exportWordWithSections({
      defaultStyleOverrides: {
        heading1: {
          paragraph: {
            spacing: {
              before: convertMillimetersToTwip(5),
            },
          },
        },
        heading2: {
          paragraph: {
            spacing: {
              before: convertMillimetersToTwip(2),
            },
          },
        },
        heading3: {
          paragraph: {
            spacing: {
              before: convertMillimetersToTwip(2),
            },
          },
        },
      },
      filePrefixOverride: 'DILIGENCE_TRANSCRIPTS',
      includeAnnotation: false,
      queryId: '0', // TODO: Set to eventId when available
      sections: [
        {
          content: response,
          type: 'html',
        },
      ],
      taskType: TaskType.DILIGENCE_TRANSCRIPTS,
      title: 'Diligence Transcripts',
    })
  }

  const handleFile = async (file: File) => {
    const response = await uploadFile(file)
    setActiveDocument(response)
    setFileType(file.type)
  }

  const handleFileDelete = () => {
    setActiveDocument(null)
    setFileType(null)
  }

  const handleReset = () => {
    navigate(workflow.path, { replace: true })
    reset()

    setSearchParams((prevParams) => {
      const newParams = new URLSearchParams(prevParams)
      newParams.delete(FILE_ID_PARAM)
      newParams.delete(SOURCE_ID_PARAM)
      return newParams
    })

    recordReset()
  }

  const handleSubmit = async () => {
    if (!activeDocument) {
      return
    }

    recordQuerySubmitted({
      event_kind: EventKind.DILIGENCE_TRANSCRIPTS,
    })

    initSocketAndSendQuery({
      query: '',
      additionalAuthParams: {
        task_type: EventKind.DILIGENCE_TRANSCRIPTS,
      },
      additionalRequestParams: {
        auto_generate_themes: isAutoGenerateThemes,
        context: userProvidedContext,
        documents: [UploadedFileToJSON(activeDocument)],
        keywords: userProvidedKeywords,
        style: userProvidedStyle,
        themes: themes,
      },
    })
  }

  const resetLayout = () => {
    const panelGroup = resizablePanelGroupRef.current
    if (panelGroup) {
      panelGroup.setLayout(defaultResizablePanelSizes)
    }
  }

  const TagInputEmptyState = () => {
    return (
      <div className="ml-1 text-sm text-muted">
        Example themes: Usage, Opinions on solutions, Competitors
      </div>
    )
  }

  const previewComponent =
    activeDocument && isAudioFile ? (
      <div className="flex h-full flex-col">
        <DiligenceDocumentsTable
          documents={[{ file: activeDocument, isLoading: false }]}
          onDeleteClick={handleFileDelete}
          onMultiSelectChange={() => {}}
        />
      </div>
    ) : null

  return (
    <WorkflowLayout
      appHeaderActions={
        <>
          <AppHeaderActions
            handleReset={handleReset}
            resetDisabled={resetDisabled}
          />
          <ExportDialog
            onExport={handleExport}
            disabled={isLoading || !response}
          />
        </>
      }
      {...(selectedSourceFileId ? { childClassName: 'w-full max-w-full' } : {})}
      title="Diligence Transcripts"
      workflowType={WorkflowType.DILIGENCE_TRANSCRIPTS}
    >
      <ResizablePanelGroup direction="horizontal" ref={resizablePanelGroupRef}>
        <ResizablePanel
          className={cn({
            'pr-8': selectedSourceFileId,
          })}
          defaultSize={defaultResizablePanelSizes[0]}
          minSize={20}
        >
          <WorkflowsDualPaneLayout
            hideInput={!!selectedSourceFileId}
            input={
              <ScrollArea className="h-full pr-1" isFullHeight>
                <div className="mx-1 h-full">
                  <WorkflowFileInput
                    eventKind={EventKind.DILIGENCE_TRANSCRIPTS}
                    dropzoneDescription={
                      <DropzoneDescription
                        fileTypes={config.FILE_TYPES}
                        maxSize={mbToReadable(config.MAX_FILE_SIZE_MB)}
                      />
                    }
                    fileTypes={config.FILE_TYPES}
                    handleFile={handleFile}
                    maxFileSizeMb={config.MAX_FILE_SIZE_MB}
                    previewComponent={previewComponent}
                    pspdfInstanceRef={pspdfInstanceRef}
                    store={useDiligenceTranscriptsStore}
                  >
                    <div className="flex flex-col">
                      {/* Relevant Keywords */}
                      {isAudioFile && (
                        <>
                          <div className="flex pt-3">
                            <Label>Relevant Keywords</Label>
                            <Tooltip>
                              <TooltipTrigger>
                                <Button
                                  className={cn(
                                    'ml-1 mt-0.5 h-4 w-4 shrink-0 align-top hover:bg-button-secondary-hover',
                                    { '-mt-0.5': isAudioFile }
                                  )}
                                  size="xsIcon"
                                  variant="ghost"
                                >
                                  <Icon icon={InfoIcon} size="small" />
                                </Button>
                              </TooltipTrigger>
                              <TooltipContent className="w-72 text-center">
                                Enter the names of key vendors, target
                                companies, or other important entities to ensure
                                they are accurately captured in the transcript
                                summary
                              </TooltipContent>
                            </Tooltip>
                          </div>
                          <Textarea
                            className="min-h-30 resize-none focus-visible:ring-offset-0"
                            onChange={(e) =>
                              setUserProvidedKeywords(e.target.value)
                            }
                            placeholder="Key vendors are Apple, Google, Facebook, and Amazon"
                            value={userProvidedKeywords}
                          />
                        </>
                      )}

                      {/* Themes */}
                      <div className="flex pt-3">
                        <Label>
                          Themes{' '}
                          <span className="text-sm text-muted">(optional)</span>
                        </Label>
                        <Tooltip>
                          <TooltipTrigger>
                            <Button
                              className="ml-1 mt-0.5 h-4 w-4 shrink-0 align-top hover:bg-button-secondary-hover"
                              size="xsIcon"
                              variant="ghost"
                            >
                              <Icon icon={InfoIcon} size="small" />
                            </Button>
                          </TooltipTrigger>
                          <TooltipContent className="w-72 text-center">
                            Themes are the main topics in a transcript. Any
                            themes listed here will be included as a topic in
                            the summary.
                          </TooltipContent>
                        </Tooltip>
                      </div>
                      <DeprecatedTagInput
                        addButtonText="Add"
                        className="min-h-30"
                        closeableTags
                        emptyState={<TagInputEmptyState />}
                        inputPlaceholder="Add a theme"
                        setTags={setThemes}
                        tags={themes}
                      />
                      <div className="py-2">
                        <Checkbox
                          id="example-visibility"
                          onClick={() =>
                            setIsAutoGenerateThemes(!isAutoGenerateThemes)
                          }
                          label="Automatically generate additional themes"
                          checked={isAutoGenerateThemes}
                        />
                      </div>

                      <Collapsible
                        className="mt-4"
                        defaultCollapsed={true}
                        title="Additional Options"
                        variant="ghost"
                      >
                        {/* Context */}
                        <div className="flex pt-2">
                          <Label>
                            Context{' '}
                            <span className="text-sm text-muted">
                              (optional)
                            </span>
                          </Label>
                          <Tooltip>
                            <TooltipTrigger>
                              <Button
                                className="ml-1 mt-0.5 h-4 w-4 shrink-0 align-top hover:bg-button-secondary-hover"
                                size="xsIcon"
                                variant="ghost"
                              >
                                <Icon icon={InfoIcon} size="small" />
                              </Button>
                            </TooltipTrigger>
                            <TooltipContent className="w-72 text-center">
                              Enter any additional context about the interview
                              for the summary
                            </TooltipContent>
                          </Tooltip>
                        </div>
                        <Textarea
                          className="min-h-30 resize-none focus-visible:ring-offset-0"
                          onChange={(e) =>
                            setUserProvidedContext(e.target.value)
                          }
                          placeholder="This is an interview with a customer of AI-powered legal software"
                          value={userProvidedContext}
                        />

                        {/* Style */}
                        <div className="flex pt-5">
                          <Label>
                            Style{' '}
                            <span className="text-sm text-muted">
                              (optional)
                            </span>
                          </Label>
                          <Tooltip>
                            <TooltipTrigger>
                              <Button
                                className="ml-1 mt-0.5 h-4 w-4 shrink-0 align-top hover:bg-button-secondary-hover"
                                size="xsIcon"
                                variant="ghost"
                              >
                                <Icon icon={InfoIcon} size="small" />
                              </Button>
                            </TooltipTrigger>
                            <TooltipContent className="w-72 text-center">
                              Specify any preferred output style, such as
                              language (e.g., British English) or level of
                              detail (e.g., verbatim or concise)
                            </TooltipContent>
                          </Tooltip>
                        </div>
                        <Textarea
                          className="min-h-30 resize-none focus-visible:ring-offset-0"
                          onChange={(e) => setUserProvidedStyle(e.target.value)}
                          placeholder="Output the transcript summary in British English"
                          value={userProvidedStyle}
                        />
                      </Collapsible>

                      <AskHarveyButton
                        className="mt-2 w-full"
                        disabled={isAskHarveyButtonDisabled}
                        handleSubmit={handleSubmit}
                        isLoading={false}
                        size="lg"
                        tooltip={askHarveyButtonTooltip()}
                      />
                    </div>
                  </WorkflowFileInput>
                </div>
              </ScrollArea>
            }
            output={
              <Response
                emptyStateText={DILIGENCE_TRANSCRIPTS_HELP}
                getHrvyInfoMetadata={getHrvyInfoMetadata}
                handleCancel={handleCancel}
                headerText={headerText}
                isLoading={isLoading}
                progress={progress}
                markdown={response}
              />
            }
          />
        </ResizablePanel>
        {selectedSourceFileId && (
          <>
            <ResizableHandle withHandle onDoubleClick={resetLayout} />
            <ResizablePanel
              defaultSize={defaultResizablePanelSizes[1]}
              minSize={20}
            >
              {pushSheet}
            </ResizablePanel>
          </>
        )}
      </ResizablePanelGroup>
    </WorkflowLayout>
  )
}

export default DiligenceTranscriptsWorkflow
