/* tslint:disable */
/* eslint-disable */
/**
 * Harvey Web App - Internal Admin
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * All possible storage options for storing user data.
 * @export
 */
export const DataPrivacy = {
    BASIC: 'Basic',
    SEGREGATED: 'Segregated',
    BYOK: 'BYOK'
} as const;
export type DataPrivacy = typeof DataPrivacy[keyof typeof DataPrivacy];


export function instanceOfDataPrivacy(value: any): boolean {
    for (const key in DataPrivacy) {
        if (Object.prototype.hasOwnProperty.call(DataPrivacy, key)) {
            if (DataPrivacy[key as keyof typeof DataPrivacy] === value) {
                return true;
            }
        }
    }
    return false;
}

export function DataPrivacyFromJSON(json: any): DataPrivacy {
    return DataPrivacyFromJSONTyped(json, false);
}

export function DataPrivacyFromJSONTyped(json: any, ignoreDiscriminator: boolean): DataPrivacy {
    return json as DataPrivacy;
}

export function DataPrivacyToJSON(value?: DataPrivacy | null): any {
    return value as any;
}

