/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useCallback } from 'react'

import { ColDef, ValueGetterParams } from 'ag-grid-community'
import { isEmpty } from 'lodash'
import { useShallow } from 'zustand/react/shallow'

import { EM_DASH } from 'utils/utils'

import { useCompetitiveAnalysisDataGridStore } from 'components/workflows/workflow/competitive-analysis/stores/competitive-analysis-data-grid-store'
import { useCompetitiveAnalysisStore } from 'components/workflows/workflow/competitive-analysis/stores/competitive-analysis-store'

import CellViewer from './data-grid/cell-viewer'
import CompetitiveAnalysisDataGrid from './data-grid/competitive-analysis-data-grid'
import CompetitiveAnalysisDataGridHeader from './data-grid/competitive-analysis-data-grid-header'

export const BASE_NUM_KEY = 'IQ_EBITDA'
export enum CellType {
  TEXT = 'text',
  FINANCIAL = 'financial',
  NAME = 'name',
  NUMBER = 'number',
  GUTTER = 'gutter',
}

const CompetitiveAnalysisTable = () => {
  const gridApi = useCompetitiveAnalysisDataGridStore(
    useShallow((s) => s.gridApi)
  )
  const rawData = useCompetitiveAnalysisStore(useShallow((s) => s.rawData))

  const setupGridRowColumnData = useCallback(() => {
    if (!gridApi || !rawData) return
    const columnDefs: Array<ColDef> = [
      {
        valueGetter: (params: ValueGetterParams) => {
          const node = params.node
          const rowIndex = node?.rowIndex ?? 0
          return rowIndex + 1
        },
        field: 'row',
        headerName: '#',
        type: CellType.NUMBER,
        width: 48,
        resizable: false,
        pinned: 'left',
      },
      {
        field: 'name',
        headerName: 'Name',
        type: CellType.NAME,
        pinned: 'left',
        minWidth: 256,
        flex: 4,
      },
      {
        field: 'ciqName',
        headerName: 'CIQ Entity Name',
        type: CellType.TEXT,
        minWidth: 256,
        flex: 4,
      },
      {
        field: 'companySummary',
        headerName: 'Company Summary',
        type: CellType.TEXT,
        minWidth: 256,
        flex: 4,
      },
      {
        field: 'productsAndServices',
        headerName: 'Products and Services',
        type: CellType.TEXT,
        minWidth: 256,
        flex: 4,
      },
      {
        field: 'geo',
        headerName: 'Geography',
        type: CellType.TEXT,
        minWidth: 256,
        flex: 4,
      },
      {
        field: 'analysisResults',
        headerName: 'Competitive Analysis',
        type: CellType.TEXT,
        minWidth: 256,
        flex: 4,
      },
      {
        field: 'currency',
        headerName: 'Filing Currency',
        type: CellType.TEXT,
        minWidth: 256,
        flex: 4,
      },
      {
        field: 'employees',
        headerName: 'Employees',
        type: CellType.TEXT,
        minWidth: 256,
        flex: 4,
      },
      {
        field: 'year',
        headerName: 'Year',
        type: CellType.FINANCIAL,
        minWidth: 256,
        flex: 5,
      },
      {
        field: 'IQ_GP',
        headerName: 'Gross Profit ($m)',
        type: CellType.FINANCIAL,
        minWidth: 256,
        flex: 5,
      },
      {
        field: 'IQ_EBITDA',
        headerName: 'EBITDA ($m)',
        type: CellType.FINANCIAL,
        minWidth: 256,
        flex: 5,
      },
      {
        field: 'IQ_EBIT',
        headerName: 'EBIT ($m)',
        type: CellType.FINANCIAL,
        minWidth: 256,
        flex: 5,
      },
      {
        field: 'IQ_TOTAL_EQUITY',
        headerName: 'Total Equity ($m)',
        type: CellType.FINANCIAL,
        minWidth: 256,
        flex: 5,
      },
      {
        field: 'IQ_TOTAL_LIAB',
        headerName: 'Total Liabilities ($m)',
        type: CellType.FINANCIAL,
        minWidth: 256,
        flex: 5,
      },
    ]

    // Finally push gutter column
    columnDefs.push({
      field: 'gutter',
      headerName: 'gutter',
      type: 'gutter',
      width: 20,
      // Make it really small so it can flex but not taking up space larger than 20 px by default
      flex: 0.01,
    })

    gridApi.updateGridOptions({
      columnDefs: columnDefs,
    })

    const analysisResults = rawData['analysisResults'] ?? {}
    const competitorProfiles = rawData['competitorProfiles'] ?? {}

    const rowData = competitorProfiles
      .sort((a: any, b: any) => a.name.localeCompare(b.name))
      .map((profile: any) => {
        const financialData = profile.ciqFinancialData
        return {
          numYears: financialData?.[BASE_NUM_KEY]
            ? Object.keys(financialData?.[BASE_NUM_KEY]).filter(
                (key) => !isEmpty(key) && key !== 'Data Unavailable'
              ).length
            : 1,
          id: profile.name,
          name: profile.name,
          ciqName: profile.ciqName,
          companySummary: profile.companySummary,
          productsAndServices: profile.productsAndServices,
          geo: profile.geo,
          analysisResults: analysisResults[profile.name],
          currency:
            financialData?.['IQ_FILING_CURRENCY'] &&
            financialData?.['IQ_FILING_CURRENCY'] !== 'Data Unavailable'
              ? financialData?.['IQ_FILING_CURRENCY']
              : EM_DASH,
          employees:
            financialData?.['IQ_EMPLOYEES'] &&
            financialData?.['IQ_EMPLOYEES'] !== 'Data Unavailable'
              ? financialData?.['IQ_EMPLOYEES']
              : EM_DASH,
          ciqFinancialData: financialData,
        }
      })
    gridApi.updateGridOptions({
      rowData: rowData,
    })
  }, [gridApi, rawData])

  useEffect(() => {
    void setupGridRowColumnData()
  }, [setupGridRowColumnData])

  return (
    <div className="flex h-full w-full flex-col">
      <CompetitiveAnalysisDataGridHeader />
      <CompetitiveAnalysisDataGrid />
      <CellViewer />
    </div>
  )
}

export default CompetitiveAnalysisTable
