import React, { useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'

import { UserInfo } from 'models/user-info'

import { useNavigateWithQueryParams } from 'hooks/use-navigate-with-query-params'

import { BaseAppPath, SettingsPath } from 'components/base-app-path'
import { useAuthUser } from 'components/common/auth-context'

import { useSettingsState } from './settings-store'
import './settings.css'

const SettingsIndex = () => {
  const userInfo = useAuthUser()
  const [settingsUser, setSettingsUser] = useSettingsState((s) => [
    s.settingsUser,
    s.setSettingsUser,
  ])
  const selectedWorkspace = settingsUser?.workspace

  const navigate = useNavigateWithQueryParams()
  const location = useLocation()
  const settingsBasePath = BaseAppPath.Settings

  useEffect(() => {
    const newUser = new UserInfo(userInfo)
    setSettingsUser(newUser)
  }, [userInfo, setSettingsUser])

  useEffect(() => {
    if (
      location.pathname === settingsBasePath ||
      location.pathname === `${settingsBasePath}/`
    ) {
      if (settingsUser?.IsWorkspaceInfoViewer) {
        navigate(SettingsPath.Workspace, { replace: true })
      } else if (settingsUser?.isClientMattersReadUser) {
        navigate(SettingsPath.ClientMatters, { replace: true })
      } else if (settingsUser?.IsSharingManagementUser) {
        navigate(SettingsPath.Sharing, { replace: true })
      } else if (
        settingsUser?.IsUsageDashboardViewer ||
        settingsUser?.IsUsageDashboardV2Viewer
      ) {
        navigate(SettingsPath.Usage, { replace: true })
      } else if (settingsUser?.IsClientAdminViewUsers) {
        navigate(SettingsPath.Users, { replace: true })
      } else if (settingsUser?.IsWorkspaceHistoryReader) {
        navigate(SettingsPath.WorkspaceHistory, { replace: true })
      } else if (userInfo.IsInternalAdminReader) {
        navigate(SettingsPath.InternalAdminWorkspaces, {
          replace: true,
        })
      } else {
        navigate(BaseAppPath.Assistant, { replace: true })
      }
    }
  }, [
    location,
    location.pathname,
    userInfo,
    navigate,
    settingsBasePath,
    settingsUser,
  ])

  return (
    <React.Fragment key={selectedWorkspace?.id}>
      <Outlet />
    </React.Fragment>
  )
}

export default SettingsIndex
