import React from 'react'

import { cn } from 'utils/utils'

import { Tooltip, TooltipContent, TooltipTrigger } from 'components/ui/tooltip'

interface Props {
  query: string
  queryLimit: number
}

const QueryLimitTooltip = ({ query, queryLimit }: Props) => {
  const isMaxQueryLength = query.length >= queryLimit * 0.9
  return (
    <Tooltip>
      <TooltipTrigger
        className={cn('pointer-events-none', {
          'pointer-events-auto': isMaxQueryLength,
        })}
      >
        <div
          className={cn(
            'mr-0.5 rounded-t bg-primary p-1 pr-0.5 text-xs leading-none text-muted opacity-0',
            {
              'text-destructive': query.length > queryLimit,
              'opacity-100': isMaxQueryLength,
            }
          )}
        >
          {query.length} / {queryLimit}
        </div>
      </TooltipTrigger>
      <TooltipContent>
        {query.length > queryLimit
          ? 'Maximum query length exceeded'
          : 'Maximum query length'}
      </TooltipContent>
    </Tooltip>
  )
}

export default QueryLimitTooltip
