import React from 'react'

import { DiligenceSection } from 'openapi/models/DiligenceSection'

import { useDiligenceStore } from 'components/workflows/workflow/discovery/diligence-store'

import { ManySectionsWithPDF } from './many-sections-with-pdf'

export const ReportsBox: React.FC = () => {
  const sections = useDiligenceStore((state) => state.sections)
  const selectedTaxonomies = useDiligenceStore(
    (state) => state.selectedTaxonomies
  )

  const numSelectedSections = selectedTaxonomies.length

  const progress = (sectionsWithAnswers: DiligenceSection[]) => {
    const numCompletedSections = sectionsWithAnswers.reduce((acc, section) => {
      return acc + (section.isLoading ? 0 : 1)
    }, 0)

    return Math.max((numCompletedSections / numSelectedSections) * 100, 15)
  }

  return (
    <ManySectionsWithPDF
      sectionsWithAnswers={sections}
      progress={progress(sections)}
    />
  )
}
