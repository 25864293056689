import _ from 'lodash'

import { UserInfo } from 'models/user-info'

export const HistoryGroupingSearchParamKey = 'group'

export enum HistoryGrouping {
  KIND = 'kind',
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  DOCUMENT_NUMBER = 'document_number',
  CLIENT_MATTER = 'client_matter',
}

interface HistoryGroupingDefinition {
  label: string
  value: HistoryGrouping
  available: boolean
}

export const HistoryGroupingDefinitions = (
  userInfo: UserInfo
): HistoryGroupingDefinition[] => {
  return [
    {
      label: 'Task type',
      value: HistoryGrouping.KIND,
      available: true,
    },
    {
      label: 'Day',
      value: HistoryGrouping.DAY,
      available: true,
    },
    {
      label: 'Week',
      value: HistoryGrouping.WEEK,
      available: true,
    },
    {
      label: 'Month',
      value: HistoryGrouping.MONTH,
      available: true,
    },
    {
      label: 'Document count',
      value: HistoryGrouping.DOCUMENT_NUMBER,
      available: userInfo.isDocumentUser || false,
    },
    {
      label: 'Client matter',
      value: HistoryGrouping.CLIENT_MATTER,
      available: userInfo.isClientMattersReadUser || false,
    },
  ]
}

export const HistoryGroupingDefsAvailable = (
  userInfo: UserInfo
): HistoryGroupingDefinition[] => {
  return HistoryGroupingDefinitions(userInfo).filter(
    (groupingDef) => groupingDef.available
  )
}

export const HistoryGroupingLookup = (
  historyGroupingDefs: HistoryGroupingDefinition[]
): Record<string, string> => {
  return historyGroupingDefs.reduce((acc: Record<string, string>, cur) => {
    if (!_.isNil(cur.value)) {
      acc[cur.value] = cur.label
    }
    return acc
  }, {})
}
