import React from 'react'

import _ from 'lodash'
import pluralize from 'pluralize'

import { cn } from 'utils/utils'

import { Alert } from 'components/ui/alert'
import { Badge } from 'components/ui/badge'
import { Button } from 'components/ui/button'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogTitle,
} from 'components/ui/dialog'
import { ScrollArea } from 'components/ui/scroll-area'

interface VaultUserAddDialogProps {
  showConfirmationDialog: boolean
  setShowConfirmationDialog: (show: boolean) => void
  usersToAdd: string[]
  mismatchDomainUsers: string[]
  invalidEmails: string[]
  duplicateEmails: string[]
  handleAddUserSubmit: () => void
}

const VaultUserAddDialog = ({
  showConfirmationDialog,
  setShowConfirmationDialog,
  usersToAdd,
  mismatchDomainUsers,
  invalidEmails,
  duplicateEmails,
  handleAddUserSubmit,
}: VaultUserAddDialogProps) => {
  const dialogUsersToAdd = usersToAdd.length
  return (
    <Dialog
      open={showConfirmationDialog}
      onOpenChange={() => setShowConfirmationDialog(!showConfirmationDialog)}
    >
      <DialogContent>
        <DialogTitle>
          {!_.isEmpty(usersToAdd)
            ? `Grant ${dialogUsersToAdd} ${pluralize(
                'users',
                dialogUsersToAdd
              )} vault add-on seats`
            : `No users to add`}
        </DialogTitle>
        <DialogDescription>
          {usersToAdd.length > 0 && (
            <Alert className="mt-4 p-3" variant="success">
              <div>
                <p>Users to add:</p>
                <ScrollArea className="max-h-64">
                  <div className="max-h-64">
                    {usersToAdd.map((email, idx) => (
                      <Badge key={idx} variant="outline" className="mr-1 mt-1">
                        {email}
                      </Badge>
                    ))}
                  </div>
                </ScrollArea>
              </div>
            </Alert>
          )}
          {duplicateEmails.length > 0 && (
            <Alert className="mt-4 p-3" variant="warning">
              {duplicateEmails.length > 0 && (
                <div>
                  <p>Duplicate users:</p>
                  <ScrollArea className="max-h-48">
                    <div className="max-h-48">
                      {duplicateEmails.map((email, idx) => (
                        <Badge
                          key={idx}
                          variant="outline"
                          className="mr-1 mt-1"
                        >
                          {email}
                        </Badge>
                      ))}
                    </div>
                  </ScrollArea>
                </div>
              )}
            </Alert>
          )}
          {mismatchDomainUsers.length + invalidEmails.length > 0 && (
            <Alert className="mt-4 p-3" variant="destructive">
              <div>
                {mismatchDomainUsers.length > 0 && (
                  <div>
                    <p>Mismatched domain users:</p>
                    <ScrollArea className="max-h-48">
                      <div className="max-h-48">
                        {mismatchDomainUsers.map((email, idx) => (
                          <Badge
                            key={idx}
                            variant="outline"
                            className="mr-1 mt-1"
                          >
                            {email}
                          </Badge>
                        ))}
                      </div>
                    </ScrollArea>
                  </div>
                )}
                {invalidEmails.length > 0 && (
                  <div
                    className={cn({
                      'mt-1.5': mismatchDomainUsers.length > 0,
                    })}
                  >
                    <p>Invalid users:</p>
                    <ScrollArea className="max-h-48">
                      <div className="max-h-48">
                        {invalidEmails.map((email, idx) => (
                          <Badge
                            key={idx}
                            variant="outline"
                            className="mr-1 mt-1"
                          >
                            {email}
                          </Badge>
                        ))}
                      </div>
                    </ScrollArea>
                  </div>
                )}
              </div>
            </Alert>
          )}
        </DialogDescription>
        <DialogFooter>
          <DialogClose asChild>
            <Button
              variant="outline"
              onClick={() => setShowConfirmationDialog(false)}
            >
              Cancel
            </Button>
          </DialogClose>
          <DialogClose asChild>
            <Button
              onClick={handleAddUserSubmit}
              disabled={dialogUsersToAdd === 0}
            >
              {`Add ${dialogUsersToAdd} ${pluralize(
                'users',
                dialogUsersToAdd
              )}`}
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default VaultUserAddDialog
