import React, { useEffect, useState } from 'react'

import {
  Form,
  List,
  Modal,
  Select,
  SelectProps,
  Typography,
  message,
} from 'antd'
import emailAddresses from 'email-addresses'
import _ from 'lodash'
import pluralize from 'pluralize'

import {
  BulkGrantPermsToUsers,
  BulkManagePermsUsersResult,
  BulkRevokePermsFromUsers,
  FetchAllPerms,
  Perm,
} from 'models/perms'
import {
  Permission,
  isInternalOnlyPerm,
  isRestrictedPerm,
  isSensitivePerm,
} from 'models/user-info'

import { useAuthUser } from 'components/common/auth-context'
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from 'components/ui/accordion'
import { Badge } from 'components/ui/badge'
import { Button } from 'components/ui/button'
import { Card } from 'components/ui/card'
import { Table, TableBody, TableCell, TableRow } from 'components/ui/table'

import MultiEmailTextarea from './multi-email-textarea'

interface SettingsBulkManagePermsProps {
  type: 'grant' | 'revoke'
}

const MAX_NUM_USERS = 1000

const SettingsBulkManagePerms = ({ type }: SettingsBulkManagePermsProps) => {
  const userInfo = useAuthUser()

  const [bulkPermsForm] = Form.useForm()
  const [allPerms, setAllPerms] = useState<{ [key: string]: Perm }>({})
  const [permOptions, setPermOptions] = useState<SelectProps['options']>([])
  const [invalidEmails, setInvalidEmails] = useState<string[]>([])
  const [permissionsExistEmails, setPermissionsExistEmails] = useState<
    string[]
  >([])
  const [permManagedEmails, setPermManagedEmails] = useState<string[]>([])
  const [emailTextArea, setEmailTextArea] = useState<string>('')
  const [emailsValid, setEmailsValid] = useState<boolean>(false)
  const [selectedPerms, setSelectedPerms] = useState<string[]>([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      const allPerms = await FetchAllPerms()
      const allPermsObj = _.keyBy(allPerms, (perm) => perm.permId)
      setAllPerms(allPermsObj)

      const options: SelectProps['options'] = Object.values(allPerms)
        .filter((p) => !isSensitivePerm(p.permId as Permission))
        .filter((p) => !isInternalOnlyPerm(p.permId as Permission))
        .filter((p) => !isRestrictedPerm(p.permId as Permission))
        .sort((a, b) => a.permId.localeCompare(b.permId))
        .map((perm) => ({
          label: `${perm.name} - ${perm.permId}`,
          value: perm.permId,
        }))
      setPermOptions(options)
    }
    fetchData()
  }, [])

  if (_.isNil(userInfo) || !userInfo.isUserManagement) return <></>

  const handleManageBulkPermissions = async (allPerms: {
    [key: string]: Perm
  }): Promise<void> => {
    const { emails, permissions } = await bulkPermsForm.validateFields()

    if (_.isEmpty(permissions)) {
      await message.error('Please select at least one permission')
      return
    }
    if (_.isEmpty(emails)) {
      await message.error('Please enter at least one email')
      return
    }

    const allEmails: string[] = emails
      .split('\n')
      .map((email: string) => email.trim().toLocaleLowerCase())
      .filter((email: string) => !_.isEmpty(email))
    const userEmails = _.uniq(allEmails)

    const [validEmails, invalidEmails] = _.partition(userEmails, (email) =>
      emailAddresses.parseOneAddress(email)
    )

    if (_.isEmpty(validEmails)) {
      setInvalidEmails(invalidEmails)
      await message.error('Please enter a valid email')
      return
    }

    Modal.confirm({
      title: `Are you sure you want to ${type} ${
        permissions.length
      } ${pluralize('permissions', permissions.length)} ${
        type === 'grant' ? 'to' : 'from'
      } ${validEmails.length} ${pluralize('users', validEmails.length)}?`,
      okText: `${_.startCase(type)} ${permissions.length} ${pluralize(
        'permissions',
        permissions.length
      )}`,
      okButtonProps: {
        style: {
          backgroundColor: type === 'grant' ? '#2463EB' : 'red',
          borderColor: type === 'grant' ? '#2463EB' : 'red',
        },
      },
      onOk: async () => {
        let response: BulkManagePermsUsersResult
        setLoading(true)
        if (type == 'grant') {
          response = await BulkGrantPermsToUsers(validEmails, permissions)
        } else {
          response = await BulkRevokePermsFromUsers(validEmails, permissions)
        }
        if (_.isEmpty(response)) {
          message.error(`Failed to ${type} permissions`)
        } else {
          const errorUsers = response?.errorUser
          invalidEmails.push(...errorUsers)
          if (
            _.isEmpty(response?.userManagedPerms) &&
            _.isEmpty(response?.userNoAction) &&
            !_.isEmpty(errorUsers)
          ) {
            message.error(`Failed to ${type} permissions`)
          } else {
            message.success(
              `Permissions ${
                type === 'grant' ? 'granted' : 'revoked'
              } successfully`
            )
          }
          setInvalidEmails(invalidEmails)
          setPermissionsExistEmails(response?.userNoAction)
          setPermManagedEmails(response?.userManagedPerms)
          bulkPermsForm.setFieldsValue({ emails: '' })
        }
        setLoading(false)
      },
      width: 800,
      content: (
        <div className="mt-4 text-sm font-medium text-primary">
          This will {type} the following permissions{' '}
          {type === 'grant' ? 'to' : 'from'} {validEmails.length}{' '}
          {pluralize('users', validEmails.length)}:
          <div className="w-xs mr-4">
            <Table className="my-4 border-spacing-1 border pr-4">
              <TableBody>
                {permissions.map((perm: Permission) => (
                  <TableRow key={perm}>
                    <TableCell className="mr-4 py-2 pl-4 pr-6">
                      {allPerms[perm]?.name}
                    </TableCell>
                    <TableCell className="max-w-sm p-1">
                      <Typography.Paragraph
                        className="mt-3 font-medium text-muted"
                        ellipsis={{ rows: 1, expandable: true, symbol: 'more' }}
                      >
                        {allPerms[perm]?.desc}
                      </Typography.Paragraph>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </div>
      ),
    })
  }

  const clearResultState = () => {
    setInvalidEmails([])
    setPermissionsExistEmails([])
    setPermManagedEmails([])
  }

  const isResultEmpty =
    _.isEmpty(invalidEmails) &&
    _.isEmpty(permissionsExistEmails) &&
    _.isEmpty(permManagedEmails)

  const isSubmitDisabled = !emailsValid || _.isEmpty(selectedPerms)

  const getTooltipText = () => {
    if (_.isEmpty(selectedPerms)) {
      return 'Please select at least one permission'
    } else if (_.isEmpty(emailTextArea)) {
      return 'Please enter at least one email'
    } else if (loading) {
      return type === 'grant'
        ? 'Granting permissions…'
        : 'Revoking permissions…'
    } else if (!emailsValid) {
      return `Maximum ${MAX_NUM_USERS} users allowed`
    } else {
      return ''
    }
  }

  return (
    <Accordion type="single" collapsible className="rounded-md border">
      <AccordionItem value={`bulk-${type}-perms`}>
        <AccordionTrigger className="p-4">
          <div className="flex items-center justify-between">
            <h2 className="text-lg font-semibold">
              Bulk {_.startCase(type)} Permissions
            </h2>
          </div>
        </AccordionTrigger>
        <AccordionContent>
          <div className="p-5 pb-0">
            <p className="text-sm font-medium text-primary">
              Bulk {type} permissions allows you to {type} permissions{' '}
              {type == 'grant'
                ? 'to multiple users at once. Users who already have a permission wont be re-granted the permission'
                : 'for multiple users at once. Users who don’t have the permission will be skipped. Workspace permissions will not be revoked.'}
            </p>
            <div className="mt-3">
              <Form form={bulkPermsForm} name="bulkPermsForm" layout="vertical">
                <Form.Item label="Permissions" name="permissions">
                  <Select
                    mode="multiple"
                    placeholder={`${
                      type == 'grant' ? 'Add' : 'Remove'
                    } user permissions`}
                    virtual={false}
                    style={{ width: '100%' }}
                    options={_.sortBy(permOptions, 'label')}
                    loading={_.isEmpty(permOptions)}
                    optionFilterProp="label"
                    onChange={(value) => setSelectedPerms(value)}
                  />
                </Form.Item>
                <Form.Item name="emails" label="Emails">
                  <MultiEmailTextarea
                    placeholder={`Enter user emails to ${type} perms to, one per line`}
                    value={emailTextArea}
                    onChange={setEmailTextArea}
                    maxNumUsers={MAX_NUM_USERS}
                    emailsValidCallback={(valid: boolean) =>
                      setEmailsValid(valid)
                    }
                  />
                </Form.Item>
                <Form.Item>
                  <div className="flex w-full">
                    {isResultEmpty ? (
                      <div className="flex w-full justify-end">
                        <Button
                          onClick={() => handleManageBulkPermissions(allPerms)}
                          disabled={isSubmitDisabled}
                          tooltip={getTooltipText()}
                          variant={type === 'grant' ? 'default' : 'destructive'}
                        >
                          {`${type == 'grant' ? 'Grant' : 'Revoke'} ${
                            selectedPerms.length > 0 ? selectedPerms.length : ''
                          } ${pluralize('permissions', selectedPerms.length)}`}
                        </Button>
                      </div>
                    ) : (
                      <div className="flex w-full items-center justify-between">
                        <div>
                          <p className="text-md font-semibold text-primary">
                            Bulk permission {type} results
                          </p>
                        </div>
                        <div>
                          <Button
                            variant="default"
                            className="float-right flex justify-end"
                            onClick={clearResultState}
                          >
                            Clear
                          </Button>
                        </div>
                      </div>
                    )}
                  </div>
                </Form.Item>
              </Form>
            </div>
          </div>
          {!isResultEmpty && (
            <div className="p-4 pt-0">
              <Card title="Result">
                <List>
                  <List.Item>
                    <div className="flex items-center space-x-2">
                      <p className="text  pl-4 text-sm font-medium">
                        Permission {type} emails:
                      </p>
                      <div className="space-x-0.5 space-y-0.5">
                        {permManagedEmails.length
                          ? permManagedEmails.map((email, i) => (
                              <Badge key={i}>{email}</Badge>
                            ))
                          : '-'}
                      </div>
                    </div>
                  </List.Item>
                  <List.Item>
                    <div className="flex items-center space-x-2">
                      <p className="text pl-4 text-sm font-medium">
                        Permissions{' '}
                        {type === 'grant' ? 'exists' : 'doesn’t exist'} emails:
                      </p>
                      <div className="space-x-0.5 space-y-0.5">
                        {permissionsExistEmails.length
                          ? permissionsExistEmails.map((email, i) => (
                              <Badge variant="secondary" key={i}>
                                {email}
                              </Badge>
                            ))
                          : '-'}
                      </div>
                    </div>
                  </List.Item>
                  <List.Item>
                    <div className="flex items-center space-x-2">
                      <p className="pl-4 text-sm font-medium text-primary">
                        Invalid emails:
                      </p>
                      <div className="space-x-0.5 space-y-0.5">
                        {invalidEmails.length
                          ? invalidEmails.map((email, i) => (
                              <Badge variant="destructive" key={i}>
                                {email}
                              </Badge>
                            ))
                          : '-'}
                      </div>
                    </div>
                  </List.Item>
                </List>
              </Card>
            </div>
          )}
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  )
}

export default SettingsBulkManagePerms
