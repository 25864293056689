import React from 'react'

import { ChevronDown, ChevronUp, X } from 'lucide-react'

import { Button } from 'components/ui/button'
import Icon from 'components/ui/icon/icon'

export const FileControls = ({
  currentFileIndex,
  fileIds,
  previousFileId,
  nextFileId,
  onNavigateToFile,
  onExit,
}: {
  currentFileIndex: number | undefined
  fileIds: string[]
  previousFileId: string | undefined
  nextFileId: string | undefined
  onNavigateToFile: (fileId: string) => void
  onExit: () => void
}) => {
  return (
    <>
      {fileIds.length > 1 && currentFileIndex !== undefined && (
        <>
          <p className="shrink-0 text-xs">
            {currentFileIndex + 1}
            <span className="text-muted"> / {fileIds.length}</span>
          </p>
          <Button
            size="xsIcon"
            variant="outline"
            className="shrink-0"
            onClick={() => {
              onNavigateToFile(previousFileId!)
            }}
            disabled={!previousFileId}
          >
            <Icon icon={ChevronUp} />
            <span className="sr-only">Previous file</span>
          </Button>
          <Button
            size="xsIcon"
            variant="outline"
            className="shrink-0"
            onClick={() => {
              onNavigateToFile(nextFileId!)
            }}
            disabled={!nextFileId}
          >
            <Icon icon={ChevronDown} />
            <span className="sr-only">Next file</span>
          </Button>
        </>
      )}
      <Button
        size="xsIcon"
        variant="ghost"
        className="shrink-0"
        onClick={onExit}
      >
        <Icon icon={X} />
        <span className="sr-only">Close</span>
      </Button>
    </>
  )
}
