import React from 'react'
import { useParams } from 'react-router-dom'
import { useMount } from 'react-use'

import {
  Coins,
  Euro,
  Landmark,
  ScaleIcon,
  NotebookText,
  Shell,
  Loader2Icon,
  Mountain,
  Link,
} from 'lucide-react'

import { useResearchTaxonomyQuery } from 'models/queries/use-research-taxonomy-query'
import { ResearchArea } from 'openapi/models/ResearchArea'
import { TaxCorpusFilterIDs } from 'openapi/models/TaxCorpusFilterIDs'

import { AppHeader } from 'components/common/app-header'
import { AppMain } from 'components/common/app-main'
import { ResearchAreaCard } from 'components/research/research-area-card'
import { useResearchStore } from 'components/research/research-store'
import { SkeletonBlock } from 'components/ui/skeleton'

const ResearchIndexPage: React.FC = () => {
  const resetState = useResearchStore((s) => s.resetState)
  const { area } = useParams()
  const { isLoading, taxonomy } = useResearchTaxonomyQuery(area as ResearchArea)

  useMount(() => {
    resetState()
  })

  const taxIds = Object.values(TaxCorpusFilterIDs)

  const hasFeature = (featureId: string) => {
    return (
      !isLoading &&
      Array.isArray(taxonomy) &&
      taxonomy.some(({ id }) => id.includes(featureId))
    )
  }
  const hasTax = taxIds.some((id) => hasFeature(id))
  const hasSec = hasFeature('sec_edgar')
  const hasMemos = hasFeature('memos')
  const hasEURLex = hasFeature('eurlex')
  const hasUsaCaselaw = hasFeature('usa_caselaw')
  const hasFranceCaselaw = hasFeature('france_caselaw')
  const hasAusBreachReporting = hasFeature('aus_breach_reporting')
  const hasCuatrecasas = hasFeature(ResearchArea.CUATRECASAS)
  const hasFromCounsel = hasFeature(ResearchArea.FROMCOUNSEL)

  return (
    <AppMain hasContainer>
      <AppHeader
        title="Research"
        subtitle="Explore trusted information sources across domains"
      />

      <div
        className="grid grid-cols-1 gap-4 pb-4 md:grid-cols-2 xl:grid-cols-4"
        id="research-index-container"
        data-testid="research-container"
      >
        {isLoading &&
          [...new Array(3)].map((_, i) => (
            <ResearchAreaCard
              key={i}
              isLoading
              title={<SkeletonBlock className="h-6 w-1/2" hasDarkBackground />}
              description={
                <div className="mt-4 space-y-2">
                  <SkeletonBlock className="h-4" hasDarkBackground />
                  <SkeletonBlock className="h-4 w-3/4" hasDarkBackground />
                </div>
              }
              icon={Loader2Icon}
            />
          ))}
        {hasTax && (
          <ResearchAreaCard
            title="Tax"
            description="Tax AI Assistant provides comprehensive answers backed by trusted verified sources on global tax laws & guidance."
            area={ResearchArea.TAX}
            icon={Coins}
          />
        )}
        {hasSec && (
          <ResearchAreaCard
            title="EDGAR"
            description="Key recurring and event-based filings by public companies."
            area={ResearchArea.EDGAR}
            icon={Landmark}
          />
        )}
        {hasEURLex && (
          <ResearchAreaCard
            title="EUR-Lex"
            description="Cases from the Court of Justice of the European Union."
            area={ResearchArea.EURLEX}
            icon={Euro}
          />
        )}
        {hasUsaCaselaw && (
          <ResearchAreaCard
            title="US Case Law"
            description="Federal and state case law from all 50 states."
            area={ResearchArea.USCASELAW}
            icon={ScaleIcon}
          />
        )}
        {hasFranceCaselaw && (
          <ResearchAreaCard
            title="French Case Law"
            description="Case law from French civil, administrative, and constitutional courts."
            area={ResearchArea.FRANCECASELAW}
            icon={ScaleIcon}
          />
        )}
        {hasAusBreachReporting && (
          <ResearchAreaCard
            title="Australia Breach Reporting"
            description="Australian breach reporting requirements."
            area={ResearchArea.AUSBREACHREPORTING}
            isPendingRelease={!hasAusBreachReporting}
            // TODO: What should this be?
            icon={Shell}
          />
        )}
        {hasMemos && (
          <ResearchAreaCard
            title="Memos"
            description="Comprehensive databases of law firm memos."
            area={ResearchArea.MEMOS}
            icon={NotebookText}
          />
        )}
        {hasCuatrecasas && (
          <ResearchAreaCard
            title="Cuatrecasas"
            description="Cuatrecasas Knowledge Base Tool"
            area={ResearchArea.CUATRECASAS}
            icon={Mountain}
          />
        )}
        {hasFromCounsel && (
          <ResearchAreaCard
            title="From Counsel"
            description="From Counsel external knowledge tool"
            area={ResearchArea.FROMCOUNSEL}
            icon={Link}
          />
        )}
      </div>
    </AppMain>
  )
}

export default ResearchIndexPage
