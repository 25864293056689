import { useState, useCallback } from 'react'
import { DateRange } from 'react-day-picker'
import { useMount } from 'react-use'

import { parse } from 'date-fns'
import _ from 'lodash'
import { useAbortController } from 'providers/abort-controller-provider'

import {
  getDisplayStringForProductLabel,
  FetchUsageDataRaw,
  FetchUsageMetadata,
  UsageData,
  UsageDataRaw,
  UsageDataTimeseriesEntry,
} from 'models/usage-data'
import { Workspace } from 'models/workspace'
import { ProductLabel } from 'openapi/models/ProductLabel'
import { TimeInterval } from 'openapi/models/TimeInterval'

import { getOneMonthPrior } from 'utils/date-utils'
import { displayErrorMessage } from 'utils/toast'
import { HARVEY_START_DATE } from 'utils/utils'

import { useAuthUser } from 'components/common/auth-context'

interface UseUsageDataProps {
  workspace: Workspace
  earliestDate?: Date
}

const PRIORITY_PRODUCT_LABELS: Set<string> = new Set([
  ProductLabel.ASSIST_NO_UPLOADED_FILES.valueOf(),
  ProductLabel.ASSIST_USER_UPLOADED_FILES.valueOf(),
  ProductLabel.DRAFT_NO_UPLOADED_FILES.valueOf(),
  ProductLabel.DRAFT_USER_UPLOADED_FILES.valueOf(),
])

const getParsedDate = (timeInterval: TimeInterval, timestamp: string): Date => {
  switch (timeInterval) {
    case TimeInterval.DAY:
      return parse(timestamp, 'yyyy-MM-dd', new Date())
    case TimeInterval.MONTH:
      return parse(timestamp, 'yyyy-MM', new Date(0, 0, 1))
    default:
      throw new Error(`Invalid time interval: ${timeInterval}`)
  }
}

const getParsedDateShortened = (
  timeInterval: TimeInterval,
  parsedDate: Date
): string => {
  switch (timeInterval) {
    case TimeInterval.DAY:
      return new Intl.DateTimeFormat(navigator.language, {
        month: 'short',
        day: 'numeric',
      }).format(parsedDate)
    case TimeInterval.MONTH:
      return new Intl.DateTimeFormat(navigator.language, {
        month: 'short',
      }).format(parsedDate)
    default:
      throw new Error(`Invalid time interval: ${timeInterval}`)
  }
}

const getParsedDateString = (
  timeInterval: TimeInterval,
  parsedDate: Date
): string => {
  switch (timeInterval) {
    case TimeInterval.DAY:
      return new Intl.DateTimeFormat(navigator.language, {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      }).format(parsedDate)
    case TimeInterval.MONTH:
      return new Intl.DateTimeFormat(navigator.language, {
        year: 'numeric',
        month: 'long',
      }).format(parsedDate)
    default:
      throw new Error(`Invalid time interval: ${timeInterval}`)
  }
}

const processUsageDataRaw = (usageDataRaw: UsageDataRaw): UsageData => {
  const processedTimeseriesMap: {
    [key in TimeInterval]: UsageDataTimeseriesEntry[]
  } = {
    [TimeInterval.DAY]: [],
    [TimeInterval.MONTH]: [],
  }
  // preserve the order for graph (in case we don't sort again)
  Object.entries(usageDataRaw.timeseries).forEach(
    ([timeInterval, timeseriesForInterval]) => {
      const processedTimeseriesForInterval = timeseriesForInterval.map(
        (timeseries) => {
          let parsedDateShortened: string // e.g. Jan 1
          let parsedDateString: string // e.g. January 1, 2024

          try {
            // we need to ensure the date string is treated as local timezone, not UTC
            const parsedDate: Date = getParsedDate(
              timeInterval as TimeInterval,
              timeseries.timestamp
            )
            parsedDateShortened = getParsedDateShortened(
              timeInterval as TimeInterval,
              parsedDate
            )
            parsedDateString = getParsedDateString(
              timeInterval as TimeInterval,
              parsedDate
            )
          } catch (error) {
            console.error('Failed to parse date', timeseries.timestamp)
            parsedDateShortened = timeseries.timestamp
            parsedDateString = timeseries.timestamp
          }

          return {
            ...timeseries,
            queryCountByType: Object.fromEntries(
              Object.entries(timeseries.queryCountByType).map(
                ([key, value]) => [getDisplayStringForProductLabel(key), value]
              )
            ),
            userCountByType: Object.fromEntries(
              Object.entries(timeseries.userCountByType).map(([key, value]) => [
                getDisplayStringForProductLabel(key),
                value,
              ])
            ),
            parsedDateShortened: parsedDateShortened,
            parsedDateString: parsedDateString,
          }
        }
      )
      processedTimeseriesMap[timeInterval as TimeInterval] =
        processedTimeseriesForInterval
    }
  )

  return {
    distinctTypes: usageDataRaw.distinctTypes
      .map(getDisplayStringForProductLabel)
      .sort(),
    aggregate: {
      ...usageDataRaw.aggregate,
      userCountByType: Object.fromEntries(
        Object.entries(usageDataRaw.aggregate.userCountByType).map(
          ([key, value]) => [getDisplayStringForProductLabel(key), value]
        )
      ),
      queryCountByType: Object.fromEntries(
        Object.entries(usageDataRaw.aggregate.queryCountByType).map(
          ([key, value]) => [getDisplayStringForProductLabel(key), value]
        )
      ),
    },
    timeseriesMap: processedTimeseriesMap,
    userStats: Object.entries(usageDataRaw.userStats).map(
      ([userEmail, productTypeStats]) => ({
        userEmail,
        ...Object.fromEntries(
          Object.entries(productTypeStats).map(([key, value]) => [
            getDisplayStringForProductLabel(key),
            value,
          ])
        ),
      })
    ),
  }
}

const useUsageData = ({ workspace, earliestDate }: UseUsageDataProps) => {
  const [selectedDateRange, setSelectedDateRange] = useState<
    DateRange | undefined
  >({
    from: getOneMonthPrior(),
    to: new Date(),
  })
  const [selectedProductTypes, setSelectedProductTypes] = useState<string[]>([])

  // this is what powers multi-select dropdown, and is passed to backend
  // needs to be ProductLabel strings
  const [productTypeOptions, setProductTypeOptions] = useState<string[]>([])

  const authUser = useAuthUser()

  const [isLoadingUsageData, setIsLoadingUsageData] = useState(true)
  const [usageData, setUsageData] = useState<UsageData>()

  const ABORT_CONTROLLER_KEY_FETCH_USAGE_DATA = 'fetchUsageData'
  const { getAbortController, addAbortController, removeAbortController } =
    useAbortController()

  const fetchUsageMetadata = useCallback(async () => {
    const usageMetadata = await FetchUsageMetadata(workspace.slug)
    const sortedTypes = _.orderBy(
      usageMetadata.distinctTypes,
      [
        (type: string) => PRIORITY_PRODUCT_LABELS.has(type),
        (type: string) => type,
      ],
      ['desc', 'asc']
    )
    setProductTypeOptions(sortedTypes)
    return sortedTypes
  }, [workspace])

  const fetchUsageData = useCallback(
    async (dateRange: DateRange | undefined, productTypes: string[]) => {
      // NOTE: need to prevent BE call if user doesn't have perms for v2 dashboard
      if (!authUser.IsUsageDashboardV2Viewer) {
        return
      }

      setIsLoadingUsageData(true)

      try {
        // all in user's local timezone
        const createdAfterOrEqual =
          dateRange?.from ?? earliestDate ?? HARVEY_START_DATE
        createdAfterOrEqual.setHours(0, 0, 0, 0)

        // set to end of day if from is set by user, otherwise set to all possible times
        const createdBeforeOrEqual =
          dateRange?.to ??
          (dateRange?.from ? new Date(createdAfterOrEqual) : new Date())
        createdBeforeOrEqual.setHours(23, 59, 59)

        const oldAbortController = getAbortController(
          ABORT_CONTROLLER_KEY_FETCH_USAGE_DATA
        )
        if (oldAbortController) {
          oldAbortController.abort()
          removeAbortController(ABORT_CONTROLLER_KEY_FETCH_USAGE_DATA)
        }

        const abortController = new AbortController()
        addAbortController(
          ABORT_CONTROLLER_KEY_FETCH_USAGE_DATA,
          abortController
        )

        const usageDataRaw = await FetchUsageDataRaw(
          {
            workspaceSlug: workspace.slug,
            createdAfterOrEqual,
            createdBeforeOrEqual,
            userTz: Intl.DateTimeFormat().resolvedOptions().timeZone,
            selectedProductTypes: productTypes,
          },
          abortController
        )

        const usageData = processUsageDataRaw(usageDataRaw)
        setUsageData(usageData)
      } catch (error: any) {
        if (error.name !== 'AbortError') {
          console.error('Failed to fetch usage data', error)
          displayErrorMessage('Failed to fetch usage data. Please try again.')
        }
      } finally {
        setIsLoadingUsageData(false)
      }
    },
    [
      workspace,
      earliestDate,
      authUser,
      getAbortController,
      addAbortController,
      removeAbortController,
    ]
  )

  useMount(async () => {
    // only fetch product type options once
    const sortedTypes = await fetchUsageMetadata()
    setSelectedProductTypes(sortedTypes)
    void fetchUsageData(selectedDateRange, sortedTypes)
  })

  // need function signature to match useState setter
  const updateSelectedDateRange = useCallback(
    (dateRange: DateRange | undefined) => {
      // if dateRange is undefined, user has cleared filter, so we should fetch all data
      setSelectedDateRange(dateRange)
      void fetchUsageData(dateRange, selectedProductTypes)
    },
    [fetchUsageData, selectedProductTypes]
  )

  const updateSelectedProductTypes = useCallback(
    (productTypes: string[]) => {
      setSelectedProductTypes(productTypes)
      void fetchUsageData(selectedDateRange, productTypes)
    },
    [fetchUsageData, selectedDateRange]
  )

  return {
    usageData,
    isLoadingUsageData,
    selectedDateRange,
    updateSelectedDateRange,
    productTypeOptions,
    selectedProductTypes,
    updateSelectedProductTypes,
  }
}

export default useUsageData
