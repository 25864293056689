import React from 'react'

const VaultSectionBreadcrumb = ({
  title,
  trailingActions,
}: {
  title: React.ReactNode
  trailingActions?: React.ReactNode[]
}) => {
  return (
    <div className="flex items-center justify-between border-b py-2.5">
      {title}
      {trailingActions && (
        <div className="flex items-center">{trailingActions}</div>
      )}
    </div>
  )
}

export default VaultSectionBreadcrumb
