/* tslint:disable */
/* eslint-disable */
/**
 * PwC Deals API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { UploadedFile } from './UploadedFile';
import {
    UploadedFileFromJSON,
    UploadedFileFromJSONTyped,
    UploadedFileToJSON,
    UploadedFileToJSONTyped,
} from './UploadedFile';

/**
 * 
 * @export
 * @interface DiligenceDocument
 */
export interface DiligenceDocument {
    /**
     * 
     * @type {UploadedFile}
     * @memberof DiligenceDocument
     */
    file: UploadedFile;
    /**
     * 
     * @type {boolean}
     * @memberof DiligenceDocument
     */
    isLoading: boolean;
}

/**
 * Check if a given object implements the DiligenceDocument interface.
 */
export function instanceOfDiligenceDocument(value: object): value is DiligenceDocument {
    if (!('file' in value) || value['file'] === undefined) return false;
    if (!('isLoading' in value) || value['isLoading'] === undefined) return false;
    return true;
}

export function DiligenceDocumentFromJSON(json: any): DiligenceDocument {
    return DiligenceDocumentFromJSONTyped(json, false);
}

export function DiligenceDocumentFromJSONTyped(json: any, ignoreDiscriminator: boolean): DiligenceDocument {
    if (json == null) {
        return json;
    }
    return {
        
        'file': UploadedFileFromJSON(json['file']),
        'isLoading': json['is_loading'],
    };
}

  export function DiligenceDocumentToJSON(json: any): DiligenceDocument {
      return DiligenceDocumentToJSONTyped(json, false);
  }

  export function DiligenceDocumentToJSONTyped(value?: DiligenceDocument | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'file': UploadedFileToJSON(value['file']),
        'is_loading': value['isLoading'],
    };
}

