import {
  InfiniteData,
  QueryClient,
  useQueryClient,
} from '@tanstack/react-query'

import { Event } from 'models/event'
import { makeHistoryUrl } from 'models/fetchers/history-fetcher'
import { FetchHistoryArgs, HistoryStore } from 'models/helpers/history-helper'
import { AdditionalQueryOptions } from 'models/queries/lib'
import { usePollingAndOlderQuery } from 'models/queries/lib'
import Services from 'services'

import { getOneYearPrior } from 'utils/date-utils'
import { addQueryParamsToRequestPath } from 'utils/routing'

import { HarvQueryKeyPrefix } from './all-query-keys'
import { prefetchInfiniteWrapper } from './lib/prefetch-wrapper'
import { defaultRetry } from './lib/use-wrapped-query'

const idFunc = (item: Event) => item.id
const getArrayFromResults = (results: { events: Event[] }) => results.events

interface HistoryPollingParams {
  fetchHistoryArgs: FetchHistoryArgs
  oldestDate?: Date
  batchSize?: number
  queryOptions?: AdditionalQueryOptions<{ pages: Event[][] }>
  retentionPolicyInSeconds?: number
  updateLastUpdatedTime?: () => void
}

const useHistoryPollingQuery = ({
  fetchHistoryArgs,
  oldestDate = getOneYearPrior(),
  queryOptions,
  retentionPolicyInSeconds,
  updateLastUpdatedTime,
}: HistoryPollingParams) => {
  const url = makeHistoryUrl(fetchHistoryArgs)
  const queryResult = usePollingAndOlderQuery({
    cacheKeyPrefix: HarvQueryKeyPrefix.HistoryPollingQuery,
    url: url,
    getArrayFromResults: getArrayFromResults,
    oldestDateRequested: oldestDate,
    createdKey: 'created',
    modifiedKey: 'updatedAt',
    modifiedAfterKey: 'updated_after_exclusive',
    deletedKey: 'deletedAt',
    idFunc: idFunc,
    queryOptions: queryOptions,
    pollTimeInSeconds: 60, // 60 seconds default
    batchSize: fetchHistoryArgs.pageSize,
    retentionPolicyInSeconds: retentionPolicyInSeconds ?? Infinity,
    updateLastUpdatedTime,
  })

  return queryResult
}

const useHistoryPollingQuery_Prefetch = (
  fetchHistoryArgs: FetchHistoryArgs,
  isEnabled: boolean = true,
  updateLastUpdatedTime: () => void
) => {
  const queryClient = useQueryClient()
  const url = makeHistoryUrl(fetchHistoryArgs)
  const searchParamsToAdd = new URLSearchParams({
    page_size: fetchHistoryArgs.pageSize.toString(),
  })
  const requestPath = addQueryParamsToRequestPath(url, searchParamsToAdd)
  const queryKey = [HarvQueryKeyPrefix.HistoryPollingQuery, requestPath]
  const queryFn = async () =>
    getArrayFromResults(
      await Services.Backend.Get<HistoryStore>(requestPath, {
        throwOnError: true,
      })
    )

  // The results of this query will be cached like a normal query
  // The difference is they will not refetch on each render, obey the stale time, etc. it is just a one time prefetch
  if (isEnabled) {
    prefetchInfiniteWrapper({
      queryClient,
      fetchInfiniteQueryOptions: {
        queryKey: queryKey,
        queryFn: queryFn,
        initialPageParam: 0,
        retry: defaultRetry,
      },
      onlyIfEmpty: true,
      updateLastUpdatedTime,
    })
  }
}

export const getHistoryPollingQueryData = (
  queryClient: QueryClient,
  fetchHistoryArgs: FetchHistoryArgs
): Event[] => {
  const url = makeHistoryUrl(fetchHistoryArgs)
  const searchParamsToAdd = new URLSearchParams({
    page_size: fetchHistoryArgs.pageSize.toString(),
  })
  const requestPath = addQueryParamsToRequestPath(url, searchParamsToAdd)
  const queryKey = [HarvQueryKeyPrefix.HistoryPollingQuery, requestPath]
  return (
    (
      queryClient.getQueryData(queryKey) as InfiniteData<Event[]> | undefined
    )?.pages.flatMap((page) => page) ?? []
  )
}

// export
export { useHistoryPollingQuery, useHistoryPollingQuery_Prefetch }
