import React from 'react'

import { useShallow } from 'zustand/react/shallow'

import { Button } from 'components/ui/button'
import { useVaultStore } from 'components/vault/utils/vault-store'

const VaultAssistantButton = () => {
  const setOpenVaultAssistantModal = useVaultStore(
    useShallow((s) => s.setIsVaultAssistantModalOpen)
  )
  const openInAssistantHandler = () => {
    setOpenVaultAssistantModal(true)
  }

  return (
    <Button variant="outline" onClick={openInAssistantHandler}>
      <p>Open in Assistant</p>
    </Button>
  )
}

export default VaultAssistantButton
