import { ColumnDataType } from 'components/vault/utils/vault'

import { ClassifyColumnFilter } from './classify-column-filter'
import { CurrencyColumnFilter } from './currency-column-filter'
import { DateColumnFilter } from './date-column-filter'
import { DurationColumnFilter } from './duration-column-filter'
import { NumericColumnFilter } from './numeric-column-filter'
import { TextColumnFilter } from './text-column-filter'

const textColumnFilter = new TextColumnFilter()
const dateColumnFilter = new DateColumnFilter()
const classifyColumnFilter = new ClassifyColumnFilter()
const durationColumnFilter = new DurationColumnFilter()
const numericColumnFilter = new NumericColumnFilter()
const currencyColumnFilter = new CurrencyColumnFilter()

export const columnFilterMap = {
  [ColumnDataType.freeResponse]: textColumnFilter,
  [ColumnDataType.date]: dateColumnFilter,
  [ColumnDataType.duration]: durationColumnFilter,
  [ColumnDataType.numeric]: numericColumnFilter,
  [ColumnDataType.currency]: currencyColumnFilter,

  // Default all other column types to text filter
  [ColumnDataType.classify]: classifyColumnFilter,
  [ColumnDataType.extraction]: textColumnFilter,
  [ColumnDataType.compoundResponse]: textColumnFilter,
  [ColumnDataType.list]: textColumnFilter,
  [ColumnDataType.empty]: textColumnFilter,
  [ColumnDataType.noFormat]: textColumnFilter,
  [ColumnDataType.string]: textColumnFilter,
  [ColumnDataType.binary]: textColumnFilter,
}
