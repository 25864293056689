import React, { useState } from 'react'

import { Table } from 'antd'
import _ from 'lodash'
import pluralize from 'pluralize'
import { useShallow } from 'zustand/react/shallow'

import {
  BulkDeleteUsersResult,
  RawUser,
  ClientAdminDeleteUsers,
} from 'models/users'
import { Workspace } from 'models/workspace'
import { useClientAdminStore } from 'stores/client-admin-store'

import { getTableDateString } from 'utils/date-utils'
import { PWD_AUTH_CONN } from 'utils/routing'
import { displaySuccessMessage } from 'utils/toast'
import { cn } from 'utils/utils'

import { useSettingsState } from 'components/settings/settings-store'
import { Badge } from 'components/ui/badge'
import { Button } from 'components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogTrigger,
} from 'components/ui/dialog'
import { Spinner } from 'components/ui/spinner'

import './workspace-users-table.css'

interface UserInfoTableProps {
  users: RawUser[]
  workspace: Workspace
}

const UserDeletionModal = ({
  usersToDelete,
  deletionAction,
  postDeleteAction,
}: {
  usersToDelete: string[]
  deletionAction: () => Promise<void>
  postDeleteAction: () => void
}) => {
  const deleteIsDisabled = usersToDelete.length == 0
  const deleteButtonCopy = deleteIsDisabled
    ? 'Delete selected users'
    : `Delete ${usersToDelete.length} ${pluralize(
        'user',
        usersToDelete.length
      )}`
  const [isOpen, setOpen] = useState(false)
  const [isDeleting, setDeleting] = useState(false)

  const onDeleteSubmit = async () => {
    setDeleting(true)
    await deletionAction()
    setOpen(false)
    setDeleting(false)
    postDeleteAction()
  }

  const closeOrOpen = () => setOpen(!isOpen)

  return (
    <Dialog open={isOpen}>
      <DialogTrigger asChild>
        <Button
          disabled={deleteIsDisabled}
          type="submit"
          variant="destructive"
          onClick={closeOrOpen}
        >
          {deleteButtonCopy}
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-max" showCloseIcon={false}>
        <DialogTitle>Delete users</DialogTitle>
        <div className="mt-4">
          <div>
            The following {usersToDelete.length}{' '}
            {pluralize('user', usersToDelete.length)} will be deleted from
            Harvey:
          </div>
          <div className="mt-1 flex flex-wrap gap-1">
            {usersToDelete.map((email) => (
              <span key={email}>
                <Badge variant="outline">{email}</Badge>
              </span>
            ))}
          </div>
          <div className="mt-6 flex justify-end space-x-2">
            <Button variant="ghost" onClick={closeOrOpen}>
              Cancel
            </Button>
            <Button
              variant="destructive"
              disabled={isDeleting}
              onClick={onDeleteSubmit}
              className="*:text-sm"
            >
              {isDeleting ? (
                <div className="flex items-center">
                  <Spinner className="mr-2 h-3 w-3" />
                  Deleting…
                </div>
              ) : (
                <span>Delete</span>
              )}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}

const WorkspaceUsersTable: React.FC<UserInfoTableProps> = ({
  users,
  workspace,
}) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
  const settingsUser = useSettingsState((s) => s.settingsUser)
  const [fetchUsers] = useClientAdminStore(useShallow((s) => [s.fetchUsers]))
  const canDeleteUser =
    settingsUser?.IsClientAdminRemoveUsers &&
    (settingsUser.workspace.authConn === PWD_AUTH_CONN ||
      settingsUser.workspace.blockNonProvisionedSamlUsers)
  // get all Screens as Columns
  const displayUsers = _.orderBy(
    users,
    [(user) => (user.deletedAt ? 1 : 0), 'email'],
    ['asc', 'asc']
  )
    .map((x) => ({
      ...x,
      ...(x.settings?.welcomeScreens
        ? {
            interstitial: (
              Object.values(x.settings.welcomeScreens)[0] as string
            ).toLowerCase(),
          }
        : {}),
    }))
    .filter((x) => _.isNil(x.deletedAt))

  const fetchUserData = async () => {
    if (_.isNil(workspace)) {
      return
    }
    await fetchUsers(workspace.id)
  }

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys)
  }

  const onRowSelection = canDeleteUser
    ? {
        selectedRowKeys,
        onChange: onSelectChange,
      }
    : undefined

  const deletionAction = async () => {
    const emails = selectedRowKeys as string[]
    const result: BulkDeleteUsersResult = await ClientAdminDeleteUsers(
      workspace.id,
      emails
    )
    if (_.isNil(result)) {
      return
    } else {
      const { usersDeleted } = result
      if (!_.isEmpty(usersDeleted)) {
        displaySuccessMessage(
          `${usersDeleted.length} ${pluralize(
            'user',
            usersDeleted.length
          )} deleted`
        )
      }
    }
    await fetchUserData()
  }

  const postDeleteAction = () => {
    setTimeout(() => {
      setSelectedRowKeys([])
    }, 500)
  }

  return (
    <Table
      className="client-user-workspace-table"
      dataSource={displayUsers}
      bordered
      rowKey={(user: RawUser) => user.email}
      columns={[
        {
          title: 'Email',
          dataIndex: 'email',
          key: 'email',
        },
        {
          title: 'Created',
          dataIndex: 'createdAt',
          key: 'createdAt',
          width: 200,
          render: (createdAt: string) => {
            return createdAt ? getTableDateString(createdAt) : ''
          },
        },
        ...(workspace.hasInterstitial
          ? [
              {
                title: 'Terms popup',
                dataIndex: 'interstitial',
                key: 'interstitial',
                width: 200,
              },
            ]
          : []),
      ]}
      rowSelection={onRowSelection}
      footer={() => (
        <div
          className={cn('flex', {
            'items-center justify-between': canDeleteUser,
            'justify-end': !canDeleteUser,
          })}
        >
          {canDeleteUser && (
            <UserDeletionModal
              usersToDelete={selectedRowKeys as string[]}
              deletionAction={deletionAction}
              postDeleteAction={postDeleteAction}
            />
          )}
          <p>{users.length} users</p>
        </div>
      )}
    />
  )
}

export default WorkspaceUsersTable
