/* tslint:disable */
/* eslint-disable */
/**
 * Harvey Web App - User Permissions
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Enum for specifying the type of authentication.
 * @export
 */
export const AuthType = {
    USERNAME_PASSWORD_AUTHENTICATION: 'Username-Password-Authentication',
    EMAIL: 'email',
    SAML: 'saml'
} as const;
export type AuthType = typeof AuthType[keyof typeof AuthType];


export function instanceOfAuthType(value: any): boolean {
    for (const key in AuthType) {
        if (Object.prototype.hasOwnProperty.call(AuthType, key)) {
            if (AuthType[key as keyof typeof AuthType] === value) {
                return true;
            }
        }
    }
    return false;
}

export function AuthTypeFromJSON(json: any): AuthType {
    return AuthTypeFromJSONTyped(json, false);
}

export function AuthTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuthType {
    return json as AuthType;
}

export function AuthTypeToJSON(value?: AuthType | null): any {
    return value as any;
}

