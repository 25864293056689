import React, { useEffect, useMemo, useRef, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { useMount } from 'react-use'

import { isEmpty } from 'lodash'
import { useShallow } from 'zustand/react/shallow'

import { useHistoryItemQuery } from 'models/queries/use-history-item-query'
import { useResearchTaxonomyQuery } from 'models/queries/use-research-taxonomy-query'
import { ResearchArea } from 'openapi/models/ResearchArea'
import { ResearchFilter } from 'openapi/models/ResearchFilter'

import { useNavigateWithQueryParams } from 'hooks/use-navigate-with-query-params'

import ResearchHeader from './components/research-header'
import ResearchPageDetail from './components/research-page-detail'
import useQueryAnalytics from 'components/common/analytics/use-query-analytics'
import { useAuthUser } from 'components/common/auth-context'
import ErrorPage, { ErrorPageTitle } from 'components/common/error/error'
import FullscreenLoading from 'components/common/fullscreen-loading'
import {
  getTitle,
  hasResearchAreaPermission,
  isResearchArea,
  getAllChildrenDeep,
  getEventForResearchArea,
  getAllParentsDeep,
  getFilterDepth,
} from 'components/research/research-helpers'
import { useResearchStore } from 'components/research/research-store'

const ResearchPage = () => {
  const navigate = useNavigateWithQueryParams()
  const userInfo = useAuthUser()

  const { area, id: historyId } = useParams()
  const historyIdRef = useRef(historyId)
  const researchArea = area as ResearchArea
  const isTax = researchArea === ResearchArea.TAX

  const eventKind = getEventForResearchArea(researchArea)
  const userHasPermission = hasResearchAreaPermission(userInfo, researchArea)
  const shouldFetchTaxonomy = isResearchArea(researchArea) && userHasPermission

  const { setQueryViewed } = useQueryAnalytics(eventKind)

  const { taxonomy, isFetched: isTaxonomyFetched } = useResearchTaxonomyQuery(
    researchArea,
    shouldFetchTaxonomy
  )
  const allFiltersFlattened = useMemo(() => {
    const researchFilters = taxonomy
    return researchFilters.concat(
      researchFilters.flatMap((researchFilter) =>
        getAllChildrenDeep(researchFilter)
      )
    )
  }, [taxonomy])

  const queryId = useResearchStore(useShallow((s) => s.queryId))
  const setTask = useResearchStore(useShallow((s) => s.setTask))
  const [setOpenFilters, setSelectedFilters] = useResearchStore(
    useShallow((state) => [state.setOpenFilters, state.setSelectedFilters])
  )

  const { historyItem, isFetching, isFetched } = useHistoryItemQuery({
    id: historyIdRef.current,
    isEnabled:
      allFiltersFlattened.length > 0 && queryId !== historyIdRef.current,
  })

  const isHistoryFetchedCheck = historyIdRef.current
    ? !isFetching && isFetched && historyItem?.id === historyIdRef.current
    : true
  const isQueryIdMatchHistory = historyIdRef.current
    ? queryId === historyIdRef.current
    : true
  const isDataFetched = isTaxonomyFetched && isHistoryFetchedCheck

  const setDefaultFilters = useCallback(() => {
    if (researchArea === ResearchArea.FROMCOUNSEL) {
      setSelectedFilters(allFiltersFlattened)
      setOpenFilters(allFiltersFlattened)
    }
  }, [researchArea, allFiltersFlattened, setOpenFilters, setSelectedFilters])

  useMount(() => {
    if (historyId) {
      setQueryViewed()
    }
  })

  useEffect(() => {
    if (isFetching || isEmpty(allFiltersFlattened)) return
    if (isEmpty(historyItem)) {
      setDefaultFilters()
      return
    }
    setTask({ ...historyItem, queryId: historyItem.id })
    const filterIdsFromHistory = (historyItem.filterIds || []) as string[]
    const matchingFilters = allFiltersFlattened.filter(
      (filter: ResearchFilter) => filterIdsFromHistory.includes(filter.id)
    )
    const allFilterIdsToFilterMap: { [key: string]: ResearchFilter } = {}
    allFiltersFlattened.forEach((filter: ResearchFilter) => {
      allFilterIdsToFilterMap[filter.id] = filter
    })
    const allParentsDeep = getAllParentsDeep(
      matchingFilters,
      allFiltersFlattened
    ).sort(
      // order by depth in the taxonomy
      (a, b) =>
        getFilterDepth(a, allFilterIdsToFilterMap) -
        getFilterDepth(b, allFilterIdsToFilterMap)
    )
    setSelectedFilters(matchingFilters)
    setOpenFilters(allParentsDeep.concat(matchingFilters))
  }, [
    allFiltersFlattened,
    historyItem,
    isFetching,
    researchArea,
    setTask,
    setSelectedFilters,
    setOpenFilters,
    setDefaultFilters,
  ])

  if (!isResearchArea(researchArea)) {
    return (
      <ErrorPage
        title={ErrorPageTitle.PAGE_NOT_FOUND}
        primaryCTA={{
          text: 'Back to Research',
          onClick: () => navigate('/research'),
        }}
      />
    )
  }

  if (!userHasPermission) {
    return (
      <ErrorPage
        title={ErrorPageTitle.NOT_AUTHORIZED}
        primaryCTA={{
          text: 'Back to Research',
          onClick: () => navigate('/research'),
        }}
      />
    )
  }

  if (
    historyIdRef.current &&
    isFetched &&
    (isEmpty(historyItem) || historyItem.id !== historyIdRef.current)
  ) {
    return (
      <ErrorPage
        title={ErrorPageTitle.PAGE_NOT_FOUND}
        description={`The requested history item /${historyIdRef.current} you are trying to access does not exist.\nContact support@harvey.ai if this issue persists.`}
        primaryCTA={{
          text: `Back to ${researchArea}`,
          onClick: () => navigate(`/research/${getTitle(researchArea)}`),
        }}
      />
    )
  }

  return (
    <>
      <FullscreenLoading isLoading={!isDataFetched && !isQueryIdMatchHistory} />
      <ResearchHeader
        isTax={isTax}
        researchArea={researchArea}
        eventKind={eventKind}
        historyIdRef={historyIdRef}
        setDefaultFilters={setDefaultFilters}
      />
      <ResearchPageDetail
        isTax={isTax}
        historyIdRef={historyIdRef}
        eventKind={eventKind}
        researchArea={researchArea}
        allFiltersFlattened={allFiltersFlattened}
        taxonomy={taxonomy}
      />
    </>
  )
}

export default ResearchPage
