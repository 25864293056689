import React, { useEffect, useCallback, useMemo, useState, useRef } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { useLocation } from 'react-router-dom'

import { useQueryClient } from '@tanstack/react-query'
import { startCase, isEmpty } from 'lodash'
import { InfoIcon, PauseCircle, Plus } from 'lucide-react'
import pluralize from 'pluralize'
import queryString from 'query-string'
import { toast } from 'sonner'
import { useShallow } from 'zustand/react/shallow'

import { HarvQueryKeyPrefix } from 'models/queries/all-query-keys'
import { useWrappedQuery } from 'models/queries/lib/use-wrapped-query'
import { EventKind } from 'openapi/models/EventKind'

import { useNavigateWithQueryParams } from 'hooks/use-navigate-with-query-params'
import { bytesToPreciseReadable } from 'utils/file-utils'
import { SafeRecord } from 'utils/safe-types'
import { TaskType } from 'utils/task'
import { displayErrorMessage, displayInfoMessage } from 'utils/toast'
import { parseIsoString } from 'utils/utils'

import { BaseAppPath } from 'components/base-app-path'
import {
  ClientMatter,
  useClientMattersStore,
} from 'components/client-matters/client-matters-store'
import { useAnalytics } from 'components/common/analytics/analytics-context'
import { AppHeader } from 'components/common/app-header'
import { useAuthUser } from 'components/common/auth-context'
import RouterBreadcrumbs from 'components/common/router-breadcrumbs'
import { Badge } from 'components/ui/badge'
import { Button } from 'components/ui/button'
import { Icon } from 'components/ui/icon/icon'
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/ui/tooltip'
import SetExampleProject from 'components/vault/components/set-example-project'
import VaultExportDialog from 'components/vault/dialogs/vault-export-dialog-v2'
import useSharingPermissions from 'components/vault/hooks/use-sharing-permissions'
import useVaultQueryDetailStore, {
  ReviewHistoryItem,
} from 'components/vault/query-detail/vault-query-detail-store'
import {
  REMOVE_PARAMS,
  newProjectPathRaw,
  projectsPath,
  projectsPathRaw,
  filesPathRaw,
  queriesPathRaw,
  DOT_SEPARATOR,
  filesPath,
} from 'components/vault/utils/vault'
import {
  CancelVaultHistoryItem,
  FetchVaultFolderHistoryStats,
} from 'components/vault/utils/vault-fetcher'
import { getQuestionsLimit } from 'components/vault/utils/vault-helpers'
import { QUERY_TYPES, useVaultStore } from 'components/vault/utils/vault-store'
import { pluralizeFiles } from 'components/vault/utils/vault-text-utils'
import { useVaultUsageStore } from 'components/vault/utils/vault-usage-store'

import FileBreadcrumb from './file-breadcrumb'
import { FileControls } from './file-controls'
import QueryDetailBreadcrumb from './query-detail-breadcrumb'
import VaultHomeHeader from './vault-home-header'
import VaultProjectTabs from './vault-project-tabs'
import VaultRunButton from './vault-run-button'
import VaultShareButton from './vault-share-button'

const loadingText = 'Loading…'
const untitledQueryText = 'Untitled'
interface BreadcrumbPath {
  name: string
  path: string
}

const VaultAppHeader = () => {
  const location = useLocation()
  const userInfo = useAuthUser()
  const navigate = useNavigateWithQueryParams()
  const { trackEvent } = useAnalytics()
  const queryClient = useQueryClient()

  const [clientMatters] = useClientMattersStore(
    useShallow((s) => [s.clientMatters])
  )

  const [
    currentProject,
    currentProjectMetadata,
    exampleProjectIds,
    fileIdToVaultFile,
    folderIdToVaultFileIds,
    setPendingQuery,
    setPendingQueryFileIdsV1,
    setQueryType,
    setIsTextAreaFocused,
  ] = useVaultStore(
    useShallow((s) => [
      s.currentProject,
      s.currentProjectMetadata,
      s.exampleProjectIds,
      s.fileIdToVaultFile,
      s.folderIdToVaultFileIds,
      s.setPendingQuery,
      s.setPendingQueryFileIds,
      s.setQueryType,
      s.setIsTextAreaFocused,
    ])
  )
  const [reviewQuestionsPerQueryLimit, reviewFilesPerQueryLimit] =
    useVaultUsageStore(
      useShallow((s) => [
        s.reviewQuestionsPerQueryLimit,
        s.reviewFilesPerQueryLimit,
      ])
    )
  const [
    useV1QueryDetail,
    isQueryLoading,
    hasOnGridReadyExecuted,
    queryId,
    historyItem,
  ] = useVaultQueryDetailStore(
    useShallow((s) => [
      s.useV1QueryDetail,
      s.isQueryLoading,
      s.hasOnGridReadyExecuted,
      s.queryId,
      s.historyItem,
    ])
  )

  const [queryIdInV1, queryIdToState] = useVaultStore(
    useShallow((s) => [s.queryId, s.queryIdToState])
  )
  const queryIdState = useV1QueryDetail
    ? queryIdToState[queryIdInV1!]
    : (historyItem as ReviewHistoryItem)

  const isNewQuery = queryId === 'new'
  const taskType = queryIdState?.taskType
  const isReviewQueryType = !taskType
    ? isNewQuery
    : taskType === EventKind.VAULT_REVIEW
  const query = queryIdState?.query

  const queryTitle = queryIdState?.title ?? ''
  const queryNumFiles = queryIdState?.numFiles ?? 0
  const creatorUserEmail = queryIdState?.creatorUserEmail ?? ''
  const isDryRun = queryIdState?.dryRun ?? false

  const filesLimit =
    (queryIdState as ReviewHistoryItem)?.filesLimit ?? reviewFilesPerQueryLimit

  const queryQuestions = useMemo(() => {
    return (queryIdState as ReviewHistoryItem)?.questions ?? []
  }, [queryIdState])

  const processedFileIds: string[] | undefined = useMemo(
    () => (queryIdState as ReviewHistoryItem)?.processedFileIds,
    [queryIdState]
  )

  const queryQuestionsLimit = (queryIdState as ReviewHistoryItem)
    ?.questionsLimit
  const questionsLimit = getQuestionsLimit(
    queryQuestionsLimit,
    reviewQuestionsPerQueryLimit
  )

  const fileIds = useMemo(() => {
    const queryFileIds = queryIdState?.fileIds ?? []
    return queryFileIds?.filter((id: string) => !!fileIdToVaultFile[id])
  }, [fileIdToVaultFile, queryIdState])

  const numFiles = useMemo(() => {
    return fileIds?.length == 0 && queryNumFiles
      ? queryNumFiles
      : fileIds?.length ?? 0
  }, [fileIds, queryNumFiles])

  const startedAt = queryIdState?.startedAt
  const completedAt = queryIdState?.completedAt
  const cancelledAt = queryIdState?.cancelledAt
  const failedAt = queryIdState?.failedAt
  const eta = (queryIdState as ReviewHistoryItem)?.eta

  const [isSetExampleOpen, setIsSetExampleOpen] = useState<boolean>(false)
  const [lastCancelRequestedAt, setLastCancelRequestedAt] =
    useState<Date | null>(null)

  const shouldRenderVaultHomeHeader = useMemo(() => {
    return (
      location.pathname === BaseAppPath.Vault ||
      location.pathname === `${BaseAppPath.Vault}/${projectsPathRaw}` ||
      location.pathname === `${BaseAppPath.Vault}${projectsPath}`
    )
  }, [location.pathname])

  const shouldRenderNewProjectBreadcrumb = useMemo(() => {
    const paths = location.pathname.split('/').filter(Boolean)
    return paths.length === 2 && paths[1] === newProjectPathRaw
  }, [location.pathname])

  const shouldRenderQueriesBreadcrumb = useMemo(() => {
    // When path is /vault/projects/:projectId/queries
    const paths = location.pathname.split('/').filter(Boolean)
    return (
      paths.length === 4 &&
      paths[1] === projectsPathRaw &&
      paths[3] === queriesPathRaw
    )
  }, [location.pathname])

  const shouldRenderQueryDetailBreadcrumb = useMemo(() => {
    // When path is /vault/projects/:projectId/queries/:queryId
    const paths = location.pathname.split('/').filter(Boolean)
    return (
      paths.length === 5 &&
      paths[1] === projectsPathRaw &&
      paths[3] === queriesPathRaw
    )
  }, [location.pathname])

  const shouldRenderFileBreadcrumb = useMemo(() => {
    // When path is /vault/projects/:projectId/files/:fileId
    const paths = location.pathname.split('/').filter(Boolean)
    return (
      paths.length === 5 &&
      paths[1] === projectsPathRaw &&
      paths[3] === filesPathRaw
    )
  }, [location.pathname])

  const isShowingProjectPage = useMemo(() => {
    // When path is /vault/projects/:projectId
    const paths = location.pathname.split('/').filter(Boolean)

    return paths.length === 3 && paths[1] === projectsPathRaw
  }, [location.pathname])

  // we cannot get the fileId from useParams because vault-app-header is not a child of the layout
  // its in the same route as the layout
  const currentFileId = useMemo(() => {
    if (!shouldRenderFileBreadcrumb) return undefined
    const paths = location.pathname.split('/').filter(Boolean)
    return paths[paths.length - 1]
  }, [location, shouldRenderFileBreadcrumb])
  const currentFile = useMemo(() => {
    if (!currentFileId) return undefined
    return fileIdToVaultFile[currentFileId]
  }, [fileIdToVaultFile, currentFileId])

  const {
    doesCurrentUserHaveEditPermission,
    isOwner,
    canCurrentUserCreateShares,
  } = useSharingPermissions({
    projectId: currentProject?.id,
  })
  const isExampleProject = useMemo(
    () => currentProject && exampleProjectIds.has(currentProject.id),
    [currentProject, exampleProjectIds]
  )
  const shouldHideShareButtonForProjectOwner =
    isOwner && !canCurrentUserCreateShares

  const canCurrentUserEditProject =
    !isExampleProject && doesCurrentUserHaveEditPermission

  const breadcrumbPaths: BreadcrumbPath[] = useMemo(() => {
    // When path is either
    // 1. /vault/projects
    // 2. /vault/projects/:projectId
    // 3. /vault/projects/:projectId/queries
    // We should show the project name in the breadcrumbs
    const vaultDisplayNameWithPath = { name: 'Vault', path: BaseAppPath.Vault }
    const paths = location.pathname.split('/').filter(Boolean)

    if (shouldRenderNewProjectBreadcrumb) {
      return [
        vaultDisplayNameWithPath,
        { name: 'New project', path: location.pathname },
      ]
    }

    if (paths.length <= 2 || paths[1] !== projectsPathRaw) {
      // Since we are at vault app header, we should default to the vault projects path
      // if the path is not valid or too short
      return [vaultDisplayNameWithPath]
    }

    const projectId = paths[2]
    const projectDetailPath = `${BaseAppPath.Vault}${projectsPath}${projectId}`
    const projectDetailDisplayNameWithPath = {
      name: currentProject?.name || loadingText,
      path: projectDetailPath,
    }

    if (paths.length === 3) {
      return [vaultDisplayNameWithPath, projectDetailDisplayNameWithPath]
    }

    if (shouldRenderQueriesBreadcrumb) {
      return [
        vaultDisplayNameWithPath,
        projectDetailDisplayNameWithPath,
        { name: startCase(queriesPathRaw), path: location.pathname },
      ]
    }

    if (shouldRenderQueryDetailBreadcrumb) {
      return [
        vaultDisplayNameWithPath,
        projectDetailDisplayNameWithPath,
        {
          name: startCase(queriesPathRaw),
          path: `${projectDetailPath}/${queriesPathRaw}`,
        },
        { name: 'Query', path: location.pathname },
      ]
    }

    if (shouldRenderFileBreadcrumb) {
      return [
        vaultDisplayNameWithPath,
        projectDetailDisplayNameWithPath,
        { name: startCase(filesPathRaw), path: projectDetailPath },
        { name: 'File', path: location.pathname },
      ]
    }
    return []
  }, [
    location.pathname,
    currentProject?.name,
    shouldRenderNewProjectBreadcrumb,
    shouldRenderQueriesBreadcrumb,
    shouldRenderQueryDetailBreadcrumb,
    shouldRenderFileBreadcrumb,
  ])

  const pathForIndexFunc = useCallback(
    (index: number, pathname: string, search: string) => {
      const queryParams = queryString.parse(search)
      for (const param of REMOVE_PARAMS) {
        delete queryParams[param]
      }
      if (Object.keys(queryParams).length > 0) {
        return `${breadcrumbPaths[index].path}?${queryString.stringify(
          queryParams
        )}`
      }
      return breadcrumbPaths[index].path
    },
    [breadcrumbPaths]
  )

  const isCancelling =
    !!lastCancelRequestedAt && !!startedAt && lastCancelRequestedAt > startedAt

  const appHeaderTitle = useMemo(() => {
    if (useV1QueryDetail) return queryTitle || query || loadingText
    if (isReviewQueryType && !hasOnGridReadyExecuted) return loadingText
    if (isReviewQueryType && isNewQuery) return untitledQueryText
    return queryTitle || query || loadingText
  }, [
    hasOnGridReadyExecuted,
    isReviewQueryType,
    isNewQuery,
    queryTitle,
    query,
    useV1QueryDetail,
  ])

  const overridePath = breadcrumbPaths.map((path) => path.name).join('/')
  const showNewQueryButton =
    taskType === TaskType.VAULT &&
    queryId &&
    !isQueryLoading &&
    canCurrentUserEditProject
  const showCancelRequestNNButton =
    taskType === TaskType.VAULT_REVIEW && isQueryLoading
  // show project sharing button if user is currently viewing a project homepage
  const showShareButton =
    userInfo.IsVaultViewSharesUser &&
    currentProject?.id &&
    !isExampleProject &&
    !isQueryLoading &&
    isShowingProjectPage &&
    !shouldHideShareButtonForProjectOwner
  const showFileControls = !!currentFile
  const fileIdsInCurrentFolder = useMemo(() => {
    if (!currentFile?.vaultFolderId) return []
    return (folderIdToVaultFileIds[currentFile.vaultFolderId] ?? [])
      .map((id) => fileIdToVaultFile[id])
      .filter((file) => !!file)
      .sort((a, b) => a!.name.localeCompare(b!.name))
      .map((file) => file!.id)
  }, [currentFile, folderIdToVaultFileIds, fileIdToVaultFile])
  const previousFileId = useMemo(() => {
    if (!currentFile?.vaultFolderId) return undefined
    const currentIndex = fileIdsInCurrentFolder.indexOf(currentFile.id)
    if (currentIndex === -1 || currentIndex === 0) return undefined
    return fileIdsInCurrentFolder[currentIndex - 1]
  }, [currentFile, fileIdsInCurrentFolder])
  const nextFileId = useMemo(() => {
    if (!currentFile?.vaultFolderId) return undefined
    const currentIndex = fileIdsInCurrentFolder.indexOf(currentFile.id)
    if (
      currentIndex === -1 ||
      currentIndex === fileIdsInCurrentFolder.length - 1
    )
      return undefined
    return fileIdsInCurrentFolder[currentIndex + 1]
  }, [currentFile, fileIdsInCurrentFolder])
  const currentFileIndex = useMemo(() => {
    if (!currentFile?.vaultFolderId) return undefined
    return fileIdsInCurrentFolder.indexOf(currentFile.id)
  }, [currentFile, fileIdsInCurrentFolder])

  const shouldShowClientMatterBadge =
    userInfo.IsVaultProjectClientMatterUser &&
    isShowingProjectPage &&
    currentProject &&
    currentProject.clientMatterId

  const onNewQuery = () => {
    if (!showNewQueryButton) return
    trackEvent('Vault Review Query New Query Button Clicked', {
      query_id: queryId,
    })
    setIsTextAreaFocused(true)
    setQueryType(QUERY_TYPES.N1)
    setPendingQuery(query ?? '')
    setPendingQueryFileIdsV1(fileIds.length > 0 ? fileIds : null)
  }
  const onCancelNNQuery = async () => {
    if (!showCancelRequestNNButton) return
    trackEvent('Vault Review Query Cancel Button Clicked', {
      query_id: queryId,
    })
    setLastCancelRequestedAt(new Date())
    try {
      const response = await CancelVaultHistoryItem(queryId)
      if (response.eventCancelled) {
        await queryClient.invalidateQueries({
          queryKey: [HarvQueryKeyPrefix.VaultHistoryItemQuery, queryId, true],
        })
      } else {
        displayInfoMessage('Cancelling, it will take a few seconds…', 5)
      }
    } catch (e) {
      console.error(e)
      displayErrorMessage('Failed to cancel query, please try again later')
    }
  }

  const navigateToFile = (currentProjectId: string, fileId: string) => {
    navigate(
      `${BaseAppPath.Vault}${projectsPath}${currentProjectId}${filesPath}${fileId}`,
      {},
      REMOVE_PARAMS
    )
  }
  useHotkeys(
    'up',
    () => {
      if (previousFileId) {
        navigateToFile(currentProject!.id, previousFileId)
      }
    },
    {
      enabled: showFileControls && !!currentProject && !!previousFileId,
    }
  )
  useHotkeys(
    'down',
    () => {
      if (nextFileId) {
        navigateToFile(currentProject!.id, nextFileId)
      }
    },
    {
      enabled: showFileControls && !!currentProject && !!nextFileId,
    }
  )
  const navigateToProject = (currentProjectId: string) => {
    navigate(
      `${BaseAppPath.Vault}${projectsPath}${currentProjectId}`,
      {},
      REMOVE_PARAMS
    )
  }
  // when the user presses escape key we want to hide the document previewer
  useHotkeys(
    'esc',
    () => {
      navigateToProject(currentProject!.id)
    },
    {
      enabled: showFileControls && !!currentProject,
    },
    [navigateToProject, currentProject, currentFile]
  )

  const dryRunStartToastIdRef = useRef<
    SafeRecord<string, string | number | null>
  >({})
  const dryRunSuccessToastIdRef = useRef<
    SafeRecord<string, string | number | null>
  >({})
  useEffect(() => {
    if (!isDryRun) {
      for (const toastId of Object.values({
        ...dryRunStartToastIdRef.current,
        ...dryRunSuccessToastIdRef.current,
      })) {
        if (toastId) {
          // Dismiss the previous toast if it exists
          toast.dismiss(toastId)
        }
      }
      dryRunStartToastIdRef.current = {}
      dryRunSuccessToastIdRef.current = {}
    }
  }, [isDryRun])
  useEffect(() => {
    if (isDryRun && isQueryLoading && fileIds?.length > 1) {
      if (
        dryRunStartToastIdRef.current[queryId] ||
        dryRunSuccessToastIdRef.current[queryId]
      ) {
        // If the toast is already showing, don't show it again
        return
      }
      dryRunStartToastIdRef.current[queryId] = displayInfoMessage(
        'The review query will run the first file, you can add more columns or continue review on the remaining files once it finishes',
        10
      )
    }
  }, [fileIds, queryId, isQueryLoading, isDryRun])

  useEffect(() => {
    if (isEmpty(currentProject)) {
      document.title = 'Vault'
    } else if (currentFile) {
      document.title = currentFile.name
    } else if (!isEmpty(currentProject) && isNewQuery) {
      document.title = currentProject.name
    } else {
      document.title = appHeaderTitle
    }

    return () => {
      document.title = 'Harvey'
    }
  }, [appHeaderTitle, currentProject, overridePath, currentFile, isNewQuery])

  const projectFilesCountLimitDisplayText = useMemo(() => {
    return `Each project can contain up to ${userInfo.workspace
      .getVaultFilesCountLimit(userInfo.vaultFeature)
      .toLocaleString()} files`
  }, [userInfo.vaultFeature, userInfo.workspace])

  const sizeDisplayText = useMemo(() => {
    const projectData = currentProject ? currentProjectMetadata : null
    if (!projectData) return 'Loading…'
    const numProjectFiles = projectData.totalFiles || 0
    const projectSize = bytesToPreciseReadable(
      projectData.folderSize || 0,
      2,
      true
    )
    if (numProjectFiles === 0) {
      return projectFilesCountLimitDisplayText
    }
    return `${pluralizeFiles(numProjectFiles)} (${projectSize})`
  }, [
    currentProject,
    currentProjectMetadata,
    projectFilesCountLimitDisplayText,
  ])

  const { data: historyStats } = useWrappedQuery({
    refetchOnWindowFocus: false,
    enabled: !!currentProject,
    queryKey: [HarvQueryKeyPrefix.VaultHistoryStatsQuery, currentProject?.id],
    queryFn: () => FetchVaultFolderHistoryStats(currentProject?.id ?? ''),
    refetchInterval: (query) => {
      // Poll for history stats updates every 10 seconds if there are any in progress
      if (
        query.state.status === 'success' &&
        query.state.data &&
        query.state.data.inProgressCount > 0
      ) {
        return 10_000
      }
      return false
    },
  })

  const queriesDisplayText = useMemo(() => {
    if (!historyStats || historyStats.totalCount === 0) return null
    return `${historyStats.totalCount} ${pluralize(
      'query',
      historyStats.totalCount
    )}`
  }, [historyStats])

  const subtitleForProjectDetail = useMemo(() => {
    return [sizeDisplayText, queriesDisplayText]
      .filter(Boolean)
      .join(DOT_SEPARATOR)
  }, [sizeDisplayText, queriesDisplayText])

  const TitleComponent = useMemo(() => {
    if (shouldRenderVaultHomeHeader) {
      return <VaultHomeHeader />
    }

    if (shouldRenderFileBreadcrumb) {
      return <FileBreadcrumb currentFile={currentFile} />
    }

    if (shouldRenderQueryDetailBreadcrumb) {
      return (
        <QueryDetailBreadcrumb
          numFiles={numFiles}
          filesLimit={filesLimit}
          numQuestions={queryQuestions?.length}
          questionsLimit={questionsLimit}
          // Only show the query limit for vault review tasks
          showQueryLimit={taskType === TaskType.VAULT_REVIEW}
          processedFileIds={processedFileIds}
          isLoading={isQueryLoading}
          completedAt={completedAt}
          cancelledAt={cancelledAt}
          failedAt={failedAt}
          eta={eta ? parseIsoString(eta) : null}
          // Only show the processing started time if it's a review task, because
          // for other types of tasks, it's usually quick and not worth showing.
          startedAt={taskType === TaskType.VAULT_REVIEW ? startedAt : undefined}
          projectId={currentProject?.id}
          title={appHeaderTitle}
          creatorUserEmail={creatorUserEmail}
        />
      )
    }

    if (breadcrumbPaths.length === 0) return null
    const lastBreadcrumbName = breadcrumbPaths[breadcrumbPaths.length - 1]?.name
    const subtitle = () => {
      if (shouldRenderQueriesBreadcrumb) return 'Browse your previous queries'
      if (shouldRenderNewProjectBreadcrumb)
        return projectFilesCountLimitDisplayText
      return subtitleForProjectDetail
    }

    return (
      <>
        <div className="flex space-x-2">
          <p className="line-clamp-2 text-xl font-medium">
            {lastBreadcrumbName}
          </p>
          {isExampleProject && (
            <Badge variant="secondary" className="border-0 text-muted" isPill>
              Example
              <Tooltip delayDuration={200}>
                <TooltipTrigger className="ml-1">
                  <Icon icon={InfoIcon} size="small" />
                </TooltipTrigger>
                <TooltipContent
                  className="max-w-80 text-start"
                  align="start"
                  side="right"
                >
                  This is an example project to give you a preview of how Vault
                  works. Queries cannot be run on this project. It will not
                  count towards any limits and cannot be deleted
                </TooltipContent>
              </Tooltip>
            </Badge>
          )}
          <SetExampleProject
            isSetExampleOpen={isSetExampleOpen}
            setIsSetExampleOpen={setIsSetExampleOpen}
          />
        </div>
        <p className="truncate text-xs text-muted">{subtitle()}</p>
      </>
    )
  }, [
    currentFile,
    currentProject,
    numFiles,
    filesLimit,
    queryQuestions,
    questionsLimit,
    taskType,
    appHeaderTitle,
    isQueryLoading,
    processedFileIds,
    startedAt,
    completedAt,
    cancelledAt,
    failedAt,
    eta,
    creatorUserEmail,
    shouldRenderFileBreadcrumb,
    shouldRenderQueriesBreadcrumb,
    shouldRenderNewProjectBreadcrumb,
    shouldRenderQueryDetailBreadcrumb,
    shouldRenderVaultHomeHeader,
    breadcrumbPaths,
    projectFilesCountLimitDisplayText,
    isExampleProject,
    subtitleForProjectDetail,
    isSetExampleOpen,
    setIsSetExampleOpen,
  ])

  return (
    <AppHeader
      titleComponent={TitleComponent}
      breadcrumbs={
        breadcrumbPaths.length === 0 ? null : (
          <RouterBreadcrumbs
            overridesDocumentTitle
            overridePath={overridePath}
            keepCaseStartingFromIndex={1}
            checkHistoryIdInPath={false}
            pathForIndexFunc={pathForIndexFunc}
          />
        )
      }
      actions={
        <div className="ml-2 flex shrink-0 grow items-center justify-end gap-2">
          {shouldShowClientMatterBadge && (
            <Badge
              variant="secondary"
              className="text-muted hover:bg-button-secondary"
            >
              CM#{' '}
              {
                clientMatters.find(
                  (cm: ClientMatter) =>
                    cm.id === currentProjectMetadata.clientMatterId ||
                    cm.id === currentProject.clientMatterId
                )?.name
              }
            </Badge>
          )}
          {showNewQueryButton && (
            <Button variant="outline" onClick={onNewQuery}>
              <Icon icon={Plus} className="mr-1" />
              New query
            </Button>
          )}
          {shouldRenderQueryDetailBreadcrumb && <VaultExportDialog />}
          {showCancelRequestNNButton && (
            <Button
              variant="outline"
              onClick={onCancelNNQuery}
              disabled={isCancelling}
              data-testid="cancel-query-button"
            >
              {!isCancelling && <Icon icon={PauseCircle} className="mr-1" />}
              {isCancelling ? 'Pausing…' : 'Pause'}
            </Button>
          )}
          {shouldRenderQueryDetailBreadcrumb && isReviewQueryType && (
            <VaultRunButton />
          )}
          {showShareButton && <VaultShareButton />}
          {showFileControls && currentProject && (
            <FileControls
              currentFileIndex={currentFileIndex}
              fileIds={fileIdsInCurrentFolder}
              previousFileId={previousFileId}
              nextFileId={nextFileId}
              onNavigateToFile={(fileId: string) => {
                navigateToFile(currentProject.id, fileId)
              }}
              onExit={() => {
                navigateToProject(currentProject.id)
              }}
            />
          )}
        </div>
      }
    >
      {/* TODO(stella): replace with userInfo.IsVaultViewSharesUser for GA */}
      {userInfo.IsVaultCreateSharesUser && shouldRenderVaultHomeHeader && (
        <div className="px-6">
          <VaultProjectTabs />
        </div>
      )}
    </AppHeader>
  )
}

export default VaultAppHeader
