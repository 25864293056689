import _ from 'lodash'

import { UserInfo } from 'models/user-info'

import {
  Announcement,
  AudienceType,
  PersistanceBehavior,
} from 'components/common/announcements/announcement.types'
import { AnnouncementList } from 'components/common/announcements/data/all-announcements'

import { getHasDismissedCookie } from './is-dismissed'

/*
  This function fills in default values for an announcement
*/
const fillInDefaultValuesForAnnouncement = (announcement: Announcement) => {
  const newAnnouncement: Announcement = { ...announcement }
  if (!newAnnouncement.zIndex) {
    newAnnouncement.zIndex = 0
  }
  if (!newAnnouncement.criteria.persistanceBehavior) {
    newAnnouncement.criteria.persistanceBehavior =
      PersistanceBehavior.HIDE_AFTER_VIEWED
  }

  return newAnnouncement
}

/*
  This function checks if a single announcement is active for a single user
*/
const isAnnouncementActiveForUser = (
  userInfo: UserInfo,
  announcement: Announcement
): boolean => {
  const newAnnouncement = fillInDefaultValuesForAnnouncement(announcement)
  const criteria = newAnnouncement.criteria
  const now = new Date()
  const userCreatedDate = _.isNil(userInfo.createdAt)
    ? null
    : new Date(userInfo.createdAt)
  const isDismissedCookie = getHasDismissedCookie(newAnnouncement)

  // Case 0: Announcement is not enabled
  if (!newAnnouncement.enabled) {
    return false
  }

  // Case 1: End date has passed or start date as not been reached
  if (
    (criteria.startDate && now < criteria.startDate) ||
    (criteria.endDate && now > criteria.endDate)
  ) {
    return false
  }
  // Case 2: User was created after the announcement, and the announcement is only for users created before the announcement
  if (
    criteria.audienceType ===
    AudienceType.ONLY_USERS_CREATED_BEFORE_ANNOUNCEMENT
  ) {
    if (
      userCreatedDate &&
      criteria.startDate &&
      userCreatedDate > criteria.startDate
    ) {
      return false
    }
  }

  // Check 3: Is there a custom validator?
  if (criteria.customValidator && !criteria.customValidator(userInfo)) {
    return false
  }
  // Check 4: Has user seen this announcement before?
  if (isDismissedCookie) {
    return false
  }

  // Nothing above has returned false
  return true
}

/*
  This function gets all active announcements for a user
  It filters through all announcements and returns the ones that are active for the user
*/
const getActiveAnnouncementsForUser = (
  userInfo: UserInfo,
  announcements: Announcement[] = AnnouncementList
) => {
  const activeAnn = announcements
    .filter((anmt) => isAnnouncementActiveForUser(userInfo, anmt))
    .sort((a, b) => (b.zIndex || 0) - (a.zIndex || 0)) // Sorting in descending order by zIndex

  return activeAnn
}

// exports
export { getActiveAnnouncementsForUser }
