import { useQueryClient } from '@tanstack/react-query'

import {
  ClientMattersSettingsStore,
  settingsClientMattersLoader,
} from 'models/client-matters'
import { prefetchWrapper } from 'models/queries/lib'
import { UserInfo } from 'models/user-info'
import { Maybe } from 'types'

import { HarvQueryKeyPrefix } from './all-query-keys'
import { useWrappedQuery } from './lib/use-wrapped-query'

export const useSettingsClientMattersQuery = (
  settingsUserInfo: Maybe<UserInfo>,
  isEnabled: boolean = true
) => {
  const clientMattersFetcher = async () =>
    settingsClientMattersLoader(settingsUserInfo)

  const {
    isPending: isCmPending,
    error: cmError,
    data: _cmData,
    isFetching: isCmFetching,
  } = useWrappedQuery({
    queryKey: [HarvQueryKeyPrefix.SettingsClientMatters],
    queryFn: clientMattersFetcher,
    enabled: isEnabled,
  })

  const cmData =
    _cmData ||
    ({
      clientMattersWithStats: [],
      workspaces: [settingsUserInfo?.workspace],
    } as ClientMattersSettingsStore)

  return { cmData, isCmPending, isCmFetching, cmError }
}

export const useSettingsClientMattersPrefetch = (
  settingsUserInfo: Maybe<UserInfo>,
  isEnabled?: boolean
) => {
  const queryClient = useQueryClient()
  const clientMattersFetcher = async () =>
    settingsClientMattersLoader(settingsUserInfo)

  if (isEnabled) {
    prefetchWrapper(queryClient, {
      queryKey: [HarvQueryKeyPrefix.SettingsClientMatters],
      queryFn: clientMattersFetcher,
    })
  }

  return
}
