/* tslint:disable */
/* eslint-disable */
/**
 * Harvey Web App - User Permissions
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const TimeInterval = {
    DAY: 'day',
    MONTH: 'month'
} as const;
export type TimeInterval = typeof TimeInterval[keyof typeof TimeInterval];


export function instanceOfTimeInterval(value: any): boolean {
    for (const key in TimeInterval) {
        if (Object.prototype.hasOwnProperty.call(TimeInterval, key)) {
            if (TimeInterval[key as keyof typeof TimeInterval] === value) {
                return true;
            }
        }
    }
    return false;
}

export function TimeIntervalFromJSON(json: any): TimeInterval {
    return TimeIntervalFromJSONTyped(json, false);
}

export function TimeIntervalFromJSONTyped(json: any, ignoreDiscriminator: boolean): TimeInterval {
    return json as TimeInterval;
}

export function TimeIntervalToJSON(value?: TimeInterval | null): any {
    return value as any;
}

export function TimeIntervalToJSONTyped(value: any, ignoreDiscriminator: boolean): TimeInterval {
    return value as TimeInterval;
}

