import React from 'react'

import _ from 'lodash'
import { useShallow } from 'zustand/react/shallow'

import { useIntegrationsStore } from 'stores/integrations-store'
import { FileType, FileTypeToExtension } from 'types/file'

import { Button } from 'components/ui/button'

import SharepointOneDriveLogo from './sharepoint-onedrive-logo'

interface Props {
  onUploadFromSharepoint: (files: File[]) => Promise<void>
  acceptedFileTypes: FileType[]
}

const SharepointButton: React.FC<Props> = ({
  onUploadFromSharepoint,
  acceptedFileTypes,
}) => {
  const [setSharepointPickerOpenState] = useIntegrationsStore(
    useShallow((state) => [state.setSharepointPickerOpenState])
  )

  const handleSharePointClick = () => {
    setSharepointPickerOpenState({
      acceptedFileTypes: _.flatMap(
        acceptedFileTypes,
        (type) => FileTypeToExtension[type] ?? []
      ),
      onUploadFromSharepoint,
    })
  }

  return (
    <Button
      onClick={handleSharePointClick}
      size="sm"
      variant="outline"
      className="pointer-events-auto px-3"
    >
      <SharepointOneDriveLogo />
    </Button>
  )
}

export default SharepointButton
