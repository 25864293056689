import React from 'react'

import { ContractsDocumentExtractedTermsValue } from 'openapi/models/ContractsDocumentExtractedTermsValue'

import { ALL_FILTER } from 'components/workflows/workflow/contracts/utils/utils'

import TermItem from './term-item'
import { termsViewerSort } from './util'

interface TermsViewerProps {
  terms: string[]
  selectedFilter: string
  extractedTerms: { [key: string]: ContractsDocumentExtractedTermsValue }
  onSourceClick: (term: string) => Promise<void>
}

const TermsViewer: React.FC<TermsViewerProps> = ({
  terms,
  selectedFilter,
  extractedTerms,
  onSourceClick,
}) => {
  const sorted = termsViewerSort(terms, extractedTerms)

  return (
    <div className="mb-8 px-4">
      {selectedFilter === ALL_FILTER.value ? (
        <>
          {sorted.map((term) => (
            <TermItem
              term={term}
              termExtraction={extractedTerms[term]}
              key={term}
              onSourceClick={onSourceClick}
            />
          ))}
        </>
      ) : (
        <TermItem
          term={selectedFilter}
          termExtraction={extractedTerms[selectedFilter]}
          onSourceClick={onSourceClick}
        />
      )}
    </div>
  )
}

export default TermsViewer
