import { TranscriptsDocument } from 'openapi/models/TranscriptsDocument'
import { TranscriptsDocumentTopic } from 'openapi/models/TranscriptsDocumentTopic'

export type Witness = {
  name: string
  topics: {
    topic: TranscriptsDocumentTopic
    docOrQA: TranscriptsDocument
  }[]
}

export const getAllWitnessesWithTopics = (
  documents: TranscriptsDocument[]
): Witness[] => {
  const witnesses: Witness[] = []
  for (const doc of documents) {
    for (const topic of doc.topics) {
      const witnessIndex = witnesses.findIndex((w) => w.name === topic.witness)
      if (witnessIndex > -1) {
        witnesses[witnessIndex].topics.push({ topic, docOrQA: doc })
      } else {
        witnesses.push({
          name: topic.witness,
          topics: [{ topic, docOrQA: doc }],
        })
      }
    }
  }
  return witnesses
}

export const getWitnessTopics = (
  documents: TranscriptsDocument[],
  witnessName: string
): Witness | null => {
  return (
    getAllWitnessesWithTopics(documents).find(
      (witness) => witness.name === witnessName
    ) ?? null
  )
}
