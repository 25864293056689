import { useNavigate, useParams } from 'react-router-dom'
import { useMount } from 'react-use'

const AssistantV1Redirect = () => {
  const { id } = useParams()
  const navigate = useNavigate()

  useMount(() => {
    navigate(`/assistant/assist/${id}`, { replace: true })
  })

  return null
}

export default AssistantV1Redirect
