import { useSearchParams } from 'react-router-dom'

import { Source } from 'openapi/models/Source'

import {
  FILE_ID_PARAM,
  SOURCE_ID_PARAM,
} from 'components/assistant/utils/assistant-helpers'

export const useSelectedCitation = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const selectedSourceFileId = searchParams.get(FILE_ID_PARAM)
  const selectedSourceId = searchParams.get(SOURCE_ID_PARAM)

  const setSelectedCitation = (source: Source | null | undefined) => {
    if (source === undefined || (source && !source.documentId)) {
      return
    }

    if (source === null) {
      setSearchParams((prevParams) => {
        const newParams = new URLSearchParams(prevParams)
        newParams.delete(FILE_ID_PARAM)
        newParams.delete(SOURCE_ID_PARAM)
        return newParams
      })

      return
    }

    setSearchParams((prevParams) => {
      const newParams = new URLSearchParams(prevParams)
      newParams.set(FILE_ID_PARAM, source.documentId!)
      newParams.set(SOURCE_ID_PARAM, source.id!)
      return newParams
    })
  }

  return { selectedSourceFileId, selectedSourceId, setSelectedCitation }
}
