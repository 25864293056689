import { GridApi } from 'ag-grid-community'
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'

export interface PopoverPosition {
  left: number
  top: number
  right: number
  width: number
}

export interface PopoverData {
  id: string
  displayText: string
  numYears: number
}

export interface CompetitiveAnalysisDataGridState {
  gridApi: GridApi | null
  selectedRows: string[]
  popoverData: PopoverData | null
  popoverPosition: PopoverPosition
}

export interface CompetitiveAnalysisDataGridActions {
  setGridApi: (gridApi: GridApi | null) => void
  addSelectedRow: (rowId: string) => void
  bulkAddSelectedRows: (ids: string[]) => void
  removeSelectedRow: (rowId: string) => void
  bulkRemoveSelectedRows: (ids: string[]) => void
  clearSelectedRows: () => void
  setPopoverData: (popoverData: PopoverData | null) => void
  setPopoverPosition: (popoverPosition: PopoverPosition) => void
}

export const useCompetitiveAnalysisDataGridStore = create(
  devtools(
    immer<
      CompetitiveAnalysisDataGridState & CompetitiveAnalysisDataGridActions
    >((set) => ({
      gridApi: null,
      selectedRows: [],
      popoverData: null,
      popoverPosition: {
        left: 0,
        top: 0,
        right: 0,
        width: 0,
      },
      setGridApi: (gridApi: GridApi | null) => set({ gridApi }),
      addSelectedRow: (id) => {
        set((state) => {
          if (!state.selectedRows.includes(id)) {
            return {
              selectedRows: [...state.selectedRows, id],
            }
          }
        })
      },
      bulkAddSelectedRows: (ids: string[]) => {
        set((state) => ({
          selectedRows: [
            ...Array.from(new Set([...state.selectedRows, ...ids])),
          ],
        }))
      },
      removeSelectedRow: (id) => {
        set((state) => ({
          selectedRows: state.selectedRows.filter((r) => r !== id),
        }))
      },
      bulkRemoveSelectedRows: (ids: string[]) => {
        set((state) => ({
          selectedRows: state.selectedRows.filter((r) => !ids.includes(r)),
        }))
      },
      clearSelectedRows: () => set({ selectedRows: [] }),
      setPopoverData: (popoverData) => set({ popoverData }),
      setPopoverPosition: (popoverPosition: PopoverPosition) =>
        set({ popoverPosition }),
    }))
  )
)
