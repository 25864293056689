import { useState, useEffect, useCallback } from 'react'

import { fetchAllWorkspaceUsers, RawUser } from 'models/users'

const useWorkspaceUsers = ({
  workspaceId,
}: {
  workspaceId: string | undefined
}) => {
  const [workspaceUsers, setWorkspaceUsers] = useState<RawUser[]>([])

  const fetchWorkspaceUsers = useCallback(async () => {
    if (!workspaceId) return
    const allUsers = await fetchAllWorkspaceUsers(Number(workspaceId))
    setWorkspaceUsers(allUsers)
  }, [workspaceId])

  useEffect(() => {
    void fetchWorkspaceUsers()
  }, [fetchWorkspaceUsers])
  return { workspaceUsers, fetchWorkspaceUsers }
}

export default useWorkspaceUsers
