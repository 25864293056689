import React from 'react'
import { Navigate, useParams } from 'react-router-dom'

import { UserInfo } from 'models/user-info'
import { TranscriptType } from 'openapi/models/TranscriptType'

import { createRouteComponent } from 'utils/routing'

import TranscriptsCaseView from './case-view'
import TranscriptsTranscriptView from './transcript-view'
import TranscriptsByType from './transcripts-by-type'
import TranscriptsWitnessView from './witness-view'

enum TranscriptsPages {
  FILE_EXPLORER = '/workflows/transcripts',
  // XXX: ":filename" is replaced with the actual filename, don't change it
  // without updating that replace. Same for ":witnessname" below
  FILE_VIEW_FROM_ROOT = '/workflows/transcripts/documents/:filename',
  FILE_VIEW_FROM_TYPE = '/workflows/transcripts/:type/documents/:filename',

  TRANSCRIPT_TYPE = '/workflows/transcripts/:type',
  TRANSCRIPT_TYPE_WITNESS = '/workflows/transcripts/:type/witness/:witnessname',

  WITNESSES = '/workflows/transcripts/:type/witness',
  FILE_VIEW_FROM_ROOT_DOCUMENTS = '/workflows/transcripts/documents',
  FILE_VIEW_FROM_TYPE_DOCUMENTS = '/workflows/transcripts/:type/documents',
}

export const getTranscriptsPageRoutes = (userInfo: UserInfo) => {
  return [
    createRouteComponent({
      path: TranscriptsPages.FILE_EXPLORER,
      hasPerms: userInfo.isTranscriptsUser,
      component: TranscriptsCaseView,
    }),
    createRouteComponent({
      path: TranscriptsPages.FILE_VIEW_FROM_ROOT,
      hasPerms: userInfo.isTranscriptsUser,
      component: TranscriptsTranscriptView,
    }),
    createRouteComponent({
      path: TranscriptsPages.FILE_VIEW_FROM_TYPE,
      hasPerms: userInfo.isTranscriptsUser,
      component: TranscriptsTranscriptView,
    }),
    createRouteComponent({
      path: TranscriptsPages.TRANSCRIPT_TYPE,
      hasPerms: userInfo.isTranscriptsUser,
      component: TranscriptsByType,
    }),
    createRouteComponent({
      path: TranscriptsPages.TRANSCRIPT_TYPE_WITNESS,
      hasPerms: userInfo.isTranscriptsUser,
      component: TranscriptsWitnessView,
    }),

    // Unimplemented (redirect) routes:
    createRouteComponent({
      path: TranscriptsPages.WITNESSES,
      hasPerms: userInfo.isTranscriptsUser,
      component: () => {
        // eslint-disable-next-line react-hooks/rules-of-hooks -- unnamed component here
        const { type } = useParams<{ type: TranscriptType }>()
        return (
          <Navigate
            to={getTranscriptsTypePage(type as TranscriptType)}
            replace
          />
        )
      },
    }),
    createRouteComponent({
      path: TranscriptsPages.FILE_VIEW_FROM_ROOT_DOCUMENTS,
      hasPerms: userInfo.isTranscriptsUser,
      component: () => <Navigate to={TranscriptsPages.FILE_EXPLORER} replace />,
    }),
    createRouteComponent({
      path: TranscriptsPages.FILE_VIEW_FROM_TYPE_DOCUMENTS,
      hasPerms: userInfo.isTranscriptsUser,
      component: () => {
        // eslint-disable-next-line react-hooks/rules-of-hooks -- unnamed component here
        const { type } = useParams<{ type: TranscriptType }>()
        return (
          <Navigate
            to={getTranscriptsTypePage(type as TranscriptType)}
            replace
          />
        )
      },
    }),
  ]
}

// Util functions to get the file view page
export const getTranscriptsFileViewFromRootPage = (filename: string) =>
  TranscriptsPages.FILE_VIEW_FROM_ROOT.replace(':filename', filename)

export const getTranscriptsFileViewFromTypePage = (
  filename: string,
  type: TranscriptType
) =>
  TranscriptsPages.FILE_VIEW_FROM_TYPE.replace(':filename', filename).replace(
    ':type',
    type
  )

export const getTranscriptsTypePage = (type: TranscriptType) =>
  TranscriptsPages.TRANSCRIPT_TYPE.replace(':type', type)

export const getTranscriptsWitnessViewPage = (
  type: TranscriptType,
  witnessname: string
) =>
  TranscriptsPages.TRANSCRIPT_TYPE_WITNESS.replace(':type', type).replace(
    ':witnessname',
    witnessname
  )
