import { parseIsoDurationString, durationToSeconds } from 'utils/utils'

import { ReviewCellResponse } from 'components/vault/utils/vault'

import { CellComparator, CompareConfig } from './cell-comparator-types'

export class DurationCellComparator extends CellComparator {
  compareCells({ answerA, answerB }: CompareConfig) {
    // We index at 0 because we know the response is always a single duration value
    const responseA = answerA.rawResponse?.[0] as ReviewCellResponse
    const responseB = answerB.rawResponse?.[0] as ReviewCellResponse

    return compareDurations(responseA.value || '', responseB.value || '')
  }
}

export const compareDurations = (
  durationAString: string,
  durationBString: string
) => {
  const durationA = parseIsoDurationString(durationAString)
  const durationB = parseIsoDurationString(durationBString)
  const secondsA = durationToSeconds(durationA)
  const secondsB = durationToSeconds(durationB)
  return secondsA > secondsB ? 1 : -1
}
