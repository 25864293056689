import { NavigateFunction } from 'react-router-dom'

import { driver, DriveStep } from 'driver.js'

import { UserInfo } from 'models/user-info'
import { ResearchArea } from 'openapi/models/ResearchArea'

import { waitForElement } from 'utils/utils'

import { useProductTourStore } from 'components/common/product-tours/product-tour-store'
import { ResearchAreaToDetail } from 'components/research/research-definitions'
import { userHasResearchPermission } from 'components/research/research-helpers'

export const runHarveyV1ProductTour = (
  userInfo: UserInfo,
  navigate: NavigateFunction
) => {
  const startRoute = window.location.pathname
  navigate('/assistant')

  const steps: DriveStep[] = [
    {
      element: '#sidebar-item--Assistant',
      popover: {
        title: 'Assistant',
        description:
          'This is where you can ask a question or give an instruction and receive a response based on the documents you upload at the time of query.',
      },
    },
  ]

  if (userInfo.IsAssistantV2User) {
    steps.push(
      {
        element: '#assistant-mode-assist',
        popover: {
          title: 'Assist mode',
          description:
            'Assist mode is well-suited for tasks that require complex reasoning and analysis',
        },
      },
      {
        element: '#assistant-mode-draft',
        popover: {
          title: 'Draft mode',
          description:
            'Draft mode excels at summarizing and writing long-form content, like contracts or emails',
        },
      },
      {
        element: '#assistant-text-input',
        popover: {
          title: 'Query box',
          description: 'Choose a mode and type your query into text box.',
        },
      }
    )
    if (
      userInfo.IsDocQaUser ||
      userInfo.IsMultiDocQaUser ||
      userInfo.IsCorpusQaUser
    ) {
      steps.push({
        element: '#assistant-document-input',
        popover: {
          title: 'Upload source material',
          description:
            'Use this upload box to upload files to Assistant. Your query will be answered based on the documents you upload.',
        },
      })
    }
    steps.push({
      element: '#assistant-submit',
      popover: {
        title: 'Submit button',
        description: 'Click “Ask Harvey” to submit each new query.',
      },
    })
  } else {
    steps.push({
      element: '#assistant-text-input',
      popover: {
        title: 'Query box',
        description: 'Type a question or an instruction in this text box.',
      },
    })

    if (
      userInfo.IsDocQaUser ||
      userInfo.IsMultiDocQaUser ||
      userInfo.IsCorpusQaUser
    ) {
      steps.push({
        element: '#assistant-document-input',
        popover: {
          title: 'Document upload box',
          description:
            'Use this upload box to upload files to Assistant. Your query will be answered based on the documents you upload.',
        },
      })
    }

    steps.push({
      element: '#assistant-submit',
      popover: {
        title: 'Submit button',
        description: 'Click “Ask Harvey” to submit each new query.',
      },
    })

    steps.push({
      element: '#assistant-response',
      popover: {
        title: 'Response panel',
        description:
          'The response to your query will display here after a brief loading time. Below the response will be references that you can use to check Harvey’s work. When a response has been generated, an export button will appear in the bottom right corner of this panel. Clicking the export button will download the response as a Word document.',
      },
    })
  }

  const availableResearchAreas = Object.values(ResearchArea)
    .filter((area) => userHasResearchPermission(userInfo, area))
    .map((area) => ResearchAreaToDetail[area])

  if (availableResearchAreas.length) {
    // /research page
    steps.push({
      element: '#sidebar-item--Research',
      popover: {
        title: 'Research',
        description:
          'This is where you can ask a question or give an instruction and receive a response based on an underlying knowledge source.',
        onNextClick: async () => {
          navigate('/research')
          await waitForElement('.research-area-card')
          driverObj.moveNext()
        },
      },
    })
    steps.push({
      element: '.research-area-card',
      popover: {
        title: 'Research area',
        description: 'Click on a topic to start researching in that area.',
        onPrevClick: async () => {
          navigate('/assistant')
          await waitForElement('#assistant-container')
          driverObj.movePrevious()
        },
        onNextClick: async () => {
          navigate(availableResearchAreas[0].path)
          await waitForElement('#research-container')
          driverObj.moveNext()
        },
      },
    })
    // /research/:area page
    steps.push({
      element: '.research-input-query',
      popover: {
        title: 'Query box',
        description: 'Type a question or an instruction in this text box.',
        onPrevClick: async () => {
          navigate('/research')
          await waitForElement('#research-index-container')
          driverObj.movePrevious()
        },
      },
    })
    steps.push({
      element: '.research-explorer',
      popover: {
        title: 'Filters',
        description:
          'Check one or more of these boxes to narrow down the sources of knowledge that will be used to respond to your query.',
      },
    })
    steps.push({
      element: '.research-submit',
      popover: {
        title: 'Submit button',
        description: 'Click “Ask Harvey” to submit each new query.',
      },
    })
  }

  if (userInfo.IsHarveyV1WorkflowUser) {
    // /workflows page
    steps.push({
      element: '#sidebar-item--Workflows',
      popover: {
        title: 'Workflows',
        description:
          'This is where you can have Harvey generate a specific work product using the document or other input you provide.',
        onPrevClick: async () => {
          if (availableResearchAreas.length) {
            navigate(availableResearchAreas[0].path)
            await waitForElement('#research-container')
          } else {
            navigate('/assistant')
            await waitForElement('#assistant-container')
          }
          driverObj.movePrevious()
        },
        onNextClick: async () => {
          navigate('/workflows')
          await waitForElement('#workflows-available-workflows')
          driverObj.moveNext()
        },
      },
    })

    steps.push({
      element: '#workflows-available-workflows',
      popover: {
        title: 'Workflows',
        description:
          'Click on one of the available workflows to start using it.',
      },
    })
  }

  if (userInfo.IsHistoryUser) {
    steps.push({
      element: '#sidebar-item--History',
      popover: {
        title: 'History',
        description:
          'This is where you can find and recover your previous queries.',
        onPrevClick: async () => {
          if (userInfo.IsHarveyV1WorkflowUser) {
            navigate('/workflows')
            await waitForElement('#workflows-available-workflows')
          } else if (availableResearchAreas.length) {
            navigate(availableResearchAreas[0].path)
            await waitForElement('#research-container')
          } else {
            navigate('/assistant')
            await waitForElement('#assistant-container')
          }
          driverObj.movePrevious()
        },
      },
    })
  }

  if (userInfo.IsLibraryUser) {
    steps.push({
      element: '#sidebar-item--Library',
      popover: {
        title: 'Library',
        description:
          'This is where you can find example queries that show you how to use Harvey, as well as prompts to help you quickly accomplish specific tasks.',
        onPrevClick: async () => {
          if (!userInfo.IsHistoryUser) {
            if (userInfo.IsHarveyV1WorkflowUser) {
              navigate('/workflows')
              await waitForElement('#workflows-available-workflows')
            } else if (availableResearchAreas.length) {
              navigate(availableResearchAreas[0].path)
              await waitForElement('#research-container')
            } else {
              navigate('/assistant')
              await waitForElement('#assistant-container')
            }
          }
          driverObj.movePrevious()
        },
      },
    })
  }

  if (userInfo.isClientMattersReadUser) {
    steps.push({
      element: '#client-matter-select',
      popover: {
        title: 'Client matter #',
        description:
          'Select a client matter # to mark your queries with the number. This will help keep track of usage and billing per client.',
        onPrevClick: async () => {
          if (userInfo.IsHistoryUser) {
            navigate('/history')
            await waitForElement('#sidebar-item--History')
          } else if (userInfo.IsHarveyV1WorkflowUser) {
            navigate('/workflows')
            await waitForElement('#workflows-available-workflows')
          } else if (availableResearchAreas.length) {
            navigate(availableResearchAreas[0].path)
            await waitForElement('#research-container')
          } else {
            navigate('/assistant')
            await waitForElement('#assistant-container')
          }
          driverObj.movePrevious()
        },
        onNextClick: async () => {
          navigate('/settings/client-matters')
          await waitForElement('#settings-client-matters')
          driverObj.moveNext()
        },
      },
    })
    steps.push({
      element: '#settings-client-matters',
      popover: {
        title: 'Client matters',
        description:
          'This is where you can add new client matters or see stats on existing client matters.',
        onPrevClick: async () => {
          navigate('/assistant')
          await waitForElement('#assistant-container')
          driverObj.movePrevious()
        },
      },
    })
  }

  const closeProductTour = () => {
    useProductTourStore.getState().endTour()
    driverObj.destroy()
    navigate(startRoute)
  }

  const driverObj = driver({
    showProgress: true,
    steps,
    disableActiveInteraction: true,
    onCloseClick: closeProductTour,
    onDestroyStarted: async () => {
      if (!driverObj.hasNextStep()) {
        closeProductTour()
      }
    },
  })

  driverObj.drive()
  useProductTourStore.getState().startTour()
}
