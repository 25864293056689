// This is a markdown file, exported as a single javascript string

const markdownContent = `
# My Fun announcement

Hi, hello there. Can you see this announcement? It looks good, right?

How do you like it?

## Second part

This is just another paragraph, FYI.

# The end

And that is it!

`

export default markdownContent
