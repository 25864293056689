/* tslint:disable */
/* eslint-disable */
/**
 * Harvey Web App - Streaming Protocol (via websockets)
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Annotation } from './Annotation';
import {
    instanceOfAnnotation,
    AnnotationFromJSON,
    AnnotationFromJSONTyped,
    AnnotationToJSON,
    AnnotationToJSONTyped,
} from './Annotation';
import type { Source } from './Source';
import {
    instanceOfSource,
    SourceFromJSON,
    SourceFromJSONTyped,
    SourceToJSON,
    SourceToJSONTyped,
} from './Source';

/**
 * @type SocketMessageResponseAnnotationsValue
 *
 * @export
 */
export type SocketMessageResponseAnnotationsValue = Annotation | Source;

export function SocketMessageResponseAnnotationsValueFromJSON(json: any): SocketMessageResponseAnnotationsValue {
    return SocketMessageResponseAnnotationsValueFromJSONTyped(json, false);
}

export function SocketMessageResponseAnnotationsValueFromJSONTyped(json: any, ignoreDiscriminator: boolean): SocketMessageResponseAnnotationsValue {
    if (json == null) {
        return json;
    }
    if (instanceOfAnnotation(json)) {
        return AnnotationFromJSONTyped(json, true);
    }
    if (instanceOfSource(json)) {
        return SourceFromJSONTyped(json, true);
    }

    return {} as any;
}

export function SocketMessageResponseAnnotationsValueToJSON(value?: SocketMessageResponseAnnotationsValue | null): any {
    return SocketMessageResponseAnnotationsValueToJSONTyped(value, false);
}

// As of 10/22/24, running the OpenAPI generator fails to generate this function, which is referenced by other files. As a workaround, we've manually added the
// function to the file. Hopefully, future versions of the OpenAPI generator will make this unnecessary.
export function SocketMessageResponseAnnotationsValueToJSONTyped(value?: Annotation | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    if (instanceOfAnnotation(value)) {
        return AnnotationToJSONTyped(value as Annotation, ignoreDiscriminator);
    }
    if (instanceOfSource(value)) {
        return SourceToJSONTyped(value as Source, ignoreDiscriminator);
    }

    return {};
}

