import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'

import _ from 'lodash'

import { TranscriptType } from 'openapi/models/TranscriptType'
import { TranscriptsDocumentTopic } from 'openapi/models/TranscriptsDocumentTopic'

import { PdfViewerSheet } from 'components/common/pdf-viewer/pdf-viewer-sheet'
import { ScrollArea } from 'components/ui/scroll-area'
import { TranscriptsSubheader } from 'components/workflows/workflow/transcripts/common/subheader'
import { TranscriptsTopicList } from 'components/workflows/workflow/transcripts/common/topic-list'
import { TranscriptsTopicView } from 'components/workflows/workflow/transcripts/common/topic-view'
import { TranscriptsLayout } from 'components/workflows/workflow/transcripts/layout'
import { useTranscriptsStore } from 'components/workflows/workflow/transcripts/transcripts-store'
import {
  TranscriptsDocOrQA,
  findMatchingDocumentAndSource,
} from 'components/workflows/workflow/transcripts/util/util'
import { getWitnessTopics } from 'components/workflows/workflow/transcripts/util/witness-util'

export const TranscriptsWitnessView: React.FC = () => {
  const { documents } = useTranscriptsStore()
  const { type, witnessname } = useParams<{
    type: TranscriptType
    witnessname: string
  }>()
  const [viewingTopic, setViewingTopic] = React.useState<{
    topic: TranscriptsDocumentTopic
    docOrQA: TranscriptsDocOrQA
  } | null>(null)
  const [documentSheet, setDocumentSheet] = React.useState({
    document: documents[0],
    page: 1,
    show: false,
  })

  const witness = useMemo(
    () =>
      getWitnessTopics(
        documents.filter((d) => d.metadata?.transcriptType === type),
        witnessname ?? ''
      ),
    [documents, type, witnessname]
  )

  if (!witness) {
    return (
      <TranscriptsLayout>
        <p className="mb-2 text-xl">Witness not found</p>
      </TranscriptsLayout>
    )
  }

  const handleSourceClick = (id: string, source: TranscriptsDocOrQA) => {
    const match = findMatchingDocumentAndSource(id, source)
    if (!match) return

    setDocumentSheet({
      document: match.document,
      page: match.source.page,
      show: true,
    })
  }

  return (
    <TranscriptsLayout>
      <TranscriptsSubheader documents={documents} />
      <PdfViewerSheet
        document={documentSheet.document.file}
        page={documentSheet.page}
        showSheet={documentSheet.show}
        setShowSheet={(show) => setDocumentSheet((prev) => ({ ...prev, show }))}
      />
      <div className="flex h-full flex-col space-y-4">
        <p className="mb-2 text-xl">
          <span className="mr-2">Witness overview</span>
          <span className="font-normal text-muted">
            {_.startCase(_.toLower(witnessname))}
          </span>
        </p>
        <ScrollArea className="w-full rounded-lg">
          {!viewingTopic ? (
            <TranscriptsTopicList
              titles={witness.topics.map((t) => t.topic.topic) ?? []}
              onClick={(i) => setViewingTopic(witness.topics[i])}
            />
          ) : (
            <TranscriptsTopicView
              close={() => setViewingTopic(null)}
              onSourceClick={(id: string) =>
                handleSourceClick(id, viewingTopic.docOrQA)
              }
              title={viewingTopic.topic.topic}
              markdownContent={viewingTopic.topic.summary}
            />
          )}
        </ScrollArea>
      </div>
    </TranscriptsLayout>
  )
}

export default TranscriptsWitnessView
