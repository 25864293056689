import { useState, useEffect, useCallback } from 'react'

import { remove, sortBy, isNil } from 'lodash'

import {
  FetchWorkspaces,
  FetchWorkspace,
  type Workspace,
} from 'models/workspace'

const useWorkspaceOrgDetail = ({
  workspaceId,
}: {
  workspaceId: string | undefined
}) => {
  const [workspaces, setWorkspaces] = useState<Workspace[]>([])
  const [workspace, setWorkspace] = useState<Workspace | null>(null)
  const [isLoadingWorkspaces, setIsLoadingWorkspaces] = useState(true)

  const fetch = useCallback(async () => {
    const workspaces = (await FetchWorkspaces()).filter((w) => !w.deletedAt)
    const sortedWorkspaces = sortBy(workspaces, [(w) => w.clientName ?? w.slug])
    if (!isNil(workspaceId)) {
      const workspaceIdNumber = parseInt(workspaceId)
      remove(sortedWorkspaces, (w) => w.id === workspaceIdNumber)
      const workspace = await FetchWorkspace(workspaceIdNumber)
      setWorkspace(workspace)
    }
    setWorkspaces(sortedWorkspaces)
    setIsLoadingWorkspaces(false)
  }, [workspaceId])

  useEffect(() => {
    void fetch()
  }, [fetch])

  return { workspaces, workspace, isLoadingWorkspaces }
}

export default useWorkspaceOrgDetail
