import React, { useState } from 'react'

import { useShallow } from 'zustand/react/shallow'

import { displayErrorMessage, displaySuccessMessage } from 'utils/toast'

import { Button } from 'components/ui/button'
import {
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogContent,
  Dialog,
} from 'components/ui/dialog'
import { DeleteDraftVaultWorkflow } from 'components/vault/utils/vault-fetcher'
import useVaultWorkflowStore, {
  WorkflowModalState,
} from 'components/vault/workflows/vault-workflow-store'

const VaultDeleteWorkflow: React.FC = () => {
  const [isDeleting, setIsDeleting] = useState(false)
  const [
    workflowModalState,
    setWorkflowModalState,
    selectedWorkflow,
    removeWorkflow,
  ] = useVaultWorkflowStore(
    useShallow((state) => [
      state.workflowModalState,
      state.setWorkflowModalState,
      state.selectedWorkflow,
      state.removeWorkflow,
    ])
  )

  const handleDelete = async () => {
    if (!selectedWorkflow) return

    setIsDeleting(true)
    try {
      await DeleteDraftVaultWorkflow(selectedWorkflow.id)
      displaySuccessMessage('Workflow deleted successfully')
      removeWorkflow(selectedWorkflow.id)
      setWorkflowModalState(WorkflowModalState.Selector)
    } catch (error) {
      displayErrorMessage('Failed to delete workflow')
    } finally {
      setIsDeleting(false)
    }
  }

  return (
    <Dialog
      open={workflowModalState === WorkflowModalState.Delete}
      onOpenChange={(open) => {
        if (!open) {
          setWorkflowModalState(WorkflowModalState.Selector)
        }
      }}
    >
      <DialogContent className="bg-secondary sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Delete Workflow</DialogTitle>
          <DialogDescription>
            Are you sure you want to delete this workflow? This action cannot be
            undone.
          </DialogDescription>
        </DialogHeader>
        <div className="flex justify-end space-x-2 pt-4">
          <Button
            variant="outline"
            onClick={() => setWorkflowModalState(WorkflowModalState.Selector)}
          >
            Cancel
          </Button>
          <Button
            variant="destructive"
            onClick={handleDelete}
            disabled={isDeleting}
          >
            {isDeleting ? 'Deleting…' : 'Delete'}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default VaultDeleteWorkflow
