import { Annotation } from 'openapi/models/Annotation'
import { EventKind } from 'openapi/models/EventKind'
import { ExternalWebSource } from 'openapi/models/ExternalWebSource'
import { PdfKitSource } from 'openapi/models/PdfKitSource'
import { Source as OpenApiSource } from 'openapi/models/Source'
import { UploadedFile } from 'openapi/models/UploadedFile'

// Aliases the OpenAPI enum (which is source of truth). The rename is just for legacy compatibility.
export { EventKind as TaskType }

// Terminal states in here mirrors backend event status in models/event.py
export enum TaskStatus {
  STARTED = 'STARTED',
  PENDING = 'PENDING',
  IN_PROGRESS = 'IN_PROGRESS',
  SOURCING = 'SOURCING',
  COMPLETED = 'COMPLETED',
  ERRORED = 'ERRORED',
  CANCELLED = 'CANCELLED',
}

export type Source = OpenApiSource & PdfKitSource & ExternalWebSource

export type AnnotationById = {
  [key: string]: Annotation | Source
}

export interface AssistantDocument extends UploadedFile {
  mimeType?: string
  file?: File
  uploadPromise?: Promise<UploadedFile>
}
