/* tslint:disable */
/* eslint-disable */
/**
 * Harvey Web App - Streaming Protocol (via websockets)
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import { HallucinationWarningAnnotation, HallucinationWarningAnnotationFromJSONTyped, HallucinationWarningAnnotationToJSON, HallucinationWarningAnnotationToJSONTyped } from './HallucinationWarningAnnotation';
/**
 * 
 * @export
 * @interface Annotation
 */
export interface Annotation {
    /**
     * 
     * @type {string}
     * @memberof Annotation
     */
    id: string;
    /**
     * The type of annotation. This can be 'hallucination_warning' for hallucination warnings.
     * @type {string}
     * @memberof Annotation
     */
    annotationType?: string;
}

/**
 * Check if a given object implements the Annotation interface.
 */
export function instanceOfAnnotation(value: object): value is Annotation {
    if (!('id' in value) || value['id'] === undefined) return false;
    return true;
}

export function AnnotationFromJSON(json: any): Annotation {
    return AnnotationFromJSONTyped(json, false);
}

export function AnnotationFromJSONTyped(json: any, ignoreDiscriminator: boolean): Annotation {
    if (json == null) {
        return json;
    }
    if (!ignoreDiscriminator) {
        if (json['annotation_type'] === 'hallucination_warning') {
            return HallucinationWarningAnnotationFromJSONTyped(json, ignoreDiscriminator);
        }
    }
    return {
        
        'id': json['id'],
        'annotationType': json['annotation_type'] == null ? undefined : json['annotation_type'],
    };
}

  export function AnnotationToJSON(json: any): Annotation {
      return AnnotationToJSONTyped(json, false);
  }

  export function AnnotationToJSONTyped(value?: Annotation | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    if (!ignoreDiscriminator) {
        switch (value['annotationType']) {
            case 'hallucination_warning':
                return HallucinationWarningAnnotationToJSONTyped(value as HallucinationWarningAnnotation, ignoreDiscriminator);
            default:
                throw new Error(`No variant of Annotation exists with 'annotationType=${value['annotationType']}'`);
        }
    }

    return {
        
        'id': value['id'],
        'annotation_type': value['annotationType'],
    };
}

