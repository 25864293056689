/* tslint:disable */
/* eslint-disable */
/**
 * Harvey Web App - User Permissions
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Enum for specifying the default workspace roles
 * @export
 */
export const DefaultRole = {
    BASE: 'base',
    ADMIN: 'admin',
    VAULT_ADD_ON: 'vault-add-on',
    VAULT_REVIEW_PACK: 'vault-review-pack',
    API: 'api'
} as const;
export type DefaultRole = typeof DefaultRole[keyof typeof DefaultRole];


export function instanceOfDefaultRole(value: any): boolean {
    for (const key in DefaultRole) {
        if (Object.prototype.hasOwnProperty.call(DefaultRole, key)) {
            if (DefaultRole[key as keyof typeof DefaultRole] === value) {
                return true;
            }
        }
    }
    return false;
}

export function DefaultRoleFromJSON(json: any): DefaultRole {
    return DefaultRoleFromJSONTyped(json, false);
}

export function DefaultRoleFromJSONTyped(json: any, ignoreDiscriminator: boolean): DefaultRole {
    return json as DefaultRole;
}

export function DefaultRoleToJSON(value?: DefaultRole | null): any {
    return value as any;
}

export function DefaultRoleToJSONTyped(value: any, ignoreDiscriminator: boolean): DefaultRole {
    return value as DefaultRole;
}

