import React, { useState } from 'react'

import Services from 'services'
import { Maybe } from 'types'

import { displayErrorMessage, displaySuccessMessage } from 'utils/toast'

import { useAssistantStore } from 'components/assistant/stores/assistant-store'
import { useHistoryMetadataStore } from 'components/history/history-metadata-store'

import { useAssistantAnalytics } from './use-assistant-analytics'

export const useStarThread = (
  eventId: string | null,
  folderId: Maybe<number>
) => {
  const setFolderId = useAssistantStore((s) => s.setFolderId)
  const trackEvent = useAssistantAnalytics()

  const favoriteFolder = useHistoryMetadataStore((s) => s.favoriteFolder)
  const [isStarred, setIsStarred] = useState(
    folderId && folderId === favoriteFolder?.id
  )

  const handleStar = async (e: Event | React.MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()
    const folderId = isStarred ? null : favoriteFolder?.id
    setIsStarred(!!folderId)

    await Services.Backend.Patch<boolean>(
      `user/history/${eventId}`,
      {
        id: eventId,
        folderId,
      },
      {
        throwOnError: true,
      }
    )
      .then(() => {
        setFolderId(folderId)
        displaySuccessMessage(
          folderId ? 'Added star in History' : 'Removed star from History'
        )
        trackEvent(folderId ? 'Thread Starred' : 'Thread Unstarred', {
          eventId,
        })
      })
      .catch(() => {
        setIsStarred(!folderId)
        displayErrorMessage('Failed to update starred')
      })
  }

  return { isStarred, handleStar }
}
