import React from 'react'

import { Sparkles, Upload } from 'lucide-react'

import { TranscriptType } from 'openapi/models/TranscriptType'
import { TranscriptsDocument } from 'openapi/models/TranscriptsDocument'

import { PdfViewerSheet } from 'components/common/pdf-viewer/pdf-viewer-sheet'
import { Button } from 'components/ui/button'
import { useTranscriptsDropzone } from 'components/workflows/workflow/transcripts/hooks/use-dropzone'
import { useTranscriptsStore } from 'components/workflows/workflow/transcripts/transcripts-store'
import { findMatchingDocumentAndSource } from 'components/workflows/workflow/transcripts/util/util'

import { TranscriptsQuestionSheet } from './question-sheet'

type Props = {
  type?: TranscriptType
}

export const HeaderActions: React.FC<Props> = ({ type }) => {
  const [showSheet, setShowSheet] = React.useState(false)
  const [documentSheet, setDocumentSheet] = React.useState<{
    document: TranscriptsDocument | null
    page: number | null
    show: boolean
  }>({
    document: null,
    page: null,
    show: false,
  })
  const {
    documents,
    handleNewFiles,
    handleNewFilesCompleted,
    handleQuestion,
    handleQuestionCompleted,
    currentQuestionAnswer,
    addCurrentQuestionAnswerToQuestions,
    setStreamQuestionAnswer,
    setter,
  } = useTranscriptsStore()

  const { open } = useTranscriptsDropzone({
    handleNewFiles,
    handleNewFilesCompleted,
    documents,
    setter,
  })

  const handleSourceClick = (id: string) => {
    if (!currentQuestionAnswer) return

    const match = findMatchingDocumentAndSource(
      id,
      currentQuestionAnswer,
      documents
    )

    if (!match) return

    setDocumentSheet({
      document: match.document,
      page: match.source.page,
      show: true,
    })
  }

  const documentsUploading = documents.some((doc) => doc.isLoading)
  const askHarveyDisabled =
    documents.filter((doc) => !doc.isLoading).length === 0

  return (
    <>
      <div className="space-x-2">
        <Button variant="outline" onClick={open} disabled={documentsUploading}>
          <Upload size={16} className="mr-1.5" />
          Upload
        </Button>
        <Button
          variant="secondary"
          onClick={() => setShowSheet(true)}
          disabled={askHarveyDisabled}
        >
          <Sparkles className="mr-1.5" size={16} />
          Ask Harvey
        </Button>
      </div>
      <TranscriptsQuestionSheet
        documents={documents}
        handleQuestion={handleQuestion}
        setter={setter}
        handleQuestionCompleted={handleQuestionCompleted}
        showSheet={showSheet}
        setShowSheet={setShowSheet}
        currentQuestionAnswer={currentQuestionAnswer}
        addCurrentQuestionAnswerToQuestions={
          addCurrentQuestionAnswerToQuestions
        }
        type={type}
        onSourceClick={handleSourceClick}
        setStreamQuestionAnswer={setStreamQuestionAnswer}
      />
      {documentSheet.show && (
        <PdfViewerSheet
          document={documentSheet.document!.file}
          page={documentSheet.page!}
          showSheet={documentSheet.show}
          setShowSheet={(show) =>
            setDocumentSheet((prev) => ({ ...prev, show }))
          }
        />
      )}
    </>
  )
}
