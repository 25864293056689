import { NavigateFunction } from 'react-router-dom'

import { driver, DriveStep } from 'driver.js'
import { create } from 'zustand'

import { UserInfo } from 'models/user-info'

import { waitForElement } from 'utils/utils'

import { useClientMattersStore } from 'components/client-matters/client-matters-store'

type ProductTourState = {
  isTourActive: boolean
  startTour: () => void
  endTour: () => void
}

const useClientMatterUserTourStore = create<ProductTourState>((set) => ({
  isTourActive: false,
  startTour: () => set({ isTourActive: true }),
  endTour: () => set({ isTourActive: false }),
}))

export const runClientMatterUserTour = async (
  navigate: NavigateFunction,
  userInfo: UserInfo | undefined
) => {
  const startRoute = window.location.pathname
  navigate('/assistant')
  await waitForElement('#app-header')

  if (!userInfo) {
    return
  }

  const closeProductTour = () => {
    useClientMatterUserTourStore.getState().endTour()
    driverObj.destroy()
    navigate(startRoute)
  }

  // Computing some gating booleans
  const canAddClientMatter = useClientMattersStore.getState().canCmUserQuery()

  const canSeeHistoryTab = userInfo.IsHistoryUser

  const canSeeClientMatterSettings = userInfo.isClientMattersReadUser

  const goToPage = {
    settings: async () => {
      navigate('/settings/client-matters')
      await waitForElement('#settings-client-matters')
    },
    assistant: async () => {
      navigate('/assistant')
      await waitForElement('#assistant-container')
    },
    history: async () => {
      navigate('/history')
      await waitForElement('#table-head-query')
    },
  }

  const steps: DriveStep[] = [
    {
      // 1
      element: '#client-matter-select',
      popover: {
        title: 'Select client matter',
        description:
          'Select the client matter to be be associated with every query you run.' +
          (canAddClientMatter
            ? ' If your desired client matter is not imported into Harvey, add it here.'
            : ''),
        onNextClick: async () => {
          if (!canSeeHistoryTab && canSeeClientMatterSettings) {
            await goToPage.settings()
          }

          driverObj.moveNext()
        },
      },
    },
  ]

  if (canSeeHistoryTab) {
    steps.push({
      // 2
      element: '#sidebar-item--History',
      popover: {
        title: 'View historical client matters',
        description:
          'See which client matter was associated with each historical query. You can also adjust each query’s client matter.',
      },
    })
  }

  if (canSeeClientMatterSettings) {
    steps.push({
      // 3
      element: '#sidebar-item--Settings',
      popover: {
        title: 'View client matters',
        description: 'Go to Settings to see your client matters',
        onNextClick: async () => {
          await goToPage.settings()
          driverObj.moveNext()
        },
      },
    })

    steps.push({
      // 4
      element: '#settings-client-matters-table',
      popover: {
        title: 'View and manage client matters',
        description:
          'This is where you can see all the client matters you’ve used',
        onPrevClick: async () => {
          await goToPage.assistant()
          driverObj.movePrevious()
        },
      },
    })
  }

  const driverObj = driver({
    showProgress: true,
    smoothScroll: false,
    steps,
    disableActiveInteraction: true,
    onCloseClick: closeProductTour,
    onDestroyStarted: async () => {
      if (!driverObj.hasNextStep()) {
        closeProductTour()
      }
    },
    popoverClass: 'general-ui-tour-popover',
    nextBtnText: 'Next',
    prevBtnText: 'Previous',
  })

  driverObj.drive()
  useClientMatterUserTourStore.getState().startTour()
}
