import React from 'react'
import { useNavigate } from 'react-router-dom'

import { AppState, Auth0Provider } from '@auth0/auth0-react'

import { authDomain, authClientId, authAudience } from 'utils/server-data'

const Auth0ProviderWithRedirect = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const navigate = useNavigate()

  const onRedirectCallback = (appState: AppState | undefined) => {
    navigate(appState?.returnTo || window.location.pathname)
  }

  if (!(authDomain && authClientId)) {
    return null
  }

  return (
    <Auth0Provider
      domain={authDomain}
      clientId={authClientId}
      audience={authAudience}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  )
}

export default Auth0ProviderWithRedirect
