import React, { useEffect, useRef } from 'react'

import { cn } from 'utils/utils'

import OverflowHint, { OverflowHintSide } from './overflow-hint'

export interface TextareaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  isFluid?: boolean
  overflowHintSide?: OverflowHintSide
}

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, isFluid, overflowHintSide, ...props }, ref) => {
    const textareaRef = useRef<HTMLTextAreaElement | null>(null)
    const mergedRefs = (el: HTMLTextAreaElement) => {
      if (typeof ref === 'function') {
        ref(el)
      } else if (ref) {
        ref.current = el
      }
      textareaRef.current = el
    }

    useEffect(() => {
      if (isFluid && textareaRef.current) {
        textareaRef.current.style.height = 'auto'
        textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`
      }
    }, [isFluid, props.value])

    const innerChild = (
      innerRef:
        | React.ForwardedRef<HTMLTextAreaElement>
        | ((el: HTMLTextAreaElement) => void),
      onScroll?: (e: React.UIEvent<HTMLElement>) => void
    ) => (
      <textarea
        className={cn(
          'flex min-h-20 w-full rounded-md border border-input bg-primary px-3 py-2 text-sm ring-offset-primary placeholder:text-sm placeholder:text-muted focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
          { 'overflow-auto': isFluid },
          className
        )}
        onScroll={onScroll}
        ref={innerRef}
        {...props}
      />
    )

    if (overflowHintSide) {
      return (
        <OverflowHint side={overflowHintSide}>
          {(overflowRef, onScroll) => {
            const mergedRefs = (el: HTMLTextAreaElement) => {
              if (typeof ref === 'function') {
                ref(el)
              } else if (ref) {
                ref.current = el
              }
              overflowRef.current = el
            }
            return innerChild(mergedRefs, onScroll)
          }}
        </OverflowHint>
      )
    }

    return innerChild(mergedRefs)
  }
)
Textarea.displayName = 'Textarea'

export { Textarea }
