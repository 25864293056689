export const filterSuccessfulPromises = <T>(
  promises: PromiseSettledResult<T>[]
): T[] => {
  return promises
    .filter(
      (promise): promise is PromiseFulfilledResult<T> =>
        promise.status === 'fulfilled'
    )
    .map((promise) => promise.value)
}

export const filterRejectedPromises = <T>(
  promises: PromiseSettledResult<T>[]
): PromiseRejectedResult[] => {
  return promises.filter(
    (promise): promise is PromiseRejectedResult => promise.status === 'rejected'
  )
}
