/* tslint:disable */
/* eslint-disable */
/**
 * Harvey Web App - Internal Admin
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { TaxCorpusFilterIDs } from './TaxCorpusFilterIDs';
import {
    TaxCorpusFilterIDsFromJSON,
    TaxCorpusFilterIDsFromJSONTyped,
    TaxCorpusFilterIDsToJSON,
    TaxCorpusFilterIDsToJSONTyped,
} from './TaxCorpusFilterIDs';

/**
 * 
 * @export
 * @interface PWCTaxFeedbackExportRequest
 */
export interface PWCTaxFeedbackExportRequest {
    /**
     * 
     * @type {TaxCorpusFilterIDs}
     * @memberof PWCTaxFeedbackExportRequest
     */
    corpus: TaxCorpusFilterIDs;
    /**
     * The number of days of recent feedback to include in the export
     * @type {number}
     * @memberof PWCTaxFeedbackExportRequest
     */
    numDays: number;
    /**
     * The workspace to filter on
     * @type {string}
     * @memberof PWCTaxFeedbackExportRequest
     */
    workspaceSubstr: PWCTaxFeedbackExportRequestWorkspaceSubstrEnum;
}


/**
 * @export
 */
export const PWCTaxFeedbackExportRequestWorkspaceSubstrEnum = {
    PWC: 'pwc',
    HARVEY: 'harvey'
} as const;
export type PWCTaxFeedbackExportRequestWorkspaceSubstrEnum = typeof PWCTaxFeedbackExportRequestWorkspaceSubstrEnum[keyof typeof PWCTaxFeedbackExportRequestWorkspaceSubstrEnum];


/**
 * Check if a given object implements the PWCTaxFeedbackExportRequest interface.
 */
export function instanceOfPWCTaxFeedbackExportRequest(value: object): value is PWCTaxFeedbackExportRequest {
    if (!('corpus' in value) || value['corpus'] === undefined) return false;
    if (!('numDays' in value) || value['numDays'] === undefined) return false;
    if (!('workspaceSubstr' in value) || value['workspaceSubstr'] === undefined) return false;
    return true;
}

export function PWCTaxFeedbackExportRequestFromJSON(json: any): PWCTaxFeedbackExportRequest {
    return PWCTaxFeedbackExportRequestFromJSONTyped(json, false);
}

export function PWCTaxFeedbackExportRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PWCTaxFeedbackExportRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'corpus': TaxCorpusFilterIDsFromJSON(json['corpus']),
        'numDays': json['num_days'],
        'workspaceSubstr': json['workspace_substr'],
    };
}

  export function PWCTaxFeedbackExportRequestToJSON(json: any): PWCTaxFeedbackExportRequest {
      return PWCTaxFeedbackExportRequestToJSONTyped(json, false);
  }

  export function PWCTaxFeedbackExportRequestToJSONTyped(value?: PWCTaxFeedbackExportRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'corpus': TaxCorpusFilterIDsToJSON(value['corpus']),
        'num_days': value['numDays'],
        'workspace_substr': value['workspaceSubstr'],
    };
}

