import React from 'react'

import { Folder } from 'lucide-react'

import { VaultFolder } from 'openapi/models/VaultFolder'

import { Badge } from 'components/ui/badge'
import {
  DialogHeader,
  DialogTitle,
  DialogDescription,
} from 'components/ui/dialog'
import Icon from 'components/ui/icon/icon'

export const VaultDialogHeader = ({
  dialogTitle,
  dialogDescription,
  currentFolder,
}: {
  dialogTitle: string
  dialogDescription: string
  currentFolder: VaultFolder | null
}) => {
  return (
    <DialogHeader>
      <DialogTitle>{dialogTitle}</DialogTitle>
      {currentFolder?.parentId && (
        <DialogDescription>
          <div className="flex items-center">
            <span className="mr-1 shrink-0">{dialogDescription}</span>
            <Badge variant="secondary" className="shrink space-x-1 px-2">
              <Icon icon={Folder} />
              <span className="truncate">{currentFolder.name}</span>
            </Badge>
          </div>
        </DialogDescription>
      )}
    </DialogHeader>
  )
}
