import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'

import { UploadedFile } from 'openapi/models/UploadedFile'

import { HarveySocketSetter, HarveySocketTask } from 'utils/use-harvey-socket'
import { HarveySocketCompletionStatus } from 'utils/use-harvey-socket-utils'

import { FeedbackResult } from 'components/common/feedback/feedback-with-comments'

interface State {
  headerText: string
  query: string
  response: string
  isLoading: boolean
  queryId: string
  activeDocument: UploadedFile | File | null
  feedback: FeedbackResult | null
  progress: number
  exportUrl: string | null
  completionStatus: HarveySocketCompletionStatus | null
}

interface Action {
  setTask: HarveySocketSetter
  setActiveDocument: (document: UploadedFile | File | null) => void
  setFeedback: (feedback: FeedbackResult | null) => void
  setExportUrl: (url: string | null) => void
  reset: () => void
}

const initialState: State = {
  headerText: '',
  query: '',
  response: '',
  isLoading: false,
  queryId: '',
  activeDocument: null,
  feedback: null,
  progress: 0,
  exportUrl: null,
  completionStatus: null,
}

export const useOgcStore = create(
  devtools(
    immer<State & Action>((set) => ({
      ...initialState,
      reset: () => {
        set(() => ({
          ...initialState,
        }))
      },
      setFeedback: (feedback: FeedbackResult | null) => {
        set(() => ({
          feedback,
        }))
      },
      setTask: (socketState: Partial<HarveySocketTask>) => {
        set(() => ({
          ...socketState,
        }))
      },
      setActiveDocument: (document: UploadedFile | File | null) => {
        set(() => ({
          activeDocument: document,
        }))
      },
      setExportUrl: (url: string | null) => {
        set(() => ({
          exportUrl: url,
        }))
      },
    }))
  )
)
