import { UserInfo } from 'models/user-info'
import { WorkflowType } from 'types/workflows'

import { WorkflowTypeToDetails } from './workflow-definitions'

export const userHasPermissionForWorkflow = (
  userInfo: UserInfo,
  workflowType: WorkflowType
) => {
  return (
    userInfo.IsHarveyV1WorkflowUser &&
    WorkflowTypeToDetails[workflowType].permissions.some((permission) =>
      userInfo.permissions.includes(permission)
    )
  )
}
