import React, { useRef } from 'react'

import { isNil } from 'lodash'
import { Clipboard } from 'lucide-react'

import { useCaseLawCourtCitationQuery } from 'models/queries/use-case-law-query'

import { displayInfoMessage } from 'utils/toast'

import { getCaseDateYear } from 'components/research/research-helpers'
import { Button } from 'components/ui/button'
import Icon from 'components/ui/icon/icon'
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/ui/tooltip'

interface Props {
  title: string
  // Metadata is expected to be [Court Name, Date, Reporter]
  metadata: string[]
}

export const SourceCardArtifactCitation = ({ title, metadata }: Props) => {
  const citationRef = useRef<HTMLDivElement>(null)

  const courtName = metadata[0]

  const { data: citationData } = useCaseLawCourtCitationQuery(courtName)
  const courtCitation = citationData?.courtCitation

  const year = getCaseDateYear(metadata[1])
  const reporter = metadata[2]

  const handleCopy = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    if (!citationRef.current) return
    const plainType = 'text/plain'
    const htmlType = 'text/html'
    const innerText = citationRef.current.innerText
    const innerHtml = citationRef.current.innerHTML

    if (typeof ClipboardItem !== 'undefined') {
      // Clipboard API not fully supported in Firefox.
      // https://developer.mozilla.org/en-US/docs/Web/API/Clipboard_API#browser_compatibility
      const data = new ClipboardItem({
        [htmlType]: new Blob([innerHtml], { type: htmlType }),
        [plainType]: new Blob([innerText], { type: plainType }),
      })
      await navigator.clipboard.write([data])
    } else {
      // Fallback using the deprecated `document.execCommand`.
      // https://developer.mozilla.org/en-US/docs/Web/API/Document/execCommand#browser_compatibility
      const copyToClipBoard = (e: ClipboardEvent) => {
        if (!e.clipboardData) return
        e.clipboardData.setData(htmlType, innerHtml)
        e.clipboardData.setData(plainType, innerText)
        e.preventDefault()
      }
      document.addEventListener('copy', copyToClipBoard)
      document.execCommand('copy')
      document.removeEventListener('copy', copyToClipBoard)
    }
    displayInfoMessage('Copied citation to clipboard')
  }

  if (metadata.length !== 3 || isNil(courtCitation) || !year || !reporter) {
    return null
  }

  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <Button
          onClick={handleCopy}
          variant="ghost"
          size="xsIcon"
          className="h-5 w-5"
        >
          <Icon icon={Clipboard} size="default" className="h-3.5 w-3.5" />
          <div className="hidden" ref={citationRef}>
            <i>{title}</i>, {reporter} ({courtCitation}
            {courtCitation ? ' ' : ''}
            {year})
          </div>
        </Button>
      </TooltipTrigger>
      <TooltipContent side="right">Copy case citation</TooltipContent>
    </Tooltip>
  )
}
