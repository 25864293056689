import {
  PermissionLevel,
  PermissionsByUser,
  PermissionsByWorkspace,
} from 'types/sharing'

const permissionLevelOrder = [
  PermissionLevel.VIEW,
  PermissionLevel.EDIT,
  PermissionLevel.FULL_ACCESS,
]

/**
 * Calculates user's overall permission level for a shared resource
 * Takes the higher permission level between workspace and user permissions
 * If the user is the owner of the resource, they have owner-level permissions above Full Access
 */
export const getUserPermissionLevel = ({
  userId,
  workspaceId,
  permissionsByWorkspace,
  permissionsByUser,
}: {
  userId: string
  workspaceId: number
  permissionsByWorkspace?: PermissionsByWorkspace
  permissionsByUser?: PermissionsByUser
}): PermissionLevel | null => {
  let highestPermissionLevel: PermissionLevel | null = null
  const userPermissions = permissionsByUser?.find(
    (permission) => permission.userId === userId
  )
  if (userPermissions && userPermissions.permissionLevel) {
    highestPermissionLevel = userPermissions.permissionLevel
  }
  const workspacePermissions = permissionsByWorkspace?.find(
    (permission) => permission.workspaceId === workspaceId
  )
  if (workspacePermissions && workspacePermissions.permissionLevel) {
    if (highestPermissionLevel) {
      if (
        permissionLevelOrder.indexOf(workspacePermissions.permissionLevel) >
        permissionLevelOrder.indexOf(highestPermissionLevel)
      ) {
        // replace the user permission level with the workspace permission level if it's higher
        highestPermissionLevel = workspacePermissions.permissionLevel
      }
    } else {
      // if there's no user permission level, set the highest permission level to the workspace permission level
      highestPermissionLevel = workspacePermissions.permissionLevel
    }
  }
  return highestPermissionLevel
}

export const doesUserHavePermission = ({
  currentPermissionLevel,
  requiredPermissionLevel,
  isOwner,
}: {
  currentPermissionLevel?: PermissionLevel
  requiredPermissionLevel: PermissionLevel
  isOwner: boolean
}) => {
  if (isOwner) {
    return true
  }

  if (!currentPermissionLevel) {
    return false
  }

  return (
    permissionLevelOrder.indexOf(currentPermissionLevel) >=
    permissionLevelOrder.indexOf(requiredPermissionLevel)
  )
}
