import React from 'react'
import { NavLink as ReactRouterNavLink } from 'react-router-dom'

import { cn } from 'utils/utils'

interface Props {
  href: string
  label: string
}

export const NavLink = (props: Props): JSX.Element => {
  const isActive = window.location.pathname.startsWith(props.href)

  return (
    <ReactRouterNavLink
      to={props.href}
      key={props.href}
      id={`${props.href.replace(/\//g, '')}-nav-link`}
      className={cn(
        'hover:text flex h-8 shrink-0 items-center rounded bg-transparent px-2  text-sm text-muted transition hover:bg-secondary',
        {
          'bg-secondary text-primary': isActive,
        }
      )}
    >
      {props.label}
    </ReactRouterNavLink>
  )
}
