import { SharingSettings } from 'openapi/models/SharingSettings'
import Services from 'services'
import { RequestError } from 'services/backend/backend'

export async function fetchSharingSettingsForWorkspace(
  workspaceId: number
): Promise<SharingSettings> {
  const url = `settings/${workspaceId}/sharing`
  const response = await Services.Backend.Get<SharingSettings>(url, {
    throwOnError: true,
  })
  if (response instanceof RequestError) {
    throw response
  }
  return response
}

export async function updateSharingSettingsForWorkspace(
  workspaceId: number,
  sharingSettingsData: SharingSettings
): Promise<SharingSettings> {
  const url = `settings/${workspaceId}/sharing`
  const response = await Services.Backend.Post<SharingSettings>(
    url,
    sharingSettingsData,
    {
      throwOnError: true,
    }
  )
  if (response instanceof RequestError) {
    throw response
  }
  return response
}
