/* tslint:disable */
/* eslint-disable */
/**
 * Error Handling
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Enumeration of possible error kinds
 * @export
 */
export const ErrorKind = {
    INVALID_CLAIMS: 'invalid_claims',
    INVALID_HEADER: 'invalid_header',
    INVALID_USER: 'invalid_user',
    ACCESS_TOKEN_MALFORMED: 'access_token_malformed',
    ACCESS_TOKEN_EXPIRED: 'access_token_expired',
    RATE_LIMITED: 'rate_limited',
    QUERY_LIMIT_EXCEEDED: 'query_limit_exceeded',
    USER_ERROR_MESSAGE: 'user_error_message',
    UNPROCESSABLE_CONTENT: 'unprocessable_content',
    NOT_FOUND: 'not_found',
    INTERNAL_SERVER_ERROR: 'internal_server_error',
    STALE_LOGIN: 'stale_login',
    OPENAI_ERROR: 'openai_error',
    AZURE_OPENAI_ERROR: 'azure_openai_error',
    EMBEDDING_ERROR: 'embedding_error',
    AZURE_EMBEDDING_ERROR: 'azure_embedding_error',
    MIGRATED_TO_OTHER_SITE: 'migrated_to_other_site',
    CAP_IQ_ERROR: 'cap_iq_error',
    WORKSPACE_NOT_ACTIVE: 'workspace_not_active'
} as const;
export type ErrorKind = typeof ErrorKind[keyof typeof ErrorKind];


export function instanceOfErrorKind(value: any): boolean {
    for (const key in ErrorKind) {
        if (Object.prototype.hasOwnProperty.call(ErrorKind, key)) {
            if (ErrorKind[key as keyof typeof ErrorKind] === value) {
                return true;
            }
        }
    }
    return false;
}

export function ErrorKindFromJSON(json: any): ErrorKind {
    return ErrorKindFromJSONTyped(json, false);
}

export function ErrorKindFromJSONTyped(json: any, ignoreDiscriminator: boolean): ErrorKind {
    return json as ErrorKind;
}

export function ErrorKindToJSON(value?: ErrorKind | null): any {
    return value as any;
}

