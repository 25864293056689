import React, { useEffect, useRef, useState } from 'react'

import { useAuth0 } from '@auth0/auth0-react'

import { updateUserSettings } from 'models/user-settings'
import { WelcomeScreenAction } from 'models/user-settings'
import { WelcomeScreen } from 'models/workspace'
import Services from 'services'
import type TelemtryEvent from 'services/honey-comb/telemetry-event'

import { useAuthUser } from 'components/common/auth-context'
import { Button } from 'components/ui/button'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from 'components/ui/dialog'

const WelcomeInterstitial = () => {
  const { user, logout } = useAuth0()
  const telEventRef = useRef<TelemtryEvent | null>(null)

  const userInfo = useAuthUser()
  const [interstitial, setInterstitial] = useState<WelcomeScreen | undefined>(
    userInfo.GetInterstitial()
  )

  const onCancel = (): void => {
    telEventRef.current?.Finish({ action: WelcomeScreenAction.DECLINED })
    logout({ returnTo: window.location.origin })
  }

  const onOk = async (): Promise<void> => {
    if (!user || !interstitial?.id) {
      return
    }

    const wc: any = userInfo.WelcomeScreens ?? {}
    wc[interstitial.id] = WelcomeScreenAction.ACCEPTED
    telEventRef.current?.Finish({ action: WelcomeScreenAction.ACCEPTED })
    telEventRef.current = null
    setInterstitial(undefined)

    await updateUserSettings({
      welcomeScreens: wc,
    })
  }

  useEffect(() => {
    if (!interstitial) return

    telEventRef.current = Services.HoneyComb.Start({
      metric: 'ui.show_welcome_screen',
      welcome_screen_id: interstitial.id,
      welcome_screen_type: interstitial.type,
      task_type: interstitial.taskType,
    })
  }, [interstitial])

  if (!interstitial) return

  return (
    <Dialog open>
      <DialogContent showCloseIcon={false}>
        <DialogHeader>
          <DialogTitle>{interstitial.title || 'Important notice'}</DialogTitle>
        </DialogHeader>
        <div
          className="flex flex-col gap-4"
          dangerouslySetInnerHTML={{ __html: interstitial.content }}
        />
        <DialogFooter>
          <DialogClose asChild>
            <Button onClick={onOk}>Accept</Button>
          </DialogClose>
          <DialogClose asChild>
            <Button onClick={onCancel} variant="outline">
              Decline
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default WelcomeInterstitial
