import { useEffect } from 'react'

const useCommandEnter = (
  callback: (...args: any[]) => void,
  disabled: boolean,
  ref?: React.MutableRefObject<
    HTMLTextAreaElement | HTMLInputElement | HTMLDivElement | null
  >
) => {
  useEffect(() => {
    const handleKeyDown = (event: Event) => {
      if (
        event instanceof KeyboardEvent &&
        event.key === 'Enter' &&
        (event.metaKey || event.ctrlKey) &&
        !disabled
      ) {
        callback()
      }
    }

    const element = ref?.current
    element?.addEventListener('keydown', handleKeyDown)

    return () => {
      element?.removeEventListener('keydown', handleKeyDown)
    }
  }, [callback, disabled, ref])

  return ref
}

export default useCommandEnter
