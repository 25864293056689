import React from 'react'

import _ from 'lodash'
import { useShallow } from 'zustand/react/shallow'

import { Workspace } from 'models/workspace'
import { useClientAdminStore } from 'stores/client-admin-store'

import { useAuthUser } from 'components/common/auth-context'
import MultiUserAddRemove from 'components/settings/user-management/multi-user-add-remove'
import { Card, CardContent, CardHeader, CardTitle } from 'components/ui/card'

const MAX_NUM_USERS = 100

interface CustomerUserAddCardProps {
  workspace: Workspace
}

const CustomerUserAddCard = ({ workspace }: CustomerUserAddCardProps) => {
  const [fetchUsers] = useClientAdminStore(useShallow((s) => [s.fetchUsers]))
  const userInfo = useAuthUser()

  if (_.isNil(userInfo) || !userInfo.IsClientAdminAddUsers) return <></>

  return (
    <Card className="mb-6 w-full">
      <CardHeader>
        <CardTitle className="mb-3">Add users</CardTitle>
        <div className="text-sm">
          Add users to Harvey by entering their email addresses, with one email
          per line (maximum {MAX_NUM_USERS}). Users will not be notified, but
          will have access to Harvey next time they attempt to log in.
        </div>
      </CardHeader>
      <CardContent>
        <MultiUserAddRemove
          customerFacing
          helpMessage={<></>}
          maxNumUsers={MAX_NUM_USERS}
          type="add"
          workspace={workspace}
          displayDownloadButton={false}
          onAdd={async () => await fetchUsers(workspace.id)}
        />
      </CardContent>
    </Card>
  )
}

export default CustomerUserAddCard
