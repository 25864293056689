import React, { useMemo, useEffect, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { useUnmount } from 'react-use'

import { ColDef, ValueGetterParams, IRowNode } from 'ag-grid-community'
import { isNil } from 'lodash'

import { VaultFile } from 'openapi/models/VaultFile'
import { VaultFolder } from 'openapi/models/VaultFolder'

import { parseIsoString } from 'utils/utils'

import LoadingBarBelowAppHeader from 'components/common/loading-bar-below-app-header'
import { VaultAddFilesDialog } from 'components/vault/dialogs/vault-add-files-dialog'
import {
  ColumnDataType,
  GenerateNNResponseProps,
  ReviewAnswer,
} from 'components/vault/utils/vault'
import {
  FilterType,
  useVaultDataGridFilterStore,
} from 'components/vault/utils/vault-data-grid-filters-store'
import {
  computeRowDataDiff,
  getDisplayAnswer,
  getDisplayedRows,
  getFoldersOnPath,
  isAnswerEmpty,
} from 'components/vault/utils/vault-helpers'
import { useVaultStore } from 'components/vault/utils/vault-store'

import CellViewer from './cell-viewer'
import VaultDataGridHeader from './header/vault-data-grid-header'
import VaultDataGrid from './vault-data-grid'

const MIN_PROGRESS = 20
export const EXCLUDED_HEADER_NAMES = ['#', 'Name', 'Folder path', 'gutter']
export const EXCLUDED_HEADER_NAMES_FROM_EXPORT = ['folderPath', 'gutter']
export const EXCLUDED_HEADER_NAMES_FROM_RESIZE = ['row', 'gutter']
export const EXCLUDED_HEADER_NAMES_FROM_COLUMN_CLICK = ['row', 'gutter', 'name']
export const EXCLUDED_HEADER_NAMES_FROM_ADD_COLUMN = ['gutter']
export const FILTER_HEADER_NAMES = ['Name']
export const HIDDEN_COLUMN_ID_SUFFIX = '-hidden'

export interface ExtendedColumnDef extends ColDef {
  field: string
  headerName: string
}
export interface QuestionColumnDef extends ExtendedColumnDef {
  originalQuestion: string
  headerName: string
  columnDataType: ColumnDataType
  field: string
  questionId: string
  folderPath: string
}

const VaultQueryResultTable = ({
  generateNNResponse,
}: {
  generateNNResponse: (props: GenerateNNResponseProps) => Promise<void>
}) => {
  const { queryId } = useParams()

  const gridApi = useVaultStore((s) => s.gridApi)
  const queryIdToState = useVaultStore((s) => s.queryIdToState)
  const queryIdToReviewState = useVaultStore((s) => s.queryIdToReviewState)
  const currentProjectMetadata = useVaultStore((s) => s.currentProjectMetadata)
  const fileIdToVaultFile = useVaultStore((s) => s.fileIdToVaultFile)
  const folderIdToVaultFolder = useVaultStore((s) => s.folderIdToVaultFolder)

  const setHasRowDataApplied = useVaultDataGridFilterStore(
    (s) => s.setHasRowDataApplied
  )
  const isShowingLongResponses = useVaultDataGridFilterStore(
    (s) => s.isShowingLongResponses
  )
  const setCurrentSortColumnId = useVaultDataGridFilterStore(
    (s) => s.setCurrentSortColumnId
  )
  const setDisplayedRows = useVaultDataGridFilterStore(
    (state) => state.setDisplayedRows
  )

  const setAgGridHeaderHeight = useVaultDataGridFilterStore(
    (s) => s.setAgGridHeaderHeight
  )

  const queryFiles = useMemo(
    () =>
      (queryIdToState[queryId!]?.fileIds
        .map((id) => fileIdToVaultFile[id])
        .filter(Boolean) || []) as VaultFile[],
    [queryIdToState, queryId, fileIdToVaultFile]
  )
  const processedFileIdsSet = useMemo(
    () =>
      new Set(
        queryIdToReviewState[queryId!]?.processedFileIds.filter(
          (id) => !!fileIdToVaultFile[id]
        ) || []
      ),
    [queryIdToReviewState, queryId, fileIdToVaultFile]
  )
  const suppressedFileIdsSet = useMemo(
    () =>
      new Set(
        queryIdToReviewState[queryId!]?.suppressedFileIds.filter(
          (id) => !!fileIdToVaultFile[id]
        ) || []
      ),
    [queryIdToReviewState, queryId, fileIdToVaultFile]
  )
  const fileIdToSources = useMemo(
    () => queryIdToReviewState[queryId!]?.fileIdToSources || {},
    [queryIdToReviewState, queryId]
  )
  const fileIdToAnswers = useMemo(
    () => queryIdToReviewState[queryId!]?.answers || {},
    [queryIdToReviewState, queryId]
  )
  const fileIdToErrors = useMemo(
    () => queryIdToReviewState[queryId!]?.errors || {},
    [queryIdToReviewState, queryId]
  )
  const questions = useMemo(
    () => queryIdToReviewState[queryId!]?.questions || [],
    [queryIdToReviewState, queryId]
  )

  const isDryRun = queryIdToState[queryId!]?.dryRun ?? false
  const isLoading = queryIdToState[queryId!]?.isLoading
  const progress = useMemo(() => {
    if (queryFiles.length === 0 || questions.length === 0) {
      return 0
    }
    let processedCells = 0
    for (const file of queryFiles) {
      // Count the number of unique questions that have been processed
      const answeredColumns = (fileIdToAnswers[file.id] || []).map(
        (answer) => answer.columnId
      )
      const errorColumns = (fileIdToErrors[file.id] || []).map(
        (error) => error.columnId
      )
      processedCells += new Set(answeredColumns.concat(errorColumns)).size
    }
    return (processedCells / queryFiles.length / questions.length) * 100
  }, [queryFiles, questions, fileIdToAnswers, fileIdToErrors])

  const columnIdToHeader = useCallback(
    (columnId: string, question: { id: string; text: string }) =>
      queryIdToReviewState[queryId!]?.columnHeaders.find(
        (columnHeader) => columnHeader.id === columnId
      )?.text ||
      question.text ||
      '',
    [queryIdToReviewState, queryId]
  )

  const columnIdToDataType = useCallback(
    (columnId: string) =>
      queryIdToReviewState[queryId!]?.columnHeaders.find(
        (columnHeader) => columnHeader.id === columnId
      )?.columnDataType ?? ColumnDataType.string,
    [queryIdToReviewState, queryId]
  )

  const setupGridRowColumnData = useCallback(() => {
    if (!gridApi) return

    // When we are in the start from scratch flow the header height is set to 0
    // this is to prevent the header from being shown when there are no files
    // and then we will show the header when the files are added
    // additionally we need to update the zustand store because it's need to control the border-b class of the data-grid header
    if (queryFiles.length !== 0) {
      gridApi.setGridOption('headerHeight', 32)
      setAgGridHeaderHeight(32)
    }
    const questionIds = questions.map((question) => question.id)
    const projectFolders = [
      currentProjectMetadata,
      ...(currentProjectMetadata.descendantFolders ?? []),
    ]

    const shouldGroupRows = projectFolders.length > 1
    // we only want to compute the column definition the first time
    // if we compute every time the data changes, then the column widths will get reset
    const currentColumnDef = gridApi.getColumnDefs()

    // need to update the column defs if:
    // 1. the column defs are undefined
    // 2. the column defs length is not equal to the questions length
    // 3. for the start from scratch flow we will have files but no questions yet
    const currentQuestionColumns =
      currentColumnDef?.filter((columnDef) => {
        const isNotGroupColumn = 'colId' in columnDef
        if (!isNotGroupColumn) return false

        const extendedColumnDef = columnDef as ExtendedColumnDef
        const isHiddenColumn = extendedColumnDef.field.endsWith(
          HIDDEN_COLUMN_ID_SUFFIX
        )
        if (isHiddenColumn) return false

        return !EXCLUDED_HEADER_NAMES.includes(extendedColumnDef.headerName)
      }) ?? []

    const needsToUpdateColumnDefs =
      currentColumnDef === undefined ||
      currentQuestionColumns.length !== questions.length ||
      (queryFiles.length > 0 && currentQuestionColumns.length === 0)

    if (needsToUpdateColumnDefs) {
      const columnDefs: Array<ExtendedColumnDef | QuestionColumnDef> = [
        {
          valueGetter: (params: ValueGetterParams) => {
            // when we have row grouping, we need to get the index from the parent
            // and subtract it from the row index because group rows also have an index
            // and we don't want to count the group rows when determining the row index
            const node = params.node
            const rowIndex = node?.rowIndex ?? 0
            if (isNil(node?.parent?.rowIndex)) return rowIndex + 1
            const parentRowChildIndex = node?.parent?.childIndex ?? 0
            return rowIndex - parentRowChildIndex
          },
          field: 'row',
          headerName: '#',
          type: 'number',
          width: 48,
          resizable: false,
          pinned: 'left',
        },
        {
          field: 'name',
          headerName: 'Name',
          type: 'document',
          pinned: 'left',
          minWidth: 256,
          flex: 4,
          cellRendererParams: {
            generateNNResponse: generateNNResponse,
          },
        },
      ]
      // if we are grouping rows by folder, then we want to add a column
      // to the left of the name column that shows the folder path
      if (shouldGroupRows) {
        columnDefs.push({
          field: 'folderPath',
          headerName: 'Folder path',
          type: 'hidden',
          rowGroup: true,
        })
      }
      const columnOrder = queryIdToReviewState[queryId!]?.columnOrder
      const sortedQuestions = columnOrder
        ? [...questions].sort((a, b) => {
            const indexA = columnOrder.indexOf(a.id)
            const indexB = columnOrder.indexOf(b.id)
            if (indexA === -1 && indexB === -1) return 0
            if (indexA === -1) return 1
            if (indexB === -1) return -1
            return indexA - indexB
          })
        : questions
      sortedQuestions.map((question) => {
        const headerName = columnIdToHeader(question.id, question)
        const dataType = columnIdToDataType(question.id)

        const visibleColumn = {
          field: question.id,
          headerName: headerName,
          columnDataType: dataType,
          type:
            dataType === ColumnDataType.date
              ? FilterType.DATE
              : FilterType.TEXT,
          minWidth: 240,
          flex: 3,
          originalQuestion: question.text,
          questionId: question.id,
          // eslint-disable-next-line max-params
          comparator: (
            valueA: string,
            valueB: string,
            nodeA: IRowNode<any>,
            nodeB: IRowNode<any>,
            isDescending: boolean
          ) => {
            if (valueA == valueB) return 0
            const answersA = nodeA.data.answers
            const answersB = nodeB.data.answers
            const answerA = answersA.find(
              (answer: ReviewAnswer) =>
                answer.columnId === question.id &&
                (isShowingLongResponses ? answer.long : !answer.long)
            )
            const answerB = answersB.find(
              (answer: ReviewAnswer) =>
                answer.columnId === question.id &&
                (isShowingLongResponses ? answer.long : !answer.long)
            )
            const isAnswerAEmpty = !answerA || isAnswerEmpty(answerA.text)
            const isAnswerBEmpty = !answerB || isAnswerEmpty(answerB.text)
            if (isAnswerAEmpty && isAnswerBEmpty) {
              return 0
            }
            if (isAnswerAEmpty) {
              // if answerA is empty, then we want to put it to the end of the list
              return isDescending ? -1 : 1
            }
            if (isAnswerBEmpty) {
              // if answerB is empty, then we want to put it to the beginning of the list
              return isDescending ? 1 : -1
            }
            if (
              answerA.columnDataType === ColumnDataType.date &&
              answerB.columnDataType === ColumnDataType.date
            ) {
              const dateA = parseIsoString(answerA.text)
              const dateB = parseIsoString(answerB.text)
              return dateA > dateB ? 1 : -1
            }
            return valueA > valueB ? 1 : -1
          },
        }
        const hiddenColumn = {
          field: `${question.id}${HIDDEN_COLUMN_ID_SUFFIX}`,
          type: 'hidden',
          headerName: headerName,
          questionId: question.id,
          originalQuestion: question.text,
        }

        // Always append short answer column first regardless of isShowingLongResponses
        if (isShowingLongResponses) {
          columnDefs.push(hiddenColumn, visibleColumn)
        } else {
          columnDefs.push(visibleColumn, hiddenColumn)
        }
      })

      // Finally push gutter column
      columnDefs.push({
        field: 'gutter',
        headerName: 'gutter',
        type: 'gutter',
        resizable: false,
        width: 20,
        // Make it really small so it can flex but not taking up space larger than 20 px by default
        flex: 0.01,
      })

      gridApi.updateGridOptions({
        columnDefs: columnDefs,
      })
    }

    const rowData = queryFiles.map((file, index) => {
      const fileId = file.id
      const folderId = file.vaultFolderId
      const foldersOnPath =
        getFoldersOnPath(folderId, folderIdToVaultFolder) ?? []
      // once we get the folders on path, we want to drop the first folder because it is the root folder
      // and we are not showing the root folder in the group row
      const folderPath = foldersOnPath
        .slice(1)
        .map((f: VaultFolder) => f.name)
        .join('/')
      const sources = fileIdToSources[fileId] || []

      const cells: { [key: string]: string } = {}
      const answers = fileIdToAnswers[fileId] || []
      const errorSuppressed = suppressedFileIdsSet.has(fileId)
      const fileProcessed = processedFileIdsSet.has(fileId)
      const getDisplayText = (answer?: ReviewAnswer) => {
        if (isDryRun && index > 0) {
          // if we are in dry run mode, then we want to show processing text for
          // the first row and then show empty text for the rest of the rows
          return '<DRY_RUN_EMPTY_CELL>'
        }
        if (!answer?.text && isLoading && !errorSuppressed) {
          // If the answer is empty and the file is still processing, then we want to show processing text
          return 'Processing…'
        }
        if (!answer?.text && !fileProcessed) {
          // If the answer is empty and the file is not processed, then we want to show empty text
          return ''
        }
        return getDisplayAnswer(answer)
      }
      questionIds.forEach((questionId) => {
        const shortAnswer = answers?.find(
          (answer) => answer.columnId === questionId && !answer.long
        )
        const longAnswer = answers?.find(
          (answer) => answer.columnId === questionId && answer.long
        )

        const shortDisplayText = getDisplayText(shortAnswer)
        const longDisplayText = getDisplayText(longAnswer)

        if (isShowingLongResponses) {
          cells[questionId] = longDisplayText
          cells[`${questionId}${HIDDEN_COLUMN_ID_SUFFIX}`] = shortDisplayText
        } else {
          cells[questionId] = shortDisplayText
          cells[`${questionId}${HIDDEN_COLUMN_ID_SUFFIX}`] = longDisplayText
        }
      })
      return {
        id: fileId,
        queryId: queryId,
        name: file.name,
        file: file,
        folderPath: folderPath,
        errors:
          errorSuppressed || !fileProcessed ? [] : fileIdToErrors[fileId] || [],
        sources: sources,
        ...cells,
        answers: answers,
      }
    })

    // using applyTransactionAsync instead of updateGridData because
    // applyTransactionAsync is more efficient for large data sets and high frequency data
    // https://www.ag-grid.com/react-data-grid/data-update-high-frequency/
    // when column defs are undefined or we are adding new questions, we have to redefine the columns so we will synchronously update the table
    // if the column defs are defined, then we do an update asynchronously
    if (needsToUpdateColumnDefs) {
      gridApi.updateGridOptions({
        rowData: rowData,
      })
      setDisplayedRows(getDisplayedRows(gridApi))
      setHasRowDataApplied(true)
    } else {
      const rowDataToApply = computeRowDataDiff(gridApi, rowData)
      gridApi.applyTransactionAsync(
        {
          update: rowDataToApply,
        },
        () => {
          // Refresh the header to update the multi-select checkbox
          gridApi.refreshHeader()
          gridApi.refreshCells({
            force: true,
            columns: gridApi.getColumns() || [],
            rowNodes: rowDataToApply
              .map((row) => gridApi.getRowNode(row.id))
              .filter(Boolean) as IRowNode<any>[],
          })

          const numVisibleRows = gridApi.getDisplayedRowCount()
          if (numVisibleRows === 0) {
            gridApi.showNoRowsOverlay()
          } else {
            gridApi.hideOverlay()
          }
          setDisplayedRows(getDisplayedRows(gridApi))
        }
      )
    }
  }, [
    gridApi,
    questions,
    currentProjectMetadata,
    queryFiles,
    generateNNResponse,
    queryIdToReviewState,
    queryId,
    columnIdToHeader,
    columnIdToDataType,
    isShowingLongResponses,
    folderIdToVaultFolder,
    fileIdToSources,
    fileIdToAnswers,
    suppressedFileIdsSet,
    processedFileIdsSet,
    fileIdToErrors,
    isDryRun,
    isLoading,
    setHasRowDataApplied,
    setDisplayedRows,
    setAgGridHeaderHeight,
  ])

  // we are going to use this to update the grid row data and columns defs
  // the setupGridRowColumnData function will be called when the grid api is available
  // and its dependencies update
  useEffect(() => {
    void setupGridRowColumnData()
  }, [setupGridRowColumnData])

  useUnmount(() => {
    // when the component unmounts, we want to reset the current sort column id
    // to the name column so that the table is not sorted by a column that is not in the grid
    setCurrentSortColumnId('name')
    // Reset displayed rows to empty array when unmounting
    setDisplayedRows([])
  })

  return (
    <>
      <VaultAddFilesDialog generateNNResponse={generateNNResponse} />
      <div className="flex h-full w-full flex-col">
        {isLoading && (
          <LoadingBarBelowAppHeader
            progress={Math.max(MIN_PROGRESS, progress)}
          />
        )}
        <VaultDataGridHeader
          columnIdToHeader={columnIdToHeader}
          columnIdToDataType={columnIdToDataType}
          generateNNResponse={generateNNResponse}
        />
        <VaultDataGrid queryId={queryId!} />
        <CellViewer />
      </div>
    </>
  )
}

export default VaultQueryResultTable
