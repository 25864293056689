import React, { useState } from 'react'
import { useParams } from 'react-router-dom'

import { Copy, Download, RefreshCw, Trash } from 'lucide-react'

import { MessageFeedback } from 'openapi/models/MessageFeedback'
import { UploadedFile } from 'openapi/models/UploadedFile'

import { modelMarkdownToHtmlRemark } from 'utils/markdown'
import { TaskType } from 'utils/task'
import { displayErrorMessage, displaySuccessMessage } from 'utils/toast'

import { useAssistantAnalytics } from 'components/assistant/hooks/use-assistant-analytics'
import { useIsPwcTax } from 'components/assistant/hooks/use-is-pwc-tax'
import { useAssistantStore } from 'components/assistant/stores/assistant-store'
import { AssistantMessage } from 'components/assistant/types'
import { handleExport as handleExportHelper } from 'components/assistant/utils/assistant-export'
import { CreateMessageFeedback } from 'components/assistant/utils/assistant-fetcher'
import { useAuthUser } from 'components/common/auth-context'
import { FeedbackButton } from 'components/common/feedback/feedback'
import ToolbarButton from 'components/common/feedback/toolbar-button'
import { Button } from 'components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogDescription,
  DialogTitle,
} from 'components/ui/dialog'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'components/ui/dropdown-menu'
import Icon from 'components/ui/icon/icon'
import { Tooltip, TooltipTrigger, TooltipContent } from 'components/ui/tooltip'

import { AssistantMode } from './assistant-mode-select'

interface Props {
  documents: UploadedFile[]
  message: AssistantMessage
  onRegenerateMessage?: (message: AssistantMessage) => void
  threadTitle?: string
  threadNumber: number
  messageNumber: number
}

const AssistantToolbar = ({
  documents,
  message,
  onRegenerateMessage,
  threadTitle,
  threadNumber,
  messageNumber,
}: Props) => {
  const { mode } = useParams()
  const userInfo = useAuthUser()
  const trackEvent = useAssistantAnalytics()
  const [sentiment, setSentiment] = useState<number>(
    message.feedbackSentiment || 0
  )
  const isDraft = mode === AssistantMode.DRAFT
  const eventId = useAssistantStore((s) => s.eventId)
  const currentMessageId = useAssistantStore((s) => s.currentMessageId)
  const streamingMessage = useAssistantStore((s) => s.streamingMessage)
  const deleteMessage = useAssistantStore((s) => s.deleteMessage)
  const isEventOwner = useAssistantStore((s) => s.isEventOwner)
  const hasDocuments = documents.length > 0

  const showMutateToolbar =
    mode === AssistantMode.ASSIST &&
    message.messageId === currentMessageId &&
    !streamingMessage &&
    isEventOwner

  const isPwcTax = useIsPwcTax()
  const showFeedback = !isPwcTax && isEventOwner && userInfo.IsFeedbackUser

  const showDeleteButton = !!message.prevMessageId

  const handleSubmitFeedback = async (feedback: MessageFeedback) => {
    trackEvent('Feedback Submitted', {
      sentiment: feedback.sentiment,
      comments: feedback.selectedComments,
      thread_number: threadNumber,
      message_number: messageNumber,
    })
    setSentiment(feedback.sentiment)
    try {
      await CreateMessageFeedback(eventId, message.messageId, feedback)
      return true
    } catch {
      setSentiment(0)
      return false
    }
  }

  const handleExport = async (includeAnnotation: boolean) => {
    const taskType = isDraft
      ? TaskType.ASSISTANT_DRAFT
      : TaskType.ASSISTANT_CHAT
    await handleExportHelper({
      eventId: String(eventId),
      messages: [message],
      documents,
      includeAnnotation,
      taskType,
      exportTitle: threadTitle,
    })
    trackEvent('Response Exported', {
      export_kind: 'word',
      citations_included: includeAnnotation,
      thread_number: threadNumber,
      message_number: messageNumber,
    })
  }

  const handleDelete = () => {
    deleteMessage(message)
  }

  const handleRegenerate = () => {
    if (!onRegenerateMessage) {
      displayErrorMessage('Cannot regenerate message')
      console.error('Unexpected missing regenerateMessage function')
      return
    }
    trackEvent('Regenerate Message Initiated', {
      thread_number: threadNumber,
      message_number: messageNumber,
    })
    onRegenerateMessage(message)
  }

  return (
    <div
      className="flex w-full justify-between space-x-0.5"
      id={`assistant-toolbar-${message.messageId}`}
    >
      <div className="inline-flex items-center space-x-0.5">
        {showFeedback && (
          <>
            <FeedbackButton
              hasDocuments={hasDocuments}
              onSubmit={handleSubmitFeedback}
              sentiment={sentiment}
              sentimentValue={1}
            />
            <FeedbackButton
              hasDocuments={hasDocuments}
              onSubmit={handleSubmitFeedback}
              sentiment={sentiment}
              sentimentValue={-1}
            />
          </>
        )}
        <CopyButton message={message} />
        <ExportButton message={message} onExport={handleExport} />
      </div>
      {showMutateToolbar && (
        <div className="inline-flex items-center">
          <RegenerateButton onRegenerate={handleRegenerate} />
          {showDeleteButton && (
            <DeleteButton
              onDelete={handleDelete}
              threadNumber={threadNumber}
              messageNumber={messageNumber}
            />
          )}
        </div>
      )}
    </div>
  )
}

export default AssistantToolbar

const ExportButton = ({
  message,
  onExport,
}: {
  message: AssistantMessage
  onExport: (includeAnnotation: boolean) => void
}) => {
  const hasSources = message.sources.length > 0

  if (!hasSources) {
    return (
      <Tooltip disableHoverableContent>
        <TooltipTrigger asChild>
          <ToolbarButton
            onClick={() => onExport(false)}
            data-testid="export-button"
          >
            <Icon icon={Download} />
          </ToolbarButton>
        </TooltipTrigger>
        <TooltipContent>Export</TooltipContent>
      </Tooltip>
    )
  }

  return (
    <DropdownMenu>
      <Tooltip disableHoverableContent>
        <TooltipTrigger asChild>
          <DropdownMenuTrigger asChild>
            <ToolbarButton data-testid="export-button-toolbar">
              <Icon icon={Download} />
            </ToolbarButton>
          </DropdownMenuTrigger>
        </TooltipTrigger>
        <TooltipContent>View export options</TooltipContent>
      </Tooltip>
      <DropdownMenuContent align="start">
        <DropdownMenuItem onClick={() => onExport(true)}>
          Export with citations
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => onExport(false)}>
          Export answer only
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

const CopyButton = ({ message }: { message: AssistantMessage }) => {
  const handleCopy = () => {
    const removeHrvySpans = (markdown: string) => {
      return markdown.replace(/ <span data-hrvy-id="[^"]+">\d+<\/span>/g, '')
    }
    const markdown = removeHrvySpans(message.response)
    const asHtml = modelMarkdownToHtmlRemark(markdown)

    const item = new ClipboardItem({
      'text/html': new Blob([asHtml], { type: 'text/html' }),
      'text/plain': new Blob([markdown], { type: 'text/plain' }),
    })

    navigator.clipboard
      .write([item])
      .then(() => displaySuccessMessage('Copied response to clipboard'))
      .catch((err) =>
        console.error('Failed to copy response to clipboard: ', err)
      )
  }

  return (
    <Tooltip disableHoverableContent>
      <TooltipTrigger asChild>
        <ToolbarButton onClick={handleCopy} data-testid="copy-button">
          <Icon icon={Copy} />
        </ToolbarButton>
      </TooltipTrigger>
      <TooltipContent>Copy response</TooltipContent>
    </Tooltip>
  )
}

const DeleteButton = ({
  onDelete,
  threadNumber,
  messageNumber,
}: {
  onDelete: () => void
  threadNumber: number
  messageNumber: number
}) => {
  const trackEvent = useAssistantAnalytics()
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const trackDeleteEvent = (eventName: string) => {
    trackEvent(eventName, {
      thread_number: threadNumber,
      message_number: messageNumber,
    })
  }

  const handleDelete = () => {
    trackDeleteEvent('Delete Message Initiated')
    setIsDialogOpen(true) // Open the confirmation dialog
  }

  const confirmDelete = () => {
    trackDeleteEvent('Delete Message Confirmed')
    onDelete()
    setIsDialogOpen(false) // Close the dialog after confirmation
  }

  const cancelDelete = () => {
    trackDeleteEvent('Delete Message Cancelled')
    setIsDialogOpen(false) // Close the dialog without deleting
  }

  return (
    <>
      <Tooltip disableHoverableContent>
        <TooltipTrigger asChild>
          <ToolbarButton onClick={handleDelete} data-testid="delete-button">
            <Icon icon={Trash} />
          </ToolbarButton>
        </TooltipTrigger>
        <TooltipContent>Delete response</TooltipContent>
      </Tooltip>

      {/* Confirmation Dialog */}
      <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
        <DialogContent showCloseIcon={false}>
          <DialogHeader>
            <DialogTitle>Delete this response?</DialogTitle>
            <DialogDescription>
              The query including the response will be permanently deleted from
              this thread.
            </DialogDescription>
          </DialogHeader>
          <div className="mt-6 flex justify-end space-x-2">
            <Button variant="ghost" onClick={cancelDelete}>
              Cancel
            </Button>
            <Button onClick={confirmDelete}>Delete</Button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  )
}

const RegenerateButton = ({ onRegenerate }: { onRegenerate: () => void }) => {
  return (
    <Tooltip disableHoverableContent>
      <TooltipTrigger asChild>
        <ToolbarButton onClick={onRegenerate} data-testid="regenerate-button">
          <Icon icon={RefreshCw} />
        </ToolbarButton>
      </TooltipTrigger>
      <TooltipContent>Generate new response</TooltipContent>
    </Tooltip>
  )
}
