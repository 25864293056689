import React from 'react'

import pluralize from 'pluralize'

import Services from 'services'

import { displaySuccessMessage } from 'utils/toast'

import { useAnalytics } from 'components/common/analytics/analytics-context'
import Banner from 'components/ui/banner'
import { Button } from 'components/ui/button'
import { Dialog, DialogContent } from 'components/ui/dialog'
import { Spinner } from 'components/ui/spinner'

import { useHistoryMetadataStore } from './history-metadata-store'
import { useHistoryStore } from './history-store'

interface HistoryBulkDeleteDialogProps {
  modalOpen: boolean
  setModalOpen: (open: boolean) => void
}

const HistoryBulkDeleteDialog: React.FC<HistoryBulkDeleteDialogProps> = ({
  modalOpen,
  setModalOpen,
}) => {
  const { trackEvent } = useAnalytics()
  const { selectedEventIds, setSelectedEventIds } = useHistoryStore((s) => ({
    selectedEventIds: s.selectedEventIds,
    setSelectedEventIds: s.setSelectedEventIds,
  }))

  const [isDeleting, setIsDeleting] = React.useState(false)
  const bulkDeleteEvents = useHistoryMetadataStore((s) => s.bulkDeleteEvents)

  const onDeleteSubmit = React.useCallback(async () => {
    if (selectedEventIds.length === 0) {
      return
    }

    setIsDeleting(true)
    Services.HoneyComb.Record({
      metric: 'ui.history_bulk_delete_events_dialog_submitted',
      total_events: selectedEventIds.length,
    })
    trackEvent('History Bulk Delete Events Dialog Submitted', {
      total_events: selectedEventIds.length,
    })

    await bulkDeleteEvents(selectedEventIds)
    setSelectedEventIds([])

    setModalOpen(false)
    setIsDeleting(false)

    displaySuccessMessage('Selected history events have been deleted')
  }, [
    selectedEventIds,
    trackEvent,
    bulkDeleteEvents,
    setSelectedEventIds,
    setModalOpen,
  ])

  return (
    <Dialog
      open={modalOpen}
      onOpenChange={(open) => {
        setModalOpen(open)
        Services.HoneyComb.Record({
          metric: `ui.history_bulk_delete_events_dialog_${
            open ? 'opened' : 'dismissed'
          }`,
        })
        trackEvent(
          `History Bulk Delete Events Dialog ${open ? 'Opened' : 'Dismissed'}`
        )
      }}
    >
      <DialogContent showCloseIcon={false}>
        <div>
          <p className="text-xl">Delete history</p>
          <Banner
            title="Are you sure?"
            variant="destructive"
            description={
              <p>
                This permanently deletes the history and any associated
                documents. Once deleted, they cannot be recovered.
              </p>
            }
            className="mt-4"
          />
          <div className="mt-6 space-y-4">
            <div className="space-y-2">
              <p className="font-semibold">
                You about to permanently delete total{' '}
                <b>{selectedEventIds.length}</b> history{' '}
                {pluralize('event', selectedEventIds.length)}.
              </p>
            </div>
          </div>
          <div className="mt-6 flex flex-row justify-end space-x-2">
            <Button
              variant="ghost"
              onClick={() => {
                setModalOpen(false)
              }}
            >
              Cancel
            </Button>
            <Button
              variant="destructive"
              disabled={isDeleting}
              onClick={onDeleteSubmit}
              className="*:text-sm"
            >
              {isDeleting ? (
                <div className="flex items-center">
                  <Spinner className="top-3 mr-2 h-3 w-3" />
                  Deleting…
                </div>
              ) : (
                <span>Permanently delete</span>
              )}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default HistoryBulkDeleteDialog
