import { Permission, UserInfo } from 'models/user-info'
import { IntegrationType } from 'openapi/models/IntegrationType'

import SharePointIcon from 'components/ui/icons/sharepoint-icon'

export interface ResourceUrlProps {
  required: boolean
  tooltip: string
  inputPlaceholder?: string
}

export interface IntegrationDefinition {
  permission: Permission
  Logo: React.ComponentType<{ className?: string }>
  title: string
  description: string
  available: (userInfo: UserInfo) => boolean
  enablementContent: string[]
  resourceUrlProps?: ResourceUrlProps
}

export const IntegrationDefinitions: Record<
  IntegrationType,
  IntegrationDefinition
> = {
  [IntegrationType.SHAREPOINT]: {
    permission: Permission.SHAREPOINT_INTEGRATION,
    Logo: SharePointIcon,
    title: 'Sharepoint / OneDrive',
    description: 'Users can upload files directly from SharePoint or OneDrive.',
    available: (userInfo: UserInfo) => {
      return (
        userInfo.workspace.integrations.includes(IntegrationType.SHAREPOINT) &&
        userInfo.permissions.includes(Permission.SHAREPOINT_INTEGRATION)
      )
    },
    enablementContent: [
      'Authenticate into their SharePoint and OneDrive accounts from Harvey.',
      'Upload files from SharePoint and OneDrive directly into Harvey.',
    ],
    resourceUrlProps: {
      required: true,
      tooltip:
        'The resource URL is the URL of SharePoint or OneDrive site you want to connect to. It should start with https://',
      inputPlaceholder: 'https://acme.sharepoint.com/',
    },
  },
}
