import React, { useState } from 'react'

import { CancelWorkspaceOffboard } from 'models/workspace'

import { displaySuccessMessage } from 'utils/toast'

import { Alert, AlertTitle } from 'components/ui/alert'
import { Button } from 'components/ui/button'
import { Checkbox } from 'components/ui/checkbox'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogTitle,
} from 'components/ui/dialog'
import { Spinner } from 'components/ui/spinner'

interface WorkspaceOffboardCancelModalProps {
  open: boolean
  workspaceId: number
  onOpenChange: (open: boolean) => void
  onCancelSuccess: () => void
}

const WorkspaceOffboardCancelModal = ({
  open,
  workspaceId,
  onOpenChange,
  onCancelSuccess,
}: WorkspaceOffboardCancelModalProps) => {
  const [cancelOffboardAcknowledge, setCancelOffboardAcknowledge] =
    useState(false)
  const [isCanceling, setIsCanceling] = useState(false)

  const handleOffboardCancelSubmit = async () => {
    setIsCanceling(true)
    await CancelWorkspaceOffboard(workspaceId)
    onCancelSuccess()
    displaySuccessMessage('Workspace offboard canceled successfully.')
    setCancelOffboardAcknowledge(false)
    setIsCanceling(false)
    onOpenChange(false)
  }

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="max-w-[600px]">
        <DialogTitle>
          <div className="text-destructive">Cancel Workspace Offboard</div>
        </DialogTitle>
        <DialogDescription>
          <p>
            This will re-allow this workspace users and APIs to access the
            platform IMMEDIATELY. Scheduled data clean job will be canceled.
          </p>
          {!isCanceling && (
            <Alert className="h-17 mt-4" variant="destructive">
              <AlertTitle className="-mt-2 flex justify-between">
                Please acknowledge you want to cancel this workspace offboard
                process.
                <Checkbox
                  checked={cancelOffboardAcknowledge}
                  onCheckedChange={() =>
                    setCancelOffboardAcknowledge(!cancelOffboardAcknowledge)
                  }
                  checkboxClassName="border border-destructive"
                />
              </AlertTitle>
            </Alert>
          )}
          {isCanceling && (
            <div className="mt-4 flex items-center">
              <Spinner className="mr-2" />
              <span>This operation could take up to a minute...</span>
            </div>
          )}
        </DialogDescription>
        <DialogFooter>
          <Button
            className="btn"
            variant="secondary"
            onClick={() => {
              setCancelOffboardAcknowledge(false)
              onOpenChange(false)
            }}
            disabled={isCanceling}
          >
            Close
          </Button>
          <Button
            className="btn btn-primary"
            onClick={handleOffboardCancelSubmit}
            disabled={!cancelOffboardAcknowledge || isCanceling}
            variant="destructive"
          >
            Cancel
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default WorkspaceOffboardCancelModal
