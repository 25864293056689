import { MarkdownDiff } from 'utils/markdown-diff'

import { AssistantMessage } from 'components/assistant/types'

export const getMessageDiff = (
  messages: AssistantMessage[],
  selectedMessage: AssistantMessage | undefined,
  useDiffView: boolean
) => {
  const prevMessage = messages.find(
    (m) => m.messageId && m.messageId === selectedMessage?.prevMessageId
  )
  if (useDiffView && prevMessage && selectedMessage) {
    const prevResponse = prevMessage.response
    const selectedResponse = selectedMessage.response
    let response = selectedResponse
    try {
      response = new MarkdownDiff().run(prevResponse, selectedResponse)
    } catch (e) {
      // TODO: We should tell the user we couldn't generate the diff
      console.error('Error generating markdown diff, skipping', e)
    }
    return response
  }
  return selectedMessage?.response ?? ''
}
