import { parseIsoString } from 'utils/utils'

import { CellComparator, CompareConfig } from './cell-comparator-types'

export class DateCellComparator extends CellComparator {
  compareCells({ answerA, answerB }: CompareConfig) {
    return compareDates(answerA.text, answerB.text)
  }
}

export const compareDates = (dateAString: string, dateBString: string) => {
  const dateA = parseIsoString(dateAString)
  const dateB = parseIsoString(dateBString)
  return dateA > dateB ? 1 : -1
}
