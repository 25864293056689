import React from 'react'

import { ArrowRight } from 'lucide-react'

import { useLibraryMetadataStore } from 'components/library/library-metadata-store'
import LibraryPreview from 'components/library/library-preview'
import { LibraryItemKind } from 'components/library/library-types'
import { sortByStarred } from 'components/library/library.helpers'
import { useLibraryData } from 'components/library/use-library-data'
import Skeleton from 'components/ui/skeleton'
import { TextLink } from 'components/ui/text-link'

interface Props {
  isLoading: boolean
}

const MAX_EXAMPLES = 10

const AssistantExamples = ({ isLoading }: Props) => {
  const { items } = useLibraryData(LibraryItemKind.EXAMPLE)

  const getFavoriteStatus = useLibraryMetadataStore((s) => s.getFavoriteStatus)

  const sortedItems = Object.values(items).sort((a, b) => {
    return sortByStarred(a, b, getFavoriteStatus)
  })

  if (isLoading) {
    return <Skeleton className="py-4" rows={3} rowHeight="h-4" />
  }

  if (!sortedItems.length) {
    return (
      <p className="pt-6 text-center text-muted">
        Example queries will appear here
      </p>
    )
  }

  return (
    <>
      <LibraryPreview
        items={sortedItems.slice(0, MAX_EXAMPLES)}
        isLoading={isLoading}
        showHeaders
      />
      <TextLink
        className="mt-2 inline-block font-semibold"
        label="View all examples"
        href="/library/examples"
        trailingIcon={<ArrowRight />}
      />
    </>
  )
}

export default AssistantExamples
