import pluralize from 'pluralize'

import { uploadFile } from 'api'
import { TranscriptsAnalyzeRequestToJSON } from 'openapi/models/TranscriptsAnalyzeRequest'
import { TranscriptsDocument } from 'openapi/models/TranscriptsDocument'
import { TranscriptsTask } from 'openapi/models/TranscriptsTask'
import { UploadedFile } from 'openapi/models/UploadedFile'

import { displayFileUploadError } from 'utils/dropzone'
import { createFileName } from 'utils/file-utils'
import {
  InitSocketAndSendQuery,
  InitSocketAndSendQueryParams,
} from 'utils/use-harvey-socket'

import {
  TranscriptsActions,
  TranscriptsState,
} from 'components/workflows/workflow/transcripts/transcripts-store'

export const newFileHandler = async (params: {
  files: File[]
  initSocketAndSendQuery: InitSocketAndSendQuery
  get: () => TranscriptsState & TranscriptsActions
}) => {
  const { files, initSocketAndSendQuery, get } = params

  const newFiles = prepareFiles(files, get)
  eagerlyAddDocuments(newFiles, get)

  const uploadedFiles = await uploadFiles(newFiles)
  handleUploadResults(uploadedFiles, get)

  const validDocs = extractValidDocuments(uploadedFiles)
  if (validDocs.length > 0) {
    initSocketAndSendQuery(createSocketQueryParams(validDocs))
  }
}

function prepareFiles(
  files: File[],
  get: () => TranscriptsState & TranscriptsActions
) {
  const existingNamesSet = new Set(
    // eslint-disable-next-line
    get().documents?.map((doc) => doc.file.name) ?? []
  )
  return files.map((file) => ({
    name: createFileName(file.name, existingNamesSet),
    file,
  }))
}

function eagerlyAddDocuments(
  newFiles: { name: string; file: File }[],
  get: () => TranscriptsState & TranscriptsActions
) {
  const documentsToAdd = newFiles.map(({ name, file }) =>
    getEagerTranscriptsDocument(name, file.size)
  )
  get().setDocuments([...get().documents, ...documentsToAdd])
}

async function uploadFiles(newFiles: { name: string; file: File }[]) {
  return Promise.all(
    newFiles.map(async ({ name, file }) => ({
      name,
      file,
      uploadedFile: await uploadFile(file, true).catch(() => null),
    }))
  )
}

function handleUploadResults(
  uploadedFiles: { name: string; file: File; uploadedFile: any | null }[],
  get: () => TranscriptsState & TranscriptsActions
) {
  const rejectedFiles = uploadedFiles.filter(
    ({ uploadedFile }) => uploadedFile === null
  )

  if (rejectedFiles.length > 0) {
    displayFileUploadError(
      rejectedFiles.map(({ file }) => file.name),
      formatUploadErrorMessage(rejectedFiles.length),
      uploadedFiles.length > 0
    )
    rejectedFiles.forEach(({ name }) => get().removeDocument(name))
  }
}

function extractValidDocuments(
  uploadedFiles: { name: string; file: File; uploadedFile: any | null }[]
): UploadedFile[] {
  return uploadedFiles
    .filter(({ uploadedFile }) => uploadedFile !== null)
    .map(({ uploadedFile }) => ({
      ...uploadedFile,
    }))
}

function createSocketQueryParams(
  docs: UploadedFile[]
): InitSocketAndSendQueryParams {
  return {
    query: TranscriptsTask.ANALYZE,
    additionalAuthParams: {},
    additionalRequestParams: TranscriptsAnalyzeRequestToJSON({
      documents: docs,
    }),
  }
}

function formatUploadErrorMessage(rejectedFilesCount: number): string {
  return `${rejectedFilesCount} ${pluralize(
    'file',
    rejectedFilesCount
  )} failed to upload: `
}

function getEagerTranscriptsDocument(
  fileName: string,
  fileSize: number
): TranscriptsDocument {
  return {
    isLoading: true,
    file: {
      id: '',
      name: fileName,
      path: '',
      url: '',
      size: fileSize,
      pdfkitId: '',
    },
    qaFile: { signedUrl: '', path: '' },
    topics: [],
  }
}
