import * as Sentry from '@sentry/browser'
import { isNil } from 'lodash'
import PSPDFKit from 'pspdfkit'

import { UploadedFile } from 'openapi/models/UploadedFile'
import Services from 'services'
import { Theme } from 'types/ui-constants'

import { cdnUrl, pspdfkitStandaloneLicenseKey } from 'utils/server-data'

const devAppName =
  process.env.REACT_APP_BRANCH_NAME?.toUpperCase().replaceAll('-', '_') ?? ''

export const licenseKey =
  pspdfkitStandaloneLicenseKey ??
  process.env[`REACT_APP_PSPDFKIT_STANDALONE_LICENSE_KEY_${devAppName}`]!

export const getPdfKitConfig = (page?: number, theme?: Theme) => {
  return {
    document: '',
    container: '#pspdfkit-container',
    licenseKey,
    instant: true,
    overwriteExisting: false,
    autoSaveMode: PSPDFKit.AutoSaveMode.DISABLED,
    styleSheets: [`${window.location.origin}/static/pdfkit.css`],
    initialViewState: new PSPDFKit.ViewState({
      zoom: 'AUTO',
      enableAnnotationToolbar: false,
      showToolbar: false,
      currentPageIndex: page,
      allowPrinting: false,
      readOnly: true,
    }),
    baseUrl: `${cdnUrl}/pspdfkit-lib/2024.1.2/`,
    theme: theme === Theme.DARK ? PSPDFKit.Theme.DARK : PSPDFKit.Theme.LIGHT,
  }
}

const getConfig = async (
  document: File | UploadedFile,
  container: HTMLDivElement | null,
  theme: Theme
) => {
  const baseConfig = getPdfKitConfig(undefined, theme)
  const config: any = {
    ...baseConfig,
    container,
    document:
      document instanceof File ? await document.arrayBuffer() : document.url,
  }
  if (document instanceof File || !document.docAsPdfUrl) {
    return config
  }

  if (document.docAsPdfUrl.startsWith('https://')) {
    return {
      ...config,
      document: document.docAsPdfUrl,
    }
  }

  // base64 deserialization is only used for old docx files
  const byteCharacters = atob(document.docAsPdfUrl)
  const buffer = Uint8Array.from(byteCharacters, (c) => c.charCodeAt(0)).buffer

  return {
    ...config,
    document: buffer,
  }
}

export const loadPDF = async ({
  document,
  containerRef,
  theme,
}: {
  document: File | UploadedFile | null
  containerRef: React.RefObject<HTMLDivElement>
  theme: Theme
}) => {
  if (isNil(document)) return null
  const container = containerRef.current
  if (isNil(container)) return null

  const config = await getConfig(document, container, theme)

  const instance = await PSPDFKit.load(config).catch((error) => {
    console.error(error)
    Sentry.captureException(error)
    Services.HoneyComb.RecordError(error)
  })
  if (!instance) return null
  return instance
}

export const unloadPDF = (containerRef: React.RefObject<HTMLDivElement>) => {
  if (!containerRef.current) return
  PSPDFKit.unload(containerRef.current)
}

export const unloadPDFDiv = () => {
  const container = document.getElementById('pspdfkit-container')
  if (!container) return
  PSPDFKit.unload(container)
}
