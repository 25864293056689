import Services from 'services'

// Define the request data interface
export interface CompetitorsAnalysisRequest {
  companyName: string
  companyDescription: string
}

// Define the response data interface
export interface CreateCompetitorsExcelResponse {
  excelUrl: string
  // eslint-disable-next-line
  raw: Record<string, any>
}

export const fetchCompetitorExcel = async (
  data: CompetitorsAnalysisRequest,
  abortController: AbortController
): Promise<CreateCompetitorsExcelResponse | null> => {
  const res = await Services.Backend.Post<CreateCompetitorsExcelResponse>(
    'diligence/competitors/create_excel',
    data,
    {
      throwOnError: true,
      signal: abortController.signal,
    }
  )
  return res
}
