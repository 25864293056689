import React, { useCallback } from 'react'
import { FileRejection, useDropzone } from 'react-dropzone'

import { useAuth0 } from '@auth0/auth0-react'
import { formatDistanceToNow, addSeconds } from 'date-fns'
import _ from 'lodash'
import { UploadIcon } from 'lucide-react'

import { HarvQueryKeyPrefix } from 'models/queries/all-query-keys'
import { useWrappedQuery } from 'models/queries/lib/use-wrapped-query'
import { VaultFile } from 'openapi/models/VaultFile'
import { useGeneralStore } from 'stores/general-store'
import { FileType } from 'types/file'

import { onDrop } from 'utils/dropzone'
import { bytesToReadable, mbToBytes, mbToReadable } from 'utils/file-utils'
import { displayErrorMessage } from 'utils/toast'
import { useDropzoneTrack } from 'utils/use-dropzone-track'
import { cn } from 'utils/utils'

import { useAnalytics } from 'components/common/analytics/analytics-context'
import { useAuthUser } from 'components/common/auth-context'
import { Dropzone } from 'components/common/dropzone/dropzone'
import DropzoneDescription from 'components/common/dropzone/dropzone-description'
import { Button } from 'components/ui/button'
import { Dialog, DialogContent } from 'components/ui/dialog'
import { Spinner } from 'components/ui/spinner'
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/ui/tooltip'
import VaultUploadedFileList from 'components/vault/components/new-project/vault-uploaded-file-list'
import VaultCreateDisclaimer from 'components/vault/components/vault-create-disclaimer'
import { VaultDialogHeader } from 'components/vault/dialogs/vault-dialog-header'
import DuplicateFilesModal from 'components/vault/dialogs/vault-duplicate-files-modal'
import {
  ACCEPTED_FILE_TYPES,
  ETA_TOOLTIP_TEXT,
  FileToUpload,
  MAX_EXCEL_FILE_SIZE_IN_MB,
  maxFileSizeInMb,
  maxTotalFileSizeInMb,
} from 'components/vault/utils/vault'
import { useVaultCreateProjectStore } from 'components/vault/utils/vault-create-project-store'
import { FetchJobQueueEta } from 'components/vault/utils/vault-fetcher'
import {
  createFoldersAndFiles,
  sumFileSizesInBytes,
  handleDroppedFilesOrDeletedFiles,
} from 'components/vault/utils/vault-helpers'
import { useVaultStore } from 'components/vault/utils/vault-store'
import { pluralizeFiles } from 'components/vault/utils/vault-text-utils'

const VaultUploadFilesDialog: React.FC = () => {
  const userInfo = useAuthUser()
  const { getAccessTokenSilently } = useAuth0()
  const { trackEvent } = useAnalytics()

  const addFolderIdToFilesUploading = useGeneralStore(
    (s) => s.addFolderIdToFilesUploading
  )
  const removeFolderIdFromFilesUploading = useGeneralStore(
    (s) => s.removeFolderIdFromFilesUploading
  )

  const recordFileDrop = useDropzoneTrack('VAULT_FOLDER')

  const fileIdToVaultFile = useVaultStore((s) => s.fileIdToVaultFile)
  const folderIdToVaultFileIds = useVaultStore((s) => s.folderIdToVaultFileIds)
  const folderIdToVaultFolder = useVaultStore((s) => s.folderIdToVaultFolder)
  const isUploadFilesDialogOpen = useVaultStore(
    (s) => s.isUploadFilesDialogOpen
  )
  const currentUploadFilesFolderId = useVaultStore(
    (s) => s.currentUploadFilesFolderId
  )
  const currentProjectMetadata = useVaultStore((s) => s.currentProjectMetadata)
  const setIsUploadFilesDialogOpen = useVaultStore(
    (s) => s.setIsUploadFilesDialogOpen
  )
  const upsertVaultFolders = useVaultStore((s) => s.upsertVaultFolders)
  const upsertVaultFiles = useVaultStore((s) => s.upsertVaultFiles)
  const updateProjectMetadata = useVaultStore((s) => s.updateProjectMetadata)
  const updateProjectMetadataLastFileUploadedAt = useVaultStore(
    (s) => s.updateProjectMetadataLastFileUploadedAt
  )
  const setIsDuplicateModalOpen = useVaultStore(
    (s) => s.setIsDuplicateModalOpen
  )

  const filesToUpload = useVaultCreateProjectStore((s) => s.filesToUpload)
  const isSubmitting = useVaultCreateProjectStore((s) => s.isSubmitting)
  const isDropzoneLoading = useVaultCreateProjectStore(
    (s) => s.isDropzoneLoading
  )
  const totalFileSizeInBytes = useVaultCreateProjectStore(
    (s) => s.totalFileSizeInBytes
  )
  const setDroppedFiles = useVaultCreateProjectStore((s) => s.setDroppedFiles)
  const setFilesToUpload = useVaultCreateProjectStore((s) => s.setFilesToUpload)
  const setIsSubmitting = useVaultCreateProjectStore((s) => s.setIsSubmitting)
  const setIsDropzoneLoading = useVaultCreateProjectStore(
    (s) => s.setIsDropzoneLoading
  )
  const setTotalFileSizeInBytes = useVaultCreateProjectStore(
    (s) => s.setTotalFileSizeInBytes
  )
  const setDuplicateFiles = useVaultCreateProjectStore(
    (s) => s.setDuplicateFiles
  )

  const currentFolder = currentUploadFilesFolderId
    ? folderIdToVaultFolder[currentUploadFilesFolderId]
    : undefined
  const existingVaultFileNames = React.useMemo(
    () =>
      (
        (currentFolder?.id
          ? folderIdToVaultFileIds[currentFolder.id] ?? []
          : []
        )
          .map((fileId) => fileIdToVaultFile[fileId])
          .filter(Boolean) as VaultFile[]
      ).map((file) => file.name),
    [currentFolder, fileIdToVaultFile, folderIdToVaultFileIds]
  )
  const namesForExistingVaultFilesAndFilesToUpload = React.useMemo(
    () => [
      ...existingVaultFileNames,
      ...filesToUpload.map((file) => file.name),
    ],
    [existingVaultFileNames, filesToUpload]
  )
  const vaultFilesCountLimit = userInfo.workspace.getVaultFilesCountLimit(
    userInfo.vaultFeature
  )

  const onFileDrop = async (acceptedFiles: File[]) => {
    setIsDropzoneLoading(false)
    const { duplicates } = await handleDroppedFilesOrDeletedFiles({
      latestDroppedFiles: acceptedFiles,
      hasFolderName: false,
      currentTotalSizeInBytes: totalFileSizeInBytes,
      setTotalFileSizeInBytes: setTotalFileSizeInBytes,
      setFilesToUpload: setFilesToUpload,
      namesForExistingVaultFilesAndFilesToUpload:
        namesForExistingVaultFilesAndFilesToUpload,
      existingFilesToUpload: filesToUpload,
    })
    recordFileDrop(acceptedFiles)

    if (duplicates.length > 0) {
      setDuplicateFiles(duplicates)
      setIsDuplicateModalOpen(true)
    }
  }

  const onFileDelete = useCallback(
    async (file: FileToUpload) => {
      const filesToUploadAfterDeletion = filesToUpload.filter(
        (f) => f.name !== file.name
      )
      const currentTotalSizeInBytes = await sumFileSizesInBytes(
        filesToUploadAfterDeletion.map((file) => file.file)
      )
      await handleDroppedFilesOrDeletedFiles({
        latestDroppedFiles: [],
        hasFolderName: false,
        currentTotalSizeInBytes: currentTotalSizeInBytes,
        setTotalFileSizeInBytes: setTotalFileSizeInBytes,
        setFilesToUpload: setFilesToUpload,
        checkForDuplicates: false,
        namesForExistingVaultFilesAndFilesToUpload:
          namesForExistingVaultFilesAndFilesToUpload,
        existingFilesToUpload: filesToUpload.filter(
          (f) => f.name !== file.name
        ),
      })
    },
    [
      filesToUpload,
      setFilesToUpload,
      setTotalFileSizeInBytes,
      namesForExistingVaultFilesAndFilesToUpload,
    ]
  )

  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    onDrop: async (
      acceptedFiles: File[],
      fileRejections: FileRejection[],
      event
    ) => {
      event?.stopPropagation()
      setIsDropzoneLoading(true)
      return onDrop({
        acceptedFiles,
        fileRejections,
        currentFileCount: filesToUpload.length,
        maxFiles: vaultFilesCountLimit,
        acceptedFileTypes: ACCEPTED_FILE_TYPES,
        maxFileSize: mbToBytes(maxFileSizeInMb(userInfo)),
        maxExcelFileSize: mbToBytes(MAX_EXCEL_FILE_SIZE_IN_MB),
        maxZipFileSize: mbToBytes(maxTotalFileSizeInMb(userInfo)),
        maxTotalFileSizeProps: {
          maxTotalFileSize: mbToBytes(maxTotalFileSizeInMb(userInfo)),
          currentTotalFileSize: await sumFileSizesInBytes(
            filesToUpload.map((file) => file.file)
          ),
        },
        shouldSkipPasswordProtectionCheck: true,
        handleAcceptedFiles: onFileDrop,
        handleRejectedFiles: () => {
          setIsDropzoneLoading(false)
        },
      })
    },
    maxFiles: vaultFilesCountLimit,
  })

  const dismissDialog = useCallback(() => {
    setIsSubmitting(false)
    setIsUploadFilesDialogOpen(false)
    setDroppedFiles([])
    setTotalFileSizeInBytes(0)
    setFilesToUpload([])
  }, [
    setIsSubmitting,
    setIsUploadFilesDialogOpen,
    setDroppedFiles,
    setTotalFileSizeInBytes,
    setFilesToUpload,
  ])

  const handleUploadFiles = async () => {
    if (!currentUploadFilesFolderId || filesToUpload.length === 0) {
      displayErrorMessage('Something went wrong. Could not upload files.')
      return
    }
    trackEvent('Vault Files Uploaded', {
      num_files: filesToUpload.length,
      total_size: totalFileSizeInBytes,
    })
    setIsSubmitting(true)
    try {
      const areAllFilesProcessed =
        currentProjectMetadata.completedFiles ===
        currentProjectMetadata.totalFiles
      if (areAllFilesProcessed) {
        const uploadTimestamp = new Date().toISOString()
        updateProjectMetadataLastFileUploadedAt(uploadTimestamp)
      }
      const accessToken = await getAccessTokenSilently()
      await createFoldersAndFiles({
        accessToken,
        filesToUpload,
        rootFolderId: currentUploadFilesFolderId,
        prefix: '',
        areAllFilesProcessed,
        upsertVaultFolders,
        upsertVaultFiles,
        updateProjectMetadata,
        addFolderIdToFilesUploading,
        removeFolderIdFromFilesUploading,
        navigateHandler: dismissDialog,
        currentUserId: userInfo.id,
      })
    } catch (e) {
      console.error(e)
      displayErrorMessage('Something went wrong. Could not upload files.')
    } finally {
      setIsSubmitting(false)
    }
  }

  const { data: jobQueueEtaInSeconds } = useWrappedQuery({
    queryKey: [HarvQueryKeyPrefix.JobQueueEtaQuery, 'FILE_PROCESSING'],
    queryFn: () => FetchJobQueueEta('FILE_PROCESSING'),
    enabled: isUploadFilesDialogOpen,
    // Fetch job queue ETA and update ETA on the UI every minute
    refetchInterval: 60_000,
    select: (data) =>
      _.isNumber(data.etaInSeconds) ? Math.round(data.etaInSeconds) : undefined,
  })

  // Project size (total size of files in the project + total size of files being uploaded)
  const projectTotalSizeInBytes = currentProjectMetadata.folderSize
  const currentProjectTotalSizeInBytes =
    projectTotalSizeInBytes + totalFileSizeInBytes
  const isProjectSizeExceeded =
    currentProjectTotalSizeInBytes > mbToBytes(maxTotalFileSizeInMb(userInfo))

  // Project file count (total number of files in the project + number of files being uploaded)
  const projectTotalFiles = currentProjectMetadata.totalFiles
  const currentProjectTotalFiles = projectTotalFiles + filesToUpload.length
  const isProjectFileCountExceeded =
    currentProjectTotalFiles > vaultFilesCountLimit

  return (
    <Dialog open={isUploadFilesDialogOpen}>
      <DialogContent
        showCloseIcon={false}
        {...getRootProps({
          onClick: (event) => {
            event.stopPropagation()
            event.preventDefault()
          },
        })}
      >
        <VaultDialogHeader
          dialogTitle="Upload Files"
          dialogDescription="Add additional files to"
          currentFolder={currentFolder || null}
        />
        {filesToUpload.length === 0 || isDragActive ? (
          <div className="h-48">
            <Dropzone
              isLoading={isDropzoneLoading}
              dropzone={{ getRootProps, getInputProps }}
              description={
                <DropzoneDescription
                  customFileTypes={
                    mbToReadable(maxFileSizeInMb(userInfo)) !==
                    mbToReadable(MAX_EXCEL_FILE_SIZE_IN_MB)
                      ? [
                          {
                            fileType: FileType.EXCEL,
                            maxSize: mbToReadable(MAX_EXCEL_FILE_SIZE_IN_MB),
                          },
                        ]
                      : undefined
                  }
                  fileTypes={ACCEPTED_FILE_TYPES}
                  maxSize={mbToReadable(maxFileSizeInMb(userInfo))}
                  totalSize={mbToReadable(maxTotalFileSizeInMb(userInfo))}
                />
              }
            />
          </div>
        ) : (
          <div className="flex flex-col space-y-2">
            <VaultUploadedFileList
              height={192}
              filesToUpload={filesToUpload}
              isSubmitting={isSubmitting}
              onFileDelete={onFileDelete}
            />
            <div className="mt-1 flex justify-center">
              {isDropzoneLoading ? (
                <div className="flex h-6 items-center">
                  <Spinner className="h3 w-3" />
                  <p className="text-xs">Adding...</p>
                </div>
              ) : (
                <Button
                  variant="link"
                  onClick={open}
                  disabled={filesToUpload.length >= vaultFilesCountLimit}
                  size="sm"
                >
                  <UploadIcon size={12} className="mr-1" />
                  Add more
                </Button>
              )}
            </div>
          </div>
        )}
        <VaultCreateDisclaimer />
        {totalFileSizeInBytes > 0 && (
          <div className="space-y-0.5">
            {jobQueueEtaInSeconds && (
              <Metadata
                label="AI processing time"
                value={formatDistanceToNow(
                  addSeconds(new Date(), jobQueueEtaInSeconds)
                )}
                tooltip={ETA_TOOLTIP_TEXT}
              />
            )}
            <Metadata
              label="Total size"
              value={bytesToReadable(totalFileSizeInBytes)}
              tooltip="The total size of the files you’re uploading."
            />
            <Metadata
              label="Project usage"
              value={
                bytesToReadable(currentProjectTotalSizeInBytes) +
                ' / ' +
                mbToReadable(maxTotalFileSizeInMb(userInfo))
              }
              tooltip={`The total size of the files in this project and the files you're uploading. Max ${mbToReadable(
                maxTotalFileSizeInMb(userInfo)
              )} total for the whole project.`}
              className={isProjectSizeExceeded ? 'text-destructive' : ''}
            />
            <Metadata
              label="Total files"
              value={`${currentProjectTotalFiles.toLocaleString()} / ${vaultFilesCountLimit.toLocaleString()}`}
              tooltip={`The total number of files in this project and the number of files you're uploading. Max ${pluralizeFiles(
                vaultFilesCountLimit
              )} for the whole project.`}
              className={isProjectFileCountExceeded ? 'text-destructive' : ''}
            />
          </div>
        )}
        <div className="mt-6 space-y-2 self-end">
          <div className="flex justify-end space-x-2">
            <Button
              variant="ghost"
              disabled={isSubmitting}
              onClick={dismissDialog}
            >
              Cancel
            </Button>
            <Button
              disabled={
                isDropzoneLoading ||
                isSubmitting ||
                isProjectSizeExceeded ||
                isProjectFileCountExceeded ||
                filesToUpload.length === 0
              }
              onClick={handleUploadFiles}
              data-testid="vault-upload-files-dialog--upload-button"
            >
              {isSubmitting ? 'Uploading…' : 'Upload'}
            </Button>
          </div>
        </div>
        <DuplicateFilesModal
          namesForExistingVaultFilesAndFilesToUpload={
            namesForExistingVaultFilesAndFilesToUpload
          }
        />
      </DialogContent>
    </Dialog>
  )
}

const Metadata = ({
  label,
  value,
  tooltip,
  className,
}: {
  label: string
  value: string
  tooltip?: string
  className?: string
}) => {
  return (
    <div className={cn('flex space-x-2 pl-2', className)}>
      {tooltip ? (
        <Tooltip>
          <TooltipTrigger className="text-left">
            <p className="min-w-32">{label}</p>
          </TooltipTrigger>
          <TooltipContent side="top" className="max-w-96">
            {tooltip}
          </TooltipContent>
        </Tooltip>
      ) : (
        <p className="min-w-32">{label}</p>
      )}
      <p>{value}</p>
    </div>
  )
}

export default VaultUploadFilesDialog
