import { WebPubSubClient } from '@azure/web-pubsub-client'
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'

import { Maybe } from 'types'
import {
  SIDEBAR_CLOSED_WIDTH,
  SIDEBAR_OPEN_WIDTH,
  Theme,
  THEME_KEY,
} from 'types/ui-constants'

import { getItem, setItem } from 'utils/local-store'

import { StatusBarProps } from 'components/common/status-bar'

interface State {
  pendingRequestIds: string[]
  filesUploading: string[]
  prevSidebarOpen: boolean
  isSidebarOpen: boolean
  sidebarWidth: number
  azureWebPubSubClient: WebPubSubClient | null
  statusBar: Maybe<StatusBarProps>
  isStatusBarPreview: boolean
  theme: Theme
  sharepointPickerOpen: boolean
}

interface Action {
  addRequestToPendingRequestIds: (requestId: string) => void
  removeRequestFromPendingRequestIds: (requestId: string) => void
  setIsSidebarOpen: (isOpen: boolean) => void
  setIsSidebarOpenAndToggle: (isOpen: boolean) => void
  revertSidebarOpen: () => void
  addFolderIdToFilesUploading: (folderId: string) => void
  removeFolderIdFromFilesUploading: (folderId: string) => void
  setAzureWebPubSubClient: (client: WebPubSubClient | null) => void
  stopAzureWebPubSubClient: () => void
  setStatusBar: (statusBar: Maybe<StatusBarProps>) => void
  setIsStatusBarPreview: (isStatusBarPreview: boolean) => void
  setTheme: (theme: Theme) => void
  setSharepointPickerOpen: (open: boolean) => void
}

export const useGeneralStore = create(
  devtools(
    immer<State & Action>((set) => ({
      pendingRequestIds: [],
      filesUploading: [],
      prevSidebarOpen: true,
      isSidebarOpen: true,
      sidebarWidth: SIDEBAR_OPEN_WIDTH,
      azureWebPubSubClient: null,
      statusBar: null,
      isStatusBarPreview: false,
      theme: getItem(THEME_KEY) || Theme.LIGHT,
      hasThemes: false,
      sharepointPickerOpen: false,
      addRequestToPendingRequestIds: (requestId: string) =>
        set((state) => {
          return {
            pendingRequestIds: [...state.pendingRequestIds, requestId],
          }
        }),
      removeRequestFromPendingRequestIds: (requestId: string) =>
        set((state) => {
          return {
            pendingRequestIds: state.pendingRequestIds.filter(
              (id) => id !== requestId
            ),
          }
        }),
      addFolderIdToFilesUploading: (folderId: string) =>
        set((state) => {
          return {
            filesUploading: [...state.filesUploading, folderId],
          }
        }),
      removeFolderIdFromFilesUploading: (folderId: string) =>
        set((state) => {
          const filteredFilesUploading = state.filesUploading.filter(
            (id: string) => id !== folderId
          )
          return {
            filesUploading: filteredFilesUploading,
          }
        }),
      setIsSidebarOpen: (isSidebarOpen) => {
        set(() => {
          return {
            isSidebarOpen,
            sidebarWidth: isSidebarOpen
              ? SIDEBAR_OPEN_WIDTH
              : SIDEBAR_CLOSED_WIDTH,
          }
        })
      },
      setIsSidebarOpenAndToggle: (isSidebarOpen) => {
        set((state) => ({
          prevSidebarOpen: state.isSidebarOpen,
          isSidebarOpen,
          sidebarWidth: isSidebarOpen
            ? SIDEBAR_OPEN_WIDTH
            : SIDEBAR_CLOSED_WIDTH,
        }))
      },
      revertSidebarOpen: () => {
        set((state) => ({
          isSidebarOpen: state.prevSidebarOpen,
          sidebarWidth: state.prevSidebarOpen
            ? SIDEBAR_OPEN_WIDTH
            : SIDEBAR_CLOSED_WIDTH,
        }))
      },
      setAzureWebPubSubClient: (client: WebPubSubClient | null) => {
        set(() => {
          return {
            azureWebPubSubClient: client,
          }
        })
      },
      stopAzureWebPubSubClient: () => {
        set((state) => {
          if (state.azureWebPubSubClient) {
            state.azureWebPubSubClient.stop()
          }
          return {
            azureWebPubSubClient: null,
          }
        })
      },
      setStatusBar: (statusBar) => {
        set(() => {
          return {
            statusBar,
          }
        })
      },
      setIsStatusBarPreview: (isStatusBarPreview) => {
        set(() => {
          return {
            isStatusBarPreview,
          }
        })
      },
      setTheme: (theme) => {
        set(() => {
          return {
            theme,
          }
        })
        setItem(THEME_KEY, theme)
      },
      setSharepointPickerOpen: (open) => {
        set(() => {
          return {
            sharepointPickerOpen: open,
          }
        })
      },
    }))
  )
)
