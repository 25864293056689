export enum SharePopoverScreens {
  SHARE = 'share', // share with workspace and view existing shared users
  GRANT_USER_PERMISSION = 'grant-user-permission',
}

export enum ShareType {
  VAULT_PROJECT,
  EVENT,
}

// This ordering matters, the first element is the default
// Because we do Object.values(PermissionLevel) when setting
// available permissions. VIEW should be first by default.
export enum PermissionLevel {
  VIEW = 'VIEW',
  EDIT = 'EDIT',
  FULL_ACCESS = 'FULL_ACCESS',
}

export const PermissionLevelDefinition = {
  [PermissionLevel.VIEW]: {
    name: 'Can view',
    getDescription: (type: ShareType) =>
      type === ShareType.VAULT_PROJECT
        ? 'View queries and files'
        : 'View queries and files on this page',
  },
  [PermissionLevel.EDIT]: {
    name: 'Can edit',
    getDescription: () => 'Write queries and add or remove files',
  },
  [PermissionLevel.FULL_ACCESS]: {
    name: 'Full access',
    // TODO: change this description once it's used in event sharing
    getDescription: () => 'Modify project and grant people access',
  },
}

// TODO: Move to SharePermissionByWorkspace?
export type PermissionsByWorkspace = Array<{
  workspaceId?: number
  workspaceName?: string
  permissionLevel?: PermissionLevel
}>

// TODO: Move to SharePermissionByUser?
export type PermissionsByUser = Array<{
  userId?: string
  userEmail?: string
  permissionLevel?: PermissionLevel
}>

export const SHARE_SUCCESS_MESSAGE = 'Access updated successfully'
export const SHARE_ERROR_MESSAGE = 'Failed to update access'
