import React from 'react'

import { UserInfo } from 'models/user-info'
import Services from 'services'

import ErrorPage, { ErrorPageTitle } from 'components/common/error/error'
import { TextLink } from 'components/ui/text-link'

interface NotAuthorizedStrings {
  title: ErrorPageTitle
  description: React.ReactNode
}

interface NotAuthorizedScreenProps {
  logoutHandler: () => void
  userInfo: UserInfo
}

const PWC_STRINGS: NotAuthorizedStrings = {
  title: ErrorPageTitle.NOT_AUTHORIZED,
  description: (
    <p>
      This is likely caused by your account not having proper access to the
      Harvey platform.
      <br />
      Please contact your internal Harvey administrator within PwC for
      assistance.
      <br />
      <br />
      If you reside in UK Tax, please refer to this{' '}
      <TextLink
        label="website"
        href="https://sites.google.com/pwc.com/generativeai-uktaxtechnology/genai-learning"
      />
      .<br />
      If you reside in UK Deals, please refer to this{' '}
      <TextLink
        label="website"
        href="https://sites.google.com/pwc.com/genai-pilot-rollout-uk-deals/platforms/harvey-ai"
      />
      .<br />
      If you are in any other country, please refer to this{' '}
      <TextLink
        label="list"
        href="https://pwc.sharepoint.com/:x:/r/sites/GBL-TAX-TLS-HarveyAI/_layouts/15/Doc.aspx?sourcedoc=%7B47DF5554-3AB4-4FFE-8148-12127397D4BD%7D&file=Harvey%20Territory%20Project%20Team%20Contacts.xlsx&action=default&mobileredirect=true&DefaultItemOpen=1"
      />{' '}
      to find your local contact.
    </p>
  ),
}

const GENERIC_STRINGS: NotAuthorizedStrings = {
  title: ErrorPageTitle.NOT_AUTHORIZED,
  description:
    'This is likely caused by your account not having proper access to the Harvey platform.\nPlease contact your internal Harvey administrator for assistance.\nIf this issue persists and more help is needed, contact support@harvey.ai.',
}

const NotAuthorizedScreen: React.FC<NotAuthorizedScreenProps> = ({
  logoutHandler,
  userInfo,
}) => {
  let currentStrings = userInfo.IsPwcUser ? PWC_STRINGS : GENERIC_STRINGS

  if (
    userInfo.fetchErr?.message &&
    userInfo.fetchErr?.message.includes('disallowed on this network')
  ) {
    currentStrings = {
      title: ErrorPageTitle.INCORRECT_NETWORK,
      description: userInfo.fetchErr?.message,
    }
  }

  Services.HoneyComb.Record({
    metric: 'ui.show_not_authorized_screen',
    user_id: userInfo.id,
    is_pwc: userInfo.IsPwcUser,
    perms: userInfo.permissions,
    perms_with_source: userInfo.permsWithSource,
    workspace_id: userInfo.workspace.id,
  })

  return (
    <ErrorPage
      title={currentStrings.title}
      description={currentStrings.description}
      primaryCTA={{
        text: 'Retry',
        onClick: () => (window.location.pathname = '/'),
      }}
      secondaryCTA={{
        text: 'Logout',
        onClick: logoutHandler,
      }}
    />
  )
}

export default NotAuthorizedScreen
