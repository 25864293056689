import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'

import { HistoryGrouping } from './history-grouping-def'

interface State {
  grouping: HistoryGrouping | undefined
}

interface Action {
  setGrouping: (grouping: HistoryGrouping | undefined) => void
}

export const useGroupingStore = create(
  devtools(
    immer<State & Action>((set) => ({
      grouping: undefined,
      setGrouping: (grouping) => {
        set(() => ({ grouping }))
      },
    }))
  )
)
