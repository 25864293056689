import React, { useCallback, useEffect, useMemo } from 'react'

import _ from 'lodash'
import { useShallow } from 'zustand/react/shallow'

import { RawUser } from 'models/users'
import {
  getVaultAddOnUsers,
  getVaultReviewPackUsers,
  VaultSeats,
  VaultUser,
} from 'models/vault'
import { Workspace } from 'models/workspace'

import { useAuthUser } from 'components/common/auth-context'
import { useVaultManagementStore } from 'components/settings/workspace/workspace-details/vault-management/vault-management-store'
import { Card, CardContent, CardTitle } from 'components/ui/card'
import { Tabs, TabsContent, TabsList, TabsTrigger } from 'components/ui/tabs'

import VaultUsersAdd from './vault-users-add'
import { VaultUsersLegacy } from './vault-users-legacy'
import VaultUsersTable from './vault-users-table'

interface VaultAddOnUsersProps {
  workspace: Workspace
}

enum VaultUserTab {
  roleUsers = 'roleUsers',
  addUsers = 'addUsers',
  legacyUsers = 'legacyUsers',
}

export interface RoleRawUser extends RawUser {
  dateAdded: string
}

const VaultUsers = ({ workspace }: VaultAddOnUsersProps) => {
  const userInfo = useAuthUser()
  const vaultAddOnUsers = useVaultManagementStore(
    useShallow((state) => state.vaultAddOnUsers)
  )

  const vaultReviewPackUsers = useVaultManagementStore(
    useShallow((state) => state.vaultReviewPackUsers)
  )

  const setVaultAddOnUsers = useVaultManagementStore(
    (state) => state.setVaultAddOnUsers
  )
  const setVaultReviewPackUsers = useVaultManagementStore(
    (state) => state.setVaultReviewPackUsers
  )
  const setRemainingReviewPackFiles = useVaultManagementStore(
    (state) => state.setRemainingReviewPackFiles
  )

  const [isLoading, setIsLoading] = React.useState<boolean>(false)

  const fetchVaultAddOnUsers = useCallback(async () => {
    setIsLoading(true)
    const vaultAddOnUsersData = await getVaultAddOnUsers(workspace.id)
    setVaultAddOnUsers(vaultAddOnUsersData.users ?? [])
    setIsLoading(false)
  }, [setVaultAddOnUsers, workspace.id])

  const fetchVaultReviewPackUsers = useCallback(async () => {
    setIsLoading(true)
    const vaultReviewPackUsersData = await getVaultReviewPackUsers(workspace.id)
    setVaultReviewPackUsers(vaultReviewPackUsersData.users ?? [])
    setRemainingReviewPackFiles(
      vaultReviewPackUsersData.remainingReviewQueryFileLimit ?? 0
    )
    setIsLoading(false)
  }, [workspace.id, setVaultReviewPackUsers, setRemainingReviewPackFiles])

  useEffect(() => {
    void fetchVaultAddOnUsers()
    if (workspace.hasVaultReviewPack) {
      void fetchVaultReviewPackUsers()
    }
  }, [fetchVaultAddOnUsers, fetchVaultReviewPackUsers, workspace])

  const onAddOnUserUpdate = async (vaultUser: VaultUser) => {
    setVaultAddOnUsers([
      ...vaultAddOnUsers.filter(
        (user) => user.userEmail !== vaultUser.userEmail
      ),
      vaultUser,
    ])
  }

  const onReviewPackUpdate = async (seatsData: VaultSeats) => {
    setVaultReviewPackUsers(seatsData.users)
    setRemainingReviewPackFiles(seatsData.remainingReviewQueryFileLimit ?? 0)
    await fetchVaultAddOnUsers()
  }

  // current users is combined set of vaultAddOnUsers and vaultReviewPackUsers
  const currentUsers = useMemo(() => {
    return [...vaultAddOnUsers, ...vaultReviewPackUsers].filter(
      (user) => user.hasSeat
    )
  }, [vaultAddOnUsers, vaultReviewPackUsers])

  if (_.isNil(userInfo) || !userInfo.IsInternalAdminReader) return null

  return (
    <div className="w-full p-2">
      <Card className="p-4">
        <CardTitle className="mb-3 ml-1">Vault Users</CardTitle>
        <CardContent className="mt-2 px-1">
          <Tabs defaultValue={VaultUserTab.roleUsers}>
            <TabsList>
              <TabsTrigger value={VaultUserTab.roleUsers}>Users</TabsTrigger>
              <TabsTrigger value={VaultUserTab.addUsers}>Add users</TabsTrigger>
              <TabsTrigger value={VaultUserTab.legacyUsers}>
                Legacy users
              </TabsTrigger>
            </TabsList>
            <TabsContent value={VaultUserTab.roleUsers}>
              <VaultUsersTable
                isLoading={isLoading}
                vaultUsers={currentUsers}
                workspace={workspace}
                onAddOnUserUpdate={onAddOnUserUpdate}
                onReviewPackUpdate={onReviewPackUpdate}
              />
            </TabsContent>
            <TabsContent value={VaultUserTab.addUsers}>
              <VaultUsersAdd
                workspace={workspace}
                currentUsers={
                  new Set(currentUsers.map((user) => user.userEmail))
                }
                type="add-on"
              />
            </TabsContent>
            <TabsContent value={VaultUserTab.legacyUsers}>
              <VaultUsersLegacy workspace={workspace} />
            </TabsContent>
          </Tabs>
        </CardContent>
      </Card>
    </div>
  )
}

export default VaultUsers
