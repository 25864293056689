import React, { ChangeEvent, useCallback, useEffect } from 'react'

import { useShallow } from 'zustand/react/shallow'

import { ResearchArea } from 'openapi/models/ResearchArea'
import { ResearchFilter } from 'openapi/models/ResearchFilter'

import { displayWarningMessage } from 'utils/toast'
import {
  EMPTY_QUERY_HELP_TEXT,
  IS_LOADING_HELP_TEXT,
  SELECT_RESEARCH_DATABASE_HELP_TEXT,
} from 'utils/tooltip-texts'
import { cn } from 'utils/utils'

import AskHarveyButton from 'components/common/ask-harvey-button'
import useChangeHistory from 'components/common/use-change-history'
import { useNavigationQueryState } from 'components/common/use-navigation-query-state'
import { useResearchStore } from 'components/research/research-store'
import { Card } from 'components/ui/card'
import { Textarea } from 'components/ui/text-area'
import { TooltipTrigger, Tooltip, TooltipContent } from 'components/ui/tooltip'

import { hasSelectedChild } from './research-helpers'

interface InputCardProps {
  area: ResearchArea
  inputRef?: React.MutableRefObject<HTMLTextAreaElement | null>
  onAskHarvey: () => void
  placeholder?: string
  taxonomy: ResearchFilter[]
  children: React.ReactNode
}

export const maxQueryLength = 4000

const InputCard: React.FC<InputCardProps> = ({
  inputRef,
  onAskHarvey,
  taxonomy,
  placeholder,
  area,
  children,
}) => {
  const [query, queryPreview, selectedFilters, setTask, isLoading] =
    useResearchStore(
      useShallow((s) => [
        s.query,
        s.queryPreview,
        s.selectedFilters,
        s.setTask,
        s.isLoading,
      ])
    )
  const queryLength = query.trim().length

  const getIsAskHarveyDisabled = () => {
    if (selectedFilters.length === 0 || isLoading || queryLength === 0) {
      return true
    }

    return (
      // for memos, each L0 should have at least one selected child, otherwise the button should be disabled
      area === ResearchArea.MEMOS &&
      taxonomy.some((x) => !hasSelectedChild(x, selectedFilters))
    )
  }

  const buttonIsDisabled = getIsAskHarveyDisabled()
  const textareaRef = React.useRef<HTMLTextAreaElement | null>(null)
  const mergedRefs = (ref: HTMLTextAreaElement) => {
    textareaRef.current = ref
    if (inputRef) inputRef.current = ref
  }

  const getButtonTooltip = () => {
    if (selectedFilters.length === 0) {
      return SELECT_RESEARCH_DATABASE_HELP_TEXT
    } else if (isLoading) {
      return IS_LOADING_HELP_TEXT
    } else if (queryLength === 0) {
      return EMPTY_QUERY_HELP_TEXT
    } else {
      return ''
    }
  }

  const setQuery = useCallback(
    (value: string) => {
      setTask({ query: value })
    },
    [setTask]
  )

  const { handleKeyDown, setValue } = useChangeHistory(setQuery)

  const navigationState = useNavigationQueryState()
  useEffect(() => {
    if (navigationState?.query) {
      setValue(navigationState.query)
    }
  }, [navigationState, setValue])

  const handleQueryChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    if (e.target.value.length > maxQueryLength) {
      displayWarningMessage(
        `Query length cannot exceed ${maxQueryLength} characters`
      )
    } else {
      setValue(e.target.value)
    }
  }

  const nonPreviewQuery = queryPreview ? '' : query

  return (
    <Card className="relative overflow-clip shadow-none">
      <div className="h-40">
        <Textarea
          ref={mergedRefs}
          className={cn(
            'research-input-query h-28 resize-none border-0 py-3 pl-4 pr-[120px]',
            {
              'overflow-hidden': queryPreview,
            }
          )}
          placeholder={
            queryPreview ||
            placeholder ||
            'Select at least one source of knowledge and enter a research query'
          }
          value={nonPreviewQuery}
          disabled={isLoading || !!queryPreview}
          onChange={handleQueryChange}
          onKeyDown={handleKeyDown}
          overflowHintSide="end"
          data-testid="research-text-input"
        />
        <div className="research-submit absolute right-4 top-3">
          <AskHarveyButton
            handleSubmit={onAskHarvey}
            disabled={buttonIsDisabled}
            tooltip={getButtonTooltip()}
            dataTestId="research-submit"
            isLoading={isLoading}
            inputRef={textareaRef}
          />
        </div>
        <div className="absolute right-4 top-32">
          <Tooltip>
            <TooltipTrigger>
              <p className="cursor-default text-right text-sm text-muted">
                {queryLength} / {maxQueryLength}
              </p>
            </TooltipTrigger>
            <TooltipContent>Maximum query length</TooltipContent>
          </Tooltip>
        </div>
      </div>
      {children}
    </Card>
  )
}

export default InputCard
