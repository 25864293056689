import { useCallback, useEffect, useRef } from 'react'

import { Maybe } from 'types'

import {
  BroadcastChannelDetails,
  BroadcastChannelMessageEvent,
  BroadcastMessage,
  BroadcastMessageTypes,
} from './use-broadcast-channel-utils'

export const useBroadcastChannelReceive = <T>(
  broadcastChannel: BroadcastChannelDetails,
  onDataReceived: (data: T) => void
) => {
  const channelRef = useRef<BroadcastChannel | null>(null)

  // This useEffect is responsible for the lifecycle of the BroadcastChannel
  useEffect(() => {
    channelRef.current = new BroadcastChannel(
      broadcastChannel.key + broadcastChannel.name
    )

    // Send a ready signal
    channelRef.current.postMessage({ type: BroadcastMessageTypes.READY })

    // Cleanup function to close the channel when the component unmounts or dependencies change
    return () => {
      if (channelRef.current) {
        channelRef.current.close()
        channelRef.current = null
      }
    }
  }, [broadcastChannel])

  // This useEffect is for setting up and cleaning up the data reception event listener
  useEffect(() => {
    const channel = channelRef.current
    if (!channel) return

    const receiveData = (event: MessageEvent<BroadcastMessage<T>>) => {
      if (event.data.type === BroadcastMessageTypes.DATA) {
        onDataReceived(event.data.payload!)
      }
    }

    channel.addEventListener(BroadcastChannelMessageEvent, receiveData)

    return () => {
      channel.removeEventListener(BroadcastChannelMessageEvent, receiveData)
    }
  }, [onDataReceived])
}

export const useBroadcastChannelSend = <T>(
  broadcastChannel: BroadcastChannelDetails
) => {
  const channelRef = useRef<Maybe<BroadcastChannel>>(null)

  useEffect(() => {
    channelRef.current = new BroadcastChannel(
      broadcastChannel.key + broadcastChannel.name
    )

    return () => {
      if (channelRef.current) {
        channelRef.current.close()
        channelRef.current = null
      }
    }
  }, [broadcastChannel])

  const sendData = useCallback((data: T) => {
    const checkReady = (event: MessageEvent<BroadcastMessage<T>>) => {
      if (event.data.type === BroadcastMessageTypes.READY) {
        // Now send the data
        if (channelRef.current) {
          channelRef.current.postMessage({
            type: BroadcastMessageTypes.DATA,
            payload: data,
          })
          channelRef.current.removeEventListener(
            BroadcastChannelMessageEvent,
            checkReady
          )
        }
      }
    }

    if (channelRef.current) {
      channelRef.current.addEventListener(
        BroadcastChannelMessageEvent,
        checkReady
      )
    }
  }, [])

  return sendData
}
