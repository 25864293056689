// Height of the 'Sources' header
export const HEADER_HEIGHT = 28
// Height of the 'Files' and 'Databases' header under 'Sources'
export const SOURCE_HEADER_HEIGHT = 24

// Height of a single database button
export const DATABASE_HEIGHT = 46
export const DATABASE_GAP = 8

// Default height of the dropzone
export const BASE_HEIGHT = DATABASE_HEIGHT * 3 + DATABASE_GAP * 2
export const BASE_VERTICAL_PADDING = 24

export const calculateAssistantSourceHeight = (
  numDataSources: number,
  numFileSources: number,
  hasFiles: boolean
) => {
  // We fix the height so when user moves on to files uploaded view,
  // or knowledge source view, the area doesn't jump.
  let containerHeight = BASE_HEIGHT
  if (numDataSources > 0 && numDataSources <= 3) {
    const databasesHeight =
      numDataSources * DATABASE_HEIGHT + (numDataSources - 1) * DATABASE_GAP
    containerHeight = Math.max(containerHeight, databasesHeight)
  } else if (numDataSources > 3) {
    const databasesHeight =
      3 * DATABASE_HEIGHT + 2 * DATABASE_GAP + 0.75 * DATABASE_HEIGHT
    containerHeight = Math.max(containerHeight, databasesHeight)
  }
  if (hasFiles && numFileSources > 0) {
    containerHeight += SOURCE_HEADER_HEIGHT
  }
  containerHeight += HEADER_HEIGHT + BASE_VERTICAL_PADDING
  return containerHeight
}
