import React from 'react'

import { RelatedQuestion } from 'openapi/models/RelatedQuestion'

import { useAnalytics } from 'components/common/analytics/analytics-context'

interface Props {
  questions?: RelatedQuestion[]
  onSelectQuestion: (text: string) => void
}

const AssistantFollowUps = ({ questions, onSelectQuestion }: Props) => {
  const { trackEvent } = useAnalytics()
  const handleSelectQuestion = (text: string) => {
    trackEvent('Follow-up Selected', {
      query_length: text.length,
    })
    onSelectQuestion(text)
  }
  if (!questions?.length) return null
  return (
    <div id="assistant-followups">
      <h4 className="text-lg font-semibold">Follow-ups</h4>
      <div className="mb-2 mt-3 divide-y">
        {questions.map((question) => (
          <button
            key={question.text}
            className="w-full px-1 py-2 text-left text-sm hover:bg-button-secondary"
            onClick={() => handleSelectQuestion(question.text)}
          >
            {question.text}
          </button>
        ))}
      </div>
    </div>
  )
}

export default AssistantFollowUps
