/* tslint:disable */
/* eslint-disable */
/**
 * Harvey Web App - User Permissions
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const PermSource = {
    USER: 'USER'
} as const;
export type PermSource = typeof PermSource[keyof typeof PermSource];


export function instanceOfPermSource(value: any): boolean {
    for (const key in PermSource) {
        if (Object.prototype.hasOwnProperty.call(PermSource, key)) {
            if (PermSource[key as keyof typeof PermSource] === value) {
                return true;
            }
        }
    }
    return false;
}

export function PermSourceFromJSON(json: any): PermSource {
    return PermSourceFromJSONTyped(json, false);
}

export function PermSourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): PermSource {
    return json as PermSource;
}

export function PermSourceToJSON(value?: PermSource | null): any {
    return value as any;
}

