// This file contains the Harvey implementation of react-query (now called tanstack-query)
// It creates a persistor to store the queryCache offline, creates a query client, and also sets some default options
// for dehydration and the like.
// It exports a component that we use to wrap our root component for authorized routes
import React from 'react'

import { createAsyncStoragePersister } from '@tanstack/query-async-storage-persister'
import { QueryClient } from '@tanstack/react-query'
import { DehydrateOptions } from '@tanstack/react-query'
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client'

import { isQueryStoredOnDisk } from 'models/queries/all-query-keys'

import { HarvStorage } from './offline-db'

// Dehydration options to not dehydrate some queries
const dehydrateOptions: DehydrateOptions = {
  shouldDehydrateQuery: (query) => {
    const queryIsReadyForPersistance = query.state.status === 'success'
    return queryIsReadyForPersistance && isQueryStoredOnDisk(query.queryKey)
  },
}

// Interface for query provider wrapper
interface QueryProviderWrapperProps {
  children: React.ReactNode
  userBuster: string // a string to see if we should bust the cache because it is a different user
}

// Create a persister for react-query persistance
const asyncStoragePersister = createAsyncStoragePersister({
  storage: HarvStorage,
})

// create a query client for react-query
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // gcTime is the garbage collection time, which collects and removes queries from the cache after they unmount (and then after the gc time interval passes)
      // see this link to see why it is important to set the gc time when using offline dehydration and hydration:
      // https://tanstack.com/query/latest/docs/react/plugins/persistQueryClient#how-it-works
      gcTime: 1000 * 60 * 60 * 24, // 24 hours,
    },
  },
})

// Wrapper component
const ReactQueryWrapper: React.FC<QueryProviderWrapperProps> = ({
  children,
  userBuster,
}) => {
  return (
    // Provide the client to your App
    <PersistQueryClientProvider
      client={queryClient}
      persistOptions={{
        persister: asyncStoragePersister,
        buster: userBuster,
        dehydrateOptions,
      }}
    >
      {children}
    </PersistQueryClientProvider>
  )
}

// export
export default ReactQueryWrapper
