import React, { useState, useCallback, useEffect, useRef } from 'react'
import { useLocation, useParams, useSearchParams } from 'react-router-dom'

import _ from 'lodash'
import { Check } from 'lucide-react'

import { useNavigateWithQueryParams } from 'hooks/use-navigate-with-query-params'
import { displayErrorMessage } from 'utils/toast'

import { AppHeader } from 'components/common/app-header'
import { AppMain } from 'components/common/app-main'
import { useAuthUser } from 'components/common/auth-context'
import Markdown from 'components/common/markdown/markdown'
import {
  UserComparison,
  FetchUserComparisons,
  FetchComparisonDetails,
  ComparisonDetail,
  DecideOnComparison,
} from 'components/compare/compare-fetcher'
import BasicTransition from 'components/ui/basic-transition'
import { Button } from 'components/ui/button'
import {
  Card,
  CardTitle,
  CardHeader,
  CardDescription,
  CardContent,
} from 'components/ui/card'
import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationNext,
  PaginationPageInput,
  PaginationPrevious,
} from 'components/ui/pagination/pagination'
import { ScrollArea } from 'components/ui/scroll-area'

import ComparePreferencePicker from './compare-preference-picker'
import Preference from './compare-response'

const Compare = (): JSX.Element => {
  const [userComparisons, setUserComparisons] = useState([] as UserComparison[])
  const [currentComparisonIndex, setCurrentComparisonIndex] = useState(0)
  const [currentComparisonDetails, setCurrentComparisonDetails] = useState(
    {} as ComparisonDetail
  )
  const [loading, setLoading] = useState(false)
  const [isSwapped, setIsSwapped] = useState(false)
  const [currentPreference, setCurrentPreference] = useState('')

  const queryIdSearchParamKey = 'queryId'
  const userInfo = useAuthUser()
  const userId = userInfo?.id || ''
  const { id: experimentId = '' } = useParams<{ id: string }>()
  const [searchParams] = useSearchParams()
  const queryIdParam = searchParams.get(queryIdSearchParamKey)
  const navigateWithQueryParams = useNavigateWithQueryParams()
  const location = useLocation()

  const queryIdRef = useRef<number | null>(null)

  const [paginationInput, setPaginationInput] = useState(1)

  // Response swapping logic
  const hashComparisonId = (comparisonId: string) => {
    if (!comparisonId) return 0
    let hash = 0
    for (let i = 0; i < comparisonId.length; i++) {
      hash = (hash << 5) - hash + comparisonId.charCodeAt(i)
      hash |= 0
    }
    return hash
  }

  const response1 = isSwapped
    ? currentComparisonDetails.responseB
    : currentComparisonDetails.responseA
  const response2 = isSwapped
    ? currentComparisonDetails.responseA
    : currentComparisonDetails.responseB
  const preference1 = isSwapped ? 'B' : 'A'
  const preference2 = isSwapped ? 'A' : 'B'

  const fetchComparisonDetails = useCallback(
    async (
      userComparisonsInput: UserComparison[],
      currentComparisonIndexInput: number
    ) => {
      setLoading(true)
      setCurrentComparisonIndex(currentComparisonIndexInput)

      const newQueryParamId = currentComparisonIndexInput + 1 // params are 1-indexed
      setPaginationInput(newQueryParamId)
      queryIdRef.current = newQueryParamId
      const searchParams = new URLSearchParams()
      searchParams.set(queryIdSearchParamKey, newQueryParamId.toString())
      const searchParamString = `?${searchParams.toString()}`
      navigateWithQueryParams(location.pathname + searchParamString)

      try {
        setIsSwapped(
          hashComparisonId(
            userComparisons[currentComparisonIndex]?.comparisonId
          ) %
            2 ===
            0
        )

        const currentUserComparison =
          userComparisonsInput[currentComparisonIndexInput]
        const currentPreference = !_.isEmpty(currentUserComparison)
          ? currentUserComparison.preference
          : ''
        const comparisonDetails = await FetchComparisonDetails(
          currentUserComparison?.comparisonId
        )
        if (comparisonDetails) {
          setCurrentComparisonDetails(comparisonDetails)
        }
        setCurrentPreference(currentPreference)
      } finally {
        setTimeout(() => {
          setLoading(false)
        }, 200)
      }
    },
    [navigateWithQueryParams, location, userComparisons, currentComparisonIndex]
  )

  const goToNextIncomplete = useCallback(
    (userComparisonsInput: UserComparison[], startIdx: number = 0) => {
      setLoading(true)
      const nextIncompleteIndexUnshifted =
        userComparisonsInput.length > 0
          ? [
              ...userComparisonsInput.slice(startIdx),
              ...userComparisonsInput.slice(0, startIdx),
            ].findIndex((c) => !c.preference)
          : 0
      const nextIncompleteIndex =
        nextIncompleteIndexUnshifted > -1
          ? (nextIncompleteIndexUnshifted + startIdx) %
            userComparisonsInput.length
          : -1
      if (
        nextIncompleteIndex > -1 &&
        (nextIncompleteIndex != currentComparisonIndex ||
          _.isEmpty(currentComparisonDetails))
      ) {
        fetchComparisonDetails(userComparisonsInput, nextIncompleteIndex)
      }
      setLoading(false)
    },
    [currentComparisonIndex, currentComparisonDetails, fetchComparisonDetails]
  )

  // Increment and decrement functions
  const incrementComparisonIndex = () => {
    if (currentComparisonIndex < userComparisons.length - 1) {
      fetchComparisonDetails(userComparisons, currentComparisonIndex + 1)
    }
  }

  const decrementComparisonIndex = () => {
    if (currentComparisonIndex > 0) {
      fetchComparisonDetails(userComparisons, currentComparisonIndex - 1)
    }
  }

  const fetchData = useCallback(async () => {
    setLoading(true)
    const newUserComparisons = await FetchUserComparisons(experimentId)
    newUserComparisons.length > 0 &&
      newUserComparisons.sort((a: UserComparison, b: UserComparison) =>
        a.comparisonId.localeCompare(b.comparisonId)
      )
    setUserComparisons(newUserComparisons)

    setLoading(false)
    return newUserComparisons
  }, [experimentId, setUserComparisons])

  const handleNavigation = useCallback(
    async (queryId: number | null = null) => {
      const newUserComparisons = await fetchData()
      if (
        !_.isNil(queryId) &&
        queryId > 0 &&
        queryId <= newUserComparisons.length
      ) {
        fetchComparisonDetails(newUserComparisons, queryId - 1)
      } else {
        goToNextIncomplete(newUserComparisons)
      }
    },
    [fetchData, goToNextIncomplete, fetchComparisonDetails]
  )

  useEffect(() => {
    let queryId: number | null = null
    if (queryIdParam) {
      const parsed = parseInt(queryIdParam)
      if (!isNaN(parsed)) {
        queryId = parsed
      }
    }
    if (!_.isNil(queryId) && queryId != queryIdRef.current) {
      void handleNavigation(queryId)
    } else if (_.isNil(queryId)) {
      void handleNavigation()
    }
  }, [handleNavigation, queryIdParam])

  const recordComparisonPreference = async (preference: string) => {
    if (!preference) {
      displayErrorMessage('Please select a preference')
      return
    }
    setCurrentPreference(preference)
    setLoading(true)

    const comparisonId = userComparisons[currentComparisonIndex]?.comparisonId
    const experimentId = userComparisons[currentComparisonIndex]?.experimentId
    const params = {
      experimentId,
      comparisonId,
      preference,
      reason: preference == 'NA' ? '{}' : '',
      userId,
    }

    const decisionRecorded = await DecideOnComparison(params)
    if (!decisionRecorded) {
      displayErrorMessage('Failed to update preference')
      setCurrentPreference('')
      setLoading(false)
    } else {
      const newUserComparisons = await fetchData()
      goToNextIncomplete(newUserComparisons, currentComparisonIndex)
    }
  }

  const handleSetCurrentPreference = (preference: string) => {
    let newPreference = ''
    switch (preference) {
      case '1':
        newPreference = preference1
        break
      case '2':
        newPreference = preference2
        break
      case '3':
        newPreference = 'Tie'
        break
      default:
        newPreference = ''
    }
    setCurrentPreference(newPreference)
  }

  if (_.isNil(userInfo) || !userInfo.IsResponseComparisonUser) {
    return <div />
  }
  // Calculate progress
  const totalComparisons = userComparisons.length
  const completedComparisons =
    userComparisons.length > 0
      ? userComparisons.filter((c) => c.preference).length
      : 0
  const hasUnsetPreference = !_.isEmpty(userComparisons)
    ? userComparisons.some((c) => !c.preference)
    : false

  if (!loading && userComparisons.length == 0) {
    return (
      <AppMain>
        <AppHeader
          title="Compare"
          subtitle="Compare model responses"
          breadcrumbs={<div />}
        />
        <div
          className="container mx-auto flex h-full flex-col space-y-4 py-4"
          data-testid="comparison-container"
        >
          No comparisons are available
        </div>
      </AppMain>
    )
  }

  const isExperimentComplete = userComparisons.every((c) => c.preference)

  return (
    <AppMain>
      <AppHeader
        title="Compare"
        subtitle="Compare model responses"
        breadcrumbs={<div />}
        actions={
          <div className="flex justify-end">
            {/* Progress Bar */}
            <div className="mr-2 text-center">
              {`${completedComparisons}/${totalComparisons} Completed`}
              {completedComparisons > 0 ? (
                <div
                  style={{
                    width: `${Math.round(
                      (completedComparisons / totalComparisons) * 100
                    )}%`,
                  }}
                  className="mt-1 h-0.5 rounded-t bg-primary"
                />
              ) : (
                <div className="w-100 mt-1 h-0.5 rounded-t bg-accent" />
              )}
            </div>
          </div>
        }
      />
      <ScrollArea className="h-full">
        <div
          className="container mx-auto flex h-full flex-col space-y-4 py-4"
          data-testid="comparison-container"
        >
          <BasicTransition show={!loading}>
            {isExperimentComplete ? (
              <CardTitle className="flex justify-start">
                All comparisons completed
                <Check size={24} className="ml-2 text-primary" />
              </CardTitle>
            ) : userComparisons.length > 0 ? (
              <div className="comparison">
                {/* Query Details */}
                <Card>
                  <CardHeader>
                    <CardTitle>
                      <div className="flex justify-between">
                        Query ({currentComparisonIndex + 1}/{totalComparisons})
                      </div>
                    </CardTitle>
                    <div className="h-1" />
                    <CardDescription>
                      {currentComparisonDetails.taxNetwork}
                    </CardDescription>
                    <CardContent>
                      <div className="mt-2" />
                      <BasicTransition show={!loading}>
                        {currentComparisonDetails.query && (
                          <Markdown content={currentComparisonDetails.query} />
                        )}
                      </BasicTransition>
                    </CardContent>
                  </CardHeader>
                  <div className="mx-2 my-2 flex justify-end">
                    <Pagination className="flex items-center justify-end space-x-2">
                      {hasUnsetPreference && (
                        <Button
                          className="mx-1"
                          variant="outline"
                          onClick={() =>
                            goToNextIncomplete(
                              userComparisons,
                              currentComparisonIndex
                            )
                          }
                          disabled={_.isNil(
                            userComparisons[currentComparisonIndex].preference
                          )}
                        >
                          Next incomplete
                        </Button>
                      )}
                      <PaginationContent>
                        <PaginationItem>
                          <PaginationPrevious
                            onClick={decrementComparisonIndex}
                          />
                        </PaginationItem>
                        <PaginationItem>
                          <PaginationPageInput
                            page={paginationInput}
                            setPage={setPaginationInput}
                            onSubmit={(page) => handleNavigation(page)}
                          />{' '}
                          of {totalComparisons}
                        </PaginationItem>
                        <PaginationItem>
                          <PaginationNext onClick={incrementComparisonIndex} />
                        </PaginationItem>
                      </PaginationContent>
                    </Pagination>
                  </div>
                </Card>
                <ComparePreferencePicker
                  selectedPreference={
                    currentPreference === preference1
                      ? '1'
                      : currentPreference === preference2
                      ? '2'
                      : currentPreference === 'Tie'
                      ? '3'
                      : ''
                  }
                  onSelectPreference={(preference) => {
                    handleSetCurrentPreference(preference)
                  }}
                  onSubmit={() => recordComparisonPreference(currentPreference)}
                  loading={loading}
                />
                <div className="mb-5 mt-2 flex justify-between">
                  <div className="mt-2 flex-1">
                    <Preference
                      headerText="Response 1"
                      markdown={response1}
                      isLoading={loading}
                      isSelected={
                        currentPreference === preference1 ||
                        currentPreference === 'Tie'
                      }
                      onSelect={() => setCurrentPreference(preference1)}
                    />
                  </div>
                  <div className="mt-2 flex-1">
                    <Preference
                      headerText="Response 2"
                      markdown={response2}
                      isLoading={loading}
                      isSelected={
                        currentPreference === preference2 ||
                        currentPreference === 'Tie'
                      }
                      onSelect={() => setCurrentPreference(preference2)}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div>No comparisons are available</div>
            )}
          </BasicTransition>
        </div>
      </ScrollArea>
    </AppMain>
  )
}

export default Compare
