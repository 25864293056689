import React from 'react'

import { createFileName, parsedName } from 'utils/file-utils'

import { Button } from 'components/ui/button'
import { Dialog, DialogContent, DialogFooter } from 'components/ui/dialog'
import VaultUploadedFileList from 'components/vault/components/new-project/vault-uploaded-file-list'
import { VaultDialogHeader } from 'components/vault/dialogs/vault-dialog-header'
import { FileToUpload } from 'components/vault/utils/vault'
import { useVaultCreateProjectStore } from 'components/vault/utils/vault-create-project-store'
import { useVaultStore } from 'components/vault/utils/vault-store'

type DuplicateFilesModalProps = {
  namesForExistingVaultFilesAndFilesToUpload: string[]
}

const DuplicateFilesModal: React.FC<DuplicateFilesModalProps> = ({
  namesForExistingVaultFilesAndFilesToUpload,
}) => {
  const filesToUpload = useVaultCreateProjectStore((s) => s.filesToUpload)
  const duplicateFiles = useVaultCreateProjectStore((s) => s.duplicateFiles)
  const isDuplicateModalOpen = useVaultStore((s) => s.isDuplicateModalOpen)
  const setDuplicateFiles = useVaultCreateProjectStore(
    (s) => s.setDuplicateFiles
  )
  const setFilesToUpload = useVaultCreateProjectStore((s) => s.setFilesToUpload)
  const setIsDuplicateModalOpen = useVaultStore(
    (s) => s.setIsDuplicateModalOpen
  )
  const handleFileDelete = (fileToDelete: FileToUpload) => {
    setDuplicateFiles(
      duplicateFiles.filter((file) => file.name !== fileToDelete.name)
    )
  }

  const handleCancel = () => {
    // If cancel, remove the duplicates and reset
    setIsDuplicateModalOpen(false)
    setDuplicateFiles([])
  }

  const handleSkipDuplicates = () => {
    // Simply close the modal and don't add duplicates
    setIsDuplicateModalOpen(false)
    setDuplicateFiles([])
  }

  const handleKeepDuplicates = () => {
    const existingNamesSet = new Set<string>(
      namesForExistingVaultFilesAndFilesToUpload
    )

    const renamedDuplicateFiles: FileToUpload[] = duplicateFiles.map((file) => {
      const parsedFileName = parsedName(file.name)
      const newFileName = createFileName(parsedFileName, existingNamesSet)
      if (newFileName !== parsedFileName) {
        // Optionally, you can track that the file was renamed
        // e.g., trackEvent('Vault Files Upload - File Renamed', { originalName: parsedFileName, newName: newFileName })
      }
      existingNamesSet.add(newFileName)
      return {
        ...file,
        name: newFileName,
      }
    })

    // Add the renamed duplicates to the filesToUpload list
    setFilesToUpload([...filesToUpload, ...renamedDuplicateFiles])
    setIsDuplicateModalOpen(false)
    setDuplicateFiles([])
  }

  return (
    <Dialog open={isDuplicateModalOpen} onOpenChange={setIsDuplicateModalOpen}>
      <DialogContent showCloseIcon={false}>
        <VaultDialogHeader
          dialogTitle="Duplicate Files Detected"
          dialogDescription="Duplicate files in the current folder"
          currentFolder={null}
        />
        <div className="flex flex-col space-y-2">
          <p>The following files already exist in the folder:</p>
          <VaultUploadedFileList
            height={192}
            filesToUpload={duplicateFiles}
            isSubmitting={false}
            onFileDelete={handleFileDelete}
          />
          {duplicateFiles.length > 0 ? (
            <p className="mt-4">What would you like to do?</p>
          ) : (
            <p className="mt-4">
              All duplicate files have been removed. You can now close this
              dialog.
            </p>
          )}
        </div>
        <DialogFooter className="mt-6 flex justify-end space-x-2">
          <Button variant="ghost" onClick={handleCancel}>
            Cancel
          </Button>
          {duplicateFiles.length > 0 && (
            <>
              <Button variant="destructive" onClick={handleSkipDuplicates}>
                Skip duplicates
              </Button>
              <Button
                onClick={handleKeepDuplicates}
                data-testid="vault-duplicate-files-modal--keep-button"
              >
                Keep duplicates
              </Button>
            </>
          )}
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default DuplicateFilesModal
