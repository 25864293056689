import React from 'react'

const AssistantHighlightTip = () => {
  return (
    <div>
      <div className="mb-1 text-2xs font-medium text-muted">Tip</div>
      <div className="text-xs">
        You can{' '}
        <span className="relative inline-block overflow-hidden align-bottom text-xs before:absolute before:-z-10 before:h-full before:w-full before:animate-[highlight-once_2.5s_ease-in-out_2.5s_both] before:bg-highlight before:content-['']">
          select text
        </span>{' '}
        in the draft to make changes to specific sections
      </div>
    </div>
  )
}

export default AssistantHighlightTip
