import React from 'react'

import { isNil } from 'lodash'

import Services from 'services'

import { cn, getCurrentRouteStringFromLocation } from 'utils/utils'

import { useAnalytics } from 'components/common/analytics/analytics-context'
import Markdown from 'components/common/markdown/markdown'
import { Badge } from 'components/ui/badge'
import Citation from 'components/ui/citation'

interface Props {
  title: string
  text: string
  badges?: Badge[]
  metadata?: string[]
  href?: string
  onClick?: () => void
  number?: string
  useLongSnippets?: boolean
}

interface Badge {
  text: string
  variant: 'default' | 'secondary' | 'destructive' | 'outline' | 'ghost'
}

export const SourceCard: React.FC<Props> = ({
  title,
  text,
  badges,
  metadata,
  href,
  onClick,
  number,
  useLongSnippets,
}) => {
  const { trackEvent } = useAnalytics()
  const internalOnClick = () => {
    onClick?.()
    Services.HoneyComb.Record({
      metric: 'ui.source_card_clicked',
      route: getCurrentRouteStringFromLocation(window.location.pathname),
    })
    trackEvent('Source Card Clicked')
  }

  return (
    <a
      href={href}
      target="_blank"
      rel="noreferrer"
      className={cn(
        'duration-50 group -ml-2 flex w-full cursor-pointer flex-col border-l-2 border-transparent pl-2 transition-all hover:border-primary'
      )}
      onClick={internalOnClick}
      data-testid="source-item"
    >
      <div
        className="flex items-start justify-between space-x-2"
        data-testid="assistant--source-card"
      >
        <div className="flex-1">
          <div className="flex items-center space-x-1">
            {number ? (
              <Citation data-testid={`source-citation-${number}`}>
                {number}
              </Citation>
            ) : null}
            <span className="truncate text-sm font-semibold">{title}</span>
          </div>
          {!isNil(metadata) && metadata.length > 0 && (
            <div className="flex space-x-2 divide-x">
              {metadata.map((meta, index) => (
                <p key={index} className="pl-2 first:pl-0">
                  {meta}
                </p>
              ))}
            </div>
          )}
          <div className="break-all" data-testid="source-text">
            <Markdown
              content={text}
              lineClamp={useLongSnippets ? 3 : 1}
              className="*:text-xs"
            />
          </div>
        </div>
        {!isNil(badges) && badges.length > 0 && (
          <div className="space-x-2">
            {badges.map((badge, index) => (
              <Badge
                key={index}
                variant={badge.variant}
                className="text-nowrap"
              >
                {badge.text}
              </Badge>
            ))}
          </div>
        )}
      </div>
    </a>
  )
}
