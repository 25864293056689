import React from 'react'
import { useLocation, Navigate } from 'react-router-dom'

interface RedirectWithQueryProps {
  to: string // Destination path
}

const RedirectWithQuery: React.FC<RedirectWithQueryProps> = ({ to }) => {
  const location = useLocation()
  return <Navigate to={`${to}${location.search}`} replace />
}

export default RedirectWithQuery
