import { toLower } from 'lodash'

import {
  PaginatedResponse,
  PaginationOptions,
} from 'models/queries/lib/use-paginated-query'
import { LibraryVisbilityScope } from 'openapi/models/LibraryVisbilityScope'
import Services from 'services'

import { LibraryItem, LibraryItemKind } from './library-types'

export const FetchLibraryItems = async (
  type: LibraryItemKind,
  pagination?: PaginationOptions
) => {
  const { pageNumber, pageSize, createdBefore } = pagination || {}
  const searchParams = new URLSearchParams()
  if (pageNumber) searchParams.append('page_number', pageNumber.toString())
  if (pageSize) searchParams.append('page_size', pageSize.toString())
  if (createdBefore) {
    searchParams.append('created_before_exclusive', createdBefore)
  }

  const requestPath = `library/${toLower(type)}${
    searchParams.size > 0 ? '?' + searchParams.toString() : ''
  }`
  const response = await Services.Backend.Get<PaginatedResponse<LibraryItem>>(
    requestPath,
    { throwOnError: true }
  )
  return response
}

export const getLibraryItemsExportURL = async (
  kind: LibraryItemKind,
  visibilityScope: LibraryVisbilityScope
): Promise<string> => {
  const route = `library/${toLower(kind)}/export`
  const params = {
    visibilityScope: visibilityScope,
  }
  const response = await Services.Backend.Post<{ signedUrl: string }>(
    route,
    params,
    { throwOnError: true }
  )

  if (!response.signedUrl) {
    throw new Error('No signed URL returned')
  }

  return response.signedUrl
}
