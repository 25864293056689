import React from 'react'

import { cn } from 'utils/utils'

interface FootnoteProps {
  text: string
  isCurrentSource: boolean
  onClick: () => void
}

const Footnote = ({ text, isCurrentSource, onClick }: FootnoteProps) => {
  return (
    <button
      onClick={onClick}
      className={cn(
        'inline-flex size-4 shrink-0 cursor-pointer items-center justify-center rounded border bg-accent text-[10px] font-normal transition hover:border-input-focused',
        {
          'border-background-interactive': isCurrentSource,
        }
      )}
    >
      {text}
    </button>
  )
}

export default Footnote
