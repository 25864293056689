/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'

import _ from 'lodash'

import { ToFrontendKeys } from 'utils/utils'

import Markdown from 'components/common/markdown/markdown'
import BasicTransition from 'components/ui/basic-transition'
import { Card, CardTitle, CardHeader, CardContent } from 'components/ui/card'

interface PreferenceProps {
  headerText: string
  markdown: string
  isLoading?: boolean
  isSelected: boolean
  onSelect: () => void
}

const Preference = ({
  headerText,
  markdown,
  isSelected,
  isLoading,
  onSelect,
}: PreferenceProps) => {
  let content = markdown
  let annotations: { [key: string]: { documentUrl: string } } = {}
  try {
    const parsed = JSON.parse(markdown || '')
    if (parsed?.response && parsed?.annotations) {
      content = _.isEmpty(parsed.response) ? markdown : parsed.response
      annotations = _.isEmpty(parsed.annotations)
        ? {}
        : ToFrontendKeys(parsed.annotations)
    }
  } catch {
    content = markdown
  }

  const getHrvyInfoMetadata = (id: string) => {
    if (_.isEmpty(annotations[id])) return
    return { url: annotations[id].documentUrl }
  }
  return (
    <BasicTransition show={!isLoading}>
      <div
        className="m-2 flex-1 cursor-pointer"
        role="button"
        onClick={onSelect}
        tabIndex={0}
      >
        <Card
          className={
            isSelected
              ? 'border-2 border-primary'
              : ' hover:border-input-focused'
          }
        >
          <CardHeader>
            <CardTitle>{headerText}</CardTitle>
            <div className="h-1" />
            <CardContent>
              {markdown && (
                <Markdown
                  getHrvyInfoMetadata={getHrvyInfoMetadata}
                  content={content}
                  width="100%"
                />
              )}
            </CardContent>
          </CardHeader>
        </Card>
      </div>
    </BasicTransition>
  )
}

export default Preference
