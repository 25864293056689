import { useQuery, UseQueryOptions } from '@tanstack/react-query'

export const defaultRetry = () => {
  // Our Services.Backend client has retries built in already
  return false
}

/**
 * useWrappedQuery wraps react-query's useQuery with default options.
 * This should always be used in place of directly using `useQuery`
 */
export const useWrappedQuery = <
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
>(
  options: UseQueryOptions<TQueryFnData, TError, TData>
) => {
  return useQuery<TQueryFnData, TError, TData>({
    retry: defaultRetry,
    ...options,
  })
}
