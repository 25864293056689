import { useEffect, useCallback, useRef } from 'react'

import _ from 'lodash'

import { getStatusMessageForWorkspace } from 'models/workspace'
import { useGeneralStore } from 'stores/general-store'
import { Maybe } from 'types'

import { StatusBarProps } from './common/status-bar'

const REFETCH_INTERVAL = 1000 * 60 // 1 minute

export const useStatusBarFetcher = (workspaceId: number) => {
  const setStatusBar = useGeneralStore((s) => s.setStatusBar)
  const lastStatusBarRef = useRef<Maybe<StatusBarProps>>(null)
  const intervalIdRef = useRef<NodeJS.Timeout | null>(null)

  const fetchStatusBar = useCallback(async () => {
    // don't fetch status bar if the tab is not visible
    if (document.visibilityState !== 'visible') {
      return
    }

    try {
      const statusBar = await getStatusMessageForWorkspace(workspaceId)
      if (!_.isEqual(statusBar?.statusBarMeta, lastStatusBarRef.current)) {
        lastStatusBarRef.current = statusBar?.statusBarMeta
        setStatusBar(statusBar?.statusBarMeta)
      }
    } catch (error) {
      console.error('Failed to fetch status bar:', error)
    }
  }, [setStatusBar, workspaceId])

  useEffect(() => {
    fetchStatusBar()

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        fetchStatusBar()
      }
    }

    document.addEventListener('visibilitychange', handleVisibilityChange)
    window.addEventListener('online', fetchStatusBar)

    if (intervalIdRef.current === null) {
      intervalIdRef.current = setInterval(fetchStatusBar, REFETCH_INTERVAL)
    }

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
      window.removeEventListener('online', fetchStatusBar)
      if (intervalIdRef.current !== null) {
        clearInterval(intervalIdRef.current)
        intervalIdRef.current = null
      }
    }
  }, [fetchStatusBar, workspaceId])
}
