import { DiligenceDocument } from 'openapi/models/DiligenceDocument'
import { DiligenceProcessSectionsRequest } from 'openapi/models/DiligenceProcessSectionsRequest'
import { DiligenceSection } from 'openapi/models/DiligenceSection'
import { DiligenceVaultKnowledgeSource } from 'openapi/models/DiligenceVaultKnowledgeSource'
import Services from 'services'

interface DiscoveryReportResult {
  eventId: string
  jobId?: string
}

export async function fetchDiscoveryReportAsync({
  documents,
  sections,
  vaultDocuments,
}: {
  documents: DiligenceDocument[]
  sections: DiligenceSection[]
  vaultDocuments?: DiligenceVaultKnowledgeSource
}): Promise<DiscoveryReportResult> {
  const data: DiligenceProcessSectionsRequest = {
    documents,
    sections,
    vaultDocuments,
  }

  try {
    const response = await Services.Backend.Post<DiscoveryReportResult>(
      'diligence/create_report_job',
      data,
      { throwOnError: true }
    )
    return response
  } catch (error) {
    console.error('Error fetching discovery report:', error)
    throw error
  }
}
