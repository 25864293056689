import React from 'react'

import { Accordion } from 'components/ui/accordion'
import { Card } from 'components/ui/card'
import { ScrollArea } from 'components/ui/scroll-area'
import useWorkflowTaxonomySelector from 'components/workflows/workflow/utils/use-workflow-taxonomy-selector'
import { WorkflowTaxonomyGroup } from 'components/workflows/workflow/utils/use-workflow-taxonomy-selector-group'

import { DiligenceTaxonomySelectorGroup } from './diligence-taxonomy-selector-group'

interface Props<T> {
  availableTaxonomies: T[]
  selectedTaxonomies: T[]
  setSelectedTaxonomies: (taxonomies: T[]) => void
  checkboxDisabled: (title: string) => boolean
  disabledTooltipText: string
}

export const DiligenceTaxonomySelector = <T extends WorkflowTaxonomyGroup>({
  availableTaxonomies,
  selectedTaxonomies,
  setSelectedTaxonomies,
  checkboxDisabled,
  disabledTooltipText,
}: Props<T>) => {
  const { openTaxonomies, handleTaxonomyChange, handleToggleTaxonomy } =
    useWorkflowTaxonomySelector()

  return (
    <ScrollArea
      className="h-full rounded-lg border-none"
      data-testid="company-profile-input-form"
      overflowHintSide="both"
    >
      <Accordion
        type="multiple"
        value={openTaxonomies}
        onValueChange={handleTaxonomyChange}
        className="grid grid-cols-1 gap-3 overflow-auto xl:grid-cols-2 2xl:grid-cols-3"
      >
        {availableTaxonomies.map((taxonomy) => {
          return (
            <Card key={taxonomy.title}>
              <DiligenceTaxonomySelectorGroup
                group={taxonomy}
                onToggleTaxonomy={handleToggleTaxonomy}
                selectedTaxonomies={selectedTaxonomies}
                setSelectedTaxonomies={setSelectedTaxonomies}
                checkboxDisabled={checkboxDisabled}
                disabledTooltipText={disabledTooltipText}
              />
            </Card>
          )
        })}
      </Accordion>
    </ScrollArea>
  )
}
