import React from 'react'
import { useParams } from 'react-router-dom'

import _ from 'lodash'

import { Source } from 'utils/task'

import { SourceCardWithSnippets } from 'components/common/source-card/source-card-with-snippets'
import BasicTransition from 'components/ui/basic-transition'
import { Card, CardContent, CardHeader, CardTitle } from 'components/ui/card'
import { useVaultStore } from 'components/vault/utils/vault-store'

interface VaultQueryResponseProps {
  onDocumentSourceClick: (source: Source) => void
}

const VaultQuerySources = ({
  onDocumentSourceClick,
}: VaultQueryResponseProps) => {
  const { queryId } = useParams()

  const fileIdToVaultFile = useVaultStore((s) => s.fileIdToVaultFile)
  const queryIdToState = useVaultStore((s) => s.queryIdToState)
  const sources = queryIdToState[queryId!]?.sources || []

  const showSources = !_.isEmpty(sources)
  const sourceGroups = _.groupBy(sources, 'documentName')

  return (
    <BasicTransition show={showSources}>
      <Card className="shadow-none" data-testid="source-card">
        <CardHeader className="mb-4 space-y-2">
          <CardTitle>References</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="space-y-4">
            {Object.entries(sourceGroups).map(([key, value], idx) => {
              // we are checking what the most recent filename is because users can rename files
              // it is guaranteed that value will have at least one element
              const documentId = value[0].documentId
              const vaultFile = documentId
                ? fileIdToVaultFile[documentId]
                : undefined
              return (
                <SourceCardWithSnippets
                  key={idx}
                  badges={value[0].badges?.map((badge) => ({
                    text: badge.text,
                    variant: 'secondary',
                  }))}
                  title={vaultFile?.name || key}
                  snippets={value.map((source) => ({
                    text: source.text || 'Click to view',
                    citationNumber: source.footnote,
                    onClick: () => onDocumentSourceClick(source),
                  }))}
                />
              )
            })}
          </div>
        </CardContent>
      </Card>
    </BasicTransition>
  )
}

export default VaultQuerySources
