/* tslint:disable */
/* eslint-disable */
/**
 * Library API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Different levels of visibility for library items
 * @export
 */
export const LibraryVisbilityScope = {
    PRIVATE: 'PRIVATE',
    WORKSPACE: 'WORKSPACE',
    HARVEY: 'HARVEY'
} as const;
export type LibraryVisbilityScope = typeof LibraryVisbilityScope[keyof typeof LibraryVisbilityScope];


export function instanceOfLibraryVisbilityScope(value: any): boolean {
    for (const key in LibraryVisbilityScope) {
        if (Object.prototype.hasOwnProperty.call(LibraryVisbilityScope, key)) {
            if (LibraryVisbilityScope[key as keyof typeof LibraryVisbilityScope] === value) {
                return true;
            }
        }
    }
    return false;
}

export function LibraryVisbilityScopeFromJSON(json: any): LibraryVisbilityScope {
    return LibraryVisbilityScopeFromJSONTyped(json, false);
}

export function LibraryVisbilityScopeFromJSONTyped(json: any, ignoreDiscriminator: boolean): LibraryVisbilityScope {
    return json as LibraryVisbilityScope;
}

export function LibraryVisbilityScopeToJSON(value?: LibraryVisbilityScope | null): any {
    return value as any;
}

