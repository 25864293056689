import React from 'react'

import { TranscriptsDocument } from 'openapi/models/TranscriptsDocument'

import TranscriptsIsLoading from './is-loading'

type Props = {
  documents: TranscriptsDocument[]
  children?: React.ReactNode
}

export const TranscriptsSubheader: React.FC<Props> = ({ documents }) => {
  return (
    <div className="flex justify-end">
      <TranscriptsIsLoading documents={documents} />
    </div>
  )
}
