/* eslint-disable max-params */
import emailAddresses, { ParsedMailbox } from 'email-addresses'
import _ from 'lodash'

import { getCleanEmails } from './multi-email-textarea'

export const onUpsertUsers = (
  onLoadingChange: (loading: boolean) => void,
  onDialogOpenChange: (open: boolean) => void,
  setMismatchDomainUsers: (users: string[]) => void,
  users: string,
  orgDomains: string[]
) => {
  onLoadingChange(true)
  onDialogOpenChange(true)

  if (_.isEmpty(orgDomains)) return

  const emails = getCleanEmails(users)

  const mismatchDomainUsers = emails.filter((email) => {
    const parsed = emailAddresses.parseOneAddress(email)
    const domain =
      parsed && 'domain' in parsed ? (parsed as ParsedMailbox).domain : ''
    return !orgDomains.includes(domain)
  })

  setMismatchDomainUsers(mismatchDomainUsers)
}
