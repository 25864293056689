import React from 'react'

import _ from 'lodash'

import {
  IS_LOADING_HELP_TEXT,
  UPLOAD_DOCUMENT_HELP_TEXT,
} from 'utils/tooltip-texts'

import AskHarveyButton from 'components/common/ask-harvey-button'

import { useLegacyDraftingStore } from './legacy-drafting-store'

type Props = {
  onSubmit: () => void
}

const LegacyDraftingPromptInput: React.FC<Props> = ({ onSubmit }) => {
  const { isLoading, activeDocument } = useLegacyDraftingStore()

  const buttonDisabled = _.isNil(activeDocument) || isLoading

  const getButtonTooltip = () => {
    if (_.isNil(activeDocument)) {
      return UPLOAD_DOCUMENT_HELP_TEXT
    } else if (isLoading) {
      return IS_LOADING_HELP_TEXT
    } else {
      return ''
    }
  }

  return (
    <AskHarveyButton
      handleSubmit={onSubmit}
      disabled={buttonDisabled}
      size="lg"
      tooltip={getButtonTooltip()}
      isLoading={isLoading}
    />
  )
}

export default LegacyDraftingPromptInput
