import React, { useEffect, useState, useMemo, memo } from 'react'
import { useParams } from 'react-router-dom'
import { useMount, useUnmount } from 'react-use'

import { useShallow } from 'zustand/react/shallow'

import { EventKind } from 'openapi/models/EventKind'
import { useGeneralStore } from 'stores/general-store'
import { HistoryItem } from 'types/history'

import { cn } from 'utils/utils'

import { BaseAppPath } from 'components/base-app-path'
import { AppMain } from 'components/common/app-main'
import { useAuthUser } from 'components/common/auth-context'
import FullscreenLoading from 'components/common/fullscreen-loading'
import LoadingBarBelowAppHeader from 'components/common/loading-bar-below-app-header'
import { VaultAddFilesDialog } from 'components/vault/dialogs/vault-add-files-dialog'
import { useFetchEventFeedbackSentiments } from 'components/vault/hooks/use-feedback'
import { useVaultHistoryItemQuery } from 'components/vault/hooks/use-vault-history-item'
import {
  projectsPath,
  ReviewCell,
  ReviewCellStatus,
} from 'components/vault/utils/vault'
import { useVaultDataGridFilterStore } from 'components/vault/utils/vault-data-grid-filters-store'
import { useVaultStore } from 'components/vault/utils/vault-store'

import VaultDataGridHeader from './data-grid/header/vault-data-grid-header'
import { VaultColumnBuilderDialog } from './data-grid/vault-column-builder-dialog'
import VaultDataGridWrapper from './data-grid/vault-data-grid'
import VaultPushSheet from './sheets/vault-push-sheet'
import VaultDataGridToolbelt from './toolbelt/vault-data-grid-toolbelt'
import useVaultQueryDetailStore, {
  ReviewHistoryItem,
} from './vault-query-detail-store'

const MIN_PROGRESS = 20
export const LEFT_PINNED_COLUMNS = ['row', 'name']
export const EXCLUDED_HEADER_NAMES_FROM_EXPORT = ['folderPath', 'addColumn']
export const EXCLUDED_COLIDS_FROM_RESIZE_FILTER = ['row', 'addColumn']
export const EXCLUDED_COLIDS_FROM_COLUMN_CLICK = ['row', 'name', 'addColumn']
export const EXCLUDED_HEADER_NAMES_FROM_DELETE_COLUMN = [
  'row',
  'name',
  'addColumn',
  'folderPath',
]

const LoadingBarBelowAppHeaderWrapper = () => {
  const [queryId, isQueryLoading, historyItem] = useVaultQueryDetailStore(
    useShallow((s) => [s.queryId, s.isQueryLoading, s.historyItem])
  )

  const isNewQuery = queryId === 'new'

  const reviewEvent = historyItem as ReviewHistoryItem
  const progress = useMemo(() => {
    if (!reviewEvent) return 0
    const visibleColumnIds = new Set(
      reviewEvent.columns
        .filter((column) => !column.isHidden)
        .map((column) => column.id)
    )
    const visibleRowIds = new Set(
      reviewEvent.rows.filter((row) => !row.isHidden).map((row) => row.id)
    )
    if (visibleColumnIds.size === 0 || visibleRowIds.size === 0) return 0

    const numProcessedCells =
      reviewEvent?.cells?.filter(
        (cell: ReviewCell) =>
          cell.status !== ReviewCellStatus.EMPTY &&
          visibleRowIds.has(cell.reviewRowId) &&
          visibleColumnIds.has(cell.reviewColumnId)
      ).length ?? 0
    const totalCells = visibleColumnIds.size * visibleRowIds.size

    return (numProcessedCells / totalCells) * 100
  }, [reviewEvent])

  if (isNewQuery || !isQueryLoading) return null

  return (
    <LoadingBarBelowAppHeader progress={Math.max(MIN_PROGRESS, progress)} />
  )
}

const VaultQueryDetailLoading = ({ isLoading }: { isLoading: boolean }) => {
  const [gridApi] = useVaultQueryDetailStore(
    useShallow((state) => [state.gridApi])
  )

  const isLoadingGrid = !gridApi

  return (
    <FullscreenLoading zIndex="z-50" isLoading={isLoadingGrid || isLoading} />
  )
}

const VaultDataGridToolbarWrapper = () => {
  const isSidebarOpen = useGeneralStore(
    useShallow((state) => state.isSidebarOpen)
  )

  return (
    <VaultDataGridToolbelt
      className={cn('fixed ml-24 max-w-[calc(100%-192px)]', {
        'ml-8 max-w-[calc(100%-64px)]': !isSidebarOpen,
      })}
    />
  )
}

const VaultQueryResultsTable = memo(() => {
  const [setCurrentSortColumnId, setDisplayedRows] =
    useVaultDataGridFilterStore(
      useShallow((state) => [
        state.setCurrentSortColumnId,
        state.setDisplayedRows,
      ])
    )

  useUnmount(() => {
    setCurrentSortColumnId('name')
    setDisplayedRows([])
  })

  return (
    <>
      <VaultAddFilesDialog />
      <VaultColumnBuilderDialog />
      <div className="flex h-full w-full flex-col">
        <LoadingBarBelowAppHeaderWrapper />
        <div className="flex h-full w-full">
          <div className="flex h-full w-full flex-col">
            <VaultDataGridHeader />
            <VaultDataGridWrapper />
            <VaultDataGridToolbarWrapper />
          </div>
          <VaultPushSheet />
        </div>
      </div>
    </>
  )
})

const VaultQueryDetailWrapper = () => {
  const { projectId, queryId } = useParams()
  const userInfo = useAuthUser()

  const [setError] = useVaultStore(useShallow((state) => [state.setError]))
  const [setIsSidebarOpenAndToggle, revertSidebarOpen] = useGeneralStore(
    useShallow((state) => [
      state.setIsSidebarOpenAndToggle,
      state.revertSidebarOpen,
    ])
  )
  const [isOldHistoryItem, setIsOldHistoryItem] = useState(false)

  const [
    isQueryLoading,
    isFetchingQuery,
    setHistoryItem,
    setUseV1QueryDetail,
    setCellViewerData,
  ] = useVaultQueryDetailStore(
    useShallow((state) => [
      state.isQueryLoading,
      state.isFetchingQuery,
      state.setHistoryItem,
      state.setUseV1QueryDetail,
      state.setCellViewerData,
    ])
  )

  const isNewQuery = queryId === 'new'

  // If we don't have a history item, we need to fetch the history item (without sources to speed up the process)
  const { historyItem: historyItemWithoutSources, error: errorWithoutSources } =
    useVaultHistoryItemQuery({
      id: queryId,
      vaultFolderId: projectId!,
      isV2: true,
      isDualWriteUser: userInfo.IsVaultDualWriteUser,
      isEnabled: !isNewQuery,
      throwOnError: true,
      skipSources: true,
      refetchInterval: () => {
        return isQueryLoading ? 2_500 : false
      },
    })

  const isLoading = !isNewQuery && isFetchingQuery

  useEffect(() => {
    // let's only update the history item if it's not null
    // it usually is null when we hit the run button and react query does not fetch anything for new queries
    if (!historyItemWithoutSources) return

    const eventKind =
      (historyItemWithoutSources as ReviewHistoryItem)?.eventKind ||
      (historyItemWithoutSources as HistoryItem)?.kind

    const isReviewEvent = eventKind === EventKind.VAULT_REVIEW
    const doesNotHaveRowsColumns =
      isReviewEvent &&
      historyItemWithoutSources &&
      !('rows' in historyItemWithoutSources) &&
      !('columns' in historyItemWithoutSources)

    if (eventKind !== EventKind.VAULT_REVIEW) {
      setUseV1QueryDetail(true)
    } else if (doesNotHaveRowsColumns) {
      setIsOldHistoryItem(true)
    } else if (historyItemWithoutSources) {
      setHistoryItem(historyItemWithoutSources as ReviewHistoryItem)
    }
  }, [
    historyItemWithoutSources,
    setHistoryItem,
    setUseV1QueryDetail,
    setIsOldHistoryItem,
  ])

  // Fetch the feedback sentiments for the query
  useFetchEventFeedbackSentiments(
    !isNewQuery ? Number(queryId) : null,
    userInfo
  )

  useMount(() => {
    // on mount we want to set the sidebar to false
    setIsSidebarOpenAndToggle(false)
    setCellViewerData(null)
  })

  useUnmount(() => {
    revertSidebarOpen()
    setHistoryItem(null)
  })

  if (errorWithoutSources) {
    setError({
      message: `The requested history item ${queryId} you are trying to access does not exist.\nContact support@harvey.ai if this issue persists.`,
      cta: {
        redirectUri: `${BaseAppPath.Vault}${projectsPath}${projectId}`,
        message: 'Back to Vault project',
      },
    })
    return null
  }

  if (isOldHistoryItem) {
    setError({
      message: `The requested history item ${queryId} could not be loaded.\nContact support@harvey.ai if this issue persists.`,
      cta: {
        redirectUri: `${BaseAppPath.Vault}${projectsPath}${projectId}`,
        message: 'Back to Vault project',
      },
    })
  }

  return (
    <AppMain className="flex w-full">
      <VaultQueryDetailLoading isLoading={isLoading} />
      {!isLoading && <VaultQueryResultsTable />}
    </AppMain>
  )
}

VaultQueryResultsTable.displayName = 'VaultQueryDetail'
export default VaultQueryDetailWrapper
