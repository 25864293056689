import React from 'react'

import { ColumnDef } from '@tanstack/react-table'

import { ContractsDocument } from 'openapi/models/ContractsDocument'

import {
  ActionsCell,
  DateCell,
  DocumentCell,
  ExtractedTermsCell,
  FieldsCell,
  TypeCell,
} from './cells'
import {
  DateHeader,
  DocumentHeader,
  FieldsHeader,
  PartiesHeader,
  TypeHeader,
} from './headers'

export type DocumentTable = ContractsDocument & {
  extractedTermsCount: number
}

export const useColumns = (
  removeDocument: (
    e: React.MouseEvent<HTMLButtonElement>,
    document: ContractsDocument
  ) => void
): ColumnDef<DocumentTable>[] =>
  React.useMemo(() => {
    return [
      {
        id: 'file',
        accessorKey: 'file.name',
        header: ({ column }) => <DocumentHeader column={column} />,
        cell: ({ row }) => <DocumentCell row={row} />,
      },
      {
        id: 'type',
        header: () => <TypeHeader />,
        accessorKey: 'type',
        cell: ({ row }) => <TypeCell row={row} />,
      },
      {
        header: ({ column }) => <PartiesHeader column={column} />,
        accessorKey: 'extractedTerms',
        cell: ({ row }) => <ExtractedTermsCell row={row} />,
      },
      {
        header: () => <DateHeader />,
        accessorKey: 'effectiveDate',
        cell: ({ row }) => <DateCell row={row} />,
      },
      {
        accessorKey: 'extractedTermsCount',
        header: () => <FieldsHeader />,
        cell: ({ row }) => <FieldsCell row={row} />,
      },
      {
        id: 'actions',
        cell: ({ row }) => (
          <ActionsCell row={row} removeDocument={removeDocument} />
        ),
      },
    ]
  }, [removeDocument])
