import React, { useEffect } from 'react'

import { useShallow } from 'zustand/react/shallow'

import { useGeneralStore } from 'stores/general-store'

import { site } from 'utils/server-data'
import { getSiteName } from 'utils/site'

import SettingsAppHeader from 'components/settings/settings-app-header'
import SettingsLayout from 'components/settings/settings-layout'

import CreateIncidentStatusCard from './create-incident-status-card'
import ExistingIncidentsCard from './existing-incidents-card'
import { useIncidentManagementStore } from './incident-management-store'

const SettingsIncidentManagement = () => {
  const isStatusBarPreview = useGeneralStore((s) => s.isStatusBarPreview)
  const [fetchAllStatusMessages, fetchWorkspaces] = useIncidentManagementStore(
    useShallow((state) => [state.fetchAllStatusMessages, state.fetchWorkspaces])
  )

  useEffect(() => {
    fetchAllStatusMessages()
    fetchWorkspaces()
  }, [fetchAllStatusMessages, fetchWorkspaces])

  return (
    <>
      <SettingsAppHeader
        title={`Incident Management (${getSiteName(site)})`}
        isInternalAdmin={!isStatusBarPreview}
      />
      <SettingsLayout>
        <div className="space-y-4">
          <div>
            <CreateIncidentStatusCard />
          </div>
          <div>
            <ExistingIncidentsCard />
          </div>
        </div>
      </SettingsLayout>
    </>
  )
}

export default SettingsIncidentManagement
