import React from 'react'

import { cn } from 'utils/utils'

import { TableHead } from 'components/ui/table'

const VaultTableHead = ({
  children,
  className,
}: {
  children: React.ReactNode
  className?: string
}) => {
  return (
    <TableHead className={cn('px-1 py-2.5 font-normal', className)}>
      {children}
    </TableHead>
  )
}

export default VaultTableHead
