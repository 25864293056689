import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'

import { Event } from 'models/event'

interface HistoryState {
  isLoading: boolean
  isHistoryPending: boolean
  isLoadingMore: boolean
  earliestLoadedTime: string
  latestLoadedTime: string
  lastUpdatedTime: Date | null
  dataSource: Event[]
  fetchHistoryUpdate: () => void
  selectedEventIds: number[]
}

interface HistoryActions {
  setIsLoading: (isLoading: boolean) => void
  setIsHistoryPending: (isHistoryPending: boolean) => void
  setIsLoadingMore: (isLoadingMore: boolean) => void
  setEarliestLoadedTime: (time: string) => void
  setLatestLoadedTime: (time: string) => void
  updateLastUpdatedTime: () => void
  setDataSource: (dataSource: Event[]) => void
  setFetchHistoryUpdate: (fetchHistoryUpdate: () => void) => void
  setSelectedEventIds: (selectedEventIds: number[]) => void
}

export const useHistoryStore = create<HistoryState & HistoryActions>()(
  devtools(
    immer((set) => ({
      isLoading: false,
      isHistoryPending: false,
      isLoadingMore: false,
      earliestLoadedTime: '',
      latestLoadedTime: '',
      lastUpdatedTime: null,
      oldestDate: null,
      dataSource: [],
      selectedEventIds: [],
      fetchHistoryUpdate: () => {},
      setIsLoading: (isLoading) => set(() => ({ isLoading })),
      setIsHistoryPending: (isHistoryPending) =>
        set(() => ({ isHistoryPending })),
      setIsLoadingMore: (isLoadingMore) => set(() => ({ isLoadingMore })),
      setEarliestLoadedTime: (time) =>
        set(() => ({ earliestLoadedTime: time })),
      setLatestLoadedTime: (time) => set(() => ({ latestLoadedTime: time })),
      updateLastUpdatedTime: () =>
        set(() => ({
          lastUpdatedTime: new Date(),
        })),
      setDataSource: (dataSource) => set(() => ({ dataSource })),
      setFetchHistoryUpdate: (fetchHistoryUpdate) =>
        set(() => ({ fetchHistoryUpdate })),
      setSelectedEventIds: (selectedEventIds) =>
        set(() => ({ selectedEventIds })),
    }))
  )
)
