import React from 'react'

import { UserInfo } from 'models/user-info'
import { LibraryVisbilityScope } from 'openapi/models/LibraryVisbilityScope'
import Services from 'services'

import {
  AnalyticsContextType,
  FILTER_CHANGED_EVENT_NAME,
} from 'components/common/analytics/analytics-context'
import { useAuthUser } from 'components/common/auth-context'
import { Tabs, TabsList, TabsTrigger } from 'components/ui/tabs'

export type TabConfig = {
  value: LibraryVisbilityScope
  label: string | ((userInfo: UserInfo) => string)
  tooltip?: string
}

interface Props extends AnalyticsContextType {
  id: string
  filterKey: string
  tabs: TabConfig[]
  currentTab: LibraryVisbilityScope
  setCurrentTab: (value: LibraryVisbilityScope) => void
}

const SelectVisibleFilter = ({
  id,
  filterKey,
  tabs,
  currentTab,
  setCurrentTab,
  trackEvent,
}: Props) => {
  const userInfo = useAuthUser()
  const onChangeTab = (value: LibraryVisbilityScope) => {
    Services.HoneyComb.Record({
      metric: `ui.library_tab_change`,
      tab: currentTab,
    })
    trackEvent(FILTER_CHANGED_EVENT_NAME, {
      entity_name: 'library table',
      field_changed: 'tab',
      tab: value,
    })
    setCurrentTab(value)
  }

  return (
    <Tabs
      id={id}
      value={currentTab}
      onValueChange={onChangeTab as (value: string) => void}
      key={filterKey}
    >
      <TabsList className="h-8">
        {tabs.map((tab) => (
          <TabsTrigger
            className="h-6"
            value={tab.value}
            key={tab.value}
            tooltip={tab.tooltip}
          >
            {typeof tab.label === 'string' ? tab.label : tab.label(userInfo)}
          </TabsTrigger>
        ))}
      </TabsList>
    </Tabs>
  )
}

export default SelectVisibleFilter
