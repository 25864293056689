import React, { useRef, useState } from 'react'
import { DateRange } from 'react-day-picker'

import { isSameDay, parseISO, subYears } from 'date-fns'
import { CalendarRange, ChevronRight } from 'lucide-react'

import { readableDateRange } from 'utils/date-utils'

import { Button } from 'components/ui/button'
import { Calendar } from 'components/ui/calendar'
import { DateInput } from 'components/ui/date-input'
import {
  Popover,
  PopoverContent,
  PopoverMenuItem,
  PopoverTrigger,
} from 'components/ui/popover'

type DateRangeMaybeString = Omit<DateRange, 'from' | 'to'> & {
  from: Date | string | undefined
  to?: Date | string | undefined
}

interface Props {
  dateRange: DateRangeMaybeString | null
  handleSetDateRange: (range: DateRange | null) => void
}

export const ResearchDatePicker = ({
  dateRange,
  handleSetDateRange,
}: Props) => {
  const [isMenuOpen, setMenuOpen] = useState(false)
  const parsedTo =
    typeof dateRange?.to === 'string'
      ? parseISO(dateRange.to)
      : dateRange?.to ?? undefined
  const parsedFrom =
    typeof dateRange?.from === 'string'
      ? parseISO(dateRange.from)
      : dateRange?.from ?? undefined
  const parsedDateRange = dateRange
    ? { from: parsedFrom, to: parsedTo }
    : undefined

  // Define time periods and their respective calculation for the date range
  const timePeriods = [
    { label: 'All time', getDateRange: () => null },
    {
      label: 'Past year',
      getDateRange: () => ({ from: subYears(new Date(), 1), to: new Date() }),
    },
    {
      label: 'Past 3 years',
      getDateRange: () => ({ from: subYears(new Date(), 3), to: new Date() }),
    },
    {
      label: 'Past 5 years',
      getDateRange: () => ({ from: subYears(new Date(), 5), to: new Date() }),
    },
  ]

  const timePeriodMenuRefs = useRef<(HTMLButtonElement | null)[]>([])

  const onDayClick = (date: Date) => {
    if (parsedFrom && parsedTo) {
      if (isSameDay(parsedFrom, date) || isSameDay(parsedTo, date)) {
        // Reset date if either end is selected again
        handleSetDateRange({ from: undefined, to: undefined })
      } else {
        handleSetDateRange({
          from: date < parsedFrom ? date : parsedFrom,
          to: date > parsedFrom ? date : parsedFrom,
        })
      }
    } else {
      // Set both dates if no date is selected
      handleSetDateRange({ from: date, to: date })
    }
  }

  const onFromDateChange = (date: Date) => {
    let to = parsedTo
    if (!to) to = new Date()
    if (date > to) to = date
    handleSetDateRange({ from: date, to })
  }

  const onToDateChange = (date: Date) => {
    let from = parsedFrom
    if (!from) from = new Date()
    if (date < from) from = date
    handleSetDateRange({ from, to: date })
  }

  return (
    <Popover open={isMenuOpen} onOpenChange={setMenuOpen}>
      <PopoverTrigger asChild>
        <Button variant="secondary" size="sm">
          <CalendarRange size={16} className="mr-1" />
          <span className="text-xs">
            {parsedDateRange ? formatDateRange(parsedDateRange) : 'All time'}
          </span>
        </Button>
      </PopoverTrigger>
      <PopoverContent
        align="start"
        className="w-44 p-1"
        onOpenAutoFocus={(e) => {
          // Popover auto-focuses the first focusable element by default.
          // This custom logic focuses the active time period, if one is set.
          if (parsedDateRange) {
            const formattedRange = formatDateRange(parsedDateRange)
            let periodIndex = timePeriods.findIndex(
              (period) => period.label === formattedRange
            )
            // Custom time period should be last ref in the array
            if (periodIndex === -1) periodIndex = timePeriods.length
            const periodRef = timePeriodMenuRefs.current[periodIndex]
            if (periodRef) {
              periodRef.focus()
              e.preventDefault()
            }
          }
        }}
      >
        {timePeriods.map((period, index) => (
          <PopoverMenuItem
            key={index}
            className="cursor-pointer"
            onClick={() => {
              handleSetDateRange(period.getDateRange())
              setMenuOpen(false)
            }}
            ref={(el) => (timePeriodMenuRefs.current[index] = el)}
          >
            {period.label}
          </PopoverMenuItem>
        ))}
        <Popover>
          <PopoverTrigger asChild>
            <PopoverMenuItem
              ref={(el) =>
                (timePeriodMenuRefs.current[timePeriods.length] = el)
              }
            >
              Custom date range <ChevronRight className="h-4 w-4" />
            </PopoverMenuItem>
          </PopoverTrigger>
          <PopoverContent
            className="w-64 p-2"
            align="start"
            side="right"
            alignOffset={-12}
          >
            <div className="flex flex-col gap-1">
              <div className="flex justify-between gap-1">
                <DateInput value={parsedFrom} onChange={onFromDateChange} />
                <div className="py-1 text-muted">–</div>
                <DateInput value={parsedTo} onChange={onToDateChange} />
              </div>
              <Calendar
                mode="range"
                defaultMonth={parsedTo}
                selected={parsedDateRange ?? undefined}
                numberOfMonths={1}
                onDayClick={onDayClick}
              />
            </div>
          </PopoverContent>
        </Popover>
      </PopoverContent>
    </Popover>
  )
}

const formatDateRange = (dateRange: DateRange | null) => {
  if (!dateRange?.from) {
    return 'Date range'
  } else {
    return readableDateRange(dateRange)
  }
}
