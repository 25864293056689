import React from 'react'

import { AppHeader } from 'components/common/app-header'
import { useAuthUser } from 'components/common/auth-context'
import SettingsLayout from 'components/settings/settings-layout'

import LibraryEventsContentCopier from './settings-library-events-content-copier'

const SettingsLibraryEventsManager: React.FC = () => {
  const userInfo = useAuthUser()

  if (!userInfo.IsLibraryCreateHarveyItemsUser) {
    return null
  }

  return (
    <>
      <AppHeader
        title="Library Events Manager"
        subtitle="Manage library events content"
        breadcrumbs={<div />}
        isInternalAdmin
      />
      <SettingsLayout>
        <LibraryEventsContentCopier />
      </SettingsLayout>
    </>
  )
}

export default SettingsLibraryEventsManager
