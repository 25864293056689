import React from 'react'

import { cn } from 'utils/utils'

import { ScrollArea } from 'components/ui/scroll-area'

type Props = {
  input: React.ReactElement
  output: React.ReactElement
  hideInput?: boolean
}

export const WorkflowsDualPaneLayout: React.FC<Props> = ({
  input,
  output,
  hideInput,
}) => {
  return (
    <div className="flex h-full space-x-2">
      <div className={cn('w-1/2', { 'w-0': hideInput })}>{input}</div>
      <div className={cn('w-1/2', { 'w-full': hideInput })}>
        <ScrollArea className="h-full rounded-lg border" hasHorizontalScroll>
          {React.cloneElement(output, { className: 'border-none' })}
        </ScrollArea>
      </div>
    </div>
  )
}
