/* tslint:disable */
/* eslint-disable */
/**
 * Harvey - Side-by-Side (SxS) Evaluations
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const EvaluationQuestionType = {
    RESPONSE_LEVEL: 'RESPONSE_LEVEL',
    EVALUATION_LEVEL: 'EVALUATION_LEVEL'
} as const;
export type EvaluationQuestionType = typeof EvaluationQuestionType[keyof typeof EvaluationQuestionType];


export function instanceOfEvaluationQuestionType(value: any): boolean {
    for (const key in EvaluationQuestionType) {
        if (Object.prototype.hasOwnProperty.call(EvaluationQuestionType, key)) {
            if (EvaluationQuestionType[key as keyof typeof EvaluationQuestionType] === value) {
                return true;
            }
        }
    }
    return false;
}

export function EvaluationQuestionTypeFromJSON(json: any): EvaluationQuestionType {
    return EvaluationQuestionTypeFromJSONTyped(json, false);
}

export function EvaluationQuestionTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): EvaluationQuestionType {
    return json as EvaluationQuestionType;
}

export function EvaluationQuestionTypeToJSON(value?: EvaluationQuestionType | null): any {
    return value as any;
}

