import {
  Node as ASTNode,
  Parent,
  PhrasingContentMap,
  BlockContentMap,
  ListContentMap,
  DefinitionContentMap,
} from 'mdast'

type NodeTypeMap = PhrasingContentMap &
  BlockContentMap &
  ListContentMap &
  DefinitionContentMap

export const isParent = (node: ASTNode | null): node is Parent =>
  !!node && 'children' in node

export const isNodeType =
  <T extends keyof NodeTypeMap>(type: T) =>
  (node: ASTNode | null | undefined): node is NodeTypeMap[T] =>
    !!node && node.type === type

export const isTable = isNodeType('table')
export const isHtml = isNodeType('html')
export const isCode = isNodeType('code')
export const isLink = isNodeType('link')
export const isLineBreak = isNodeType('break')
export const isStrong = isNodeType('strong')
export const isEmphasis = isNodeType('emphasis')
export const isDelete = isNodeType('delete')
export const isText = isNodeType('text')
export const isList = isNodeType('list')
export const isListItem = isNodeType('listItem')
export const isThematicBreak = isNodeType('thematicBreak')
export const isHeading = isNodeType('heading')
export const isImage = isNodeType('image')
export const isFootnoteReference = isNodeType('footnoteReference')
export const isFootnoteDefinition = isNodeType('footnoteDefinition')

export const isBlockContent = (
  node: ASTNode | null | undefined
): node is BlockContentMap[keyof BlockContentMap] =>
  !!node &&
  (node.type === 'blockquote' ||
    node.type === 'code' ||
    node.type === 'heading' ||
    node.type === 'html' ||
    node.type === 'list' ||
    node.type === 'paragraph' ||
    node.type === 'table' ||
    node.type === 'thematicBreak')
