import { GridApi } from 'ag-grid-community'

import {
  ColumnDataType,
  ReviewAnswer,
  ReviewCellResponse,
} from 'components/vault/utils/vault'
import { FilterType } from 'components/vault/utils/vault-data-grid-filters-store'

import { ColumnFilter } from './column-filter-types'
import { TextColumnFilter } from './text-column-filter'

export class CurrencyColumnFilter extends ColumnFilter {
  getUniqueColumnValues(
    gridApi: GridApi,
    currentFilterQuestionId: string
  ): string[] {
    const uniqueCurrencySet = new Set<{
      value: number
      currencyCode?: string
    }>()
    let hasDataTypeMismatch = false
    gridApi.forEachNode((node) => {
      if (node.group) return
      const answer = (node.data.answers as ReviewAnswer[]).find(
        (answer) => answer.columnId === currentFilterQuestionId
      )

      if (!answer) return

      if (answer?.columnDataType === ColumnDataType.currency) {
        // We index at 0 because we know the response is always a single currency value
        const response = answer?.rawResponse?.[0] as ReviewCellResponse
        if (response.value) {
          const number = parseFloat(response.value)
          if (!isNaN(number)) {
            uniqueCurrencySet.add({
              value: number,
              currencyCode: response.currencyCode ?? undefined,
            })
          }
        }
      } else {
        hasDataTypeMismatch = true
      }
    })

    // if any answers are non-currency column type, use text column filter instead
    if (hasDataTypeMismatch) {
      return new TextColumnFilter().getUniqueColumnValues(
        gridApi,
        currentFilterQuestionId
      )
    }

    return Array.from(uniqueCurrencySet)
      .sort((a, b) => a.value - b.value)
      .map(({ value, currencyCode }) => {
        const hasZeroDecimals = value % 1 === 0
        const formatter = new Intl.NumberFormat(navigator.language, {
          style: 'currency',
          currency: currencyCode ?? undefined,
          minimumFractionDigits: hasZeroDecimals ? 0 : 2,
          maximumFractionDigits: 2,
          useGrouping: true,
        })
        return formatter.format(value)
      })
  }

  getFilterType(): FilterType {
    return FilterType.CURRENCY
  }
}
